import { Form, Input } from 'antd';

import React from 'react';

interface IProps {
  style?: any;
  disabled?: any;
  className?: any;
  placeholder?: any;
  maxLength?: any;
  value?: any;
  onChange?: any;
  onBlur?: any;
  addonBefore?: any;
  prefix?: any;
  size?: any;
  type?: any;
  validatestatus?: any;
  help?: any;
  validateType?: any;
  onPressEnter?: any;
  leftPrefix?: any;
  input?: any;
  styleForm?: any;
  classNameForm?: any;
}

const { TextArea } = Input;

export default function FormInputTextMaterial({
  validateType,
  validatestatus,
  help,
  type,
  className,
  disabled,
  addonBefore,
  prefix,
  style,
  placeholder,
  maxLength,
  size,
  value,
  onChange,
  onBlur,
  onPressEnter,
  leftPrefix,
  styleForm,
  classNameForm,
}: IProps) {
  return validateType === true ? (
    <Form.Item
      validateStatus={validatestatus}
      help={help}
      style={styleForm}
      className={classNameForm}
    >
      {type === 'password' ? (
        <Input.Password
          className={className}
          disabled={disabled}
          addonBefore={addonBefore}
          prefix={prefix}
          style={style}
          placeholder={placeholder}
          maxLength={maxLength}
          size={size}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
        />
      ) : type === 'textArea' ? (
        <TextArea
          className={className}
          disabled={disabled}
          prefix={prefix}
          style={style}
          placeholder={placeholder}
          maxLength={maxLength}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
        />
      ) : (
        <Input
          className={className}
          disabled={disabled}
          addonBefore={addonBefore}
          prefix={leftPrefix || prefix}
          style={style}
          placeholder={placeholder}
          maxLength={maxLength}
          size={size}
          type={type}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
        />
      )}
    </Form.Item>
  ) : type === 'allowClear' ? (
    <Input
      className={className}
      disabled={disabled}
      allowClear
      addonBefore={addonBefore}
      prefix={prefix}
      style={style}
      placeholder={placeholder}
      maxLength={maxLength}
      size={size}
      type={type}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onPressEnter={onPressEnter}
    />
  ) : (
    <Input
      className={className}
      disabled={disabled}
      addonBefore={addonBefore}
      prefix={prefix}
      style={style}
      placeholder={placeholder}
      maxLength={maxLength}
      size={size}
      type={type}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onPressEnter={onPressEnter}
    />
  );
}
