import * as ActionCallCenter from '../../CallCenter/Store/CallCenterAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionGeneralData from '../../App/Store/GeneralDataAction';
import * as SelectorsCallCenter from '../../CallCenter/Selector/CallCenterSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGeneralData from '../../App/Selector/GeneralDataSelector';

import { bindActionCreators, compose } from 'redux';
import { change, getFormSyncErrors, getFormValues } from 'redux-form';
import { connect, useDispatch } from 'react-redux';

import CallCenterFormComponent from '../Component/CallCenterFormComponent';
import React from 'react';
import { createStructuredSelector } from 'reselect';

function CallCenterFormContainer(props) {
  const { actionGeneralData, actionCallCenter, componentAction, formValue } =
    props;
  const customerId = formValue ? formValue.customer : '';
  const dispatch = useDispatch();
  const handleClearFormatCsvAndProduct = () => {
    dispatch(change('callCenterForm', 'formatCsv', null));
    dispatch(change('callCenterForm', 'product', null));
  };
  const handleGetCustomerAndBranchList = () => {
    actionGeneralData.getCustomerList();
    actionGeneralData.getBranchList();
  };
  const handleGetFormatCsv = () => {
    if (customerId !== undefined || customerId !== '') {
      actionCallCenter.getFormatCsvList();
    }
  };
  const handleGetProduct = () => {
    if (customerId !== undefined || customerId !== '') {
      actionCallCenter.getCustomerProductList();
    }
  };
  const handleClearCourier = () => {
    actionCallCenter.getCourierList();
  };
  const handleGetCourierList = () => {
    dispatch(change('callCenterForm', 'courier', null));
  };
  const getBase64 = (file: any) => {
    return new Promise(resolve => {
      let baseURL;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleConvertCsv = event => {
    actionCallCenter.changeCsvFileName(event.name);
    getBase64(event)
      .then((result: any) => {
        event['base64'] = result.toString().split(',')[1];
        actionCallCenter.setCsvFile(result.split(',')[1]);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const handleCloseModal = () => {
    actionCallCenter.clearListResultCsvCallCenter();
    actionCallCenter.clearFormCallCenter();
    componentAction.openModal('Notification');
  };
  const handleSubmit = () => {
    actionCallCenter.submitUploadCallCenter();
  };
  const handleDownload = () => {
    actionCallCenter.downloadResultCsvCallCenter();
  };
  return (
    <React.Fragment>
      <CallCenterFormComponent
        handleClearFormatCsvAndProduct={handleClearFormatCsvAndProduct}
        handleGetCourierList={handleGetCourierList}
        handleConvertCsv={handleConvertCsv}
        handleCloseModal={handleCloseModal}
        handleGetCustomerAndBranchList={handleGetCustomerAndBranchList}
        handleSubmit={handleSubmit}
        handleDownload={handleDownload}
        handleGetFormatCsv={handleGetFormatCsv}
        handleGetProduct={handleGetProduct}
        handleClearCourier={handleClearCourier}
        {...props}
      />
    </React.Fragment>
  );
}

const mapStateToProps = () =>
  createStructuredSelector({
    branchList: SelectorsGeneralData.makeBranchListSelector(),
    customerList: SelectorsGeneralData.makeCustomerListSelector(),
    formatCsvList: SelectorsCallCenter.makeFormatCsvListSelector(),
    courierList: SelectorsCallCenter.makeCourierListSelector(),
    customerProductList: SelectorsCallCenter.makeCustomerProductListSelector(),
    totalSuccessKu: SelectorsCallCenter.makeDataCsvSuccessKuSelector(),
    totalFailedKu: SelectorsCallCenter.makeDataCsvFailedKuSelector(),
    totalSuccessAttempt:
      SelectorsCallCenter.makeDataCsvSuccessAttemptSelector(),
    totalFailedAttempt: SelectorsCallCenter.makeDataCsvFailedAttemptSelector(),
    isLoading: SelectorsComponent.makeIsLoading(),
    notificationModalIsShow: SelectorsComponent.notificationModalIsShow(),
    isLoadingBranchList: SelectorsGeneralData.makeIsLoadingGetBranch(),
    isLoadingCustomerList: SelectorsGeneralData.makeIsLoadingGetCustomer(),
    isLoadingFormatCsvList: SelectorsGeneralData.makeIsLoadingGetFormatCsv(),
    isLoadingCourierList: SelectorsGeneralData.makeIsLoadingGetCourier(),
    formValue: getFormValues('callCenterForm'),
    errorFormValue: getFormSyncErrors('callCenterForm'),
  });
const mapDispatchToProps = dispatch => ({
  componentAction: bindActionCreators(ActionComponent, dispatch),
  actionGeneralData: bindActionCreators(ActionGeneralData, dispatch),
  actionCallCenter: bindActionCreators(ActionCallCenter, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(CallCenterFormContainer);
