import React, { useState } from 'react';
import styles from './SlaDataTable.module.css';
import SpinMaterial from 'materials/SpinMaterial';
import CardMaterial from 'materials/CardMaterial';
import SearchMatrial from 'materials/SearchMatrial';
import ButtonMaterial from 'materials/ButtonMaterial';
import SelectField from 'components/SelectField';
import { FilterDataSlaProps } from 'module/Report/Ringkasan/container/SlaPengiriman';
import { ColumnAlignTable } from 'interface/general';
import { DownloadReportSLATypes } from 'lib/constants';
import { Row, Col, Table } from 'antd';
import { DataTableResProps } from 'interface/general';
import { trimText } from 'lib/util';

type SlaDataTableProps = {
  dataFilterSla: FilterDataSlaProps;
  dataTableSla: DataTableResProps;
  isLoadingTable: boolean;
  handlePage: (value: number) => void;
  handleSearchValue: (value: string) => void;
  selectedDownloadSla: string;
  setSelectedDownloadSla: (value: string) => void;
  handleDownloadFile: () => void;
};

export default function SlaDataTable({
  dataFilterSla,
  isLoadingTable,
  dataTableSla,
  handlePage,
  handleSearchValue,
  selectedDownloadSla,
  setSelectedDownloadSla,
  handleDownloadFile,
}: SlaDataTableProps) {
  const [valueSearch, setValueSearch] = useState('');

  const pagePagination = dataTableSla?.page + 1 || 0;
  const totalPagination = dataTableSla?.totalPages * 10 || 0;
  const looppage = (pagePagination - 1) * 10 + 1;

  const formatAgingText = (aging: any, index: any) => {
    return JSON.parse(aging)[index] || 0;
  };
  const columnsTable = [
    {
      title: 'No',
      render: (text: any, record: any, index: any) => (
        <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
      ),
      width: 80,
    },
    {
      title:
        dataFilterSla.typeFilter === 'Courier' ? 'Nama Kurir' : 'Nama Posko',
      dataIndex:
        dataFilterSla.typeFilter === 'Courier' ? 'courierName' : 'branchName',
    },
    {
      title:
        dataFilterSla.typeFilter === 'Courier' ? 'Kode Kurir' : 'Kode Posko',
      align: 'center' as ColumnAlignTable,
      dataIndex:
        dataFilterSla.typeFilter === 'Courier' ? 'courierCode' : 'branchCode',
    },
    ...(dataFilterSla.typeFilter === 'Courier'
      ? [
          {
            title: 'Posko',
            dataIndex: 'branchName',
            align: 'center' as ColumnAlignTable,
          },
        ]
      : []),
    {
      title: 'Aging',
      className: styles.columnTableHead,
      children: [
        {
          title: '1',
          dataIndex: 'aging',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 1);
          },
        },
        {
          title: '2',
          dataIndex: 'aging',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 2);
          },
        },
        {
          title: '3',
          dataIndex: 'aging',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 3);
          },
        },
        {
          title: '4',
          dataIndex: 'aging',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 4);
          },
        },
        {
          title: '5',
          dataIndex: 'aging',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 5);
          },
        },
        {
          title: '6',
          dataIndex: 'aging',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 6);
          },
        },
        {
          title: '7',
          dataIndex: 'aging',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 7);
          },
        },
        {
          title: '8',
          dataIndex: 'aging',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 8);
          },
        },
        {
          title: '9',
          dataIndex: 'aging',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, 9);
          },
        },
        {
          title: '>9',
          dataIndex: 'aging',
          className: 'columnAgingHead',
          align: 'center' as ColumnAlignTable,
          render: text => {
            return formatAgingText(text, '9+');
          },
        },
      ],
    },
    {
      title: 'Total',
      align: 'center' as ColumnAlignTable,
      dataIndex: 'total',
    },
  ];
  return (
    <SpinMaterial spinning={isLoadingTable} size="large">
      <CardMaterial style={{ borderRadius: '10px 10px 0 0' }}>
        <Row>
          <Col span={18}>
            <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
              <span className="titleSearchCard" style={{ padding: 0 }}>
                SEMUA DATA
              </span>
              <SearchMatrial
                style={{ borderRadius: '10px', width: '50%' }}
                placeholder={
                  dataFilterSla.typeFilter === 'Courier'
                    ? 'Cari berdasarkan nama/kode kurir'
                    : 'Cari berdasarkan nama/kode posko'
                }
                value={valueSearch}
                onChange={e => setValueSearch(trimText(e.target.value))}
                onPressEnter={e => handleSearchValue(e.target.value)}
              />
            </div>
          </Col>
        </Row>
      </CardMaterial>
      <Table
        columns={columnsTable}
        bordered
        dataSource={dataTableSla?.data || []}
        pagination={{
          onChange: page => {
            handlePage(page - 1);
          },
          pageSize: 10,
          total: totalPagination,
          current: pagePagination === 0 ? 1 : pagePagination,
        }}
        footer={() => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              gap: 8,
            }}
          >
            <SelectField
              placeholder="Pilih Jenis Form"
              style={{ width: '200px' }}
              value={selectedDownloadSla}
              data={DownloadReportSLATypes}
              onChange={value => setSelectedDownloadSla(value)}
            />
            <ButtonMaterial
              icon="download"
              type="download"
              style={{
                ...(selectedDownloadSla &&
                  dataTableSla?.data.length >= 0 && {
                    background: '#874FFF',
                    color: 'white',
                  }),
              }}
              disabled={
                !selectedDownloadSla ||
                !dataTableSla?.data ||
                dataTableSla?.data.length === 0
              }
              handleSubmit={handleDownloadFile}
            />
            <p style={{ margin: 0, fontWeight: 500 }}>
              Total Entri ({dataTableSla?.totalDatas || 0})
            </p>
          </div>
        )}
      />
    </SpinMaterial>
  );
}
