import * as ActionAuth from '../../action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as formPassword from '../../action/ResetPassword/ActionResetPassword';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_editResetPassword(action: any) {
  try {
    yield put(toggle.changeLoadingTrue());
    const state = yield select();

    const token = state.Auth.Token;
    const config = {
      Authorization: `Bearer ${token}`,
    };
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    const Password = state.ResetPassword.Password;
    const NewPassword = state.ResetPassword.NewPassword;

    yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/User/password`,
      {
        oldPassword: Password,
        newPassword: NewPassword,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(toggle.changeModal());
    yield put(toggle.clearJenisToggle());
    yield put(formPassword.changeClearDataResetPassword());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Kata Sandi Berhasil Di Ubah</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    if (error.response.data.Message === 'Old Password does not match') {
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            <b>Kata sandi lama anda salah</b>
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>Silahkan coba lagi</p>
          </div>
        ),
        onOk() {},
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}
export function* SM_editResetPassword() {
  yield takeLatest('SUBMIT_EDIT_RESET_PASSWORD', S_editResetPassword);
}
