import * as ActionActivationVoip from '../../../../core/action/Customer/Voip/ActionActivationVoip';
import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle/index';

import { Modal, notification } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../../ErrorMassage/index';
import React from 'react';
import axios from 'axios';

export function* S_submitAddActivation() {
  const state = yield select();
  const token = state.Auth.Token;
  const productType = state.Voip.ActivationVoip.productType;
  const customerId = state.Customer.PropertiesCustomer.IdCustomer;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  const openNotification = () => {
    notification.warning({
      message: 'Gagal menambahkan data',
      description: 'Silahkan coba kembali',
    });
  };

  try {
    yield put(toggle.changeLoadingTrue());
    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/VoipSetting/activeProduct',
      {
        customerProduct: productType === null ? [] : productType,
        customer: customerId,
      },
      { headers: config }
    );

    yield put(ActionActivationVoip.clearActivationVoip());
    yield put(ActionActivationVoip.getDetailCustomerActivation());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil disimpan</b>
        </p>
      ),
      onOk() {},
    });
  } catch (error) {
    if (error.response.status === 401) {
      openNotification();
      yield put(ActionLogin.submitTokenRefrash());
      if (statusTokenRefresh === true) {
        yield put(ActionActivationVoip.getDetailCustomerActivation());
        yield put(ActionAuth.changeStatusTokenRefresh(false));
      }
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      const propsError = ErrorMassage('Activation VoIP');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}
export function* SM_submitAddActivation() {
  yield takeLatest('ADD_ACTIVATION_PRODUCT_VOIP', S_submitAddActivation);
}
