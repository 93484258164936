const ComplaintReportValidation = (values, props) => {
  const { startDate, finishDate, reportType, customer, product } = values;
  const errors: any = {};
  if (!startDate) {
    errors.startDate = 'Tanggal wajib diisi';
  }
  if (!finishDate) {
    errors.finishDate = 'Tanggal wajib diisi';
  }
  if (!customer) {
    errors.customer = 'Pelanggan wajib diisi';
  }
  if (!reportType) {
    errors.reportType = 'Jenis laporan wajib diisi';
  }
  if (!product) {
    errors.product = 'Produk wajib diisi';
  }
  const complaintStatusValueLength = values.complaintStatus
    ? values.complaintStatus.length
    : 0;
  if (complaintStatusValueLength === 0) {
    errors.complaintStatus = 'Status keluhan wajib diisi';
  }
  return errors;
};
export default ComplaintReportValidation;
