import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as ActionToggle from '../../../../core/action/toggle/index';
import * as ActionCourierManifest from '../../../../core/action/Manifest/CourierManifest/ActionCourierManifest';
import TableCourierManifestComponent from '../../../../components/Content/Manifest/CourierManifest/TableCourierManifestComponent';

interface IProps {
  isLoading: any;
  TableDeliveryData: any;
  ListCourierManifest: any;
  Count: any;
  BranchId: any;
  actionToggle: any;
  actionCourierManifest: any;
}
interface IState {
  page: any;
}
class TableCourierManifestContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: null,
    };
    this.handlePage = this.handlePage.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }
  handlePage(event: any) {
    this.setState({
      page: event,
    });
  }
  
  handleReset() {
    this.props.actionCourierManifest.clearCourierManifestForm();
    this.props.actionCourierManifest.clearTableCourierManifestForm();
    this.props.actionCourierManifest.clearListCourierManifestForm();
  }

  handleDownload() {
    this.props.actionCourierManifest.downloadCourierManifestForm();
  }

  render() {
    return (
      <div>
        <TableCourierManifestComponent
          isLoading={this.props.isLoading}
          TableDeliveryData={this.props.TableDeliveryData}
          ListCourierManifest={this.props.ListCourierManifest}
          Count={this.props.Count}
          BranchId={this.props.BranchId}
          handleReset={this.handleReset}
          handlePage={this.handlePage}
          handleDownload={this.handleDownload}
          page={this.state.page}
        />
      </div>
    );
  }
}
const mapStateProps = (state: any) => ({
  TableDeliveryData: state.Manifest.TableCourierManifest,
  ListCourierManifest: state.Manifest.ListCourierManifest,
  Count: state.Manifest.CourierManifestForm.Count,
  BranchId: state.Manifest.CourierManifestForm.BranchId,
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionCourierManifest: bindActionCreators(ActionCourierManifest, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(TableCourierManifestContainer);
