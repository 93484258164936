import * as ActionAuth from '../../../core/action//Auth';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionLogActivty from '../Store/LogActivityAction';
import * as ActionLogin from '../../Login/Store';
import * as SelectorApp from '../../App/Selector/AppSelector';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getFormValues } from 'redux-form';
import moment from 'moment';

function* S_getLogActivityListProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const formValue = yield select(getFormValues('logActivityForm'));
  const startDate = moment(formValue.startDateLogActivity).format('YYYYMMDD');
  const finishDate = moment(formValue.finishDateLogActivity).format('YYYYMMDD');
  const pagePagination = yield select(SelectorApp.makePagePagination());
  const pagePaginationValue =
    pagePagination === 0 ? pagePagination : pagePagination - 1;
  const sizeDataPagination = yield select(SelectorApp.makeSizeDataPagination());
  const logTypePostValue =
    'Action=' + Object.values(formValue.logType).join('&Action=');
  const actorPostValue =
    'Actor=' + Object.values(formValue.user).join('&Actor=');
  let apiLinkValue = '';
  const apiLink = () => {
    if (formValue.logType.length === 0 && formValue.user.length === 0) {
      apiLinkValue = `StartDate=${startDate}&EndDate=${finishDate}`;
    } else if (formValue.user.length > 0 && formValue.logType.length === 0) {
      apiLinkValue = `${actorPostValue}&StartDate=${startDate}&EndDate=${finishDate}`;
    } else if (formValue.user.length === 0 && formValue.logType.length > 0) {
      apiLinkValue = `${logTypePostValue}&StartDate=${startDate}&EndDate=${finishDate}`;
    } else {
      apiLinkValue = `${actorPostValue}&${logTypePostValue}&StartDate=${startDate}&EndDate=${finishDate}`;
    }
  };

  try {
    apiLink();
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/LogActivity/${pagePaginationValue}/${sizeDataPagination}?${apiLinkValue}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComponent.setTotalPagePagination(data.totalPages * 10));

    yield put(ActionLogActivty.fetchLogActivityListData(data.data));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

function* S_downloadLogActivityDataProcess(action) {
  const state = yield select();
  const key = 'download';
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const formValue = yield select(getFormValues('logActivityForm'));
  const startDate = moment(formValue.startDateLogActivity).format('YYYYMMDD');
  const finishDate = moment(formValue.finishDateLogActivity).format('YYYYMMDD');
  const logTypePostValue =
    'Action=' + Object.values(formValue.logType).join('&Action=');
  const actorPostValue =
    'Actor=' + Object.values(formValue.user).join('&Actor=');
  let apiLinkValue = '';
  const apiLink = () => {
    if (formValue.logType.length === 0 && formValue.user.length === 0) {
      //JIKA FORM LOG ACTIVITY & USER TIDAK DIISI
      apiLinkValue = `StartDate=${startDate}&EndDate=${finishDate}`;
    } else if (formValue.user.length > 0 && formValue.logType.length === 0) {
      //JIKA FORM USER DIISI DAN TIPE LOG TIDAK DIISI
      apiLinkValue = `${actorPostValue}&StartDate=${startDate}&EndDate=${finishDate}`;
    } else if (formValue.user.length === 0 && formValue.logType.length > 0) {
      //JIKA FORM USER TIDAK DIISI DAN TIPE LOG DIISI 1 VALUE
      apiLinkValue = `${logTypePostValue}&StartDate=${startDate}&EndDate=${finishDate}`;
    } else {
      //JIKA FORM USER & TIPE LOG DIISI
      apiLinkValue = `${actorPostValue}&${logTypePostValue}&StartDate=${startDate}&EndDate=${finishDate}`;
    }
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const fileName = `LogAktivitas${startDate}- ${finishDate}.xlsx`;
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
  try {
    yield put(ActionComponent.processLoading(true));
    apiLink();
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/LogActivity/ExportLogActivity?${apiLinkValue}`,
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);
          if (percentProgress < 100) {
            message.loading({
              content: `Loading ${percentProgress}%'`,
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionComponent.processLoading(false));
    if (error.response.data.Message === 'No data found!') {
      message.warning({
        content: 'Data Tidak Tersedia',
        key,
        duration: 3,
      });
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}

function* S_logActivityLogoutProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const tokenRefresh = state.Auth.TokenRefresh;

  try {
    yield put(ActionComponent.processLoading(true));
    yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Token/invalidate/${tokenRefresh}`,
      {},
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

export function* SM_getLogActivityListAction() {
  yield takeLatest(
    'GET_LOG_ACTIVITY_DATA_LIST_REQUESTED',
    S_getLogActivityListProcess
  );
}
export function* SM_downloadLogActivityDataAction() {
  yield takeLatest(
    'DOWNLOAD_LOG_ACTIVITY_DATA_REQUESTED',
    S_downloadLogActivityDataProcess
  );
}
export function* SM_logActivityLogoutAction() {
  yield takeLatest(
    'HIT_LOG_ACTIVITY_LOGOUT_REQUESTED',
    S_logActivityLogoutProcess
  );
}
