import 'styles/ResendStyle/index.css';

import { Button, Col, Icon, Row, Tag } from 'antd';

import React from 'react';
import icon_resend_failed from 'image/icon_resend_failed.png';
import icon_resend_success from 'image/icon_resend_success.png';
import moment from 'moment';

interface IProps {
  CollapsListResend: any;
  CollapsKeyListResend: any;
  getListResend: any;
  handleCollapsListResend: any;
  handleRemoveAllListResend: any;
  handleViewStatusDelivery: any;
}
export default function HistoryListResendComponent({
  CollapsListResend,
  CollapsKeyListResend,
  getListResend,
  handleCollapsListResend,
  handleRemoveAllListResend,
  handleViewStatusDelivery,
}: IProps) {
  return (
    <div style={{ paddingTop: '20px', paddingBottom: '35px' }}>
      {getListResend
        .sort(function (a: any, b: any) {
          return b.index - a.index;
        })
        .map((data: any, index: any) => (
          <div>
            <div className="header">
              <div onClick={() => handleCollapsListResend(index)}>
                <Row style={{ margin: '15px' }}>
                  <Col span={1}>
                    <div style={{ paddingTop: '4px' }}>
                      <Icon
                        type={
                          CollapsKeyListResend === index &&
                          CollapsListResend === true
                            ? 'down'
                            : 'right'
                        }
                      />
                    </div>
                  </Col>
                  <Col span={21}>
                    <div
                      style={{
                        fontSize: '1.2em',
                        color:
                          data.Status === 'notfound' ||
                          data.data.message === 'Update failed!'
                            ? '#FF5858'
                            : '#31E116',
                      }}
                    >
                      <b>
                        {data.Status === 'notfound' ? (
                          <div>
                            <span style={{ fontStyle: 'italic' }}>
                              "{data.data.waybillCode}"
                            </span>
                            <span>-Tidak Ditemukan</span>
                          </div>
                        ) : (
                          data.data.waybillCode +
                          (data.data.message === 'Update failed!'
                            ? `-${data.data.courierCode}-Gagal Update`
                            : `-${data.data.courierCodeResend}-ke${data.data.deliveryPositionResend}`)
                        )}
                      </b>
                    </div>
                  </Col>
                  <Col span={2}>
                    <img
                      src={
                        data.Status === 'notfound' ||
                        data.data.message === 'Update failed!'
                          ? icon_resend_failed
                          : icon_resend_success
                      }
                      alt="icon resend"
                      style={{ float: 'right', width: '30px' }}
                    />
                  </Col>
                </Row>
              </div>
              {CollapsKeyListResend === index && CollapsListResend === true ? (
                <div>
                  <hr
                    style={{
                      display: 'block',
                      height: '1px',
                      width: '100%',
                      border: '0',
                      borderTop: '1px solid #ccc',
                      margin: '0',
                      padding: '0',
                    }}
                  />
                  <div style={{ width: '100%', padding: '15px' }}>
                    {data.Status === 'notfound' ? (
                      <div
                        style={{
                          color: '#969696',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        Tidak ditemukan
                      </div>
                    ) : (
                      <div style={{ fontSize: '1.0em' }}>
                        <Row>
                          <Col
                            span={12}
                            style={{
                              paddingLeft: '42px',
                              paddingRight: '15px',
                            }}
                          >
                            <Row>
                              <Col span={9}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#969696',
                                  }}
                                >
                                  <span>Waybill</span>
                                </div>
                              </Col>
                              <Col span={15}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#000000',
                                  }}
                                >
                                  <span>{data.data.waybillCode}</span>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={9}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#969696',
                                  }}
                                >
                                  <span>Nama Pemilik</span>
                                </div>
                              </Col>
                              <Col span={15}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#000000',
                                  }}
                                >
                                  <span>{data.data.shipToNames}</span>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={9}>
                                <div>
                                  <span style={{ color: '#969696' }}>
                                    Alamat
                                  </span>
                                </div>
                              </Col>
                              <Col span={15}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#000000',
                                  }}
                                >
                                  <span>{`${Object.values(
                                    data.AddressArray
                                  ).join(' ')}`}</span>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={9}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#969696',
                                  }}
                                >
                                  <span>Status Terakhir</span>
                                </div>
                              </Col>
                              <Col span={15}>
                                <div style={{ color: '#000000' }}>
                                  <Tag
                                    color={
                                      data.data.deliveryStatus === 'Success'
                                        ? 'green'
                                        : data.data.deliveryStatus ===
                                          'OnProcces'
                                        ? 'gray'
                                        : 'red'
                                    }
                                  >
                                    {data.data.deliveryStatus === 'Success' ? (
                                      <span style={{ fontSize: '14px' }}>
                                        Diterima
                                      </span>
                                    ) : data.data.deliveryStatus ===
                                      'OnProcces' ? (
                                      <span style={{ fontSize: '14px' }}>
                                        Proses
                                      </span>
                                    ) : (
                                      <span style={{ fontSize: '14px' }}>
                                        Dikembalikan
                                      </span>
                                    )}
                                  </Tag>
                                  {data.data.deliveryStatus === 'Return3x' ? (
                                    <span style={{ fontSize: '14px' }}>
                                      {`Ke- ${data.data.deliveryPositionResend}`}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  <span>
                                    {data.data.deliveryStatus === 'Return'
                                      ? data.data.returnedReason
                                      : data.data.deliveryStatus === 'Success'
                                      ? `${data.data.receiverTitle} ` +
                                        (data.data.receiverName === ''
                                          ? ''
                                          : `(${data.data.receiverName})`)
                                      : ''}
                                  </span>
                                </div>
                              </Col>
                              {data.data.deliveryStatus ===
                              'OnProcces' ? null : (
                                <div>
                                  <Col span={9}></Col>
                                  <Col span={15}>
                                    <div
                                      style={{
                                        paddingBottom: '15px',
                                        color: '#000000',
                                      }}
                                    >
                                      {`(${moment(
                                        data.data.itemFinishedTime
                                      ).format('DD/MM/YYYY')})`}
                                    </div>
                                  </Col>
                                </div>
                              )}
                            </Row>
                            <Row>
                              <Col span={9}>
                                <div>
                                  <span style={{ color: '#969696' }}>
                                    Pengirim Sebelumnya
                                  </span>
                                </div>
                              </Col>
                              <Col span={15}>
                                <div
                                  style={{
                                    paddingBottom: '15px',
                                    color: '#000000',
                                  }}
                                >
                                  <span>
                                    {`${data.data.courierCode} (${data.data.courierName})`}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={12} style={{ paddingLeft: '15px' }}>
                            <div>
                              <Row
                                style={{
                                  paddingBottom: '6px',
                                  paddingTop: '1px',
                                }}
                              >
                                <Col>
                                  <div
                                    style={{
                                      padding: 5 % 0,
                                      backgroundColor: '#B0E0E6',
                                      textAlign: 'center',
                                      verticalAlign: 'middle',
                                    }}
                                  >
                                    <div
                                      style={{
                                        color: '#007FAF',
                                        padding: 10 % 0,
                                      }}
                                    >
                                      <b>Pembaharuan Data</b>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  paddingBottom: '6px',
                                  paddingTop: '1px',
                                }}
                              >
                                <Col span={11}>
                                  <div
                                    style={{
                                      paddingBottom: '15px',
                                      color: '#969696',
                                    }}
                                  >
                                    <span>Tanggal Kirim Ulang</span>
                                  </div>
                                </Col>
                                <Col span={13}>
                                  <div
                                    style={{
                                      paddingBottom: '15px',
                                      color: '#000000',
                                    }}
                                  >
                                    <span>
                                      {data.data.deliveryStatus ===
                                        'OnProcces' ||
                                      data.data.deliveryStatus === 'Return3x' ||
                                      data.data.deliveryStatus === 'Success'
                                        ? '-'
                                        : moment(data.data.dateResend).format(
                                            'DD/MM/YYYY'
                                          )}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  paddingBottom: '6px',
                                  paddingTop: '1px',
                                }}
                              >
                                <Col span={11}>
                                  <div
                                    style={{
                                      paddingBottom: '15px',
                                      color: '#969696',
                                    }}
                                  >
                                    <span>Pengiriman</span>
                                  </div>
                                </Col>
                                <Col span={13}>
                                  <div
                                    style={{
                                      paddingBottom: '15px',
                                      color: '#000000',
                                    }}
                                  >
                                    <span>
                                      {data.data.deliveryStatus ===
                                        'OnProcces' ||
                                      data.data.deliveryStatus === 'Return3x' ||
                                      data.data.deliveryStatus === 'Success'
                                        ? '-'
                                        : `'KE ${data.data.deliveryPositionResend}`}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col span={11}>
                                  <div style={{ color: '#969696' }}>
                                    <span>Pengirim Saat Ini</span>
                                  </div>
                                </Col>
                                <Col span={13}>
                                  <div style={{ color: '#000000' }}>
                                    <span>
                                      {data.data.deliveryStatus ===
                                        'OnProcces' ||
                                      data.data.deliveryStatus === 'Return3x' ||
                                      data.data.deliveryStatus === 'Success'
                                        ? '-'
                                        : `${data.data.courierCodeResend}(${data.data.courierNameResend})`}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                        <div
                          onClick={() =>
                            handleViewStatusDelivery(data.data.waybillCode)
                          }
                          style={{
                            paddingLeft: '42px',
                            paddingTop: '40px',
                            fontStyle: 'italic',
                            textDecoration: 'underline',
                            color: '#11BEFF',
                          }}
                        >
                          Lihat Status Pengiriman
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      {getListResend.length !== 0 ? (
        <div style={{ width: '100%', paddingTop: '10px' }}>
          <Button
            style={{
              left: '50%',
              transform: 'translate(-50%)',
              borderRadius: '8px',
              background: 'red',
              borderColor: 'red',
              color: '#FFF',
            }}
            onClick={handleRemoveAllListResend}
          >
            <span>
              <b>Hapus semua list</b>
            </span>
          </Button>
        </div>
      ) : null}
      {/* <Collapse onChange={callback}>
        {ListHistory.map((data: any, index: any) => (
          <Panel header={data.header} key={index}>
            <p>{data.deskription}</p>
          </Panel>
        ))}
      </Collapse> */}
    </div>
  );
}
