export function changeDeliveryType(deliveryType:any)
{
    return({
        type:"CHANGE_DELIVERY_TYPE_AC",
        deliveryType
    })
}
export function changeEnvelopeType(envelopeType:any)
{
    return({
        type:"CHANGE_ENVELOPE_TYPE_AC",
        envelopeType
    })
}
export function changeWeight(weight:any)
{
    return({
        type:"CHANGE_WEIGHT_AC",
        weight
    })
}
export function changeKoli(koli:any)
{
    return({
        type:"CHANGE_KOLI_AC",
        koli
    })
}
export function changeListKoli(ListKoli:any)
{
    return({
        type:"CHANGE_LIST_KOLI_AC",
        ListKoli
    })
}
export function changeArea(area:any)
{
    return({
        type:"CHANGE_AREA_AC",
        area
    })
}
export function changeBranchId(branchId:any)
{
    return({
        type:"CHANGE_BRANCH_ID_AC",
        branchId
    })
}
export function changeCourierId(courierId:any)
{
    return({
        type:"CHANGE_COURIER_ID_AC",
        courierId
    })
}
export function changeCourierCode(courierCode:any)
{
    return({
        type:"CHANGE_COURIER_CODE_AC",
        courierCode
    })
}
export function changeDetailCourierCode(DetailCourierCode:any)
{
    return({
        type:"CHANGE_DETAIL_COURIER_CODE_AC",
        DetailCourierCode
    })
}
export function changeWorB(WorB:any)
{
    return({
        type:"CHANGE_W_OR_B_AC",
        WorB
    })
}
export function changeDetailWorB(DetailWorB:any)
{
    return({
        type:"CHANGE_DETAIL_W_OR_B_AC",
        DetailWorB
    })
}
export function changeCheckBox(CheckBox:any)
{
    return({
        type:"CHANGE_CHECKBOX_AC",
        CheckBox
    })
}
export function changeCheckBox2(CheckBoxKoli:any)
{
    return({
        type:"CHANGE_CHECKBOX_KOLI_AC",
        CheckBoxKoli
    })
}

export function changeAssignStatus(AssignStatus:any)
{
    return({
        type:"CHANGE_ASSIGN_STATUS_AC",
        AssignStatus
    })
}

export function submitAssigningCourierWorB()
{
    return({
        type:"SUBMIT_ASSIGNING_COURIER_W_OR_B"
    })
}

export function submitAssigningCourierWorBSA()
{
    return({
        type:"SUBMIT_ASSIGNING_COURIER_W_OR_B_SA"
    })
}

export function ResetFormAc()
{
    return({
        type:"RESET_FORM_AC"
    })
}

export function ResetFormAcBranch()
{
    return({
        type:"RESET_FORM_AC_BRANCH"
    })
}

export function ClearWorBAc()
{
    return({
        type:"CLEAR_W_OR_B_AC"
    })
}
export function ClearCheckboxKoliAc()
{
    return({
        type:"CLEAR_CHECKBOX_KOLI_AC"
    })
}
export function ClearKoliOrWeightAc()
{
    return({
        type:"CLEAR_KOLI_OR_WEIGHT_AC"
    })
}
export function ClearFormAc()
{
    return({
        type:"CLEAR_FORM_AC"
    })
}
export function ClearListKoliAc()
{
    return({
        type:"CLEAR_LIST_KOLI_AC"
    })
}
export function ClearKoliAc()
{
    return({
        type:"CLEAR_KOLI_AC"
    })
}