import { Action } from 'redux';

export interface IFetchGeneralListAction extends Action {
  data: object;
}
export interface IChangeLoadingAction extends Action {
  component: string;
}
export function loadingGetData(component: string): IChangeLoadingAction {
  return {
    type: 'LOADING_GET_DATA',
    component,
  };
}
export function fetchCustomerList(data: object): IFetchGeneralListAction {
  return {
    type: 'FETCH_GENERAL_CUSTOMER_LIST',
    data,
  };
}
export function fetchBranchList(data: object): IFetchGeneralListAction {
  return {
    type: 'FETCH_GENERAL_BRANCH_LIST',
    data,
  };
}
export function fetchLogActivityList(data: object): IFetchGeneralListAction {
  return {
    type: 'FETCH_GENERAL_LOG_ACTIVITY_LIST',
    data,
  };
}
export function fetchUserList(data: object): IFetchGeneralListAction {
  return {
    type: 'FETCH_GENERAL_USER_LIST',
    data,
  };
}
export function fetchInternalBranch(data: object): IFetchGeneralListAction {
  return {
    type: 'FETCH_INTERNAL_BRANCH_LIST',
    data,
  };
}
export function getCustomerList() {
  return {
    type: 'GET_GENERAL_CUSTOMER_LIST_REQUESTED',
  };
}
export function getBranchList() {
  return {
    type: 'GET_GENERAL_BRANCH_LIST_REQUESTED',
  };
}
export function getLogActivityList() {
  return {
    type: 'GET_GENERAL_LOG_ACTIVITY_LIST_REQUESTED',
  };
}
export function getUserList() {
  return {
    type: 'GET_USER_LIST_REQUESTED',
  };
}
export function getInternalBranchList() {
  return {
    type: 'GET_INTERNAL_BRANCH_LIST_REQUESTED',
  };
}
