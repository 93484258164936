import * as ActionAuth from '../../../core/action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as Address from '../../action/Address/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_GetCity(action: any) {
  try {
    yield put(Address.setLoadingAddress());
    yield put(Address.setLoadingTypeAddress('selectCity'));
    const state = yield select();
    const idProvince = state.Branch.DetailBranch.Province;
    const token = state.Auth.Token;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Address/city/0/1000?provinceId=${idProvince}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(Address.SetCity(data.data));
    yield put(Address.setLoadingAddress());
    yield put(Address.clearLoadingTypeAddress());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_GetCity() {
  yield takeLatest('GET_CITY', S_GetCity);
}
