import {Action} from 'redux'

const initialState = 
{
    Province:[],
    City:[],
    SubDistrict:[],
    Village:[],
    LoadingAddress:false,
    LoadingTypeAddress:null

}

export interface IAuthAction extends Action<string>
{

}
export interface ISetProvince extends IAuthAction
{
    Province : []
    
}
export interface ISetCity extends IAuthAction
{
    City : []
    
}
export interface ISetSubDistrict extends IAuthAction
{
    SubDistrict : []
    
}
export interface ISetVillage extends IAuthAction
{
    Village : []
    
}
export interface ISetLoadingTypeAddress extends IAuthAction
{
    LoadingTypeAddress : any
    
}

export default function AuthReducer(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "SET_PROVINCE":
            const setprovince = action as ISetProvince
            newState.Province = setprovince.Province
        return {...newState}

        case "CLEAR_PROVINCE":
            newState.Province = []
        return {...newState}

        case "SET_CITY":
            const setcity = action as ISetCity
            newState.City = setcity.City
        return {...newState}

        case "CLEAR_CITY":
            newState.City = []
        return {...newState}

        case "SET_SUBDISTRICT":
            const setsubdistrict = action as ISetSubDistrict
            newState.SubDistrict = setsubdistrict.SubDistrict
            return {...newState}

        case "CLEAR_SUBDISTRICT":
            newState.SubDistrict = []
        return {...newState}

        case "SET_VILLAGE":
            const setvillage = action as ISetVillage
            newState.Village = setvillage.Village
            return {...newState}

        case "CLEAR_VILLAGE":
            newState.Village = []
        return {...newState}

        case "CLEAR_ALL_ADDRESS":
            newState.Province = []
            newState.City = []
            newState.SubDistrict = []
            newState.Village = []
            newState.LoadingTypeAddress = null
            
        return {...newState}

        case "SET_LOADING_ADDRESS":
            newState.LoadingAddress = !newState.LoadingAddress
        return {...newState}

        case "SET_LOADING_TYPE_ADDRESS":
            const setLoadingTypeAddress = action as ISetLoadingTypeAddress
            newState.LoadingTypeAddress = setLoadingTypeAddress.LoadingTypeAddress
        return {...newState}

        case "CLEAR_LOADING_TYPE_ADDRESS":
            newState.LoadingTypeAddress = null
        return {...newState}

    }
    return {...newState}
}