import React, { useState, useEffect } from 'react';
import FormDownloadDeleteComponent from '../Component/FormDownloadDeleteComponent';
import ModalMaterial from 'materials/ModalMaterial';
import {
  downloadBastArchive,
  getSingleBastArchive,
} from 'services/RestoreService';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import ModalStatus from 'materials/ModalStatus';
import { downloadBlob } from 'lib/util';
import moment from 'moment-timezone';

type FormDownloadDeleteContainerProps = {
  showModal: boolean;
  handleHideModal: () => void;
  refetch: () => void;
  selectedData?: any;
};

export default function FormDownloadDeleteContainer({
  showModal,
  handleHideModal,
  selectedData,
}: FormDownloadDeleteContainerProps) {
  const [numberDocument, setNumberDocument] = useState<any>('');
  const [namePic, setNamePic] = useState<any>('');
  const [divitionPic, setDivitionPic] = useState<any>('');
  const [customerName, setCustomerName] = useState<any>('');
  const [typeDownload, setTypeDownload] = useState<any>('Manual');
  const [address, setAddress] = useState<Array<string>>(['']);

  const { data: dataBastArchive, isLoading: isLoadingModal } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['getSingleBastArchive', { id: selectedData?.id }],
    queryFn: ({ queryKey }) => getSingleBastArchive(queryKey),
    enabled: !!selectedData?.id,
  });

  const { mutate: downloadBast, isLoading: isLoadingDownload } = useMutation(
    downloadBastArchive,
    {
      onSuccess: res => {
        if (res.status === 200) {
          const renderDate =
            selectedData?.year ||
            `${moment(selectedData.startDate).format('MMM YYYY')} - ${moment(
              selectedData.endDate
            ).format('MMM YYYY')}`;
          downloadBlob(
            res.data,
            `BAST_HapusData_${selectedData?.customerName}_${renderDate}`
          );
          handleHideModal();
          handleClearForm();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Menjalankan Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    }
  );

  useEffect(() => {
    if (dataBastArchive && dataBastArchive.data) {
      const dataSingleBast = dataBastArchive?.data;
      const address = dataSingleBast.address
        ? Object.values(JSON.parse(dataSingleBast.address)).filter(
            value => value !== ''
          )
        : [''];
      setNumberDocument(dataSingleBast?.documentNumber || '');
      setNamePic(dataSingleBast?.picName || '');
      setDivitionPic(dataSingleBast?.picDepartementOrDivisi || '');
      setCustomerName(dataSingleBast?.customCustomerName || '');
      setAddress(address as Array<string>);
    }
  }, [dataBastArchive]);

  const handleClearForm = () => {
    setNumberDocument('');
    setNamePic('');
    setDivitionPic('');
    setCustomerName('');
    setAddress(['']);
  };

  const handleSubmit = value => {
    const initialAddress = {
      address1: '',
      address2: '',
      address3: '',
    };

    value.address.forEach((_, index) => {
      initialAddress['address' + (index + 1)] = value.address[index];
    });

    const data = {
      id: selectedData.id,
      type: typeDownload,
      documentNumber: value.numberDocument,
      picName: value.namePic,
      picDepartment: value.divitionPic,
      customerName: value.nameCustomer,
      address: JSON.stringify(initialAddress),
    };

    downloadBast(data);
  };

  return (
    <ModalMaterial
      width={'40%'}
      teksTitle={'DOWNLOAD BAST HAPUS DATA'}
      visible={showModal}
      onCancel={handleHideModal}
      content={
        <FormDownloadDeleteComponent
          handleSubmit={handleSubmit}
          isLoadingDownload={isLoadingDownload}
          isLoadingModal={isLoadingModal}
          numberDocument={numberDocument}
          namePic={namePic}
          divitionPic={divitionPic}
          customerName={customerName}
          address={address}
          typeDownload={typeDownload}
          setNumberDocument={(value: any) => setNumberDocument(value)}
          setNamePic={(value: any) => setNamePic(value)}
          setDivitionPic={(value: any) => setDivitionPic(value)}
          setCustomerName={(value: any) => setCustomerName(value)}
          setAddress={(value: Array<string>) => setAddress(value)}
          setTypeDownload={setTypeDownload}
        />
      }
      className="modalProcessPickupAssignCourier"
    />
  );
}
