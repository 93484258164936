import React from 'react';
import { Form, Input } from 'antd';
import { trimText } from 'lib/util';
import cx from 'classnames';

type InputTextFieldProps = {
  error?: any;
  label?: string;
  width?: any;
  value?: any;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  onPressEnter?: (value?: any) => void;
  className?: any;
  styleInput?: any;
  disabled?: boolean;
  addonBefore?: any;
  placeholder?: string;
  maxLength?: number;
};

export default function InputTextField({
  error,
  label,
  width,
  value,
  onChange = () => undefined,
  onBlur = () => undefined,
  onPressEnter = () => undefined,
  className,
  styleInput,
  disabled,
  addonBefore,
  placeholder,
  maxLength,
}: InputTextFieldProps) {
  const handleOnChange = (e?: any) => {
    onChange(trimText(e.target.value) || '');
  };
  return (
    <div style={{ width: '100%' }}>
      {label && (
        <span>
          <b>{label}</b>
        </span>
      )}
      <Form.Item
        validateStatus={error ? 'error' : ''}
        help={error ? error : ''}
        style={{ margin: 0 }}
        className={cx('input-text', className)}
      >
        <Input
          style={{
            borderRadius: 8,
            width: width,
            ...styleInput,
          }}
          value={value}
          onChange={e => handleOnChange(e)}
          onBlur={onBlur}
          type="text"
          disabled={disabled}
          addonBefore={addonBefore}
          placeholder={placeholder}
          className="input-text"
          onPressEnter={onPressEnter}
          maxLength={maxLength}
        />
      </Form.Item>
    </div>
  );
}
