export function getListCustomer()
{
    return({
        type:"GET_LIST_CUSTOMER"
    })
}
export function setListCustomer(listCustomer:any)
{
    return({
        type:"SET_LIST_CUSTOMER",
        listCustomer
    })
}
export function clearListCustomer()
{
    return({
        type:"CLEAR_LIST_CUSTOMER"
    })
}