import * as ActionAuth from '../../../core/action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionPagination from '../../action/Pagination/ActionPagination';
import * as LoadingCustomer from '../../action/Customer/ActionCustomerLoading';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeLoadingFalse,
  changeLoadingTrue,
  changeValueSearch,
} from '../../action/toggle/index';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import { SetTableCustomer } from '../../action/Customer/ActionTableCustomer';
import axios from 'axios';

export function* S_GetDataTableCustomer() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const router = state.router.location.pathname;
  const pagePagination = state.Pagination.PagePagination;
  const sizeDataPagination = state.Pagination.SizeDataPagination;
  try {
    yield put(LoadingCustomer.setLoadingCustomer());
    yield put(LoadingCustomer.setTypeLoadingCustomer('selectCustomer'));
    if (
      router === '/customer' ||
      router === '/manageCustomerAccount' ||
      router === '/manageAccount'
    ) {
      yield put(changeLoadingTrue());
      const { data } = yield call(
        axios.get,
        process.env.REACT_APP_URL +
          `/Customer/${pagePagination}/${sizeDataPagination}`,
        { headers: config }
      );
      yield put(SetTableCustomer(data.data));
      yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
      yield put(changeLoadingFalse());
    } else {
      const { data } = yield call(
        axios.get,
        process.env.REACT_APP_URL + '/Customer/0/10000',
        { headers: config }
      );
      yield put(SetTableCustomer(data.data));
      yield put(LoadingCustomer.clearLoadingCustomer());
    }
  } catch (error) {
    console.log(error.response.status);
    
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
    } else {
      const state = yield select();
      const router = state.router.location.pathname;
      if (router === '/customer' || router === '/manageCustomerAccount') {
        yield put(changeLoadingFalse());
        const propsError = ErrorMassage('List pelanggan');
        const code = propsError.filter((person: any) =>
          person.Code.toString().toLowerCase().includes(error.response.status)
        );
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              {code.map((data: any) => data.TitleMassage)}
            </p>
          ),
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>
                {code.map((data: any) => data.Massage)}
              </p>
            </div>
          ),
          onOk() {},
        });
      }
    }
  }
}

export function* S_searchTableCustomer(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const searchValue = state.Toggle.ValueSearch;
  // const pagePagination = state.Pagination.PagePagination;
  // const sizeDataPagination = state.Pagination.SizeDataPagination;
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/SearchDataCustomer/0/10000?NameOrCode=${searchValue}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(SetTableCustomer(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeValueSearch(''));
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(changeLoadingFalse());
  }
}

export function* SM_GetDataTableCustomer() {
  yield takeLatest('GET_TABLE_CUSTOMER', S_GetDataTableCustomer);
}

export function* SM_SearchTableCustomer() {
  yield takeLatest('SUBMIT_SEARCH_CUSTOMER', S_searchTableCustomer);
}
