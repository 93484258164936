import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import * as ActionGroupManifest from '../../../core/action/Manifest/ScanManifest/ActionGroupManifest';
import * as LastAccess from '../../../core/action/LastAccess/index';
import * as ScanManifest from '../../../core/action/Manifest/ScanManifest/ActionScanManifest';
import * as CustomerManifest from '../../../core/action/Manifest/CustomerManifest/ActionCustomerManifest';
import * as ActionCourierManifest from '../../../core/action/Manifest/CourierManifest/ActionCourierManifest';
import IndexManifestComponent from '../../../components/Content/Manifest/IndexManifestComponent';

interface IProps {
  actionLastAccess: any;
  actionScanManifest: any;
  actionCustomerManifest: any;
  actionCourierManifest: any;
  actionGroupManifest: any;
  Fiture: any;
  roleAccount: any;
  sideMenus: any;
}

class IndexManifestContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleChangeTabs = this.handleChangeTabs.bind(this);
  }

  handleChangeTabs(event: any) {
    this.props.actionScanManifest.clearManifestForm();
    this.props.actionScanManifest.clearPropertiesScanManifest();
    this.props.actionCustomerManifest.clearCustomerManifestForm();
    this.props.actionCustomerManifest.clearTableCustomerManifestForm();
    this.props.actionCustomerManifest.clearListCustomerrManifestForm();
    this.props.actionCourierManifest.clearCourierManifestForm();
    this.props.actionCourierManifest.clearTableCourierManifestForm();
    this.props.actionCourierManifest.clearListCourierManifestForm();

    if (event === '1') {
      this.props.actionLastAccess.changeLastAccessFiture('courierManifest');
    } else if (event === '2') {
      this.props.actionGroupManifest.getGroupList();
      this.props.actionLastAccess.changeLastAccessFiture('scanManifest');
    } else if (event === '3') {
      this.props.actionLastAccess.changeLastAccessFiture('customerManifest');
    }
  }

  render() {
    return (
      <IndexManifestComponent
        roleAccount={this.props.roleAccount}
        lastAccessFiture={this.props.Fiture}
        handleChangeTabs={this.handleChangeTabs}
        sideMenus={this.props.sideMenus}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  Fiture: state.LastAccess.Fiture,
  sideMenus: state.Auth.SideMenus,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
  actionScanManifest: bindActionCreators(ScanManifest, dispatch),
  actionCustomerManifest: bindActionCreators(CustomerManifest, dispatch),
  actionCourierManifest: bindActionCreators(ActionCourierManifest, dispatch),
  actionGroupManifest: bindActionCreators(ActionGroupManifest, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IndexManifestContainer);
