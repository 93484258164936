const PickupValidation = (values, props) => {
  const { branch, courier, pickupDate, waybillPickup, checkboxWaybill,fileCsv } =
    values;
  const errors: any = {};
  if (!branch) {
    errors.branch = 'Posko wajib diisi';
  }
  if (!courier) {
    errors.courier = 'Kurir wajib diisi';
  }
  if (!pickupDate) {
    errors.pickupDate = 'Tanggal Ambil wajib diisi';
  }
  if (!waybillPickup) {
    const identityPickup = !checkboxWaybill ? 'Waybill' : 'Barcode';
    errors.waybillPickup = `${identityPickup} wajib diisi`;
  }
  if (fileCsv) {
    const length = fileCsv.length;
    const extFile = fileCsv.substring(length - 3, length);
    if(extFile!=='csv'){
      errors.fileCsv = 'File harus csv'
    }
  }
  return errors;
};
export default PickupValidation;
