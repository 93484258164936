import instance from 'config/interceptor';

export const getDeliveryStatusScan = async params => {
  try {
    const { cycleDate, productId } = params;
    let link = `/Delivery/statusScan/${cycleDate}/${productId}`;

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getDataDeliveryScan = async params => {
  try {
    const { cycleDate, productId, isScan } = params;
    let link = `/Delivery/listScanOrUnscan/${cycleDate}/${productId}`;
    if (isScan) link = link.concat('/ScanBarcode');
    if (!isScan) link = link.concat('/Uploaded');

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const putAssignDocument = async data => {
  try {
    const isWaybill = data.typeScan === 'waybill';
    let link = `/Delivery`;

    if (isWaybill) link = link.concat('/scanByWaybill');
    if (!isWaybill) link = link.concat('/scanByBarcode');

    return await instance.put(process.env.REACT_APP_URL + link, data);
  } catch (error) {
    return error;
  }
};
