import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import DashboardKpiComponent from 'module/Dashboard/Component/Kpi';
import { SelectOptionsInterface } from 'interface/general';
import {
  getAllCustomers,
  getProductByCustomers,
} from 'services/GeneralService';
import moment from 'moment-timezone';
import { getDashboardKpi } from 'services/DashboardService';
import { TypeDashboardPerformance } from 'lib/constants';
import ModalStatus from 'materials/ModalStatus';

export type DataFilterProps = {
  typeBranch: string | null;
  customerId: number | null;
  selectedDates: [moment.Moment, moment.Moment];
  productIds: number[];
};

export type DataPerformanceProps = {
  branchId: number | null;
  branchName: string;
  hitRate: string;
  inboundDay: string;
  overalSla: string;
  productivity: string;
  returnRate: string;
  successRate: string;
  totalInbound: string;
};

export default function DashboardKpi() {
  const today = moment();
  const firstDayOfMonth = moment(today).startOf('month');

  const [dataPerformance, setDataPerformance] = useState<
    DataPerformanceProps[]
  >([]);

  const [dataFilter, setDataFilter] = useState<DataFilterProps>({
    typeBranch: '0',
    customerId: null,
    selectedDates: [firstDayOfMonth, today],
    productIds: [],
  });
  const [dataCustomers, setDataCustomers] = useState<SelectOptionsInterface[]>(
    []
  );
  const [dataProducs, setDataProducs] = useState<SelectOptionsInterface[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);

  const { isFetching: isLoadingKpi } = useQuery({
    queryKey: ['dataDashboardKpi', dataFilter],
    queryFn: () => getDashboardKpi(dataFilter),
    onSuccess(res) {
      if (res.status === 200) {
        const response = res.data;
        if (response) {
          const data = [] as DataPerformanceProps[];
          data.unshift({
            branchId: null,
            branchName:
              TypeDashboardPerformance.find(
                value => value.id === dataFilter.typeBranch
              )?.name || '',
            totalInbound: response.totalInbound,
            inboundDay: response.inboundDay,
            overalSla: response.overalSla,
            successRate: response.successRate,
            productivity: response.productivity,
            returnRate: response.returnRate,
            hitRate: response.hitRate,
          });
          if (response.branchPerformance.length > 0) {
            response.branchPerformance.forEach(item => {
              data.push({
                branchId: item.branchId,
                branchName: item.branchName,
                totalInbound: item.totalInbound,
                inboundDay: item.inboundDay,
                overalSla: item.overalSla,
                successRate: item.successRate,
                productivity: item.productivity,
                returnRate: item.returnRate,
                hitRate: item.hitRate,
              });
            });
          }
          setDataPerformance(data);
        } else {
          setDataPerformance([]);
        }
      } else {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan!!!',
          content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
        });
        setDataPerformance([]);
      }
    },
    refetchOnWindowFocus: false,
    enabled: !!(dataFilter.selectedDates.length > 1),
  });

  const { isLoading: isLoadingCustomers } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataCustomers'],
    queryFn: () => getAllCustomers(),
    onSuccess(data) {
      const datas = [] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataCustomers(datas);
    },
    refetchOnWindowFocus: false,
  });

  const { isFetching: isLoadingProducts } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataProducts', { id: dataFilter.customerId }],
    queryFn: ({ queryKey }) => getProductByCustomers(queryKey),
    onSuccess(data) {
      const datas = [] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataProducs(datas);
    },
    enabled: !!dataFilter.customerId,
    refetchOnWindowFocus: false,
  });

  const handleChangeFilter = (name: string, value: any) => {
    setDataFilter(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeProducts = value => {
    const selectProduct = [...selectedProducts] as number[];
    if (value) {
      if (selectProduct.includes(value)) {
        setSelectedProducts(selectProduct.filter(product => product !== value));
      } else {
        selectProduct.push(value);
        setSelectedProducts(selectProduct);
      }
    } else {
      setSelectedProducts([]);
    }
  };

  const handleSubmitProduct = () => {
    handleChangeFilter('productIds', selectedProducts);
  };

  return (
    <DashboardKpiComponent
      dataPerformance={dataPerformance}
      dataFilter={dataFilter}
      isLoading={isLoadingKpi}
      isLoadingProducts={isLoadingProducts}
      dataBranch={TypeDashboardPerformance}
      dataCustomers={dataCustomers}
      dataProducs={dataProducs}
      isLoadingCustomers={isLoadingCustomers}
      handleChangeFilter={handleChangeFilter}
      selectedProducts={selectedProducts}
      handleChangeProducts={handleChangeProducts}
      handleSubmitProduct={handleSubmitProduct}
    />
  );
}
