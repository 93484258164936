import * as ActionLastAccess from '../../../core/action/LastAccess';
import * as ActionToggle from '../../../core/action/toggle/index';
import * as FormConfigReport from '../../../core/action/Customer/ActionDetailConfigReport';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
  detailCustomerFormatReport,
  getCustomerDetailCsvList,
} from 'services/CustomerService';

import CustomerDetailConfigReportComponent from '../../../components/Content/Customer/CustomerDetailConfigReportComponent';
import { connect } from 'react-redux';
import { InitialValueFormCustomerFormat } from 'module/Customer/Report/components/FormCustomerFormatReport';
import EditCustomerFormatReport from 'module/Customer/Report/container/EditCustomerFormatReport';

interface IProps {
  actionFormConfigReport: any;
  actionToggle: any;
  actionLastAccess: any;
  GetCustomerName: any;
  GetCustomerNameReport: any;
  GetFormatReport: any;
  GetPeriodeReport: any;
  GetDelimitedReport: any;
  GetSettingReport: any;
  GetSettingReportName: any;
  GetWithoutHeader: any;
  GetShowInPortal: any;
  IdCustomerReport: any;
  IdCustomer: any;
  isLoading: any;
}
const CustomerDetailConfigReportContainer = (props: IProps) => {
  const defaultForm = {
    isActive: false,
    nameFormatReport: '',
    formatFile: '',
    settingFile: '',
    periode: '',
    delimited: '',
    disburseData: '',
    noHeader: false,
    showInPortal: false,
  };
  const [showModalEditId, setShowModalEditId] = useState<number | null>(null);
  const [customerFormatData, setCustomerFormatData] =
    useState<InitialValueFormCustomerFormat>(defaultForm);
  const [dataCsvList, setDataCsvList] = useState<any[]>([]);

  const { isFetching: isLoadingDetail, refetch } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataDetailCustomerConfig', props.IdCustomerReport],
    queryFn: () => detailCustomerFormatReport(props.IdCustomerReport),
    enabled: !!props.IdCustomerReport,
    refetchOnWindowFocus: false,
    onSuccess: res => {
      if (res.data) {
        const response = res.data;
        setCustomerFormatData(prevState => ({
          ...prevState,
          nameFormatReport: response.name,
          formatFile: response.format,
          settingFile: response.customCsvId,
          periode: response.periode,
          delimited: response.delimited,
          noHeader: response.headerHide,
          showInPortal: response.reportPortal,
          isActive: response.isActive,
          disburseData: response.customReportType,
        }));
      }
    },
  });

  const { isFetching: isLoadingCsvList } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataCustomerCsvList', props.IdCustomer],
    queryFn: () => getCustomerDetailCsvList({ IdCustomer: props.IdCustomer }),
    enabled: !!props.IdCustomer,
    refetchOnWindowFocus: false,
    onSuccess: res => {
      if (res?.data.length > 0) {
        setDataCsvList(res.data);
      } else {
        setDataCsvList([]);
      }
    },
  });

  const handleEditConfigReport = () => {
    setShowModalEditId(props.IdCustomerReport);
  };
  const handleCancelEdit = () => {
    setShowModalEditId(null);
  };
  const handleBackConfigReport = () => {
    props.actionLastAccess.changeClearLastAccessFiture();
    props.actionFormConfigReport.changeClearCustomerConfigReport();
  };
  return (
    <div>
      <CustomerDetailConfigReportComponent
        customerFormatData={customerFormatData}
        GetCustomerName={props.GetCustomerName}
        GetCustomerNameReport={props.GetCustomerNameReport}
        GetFormatReport={props.GetFormatReport}
        GetPeriodeReport={props.GetPeriodeReport}
        GetDelimitedReport={props.GetDelimitedReport}
        isLoading={props.isLoading || isLoadingDetail || isLoadingCsvList}
        GetSettingReport={props.GetSettingReport}
        GetSettingReportName={props.GetSettingReportName}
        GetWithoutHeader={props.GetWithoutHeader}
        GetShowInPortal={props.GetShowInPortal}
        handleEditConfigReport={handleEditConfigReport}
        handleBackConfigReport={handleBackConfigReport}
        dataCsvList={dataCsvList}
      />
      <EditCustomerFormatReport
        show={showModalEditId}
        onClickCancel={handleCancelEdit}
        customerName={props.GetCustomerName}
        dataCsvList={dataCsvList}
        customerId={props.IdCustomer}
        refetch={refetch}
      />
    </div>
  );
};

const mapStateProps = (state: any) => ({
  GetCustomerName: state.Customer.DetailCustomer.CustomerName,
  GetCustomerNameReport:
    state.Customer.FormCustomerConfigReport.CustomerNameReport,
  GetFormatReport: state.Customer.FormCustomerConfigReport.FormatReport,
  GetPeriodeReport: state.Customer.FormCustomerConfigReport.PeriodeReport,
  GetDelimitedReport: state.Customer.FormCustomerConfigReport.DelimitedReport,
  GetSettingReport: state.Customer.FormCustomerConfigReport.SettingReport,
  GetSettingReportName:
    state.Customer.FormCustomerConfigReport.SettingReportName,
  GetWithoutHeader: state.Customer.FormCustomerConfigReport.WithoutHeader,
  GetShowInPortal: state.Customer.FormCustomerConfigReport.ShowInPortal,
  IdCustomerReport: state.Customer.FormCustomerConfigReport.IdCustomerReport,
  isLoading: state.Toggle.isLoading,
  IdCustomer: state.Customer.PropertiesCustomer.IdCustomer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionFormConfigReport: bindActionCreators(FormConfigReport, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionLastAccess: bindActionCreators(ActionLastAccess, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(CustomerDetailConfigReportContainer);
