import * as ActionAuth from '../../action/Auth';
import * as ActionDownloadCustomCsvCustomer from '../../../core/action/Customer/ActionDownloadSampleCustomCsv';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionSetCustomCsvCustomer from '../../action/Customer/ActionSetCustomCsvCustomer';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import axios from 'axios';

export function* S_DetailCustomCsvCustomer(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const IdCustomCsv = state.Customer.FormCustomCsvCustomer.IdCsv;
  const typeFormTemplate = state.Customer.FormCustomCsvCustomer.TypeFormCsv;
  try {
    yield put(changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Setting/CustomCsv/' + IdCustomCsv,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(
      ActionSetCustomCsvCustomer.setFileSettingNameCsv(data.nameCustomCsv)
    );
    yield put(
      ActionSetCustomCsvCustomer.setStatusDefaultCsv(data.defaultStatus)
    );
    yield put(
      ActionSetCustomCsvCustomer.setReverseBarcodeCsv(data.barcodeReverse)
    );

    yield put(ActionSetCustomCsvCustomer.changeFileType(data.formatFileType));
    yield put(ActionSetCustomCsvCustomer.setDelimitedCsv(data.delimited));
    yield put(ActionSetCustomCsvCustomer.setCycleCsv(data.cycle));
    yield put(ActionSetCustomCsvCustomer.setFormatCycleCsv(data.formatCycle));
    yield put(ActionSetCustomCsvCustomer.setCityCsv(data.city));
    yield put(ActionSetCustomCsvCustomer.setAddressCsv(data.address));
    yield put(
      ActionSetCustomCsvCustomer.setAdditionalDataCsv(data.additionalData)
    );
    yield put(ActionSetCustomCsvCustomer.setBarcodeCsv(data.barcode));
    yield put(ActionSetCustomCsvCustomer.setOwnerNameCsv(data.sendToName));
    yield put(ActionSetCustomCsvCustomer.setPostalCodeCsv(data.postalCode));
    yield put(ActionSetCustomCsvCustomer.setNoTelpCsv(data.phone));
    yield put(ActionSetCustomCsvCustomer.setCallLinkCsv(data.callLink));
    yield put(
      ActionSetCustomCsvCustomer.setListAddressCsv(data.address.split(';'))
    );
    yield put(
      ActionSetCustomCsvCustomer.setListAddressOffice(
        data?.additionalAddress?.split(';') || []
      )
    );
    yield put(
      ActionSetCustomCsvCustomer.setListAdditionalDataCsv(
        data.additionalData.split(';')
      )
    );
    yield put(ActionSetCustomCsvCustomer.setCustomerNameCsv(data.customerName));
    yield put(ActionSetCustomCsvCustomer.setGroupName(data.groupName));
    yield put(ActionSetCustomCsvCustomer.setSearchTypeCsv(data.specificSearch));
    yield put(ActionSetCustomCsvCustomer.setSearchKeyCsv(data.keySearch));
    yield put(
      ActionSetCustomCsvCustomer.setCheckboxCallCenterCsv(
        data.callCentreSetting
      )
    );
    yield put(ActionSetCustomCsvCustomer.setWaybillCsv(data.callCentreWaybill));
    yield put(
      ActionSetCustomCsvCustomer.setAdrressUpdateCsv(data.callCentreAddress)
    );
    if (typeFormTemplate === 'download') {
      yield put(ActionDownloadCustomCsvCustomer.downloadCustomCsvCustomer());
    }
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_DetailCustomCsvCustomer() {
  yield takeLatest('GET_DETAIL_CUSTOM_CSV_CUSTOMER', S_DetailCustomCsvCustomer);
}
