import React, { CSSProperties } from 'react';

type BadgeTreeProps = {
  label: 'Modul' | 'Submodul' | 'Tab';
  styles?: CSSProperties;
};

const BadgeTree = ({ label = 'Modul', styles }: BadgeTreeProps) => {
  const background =
    label === 'Modul'
      ? '#1890FF'
      : label === 'Submodul'
      ? '#11BEFF'
      : '#FCC642';
  return (
    <div
      style={{
        background,
        padding: '0px 8px',
        borderRadius: 4,
        ...styles,
      }}
    >
      <p style={{ margin: 0, fontSize: 12, color: '#FFFFFF', fontWeight: 500 }}>
        {label}
      </p>
    </div>
  );
};

export default BadgeTree;
