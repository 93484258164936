export function submitAddBranch()
{
    return({
        type:"SUBMIT_ADD_BRANCH"
    })
}
export function addAccountAdmin()
{
    return({
        type:"ADD_ACCOUNT_ADMIN"
    })
}