export function changeSwitchValuePW(SwitchValue: any) {
  return {
    type: 'CHANGE_SWITCH_VALUE_PW',
    SwitchValue,
  };
}
export function changeRadioButtonPW(RadioButton: any) {
  return {
    type: 'CHANGE_RADIO_BUTTON_PW',
    RadioButton,
  };
}
export function changeUploadDatePW(UploadDate: any) {
  return {
    type: 'CHANGE_UPLOAD_DATE_PW',
    UploadDate,
  };
}
export function changeBranchIdPW(BranchId: any) {
  return {
    type: 'CHANGE_BRANCH_ID_PW',
    BranchId,
  };
}
export function changeCourierIdPW(CourierId: any) {
  return {
    type: 'CHANGE_COURIER_ID_PW',
    CourierId,
  };
}
export function changeCustomerIdPW(CustomerId: any) {
  return {
    type: 'CHANGE_CUSTOMER_ID_PW',
    CustomerId,
  };
}
export function changeCustomerProductIdPW(CustomerProductId: any) {
  return {
    type: 'CHANGE_CUSTOMER_PRODUCT_ID_PW',
    CustomerProductId,
  };
}
export function changeWaybillPW(Waybill: any) {
  return {
    type: 'CHANGE_WAYBILL_PW',
    Waybill,
  };
}
export function changeDeliveryDatePW(DeliveryDate: any) {
  return {
    type: 'CHANGE_DELIVERY_DATE_PW',
    DeliveryDate,
  };
}
export function changeFileDataPW(FileData: any) {
  return {
    type: 'CHANGE_FILE_DATA_PW',
    FileData,
  };
}
export function setDataPrintWaybill(DataListPrintWaybill: any) {
  return {
    type: 'SET_LIST_DATA_PW',
    DataListPrintWaybill,
  };
}
export function changeSelectedCheckboxDate(CheckboxDate: any) {
  return {
    type: 'CHANGE_SELECTED_CHECKBOX_DATE_PW',
    CheckboxDate,
  };
}
export function changeRemovedCheckboxDate(CheckboxDate: any) {
  return {
    type: 'CHANGE_REMOVED_CHECKBOX_DATE_PW',
    CheckboxDate,
  };
}
export function changeSelectedCheckboxId(CheckboxId: any) {
  return {
    type: 'CHANGE_SELECTED_CHECKBOX_ID_PW',
    CheckboxId,
  };
}
export function changeRemovedCheckboxId(CheckboxId: any) {
  return {
    type: 'CHANGE_REMOVED_CHECKBOX_ID_PW',
    CheckboxId,
  };
}
export function changeSeparatorAddress(SeparatorAddress: any) {
  return {
    type: 'CHANGE_SEPARATOR_ADDRESS_PW',
    SeparatorAddress,
  };
}
export function changeWatermark(Watermark: any) {
  return {
    type: 'CHANGE_WATERMARK_PW',
    Watermark,
  };
}

export function changeNotelp(NoTelp: any) {
  return {
    type: 'CHANGE_NOTELP_PW',
    NoTelp,
  };
}
export function changeCodeBrankas(CodeBrankas: any) {
  return {
    type: 'CHANGE_CODEBRANKAS_PW',
    CodeBrankas,
  };
}
export function clearDeliveryDate() {
  return {
    type: 'CLEAR_DELIVERY_DATE_PW',
  };
}
export function clearCustomerProduct() {
  return {
    type: 'CLEAR_CUSTOMER_PRODUCT_PW',
  };
}
export function getListDataPrintWaybill() {
  return {
    type: 'GET_DATA_PRINT_WAYBILL',
  };
}
export function submitEditDeliveryDate() {
  return {
    type: 'SUBMIT_DELIVERY_DATE_PW',
  };
}
export function downloadTemplate() {
  return {
    type: 'DOWNLOAD_TEMPLATE',
  };
}
export function downloadByWaybill() {
  return {
    type: 'DOWNLOAD_BY_WAYBILL',
  };
}
export function downloadMultipleWaybill() {
  return {
    type: 'DOWNLOAD_MULTIPLE_WAYBILL',
  };
}
export function downloadSelectWaybill() {
  return {
    type: 'DOWNLOAD_SELECT_WAYBILL',
  };
}
export function clearWaybillPrintType() {
  return {
    type: 'CLEAR_WAYBILL_PRINT_TYPE_PW',
  };
}
export function clearCountCheckbox() {
  return {
    type: 'CLEAR_COUNT_CHECKBOX_PW',
  };
}
export function clearListAndDate() {
  return {
    type: 'CLEAR_LIST_AND_DATE_PW',
  };
}
export function changeCountCheckbox(CountCheckbox: any) {
  return {
    type: 'CHANGE_COUNT_CHECKBOX_PW',
    CountCheckbox,
  };
}
export function changeWaybillPrintType(WaybillPrintType: any) {
  return {
    type: 'CHANGE_WAYBILL_PRINT_TYPE_PW',
    WaybillPrintType,
  };
}
export function ClearAllCheckbox() {
  return {
    type: 'CLEAR_ALL_CHECKBOX_PW',
  };
}
export function ClearListDataPrintWaybill() {
  return {
    type: 'CLEAR_LIST_DATA_PW',
  };
}
export function ClearWaybill() {
  return {
    type: 'CLEAR_WAYBILL_FORM_PW',
  };
}
export function ClearUploadForm() {
  return {
    type: 'CLEAR_UPLOAD_FORM_PW',
  };
}
export function ClearPrintWaybillFormPW() {
  return {
    type: 'CLEAR_PRINT_WAYBILL_FORM',
  };
}
export function clearWatermarkPW() {
  return {
    type: 'CLEAR_WATERMARK_PW',
  };
}
