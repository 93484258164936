// DY: DEV CHANGES 4 JUL 24

import React from 'react';
import { Icon } from 'antd';

export const customExpandIcon = (props: any) => {
  if (props.expanded) {
    return (
      // eslint-disable-next-line
      <p
        style={{ margin: 0, cursor: 'pointer' }}
        onClick={e => {
          props.onExpand(props.panelKey);
        }}
      >
        <Icon type="up" style={{ fontSize: 12 }} />
      </p>
    );
  } else {
    return (
      // eslint-disable-next-line
      <p
        style={{ margin: 0, cursor: 'pointer' }}
        onClick={e => {
          props.onExpand(props.panelKey);
        }}
      >
        <Icon type="down" style={{ fontSize: 12 }} />
      </p>
    );
  }
};

export default function ExpandRowItemCard({
  label,
  datas,
}: {
  label: string;
  datas: any[];
}) {
  return (
    <div style={{ display: 'flex', gap: 40 }}>
      <b>{label} </b>
      <div style={{ display: 'flex', gap: 8 }}>
        :
        <div
          style={{
            display: 'flex',
            gap: 12,
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          {datas.map((item, index) => (
            <div
              style={{
                padding: '4px 8px',
                background: '#FAFAFA',
                borderRadius: 4,
                border: '1px solid #E0E0E0',
              }}
            >
              <p style={{ margin: 0 }} key={index}>
                {item}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
