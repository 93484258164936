import React, { useState } from 'react';
import { Icon, Row, Col, Button as ButtonAntd } from 'antd';
import { Form, Field } from 'react-final-form';

import SpinMaterial from 'materials/SpinMaterial';
import CardMaterial from 'materials/CardMaterial';
import InputNumberField from 'components/InputNumberField';
import InputTextField from 'components/InputTextField';
import { SingleCheckbox } from 'components/SingleCheckbox';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import Button from 'components/Button';

type AdditionalDataProps = {
  isLoading: boolean;
  handleChange: (name: string, value: any) => void;
  handleAssignDocument: () => void;
  initialValues: any;
};

export default function AdditionalData({
  isLoading,
  handleAssignDocument,
  handleChange,
  initialValues,
}: AdditionalDataProps) {
  const [showDistributionContent, setShowDistributionContent] =
    useState<boolean>(false);

  const handleAddKoli = () => {
    const dataInitialKoli = [...initialValues.koli];
    dataInitialKoli.push(undefined);

    handleChange('koli', dataInitialKoli);
  };

  const handleChangeKoli = (index: number, value: any) => {
    const dataInitialKoli = [...initialValues.koli];
    dataInitialKoli[index] = value;

    handleChange('koli', dataInitialKoli);
  };

  const handleDeleteKolli = (index: number) => {
    const dataInitialKoli = [...initialValues.koli];
    dataInitialKoli.splice(index, 1);

    handleChange('koli', dataInitialKoli);
  };

  return (
    <SpinMaterial spinning={isLoading} size="large">
      <CardMaterial
        title={
          <b
            style={{ color: '#11BEFF', cursor: 'pointer' }}
            onClick={() => setShowDistributionContent(!showDistributionContent)}
          >
            DATA TAMBAHAN
          </b>
        }
        extra={
          <Icon
            type={showDistributionContent ? 'up' : 'down'}
            style={{ color: '#11BEFF', cursor: 'pointer' }}
            onClick={() => setShowDistributionContent(!showDistributionContent)}
          />
        }
        style={{ borderRadius: '10px' }}
      >
        {showDistributionContent && (
          <Form
            onSubmit={() => {}}
            initialValues={{ ...initialValues }}
            mutators={{
              ...arrayMutators,
            }}
          >
            {({
              values,
              form: {
                mutators: { push },
              },
            }) => (
              <Row gutter={[24, 24]}>
                <Col span={12}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 16,
                    }}
                  >
                    {!values.trueOrFalseKoli ? (
                      <div style={{ display: 'flex', gap: 24 }}>
                        <Field name="totalWeight">
                          {({ input, meta }) => (
                            <InputNumberField
                              width="100%"
                              isDropdown
                              label="Total berat(kg)"
                              value={input.value}
                              onChange={value => {
                                handleChange('totalWeight', value);
                              }}
                              max={100}
                            />
                          )}
                        </Field>
                        <Field name="trueOrFalseKoli">
                          {({ input, meta }) => (
                            <SingleCheckbox
                              labelRight="Ubah koli"
                              onChange={() => {
                                handleChange('trueOrFalseKoli', !input.value);
                                handleChange('koli', [undefined]);
                              }}
                              input={input}
                              meta={meta}
                              formStyle={{ marginTop: 16 }}
                            />
                          )}
                        </Field>
                      </div>
                    ) : (
                      <FieldArray name="koli">
                        {({ fields }) => (
                          <>
                            {fields.map((field, index) => (
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  gap: 24,
                                }}
                              >
                                <Field name={field} key={index}>
                                  {({ input, meta }) => (
                                    <InputNumberField
                                      width="100%"
                                      isDropdown
                                      label={`Koli-${index + 1} (Kg)`}
                                      value={input.value}
                                      onChange={value => {
                                        handleChangeKoli(index, value);
                                      }}
                                      max={100}
                                      placeholder="Masukkan berat total"
                                    />
                                  )}
                                </Field>
                                {index === 0 ? (
                                  <Field name="trueOrFalseKoli">
                                    {({ input, meta }) => (
                                      <SingleCheckbox
                                        labelRight="Ubah koli"
                                        onChange={() => {
                                          handleChange(
                                            'trueOrFalseKoli',
                                            !input.value
                                          );
                                          handleChange('koli', []);
                                        }}
                                        input={input}
                                        meta={meta}
                                        formStyle={{ marginTop: 16 }}
                                      />
                                    )}
                                  </Field>
                                ) : (
                                  <div style={{ marginTop: 22, width: 120 }}>
                                    <Button
                                      icon="delete"
                                      variant="danger"
                                      onClick={() => handleDeleteKolli(index)}
                                    />
                                  </div>
                                )}
                              </div>
                            ))}
                            <ButtonAntd
                              type={'dashed'}
                              size={'default'}
                              icon={'plus'}
                              style={{
                                width: '100%',
                                color: '#31E116',
                                borderRadius: '12px',
                              }}
                              disabled={fields?.value?.length === 10}
                              onClick={() =>
                                fields &&
                                fields?.value?.length < 10 &&
                                handleAddKoli()
                              }
                            >
                              <span style={{ color: 'black' }}>
                                Tambahkan Koli
                              </span>
                            </ButtonAntd>
                          </>
                        )}
                      </FieldArray>
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <Field name="noResi">
                    {({ input, meta }) => (
                      <InputTextField
                        label="Nomor Resi"
                        placeholder="Masukkan Nomor Resi"
                        maxLength={40}
                        value={input.value}
                        onChange={value => {
                          handleChange('noResi', value);
                        }}
                        onBlur={value => {
                          input.onBlur(value);
                        }}
                        onPressEnter={handleAssignDocument}
                        error={meta.error && meta.touched && meta.error}
                        // disabled={!values.scan}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </CardMaterial>
    </SpinMaterial>
  );
}
