import * as ActionCourierTable from 'core/action/ManageAccount/Courier/ActionTableCourier';
import * as ActionFormResend from 'core/action/Attempt/ActionFormResend';
import * as ActionListBranch from 'core/action/Branch/ActionAllBranch';
import * as ActionListResendSubmit from 'core/action/Attempt/ActionListResendSubmit';
import * as ActionSubmitResend from 'core/action/Attempt/ActionAddResend';

import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { useMutation } from '@tanstack/react-query';
import { downloadExcelAttempt } from 'services/AttemptService';
import { downloadBlob, getBase64 } from 'lib/util';
import ModalStatus from 'materials/ModalStatus';
import { downloadResultResponse } from 'services/HolidayServices';

import ResendComponent from 'module/Attempt/Component/ResendComponent';
import { connect } from 'react-redux';
import moment from 'moment';

interface IProps {
  roleAccount: any;
  actionFormResend: any;
  actionSubmitResend: any;
  actionListResendSubmit: any;
  actionListBranch: any;
  actionCourierTable: any;
  IdBranch: any;
  getResendBranch: any;
  getResendCourier: any;
  getResendDeliveryDate: any;
  getResendWaybill: any;
  getResendVendor: any;
  getListResend: any;
  listAllBranch: any;
  listCourier: any;
  isLoading: any;
  LoadingSelectBranch: any;
  TypeLoadingSelectBranch: any;
  LoadingSelectCourier: any;
  TypeLoadingSelectCourier: any;
  getIsChangeCourier: any;
  getIsScan: any;
  getIsSuccess: any;
  getExcelName: any;
  getResendCounting: any;
  ResultListCsv: any;
}

function ResendContainer({
  roleAccount,
  actionFormResend,
  actionSubmitResend,
  actionListBranch,
  actionCourierTable,
  IdBranch,
  getResendBranch,
  getResendCourier,
  getResendDeliveryDate,
  getResendWaybill,
  getResendVendor,
  listAllBranch,
  listCourier,
  isLoading,
  LoadingSelectBranch,
  TypeLoadingSelectBranch,
  LoadingSelectCourier,
  TypeLoadingSelectCourier,
  getIsChangeCourier,
  getIsScan,
  getIsSuccess,
  getExcelName,
  getResendCounting,
  ResultListCsv,
}: IProps) {
  useEffect(() => {
    if (roleAccount === 'Branch') {
      actionListBranch.getAllBranch();
      actionFormResend.setResendBranch(IdBranch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [validateResendBranch, setValidateResendBranch]: any = useState(null);
  const [validateResendCourier, setValidateResendCourier]: any = useState(null);
  const [validateResendDeliveryDate, setValidateResendDeliveryDate]: any =
    useState(null);
  const [validateResendFile, setValidateResendFile]: any = useState(null);
  const [validateResendWaybill, setValidateResendWaybill]: any = useState(null);
  const [validateDate, setValidateDate]: any = useState(null);
  const [valueResendDate, setValueResendDate]: any = useState(null);

  const { mutate: mutateDownloadFile, isLoading: isLoadingDownload } =
    useMutation(downloadExcelAttempt, {
      onSuccess: res => {
        if (res.status === 200) {
          downloadBlob(res.data, 'Unggah Attempt', 'xlsx');
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });

  const { mutate: mutateDownloadHoliday, isLoading: isLoadingDownloadResult } =
    useMutation(downloadResultResponse, {
      onSuccess: res => {
        if (res.status === 200) {
          downloadBlob(
            res.data,
            `gagal attempt ${moment().format('yyyyMMDDhhmmss')}`,
            'txt'
          );
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });

  function handleChangeResendBranch(event: any) {
    actionFormResend.setResendBranch(event);
    if (event === null) {
      setValidateResendBranch('error');
    } else {
      setValidateResendBranch(null);
    }
  }
  function handleChangeResendCourier(event: any) {
    actionFormResend.setResendCourier(event);
    if (event === null) {
      setValidateResendCourier('error');
    } else {
      setValidateResendCourier(null);
    }
  }
  function handleChangeResendDateDelivery(event: any) {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      actionFormResend.setResendDeliveryDate(dateValue);
    } else {
      actionFormResend.setResendDeliveryDate(null);
    }
    setValueResendDate(event);

    if (event === '' || event === null) {
      setValidateResendDeliveryDate('error');
    } else {
      setValidateResendDeliveryDate(null);
    }
  }
  function handleChangeResendWaybill(event: any) {
    actionFormResend.setResendWaybill(event.target.value);
    if (event.target.value === null || event.target.value === '') {
      setValidateResendWaybill('error');
    } else {
      setValidateResendWaybill(null);
    }
  }
  function handleChangeResendVendor() {
    actionFormResend.setResendVendor();
  }
  function handleGetAllBranch() {
    actionListBranch.getAllBranch();
  }
  function handleGetCourier() {
    actionCourierTable.getCourierTableBranch();
  }
  function handleBlurResendBranch() {
    if (getResendBranch === null) {
      setValidateResendBranch('error');
    } else {
      setValidateResendBranch(null);
    }
  }
  function handleBlurResendCourier() {
    if (getResendCourier === null) {
      setValidateResendCourier('error');
    } else {
      setValidateResendCourier(null);
    }
  }
  function handleValidateResendDateDelivery(event: any) {
    setValidateDate(event);
    if (validateDate === true) {
      if (
        getResendDeliveryDate === null ||
        getResendDeliveryDate === undefined ||
        getResendDeliveryDate === 'Invalid date'
      ) {
        setValidateResendDeliveryDate('error');
      } else {
        setValidateResendDeliveryDate(null);
      }
    } else {
    }
  }
  function handleBlurResendWaybill() {
    if (getResendWaybill === null || getResendWaybill === '') {
      setValidateResendWaybill('error');
    } else {
      setValidateResendWaybill(null);
    }
  }

  const handleChangeFile = e => {
    if (!e) {
      setValidateResendFile('error');
    } else {
      setValidateResendFile(null);
      const files = e.file.originFileObj;
      const ResendExcelName = files;
      getBase64(files)
        .then((result: any) => {
          const ResendExcelBase64 = result.split(',')[1];
          actionFormResend.setResendFileExcel({
            ResendExcelBase64,
            ResendExcelName,
          });
        })
        .catch(error => {
          console.log(error, 'DY: error when change file');
        });
    }
  };

  // async componentWillMount() {
  //   if (roleAccount === 'Branch') {
  //     await actionListBranch.getAllBranch();
  //     await actionFormResend.setResendBranch(IdBranch);
  //   }
  // }

  function handleResetResendBranch() {
    actionFormResend.clearResendCourier();
    actionCourierTable.clearCourierTable();
  }
  function handleResendSubmit() {
    actionSubmitResend.SubmitResend();
  }
  function handleCheckBoxCourier() {
    actionFormResend.setResendIsChangeCourier();
  }
  function handleScan() {
    actionFormResend.setResendIsScan();
  }
  function handleSetStatusSuccess() {
    actionFormResend.setResendIsSuccess();
    actionCourierTable.clearCourierTable();
  }

  const handleDownloadResultCsv = () => {
    mutateDownloadHoliday({ listResult: ResultListCsv });
  };

  return (
    <div>
      <ResendComponent
        loadingSelectBranch={
          LoadingSelectBranch === true &&
          TypeLoadingSelectBranch === 'selectbranch'
            ? true
            : false
        }
        loadingSelectCourier={
          LoadingSelectCourier === true &&
          TypeLoadingSelectCourier === 'selectCourier'
            ? true
            : false
        }
        isLoading={isLoading}
        roleAccount={roleAccount}
        getResendBranch={getResendBranch}
        getResendCourier={getResendCourier}
        getResendDeliveryDate={getResendDeliveryDate}
        getResendWaybill={getResendWaybill}
        getResendVendor={getResendVendor}
        listAllBranch={listAllBranch}
        listCourier={listCourier}
        validateResendBranch={
          getIsChangeCourier && validateResendBranch === 'error' ? 'error' : ''
        }
        validateResendCourier={
          getIsChangeCourier && validateResendCourier === 'error' ? 'error' : ''
        }
        validateResendDeliveryDate={
          validateResendDeliveryDate === 'error' ? 'error' : ''
        }
        validateResendWaybill={validateResendWaybill === 'error' ? 'error' : ''}
        helpValidateResendBranch={
          getIsChangeCourier && validateResendBranch === 'error'
            ? 'Posko wajib diisi'
            : ''
        }
        helpValidateResendCourier={
          getIsChangeCourier && validateResendCourier === 'error'
            ? 'Kurir wajib diisi'
            : ''
        }
        helpValidateResendDeliveryDate={
          validateResendDeliveryDate === 'error' ? 'Tanggal wajib diisi' : ''
        }
        helpValidateResendWaybill={
          validateResendWaybill === 'error' ? 'Waybill wajib diisi' : ''
        }
        handleChangeResendBranch={handleChangeResendBranch}
        handleChangeResendCourier={handleChangeResendCourier}
        handleChangeResendDateDelivery={handleChangeResendDateDelivery}
        handleChangeResendWaybill={handleChangeResendWaybill}
        handleChangeResendVendor={handleChangeResendVendor}
        handleBlurResendBranch={handleBlurResendBranch}
        handleBlurResendCourier={handleBlurResendCourier}
        handleValidateResendDateDelivery={handleValidateResendDateDelivery}
        handleBlurResendWaybill={handleBlurResendWaybill}
        handleGetAllBranch={handleGetAllBranch}
        handleGetCourier={handleGetCourier}
        handleResetResendBranch={handleResetResendBranch}
        handleResendSubmit={handleResendSubmit}
        valueResendDate={valueResendDate}
        isChangeCourier={getIsChangeCourier}
        isScan={getIsScan}
        handleCheckBoxCourier={handleCheckBoxCourier}
        handleScan={handleScan}
        getIsSuccess={getIsSuccess}
        handleSetStatusSuccess={handleSetStatusSuccess}
        handleChangeFile={handleChangeFile}
        validateResendFile={validateResendFile}
        mutateDownloadFile={mutateDownloadFile}
        isLoadingDownload={isLoadingDownload}
        getExcelName={getExcelName}
        getResendCounting={getResendCounting}
        isLoadingDownloadResult={isLoadingDownloadResult}
        handleDownloadResultCsv={handleDownloadResultCsv}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  IdBranch: state.Auth.BranchAuth,
  getResendBranch: state.Resend.FormResendDelivery.ResendBranch,
  getResendCourier: state.Resend.FormResendDelivery.ResendCourier,
  getResendDeliveryDate: state.Resend.FormResendDelivery.ResendDeliveryDate,
  getResendWaybill: state.Resend.FormResendDelivery.ResendWaybill,
  getResendVendor: state.Resend.FormResendDelivery.ResendVendor,
  getIsChangeCourier: state.Resend.FormResendDelivery.isChangeCourier,
  getIsScan: state.Resend.FormResendDelivery.isScan,
  getExcelName: state.Resend.FormResendDelivery.ResendExcelName,
  getExcelBase64: state.Resend.FormResendDelivery.ResendExcelBase64,
  getIsSuccess: state.Resend.FormResendDelivery.isSuccess,
  getResendCounting: state.Resend.FormResendDelivery.ResendCounting,
  ResultListCsv: state.Resend.FormResendDelivery.ResultListCsv,
  getListResend: state.Resend.ListSubmitResendDelivery,
  listAllBranch: state.Branch.AllBranch,
  listCourier: state.Table.TableCourier,
  isLoading: state.Toggle.isLoading,

  LoadingSelectBranch: state.Branch.PropertiesBranch.LoadingBranch,
  TypeLoadingSelectBranch: state.Branch.PropertiesBranch.TypeLoadingBranch,

  LoadingSelectCourier: state.Courier.PropertiesCourier.LoadingCourier,
  TypeLoadingSelectCourier: state.Courier.PropertiesCourier.TypeLoadingCourier,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionFormResend: bindActionCreators(ActionFormResend, dispatch),
  actionSubmitResend: bindActionCreators(ActionSubmitResend, dispatch),
  actionListResendSubmit: bindActionCreators(ActionListResendSubmit, dispatch),
  actionListBranch: bindActionCreators(ActionListBranch, dispatch),
  actionCourierTable: bindActionCreators(ActionCourierTable, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ResendContainer);
