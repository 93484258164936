import * as ActionAddBranch from 'core/action/Branch/ActionAddBranch';
import * as ActionAddress from 'core/action/Address/index';
import * as ActionAdminBranchAll from 'core/action/ManageAccount/AdminBranch/ActionAllAdminBranch';
import * as ActionBranchTracking from 'core/action/Maps/BranchTracking/ActionBranchTracking';
import * as ActionDetailBranch from 'core/action/Branch/ActionDetailBranch';
import * as ActionEditBranch from 'core/action/Branch/ActionEditBranch';
import * as ActionFieldBranch from 'core/action/Branch/ActionFieldBranch';
import * as ActionToggle from 'core/action/toggle/index';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import FormBranchComponent from 'module/Branch/Component/FormBranchComponent';
import { Modal } from 'antd';
import { connect } from 'react-redux';

interface IProps {
  visible: any;
  typeToggle: any;
  isLoading: any;
  LoadingAddress: any;
  LoadingTypeAddress: any;
  LoadingSelectAdmin: any;
  LoadingTypeSelectAdmin: any;
  actionToggle: any;
  actionFieldBranch: any;
  actionDetailBranch: any;
  actionAddress: any;
  actionAdminBranchAll: any;
  actionAddBranch: any;
  actionEditBranch: any;
  actionBranchTracking: any;
  getNameBranch: any;
  getAreaCode: any;
  getPhoneNumber: any;
  getAddress: any;
  getSelectAdminBranch: any;
  getAdminBranch: any;
  getIdAdminBranch: any;
  getProvince: any;
  getCity: any;
  getSubDistrict: any;
  getVillage: any;
  getPostalCode: any;
  getLatitudeBranch: any;
  getLongitudeBranch: any;
  getPartner: any;
  getNameAdminBranch: any;
  getListGroupName: any;
  getGroupNameBranch: any;
  EditBranch: any;
  listProvince: any;
  listCity: any;
  listSubDistrict: any;
  listVillage: any;
  listAdminBranch: any;
}

function FormBranchContainer(props: IProps) {
  const {
    visible,
    typeToggle,
    isLoading,
    LoadingAddress,
    LoadingTypeAddress,
    LoadingSelectAdmin,
    LoadingTypeSelectAdmin,
    actionToggle,
    actionFieldBranch,
    actionDetailBranch,
    actionAddress,
    actionAdminBranchAll,
    actionAddBranch,
    actionEditBranch,
    actionBranchTracking,
    getNameBranch,
    getAreaCode,
    getPhoneNumber,
    getAddress,
    getSelectAdminBranch,
    getAdminBranch,
    getIdAdminBranch,
    getProvince,
    getCity,
    getSubDistrict,
    getVillage,
    getPostalCode,
    getLatitudeBranch,
    getLongitudeBranch,
    getPartner,
    getNameAdminBranch,
    getListGroupName,
    getGroupNameBranch,
    EditBranch,
    listProvince,
    listCity,
    listSubDistrict,
    listVillage,
    listAdminBranch,
  } = props;

  const [ValidateNameBranch, setValidateNameBranch]: any = useState(null);
  const [ValidateAreaCode, setValidateAreaCode]: any = useState(null);
  const [ValidatePhoneNumber, setValidatePhoneNumber]: any = useState(null);
  const [ValidateAddress, setValidateAddress]: any = useState(null);
  const [ValidateAdminBranch, setValidateAdminBranch]: any = useState(null);
  const [ValidateProvince, setValidateProvince]: any = useState(null);
  const [ValidateCity, setValidateCity]: any = useState(null);
  const [ValidateSubDistrict, setValidateSubDistrict]: any = useState(null);
  const [ValidateVillage, setValidateVillage]: any = useState(null);
  const [ModalLocationBranch, setModalLocationBranch]: any = useState(false);

  // const getLocation = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(getCoordinates);
  //   } else {
  //     alert('Geolocation tidak bekerja di browser ini.');
  //   }
  // };
  const getCoordinates = (position: any) => {
    actionBranchTracking.setBranchLat(position.coords.latitude);
    actionBranchTracking.setBranchLng(position.coords.longitude);
  };
  function handleModalGroup() {
    actionFieldBranch.getGroupNameList();
    actionToggle.changeJenisToggle2('addBranchGroup');
    actionToggle.changeModal2();
  }
  function handleGetGroupName() {
    actionFieldBranch.getGroupNameList();
  }
  function handlePutGroup(event: any) {
    actionFieldBranch.changeGroupNameBranch(event);
  }
  function handleModalLocationBranch() {
    setModalLocationBranch(!ModalLocationBranch);
    if (ModalLocationBranch === true) {
      actionBranchTracking.clearAllBranchTracking();
    } else {
      if (getLatitudeBranch === null || getLongitudeBranch === null) {
        navigator.geolocation.getCurrentPosition(getCoordinates);
      } else if (getLatitudeBranch !== null || getLongitudeBranch !== null) {
        actionBranchTracking.setBranchLat(getLatitudeBranch);
        actionBranchTracking.setBranchLng(getLongitudeBranch);
      }
    }
  }
  function handlePutNameBranch(event: any) {
    actionFieldBranch.setNameBranch(event.target.value);
    if (event.target.value === '') {
      setValidateNameBranch('error');
    } else {
      setValidateNameBranch(null);
    }
  }

  function handlePutAreaCode(event: any) {
    actionFieldBranch.setAreaCode(event.target.value.toUpperCase());
    const RegexMinChar = /^.{3,}$/;
    const RegexHuruf = /^[a-zA-Z]*$/;
    if (event.target.value === '') {
      setValidateAreaCode('error');
    } else if (!RegexHuruf.test(event.target.value)) {
      setValidateAreaCode('huruf');
    } else if (!RegexMinChar.test(event.target.value)) {
      setValidateAreaCode('minchar');
    } else {
      setValidateAreaCode('');
    }
  }

  function handlePutPhoneNumber(event: any) {
    const number = parseInt(event.target.value || 1, 10);
    const regex = /^[0-9\b]+$/;

    if (isNaN(number)) {
      return;
    }

    if (!('value' in props)) {
      actionFieldBranch.setPhoneNumber(event.target.value.replace(/^0+/, ''));

      if (event.target.value === '') {
        setValidatePhoneNumber('error');
      } else if (!regex.test(event.target.value)) {
        setValidatePhoneNumber('nonumeric');
      } else {
        setValidatePhoneNumber('');
      }
    }
  }

  function handlePutAddress(event: any) {
    actionFieldBranch.setAddress(event.target.value);
    if (event.target.value === '') {
      setValidateAddress('error');
    } else {
      setValidateAddress('');
    }
  }

  function handlePutSelectAdmin() {
    actionFieldBranch.selectAdminBranch();

    actionFieldBranch.clearAdminBranch();
    actionFieldBranch.clearNameAdminBranch();
    actionAdminBranchAll.clearAllAdminBranch();
    setValidateAdminBranch('');
  }

  function handlePutAdminBranch(event: any) {
    actionFieldBranch.setAdminBranch(event);
  }

  function handlePutProvince(event: any) {
    actionFieldBranch.setBranchProvince(event);
  }

  function handlePutCity(event: any) {
    actionFieldBranch.setBranchCity(event);
  }
  function handlePutSubDistrict(event: any) {
    actionFieldBranch.setBranchSubDistrict(event);
  }

  function handlePutVillage(event: any) {
    actionFieldBranch.setBranchVillage(event);
    actionAddress.GetDetailVillage();
  }
  function handlePutSelectPartner(event: any) {
    actionFieldBranch.setPartner();
    if (event.target.checked === true) {
      actionFieldBranch.clearCheckboxChooseAdmin();
      actionFieldBranch.clearAdminBranch();
      actionFieldBranch.clearNameAdminBranch();
    }
  }
  function handleGetProvince() {
    actionAddress.GetProvince();
  }

  function handleGetCity() {
    actionAddress.GetCity();
  }

  function handleGetSubDistrict() {
    actionAddress.GetSubDistrict();
  }

  function handleGetVillage() {
    actionAddress.GetVillage();
  }

  function handleGetAdminBranch() {
    actionFieldBranch.getSelectAdminBranch();
  }

  function handleResetProvince() {
    actionAddress.ClearCity();
    actionAddress.ClearSubDistrict();
    actionAddress.ClearVillage();
    actionFieldBranch.clearPostalCode();
    actionFieldBranch.clearBranchCity();
    actionFieldBranch.clearBranchSubDistrict();
    actionFieldBranch.clearBranchVillage();
    setValidateProvince(null);
    setValidateCity(null);
    setValidateSubDistrict(null);
    setValidateVillage(null);
  }
  function handleResetCity() {
    actionAddress.ClearSubDistrict();
    actionAddress.ClearVillage();
    actionFieldBranch.clearPostalCode();
    actionFieldBranch.clearBranchSubDistrict();
    actionFieldBranch.clearBranchVillage();
    setValidateCity(null);
    setValidateSubDistrict(null);
    setValidateVillage(null);
  }

  function handleResetSubDistrict() {
    actionFieldBranch.clearBranchVillage();
    actionAddress.ClearVillage();
    actionFieldBranch.clearPostalCode();
    setValidateSubDistrict(null);
    setValidateVillage(null);
  }

  function handleResetVillage() {
    setValidateVillage(null);
  }
  function handleResetAdminBranch(event: any) {
    setValidateAdminBranch(null);
    actionFieldBranch.setAdminBranch(event);
  }

  function handleBlurProvince() {
    if (getProvince === null) {
      setValidateProvince('error');
    } else {
      setValidateProvince(null);
    }
  }

  function handleBlurCity() {
    if (getCity === null) {
      setValidateCity('error');
    } else {
      setValidateCity(null);
    }
  }

  function handleBlurSubDistrict() {
    if (getSubDistrict === null) {
      setValidateSubDistrict('error');
    } else {
      setValidateSubDistrict(null);
    }
  }

  function handleBlurVillage() {
    if (getVillage === null) {
      setValidateVillage('error');
    } else {
      setValidateVillage(null);
    }
  }

  function handleBlurAdminBranch() {
    if (getAdminBranch === null) {
      setValidateAdminBranch('error');
    } else {
      setValidateAdminBranch(null);
    }
  }

  function handleCloseModal() {
    actionToggle.changeModal();
    actionToggle.clearJenisToggle();
    if (EditBranch === true) {
      actionFieldBranch.clearAllFieldBranch();
      actionDetailBranch.changeDetailBranch();
    } else {
      actionFieldBranch.clearAllFieldBranch();
    }
    actionAdminBranchAll.clearAllAdminBranch();
    actionAddress.ClearAllAddress();
    setValidateNameBranch(null);
    setValidateAreaCode(null);
    setValidatePhoneNumber(null);
    setValidateAddress(null);
    setValidateProvince(null);
    setValidateCity(null);
    setValidateSubDistrict(null);
    setValidateVillage(null);
    setValidateAdminBranch(null);
  }
  function handleAddBranch() {
    if (getLatitudeBranch === null || getLongitudeBranch === null) {
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>Lokasi posko tidak boleh kosong!!!</p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              Silakan pilih lokasi posko terlebih dahulu
            </p>
          </div>
        ),
        onOk() {},
      });
    } else {
      actionAddBranch.submitAddBranch();
    }
  }
  function handleEditBranch() {
    if (getLatitudeBranch === null || getLongitudeBranch === null) {
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>Lokasi posko tidak boleh kosong!!!</p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              Silakan pilih lokasi posko terlebih dahulu
            </p>
          </div>
        ),
        onOk() {},
      });
    } else {
      actionEditBranch.EditBranch();
    }
  }
  return (
    <div>
      <FormBranchComponent
        visible={visible}
        typeToggle={typeToggle}
        isLoading={isLoading}
        LoadingAddress={LoadingAddress}
        LoadingTypeAddress={LoadingTypeAddress}
        LoadingSelectAdmin={LoadingSelectAdmin}
        LoadingTypeSelectAdmin={LoadingTypeSelectAdmin}
        modalLocationBranch={ModalLocationBranch}
        getNameBranch={getNameBranch}
        getAreaCode={getAreaCode}
        getPhoneNumber={getPhoneNumber}
        getAddress={getAddress}
        getSelectAdminBranch={getSelectAdminBranch}
        getAdminBranch={getAdminBranch}
        getProvince={getProvince}
        getCity={getCity}
        getSubDistrict={getSubDistrict}
        getVillage={getVillage}
        getPostalCode={getPostalCode}
        getIdAdminBranch={getIdAdminBranch}
        getNameAdminBranch={getNameAdminBranch}
        getLatitudeBranch={getLatitudeBranch}
        getLongitudeBranch={getLongitudeBranch}
        getPartner={getPartner}
        getGroupNameBranch={getGroupNameBranch}
        listProvince={listProvince}
        listCity={listCity}
        listSubDistrict={listSubDistrict}
        listVillage={listVillage}
        listAdminBranch={listAdminBranch}
        getListGroupName={getListGroupName}
        handleModalLocationBranch={handleModalLocationBranch}
        handleCloseModal={handleCloseModal}
        handleGetProvince={handleGetProvince}
        handleGetCity={handleGetCity}
        handleGetSubDistrict={handleGetSubDistrict}
        handleGetVillage={handleGetVillage}
        handleGetAdminBranch={handleGetAdminBranch}
        handlePutNameBranch={handlePutNameBranch}
        handlePutAreaCode={handlePutAreaCode}
        handlePutPhoneNumber={handlePutPhoneNumber}
        handlePutAddress={handlePutAddress}
        handlePutSelectAdmin={handlePutSelectAdmin}
        handlePutSelectPartner={handlePutSelectPartner}
        handlePutProvince={handlePutProvince}
        handlePutCity={handlePutCity}
        handlePutSubDistrict={handlePutSubDistrict}
        handlePutVillage={handlePutVillage}
        handlePutAdminBranch={handlePutAdminBranch}
        handleResetProvince={handleResetProvince}
        handleResetCity={handleResetCity}
        handleResetSubDistrict={handleResetSubDistrict}
        handleResetVillage={handleResetVillage}
        handleResetAdminBranch={handleResetAdminBranch}
        handleBlurProvince={handleBlurProvince}
        handleBlurCity={handleBlurCity}
        handleBlurSubDistrict={handleBlurSubDistrict}
        handleBlurVillage={handleBlurVillage}
        handleBlurAdminBranch={handleBlurAdminBranch}
        handleAddBranch={handleAddBranch}
        handleEditBranch={handleEditBranch}
        handleModalGroup={handleModalGroup}
        handleGetGroupName={handleGetGroupName}
        handlePutGroup={handlePutGroup}
        ValidateNameBranch={ValidateNameBranch}
        ValidateAreaCode={ValidateAreaCode}
        ValidatePhoneNumber={ValidatePhoneNumber}
        ValidateAddress={ValidateAddress}
        ValidateProvince={ValidateProvince}
        ValidateCity={ValidateCity}
        ValidateSubDistrict={ValidateSubDistrict}
        ValidateVillage={ValidateVillage}
        ValidateAdminBranch={ValidateAdminBranch}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  typeToggle: state.Toggle.jenisToggle,
  isLoading: state.Toggle.isLoading,
  LoadingSelectAdmin:
    state.AdminBranch.PropertiesAdminBranch.LoadingAdminBranch,
  LoadingTypeSelectAdmin:
    state.AdminBranch.PropertiesAdminBranch.LoadingTypeAdminBranch,
  LoadingAddress: state.Address.LoadingAddress,
  LoadingTypeAddress: state.Address.LoadingTypeAddress,
  getGroupNameBranch: state.Branch.DetailBranch.GroupNameBranch,
  getNameBranch: state.Branch.DetailBranch.NameBranch,
  getAreaCode: state.Branch.DetailBranch.AreaCode,
  getPhoneNumber: state.Branch.DetailBranch.PhoneNumber,
  getAddress: state.Branch.DetailBranch.Address,
  getSelectAdminBranch: state.Branch.DetailBranch.SelectAdmin,
  getAdminBranch: state.Branch.DetailBranch.AdminBranch,
  getProvince: state.Branch.DetailBranch.Province,
  getCity: state.Branch.DetailBranch.City,
  getSubDistrict: state.Branch.DetailBranch.SubDistrict,
  getVillage: state.Branch.DetailBranch.Village,
  getPostalCode: state.Branch.DetailBranch.PostalCode,
  getIdAdminBranch: state.Branch.PropertiesBranch.idAdminBranch,
  getNameAdminBranch: state.Branch.DetailBranch.NameAdminBranch,
  getLatitudeBranch: state.Branch.DetailBranch.LatitudeBranch,
  getLongitudeBranch: state.Branch.DetailBranch.LongitudeBranch,
  getPartner: state.Branch.DetailBranch.Partner,
  EditBranch: state.Branch.PropertiesBranch.EditBranch,
  listProvince: state.Address.Province,
  listCity: state.Address.City,
  listSubDistrict: state.Address.SubDistrict,
  listVillage: state.Address.Village,
  listAdminBranch: state.AdminBranch.AdminBranchAll,
  getListGroupName: state.Branch.ListGroupName,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionFieldBranch: bindActionCreators(ActionFieldBranch, dispatch),
  actionAddress: bindActionCreators(ActionAddress, dispatch),
  actionAdminBranchAll: bindActionCreators(ActionAdminBranchAll, dispatch),
  actionDetailBranch: bindActionCreators(ActionDetailBranch, dispatch),
  actionAddBranch: bindActionCreators(ActionAddBranch, dispatch),
  actionEditBranch: bindActionCreators(ActionEditBranch, dispatch),
  actionBranchTracking: bindActionCreators(ActionBranchTracking, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormBranchContainer);
