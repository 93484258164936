export function setResendBranch(ResendBranch: any) {
  return {
    type: 'SET_RESEND_BRANCH',
    ResendBranch,
  };
}
export function setResendCourier(ResendCourier: any) {
  return {
    type: 'SET_RESEND_COURIER',
    ResendCourier,
  };
}
export function setResendDeliveryDate(ResendDeliveryDate: any) {
  return {
    type: 'SET_RESEND_DELIVERY_DATE',
    ResendDeliveryDate,
  };
}
export function setResendWaybill(ResendWaybill: any) {
  return {
    type: 'SET_RESEND_WAYBILL',
    ResendWaybill,
  };
}
export function setResendFileExcel(data: any) {
  return {
    type: 'SET_FILE_EXCEL_RESEND',
    data,
  };
}
export function setResendVendor() {
  return {
    type: 'SET_RESEND_VENDOR',
  };
}
export function setResendIsChangeCourier() {
  return {
    type: 'SET_CHECKBOX_COURIER_RESEND',
  };
}
export function setResendIsScan() {
  return {
    type: 'SET_CHECKBOX_SCAN_RESEND',
  };
}
export function setResendIsSuccess(data) {
  return {
    type: 'SET_STATUS_SUCCESS',
    data,
  };
}
export function setResendListResult(data) {
  return {
    type: 'SET_LIST_RESULT',
    ListResult: data,
  };
}
export function clearResendWaybill() {
  return {
    type: 'CLEAR_RESEND_WAYBILL',
  };
}
export function clearResendCourier() {
  return {
    type: 'CLEAR_RESEND_COURIER',
  };
}
export function clearAllFormResend() {
  return {
    type: 'CLEAR_ALL_FORM_RESEND',
  };
}
