import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import SlaPengirimanComponent from '../../components/SlaPengiriman';
import { getDataTableSla } from 'services/ReportCourierServices';
import { useQuery, useMutation } from '@tanstack/react-query';
import ModalStatus from 'materials/ModalStatus';
import { downloadBlob } from 'lib/util';
import { downloadSlaDocument } from 'services/ReportCourierServices';

type SlaPengirimanProps = {
  dataAllBranch: Array<any>;
  isLoadingBranch?: boolean;
  IdBranch: number;
  roleAccount: string;
  GetUsername: string;
};

export type FilterDataSlaProps = {
  typeFilter: 'Courier' | 'Branch';
  dateSend: any;
  branch?: Array<number>;
  dateEnd: any;
  searchValue?: string;
};

export default function SlaPengiriman({
  dataAllBranch,
  isLoadingBranch,
  IdBranch,
  roleAccount,
  GetUsername,
}: SlaPengirimanProps) {
  const isBranch = roleAccount === 'Branch';
  const defaultDateSend = isBranch ? moment().subtract(1, 'weeks') : null;
  const defaultDateEnd = isBranch ? moment() : null;
  const defaultFilter = {
    typeFilter: 'Courier' as 'Courier' | 'Branch',
    dateSend: defaultDateSend,
    branch: [],
    dateEnd: defaultDateEnd,
    searchValue: '',
  };

  const [dataFilterSla, setDataFilterSla] =
    useState<FilterDataSlaProps>(defaultFilter);
  const [page, setPage] = useState<number>(0);
  const [selectedDownloadSla, setSelectedDownloadSla] = useState<string>('');

  const { data: dataTable, isFetching: isLoadingTable } = useQuery(
    ['TableSla', page, dataFilterSla],
    () => getDataTableSla({ page, ...dataFilterSla }),
    {
      onSuccess: (res: any) => {
        if (res.data) {
          const response = res.data;
          if (response.data.length > 0) {
            ModalStatus({
              status: 'success',
              title: 'Pencarian Berhasil',
              content: `Data berhasil ditemukan (${response.totalDatas})`,
            });
          } else {
            ModalStatus({
              status: 'error',
              title: 'Tidak Ditemukan',
              content: 'Data yang Anda cari tidak ditemukan Mohon ulangi',
            });
          }
        }
      },
      enabled: isBranch
        ? !!(dataFilterSla.dateSend && dataFilterSla.dateEnd) &&
          !!(
            isBranch &&
            dataFilterSla.branch &&
            dataFilterSla.branch?.length > 0
          )
        : !!(dataFilterSla.dateSend && dataFilterSla.dateEnd),
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: mutateDownloadFile, isLoading: isLoadingDownload } =
    useMutation(downloadSlaDocument, {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          downloadBlob(
            res.data,
            `SLAPengiriman_${GetUsername}_${moment().format(
              'YYYYMMDD'
            )}_${moment().format('HHMMSS')}`,
            'xlsx'
          );
          ModalStatus({
            status: 'success',
            title: 'Download berhasil',
          });
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });

  useEffect(() => {
    if (dataAllBranch.length > 0 && isBranch) {
      setDataFilterSla((prevState: FilterDataSlaProps) => ({
        ...prevState,
        branch: [IdBranch],
      }));
    }
  }, [IdBranch, dataAllBranch, isBranch]);

  const handleChangeFilter = (name: string, value: any) => {
    setDataFilterSla((prevState: FilterDataSlaProps) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitFilter = (value: FilterDataSlaProps) => {
    setDataFilterSla({
      typeFilter: value.typeFilter,
      dateSend: value.dateSend,
      dateEnd: value.dateEnd,
      branch: value.branch || [],
      searchValue: dataFilterSla?.searchValue || '',
    });
  };

  const handleDownloadFile = () => {
    mutateDownloadFile({
      slaType: dataFilterSla.typeFilter,
      startDate: dataFilterSla.dateSend.format('DDMMYYYY'),
      endDate: dataFilterSla.dateEnd.format('DDMMYYYY'),
      valueSearch: dataFilterSla.searchValue,
      branch: dataFilterSla.branch,
    });
  };

  const handleResetFilter = () => {
    setDataFilterSla(prevState => ({
      ...defaultFilter,
      branch: isBranch ? [IdBranch] : [],
      typeFilter: prevState.typeFilter,
    }));
  };

  return (
    <SlaPengirimanComponent
      dataAllBranch={dataAllBranch || []}
      isLoadingBranch={isLoadingBranch}
      IdBranch={IdBranch}
      roleAccount={roleAccount}
      dataFilterSla={dataFilterSla}
      dataTableSla={dataTable?.data || null}
      handleResetFilter={handleResetFilter}
      handleChangeFilter={handleChangeFilter}
      handleSubmitFilter={handleSubmitFilter}
      handlePage={value => setPage(value)}
      isLoadingTable={isLoadingTable || isLoadingDownload}
      handleSearchValue={value => handleChangeFilter('searchValue', value)}
      selectedDownloadSla={selectedDownloadSla}
      setSelectedDownloadSla={setSelectedDownloadSla}
      handleDownloadFile={handleDownloadFile}
    />
  );
}
