import * as ActionConfigReport from '../../action/Customer/ActionDetailConfigReport';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_addCustomerConfigReport() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const CustomerNameReport =
    state.Customer.FormCustomerConfigReport.CustomerNameReport;
  const FormatReport = state.Customer.FormCustomerConfigReport.FormatReport;
  const DelimitedReport =
    state.Customer.FormCustomerConfigReport.DelimitedReport;
  const PeriodeReportelimitedReport =
    state.Customer.FormCustomerConfigReport.PeriodeReport;
  const WithoutHeader = state.Customer.FormCustomerConfigReport.WithoutHeader;
  const ShowInPortal = state.Customer.FormCustomerConfigReport.ShowInPortal;
  const IdCustomCsv = state.Customer.FormCustomerConfigReport.SettingReport;
  const IdCustomer = state.Customer.PropertiesCustomer.IdCustomer;
  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Setting/CustomReportFile',
      {
        name: CustomerNameReport,
        format: FormatReport,
        delimited: DelimitedReport,
        periode: PeriodeReportelimitedReport,
        customerId: IdCustomer,
        customerCsvId: IdCustomCsv,
        headerHide: WithoutHeader,
        reportPortal: ShowInPortal,
      },
      { headers: config }
    );
    yield put(ActionConfigReport.changeClearCustomerConfigReport());
    yield put(ActionConfigReport.clearListCustomerReport());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeModal());
    yield put(toggle.changeLoadingFalse());
    yield put(ActionConfigReport.changeDetailCustomerConfigReport());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>Berhasil menambahkan format laporan</p>
      ),

      onOk() {},
    });
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      const propsError = ErrorMassage('Tambah format laporan');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_addCustomerConfigReport() {
  yield takeLatest('SUBMIT_ADD_CONFIG_REPORT', S_addCustomerConfigReport);
}
