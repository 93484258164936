export function editCustomerAccount()
{
    return({
        type:"EDIT_CUSTOMER_ACCOUNT"
    })
}
export function statusCustomerAccount()
{
    return({
        type:"STATUS_CUSTOMER_ACCOUNT"
    })
}