import {
  Button,
  Checkbox,
  Col,
  Form,
  InputNumber,
  Row,
  Select,
  Tooltip,
} from 'antd';

import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import FormInputTextMaterial from 'materials/FormInputTextMaterial';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';

const { Option } = Select;

interface IProps {
  DeliveryType: any;
  handlePutDeliveryType: any;
  handleBlurDeliveryType: any;
  ValidateDeliveryType: any;
  helpValidateDeliveryType: any;

  EnvelopeType: any;
  handlePutEnvelopeType: any;
  handleBlurEnvelopeType: any;
  ValidateEnvelopeType: any;
  helpValidateEnvelopeType: any;

  Area: any;
  handlePutArea: any;
  handleBlurArea: any;
  ValidateArea: any;
  helpValidateArea: any;

  ListBranch: any;
  Branch: any;
  handlePutBranch: any;
  handleBlurBranch: any;
  ValidateBranch: any;
  helpValidateBranch: any;

  ListCourier: any;
  Courier: any;
  handlePutCourier: any;
  handleBlurCourier: any;
  ValidateCourier: any;
  helpValidateCourier: any;
  handleGetCourier: any;
  ValidateCourierForm: any;

  WorB: any;
  handlePutWorB: any;
  ValidateWorB: any;
  helpValidateBorW: any;
  handlePutCheckbox: any;
  CheckBox: any;

  handlePutCheckbox2: any;
  CheckBoxKoli: any;

  handleAddListKoli: any;
  handlePutKoli: any;
  handleRemoveKoli: any;
  ListKoli: any;
  Koli: any;
  Weight: any;

  AssignStatus: any;
  DetailWorB: any;
  DetailCourierCode: any;
  validateReset: any;
  validateSubmit: any;
  handleSubmit: any;
  handleReset: any;
}
export default function AsigningCourierSAComponent({
  DeliveryType,
  handlePutDeliveryType,
  handleBlurDeliveryType,
  ValidateDeliveryType,
  helpValidateDeliveryType,

  EnvelopeType,
  handlePutEnvelopeType,
  handleBlurEnvelopeType,
  ValidateEnvelopeType,
  helpValidateEnvelopeType,

  Area,
  handlePutArea,
  handleBlurArea,
  ValidateArea,
  helpValidateArea,

  ListBranch,
  Branch,
  handlePutBranch,
  handleBlurBranch,
  ValidateBranch,
  helpValidateBranch,

  ListCourier,
  Courier,
  handlePutCourier,
  handleBlurCourier,
  ValidateCourier,
  helpValidateCourier,
  handleGetCourier,
  ValidateCourierForm,

  WorB,
  handlePutWorB,
  ValidateWorB,
  helpValidateBorW,
  handlePutCheckbox,
  CheckBox,

  handlePutCheckbox2,
  CheckBoxKoli,

  handleAddListKoli,
  handlePutKoli,
  handleRemoveKoli,
  ListKoli,
  Koli,
  Weight,

  AssignStatus,
  DetailWorB,
  DetailCourierCode,
  validateReset,
  validateSubmit,
  handleSubmit,
  handleReset,
}: IProps) {
  return (
    <div style={{ padding: '35px' }}>
      <SpinMaterial
        spinning={''}
        style={{}}
        size={'large'}
        content={
          <CardMaterial
            extra={''}
            style={{ borderRadius: '8px' }}
            title={
              <div style={{ color: '#11BEFF' }}>
                <span>
                  <b>SCAN KURIR</b>
                </span>
              </div>
            }
            content={
              <div>
                <Row>
                  <Col span={12}>
                    <div>
                      <span>
                        <b>Jenis Pengiriman</b>
                      </span>
                      <Form.Item
                        validateStatus={ValidateDeliveryType}
                        help={helpValidateDeliveryType}
                      >
                        <Select
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          showSearch
                          disabled={false}
                          onChange={handlePutDeliveryType}
                          onBlur={handleBlurDeliveryType}
                          value={DeliveryType}
                        >
                          <Option key={''} value={'Reguler'}>
                            Reguler
                          </Option>
                          <Option key={''} value={'SameDayService'}>
                            SDS
                          </Option>
                          <Option key={''} value={'Urgent'}>
                            Urgent
                          </Option>
                          <Option key={''} value={'ONS'}>
                            ONS
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Jenis Amplop</b>
                      </span>
                      <Form.Item
                        validateStatus={ValidateEnvelopeType}
                        help={helpValidateEnvelopeType}
                      >
                        <Select
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          showSearch
                          disabled={false}
                          onChange={handlePutEnvelopeType}
                          onBlur={handleBlurEnvelopeType}
                          value={EnvelopeType}
                        >
                          <Option key={''} value={'standart'}>
                            Standart
                          </Option>
                          <Option key={''} value={'a4'}>
                            A4
                          </Option>
                          <Option key={''} value={'a5'}>
                            A5
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>

                    {CheckBoxKoli === false ? (
                      <div>
                        <Row>
                          <Col span={16}>
                            <span>
                              <b>Total berat(Kg)</b>
                            </span>
                            <Form.Item validateStatus={''} help={''}>
                              <InputNumber
                                min={0}
                                max={100}
                                defaultValue={0}
                                value={Weight}
                                onChange={handlePutKoli}
                                placeholder={'Masukan total berat'}
                                style={{ width: 300, borderRadius: 10 }}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Checkbox
                              style={{
                                paddingTop: 30,
                                paddingLeft: '24px',
                              }}
                              checked={CheckBoxKoli}
                              onChange={handlePutCheckbox2}
                            />
                            <span
                              style={{
                                paddingLeft: '15px',
                                fontStyle: 'italic',
                                color: '#969696',
                              }}
                            >
                              Ubah koli
                            </span>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      ''
                    )}
                    {CheckBoxKoli === true ? (
                      <React.Fragment>
                        {ListKoli[0] === undefined ? (
                          <span>
                            <b>Koli-1 (Kg)</b>
                          </span>
                        ) : (
                          ''
                        )}
                        {ListKoli.map((data: any, index: any) => (
                          <div>
                            <span>
                              <b>Koli-{index + 1} (Kg)</b>
                            </span>
                            <div key={data.index}>
                              <Row>
                                <Col style={{ paddingRight: '10px' }} span={17}>
                                  <Form.Item validateStatus={''} help={''}>
                                    <InputNumber
                                      min={0}
                                      max={100}
                                      defaultValue={0}
                                      value={data}
                                      onChange={(e: any) =>
                                        handlePutKoli(e, index)
                                      }
                                      placeholder={'Masukan total berat'}
                                      style={{ width: 300, borderRadius: 10 }}
                                    />
                                  </Form.Item>
                                </Col>
                                {index === 0 ? (
                                  <Col span={7}>
                                    <Checkbox
                                      style={{
                                        paddingTop: 8,
                                        paddingLeft: '24px',
                                      }}
                                      checked={CheckBoxKoli}
                                      onChange={handlePutCheckbox2}
                                    />
                                    <span
                                      style={{
                                        paddingLeft: '15px',
                                        fontStyle: 'italic',
                                        color: '#969696',
                                      }}
                                    >
                                      Ubah koli
                                    </span>
                                  </Col>
                                ) : (
                                  <Col span={7}>
                                    <div>
                                      <ButtonMaterial
                                        disabled={''}
                                        handleSubmit={() =>
                                          handleRemoveKoli(index)
                                        }
                                        teksButton={''}
                                        size={''}
                                        shape={''}
                                        className={''}
                                        type={'primary'}
                                        icon={'delete'}
                                        style={{
                                          marginLeft: '20px',
                                          borderRadius: '8px',
                                          color: '#fafafa',
                                          paddingBottom: '3px',
                                          background: 'red',
                                          borderColor: 'red',
                                        }}
                                      />
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </div>
                          </div>
                        ))}
                        {ListKoli[0] === undefined ? (
                          <Row>
                            <Col span={17}>
                              <Button
                                disabled={ListKoli.length === 7 ? true : false}
                                onClick={() => handleAddListKoli()}
                                type={'dashed'}
                                size={'default'}
                                icon={'plus'}
                                style={{
                                  width: '100%',
                                  color: '#31E116',
                                  borderRadius: '12px',
                                  marginTop: '3px',
                                }}
                              >
                                <span style={{ color: 'black' }}>
                                  Tambahkan Koli
                                </span>
                              </Button>
                            </Col>
                            <Col span={7}>
                              <Checkbox
                                style={{
                                  paddingTop: 8,
                                  paddingLeft: '24px',
                                }}
                                checked={CheckBoxKoli}
                                onChange={handlePutCheckbox2}
                              />
                              <span
                                style={{
                                  paddingLeft: '15px',
                                  fontStyle: 'italic',
                                  color: '#969696',
                                }}
                              >
                                Ubah koli
                              </span>
                            </Col>
                          </Row>
                        ) : (
                          <Button
                            disabled={ListKoli.length === 7 ? true : false}
                            onClick={() => handleAddListKoli()}
                            type={'dashed'}
                            size={'default'}
                            icon={'plus'}
                            style={{
                              width: '100%',
                              color: '#31E116',
                              borderRadius: '12px',
                              marginTop: '3px',
                            }}
                          >
                            <span style={{ color: 'black' }}>
                              Tambahkan Koli
                            </span>
                          </Button>
                        )}
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </Col>
                  <Col span={12} style={{ paddingLeft: '25px' }}>
                    <div>
                      <span>
                        <b>Wilayah</b>
                      </span>
                      <Form.Item
                        validateStatus={ValidateArea}
                        help={helpValidateArea}
                      >
                        <Select
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          showSearch
                          disabled={false}
                          onChange={handlePutArea}
                          onBlur={handleBlurArea}
                          value={Area}
                        >
                          <Option key={''} value={'Jabodetabek'}>
                            Jabodetabek
                          </Option>
                          <Option key={''} value={'Jawa'}>
                            Jawa
                          </Option>
                          <Option key={''} value={'LuarJawa'}>
                            Luar Jawa
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Posko</b>
                      </span>
                      <Form.Item
                        validateStatus={ValidateBranch}
                        help={helpValidateBranch}
                      >
                        <Select
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          showSearch
                          disabled={false}
                          onChange={handlePutBranch}
                          onBlur={handleBlurBranch}
                          value={Branch}
                        >
                          {ListBranch.map((data: any, index: any) => (
                            <Option key={index} value={data.id}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Kurir</b>
                      </span>
                      <Form.Item
                        validateStatus={ValidateCourier}
                        help={helpValidateCourier}
                      >
                        <Select
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          showSearch
                          disabled={ValidateCourierForm}
                          value={Courier}
                          onBlur={handleBlurCourier}
                          onChange={handlePutCourier}
                          onFocus={handleGetCourier}
                        >
                          {ListCourier.map((data: any, index: any) => (
                            <Option
                              key={index}
                              value={data.id}
                              label={data.code}
                            >
                              {data.firstName} ({data.code})
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <Row>
                      <Col span={18}>
                        <span>
                          {CheckBox === true ? <b>Barcode</b> : <b>Waybill</b>}
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          className={''}
                          onPressEnter={handleSubmit}
                          disabled={false}
                          addonBefore={''}
                          placeholder={
                            CheckBox === true
                              ? 'Masukkan Barcode'
                              : 'Masukkan Waybill'
                          }
                          prefix={''}
                          value={WorB}
                          maxLength={30}
                          style={{}}
                          size=""
                          type={''}
                          onChange={handlePutWorB}
                          onBlur={handlePutWorB}
                          validatestatus={ValidateWorB}
                          help={helpValidateBorW}
                        />
                      </Col>
                      <Col span={6}>
                        <Tooltip
                          placement="top"
                          title={'Ceklist untuk barcode'}
                        >
                          <Checkbox
                            style={{
                              paddingTop: 30,
                              paddingLeft: '24px',
                            }}
                            checked={CheckBox}
                            onChange={handlePutCheckbox}
                          />
                        </Tooltip>
                        <span
                          style={{
                            paddingLeft: '18px',
                            fontStyle: 'italic',
                            color: '#969696',
                          }}
                        >
                          Barcode
                        </span>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        float: 'left',
                        marginTop: '5px',
                        marginBottom: '15px',
                      }}
                    >
                      {AssignStatus === 'Success' ? (
                        <p>
                          <b>
                            <span style={{ color: '#11BEFF' }}>
                              {DetailWorB}
                            </span>{' '}
                            <span style={{ color: '#31E116' }}>
                              Dikirim oleh {DetailCourierCode}
                            </span>
                          </b>
                        </p>
                      ) : AssignStatus === 'Failed' ? (
                        <p>
                          <b>
                            <span style={{ color: '#11BEFF' }}>
                              {DetailWorB}
                            </span>{' '}
                            <span style={{ color: 'red' }}>Gagal Scan</span>
                          </b>
                        </p>
                      ) : AssignStatus === 'FailedNotFound' ? (
                        <p>
                          <b>
                            <span style={{ color: '#11BEFF' }}>
                              {DetailWorB}
                            </span>{' '}
                            <span style={{ color: 'red' }}>
                              Tidak ditemukan
                            </span>
                          </b>
                        </p>
                      ) : (
                        ''
                      )}
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <div style={{ marginLeft: 20, float: 'right' }}>
                    <ButtonMaterial
                      disabled={validateSubmit}
                      handleSubmit={handleSubmit}
                      teksButton={<b>Simpan</b>}
                      size={'middle'}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={{
                        height: '35px',
                        width: '100px',
                      }}
                    />
                  </div>
                  <div style={{ float: 'right' }}>
                    <ButtonMaterial
                      disabled={validateReset}
                      handleSubmit={handleReset}
                      teksButton={<b>Reset</b>}
                      size={'middle'}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={
                        validateReset === false
                          ? {
                              background: '#FF5858',
                              borderColor: '#FF5858',
                              colorFont: '#FFFFFF',
                              height: '35px',
                              width: '100px',
                            }
                          : {
                              height: '35px',
                              width: '100px',
                            }
                      }
                    />
                  </div>
                </Row>
              </div>
            }
          />
        }
      />
    </div>
  );
}
