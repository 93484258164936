export function changeComplaintStatus(complaintStatus:any)
{
    return({
        type:"CHANGE_COMPLAINT_STATUS",
        complaintStatus
    })
}
export function changeTicketCode(complaintTicketCode:any)
{
    return({
        type:"CHANGE_COMPLAINT_TICKET_CODE",
        complaintTicketCode
    })
}
export function changeComplaintWaybill(complaintWaybill:any)
{
    return({
        type:"CHANGE_COMPLAINT_WAYBILL",
        complaintWaybill
    })
}
export function changeComplaintWaybillDetail(complaintWaybillDetail:any)
{
    return({
        type:"CHANGE_COMPLAINT_WAYBILL_DETAIL",
        complaintWaybillDetail
    })
}
export function changeComplaintBarcodeDetail(complaintBarcodeDetail:any)
{
    return({
        type:"CHANGE_COMPLAINT_BARCODE_DETAIL",
        complaintBarcodeDetail
    })
}
export function changeComplaintNoTelp(complaintNoTelp:any)
{
    return({
        type:"CHANGE_COMPLAINT_NO_TELP",
        complaintNoTelp
    })
}
export function changeComplaintDetailNoTelp(complaintNoTelpDetail:any)
{
    return({
        type:"CHANGE_COMPLAINT_NO_TELP_DETAIL",
        complaintNoTelpDetail
    })
}
export function changeComplaintDetail(complaintDetail :any)
{
    return({
        type:"CHANGE_COMPLAINT_DETAIL",
        complaintDetail
    })
}
export function changeComplaintPostCode(complaintPostCode :any)
{
    return({
        type:"CHANGE_COMPLAINT_POST_CODE",
        complaintPostCode
    })
}
export function changeComplaintCity(complaintPostCode :any)
{
    return({
        type:"CHANGE_COMPLAINT_CITY",
        complaintPostCode
    })
}
export function changeComplaintResultInvestigation(complaintResultInvestigation:any)
{
    return({
        type:"CHANGE_COMPLAINT_RESULT_INVESTIGATION",
        complaintResultInvestigation
    })
}
export function changeComplaintOwnerName(complaintOwnerName:any)
{
    return({
        type:"CHANGE_COMPLAINT_OWNER_NAME",
        complaintOwnerName
    })
}
export function changeComplaintAddress(complaintAddress:any)
{
    return({
        type:"CHANGE_COMPLAINT_ADDRESS",
        complaintAddress
    })
}
export function changeComplaintId(complaintId:any)
{
    return({
        type:"CHANGE_COMPLAINT_ID",
        complaintId
    })
}
export function changeKey(key:any)
{
    return({
        type:"CHANGE_COMPLAINT_KEY",
        key
    })
}
export function changeFilterStatus(filterStatus:any)
{
    return({
        type:"CHANGE_FILTER_STATUS",
        filterStatus
    })
}
export function getDetailComplaintTicket()
{
    return({
        type:"GET_DETAIL_COMPLAINT_TICKET"
    })
}
export function clearPropertiesComplaint()
{
    return({
        type:"CLEAR_COMPLAINT_PROPERTIES"
    })
}
export function generateTicketCode()
{
    return({
        type:"GENERATE_TICKET_CODE"
    })
}
export function updateTicket()
{
    return({
        type:"UPDATE_TICKET"
    })
}
export function downloadTicket()
{
    return({
        type:"DOWNLOAD_TICKET"
    })
}
export function submitComplaint()
{
    return({
        type:"SUBMIT_COMPLAINT"
    })
}
export function submitEditComplaint()
{
    return({
        type:"SUBMIT_EDIT_COMPLAINT"
    })
}

export function clearComplaintForm()
{
    return({
        type:"CLEAR_COMPLAINT_FORM"
    })
}
