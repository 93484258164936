import React, { Component } from 'react';
import ButtonMaterial from '../../../materials/ButtonMaterial';

interface IProps {
  handleButtonConfirm: any;
  handleDownloadRestoreData: any;
  typeButton: any;
  modal: any;
}
export default class ButtonComplainComponent extends Component<IProps> {
  render() {
    return (
      <div>
        <ButtonMaterial
          disabled={''}
          handleSubmit={this.props.handleDownloadRestoreData}
          teksButton={''}
          size={''}
          shape={''}
          className={'btnDownloadRestoreData'}
          type={'primary'}
          icon={'download'}
          style={{}}
        />
      </div>
    );
  }
}
