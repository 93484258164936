export function ClearCustomerProductTypeOther() {
  return {
    type: 'CLEAR_CUSTOMER_PRODUCT_TYPE_OTHER',
  };
}

export function changeClearAllCustomerProduct() {
  return {
    type: 'CHANGE_CLEAR_CUSTOMER_PRODUCT',
  };
}
export function changeClearFieldCustomerProduct() {
  return {
    type: 'CHANGE_CLEAR_FIELD_CUSTOMER_PRODUCT',
  };
}
export function setGroupProductId(GroupProductId: any) {
  return {
    type: 'SET_GROUP_PRODUCT_ID',
    GroupProductId,
  };
}
export function setGroupName(GroupName: any) {
  return {
    type: 'SET_GROUP_NAME',
    GroupName,
  };
}
export function setGroupNameProduct(GroupNameProduct: any) {
  return {
    type: 'SET_GROUP_NAME_PRODUCT',
    GroupNameProduct,
  };
}
export function setListGroupName(ListGroupName: any) {
  return {
    type: 'SET_LIST_GROUP_NAME',
    ListGroupName,
  };
}
export function setCustomerProductName(CustomerProductName: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_NAME',
    CustomerProductName,
  };
}
export function setCustomerIsDisabled(isDisabled: boolean) {
  return {
    type: 'SET_CUSTOMER_IS_DISABLED',
    isDisabled,
  };
}
export function setCustomerProductCode(CustomerProductCode: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_CODE',
    CustomerProductCode,
  };
}
export function setCustomerProductType(CustomerProductType: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_TYPE',
    CustomerProductType,
  };
}
export function setCustomerProductTypeOther(CustomerProductTypeOther: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_TYPE_OUTHER',
    CustomerProductTypeOther,
  };
}
export function setCustomerProductLogo(CustomerProductLogo: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_LOGO',
    CustomerProductLogo,
  };
}
export function setCustomerProductBonus(CustomerProductBonus: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_BONUS',
    CustomerProductBonus,
  };
}
export function setCustomerProductDistrict1(District1: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_DISTRICT_1',
    District1,
  };
}
export function setCustomerProductDistrict2(District2: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_DISTRICT_2',
    District2,
  };
}
export function setCustomerProductDistrict3(District3: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_DISTRICT_3',
    District3,
  };
}
export function setCustomerProductDistrict4(District4: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_DISTRICT_4',
    District4,
  };
}
export function setCustomerProductDistrict5(District5: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_DISTRICT_5',
    District5,
  };
}
export function setCustomerProductDistrict6(District6: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_DISTRICT_6',
    District6,
  };
}
export function setCustomerProductDistrict7(District7: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_DISTRICT_7',
    District7,
  };
}
export function setCustomerProductDistrict8(District8: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_DISTRICT_8',
    District8,
  };
}
export function setCustomerProductDistrict9(District9: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_DISTRICT_9',
    District9,
  };
}
export function setCustomerProductDistrict10(District10: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_DISTRICT_10',
    District10,
  };
}
export function setCustomerProductDistrict11(District11: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_DISTRICT_11',
    District11,
  };
}
export function setCustomerProductDistrict12(District12: any) {
  return {
    type: 'SET_CUSTOMER_PRODUCT_DISTRICT_12',
    District12,
  };
}
export function setListCustomerProductBonus(ListCustomerProductBonus: any) {
  return {
    type: 'SET_LIST_CUSTOMER_PRODUCT_BONUS',
    ListCustomerProductBonus,
  };
}
export function changeClearCustomerProductBonus() {
  return {
    type: 'CHANGE_CLEAR_CUSTOMER_PRODUCT_BONUS',
  };
}
export function changeClearListCustomerProductBonus() {
  return {
    type: 'CHANGE_CLEAR_LIST_CUSTOMER_PRODUCT_BONUS',
  };
}
export function submitGroupName() {
  return {
    type: 'SUBMIT_GROUP_NAME',
  };
}
export function getGroupNameList() {
  return {
    type: 'GET_GROUP_NAME_LIST',
  };
}
export function submitEditGroupName() {
  return {
    type: 'SUBMIT_EDIT_GROUP_NAME',
  };
}
export function clearGroupName() {
  return {
    type: 'CLEAR_GROUP_NAME',
  };
}
export function clearListGroupName() {
  return {
    type: 'CLEAR_LIST_GROUP_NAME',
  };
}
export function clearGroupProductId() {
  return {
    type: 'CLEAR_GROUP_PRODUCT_ID',
  };
}
