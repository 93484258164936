import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import DetailCustomerAccountComponent from '../../../../components/Content/ManageAccount/Customer/DetailCustomerAccountComponent';
import * as ActionDetailCustomer from '../../../../core/action/Customer/ActionDetailCustomer';
import * as LastAccess from '../../../../core/action/LastAccess/index';
import * as ActionCustomerAccount from '../../../../core/action/ManageAccount/Customer/ActionCustomerAccount';
import * as ActionToggle from '../../../../core/action/toggle/index';
import history from '../../../../core/utils/History';
interface IProps
{
    CustomerId:any
    CustomerName:any
    CustomerCode:any
    CustomerType:any
    CustomerTelephone:any
    CustomerEmail:any
    CustomerAddress:any
    isLoading:any
    typeToggle:any
    idCustomer:any
    actionToggle:any
    actionCustomerDetail:any
    actionCustomerAccount:any
    actionLastAccess:any
}
class DetailCustomerAccountContainer extends Component<IProps> {
    constructor(props:any)
    {
        super(props)
        
        this.handleBackDetailCustomer = this.handleBackDetailCustomer.bind(this)
    }
    handleBackDetailCustomer()
    {
        this.props.actionLastAccess.changeLastAccessFiture("customerTable")
        history.push("/manageAccount")
    }
    
    componentWillMount(){
        if(this.props.CustomerId==null){
            this.props.actionLastAccess.changeLastAccessFiture("customerTable")
            history.push('/manageAccount')
        }else{
            this.props.actionCustomerDetail.changeDetailCustomer()
            this.props.actionCustomerAccount.getCustomerAccount()
        }
    }
    render() {
        return (
            <DetailCustomerAccountComponent
            CustomerName={this.props.CustomerName}
            CustomerCode={this.props.CustomerCode}
            CustomerType={this.props.CustomerType}
            CustomerTelephone={this.props.CustomerTelephone}
            CustomerEmail={this.props.CustomerEmail}
            CustomerAddress={this.props.CustomerAddress}
            isLoading={this.props.isLoading}
            typeToggle={this.props.typeToggle}
            handleBackDetail={this.handleBackDetailCustomer}
            />
        )
    }
}
const mapStateProps = (state:any) => ({
    CustomerId: state.Customer.PropertiesCustomer.IdCustomer,
    CustomerName:state.Customer.DetailCustomer.CustomerName,
    CustomerCode:state.Customer.DetailCustomer.CustomerCode,
    CustomerType:state.Customer.DetailCustomer.CustomerType,
    CustomerTelephone:state.Customer.DetailCustomer.CustomerTelephone,
    CustomerEmail:state.Customer.DetailCustomer.CustomerEmail,
    CustomerAddress:state.Customer.DetailCustomer.CustomerAddress,
    isLoading:state.Toggle.isLoading,
    typeToggle:state.Toggle.jenisToggle,
})

const mapDispatchToProps = (dispatch:any) => ({
    actionCustomerDetail : bindActionCreators(ActionDetailCustomer, dispatch),
    actionCustomerAccount : bindActionCreators(ActionCustomerAccount, dispatch),
    actionLastAccess : bindActionCreators(LastAccess, dispatch),
    actionToggle : bindActionCreators(ActionToggle, dispatch),
})

const withConnect = connect(mapStateProps, mapDispatchToProps)

export default compose(
    withConnect
)(DetailCustomerAccountContainer)
