import * as ActionAuth from '../../action/Auth';
import * as ActionFieldBranch from '../../action/Branch/ActionFieldBranch';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionToogle from '../../action/toggle';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_getGroupNameBranchList(action: any) {
  try {
    const state = yield select();
    const token = state.Auth.Token;
    const typeTooggle = state.Toggle.jenisToggle2;
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };
    if (typeTooggle === 'addBranchGroup') {
      yield put(ActionToogle.changeLoadingTrue());
    }
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/GroupingBranch`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionFieldBranch.setGroupNameList(data));
    yield put(ActionToogle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionToogle.changeLoadingFalse());
  }
}

export function* S_submitGroupNameBranch(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const groupName = state.Branch.DetailBranch.GroupName;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  try {
    yield put(ActionToogle.changeLoadingTrue());
    yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/GroupingBranch/Add`,
      {
        groupingName: groupName,
      },
      { headers: config }
    );
    yield put(ActionFieldBranch.clearBranchGroupName());
    yield put(ActionToogle.changeModal2());
    yield put(ActionToogle.clearJenisToggle2());
    yield put(ActionToogle.changeLoadingFalse());
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    Modal.success({
      title: <p className={'titleModalNotifSuccess'}>Berhasil di simpan</p>,
      onOk() {},
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionToogle.changeLoadingFalse());
  }
}

export function* S_submitEditGroupNameBranch(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const groupName = state.Branch.DetailBranch.GroupName;
  const groupBranchId = state.Branch.DetailBranch.GroupBranchId;
  try {
    yield put(ActionToogle.changeLoadingTrue());
    yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/GroupingBranch/Update/${groupBranchId}`,
      {
        groupingName: groupName,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionToogle.changeModal2());
    yield put(ActionFieldBranch.clearBranchGroupFrom());
    yield put(ActionToogle.clearJenisToggle2());
    Modal.success({
      title: (
        <p className={'titleModalNotifSuccess'}>Berhasil Mengubah Nama Grup</p>
      ),
      onOk() {},
    });
    yield put(ActionToogle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionToogle.changeLoadingFalse());
  }
}

export function* SM_getGroupNameBranchList() {
  yield takeLatest('GET_GROUP_NAME_BRANCH_LIST', S_getGroupNameBranchList);
}
export function* SM_submitGroupNameBranch() {
  yield takeLatest('SUBMIT_GROUP_NAME_BRANCH', S_submitGroupNameBranch);
}
export function* SM_submitEditGroupNameBranch() {
  yield takeLatest(
    'SUBMIT_EDIT_GROUP_NAME_BRANCH',
    S_submitEditGroupNameBranch
  );
}
