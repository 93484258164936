import { Action } from 'redux';

export interface IFetchCallCenterAction extends Action {
  data: object;
}

export function fetchFormatCsvList(data: object): IFetchCallCenterAction {
  return {
    type: 'FETCH_FORMAT_CSV_LIST',
    data,
  };
}
export function fetchCustomerProductList(data: object): IFetchCallCenterAction {
  return {
    type: 'FETCH_CUSTOMER_PRODUCT_LIST',
    data,
  };
}
export function fetchCourierList(data: object): IFetchCallCenterAction {
  return {
    type: 'FETCH_COURIER_LIST',
    data,
  };
}
export function setFileNameCsv(data: object): IFetchCallCenterAction {
  return {
    type: 'SET_FILE_NAME_CALL_CENTER_CSV',
    data,
  };
}
export function setCsvFile(data: object): IFetchCallCenterAction {
  return {
    type: 'SET_CALL_CENTER_CSV',
    data,
  };
}
export function changeCsvFileName(data: object): IFetchCallCenterAction {
  return {
    type: 'CHANGE_CALL_CENTER_CSV_FILE_NAME',
    data,
  };
}
export function changeDeliveryDate(data: object): IFetchCallCenterAction {
  return {
    type: 'CHANGE_CALL_CENTER_DELIVERY_DATE',
    data,
  };
}
export function fetchDataSuccessAttempt(data: object): IFetchCallCenterAction {
  return {
    type: 'FETCH_DATA_SUCCESS_ATTEMPT_CSV_CALL_CENTER',
    data,
  };
}
export function fetchDataSuccessKu(data: object): IFetchCallCenterAction {
  return {
    type: 'FETCH_DATA_SUCCESS_KU_CSV_CALL_CENTER',
    data,
  };
}
export function fetchDataFailedKu(data: object): IFetchCallCenterAction {
  return {
    type: 'FETCH_DATA_FAILED_KU_CSV_CALL_CENTER',
    data,
  };
}
export function fetchDataFailedAttempt(data: object): IFetchCallCenterAction {
  return {
    type: 'FETCH_DATA_FAILED_ATTEMPT_CSV_CALL_CENTER',
    data,
  };
}
export function fetchDataListCsv(data: object): IFetchCallCenterAction {
  return {
    type: 'FETCH_DATA_LIST_CSV_CALL_CENTER',
    data,
  };
}
export function getFormatCsvList() {
  return {
    type: 'CC_GET_FORMAT_CSV_LIST_REQUESTED',
  };
}
export function getCustomerProductList() {
  return {
    type: 'CC_GET_CUSTOMER_PRODUCT_LIST_REQUESTED',
  };
}
export function getCourierList() {
  return {
    type: 'CC_GET_COURIER_LIST_REQUESTED',
  };
}
export function submitUploadCallCenter() {
  return {
    type: 'SUBMIT_UPLOAD_CALL_CENTER_REQUESTED',
  };
}
export function downloadResultCsvCallCenter() {
  return {
    type: 'DOWNLOAD_RESULT_CSV_CALL_CENTER_REQUESTED',
  };
}
export function clearListResultCsvCallCenter() {
  return {
    type: 'CLEAR_LIST_RESULT_CSV_CALL_CENTER',
  };
}
export function clearFormCallCenter() {
  return {
    type: 'CLEAR_FORM_CALL_CENTER',
  };
}
export function clearAllFormCallCenter() {
  return {
    type: 'CLEAR_ALL_FORM_CALL_CENTER',
  };
}
