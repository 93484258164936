import { Input } from 'antd';
import React from 'react';

interface IProps {
  style?: any;
  placeholder?: any;
  onChange?: any;
  onPressEnter?: any;
  value?: any;
  maxLength?: number;
}

export default function FormInputTextMaterial({
  style,
  placeholder,
  onChange,
  onPressEnter,
  value,
  maxLength,
}: IProps) {
  return (
    <Input.Search
      style={style}
      allowClear
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onPressEnter={onPressEnter}
      maxLength={maxLength}
    />
  );
}
