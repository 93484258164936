import * as ActionDataActive from '../../../action/Voip/DataActive/ActionDataActive';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionPagination from '../../../action/Pagination/ActionPagination';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../../action/toggle';

import { ErrorMassage } from '../../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_getDataTableDataActive() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const PagePagination = state.Pagination.PagePagination;
  const SizeDataPagination = state.Pagination.SizeDataPagination;
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/VoipSetting/GetDataActive/${PagePagination}/${SizeDataPagination}`,
      { headers: config }
    );
    yield put(ActionDataActive.setListDataActive(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      const propsError = ErrorMassage('List Data Active');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
    yield put(changeLoadingFalse());
  }
}

export function* S_searchDataTableDataActive() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const searchValue = state.Toggle.ValueSearch;
  const PagePagination = state.Pagination.PagePagination;
  const SizeDataPagination = state.Pagination.SizeDataPagination;
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      searchValue !== ''
        ? `${process.env.REACT_APP_URL}/VoipSetting/Syncronize/ByWaybill?WaybillCode=${searchValue}`
        : `${process.env.REACT_APP_URL}/VoipSetting/GetDataActive/${PagePagination}/${SizeDataPagination}`,

      { headers: config }
    );

    if (data.data.length === 0) {
      Modal.error({
        title: <p style={{ color: '#FF0000' }}>Pencarian Tidak Ditemukan</p>,
        onOk() {},
      });
    }
    yield put(ActionDataActive.setListDataActive(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      const propsError = ErrorMassage('List Data Active');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
    yield put(changeLoadingFalse());
  }
}

export function* SM_getDataTableDataActive() {
  yield takeLatest('GET_LIST_DATA_ACTIVE', S_getDataTableDataActive);
}

export function* SM_searchDataTableDataActive() {
  yield takeLatest('SUBMIT_SEARCH_DATA_ACTIVE', S_searchDataTableDataActive);
}
