import instance from 'config/interceptor';

export const getRoles = async params => {
  try {
    const { page = 0, isActive = 'Active', size = 10, roleName } = params[1];
    let link = `/RolePermission/${isActive}/${page}/${size}`;
    if (roleName) link = link.concat(`?roleName=${roleName}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getSingleRole = async id => {
  try {
    let link = `/RolePermission/${id}`;
    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getAllRoles = async (params: { idAccountType: string }) => {
  try {
    const { idAccountType } = params;

    let link = `/RolePermission/selected`;
    if (idAccountType) link = link.concat(`?accountType=${idAccountType}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const postRole = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/RolePermission`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const putRole = async data => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + `/RolePermission`,
      data
    );
  } catch (error) {
    return error;
  }
};
