import 'styles/LoginStyle/index.css';

import { Col, Row } from 'antd';

import LoginContainer from 'module/Login/Container/LoginContainer';
import React from 'react';
import SidebarLoginComponent from 'module/Login/Component/SidebarLoginComponent';

export default function ParentHocLogin() {
  return (
    <Row>
      <div>
        <Col span={9} className="SideBar">
          <SidebarLoginComponent />
        </Col>
        <Col span={15}>
          <LoginContainer />
        </Col>
      </div>
    </Row>
  );
}
