export function ClearDataCustomertypeother() {
  return {
    type: 'CLEAR_DATA_CUSTOMER_TYPE_OTHER',
  };
}
export function changeClearAllDataCustomer() {
  return {
    type: 'CHANGE_CLEAR_DATA_CUSTOMER',
  };
}
export function changeClearFieldCustomer() {
  return {
    type: 'CHANGE_CLEAR_FIELD_CUSTOMER',
  };
}
export function setCustomerName(customername: any) {
  return {
    type: 'CHANGE_NAME_CUSTOMER',
    customername,
  };
}
export function setCustomerCode(customercode: any) {
  return {
    type: 'CHANGE_CUSTOMER_CODE',
    customercode,
  };
}
export function setCustomerType(customertype: any) {
  return {
    type: 'CHANGE_CUSTOMER_TYPE',
    customertype,
  };
}
export function setCustomerTypeOther(customertypeother: any) {
  return {
    type: 'CHANGE_CUSTOMER_TYPE_OTHER',
    customertypeother,
  };
}
export function setCustomerTelephone(customertelephone: any) {
  return {
    type: 'CHANGE_CUSTOMER_TELEPHONE',
    customertelephone,
  };
}
export function setCustomerEmail(customeremail: any) {
  return {
    type: 'CHANGE_CUSTOMER_EMAIL',
    customeremail,
  };
}
export function setCustomerAccess(customeraccess: any) {
  return {
    type: 'CHANGE_CUSTOMER_ACCESS',
    customeraccess,
  };
}
export function setCustomerAddress(customeraddress: any) {
  return {
    type: 'CHANGE_CUSTOMER_ADDRESS',
    customeraddress,
  };
}
