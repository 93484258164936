export function changeTypeFiture(TypeFiture: any) {
  return {
    type: 'CHANGE_TYPE_FITURE',
    TypeFiture,
  };
}
export function changeIdFiture(IdFiture: any) {
  return {
    type: 'CHANGE_ID_FITURE',
    IdFiture,
  };
}
export function changeSmsDelivery(SmsDelivery: any) {
  return {
    type: 'CHANGE_SMS_DELIVERY',
    SmsDelivery,
  };
}
export function changeSmsDelivered(SmsDelivered: any) {
  return {
    type: 'CHANGE_SMS_DELIVERED',
    SmsDelivered,
  };
}
export function changeCall(Call: any) {
  return {
    type: 'CHANGE_CALL',
    Call,
  };
}
export function changeIsRadius(IsRadius: any) {
  return {
    type: 'CHANGE_ISRADIUS',
    IsRadius,
  };
}
export function changeIsRequestPhone(IsRequestPhone: any) {
  return {
    type: 'CHANGE_ISREQUESTPHONE',
    IsRequestPhone,
  };
}
export function changeIsHistory(IsHistory: any) {
  return {
    type: 'CHANGE_ISHISTORY',
    IsHistory,
  };
}
export function changeRadiusMeters(RadiusMeters: any) {
  return {
    type: 'CHANGE_RADIUS_METERS',
    RadiusMeters,
  };
}
export function changeSenderAlias(SenderAlias: any) {
  return {
    type: 'CHANGE_SENDER_ALIAS',
    SenderAlias,
  };
}
export function changeSmsDeliveryBody(SmsDeliveryBody: any) {
  return {
    type: 'CHANGE_SMS_DELIVERY_BODY',
    SmsDeliveryBody,
  };
}
export function changeSmsDeliveredBody(SmsDeliveredBody: any) {
  return {
    type: 'CHANGE_SMS_DELIVERED_BODY',
    SmsDeliveredBody,
  };
}
export function changeDetailCustomerFiture() {
  return {
    type: 'CHANGE_DETAIL_CUSTOMER_FITURE',
  };
}
export function changeAddCustomerFiture() {
  return {
    type: 'ADD_CUSTOMER_FITURE',
  };
}
export function changeEditCustomerFiture() {
  return {
    type: 'EDIT_CUSTOMER_FITURE',
  };
}
export function changeClearSmsDeliveryBody() {
  return {
    type: 'CLEAR_FITURE_SMS_DELIVERY_BODY',
  };
}
export function changeClearSmsDeliveredBody() {
  return {
    type: 'CLEAR_FITURE_SMS_DELIVERED_BODY',
  };
}
export function changeClearCustomerFiture() {
  return {
    type: 'CLEAR_CUSTOMER_FITURE',
  };
}
