export function getHolidaySynchroniz()
{
    return({
        type:"GET_HOLIDAY_SYNCHRONIZ"
    })
}
export function setHolidaySynchroniz(HolidaySynchroniz:any)
{
    return({
        type:"SET_HOLIDAY_SYNCHRONIZ",
        HolidaySynchroniz
    })
}
export function clearHolidaySynchroniz()
{
    return({
        type:"CLEAR_HOLIDAY_SYNCHRONIZ"
    })
}