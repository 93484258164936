import 'styles/TableStyle/index.css';

import { Button, Col, Dropdown, Icon, Menu, Row, Table } from 'antd';

import ButtonComplaintContainer from 'module/Complaint/Container/ButtonComplaintContainer';
import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import FormComplainContainer from 'module/Complaint/Container/FormComplaintContainer';
import React from 'react';
import SearchMatrial from 'materials/SearchMatrial';
import SpinMaterial from 'materials/SpinMaterial';

interface IProps {
  isLoading: any;
  ComplaintListData: any;
  ValueSearch: any;
  PagePagination: any;
  TotalPagePagination: any;
  handleSearch: any;
  handlePage: any;
  handleModal: any;
  page: any;
  getKey: any;
  filterStatus: any;
  handleFilter: any;
}

export default function IndexComplaintComponent({
  isLoading,
  ComplaintListData,
  ValueSearch,
  handleSearch,
  handlePage,
  handleModal,
  page,
  getKey,
  handleFilter,
}: IProps) {
  const filterLabel =
    getKey === null
      ? 'Filter'
      : getKey === 'process'
      ? 'Proses'
      : getKey === 'finished'
      ? 'Selesai'
      : 'Semua';
  const menu = (
    <Menu onClick={handleFilter} selectedKeys={[getKey]}>
      <Menu.Item key="all">&nbsp; Semua</Menu.Item>
      <Menu.Item key="process">&nbsp; Proses</Menu.Item>
      <Menu.Item key="finished">&nbsp; Selesai</Menu.Item>
    </Menu>
  );
  const loopPage = (page - 1) * 10 + 1;
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      width: 30,
      render: (text: any, record: any, index: any) => (
        <span>{page > 1 ? index + loopPage : index + 1}</span>
      ),
    },
    {
      title: 'Tiket',
      dataIndex: 'tiket',
      render: (tiket: any) => <span style={{ color: 'red' }}>{tiket}</span>,
      key: 'tiket',
      width: 90,
    },
    {
      title: 'Waybill',
      dataIndex: 'waybillCode',
      render: (waybillCode: any) => (
        <span style={{ fontSize: '12px' }}>{waybillCode}</span>
      ),
      key: 'waybillCode',
      width: 140,
    },
    {
      title: 'Komplain',
      dataIndex: 'question',
      render: (question: any) => (
        <span style={{ color: 'red' }}>{question}</span>
      ),
      key: 'question',
      width: 190,
    },
    {
      title: 'Status',
      dataIndex: 'stats',
      render: (stats: any) => (
        <span style={stats === 0 ? { color: '#31E116' } : { color: '#969696' }}>
          {stats === 0 ? 'Proses' : 'Selesai'}
        </span>
      ),
      key: 'stats',
      width: 50,
    },
    {
      title: 'Aksi',
      key: 'aksi',
      width: 80,
      render: (record: any) => (
        <div>
          <Row>
            <Col span={12} style={{ paddingRight: '5px' }}>
              <ButtonComplaintContainer
                idComplain={record.id}
                ticketStatus={record.stats}
                typeButton={'Update'}
              />
            </Col>
            <Col span={12}>
              <ButtonComplaintContainer
                idComplain={record.id}
                ticketStatus={record.stats}
                typeButton={'Change'}
              />
            </Col>
          </Row>
        </div>
      ),
    },
  ];
  return (
    <div style={{ padding: '35px' }}>
      <FormComplainContainer />
      <SpinMaterial
        spinning={isLoading}
        style={{}}
        size={'large'}
        content={
          <div>
            <CardMaterial
              extra={''}
              title={''}
              style={{}}
              content={
                <div>
                  <Row>
                    <Col span={16}>
                      <div>
                        <span
                          style={{ color: '#11BEFF', paddingRight: '20px' }}
                        >
                          <b>KOMPLAIN</b>
                        </span>
                        <SearchMatrial
                          value={ValueSearch}
                          style={{ borderRadius: '10px', width: '30%' }}
                          placeholder={'Cari berdasarkan tiket'}
                          onChange={handleSearch}
                        />
                        <Dropdown overlay={menu} trigger={['click']}>
                          <Button
                            type="link"
                            style={{ paddingLeft: '20px' }}
                            href="#"
                          >
                            <span style={{ color: 'black' }}>
                              {filterLabel}&nbsp;&nbsp;
                            </span>
                            <Icon style={{ color: 'black' }} type="down" />
                          </Button>
                        </Dropdown>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div style={{ float: 'right' }}>
                        <ButtonMaterial
                          disabled={''}
                          handleSubmit={handleModal}
                          teksButton={<b>TAMBAHKAN TIKET</b>}
                          size={''}
                          shape={''}
                          className={''}
                          type={'link'}
                          icon={''}
                          style={{
                            color: '#11BEFF',
                            paddingRight: '15px',
                          }}
                        />

                        <ButtonMaterial
                          disabled={''}
                          handleSubmit={handleModal}
                          teksButton={''}
                          size={''}
                          shape={'circle'}
                          className={''}
                          type={'primary'}
                          icon={'plus'}
                          style={{
                            color: '#fafafa',
                            paddingBottom: '3px',
                            background: '#31E116',
                            borderColor: '#31E116',
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              }
            ></CardMaterial>
            <Table
              pagination={{
                onChange: page => {
                  handlePage(page);
                },
                pageSize: 10,
                // total:TotalPagePagination,
                // current:PagePagination===0?1:PagePagination
              }}
              bordered={false}
              dataSource={ComplaintListData.filter((data: any) =>
                data.tiket.toString().toLowerCase().includes(ValueSearch)
              )}
              columns={columns}
            />
          </div>
        }
      ></SpinMaterial>
    </div>
  );
}
