import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getDataTableScanManifestBrankas,
  getManifestBrankas,
  getManifestBrankasCounting,
  postScanManifestBrankas,
  deleteScanManifestBrankas,
  downloadManifestBrankasScan,
} from 'services/ManifestSaveBrankas';
import ModalScanManifestBrankasComponent from '../components/ModalScanManifestBrankas';
import ModalSelectBarcodeMultiple from 'components/ModalSelectBarcodeMultiple';
import { ScanBarcodeMultiple } from 'module/DeliveryBetweenPosts/Component/BaggingDocument/Components/ModalScanWaybil';
import {
  DetailManifestBrankasProps,
  CountingManifestBrankasProps,
} from 'interface/general';
import ModalStatus from 'materials/ModalStatus';
import { downloadBlob, getQueryParamString } from 'lib/util';
import { MANIFEST_BRANKAS_DOWNLOAD_TYPE } from 'lib/constants';

type ModalScanManifestBrankasProps = {
  isShowModalScan: number | null;
  setIsShowModalScan: () => void;
  refetchTable: () => void;
};

export default function ModalScanManifestBrankas({
  isShowModalScan,
  setIsShowModalScan,
  refetchTable,
}: ModalScanManifestBrankasProps) {
  const [waybill, setWaybill] = useState<string>('');
  const [successWaybill, setSuccessWaybill] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isBarcode, setIsBarcode] = useState<boolean>(false);
  const [successMultipleBarcode, setSuccessMultipleBarcode] =
    useState<boolean>(false);
  const [dataScanBarcode, setDataScanBarcode] = useState<ScanBarcodeMultiple[]>(
    []
  );
  const [page, setPage] = useState<number>(0);
  const [selectedWaybill, setSelectedWaybill] = useState<string | null>(null);

  const {
    data: dataTableScan,
    isFetching: isLoadingTableScan,
    refetch: refetchTableScan,
  } = useQuery(
    ['TableScanBrankas', page, isShowModalScan],
    () => getDataTableScanManifestBrankas({ page, id: isShowModalScan }),
    {
      enabled: !!isShowModalScan,
    }
  );

  const { data: dataManifest, isFetching: isLoadingDetail } = useQuery<
    AxiosResponse<DetailManifestBrankasProps>
  >(
    ['DataDetailManifest', isShowModalScan],
    () => getManifestBrankas(isShowModalScan),
    {
      enabled: !!isShowModalScan,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: dataCounting,
    isFetching: isLoadingCounting,
    refetch: refetchCounting,
  } = useQuery<AxiosResponse<CountingManifestBrankasProps>>(
    ['DataCountingManifest', isShowModalScan],
    () => getManifestBrankasCounting(isShowModalScan),
    {
      enabled: !!isShowModalScan,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: mutateScan, isLoading: isLoadingScan } = useMutation(
    postScanManifestBrankas,
    {
      onSuccess: (res: any) => {
        if (res.status === 200 || res.status === 204) {
          if (res.data) {
            const response = res.data;
            if (response.isMultiple) {
              setSuccessMultipleBarcode(response.isMultiple);
              setDataScanBarcode(response.data);
              return;
            }

            if (
              (!isBarcode && !response.isMultiple) ||
              (isBarcode && !response.isMultiple)
            ) {
              setSuccessWaybill(waybill);
              setPage(0);
              refetchTableScan();
              refetchTable();
              refetchCounting();
              setIsSuccess(true);
              setSuccessMultipleBarcode(false);
              setWaybill('');
            }
          }
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    }
  );

  const { mutate: mutateDeleteWaybil, isLoading: isLoadingDelete } =
    useMutation(deleteScanManifestBrankas, {
      onSuccess: (res: any) => {
        if (res.status === 200 || res.status === 204) {
          ModalStatus({
            status: 'success',
            title: 'Berhasil Dihapus',
            content: `Waybill telah di hapus`,
          });
          setSelectedWaybill(null);
          setPage(0);
          refetchTableScan();
          refetchTable();
          refetchCounting();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const { mutate: downloadManifestScan, isLoading: isLoadingDownload } =
    useMutation(downloadManifestBrankasScan, {
      onSuccess: res => {
        if (res.status === 200) {
          const nameFile = getNameDownloadFile(res.config.url);
          downloadBlob(res.data, nameFile, 'xlsx');
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Menjalankan Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const getNameDownloadFile = (url: string) => {
    let nameFile = '';
    const selectedTypeDownload = getQueryParamString(
      url,
      'conditionResultManifest'
    );
    switch (selectedTypeDownload) {
      case MANIFEST_BRANKAS_DOWNLOAD_TYPE.ScanUser:
        nameFile = 'Bundle Manifest yang sudah di Scan';
        break;
      case MANIFEST_BRANKAS_DOWNLOAD_TYPE.Scan:
        nameFile = 'Total Manifest yang sudah di Scan';
        break;
      case MANIFEST_BRANKAS_DOWNLOAD_TYPE.UnScan:
        nameFile = 'Total Manifest yang belum di Scan';
        break;
      case MANIFEST_BRANKAS_DOWNLOAD_TYPE.Total:
        nameFile = 'Total Semua Manifest';
        break;
      default:
        nameFile = 'Bundle Manifest yang sudah di Scan';
        break;
    }

    return nameFile;
  };

  const handleEnterWaybil = value => {
    const detailData = dataManifest?.data || null;
    const barcodeValue = isBarcode && !successMultipleBarcode ? value : '';
    const waybillValue =
      (!isBarcode && !successMultipleBarcode) ||
      (isBarcode && successMultipleBarcode)
        ? [value]
        : '';
    const data = {
      brankasType: detailData?.brankasType,
      bundleCode: detailData?.bundleCode,
      waybill: waybillValue,
      barcode: barcodeValue,
    };

    mutateScan(data);
  };

  const handleSubmitWaybll = (waybill?: any) => {
    const detailData = dataManifest?.data || null;

    const data = {
      brankasType: detailData?.brankasType,
      bundleCode: detailData?.bundleCode,
      waybill: waybill || '',
      barcode: '',
    };

    mutateScan(data);
  };

  const handleCloseModalScan = () => {
    setIsShowModalScan();
    setWaybill('');
    setSuccessWaybill('');
    setIsSuccess(false);
  };

  const handleDeleteWaybil = (id: number) => {
    mutateDeleteWaybil(id);
  };

  const handleDownloadManifestScan = async (type: string) => {
    downloadManifestScan({ id: isShowModalScan, type });
  };

  return (
    <>
      <ModalScanManifestBrankasComponent
        isShowModalScan={isShowModalScan}
        setIsShowModalScan={handleCloseModalScan}
        waybill={waybill}
        setWaybill={setWaybill}
        successWaybill={successWaybill}
        isSuccess={isSuccess}
        isBarcode={isBarcode}
        setIsBarcode={setIsBarcode}
        setIsSuccess={setIsSuccess}
        setPage={setPage}
        handleEnterWaybil={handleEnterWaybil}
        selectedWaybill={selectedWaybill}
        setSelectedWaybill={setSelectedWaybill}
        dataTableScan={dataTableScan?.data}
        isLoadingTableScan={isLoadingTableScan || isLoadingDelete}
        isLoadingDetail={
          isLoadingDetail ||
          isLoadingCounting ||
          isLoadingScan ||
          isLoadingDownload
        }
        dataManifest={dataManifest?.data}
        dataCounting={dataCounting?.data}
        handleDeleteWaybil={handleDeleteWaybil}
        handleDownloadManifestScan={handleDownloadManifestScan}
      />
      <ModalSelectBarcodeMultiple
        dataScanBarcode={dataScanBarcode}
        isShowModal={successMultipleBarcode}
        handleCloseModal={() => setSuccessMultipleBarcode(false)}
        handleSubmit={value => handleSubmitWaybll(value)}
        isLoadingScan={isLoadingScan}
      />
    </>
  );
}
