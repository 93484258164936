import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getAllBranch } from 'services/GeneralService';
import { getTabMenus, getParams } from 'lib/util';

import SlaPengiriman from './SlaPengiriman';
import JamPengiriman from './JamPengiriman';
import PerformaKurir from './PerformaKurir';
import PerformaPosko from './PerformaPosko';

const { TabPane } = Tabs;
type RingkasanProps = {
  IdBranch: number;
  roleAccount: string;
  GetUsername: string;
  sideMenus: any;
};

export function Ringkasan({
  IdBranch,
  roleAccount,
  GetUsername,
  sideMenus,
}: RingkasanProps) {
  const activeTab = getParams('activeTab');
  const [defaultTab, setDefaultTab] = useState<string>('1');

  const { data: dataAllBranch, isLoading: isLoadingBranch } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataBranchReportFile'],
    queryFn: () => getAllBranch(),
  });

  const tabMenus = getTabMenus(sideMenus, 'Laporan', 'Ringkasan') || [];

  useEffect(() => {
    if (activeTab) {
      setDefaultTab('3');
    }
  }, [activeTab]);

  const componentTabMenus = {
    'SLA Pengiriman': (
      <SlaPengiriman
        dataAllBranch={dataAllBranch?.data || []}
        isLoadingBranch={isLoadingBranch}
        IdBranch={IdBranch}
        roleAccount={roleAccount}
        GetUsername={GetUsername}
      />
    ),
    'Jam Pengiriman': (
      <JamPengiriman
        dataAllBranch={dataAllBranch?.data || []}
        isLoadingBranch={isLoadingBranch}
        IdBranch={IdBranch}
        roleAccount={roleAccount}
        GetUsername={GetUsername}
      />
    ),
    'Performa Posko': (
      <PerformaPosko
        dataAllBranch={dataAllBranch?.data || []}
        isLoadingBranch={isLoadingBranch}
        IdBranch={IdBranch}
        roleAccount={roleAccount}
        GetUsername={GetUsername}
      />
    ),
    'Performa Kurir': (
      <PerformaKurir
        dataAllBranch={dataAllBranch?.data || []}
        isLoadingBranch={isLoadingBranch}
        IdBranch={IdBranch}
        roleAccount={roleAccount}
        GetUsername={GetUsername}
      />
    ),
  };

  return (
    <div>
      <Tabs
        style={{ padding: '35px' }}
        activeKey={defaultTab}
        onChange={key => setDefaultTab(key)}
      >
        {tabMenus.map((item, index) => (
          <TabPane tab={<b>{item.label}</b>} key={index + 1}>
            {componentTabMenus[item.label]}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  IdBranch: state.Auth.BranchAuth,
  roleAccount: state.Auth.Role,
  GetUsername: state.Auth.UsernameAuth,
  sideMenus: state.Auth.SideMenus,
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(Ringkasan);
