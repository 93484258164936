import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';

interface IProps {
  typeToggle: any;
  handleCustomerProductEdit: any;
  handleGetGroupingDetail: any;
}
export default class ButtonCustomerProductComponent extends Component<IProps> {
  render() {
    const handleSubmit =
      this.props.typeToggle === 'AddNewReportGrup'
        ? this.props.handleGetGroupingDetail
        : this.props.handleCustomerProductEdit;
    return (
      <React.Fragment>
        <ButtonMaterial
          disabled={''}
          handleSubmit={handleSubmit}
          teksButton={''}
          size={''}
          shape={''}
          className={'btnEdit'}
          type={''}
          icon={'edit'}
          style={{}}
        />
      </React.Fragment>
    );
  }
}
