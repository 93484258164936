import React from 'react';
import { Radio } from 'antd';
import moment from 'moment';
import { Form, Field } from 'react-final-form';
import styles from './CourierReportComponent.module.css';
import InputDatePicker from 'components/InputDatePicker';
import { SingleCheckbox } from 'components/SingleCheckbox';
import { fileFormat, statusDelivery } from 'lib/constants';
import Button from 'components/Button';
import SelectField from 'components/SelectField';
import SpinMaterial from 'materials/SpinMaterial';
import CardMaterial from 'materials/CardMaterial';

type CourierReportComponentNewProps = {
  ListCustomer: Array<any>;
  BranchList: Array<any>;
  productList: Array<any>;
  courierItems: Array<any>;
  handleSetCustomer: (value: any) => void;
  loadingSelectCustomer: boolean;
  loadingSelectBranch: boolean;
  isLoadingProduct: boolean;
  isLoadingCourier: boolean;
  isLoadingSubmit?: boolean;
  roleAccount: any;
  handleClearForm: () => void;
  handleSubmit: (value: any) => void;

  typeForm: any;
  startDateProcess: any;
  endDateProcess: any;
  typeReport: any;
  selectedBranch: Array<any>;
  selectedCustomer: number;
  selectAllCustomer: boolean;
  selectedCourier: Array<any>;
  selectedProduct: Array<any>;
  selectedFormatFile: string;
  typeFormatFile: any;
  selectedStatus: any;
  isSla: any;

  setTypeForm: (value: any) => void;
  setStartDateProcess: (value: any) => void;
  setEndDateProcess: (value: any) => void;
  setTypeReport: (value: any) => void;
  setSelectedBranch: (value: any) => void;
  setSelectAllCustomer: (value: any) => void;
  setSelectedCourier: (value: any) => void;
  setSelectedProduct: (value: any) => void;
  setSelectedFormatFile: (value: any) => void;
  setTypeFormatFile: (value: any) => void;
  setSelectedStatus: (value: any) => void;
  setIsSla: (value: any) => void;
};

export default function CourierReportComponentNew({
  ListCustomer,
  courierItems,
  loadingSelectCustomer,
  loadingSelectBranch,
  roleAccount,
  BranchList,
  handleSetCustomer,
  productList,
  isLoadingProduct,
  isLoadingCourier,
  handleClearForm,
  handleSubmit,
  isLoadingSubmit = false,
  // VALUE FORM
  typeForm,
  startDateProcess,
  endDateProcess,
  typeReport,
  selectedBranch,
  selectedCustomer,
  selectAllCustomer,
  selectedCourier,
  selectedProduct,
  selectedFormatFile,
  selectedStatus,
  isSla,
  // SET VALUE FORM
  setTypeForm = () => undefined,
  setStartDateProcess = () => undefined,
  setEndDateProcess = () => undefined,
  setTypeReport = () => undefined,
  setSelectedBranch = () => undefined,
  setSelectAllCustomer = () => undefined,
  setSelectedCourier = () => undefined,
  setSelectedProduct = () => undefined,
  setSelectedFormatFile = () => undefined,
  setTypeFormatFile = () => undefined,
  setSelectedStatus = () => undefined,
  setIsSla = () => undefined,
}: CourierReportComponentNewProps) {
  const minDateProses = (d: any) =>
    !d ||
    d.isBefore('2020-01-01') ||
    d.isAfter(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));

  return (
    <SpinMaterial
      spinning={isLoadingSubmit}
      size="large"
      content={
        <CardMaterial
          extra={''}
          title={<b className="titleCard">LAPORAN KURIR</b>}
          style={{ borderRadius: '10px', marginBottom: 100 }}
          content={
            <Form
              onSubmit={handleSubmit}
              validate={values => {
                const errors = {} as any;
                if (!values.startDate) {
                  errors.startDate = 'Tanggal wajib diisi';
                }
                if (!values.endDate) {
                  errors.endDate = 'Tanggal wajib diisi';
                }
                if (!values.typeReport) {
                  errors.typeReport = 'Jenis Laporan wajib diisi';
                }
                if (!values.allCustomer && !values.customer) {
                  errors.customer = 'Pelanggan wajib diisi';
                }
                if (values.status && values.status.length <= 0) {
                  errors.status = 'Status wajib diisi';
                }
                if (values.branch && values.branch.length <= 0) {
                  errors.branch = 'Posko wajib diisi';
                }
                if (values.courier && values.courier.length <= 0) {
                  errors.courier = 'Kurir wajib diisi';
                }
                if (
                  !values.allCustomer &&
                  values.product &&
                  values.product.length <= 0
                ) {
                  errors.product = 'Product wajib diisi';
                }

                return errors;
              }}
              initialValues={{
                typeForm: typeForm,
                startDate: startDateProcess,
                endDate: endDateProcess,
                typeReport: typeReport,
                branch: selectedBranch,
                customer: selectedCustomer,
                courier: selectedCourier,
                product: selectedProduct,
                formatFile: selectedFormatFile,
                allCustomer: selectAllCustomer,
                status: selectedStatus,
                sla: isSla,
              }}
              render={({ handleSubmit, values, invalid }) => (
                <form onSubmit={handleSubmit}>
                  <Field name="typeForm">
                    {({ input }) => (
                      <Radio.Group
                        onChange={e => {
                          input.onChange(e.target.value);
                          setTypeForm(e.target.value);
                        }}
                        value={input.value}
                      >
                        <Radio value={'Process'}>Proses</Radio>
                        <Radio value={'Cycle'}>Cycle</Radio>
                      </Radio.Group>
                    )}
                  </Field>

                  <div className={styles.wrapperRow}>
                    <div className={styles.wrapperColumn}>
                      <Field name="startDate">
                        {({ input, meta }) => (
                          <InputDatePicker
                            label={`Tanggal ${
                              values.typeForm === 'Process' ? 'Proses' : 'Cycle'
                            }`}
                            placeholder="Pilih Tanggal"
                            onOpenChange={input.onBlur}
                            onChange={value => {
                              input.onChange(value);
                              setStartDateProcess(value);
                            }}
                            value={input.value}
                            error={
                              meta.touched &&
                              meta.error !== undefined &&
                              meta.error
                            }
                            disabledDate={minDateProses}
                          />
                        )}
                      </Field>
                      <Field name="typeReport">
                        {({ input, meta }) => (
                          <SelectField
                            label="Jenis Laporan"
                            placeholder="Pilih Jenis Laporan"
                            data={[
                              { id: 'Detail', name: 'Detail' },
                              { id: 'Summary', name: 'Ringkasan' },
                            ]}
                            value={input.value || undefined}
                            validate
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            onChange={value => {
                              input.onChange(value);
                              setTypeReport(value);
                            }}
                            onBlur={value => {
                              input.onBlur(value);
                              setTypeReport(value);
                            }}
                          />
                        )}
                      </Field>
                      <div>
                        <Field name="customer">
                          {({ input, meta }) => (
                            <div>
                              <SelectField
                                label="Pelanggan"
                                placeholder="Pilih Pelanggan"
                                data={ListCustomer}
                                loading={loadingSelectCustomer}
                                value={input.value || undefined}
                                onChange={value => {
                                  input.onChange(value);
                                  handleSetCustomer(value);
                                }}
                                onBlur={value => {
                                  input.onBlur(value);
                                  handleSetCustomer(value);
                                }}
                                validate={!values.allCustomer}
                                disabled={values.allCustomer}
                                validateStatus={
                                  meta.error && meta.touched
                                    ? 'error'
                                    : 'validating'
                                }
                                errorMessage={
                                  meta.error && meta.touched && meta.error
                                }
                              />
                              <Field name="allCustomer">
                                {({
                                  input: inputCheckbox,
                                  meta: metaCheckbox,
                                }) => (
                                  <SingleCheckbox
                                    labelRight="Pilih Semua Pelanggan"
                                    input={inputCheckbox}
                                    meta={metaCheckbox}
                                    onChange={e => {
                                      setSelectAllCustomer(
                                        !inputCheckbox.value
                                      );
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                          )}
                        </Field>
                      </div>
                      <Field name="product">
                        {({ input, meta }) => (
                          <SelectField
                            label="Produk"
                            placeholder="Pilih Produk Pelanggan"
                            data={productList}
                            disabled={!values.customer}
                            loading={isLoadingProduct}
                            value={input.value || []}
                            onChange={value => {
                              input.onChange(value);
                              setSelectedProduct(value);
                            }}
                            onBlur={value => {
                              input.onBlur(value);
                              setSelectedProduct(value);
                            }}
                            validate
                            mode="multiple"
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            allowClear
                          />
                        )}
                      </Field>
                      <Field name="status">
                        {({ input, meta }) => (
                          <SelectField
                            label="Status"
                            placeholder="Pilih Status"
                            data={statusDelivery}
                            value={input.value || undefined}
                            onChange={value => {
                              input.onChange(value);
                              setSelectedStatus(value);
                            }}
                            onBlur={value => {
                              input.onBlur(value);
                              setSelectedStatus(value);
                            }}
                            validate
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            mode="multiple"
                          />
                        )}
                      </Field>
                    </div>
                    <div className={styles.wrapperColumn}>
                      <Field name="endDate">
                        {({ input, meta }) => (
                          <InputDatePicker
                            label="Sampai Tanggal"
                            placeholder="Pilih Tanggal"
                            disabledDate={(d: any) =>
                              !d ||
                              d.isBefore(
                                moment(values.startDate)
                                  .add(0, 'day')
                                  .format('YYYY-MM-DD')
                              ) ||
                              d.isAfter(
                                moment(values.startDate)
                                  .add(30, 'day')
                                  .format('YYYY-MM-DD')
                              )
                            }
                            onOpenChange={input.onBlur}
                            onChange={value => {
                              input.onChange(value);
                              setEndDateProcess(value);
                            }}
                            value={input.value}
                            error={
                              meta.touched &&
                              meta.error !== undefined &&
                              meta.error
                            }
                            disabled={!values.startDate}
                          />
                        )}
                      </Field>
                      <Field name="branch">
                        {({ input, meta }) => (
                          <SelectField
                            label="Posko"
                            placeholder="Pilih Posko"
                            data={BranchList}
                            loading={loadingSelectBranch}
                            disabled={roleAccount === 'Branch'}
                            mode="multiple"
                            validate
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            onChange={value => {
                              input.onChange(value);
                              setSelectedBranch(value);
                            }}
                            onBlur={value => {
                              input.onBlur(value);
                            }}
                            value={
                              BranchList.length > 0 &&
                              input.value.length === BranchList.length - 1
                                ? [0]
                                : input.value
                            }
                            allowClear
                            isNotShowAll
                          />
                        )}
                      </Field>
                      <Field name="courier">
                        {({ input, meta }) => (
                          <>
                            <SelectField
                              label="Kurir"
                              placeholder="Pilih Kurir"
                              data={courierItems}
                              disabled={selectedBranch.length <= 0}
                              mode="multiple"
                              loading={isLoadingCourier}
                              validate
                              validateStatus={
                                meta.error && meta.touched
                                  ? 'error'
                                  : 'validating'
                              }
                              errorMessage={
                                meta.error && meta.touched && meta.error
                              }
                              onChange={value => {
                                input.onChange(value);
                                setSelectedCourier(value);
                              }}
                              onBlur={value => {
                                input.onBlur(value);
                              }}
                              value={
                                courierItems.length > 0 &&
                                input.value.length === courierItems.length - 1
                                  ? [0]
                                  : input.value
                              }
                              allowClear
                              isNotShowAll
                            />
                          </>
                        )}
                      </Field>
                      <div>
                        <Field name="formatFile">
                          {({ input }) => (
                            <SelectField
                              label="Format File"
                              placeholder="Pilih Format File"
                              data={fileFormat}
                              onChange={value => {
                                input.onChange(value);
                                setSelectedFormatFile(value);
                              }}
                              onBlur={value => {
                                input.onBlur(value);
                              }}
                              value={input.value}
                              disabled={values.typeReport === 'ringkasan'}
                            />
                          )}
                        </Field>
                        <Field name="sla">
                          {({ input }) => (
                            <SingleCheckbox
                              isOptional
                              labelRight="> SLA"
                              input={input}
                              onChange={() => setIsSla(!input.value)}
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                  </div>

                  <div className={styles.wrapperAction}>
                    <Button
                      text="Reset"
                      variant="danger"
                      disabled={invalid}
                      onClick={handleClearForm}
                    />
                    <Button
                      text="Download"
                      variant="info"
                      htmlType="submit"
                      disabled={invalid}
                      loading={isLoadingSubmit}
                    />
                  </div>
                </form>
              )}
            />
          }
        />
      }
    />
  );
}
