import * as ActionCustomerAccount from '../../../action/ManageAccount/Customer/ActionCustomerAccount';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_getDetailCustomerAccount() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const codeCustomerAccount = state.Customer.CustomerAccount.Username;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Profile/initial/' + codeCustomerAccount,
      { headers: config }
    );

    yield put(
      ActionCustomerAccount.changeFirstNameAccount(data.profile.firstName)
    );
    yield put(
      ActionCustomerAccount.changeLastNameAccount(data.profile.lastName)
    );
    yield put(
      ActionCustomerAccount.changeUserNameAccount(data.profile.userName)
    );
    yield put(
      ActionCustomerAccount.changePhoneNumberAccount(data.profile.phone)
    );
    yield put(ActionCustomerAccount.changeEmailAccount(data.profile.email));
    yield put(ActionCustomerAccount.changeAddressAccount(data.profile.address));
    yield put(
      ActionCustomerAccount.changeCustomerProduct(data.profile.productBlock)
    );
    yield put(
      ActionCustomerAccount.changeStatusAccount(data.profile.isActived)
    );
    yield put(ActionCustomerAccount.changeIdCustomerAccount(data.profile.id));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      const propsError = ErrorMassage('DETAIL AKUN PELANGGAN');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_getDetailCustomerAccount() {
  yield takeLatest('GET_CUSTOMER_ACCOUNT_DETAIL', S_getDetailCustomerAccount);
}
