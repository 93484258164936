import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import { compose } from 'redux';
import { connect } from 'react-redux';

export function TemplateBerandaComponent(ComponentComposed: any) {
  interface IProps {
    jenisToggle: any;
    isLoading: any;
  }
  function ComposedComponent(props: IProps) {
    return (
      <SpinMaterial
        spinning={props.jenisToggle === 'logout' ? true : false}
        style={{ paddingTop: '300px' }}
        size={'large'}
        content={<ComponentComposed />}
      />
    );
  }
  const mapStateToProps = (state: any) => ({
    jenisToggle: state.Toggle.jenisToggle,
    isLoading: state.Toggle.isLoading,
  });
  const withConnect = connect(mapStateToProps);

  return compose(withConnect)(ComposedComponent);
  // return ComposedComponent
}
