import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionScanManifest from '../../../action/Manifest/ScanManifest/ActionScanManifest';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_GetCountScanned(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const RadioButton =
    state.ScanManifest.ScanManifestForm.ManifestRadioButtonForm === 'Success'
      ? true
      : false;
  const Cycle = moment(
    state.ScanManifest.ScanManifestForm.ManifestCycleDateForm
  ).format(
    state.ScanManifest.ScanManifestForm.ManifestMonthly ? 'YYYYMM' : 'YYYYMMDD'
  );
  const Customer =
    state.ScanManifest.ScanManifestForm.ManifestCustomerForm === null
      ? 0
      : state.ScanManifest.ScanManifestForm.ManifestCustomerForm;
  const Product =
    state.ScanManifest.ScanManifestForm.ManifestProductForm === null
      ? 0
      : state.ScanManifest.ScanManifestForm.ManifestProductForm;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const username = state.Auth.UsernameAuth;
  const Monthly = state.ScanManifest.ScanManifestForm.ManifestMonthly;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/GroupManifest/Scan/Count/${Cycle}/${Customer}/${Product}/${RadioButton}/${Monthly}/${username}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionScanManifest.changeCountScanned(data.scan));
    yield put(ActionScanManifest.changeCountUnScanned(data.unScan));
    yield put(ActionScanManifest.changeTotalScanned(data.total));
    yield put(ActionScanManifest.changeScannedByUser(data.userScan));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_GetCountScanned() {
  yield takeLatest('GET_COUNT_SCANNED', S_GetCountScanned);
}
