import * as ActionDeliverylBranch from 'core/action/Branch/ActionDeliveryBranch';
import * as ActionDetailBranch from 'core/action/Branch/ActionDetailBranch';
import * as ActionToggle from 'core/action/toggle/index';
import * as TableBranch from 'core/action/Branch/ActionTableBranch';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import DashboardComponent from 'module/Dashboard/Component/Pengiriman/DashboardComponent';
import { connect } from 'react-redux';
import history from 'core/utils/History';

// import jwt_decode from 'jwt-decode';

interface IProps {
  actiontablebranch: any;
  actionDetailBranch: any;
  actionDeliverylBranch: any;
  isloading: any;
  actionToggle: any;
  toggleType: any;
  deliveryBranch: any;
  ValueSearch: any;
  data?: any;
}
function DashboardContainer({
  deliveryBranch,
  isloading,
  ValueSearch,
  toggleType,
  actionToggle,
  actionDetailBranch,
  actiontablebranch,
  actionDeliverylBranch,
  data,
}: IProps) {
  // constructor(props: any) {
  //   super(props);
  //   this.state = {
  //     sRT: null,
  //     searchDeliveryBranch: deliveryBranch,
  //     nameSearch: false,
  //   };
  //   this.handleSearch = this.handleSearch.bind(this);
  //   this.handleResetSearch = this.handleResetSearch.bind(this);
  //   this.handleDetailBranch = this.handleDetailBranch.bind(this);
  // }

  // const [nameSearch, setNameSearch] = useState(false);
  // const [sRT, setSRT] = useState(null);
  // const [searchDeliveryBranch, setSearchDeliveryBranch] =
  //   useState(deliveryBranch);

  function handleSearch(event: any) {
    actionToggle.changeValueSearch(event.target.value.toLowerCase());
  }
  function handleDetailBranch(event: any) {
    actionDetailBranch.setIdAdminBranch(event);
    actionDetailBranch.changeDetailBranch();
    history.push('/branch/detail');
  }
  function handleResetSearch(clearFilters: any) {
    clearFilters();
    // setSRT(null);
  }
  // function getDecodedAccessToken(token: string): any {
  //   try {
  //     return jwt_decode(token);
  //   } catch (Error) {
  //     return null;
  //   }
  // }

  useEffect(() => {
    // actiontablebranch.GetTableBranch();
    // actionDeliverylBranch.getDeliveryBranch();
    // if (data.length === 0) {
    // const nameee= "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
    // const token =
    //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMjhhY2I3Yi03NjczLTQ2ZmItOTI5ZC1hOTNjOWU4MmJiZDYiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjYiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoic3VwZXJhZG1pbiIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJzZWN1cml0eS51c2VyLnZpZXciLCJzZWN1cml0eS51c2VyLmNyZWF0ZSIsInNlY3VyaXR5LnVzZXIudXBkYXRlIiwic2VjdXJpdHkudXNlci5kZWxldGUiXSwiZXhwIjoxNTg5NDI0ODg5LCJpc3MiOiJodHRwOi8vcXVhZHJhbnQtc2kuaWQiLCJhdWQiOiJRU0lRTUNPVVJJRVIifQ.wpwZeG3h1veF2_D1zi1njsyAgBK2xONMySx5_RBHW14';
    // let tokenInfo = getDecodedAccessToken(token); // decode token
    // let expireDate =
    //   tokenInfo[
    //     'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    //   ]; // get token expiration dateTime
    // if (expireDate.indexOf('security.user.vie') !== -1) {
    //   console.log('match');
    // } else {
    //   console.log('No Match');
    // }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // componentWillMount() {
  // actiontablebranch.GetTableBranch()
  // actionDeliverylBranch.getDeliveryBranch();
  // if(data.length==0){
  // const nameee= "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
  // const token="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMjhhY2I3Yi03NjczLTQ2ZmItOTI5ZC1hOTNjOWU4MmJiZDYiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjYiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoic3VwZXJhZG1pbiIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6WyJzZWN1cml0eS51c2VyLnZpZXciLCJzZWN1cml0eS51c2VyLmNyZWF0ZSIsInNlY3VyaXR5LnVzZXIudXBkYXRlIiwic2VjdXJpdHkudXNlci5kZWxldGUiXSwiZXhwIjoxNTg5NDI0ODg5LCJpc3MiOiJodHRwOi8vcXVhZHJhbnQtc2kuaWQiLCJhdWQiOiJRU0lRTUNPVVJJRVIifQ.wpwZeG3h1veF2_D1zi1njsyAgBK2xONMySx5_RBHW14"
  // let tokenInfo = this.getDecodedAccessToken(token); // decode token
  // let expireDate = tokenInfo["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"]; // get token expiration dateTime
  // if(expireDate.indexOf("security.user.vie") !== -1) {
  //     console.log('match');
  //   }else{
  //     console.log('No Match');
  //   }
  // }
  // componentWillUnmount() {
  //   actionDeliverylBranch.clearDeliveryBranch();
  // }
  return (
    <div>
      <DashboardComponent
        deliveryBranch={deliveryBranch}
        isloading={isloading}
        ValueSearch={ValueSearch}
        toggleType={toggleType}
        nameSearch={false}
        // nameSearch={nameSearch}
        searchDeliveryBranch={deliveryBranch}
        // searchDeliveryBranch={searchDeliveryBranch}
        handleSearch={handleSearch}
        handleResetSearch={handleResetSearch}
        handleDetailBranch={handleDetailBranch}
      />
    </div>
  );
}
const mapStateProps = (state: any) => ({
  isloading: state.Toggle.isLoading,
  ValueSearch: state.Toggle.ValueSearch,
  toggleType: state.Toggle.jenisToggle,
  deliveryBranch: state.Branch.DeliveryBranch,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actiontablebranch: bindActionCreators(TableBranch, dispatch),
  actionDetailBranch: bindActionCreators(ActionDetailBranch, dispatch),
  actionDeliverylBranch: bindActionCreators(ActionDeliverylBranch, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(DashboardContainer);
