import * as ActionAuth from '../../action/Auth';
import * as ActionFieldCustomer from '../../action/Customer/ActionFieldCustomer';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_GetDetailCustomer(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const route = state.router.location.pathname;
  const IdCustomer =
    route !== '/dataDelivery'
      ? state.Customer.PropertiesCustomer.IdCustomer
      : state.DataDelivery.Filter.Customer;
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Customer/' + IdCustomer,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionFieldCustomer.setCustomerName(data.name));
    yield put(ActionFieldCustomer.setCustomerCode(data.code));
    if (state.Toggle.jenisToggle === 'EditCustomer') {
      if (data.customerType === 'Bank' || data.customerType === 'Asuransi') {
        yield put(ActionFieldCustomer.setCustomerType(data.customerType));
      } else {
        yield put(ActionFieldCustomer.setCustomerType('Lainnya'));
        yield put(ActionFieldCustomer.setCustomerTypeOther(data.customerType));
      }
    } else {
      yield put(ActionFieldCustomer.setCustomerType(data.customerType));
    }
    yield put(ActionFieldCustomer.setCustomerTelephone(data.phone));
    yield put(ActionFieldCustomer.setCustomerEmail(data.email));
    yield put(ActionFieldCustomer.setCustomerAddress(data.address));
    yield put(ActionFieldCustomer.setCustomerAccess(data.access));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_GetDetailCustomer() {
  yield takeLatest('CHANGE_DETAIL_CUSTOMER', S_GetDetailCustomer);
}
