import { UseBaseQueryOptions } from '@tanstack/react-query';
import instance from 'config/interceptor';

export const getListSyncDataDelivery = async (params: UseBaseQueryOptions) => {
  const [, page, size] = params.queryKey || [];

  const { data } = await instance.get(`/SyncDataDelivery/${page}/${size}`);
  return data;
};

export const mutateSyncData = async (params: any) => {
  return await instance.put(`/SyncDataDelivery/SyncData`, params);
};

export const getDetailSyncDataDelivery = async (
  params: UseBaseQueryOptions
) => {
  const [, id, page, size] = params.queryKey || [];

  const { data } = await instance.get(
    `/SyncDataDelivery/${id}/${page}/${size}`
  );
  return data;
};
