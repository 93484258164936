import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import IndexDeliveryComponent from '../../../../components/Content/Delivery/Document/IndexDeliveryComponent';
import * as LastAccess from '../../../../core/action/LastAccess/index';
import * as ActionFormUnggahDocument from '../../../../core/action/Delivery/Document/ActionFormUnggahDocument';
import * as ActionFormDistribusi from '../../../../core/action/Delivery/Document/ActionFormDistribusi';
import * as ActionFormPrintWaybill from '../../../../core/action/Delivery/Document/ActionFormPrintWaybill';
import history from '../../../../core/utils/History';

interface IProps {
  actionLastAccess: any;
  actionValidateReport: any;
  actionFormUnggahDocument: any;
  actionFormDistribusi: any;
  actionFormPrintWaybill: any;
  Fiture: any;
  parsingFromKU: any;
  sideMenus: any;
}

class IndexDeliveryContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleChangeTabs = this.handleChangeTabs.bind(this);
  }

  handleChangeTabs(event: any) {
    this.props.actionFormDistribusi.ClearAllFormDistribution();
    this.props.actionFormUnggahDocument.clearAllFormUnggahCsv();
    this.props.actionFormPrintWaybill.clearListData();

    if (event === '1') {
      this.props.actionLastAccess.changeLastAccessFiture('deliveryUploadData');
    } else if (event === '2') {
      this.props.actionLastAccess.changeLastAccessFiture(
        'deliveryDistribution'
      );
    } else if (event === '3') {
      this.props.actionLastAccess.changeLastAccessFiture(
        'deliveryPrintWaybill'
      );
    }
  }

  componentWillMount() {
    if (this.props.parsingFromKU !== undefined) {
      history.push('/delivery/document');
      this.props.actionLastAccess.changeLastAccessFiture(
        'deliveryDistribution'
      );
    }
  }

  render() {
    return (
      <IndexDeliveryComponent
        lastAccessFiture={this.props.Fiture}
        handleChangeTabs={this.handleChangeTabs}
        sideMenus={this.props.sideMenus}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  Fiture: state.LastAccess.Fiture,
  parsingFromKU: state.router.location.search.split('?')[1],
  sideMenus: state.Auth.SideMenus,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
  actionFormUnggahDocument: bindActionCreators(
    ActionFormUnggahDocument,
    dispatch
  ),
  actionFormDistribusi: bindActionCreators(ActionFormDistribusi, dispatch),
  actionFormPrintWaybill: bindActionCreators(ActionFormPrintWaybill, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IndexDeliveryContainer);
