export function setPhotoProfile(Photo: any) {
  return {
    type: 'SET_PHOTO_PROFILE',
    Photo,
  };
}
export function setInitialsProfile(Initials: any) {
  return {
    type: 'SET_INITIALS_PROFILE',
    Initials,
  };
}
export function setFirstNameProfile(FirstName: any) {
  return {
    type: 'SET_FIRST_NAME_PROFILE',
    FirstName,
  };
}
export function setLastNameProfile(LastName: any) {
  return {
    type: 'SET_LAST_NAME_PROFILE',
    LastName,
  };
}
export function setUserNameProfile(UserName: any) {
  return {
    type: 'SET_USER_NAME_PROFILE',
    UserName,
  };
}
export function setNoTelpProfile(NoTelp: any) {
  return {
    type: 'SET_NO_TELP_PROFILE',
    NoTelp,
  };
}
export function setEmailProfile(Email: any) {
  return {
    type: 'SET_EMAIL_PROFILE',
    Email,
  };
}
export function setAddressProfile(Address: any) {
  return {
    type: 'SET_ADDRESS_PROFILE',
    Address,
  };
}
export function getDataProfile() {
  return {
    type: 'GET_DATA_PROFILE',
  };
}
export function editDataProfile() {
  return {
    type: 'EDIT_DATA_PROFILE',
  };
}
export function changeClearProfile() {
  return {
    type: 'CHANGE_CLEAR_PROFILE',
  };
}
export function changeClearDataProfile() {
  return {
    type: 'CHANGE_CLEAR_DATA_PROFILE',
  };
}
