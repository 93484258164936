export function setListSubmitMprocess(ListSubmitManualProcess:any)
{
    return({
        type:"SET_LIST_SUBMIT_MANUAL_PROCESS",
        ListSubmitManualProcess
    })
}
export function clearListMprocess()
{
    return({
        type:"CLEAR_LIST_MANUAL_PROCESS"
    })
}