import * as ActionDeliveryReport from 'core/action/Report/DeliveryReport/ActionDeliveryReport';
import * as ActionListCustomer from 'core/action/Customer/ActionListCustomer';
import * as Branch from 'core/action/Branch/ActionAllBranch';
import * as LastAccess from 'core/action/LastAccess/index';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import DeliveryReportComponent from 'module/Report/Component/DeliveryReport/DeliveryReportComponent';
import { connect } from 'react-redux';
import moment from 'moment';

interface IProps {
  roleAccount: any;
  branchAuth: any;
  GetFirstDate: any;
  GetLastDate: any;
  GetBranch: any;
  GetCustomer: any;
  GetCheckboxToday: any;
  ListBranch: any;
  ListCustomer: any;
  actionDeliveryReport: any;
  actionBranch: any;
  actionListCustomer: any;
  LoadingSelectBranch: any;
  LoadingSelectCustomer: any;
  TypeLoadingSelectBranch: any;
  TypeLoadingSelectCustomer: any;
  isLoading: any;
  Fiture: any;
}

function DeliveryReportContainer({
  roleAccount,
  branchAuth,
  GetFirstDate,
  GetLastDate,
  GetBranch,
  GetCustomer,
  GetCheckboxToday,
  ListBranch,
  ListCustomer,
  actionDeliveryReport,
  actionBranch,
  actionListCustomer,
  LoadingSelectBranch,
  LoadingSelectCustomer,
  TypeLoadingSelectBranch,
  TypeLoadingSelectCustomer,
  isLoading,
  Fiture,
}: IProps) {
  const [ValueFirstDate, setValueFirstDate]: any = useState(null);
  const [ValidateFirstDate, setValidateFirstDate]: any = useState(null);
  const [ValidateFirstDate2, setValidateFirstDate2]: any = useState(null);
  const [ValueLastDate, setValueLastDate]: any = useState(null);
  const [ValidateLastDate, setValidateLastDate]: any = useState(null);
  const [ValidateLastDate2, setValidateLastDate2]: any = useState(null);
  const [ValidateCustomer, setValidateCustomer]: any = useState(null);
  const [ValidateBranch, setValidateBranch]: any = useState(null);

  function handleChangeFirstDate(event: any) {
    setValueFirstDate(event);
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      const dateValueConvert = moment(dateValue).format('YYYYMMDD');
      const lastDateValueConvert = moment(GetLastDate).format('YYYYMMDD');
      actionDeliveryReport.setCycleDateStart(dateValue);
      if (dateValueConvert > lastDateValueConvert) {
        setValueLastDate(event);
        actionDeliveryReport.setCycleDateLast(dateValue);
      } else if (dateValueConvert === lastDateValueConvert) {
        setValueLastDate(event);
      } else {
        setValueLastDate(null);
        actionDeliveryReport.setCycleDateLast(null);
      }
      setValidateFirstDate(null);
    } else {
      setValidateFirstDate('error');
      setValidateLastDate(null);
      setValueLastDate(null);
      actionDeliveryReport.setCycleDateLast(null);
      actionDeliveryReport.setCycleDateStart(null);
    }
  }

  function handleValidateFirstDate(event: any) {
    setValidateFirstDate2(event);
    if (ValidateFirstDate2 === true) {
      if (
        GetFirstDate === null ||
        GetFirstDate === undefined ||
        GetFirstDate === 'Invalid date'
      ) {
        setValidateFirstDate('error');
      } else {
        setValidateFirstDate('');
      }
    }
  }
  function handleChangeLastDate(event: any) {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      actionDeliveryReport.setCycleDateLast(dateValue);
    } else {
      actionDeliveryReport.setCycleDateLast(null);
    }
    setValueLastDate(event);

    if (event === '') {
      setValidateLastDate('error');
    } else {
      setValidateLastDate('');
    }
  }

  function handleValidateLastDate(event: any) {
    setValidateLastDate2(event);
    if (ValidateLastDate2 === true) {
      if (
        GetLastDate === null ||
        GetLastDate === undefined ||
        GetLastDate === 'Invalid date'
      ) {
        setValidateLastDate('error');
      } else {
        ValidateLastDate('');
      }
    } else {
    }
  }

  function handleGetCustomer() {
    actionListCustomer.getListCustomer();
  }

  function handleSelectCustomer(event: any) {
    const searchSelectAll = event.toString().search('SetAllCustomer');
    if (searchSelectAll >= 0) {
      actionDeliveryReport.setCustomerId(
        ListCustomer.map((data: any, index: any) => data.id)
      );
    } else {
      actionDeliveryReport.setCustomerId(event);
    }

    if (GetCustomer === null || event.length === 0) {
      setValidateCustomer('error');
    } else {
      setValidateCustomer(null);
    }
  }

  function handleBlurCustomer() {
    if (
      GetCustomer === null ||
      GetCustomer.length === 0 ||
      GetCustomer.length === undefined
    ) {
      setValidateCustomer('error');
    } else {
      setValidateCustomer(null);
    }
  }

  function handleGetBranch() {
    actionBranch.getBranchGroupList();
  }

  function handleSelectBranch(event: any) {
    const searchSelectAll = event.toString().search('SetAllBranch');
    if (searchSelectAll >= 0) {
      actionDeliveryReport.setBranchId(
        ListBranch.map((data: any, index: any) => data.id)
      );
    } else {
      actionDeliveryReport.setBranchId(event);
    }

    if (GetBranch === null || GetBranch === [] || event.length === 0) {
      setValidateBranch('error');
    } else {
      setValidateBranch(null);
    }
  }

  function handleBlurBranch() {
    if (
      GetBranch === null ||
      GetBranch.length === 0 ||
      GetBranch.length === undefined
    ) {
      setValidateBranch('error');
    } else {
      setValidateBranch(null);
    }
  }

  function handleChangeCheckboxToday() {
    actionDeliveryReport.setCheckBoxToday();
  }

  function handleReset() {
    actionDeliveryReport.clearAllFormDeliveryReport();
    setValueFirstDate(null);
    setValidateFirstDate(null);
    setValidateFirstDate2(null);
    setValueLastDate(null);
    setValidateLastDate(null);
    setValidateLastDate2(null);
    setValidateBranch(null);
    setValidateCustomer(null);
  }

  function handleDownload() {
    actionDeliveryReport.downloadDeliveryReport();
  }

  return (
    <DeliveryReportComponent
      handleChangeFirstDate={handleChangeFirstDate}
      handleChangeLastDate={handleChangeLastDate}
      handleValidateFirstDate={handleValidateFirstDate}
      ValidateFirstDate={ValidateFirstDate === 'error' ? 'error' : ''}
      helpFirstDate={ValidateFirstDate === 'error' ? 'Tanggal wajib diisi' : ''}
      handleValidateLastDate={handleValidateLastDate}
      ValidateLastDate={ValidateLastDate === 'error' ? 'error' : ''}
      helpLastDate={ValidateLastDate === 'error' ? 'Tanggal wajib diisi' : ''}
      ValidateCustomer={ValidateCustomer === 'error' ? 'error' : ''}
      helpValidateCustomer={
        ValidateCustomer === 'error' ? 'Pelanggan wajib diisi' : ''
      }
      ValidateBranch={ValidateBranch === 'error' ? 'error' : ''}
      helpValidateBranch={ValidateBranch === 'error' ? 'Posko wajib diisi' : ''}
      ValueFirstDate={ValueFirstDate}
      GetFirstDate={GetFirstDate}
      ValueLastDate={ValueLastDate}
      GetLastDate={GetLastDate}
      ListBranch={ListBranch}
      ListCustomer={ListCustomer}
      handleGetBranch={handleGetBranch}
      handleSelectBranch={handleSelectBranch}
      handleBlurBranch={handleBlurBranch}
      GetBranch={GetBranch}
      handleGetCustomer={handleGetCustomer}
      handleSelectCustomer={handleSelectCustomer}
      handleBlurCustomer={handleBlurCustomer}
      GetCustomer={GetCustomer}
      loadingSelectBranch={
        LoadingSelectBranch === true &&
        TypeLoadingSelectBranch === 'selectBranchGroup'
          ? true
          : false
      }
      loadingSelectCustomer={
        LoadingSelectCustomer === true &&
        TypeLoadingSelectCustomer === 'selectCustomer'
          ? true
          : false
      }
      validateLastDate={GetFirstDate === null ? true : false}
      handleChangeCheckboxToday={handleChangeCheckboxToday}
      GetCheckboxToday={GetCheckboxToday}
      validateDownloadButton={
        GetFirstDate === null ||
        GetLastDate === null ||
        GetBranch === null ||
        GetBranch.length === 0 ||
        GetBranch === [] ||
        GetCustomer === null ||
        GetCustomer.length === 0 ||
        GetCustomer === []
          ? true
          : false
      }
      handleReset={handleReset}
      lastAccessFiture={Fiture}
      isLoading={isLoading}
      roleAccount={roleAccount}
      branchAuth={branchAuth}
      handleDownload={handleDownload}
    />
  );
}

const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  branchAuth: state.Auth.BranchAuth,

  GetFirstDate: state.Report.DeliveryReportForm.CycleDateStart,
  GetLastDate: state.Report.DeliveryReportForm.CycleDateLast,
  GetCustomer: state.Report.DeliveryReportForm.CustomerId,
  GetBranch: state.Report.DeliveryReportForm.BranchId,
  GetCheckboxToday: state.Report.DeliveryReportForm.CheckBoxToday,

  ListBranch: state.Branch.BranchGroupList,
  ListCustomer: state.Customer.ListCustomer,
  Fiture: state.LastAccess.Fiture,
  isLoading: state.Toggle.isLoading,

  LoadingSelectCustomer: state.Customer.PropertiesCustomer.LoadingCustomer,
  TypeLoadingSelectCustomer:
    state.Courier.PropertiesCourier.TypeLoadingCustomer,
  LoadingSelectBranch: state.Branch.PropertiesBranch.LoadingBranch,
  TypeLoadingSelectBranch: state.Branch.PropertiesBranch.TypeLoadingBranch,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionDeliveryReport: bindActionCreators(ActionDeliveryReport, dispatch),
  actionBranch: bindActionCreators(Branch, dispatch),
  actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DeliveryReportContainer);
