import * as ActionAuth from '../../../core/action//Auth';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionLogin from '../../Login/Store';
import * as ActionPickUpProcess from '../Store/PickUpProcessAction';
import * as SelectorApp from '../../App/Selector/AppSelector';
import * as SelectorPickUpProcess from '../Selector/PickUpProcessSelector';
import * as toggle from '../../../core/action/toggle';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import React from 'react';
import axios from 'axios';
import { getFormValues } from 'redux-form';
import moment from 'moment';

function* S_getPickUpListProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/monitor/CustomerPickup`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionPickUpProcess.fetchPickUpList(data));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

function* S_getDetailPickUpListProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const custId = yield select(SelectorPickUpProcess.makeCustIdSelector());
  const pagePagination = yield select(SelectorApp.makePagePagination());
  const pagePaginationValue = pagePagination - 1;
  const sizeDataPagination = yield select(SelectorApp.makeSizeDataPagination());
  try {
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Delivery/DeliveryVendorPickup/${pagePaginationValue}/${sizeDataPagination}?CustomerId=${custId}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComponent.setTotalPagePagination(data.totalPages * 10));
    yield put(ActionPickUpProcess.fetchDetailPickUpList(data.data));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

function* S_getCourierBranchListProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const branchPickupProcess = yield select(
    SelectorPickUpProcess.makeFormBranchSelector()
  );
  try {
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Courier/branch/${branchPickupProcess}?isAll=false`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionPickUpProcess.fetchCourierBranchList(data.data));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

//UPDATE KETUKER
function* S_submitAssignCourierProcess(action) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const formValue = yield select(getFormValues('assignCourierForm'));
  const customerId = yield select(SelectorPickUpProcess.makeCustIdSelector());
  const courierId = yield select(
    SelectorPickUpProcess.makeFormCourierSelector()
  );
  const checkboxValue = formValue ? formValue.checkboxWaybill : null;
  const DateNow = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
  const postDate = formValue.pickupDate + DateNow.substring(10, 19);

  try {
    yield put(ActionComponent.processLoading(true));
    yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/Delivery/Update/MultipleAssignDataVendor`,
      {
        customerId: customerId,
        courierId: courierId,
        datePickup: postDate,
        waybillPickup: !checkboxValue,
        identityPickup: formValue.waybillPickup,
      },
      { headers: config }
    );
    yield put(ActionPickUpProcess.clearCourierBranchList());
    yield put(ActionPickUpProcess.clearAllBranchList());
    yield put(ActionPickUpProcess.resetPickupForm());
    yield put(ActionComponent.openModal('AssignCourier'));
    yield put(ActionPickUpProcess.getDetailPickUpList());
    yield put(toggle.clearJenisToggle());
    yield put(ActionComponent.processLoading(false));

    Modal.success({
      title: <p className={'titleModalNotifSuccess'}>Berhasil Assign Kurir</p>,
      onOk() {},
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

function* S_downloadPickupDataProcess(action) {
  const state = yield select();
  const key = 'download';
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const customerId = yield select(SelectorPickUpProcess.makeCustIdSelector());
  const DateNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
  const fileName2 = `Data_Pickup_${DateNow}.csv`;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const fileName = fileName2;
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
  try {
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Delivery/SoftcopyMultipleUpload/DataVendor/${customerId}`,
      {},
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percentProgress = Math.floor((loaded * 100) / total);

          if (percentProgress < 100) {
            message.loading({
              content: `Loading ${percentProgress}%'`,
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
  } catch (error) {
    if (error.response.data.Message === 'No data found!') {
      message.warning({
        content: 'Data Tidak Tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
      yield put(ActionComponent.processLoading(false));
    }
  }
}

function* S_uploadCsvPickupProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const csvFile = yield select(
    SelectorPickUpProcess.makeCsvPickupProcessSelector()
  );
  const custId = yield select(SelectorPickUpProcess.makeCustIdSelector());
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(ActionComponent.processLoading(true));

    const { data } = yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/Delivery/Update/MultipleUploadDataVendor`,
      {
        file: csvFile,
        customerId: custId,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionPickUpProcess.clearFormUpload());
    yield put(ActionPickUpProcess.resetPickupForm());
    yield put(ActionPickUpProcess.getDetailPickUpList());
    yield put(ActionComponent.openModal('UploadForAssignCourier'));
    yield put(ActionPickUpProcess.fetchResultUpload(data));
    yield put(ActionComponent.openModal('ResultUpload'));
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionPickUpProcess.clearFileName());
    }
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

function* S_DownloadTemplatePickupFileProcess(action) {
  const state = yield select();
  const key = 'download';
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const fileName = 'Sample_TentukanKurir.csv';
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
  try {
    yield put(ActionComponent.processLoading(true));
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Delivery/Template/MultipleUploadDataVendor/Vendor`,
      {},
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percentProgress = Math.floor((loaded * 100) / total);

          if (percentProgress < 100) {
            message.loading({
              content: `Loading ${percentProgress}%'`,
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    yield put(ActionComponent.processLoading(false));
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
  } catch (error) {
    if (error.response.data.Message === 'No data found!') {
      message.warning({
        content: 'Data Tidak Tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
      yield put(ActionComponent.processLoading(false));
    }
  }
}
function* S_DownloadResultPickupFileProcess(action) {
  const state = yield select();
  const key = 'download';
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const DateNow = moment(new Date()).format('YYYYMMDDHHmmss');
  const resultUpload = yield select(
    SelectorPickUpProcess.makeResultUploadSelector()
  );
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const fileName = `TentukanKurir_${DateNow}.txt`;
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  }
  try {
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Delivery/upload/downloadResult`,
      {
        listResult: resultUpload.listResultCsv,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percentProgress = Math.floor((loaded * 100) / total);

          if (percentProgress < 100) {
            message.loading({
              content: `Loading ${percentProgress}%'`,
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    yield put(ActionComponent.openModal('ResultUpload'));
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
  } catch (error) {
    if (error.response.data.Message === 'No data found!') {
      message.warning({
        content: 'Data Tidak Tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
      yield put(ActionComponent.processLoading(false));
    }
  }
}

export function* SM_getPickUpListAction() {
  yield takeLatest('GET_PICKUP_LIST_REQUESTED', S_getPickUpListProcess);
}

export function* SM_getDetailPickUpListAction() {
  yield takeLatest(
    'GET_DETAIL_PICKUP_LIST_REQUESTED',
    S_getDetailPickUpListProcess
  );
}

export function* SM_getCourierBranchListAction() {
  yield takeLatest(
    'GET_COURIER_BRANCH_LIST_REQUESTED',
    S_getCourierBranchListProcess
  );
}

export function* SM_submitAssignCourierAction() {
  yield takeLatest(
    'SUBMIT_ASSIGN_COURIER_REQUESTED',
    S_submitAssignCourierProcess
  );
}

export function* SM_downloadPickupDataAction() {
  yield takeLatest(
    'DOWNLOAD_PICKUP_DATA_REQUESTED',
    S_downloadPickupDataProcess
  );
}

export function* SM_uploadCsvPickupAction() {
  yield takeLatest(
    'UPLOAD_FOR_ASSIGN_PICKUP_DATA_REQUESTED',
    S_uploadCsvPickupProcess
  );
}
export function* SM_DownloadTemplatePickupFileAction() {
  yield takeLatest(
    'DOWNLOAD_TEMPLATE_PICKUP_FILE_REQUESTED',
    S_DownloadTemplatePickupFileProcess
  );
}
export function* SM_DownloadResultPickupFileAction() {
  yield takeLatest(
    'DOWNLOAD_RESULT_UPLOAD_PICKUP_FILE_REQUESTED',
    S_DownloadResultPickupFileProcess
  );
}
