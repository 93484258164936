import { DatePicker, Form } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

interface IProps {
  typeToggle: any;
  visible: any;
  isLoading: any;
  totalDocument: any;
  returnName: any;
  returnDate: any;
  address: any;
  city: any;
  validateReturnName: any;
  validateReturnDate: any;
  helpValidateReturnName: any;
  helpValidateRetrunDate: any;
  handlePutReturnName: any;
  handlePutReturnDate: any;
  handleValidateReturnDate: any;
  handlePutAddress: any;
  handlePutCity: any;
  helpValidateAddress: any;
  validateAddress: any;
  helpValidateCity: any;
  validateCity: any;
  handleDownload: any;
  handleCloseModal: any;
  statusTreatmentDocument: any;
}
export default class FormCustomerManifestComponent extends Component<IProps> {
  render() {
    const minDate = (d: any) => !d || d.isBefore(moment('01-01-2020'));
    const ValidateButton =
      this.props.returnName === null ||
      this.props.validateReturnName === 'error' ||
      this.props.returnDate === null ||
      this.props.validateReturnDate === 'error' ||
      this.props.address === null ||
      this.props.validateAddress === 'error' ||
      this.props.city === null ||
      this.props.validateCity === 'error'
        ? true
        : false;

    return (
      <div>
        <ModalMaterial
          className={''}
          width={'40%'}
          style={{}}
          teksTitle={'DOWNLOAD MANIFEST'}
          visible={
            this.props.visible === true &&
            this.props.typeToggle === 'FormDownloadCustomerManifest'
              ? true
              : false
          }
          onCancel={this.props.handleCloseModal}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div style={{ paddingTop: '5px' }}>
                  <div
                    style={{
                      paddingRight: '20px',
                      paddingLeft: '30px',
                      paddingTop: '20px',
                    }}
                  >
                    <div>
                      <span>
                        <b>Dikembalikan Kepada PIC</b>
                      </span>
                      <FormInputTextMaterial
                        validateType={true}
                        placeholder={'Masukan Nama PIC'}
                        value={this.props.returnName}
                        maxLength={100}
                        onChange={this.props.handlePutReturnName}
                        onBlur={this.props.handlePutReturnName}
                        validatestatus={this.props.validateReturnName}
                        help={this.props.helpValidateReturnName}
                      />
                    </div>
                    <div>
                      <span>
                        <b>Alamat</b>
                      </span>
                      <FormInputTextMaterial
                        validateType={true}
                        value={this.props.address}
                        maxLength={350}
                        onChange={this.props.handlePutAddress}
                        onBlur={this.props.handlePutAddress}
                        validatestatus={this.props.validateAddress}
                        help={this.props.helpValidateAddress}
                        placeholder={'Masukan alamat'}
                      />
                    </div>
                    <div>
                      <span>
                        <b>Kota</b>
                      </span>
                      <FormInputTextMaterial
                        validateType={true}
                        value={this.props.city}
                        maxLength={50}
                        onChange={this.props.handlePutCity}
                        onBlur={this.props.handlePutCity}
                        validatestatus={this.props.validateCity}
                        help={this.props.helpValidateCity}
                        placeholder={'Masukan kota'}
                      />
                    </div>

                    <div>
                      <span>
                        {this.props.statusTreatmentDocument ===
                        'Dihancurkan' ? (
                          <b>Tanggal Penghancuran Dokumen</b>
                        ) : (
                          <b>Tanggal Pengembalian Dokumen</b>
                        )}
                      </span>
                      <Form.Item
                        validateStatus={this.props.validateReturnDate}
                        help={this.props.helpValidateRetrunDate}
                      >
                        <DatePicker
                          style={{
                            width: '100%',
                            backgroundColor: '#F5F5F5',
                            fontWeight: 'bold',
                          }}
                          locale={locale}
                          format={'YYYY-MM-DD'}
                          placeholder={'Pilih Tanggal'}
                          onOpenChange={this.props.handleValidateReturnDate}
                          value={this.props.returnDate}
                          onChange={this.props.handlePutReturnDate}
                          disabledDate={minDate}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div
                    style={{
                      textAlign: 'right',
                      marginRight: 30,
                      marginTop: 20,
                    }}
                  >
                    <ButtonMaterial
                      disabled={ValidateButton}
                      handleSubmit={this.props.handleDownload}
                      teksButton={'Download'}
                      size={''}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={
                        ValidateButton === false
                          ? {
                              borderColor: '#7956EE',
                              background: '#7956EE',
                              color: '#fff',
                            }
                          : {}
                      }
                    />
                  </div>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
