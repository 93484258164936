import { Action } from 'redux';
import { IFetchPickupStatusAction } from './PickupStatusAction';

const initialState: any = {
  pickupStatusDoc: null,
};

export default function PickUpProcessReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_PICKUP_STATUS_DOC': {
      const fetchPickupStatusDocAction = action as IFetchPickupStatusAction;
      newState.pickupStatusDoc = fetchPickupStatusDocAction.data;
      return { ...newState };
    }
    case 'CLEAR_PICKUP_STATUS_DOC': {
      newState.pickupStatusDoc = null;
      return { ...newState };
    }
  }
  return state;
}
