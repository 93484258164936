import IndexComplaintComponent from '../Component/IndexComplaintComponent';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { getFormValues } from 'redux-form';

function IndexComplaintContainer(props) {
    
  return (
    <React.Fragment>
      <IndexComplaintComponent {...props} />
    </React.Fragment>
  );
}

const mapStateToProps = () => createStructuredSelector({
    formValue: getFormValues('complaintForm'),
});
const mapDispatchToProps = dispatch => ({

});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(IndexComplaintContainer);
