import React from 'react';
import { Col, Row } from 'antd';

import CButton from 'apps/assets/components/CButton';
import CCard from 'apps/assets/components/CCard';
import CSpin from 'apps/assets/components/CSpin';
import { InputText, InputSelect } from 'components/InputField';
import { SingleCheckbox } from 'components/SingleCheckbox';
import { TreeField } from 'components/TreeField';
import { Form as FormFinal, Field } from 'react-final-form';
import { useQuery } from '@tanstack/react-query';
import { getAllLevelPermission } from 'services/LevelAccessServices';
import { AxiosResponse } from 'axios';
import { accountTypes } from 'lib/constants';

type FormRoleProps = {
  handleBtnBack: () => void;
  handleSubmit?: (value: any) => void;
  handleSetDataMenu?: (value: any) => void;
  handleSelectedlevel?: (value: any) => void;
  handleChangeForm?: (name: string, value: any) => void;
  dataAllMenus: Array<any>;
  isEdit?: boolean;
  isView?: boolean;
  isLoadingSubmit?: boolean;
  isLoadingMenus?: boolean;
  dataLevel?: any;
};

const FormRole = ({
  handleBtnBack,
  handleSubmit = () => undefined,
  handleSetDataMenu = () => undefined,
  handleSelectedlevel = () => undefined,
  handleChangeForm = () => undefined,
  dataAllMenus,
  isEdit = false,
  isView = false,
  isLoadingSubmit = false,
  isLoadingMenus = false,
  dataLevel,
}: FormRoleProps) => {
  const { data, isFetching: isLoadingLevel } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataLevelPermission', dataLevel.accountType],
    queryFn: () =>
      getAllLevelPermission({ idAccountType: dataLevel.accountType }),
    enabled: !!dataLevel.accountType,
  });

  const btnBack = [
    {
      type: 'primary',
      className: 'btnResetDefault',
      onClick: handleBtnBack,
      isLoading: isLoadingSubmit,
      content: 'Kembali',
      id: 'btnBack',
    },
  ];

  return (
    <CSpin
      spinning={isLoadingMenus || isLoadingLevel}
      size={'large'}
      content={
        <div className="defaultSpace">
          <FormFinal
            onSubmit={handleSubmit}
            initialValues={{
              accountType: dataLevel.accountType,
              levelAkses: dataLevel.levelAkses,
              roleName: dataLevel.roleName,
              isActive: dataLevel.isActive,
            }}
            validate={values => {
              const errors = {} as any;
              if (!values.accountType) {
                errors.accountType = 'Tipe Akun tidak boleh kosong!';
              }
              if (!values.levelAkses) {
                errors.levelAkses = 'Level Akses tidak boleh kosong!';
              }
              if (!values.roleName) {
                errors.roleName = 'Nama Role tidak boleh kosong!';
              }
              if (values.roleName && values.roleName.length < 3) {
                errors.roleName = 'Minimal harus 3 karakter!';
              }
              return errors;
            }}
            render={({ handleSubmit, submitting, valid, values }) => (
              <>
                <CCard
                  cardClassName={'defaultCard'}
                  cardExtra={<CButton buttonData={btnBack} />}
                  cardTitle={
                    <span className="lightBlueColor">
                      {isEdit ? 'Edit' : isView ? 'Detail' : 'Tambah'} Role
                    </span>
                  }
                  cardContent={
                    <>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Field
                            name="accountType"
                            render={input => (
                              <InputSelect
                                name="accountType"
                                placeholder={'Pilih Tipe Akun'}
                                label={'Tipe Akun'}
                                disabled={isView}
                                data={accountTypes}
                                {...input}
                                handleSelectedlevel={value =>
                                  handleChangeForm('accountType', value)
                                }
                              />
                            )}
                          />
                        </Col>
                        <Col span={8}>
                          <Field
                            name="levelAkses"
                            render={input => (
                              <InputSelect
                                name="levelAkses"
                                placeholder={'Pilih Level Akses'}
                                label={'Level Akses'}
                                maxlength={30}
                                disabled={isView || !values.accountType}
                                data={data?.data || []}
                                handleSelectedlevel={handleSelectedlevel}
                                {...input}
                              />
                            )}
                          />
                        </Col>
                        <Col span={8}>
                          <Field
                            name="roleName"
                            render={input => (
                              <InputText
                                name="roleName"
                                placeholder={'Masukan Nama Role'}
                                label={'Nama Role'}
                                maxlength={30}
                                disabled={isView}
                                {...input}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}></Col>
                        <Col span={8}></Col>
                        <Col span={8}>
                          <Field
                            name="isActive"
                            render={input => (
                              <SingleCheckbox
                                name={'checkboxStatus'}
                                idComponent="inputRefreshment"
                                labelRight={'Ceklis untuk aktifkan'}
                                disabled={isView}
                                {...input}
                              />
                            )}
                          />
                        </Col>
                      </Row>
                    </>
                  }
                />
                <Row className="spacingTop">
                  <CCard
                    cardClassName={'defaultCard'}
                    cardExtra={
                      <CButton
                        buttonData={
                          !isView && [
                            {
                              type: 'primary',
                              className: 'btnDefaultSubmit',
                              onClick: handleSubmit,
                              isLoading: isLoadingSubmit,
                              content: 'Simpan',
                              id: 'btnSubmit',
                              disabled: submitting || !valid,
                            },
                          ]
                        }
                      />
                    }
                    cardTitle={
                      <span className="lightBlueColor">Hak Akses</span>
                    }
                    cardContent={
                      <Field
                        name="menus"
                        render={input => (
                          <TreeField
                            name={'treeValue'}
                            idComponent="inputTree"
                            treeData={dataAllMenus}
                            isExpandedAll={isView}
                            expandedKeys={dataLevel.menus}
                            setDataMenuState={handleSetDataMenu}
                            isMultiple
                            isView={isView}
                            {...input}
                          />
                        )}
                      />
                    }
                  />
                </Row>
              </>
            )}
          ></FormFinal>
        </div>
      }
    />
  );
};

export default FormRole;
