export function setLoadingStatus()
{
    return({
        type:"SET_LOADING_STATUS"
    })
}
export function setTypeLoadingStatus(TypeLoadingStatus:any)
{
    return({
        type:"SET_TYPE_LOADING_STATUS",
        TypeLoadingStatus
    })
}
export function clearTypeLoadingStatus()
{
    return({
        type:"CLEAR_TYPE_LOADING_STATUS"
    })
}
export function clearLoadingStatus()
{
    return({
        type:"CLEAR_LOADING_STATUS"
    })
}