import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import ModalUpdateLocation from 'module/SettingLocation/components/ModalUpdateLocation';
import {
  downloadExcelLocationTemplate,
  postSettingLocation,
} from 'services/SettingLocationService';
import { downloadBlob, getBase64 } from 'lib/util';
import ModalStatus from 'materials/ModalStatus';
import ModalMaterial from 'materials/ModalMaterial';
import CountResult from 'components/CountResult';
import { downloadResultResponse } from 'services/HolidayServices';
import moment from 'moment-timezone';

type UpdatelocationProps = {
  handleModal: () => void;
  refetch: () => void;
  show: boolean;
};

export type FormUpdateLocationType = {
  fileUpload: any;
  fileString: string;
  fileName: string;
};

const defaultValue = {
  fileUpload: null,
  fileString: '',
  fileName: '',
};

export default function Updatelocation(props: UpdatelocationProps) {
  const [initialValue, setInitialValue] =
    useState<FormUpdateLocationType>(defaultValue);
  const [isShowSuccess, setIsShowSuccess] = useState<boolean>(false);
  const [resultUploadCsv, setResultUploadCsv] = useState<{
    success: number;
    error: number;
  }>({ success: 0, error: 0 });
  const [resultListCsv, setResultListCsv] = useState<Array<any>>([]);

  const { mutate: mutateUpdateSettingLocation, isLoading: isLoadingUpdate } =
    useMutation((data: any) => postSettingLocation(data), {
      onSuccess: res => {
        if (res.status === 200) {
          setIsShowSuccess(!isShowSuccess);
          setResultUploadCsv({
            success: res.data?.success,
            error: res.data?.failed,
          });
          setResultListCsv(res.data?.listResultCsv);
          props.handleModal();
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });
  const { mutate: mutateDownloadFile, isLoading: isLoadingDownload } =
    useMutation(downloadExcelLocationTemplate, {
      onSuccess: res => {
        if (res.status === 200) {
          downloadBlob(res.data, 'Download excel contoh', 'csv');
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });
  const { mutate: mutateDownloadError, isLoading: isLoadingDownloadError } =
    useMutation(downloadResultResponse, {
      onSuccess: res => {
        handleCloseModalSuccess();
        props.refetch();
        if (res.status === 200) {
          downloadBlob(
            res.data,
            `LogUpdateWilayah${moment().format('yyyyMMDD')}`,
            'txt'
          );
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });

  const handleUploadFile = async (value: any) => {
    const base64: any = await getBase64(value.file.originFileObj);
    setInitialValue({
      fileUpload: value.file,
      fileString: base64.split(',')[1],
      fileName: value.file.name,
    });
  };

  const handleSubmit = () => {
    mutateUpdateSettingLocation({
      fileString: initialValue.fileString,
    });
  };

  const handleCloseModalSuccess = () => {
    setIsShowSuccess(!isShowSuccess);
    setResultUploadCsv({
      success: 0,
      error: 0,
    });
    setResultListCsv([]);
    setInitialValue(defaultValue);
  };

  const handleDownloadResultCsv = () => {
    mutateDownloadError({ listResult: resultListCsv });
  };

  return (
    <>
      <ModalUpdateLocation
        {...props}
        isLoading={isLoadingUpdate}
        isLoadingDownload={isLoadingDownload}
        mutateDownloadFile={() => mutateDownloadFile()}
        handleUploadFile={handleUploadFile}
        handleSubmit={handleSubmit}
        initialValue={initialValue}
      />
      <ModalMaterial
        teksTitle={'STATUS UPDATE WILAYAH'}
        width="50%"
        visible={isShowSuccess}
        onCancel={handleCloseModalSuccess}
        content={
          <CountResult
            success={resultUploadCsv.success}
            error={resultUploadCsv.error}
            onClickDownload={handleDownloadResultCsv}
            onClickOke={handleCloseModalSuccess}
            disabledButtonDownload={
              isLoadingDownloadError || resultUploadCsv?.error === 0
            }
          />
        }
      />
    </>
  );
}
