import React from 'react';
import FormRole from 'components/FormRole';

export default function ViewRoleFormComponent(props) {
  return (
    <FormRole
      handleBtnBack={props.handleBtnBack}
      isLoadingMenus={props.isLoadingMenus}
      dataAllMenus={props.dataAllMenus}
      dataLevel={props.dataLevel}
      isView
    />
  );
}
