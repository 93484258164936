// function ValidateMessage(condition:any,Message:any) {
    
// }
const ValidationMessage=(condition:any,Message:any)=>{
    if (condition==='error') {
        return Message
    }else{
        return ""
    }
}
export {ValidationMessage}