import React, { Component } from 'react';
import { Row, Col, Avatar, Input, Button } from 'antd';
import CardMaterial from '../../../materials/CardMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';
import ButtonMaterial from '../../../materials/ButtonMaterial';
import TextArea from 'antd/lib/input/TextArea';
import EditProfileContainer from '../../../containers/Content/Profile/EditProfileContainer';
import ResetPasswordContainer from '../../../containers/Content/ResetPassword/ResetPasswordContainer';

interface IProps {
  isLoading: any;
  photo: any;
  firstName: any;
  lastName: any;
  userName: any;
  initials: any;
  noTelp: any;
  email: any;
  address: any;
  handleModalEdit: any;
  handleModalChangePassword: any;
}
export default class ProfileComponent extends Component<IProps> {
  render() {
    return (
      <div
        style={{
          padding: '35px',
        }}
      >
        <CardMaterial
          extra={''}
          style={{ borderRadius: '10px' }}
          title={
            <div>
              <Row>
                <Col
                  span={16}
                  style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                  <div>
                    <span
                      style={{
                        color: '#11BEFF',
                      }}
                    >
                      <b>PROFIL</b>
                    </span>
                  </div>
                </Col>
                <Col span={8}>
                  <div style={{ float: 'right' }}>
                    <ButtonMaterial
                      disabled={''}
                      handleSubmit={this.props.handleModalEdit}
                      teksButton={<b>Ubah</b>}
                      size={'middle'}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={{
                        float: 'right',
                        colorFont: '#11BEFF',
                        height: '30px',
                        width: '80px',
                        background: '#FCC642',
                        borderColor: '#FCC642',
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          }
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div style={{ paddingTop: '5px' }}>
                  <div
                    style={{
                      marginLeft: 30,
                      marginRight: 30,
                      textAlign: 'center',
                    }}
                  >
                    <Avatar
                      size={135}
                      src={'data:image/jpeg;base64, ' + this.props.photo}
                    >
                      <span style={{ fontSize: 40 }}>
                        <b>{this.props.initials}</b>
                      </span>
                    </Avatar>
                  </div>

                  <Row>
                    <Col span={12}>
                      <div
                        style={{
                          paddingRight: '20px',
                          paddingLeft: '30px',
                          paddingTop: '20px',
                        }}
                      >
                        <div className="padding-top-form-input">
                          <span>
                            <b>Nama Depan</b>
                          </span>
                          <Input
                            style={{
                              background: '#F5F5F5',
                              fontWeight: 'bold',
                            }}
                            value={this.props.firstName}
                          ></Input>
                        </div>

                        <div className="padding-top-form-input">
                          <span>
                            <b>Nama Belakang</b>
                          </span>
                          <Input
                            style={{
                              background: '#F5F5F5',
                              fontWeight: 'bold',
                            }}
                            value={this.props.lastName}
                          ></Input>
                        </div>

                        <div className="padding-top-form-input">
                          <span>
                            <b>Nama Pengguna</b>
                          </span>
                          <Input
                            style={{
                              background: '#F5F5F5',
                              fontWeight: 'bold',
                            }}
                            value={this.props.userName}
                          ></Input>
                        </div>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div
                        style={{
                          paddingLeft: '20px',
                          paddingRight: '30px',
                          paddingTop: '20px',
                        }}
                      >
                        <div className="padding-top-form-input">
                          <span>
                            <b>No Telp</b>
                          </span>
                          <Input
                            addonBefore={'+62'}
                            style={{
                              background: '#F5F5F5',
                              fontWeight: 'bold',
                            }}
                            value={this.props.noTelp}
                          ></Input>
                        </div>

                        <div className="padding-top-form-input">
                          <span>
                            <b>Email </b>
                          </span>
                          <Input
                            style={{
                              background: '#F5F5F5',
                              fontWeight: 'bold',
                            }}
                            value={this.props.email}
                          ></Input>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div
                    className="padding-top-form-input"
                    style={{ marginLeft: 30, marginRight: 30 }}
                  >
                    <span>
                      <b>Alamat lengkap</b>
                    </span>
                    <TextArea
                      style={{
                        height: '80px',
                        background: '#F5F5F5',
                        fontWeight: 'bold',
                      }}
                      value={this.props.address}
                    ></TextArea>
                  </div>

                  <div
                    style={{
                      textAlign: 'left',
                      marginLeft: 30,
                      marginBottom: 30,
                    }}
                  >
                    <Button
                      type='link'
                      onClick={this.props.handleModalChangePassword}
                      style={{ color: '#11BEFF', fontStyle: 'italic' }}
                    >
                      <u>Ubah Kata Sandi</u>
                    </Button>
                  </div>
                </div>
              }
            />
          }
        />
        <EditProfileContainer />
        <ResetPasswordContainer />
      </div>
    );
  }
}
