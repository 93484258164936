import * as ActionDigitalWaybill from 'core/action/Report/DigitalWaybillReport/ActionDigitalWaybill';
import * as ActionListCustomer from 'core/action/Customer/ActionListCustomer';
import * as ActionListCustomerProduct from 'core/action/CustomerProduct/ActionListCustomerProduct';
import * as ActionToggle from 'core/action/toggle/index';
import * as LastAccess from 'core/action/LastAccess/index';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import DigitalWaybillComponent from 'module/Report/Component/DigitalWaybill/DigitalWaybillComponent';
import { connect } from 'react-redux';
import moment from 'moment';

interface IProps {
  getFirstDate: any;
  getRadioButton: any;
  getLastDate: any;
  getCustomer: any;
  getCustomerProduct: any;
  getDeliveryType: any;
  getDeliveryStatus: any;
  getFlagFileName: any;
  actionLastAccess: any;
  actionToggle: any;
  actionDigitalWaybill: any;
  actionListCustomer: any;
  actionListCustomerProduct: any;
  listCustomer: any;
  listCustomerProduct: any;
  loadingSelectCustomer: any;
  loadingSelectCustomerProduct: any;
  isLoading: any;
  jenisToggle: any;
}

function DigitalWaybillContainer({
  getFirstDate,
  getRadioButton,
  getLastDate,
  getCustomer,
  getCustomerProduct,
  getDeliveryType,
  getDeliveryStatus,
  getFlagFileName,
  actionToggle,
  actionDigitalWaybill,
  actionListCustomer,
  actionListCustomerProduct,
  listCustomer,
  listCustomerProduct,
  loadingSelectCustomer,
  loadingSelectCustomerProduct,
  isLoading,
  jenisToggle,
}: IProps) {
  const [valueFirstDate, setValueFirstDate]: any = useState(null);
  const [validateFirstDate, setValidateFirstDate]: any = useState(null);
  const [validateFirstDate2, setValidateFirstDate2]: any = useState(null);
  const [valueFinishDate, setValueFinishDate]: any = useState(null);
  const [validateLastDate, setValidateLastDate]: any = useState(null);
  const [validateCustomer, setValidateCustomer]: any = useState(null);
  const [validateCustomerProduct, setValidateCustomerProduct]: any =
    useState(null);
  const [validateDeliveryType, setValidateDeliveryType]: any = useState(null);
  const [validateTypeOfName, setValidateTypeOfName]: any = useState(null);
  const [validateDeliveryStatus, setValidateDeliveryStatus]: any =
    useState(null);
  const [validateFlagFileName, setValidateFlagFileName]: any = useState(null);

  function handleRadioButton(event: any) {
    actionDigitalWaybill.changeRadioButtonDate(event.target.value);
  }
  function handleGetCustomer() {
    actionListCustomer.getListCustomer();
  }
  function handlePutFirstDate(event: any) {
    setValueFirstDate(event);
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      const lastDateValueConvert = moment(getLastDate).format('YYYYMMDD');
      const dateValueConvert = moment(dateValue).format('YYYYMMDD');
      actionDigitalWaybill.changeFirstDate(dateValue);
      if (dateValueConvert > lastDateValueConvert) {
        setValueFinishDate(event);
        actionDigitalWaybill.changeLastDate(dateValue);
      } else if (dateValueConvert === lastDateValueConvert) {
        setValueFinishDate(event);
      } else {
        setValueFinishDate(null);
        actionDigitalWaybill.changeLastDate(null);
      }
      setValidateFirstDate(null);
    } else {
      setValidateFirstDate('error');
      setValueFinishDate(null);
      actionDigitalWaybill.changeFirstDate(null);
      actionDigitalWaybill.changeLastDate(null);
    }
  }
  function handleValidateFirstDate(event: any) {
    setValidateFirstDate2(event);
    if (validateFirstDate2 === true) {
      if (
        getFirstDate === null ||
        getFirstDate === undefined ||
        getFirstDate === 'Invalid date'
      ) {
        setValidateFirstDate('error');
      } else {
        setValidateFirstDate('');
      }
    }
  }
  function handlePutLastDate(event: any) {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      actionDigitalWaybill.changeLastDate(dateValue);
    } else {
      actionDigitalWaybill.changeLastDate(null);
    }
    setValueFinishDate(event);
    if (event === '') {
      setValidateLastDate('error');
    } else {
      setValidateLastDate('');
    }
  }
  function handleValidateLastDate(event: any) {
    if (event === true) {
      if (
        getLastDate === null ||
        getLastDate === undefined ||
        getLastDate === 'Invalid date'
      ) {
        setValidateLastDate('error');
      } else {
        setValidateLastDate('');
      }
    }
  }
  function handlePutCustomer(event: any, label: any) {
    actionDigitalWaybill.changeCustomer(event);
    actionDigitalWaybill.changeCustomerName(label.props.label);

    if (event === null) {
      setValidateCustomer('error');
    } else {
      setValidateCustomer(null);
    }
  }
  function handleBlurCustomer() {
    if (getCustomer === null) {
      setValidateCustomer('error');
    } else {
      setValidateCustomer(null);
    }
  }
  function handleGetCustomerProduct() {
    actionListCustomerProduct.getListCustomerProductNoType();
  }
  function handlePutCustomerProduct(event: any) {
    if (event[0] === 'Pilih Semua Produk') {
      actionDigitalWaybill.changeCustomerProduct(
        listCustomerProduct.map((data: any, index: any) => data.id)
      );
    } else {
      actionDigitalWaybill.changeCustomerProduct(event);
    }
    if (
      getCustomerProduct === null ||
      getCustomerProduct === [] ||
      event.length === 0
    ) {
      setValidateCustomerProduct('error');
    } else {
      setValidateCustomerProduct(null);
    }
  }
  function handleBlurCustomerProduct() {
    if (
      getCustomerProduct.length === undefined ||
      getCustomerProduct.length < 0
    ) {
      setValidateCustomerProduct('error');
    } else {
      setValidateCustomerProduct(null);
    }
  }
  function handlePutDeliveryType(event: any) {
    actionDigitalWaybill.changeDeliveryType(event);

    if (event === null || event.length === 0) {
      setValidateDeliveryType('error');
    } else {
      setValidateDeliveryType(null);
    }
  }
  function handlePutFlagFileName(event: any) {
    actionDigitalWaybill.changeFlagFileName(event);

    if (event === null || event.length === 0) {
      setValidateFlagFileName('error');
    } else {
      setValidateFlagFileName(null);
    }
  }
  function handleBlurFlagFilename() {
    if (getFlagFileName === null) {
      setValidateFlagFileName('error');
    } else {
      setValidateFlagFileName(null);
    }
  }
  function handlePutDeliveryStatus(event: any) {
    actionDigitalWaybill.changeDeliveryStatus(event);

    if (event === null || event.length === 0) {
      setValidateDeliveryStatus('error');
    } else {
      setValidateDeliveryStatus(null);
    }
  }
  function handlePutTypeOfName(event: any) {
    actionDigitalWaybill.changeTypeOfName(event);
    if (event === null || event.length === 0) {
      setValidateTypeOfName('error');
    } else {
      setValidateTypeOfName(null);
    }
  }
  function handleClearCustProduct() {
    actionDigitalWaybill.clearCustomerProduct();
  }
  function handleDownload() {
    actionDigitalWaybill.downloadDigitalWaybill();
    actionToggle.changeJenisToggle('WaybillDigital');
  }
  function handleDowloadByUpload() {
    actionToggle.changeModal();
    actionToggle.changeJenisToggle('WaybillDigitalByUpload');
  }
  function handleReset() {
    actionDigitalWaybill.clearForm();
    setValueFirstDate(null);
    setValidateFirstDate(null);
    setValidateFirstDate2(null);
    setValueFinishDate(null);
    setValidateLastDate(null);
    setValidateCustomer(null);
    setValidateCustomerProduct(null);
    setValidateDeliveryType(null);
    setValidateDeliveryStatus(null);
    setValidateFlagFileName(null);
  }
  return (
    <div>
      <DigitalWaybillComponent
        handleRadioButton={handleRadioButton}
        getRadioButton={getRadioButton}
        handlePutFirstDate={handlePutFirstDate}
        handleValidateFirstDate={handleValidateFirstDate}
        valueFirstDate={valueFirstDate}
        getFirstDate={getFirstDate}
        validateFirstDate={validateFirstDate === 'error' ? 'error' : ''}
        helpFirstDate={
          validateFirstDate === 'error' ? 'Tanggal wajib diisi' : ''
        }
        handlePutLastDate={handlePutLastDate}
        handleValidateLastDate={handleValidateLastDate}
        getLastDate={getLastDate}
        valueFinishDate={valueFinishDate}
        validateLastDate={validateLastDate === 'error' ? 'error' : ''}
        helpLastDate={validateLastDate === 'error' ? 'Tanggal wajib diisi' : ''}
        handleGetCustomer={handleGetCustomer}
        listCustomer={listCustomer}
        listCustomerProduct={listCustomerProduct}
        loadingSelectCustomer={loadingSelectCustomer}
        loadingSelectCustomerProduct={loadingSelectCustomerProduct}
        handlePutCustomer={handlePutCustomer}
        handleBlurCustomer={handleBlurCustomer}
        validateCustomer={validateCustomer === 'error' ? 'error' : ''}
        helpCustomer={
          validateCustomer === 'error' ? 'Pelanggan wajib diisi' : ''
        }
        getCustomer={getCustomer}
        handlePutCustomerProduct={handlePutCustomerProduct}
        handleGetCustomerProduct={handleGetCustomerProduct}
        handleBlurCustomerProduct={handleBlurCustomerProduct}
        getCustomerProduct={getCustomerProduct}
        validateCustomerProduct={
          validateCustomerProduct === 'error' ? 'error' : ''
        }
        helpCustomerProduct={
          validateCustomerProduct === 'error' ? 'Produk wajib diisi' : ''
        }
        helpFlagFileName={
          validateFlagFileName === 'error' ? 'Penamaan File wajib diisi' : ''
        }
        validateFlagFileName={validateFlagFileName === 'error' ? 'error' : ''}
        handlePutFlagFileName={handlePutFlagFileName}
        getFlagFileName={getFlagFileName}
        handleBlurFlagFilename={handleBlurFlagFilename}
        getDeliveryType={getDeliveryType}
        handlePutDeliveryType={handlePutDeliveryType}
        validateDeliveryType={validateDeliveryType === 'error' ? 'error' : ''}
        helpDeliveryType={
          validateDeliveryType === 'error' ? 'Tipe Pengiriman wajib diisi' : ''
        }
        getDeliveryStatus={getDeliveryStatus}
        handlePutDeliveryStatus={handlePutDeliveryStatus}
        validateDeliveryStatus={
          validateDeliveryStatus === 'error' ? 'error' : ''
        }
        helpDeliveryStatus={
          validateDeliveryStatus === 'error'
            ? 'Status Pengiriman wajib diisi'
            : ''
        }
        handlePutTypeOfName={handlePutTypeOfName}
        validateTypeOfName={validateTypeOfName === 'error' ? 'error' : ''}
        helpTypeOfName={
          validateTypeOfName === 'error' ? 'Penamaan file wajib diisi' : ''
        }
        handleClearCustProduct={handleClearCustProduct}
        handleDownload={handleDownload}
        handleReset={handleReset}
        validateDownloadButton={
          getFirstDate === null ||
          getLastDate === null ||
          getCustomer === null ||
          getCustomerProduct === null ||
          getCustomerProduct.length === 0 ||
          getDeliveryStatus === null ||
          getDeliveryStatus.length === 0 ||
          getFlagFileName === null ||
          getDeliveryType === null ||
          getDeliveryType.length === 0
            ? true
            : false
        }
        validateResetButton={
          getFirstDate !== null ||
          getLastDate !== null ||
          getCustomer !== null ||
          getFlagFileName !== null ||
          getCustomerProduct.length !== 0 ||
          getDeliveryStatus.length !== 0 ||
          getDeliveryType.length !== 0
            ? false
            : true
        }
        handleDowloadByUpload={handleDowloadByUpload}
        isLoading={isLoading}
        jenisToggle={jenisToggle}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  getRadioButton: state.Report.DigitalWaybillReportForm.RadioButtonDate,
  getFirstDate: state.Report.DigitalWaybillReportForm.FirstDate,
  getLastDate: state.Report.DigitalWaybillReportForm.LastDate,
  getCustomer: state.Report.DigitalWaybillReportForm.Customer,
  getCustomerProduct: state.Report.DigitalWaybillReportForm.CustomerProduct,
  getDeliveryType: state.Report.DigitalWaybillReportForm.DeliveryType,
  getDeliveryStatus: state.Report.DigitalWaybillReportForm.DeliveryStatus,
  getFlagFileName: state.Report.DigitalWaybillReportForm.FlagFileName,
  listCustomer: state.Customer.ListCustomer,
  listCustomerProduct: state.CustomerProduct.ListCustomerProduct,
  Fiture: state.LastAccess.Fiture,
  isLoading: state.Toggle.isLoading,
  jenisToggle: state.Toggle.jenisToggle,
  loadingSelectCustomer: state.Customer.PropertiesCustomer.LoadingCustomer,
  loadingSelectCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.LoadingCustomerProduct,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionDigitalWaybill: bindActionCreators(ActionDigitalWaybill, dispatch),
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
  actionListCustomerProduct: bindActionCreators(
    ActionListCustomerProduct,
    dispatch
  ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DigitalWaybillContainer);
