export function changeRadiobutton(Radiobutton:any)
{
    return({
        type:"CHANGE_RADIOBUTTON",
        Radiobutton
    })
}
export function clearRadiobutton()
{
    return({
        type:"CHANGE_CLEAR_RADIOBUTTON"
    })
}