import React, { Component } from 'react';
import DetailCourierComponent from '../../../../components/Content/ManageAccount/Courier/DetailCourierComponent';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as toggle from '../../../../core/action/toggle';
import * as formCourier from '../../../../core/action/ManageAccount/Courier/ActionFormDetailCourier';
import * as detailCourier from '../../../../core/action/ManageAccount/Courier/ActionIdCourierBranch';
import * as ActionDetailBranch from '../../../../core/action/Branch/ActionDetailBranch';

interface IProps {
  GetCourierName: any;
  GetCourierLastName: any;
  GetCourierEmail: any;
  GetCourierPhoneNumber: any;
  GetCourierDob: any;
  GetCourierPhoto: any;
  GetCourierCode: any;
  GetCourierAddress: any;
  GetCourierStatus: any;
  GetBranchCourier: any;

  FormCourier: any;
  IdCourier: any;

  Toggle: any;
  visible: any;
  isLoading: any;
  jenisToggle: any;

  actionDetailBranch: any;
}

class DetailCourierContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);

    this.handleClearDetailCourier = this.handleClearDetailCourier.bind(this);
  }

  handleClearDetailCourier() {
    this.props.Toggle.changeModal();
    this.props.Toggle.clearJenisToggle();
    this.props.FormCourier.changeClearDataCourierForm();
    this.props.IdCourier.clearDetailCourier();
    this.props.actionDetailBranch.setBranchDetail(null);
  }

  render() {
    return (
      <DetailCourierComponent
        visible={
          this.props.jenisToggle === 'DetailCourier'&& this.props.visible===true?true:false
        }
        handleClearDetailCourier={this.handleClearDetailCourier}
        GetCourierName={this.props.GetCourierName}
        GetCourierLastName={this.props.GetCourierLastName}
        GetCourierEmail={this.props.GetCourierEmail}
        GetCourierPhoneNumber={this.props.GetCourierPhoneNumber}
        GetCourierDob={this.props.GetCourierDob}
        GetCourierPhoto={this.props.GetCourierPhoto}
        GetCourierCode={this.props.GetCourierCode}
        GetCourierAddress={this.props.GetCourierAddress}
        GetCourierStatus={this.props.GetCourierStatus}
        GetBranchCourier={this.props.GetBranchCourier}
        isLoading={this.props.isLoading}
        jenisToggle={this.props.jenisToggle}
      />
    );
  }
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,

  GetCourierName: state.Courier.FormCourier.CourierName,
  GetCourierLastName: state.Courier.FormCourier.CourierLastName,
  GetCourierEmail: state.Courier.FormCourier.CourierEmail,
  GetCourierPhoneNumber: state.Courier.FormCourier.CourierPhoneNumber,
  GetCourierDob: state.Courier.FormCourier.CourierDob,
  GetCourierPhoto: state.Courier.FormCourier.CourierPhoto,
  GetCourierCode: state.Courier.FormCourier.CourierCode,
  GetCourierAddress: state.Courier.FormCourier.CourierAddress,
  GetCourierStatus: state.Courier.FormCourier.CourierStatus,
  GetBranchCourier: state.Courier.FormCourier.BranchCourier,

  isLoading: state.Toggle.isLoading,
  jenisToggle: state.Toggle.jenisToggle,
});
const mapDispatchToProps = (dispatch: any) => ({
  Toggle: bindActionCreators(toggle, dispatch),
  FormCourier: bindActionCreators(formCourier, dispatch),
  IdCourier: bindActionCreators(detailCourier, dispatch),
  actionDetailBranch: bindActionCreators(ActionDetailBranch, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DetailCourierContainer);
