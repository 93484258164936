import { Action } from 'redux';

const initialState = {
  ProgressCourier: {
    CourierId: null,
    StartDate: null,
  },
  ListProgressCourier: [],
};

export interface IAuthAction extends Action<string> {}

export interface ISetCourierIdProgressCourier extends IAuthAction {
  CourierId: any;
}
export interface ISetStartDateProgressCourier extends IAuthAction {
  StartDate: any;
}
export interface ISetListProgressCourier extends IAuthAction {
  ListProgressCourier: any;
}

export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'SET_COURIER_ID_PROGRESS_COURIER':
      const setCourierId = action as ISetCourierIdProgressCourier;
      newState.ProgressCourier.CourierId = setCourierId.CourierId;
      return { ...newState };

    case 'SET_START_DATE_PROGRESS_COURIER':
      const setStartDate = action as ISetStartDateProgressCourier;
      newState.ProgressCourier.StartDate = setStartDate.StartDate;
      return { ...newState };

    case 'SET_LIST_PROGRESS_COURIER':
      const setListProgressCourier = action as ISetListProgressCourier;
      newState.ListProgressCourier = setListProgressCourier.ListProgressCourier;
      return { ...newState };

    case 'CHANGE_CLEAR_PROGRESS_COURIER':
      newState.ProgressCourier.CourierId = null;
      newState.ProgressCourier.StartDate = null;
      newState.ListProgressCourier = [];
      return { ...newState };
  }
  return { ...newState };
}
