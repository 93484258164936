export function collapseSidebar() {
  return {
    type: 'OPEN_SIDEBAR',
  };
}
export function processLoading(isLoading: boolean) {
  return {
    type: 'PROCESS_LOADING',
    isLoading,
  };
}
export function setPagePagination(pagePagination: any) {
  return {
    type: 'SET_PAGE_PAGINATION_COMPONENT',
    pagePagination,
  };
}
export function setTotalPagePagination(totalPagePagination: any) {
  return {
    type: 'SET_TOTAL_PAGE_PAGINATION_COMPONENT',
    totalPagePagination,
  };
}
export function setSizeDataPagination(sizeDataPagination: any) {
  return {
    type: 'SET_SIZE_DATA_PAGINATION_COMPONENT',
    sizeDataPagination,
  };
}
export function setCountingNotif(countingNotif: any) {
  return {
    type: 'SET_COUNTING_NOTIF_COMPONENT',
    countingNotif,
  };
}
export function clearAllPagination() {
  return {
    type: 'CLEAR_ALL_PAGINATION_COMPONENT',
  };
}
export function openModal(component: string) {
  return {
    type: 'SHOW_MODAL',
    component,
  };
}
