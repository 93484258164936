import * as ActionListCustomer from 'core/action/Customer/ActionListCustomer';
import * as ActionListCustomerProduct from 'core/action/CustomerProduct/ActionListCustomerProduct';
import * as Branch from 'core/action/Branch/ActionAllBranch';
import * as CourierTable from 'core/action/ManageAccount/Courier/ActionTableCourier';
import * as LastAccess from 'core/action/LastAccess/index';
import * as Report from 'core/action/Report/CourierReport/ActionCourierReportForm';

import React, { useState, useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { useQuery, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import ModalStatus from 'materials/ModalStatus';
import { postReportCourier } from 'services/ReportCourierServices';
import { downloadBlob } from 'lib/util';
import moment from 'moment';

// import CourierReportComponent from 'module/Report/Component/CourierReport/CourierReportComponent';
import CourierReportComponentNew from 'module/Report/Component/CourierReport/CourierReportComponentNew';
import { connect } from 'react-redux';
import {
  getProductByCustomers,
  getAllCustomers,
  getAllBranch,
  getCourierWithBranch,
} from 'services/GeneralService';

interface IProps {
  roleAccount: any;
  branchAuth: any;
}

function CourierReportContainer({ roleAccount, branchAuth }: IProps) {
  const [branchItems, setBranchItems] = useState<Array<any>>([]);
  const [courierItems, setCourierItems] = useState<Array<any>>([]);
  const [productItems, setProductItems] = useState<Array<any>>([]);

  const [typeForm, setTypeForm] = useState<string>('Process');
  const [startDateProcess, setStartDateProcess] = useState<any>(null);
  const [endDateProcess, setEndDateProcess] = useState<any>(null);
  const [typeReport, setTypeReport] = useState<any>(null);
  const [selectedBranch, setSelectedBranch] = useState<Array<any>>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [selectAllCustomer, setSelectAllCustomer] = useState<boolean>(false);
  const [selectedCourier, setSelectedCourier] = useState<Array<any>>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [typeFormatFile, setTypeFormatFile] = useState<string>('xls');
  const [selectedFormatFile, setSelectedFormatFile] = useState<string>('xls');
  const [selectedStatus, setSelectedStatus] = useState<any>([]);
  const [isSla, setIsSla] = useState<boolean>(false);

  const { data: dataAllCustomers, isLoading: isLoadingCustomers } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataCustomersReport'],
    queryFn: () => getAllCustomers(),
  });

  const { data: dataAllBranch, isLoading: isLoadingBranch } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataBranchReport'],
    queryFn: () => getAllBranch(),
  });

  const { data: dataAllProducs, isLoading: isLoadingProduct } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataProductByCustomersReport', { id: selectedCustomer }],
    queryFn: ({ queryKey }) => getProductByCustomers(queryKey),
    enabled: !!selectedCustomer,
  });

  const { data: dataAllCourier, isLoading: isLoadingCourier } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataProductByCustomersReport', { branchItems: selectedBranch }],
    queryFn: ({ queryKey }) => getCourierWithBranch(queryKey),
    enabled: selectedBranch.length !== 0,
  });

  const { mutate: submitManifestSafeCode, isLoading: isLoadingSubmit } =
    useMutation(postReportCourier, {
      onSuccess: res => {
        if (res.status === 200) {
          downloadBlob(
            res.data,
            `LaporanKurir -${moment(startDateProcess).format(
              'YYYYMMDD'
            )}20230301 s_d ${moment(endDateProcess).format('YYYYMMDD')}`,
            'xls'
          );
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Menjalankan Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  useEffect(() => {
    if (dataAllBranch && dataAllBranch.data.length > 0) {
      const branchArr = [{ id: 0, name: 'Pilih Semua' }] as any;
      dataAllBranch.data.forEach(item => {
        branchArr.push(item);
      });
      setBranchItems(branchArr);
    }
  }, [dataAllBranch]);

  useEffect(() => {
    if (dataAllCourier && dataAllCourier?.data?.length > 0) {
      const courierArr = [{ id: 0, name: 'Pilih Semua Kurir' }] as any;
      dataAllCourier?.data?.forEach(item => {
        courierArr.push({
          id: item.id,
          name: `${item.firstName} (${item.code})`,
        });
      });
      setCourierItems(courierArr);
    } else {
      setCourierItems([]);
    }
  }, [dataAllCourier]);

  useEffect(() => {
    if (dataAllProducs && dataAllProducs?.data?.data?.length > 0) {
      const courierArr = [{ id: 0, name: 'Pilih Semua Produk' }] as any;
      dataAllProducs?.data?.data?.forEach(item => {
        courierArr.push({
          id: item.id,
          name: item.name,
        });
      });
      setProductItems(courierArr);
    }
  }, [dataAllProducs]);

  useEffect(() => {
    if (selectAllCustomer === true) {
      setSelectedCustomer(null);
      setSelectedProduct([]);
    }
  }, [selectAllCustomer]);

  useEffect(() => {
    if (typeReport === 'ringkasan') {
      setSelectedFormatFile('xls');
    }
  }, [typeReport]);

  useEffect(() => {
    if (roleAccount === 'Branch' && branchAuth && selectedBranch.length === 0) {
      setSelectedBranch([branchAuth]);
    }
  }, [roleAccount, branchAuth, selectedBranch]);

  const handleSelectedCustomer = value => {
    setSelectedCustomer(value);
  };

  const handleClearForm = () => {
    setTypeForm('Process');
    setStartDateProcess(null);
    setEndDateProcess(null);
    setTypeReport(null);
    setSelectedBranch([]);
    setSelectedCustomer(null);
    setSelectAllCustomer(false);
    setSelectedCourier([]);
    setSelectedProduct([]);
    setSelectedFormatFile('xls');
    setSelectedStatus(null);
    setIsSla(false);
  };

  const handleSubmit = value => {
    const data = {
      couriers: value.courier,
      csv: value.formatFile === 'csv',
      customerProducts: value.product,
      dateFrom: value.startDate,
      dateTo: value.endDate,
      deliveryStats: value.status,
      sla: value.sla,
      reportCourierType: value.typeReport,
      customerAll: value.allCustomer,
      filterDateType: value.typeForm,
    };
    submitManifestSafeCode(data);
  };

  return (
    <CourierReportComponentNew
      isLoadingSubmit={isLoadingSubmit}
      ListCustomer={dataAllCustomers?.data.data || []}
      loadingSelectCustomer={isLoadingCustomers}
      loadingSelectBranch={isLoadingBranch}
      roleAccount={roleAccount}
      BranchList={branchItems}
      productList={productItems}
      courierItems={courierItems}
      isLoadingProduct={isLoadingProduct}
      isLoadingCourier={isLoadingCourier}
      handleClearForm={handleClearForm}
      // VALUE FORM
      typeForm={typeForm}
      startDateProcess={startDateProcess}
      endDateProcess={endDateProcess}
      typeReport={typeReport}
      selectedBranch={selectedBranch}
      selectedCustomer={selectedCustomer}
      selectAllCustomer={selectAllCustomer}
      selectedCourier={selectedCourier}
      selectedProduct={selectedProduct}
      selectedFormatFile={selectedFormatFile}
      typeFormatFile={typeFormatFile}
      selectedStatus={selectedStatus}
      isSla={isSla}
      // SET VALUE FORM
      setTypeForm={value => setTypeForm(value)}
      setStartDateProcess={value => setStartDateProcess(value)}
      setEndDateProcess={value => setEndDateProcess(value)}
      setTypeReport={value => setTypeReport(value)}
      setSelectedBranch={value => setSelectedBranch(value)}
      handleSetCustomer={value => handleSelectedCustomer(value)}
      setSelectAllCustomer={value => setSelectAllCustomer(value)}
      setSelectedCourier={value => setSelectedCourier(value)}
      setSelectedProduct={value => setSelectedProduct(value)}
      setSelectedFormatFile={value => setSelectedFormatFile(value)}
      setTypeFormatFile={value => setTypeFormatFile(value)}
      setSelectedStatus={value => setSelectedStatus(value)}
      setIsSla={value => setIsSla(value)}
      handleSubmit={handleSubmit}
    />
  );
}

const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  branchAuth: state.Auth.BranchAuth,
  GetBranch: state.Report.CourierReportForm.BranchCourierR,
  GetRadioButton: state.Report.CourierReportForm.RadioButtonCourierR,
  GetPickupDate: state.Report.CourierReportForm.PickupDateCourierR,
  GetDeliveryDate: state.Report.CourierReportForm.DeliveryDateCourierR,
  GetStatus: state.Report.CourierReportForm.StatusR,
  GetFormatFile: state.Report.CourierReportForm.FormatFileR,
  GetCustomer: state.Report.CourierReportForm.CustomerIdR,
  GetCustomerProduct: state.Report.CourierReportForm.CustomerProductIdR,
  GetCourier: state.Report.CourierReportForm.CourierR,
  GetRingkasanCB: state.Report.CourierReportForm.RingkasanR,
  GetSlaCB: state.Report.CourierReportForm.SlaR,
  ListCourier: state.Table.TableCourier,
  ListCustomer: state.Customer.ListCustomer,
  ListCustomerProduct: state.CustomerProduct.ListCustomerProduct,
  BranchList: state.Branch.AllBranch,
  Fiture: state.LastAccess.Fiture,
  isLoading: state.Toggle.isLoading,
  LoadingSelectCustomer: state.Customer.PropertiesCustomer.LoadingCustomer,
  LoadingSelectCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.LoadingCustomerProduct,
  LoadingSelectBranch: state.Branch.PropertiesBranch.LoadingBranch,
  TypeLoadingSelectBranch: state.Branch.PropertiesBranch.TypeLoadingBranch,

  LoadingSelectCourier: state.Courier.PropertiesCourier.LoadingCourier,
  TypeLoadingSelectCourier: state.Courier.PropertiesCourier.TypeLoadingCourier,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
  actionBranch: bindActionCreators(Branch, dispatch),
  actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
  actionListCustomerProduct: bindActionCreators(
    ActionListCustomerProduct,
    dispatch
  ),
  actionReport: bindActionCreators(Report, dispatch),
  actionCourierTable: bindActionCreators(CourierTable, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CourierReportContainer);
