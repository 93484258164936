import React from 'react';
import { Table } from 'antd';
import { Form, Field } from 'react-final-form';
import styles from './ModalAddCodeRetur.module.css';
import Button from 'components/Button';
import ButtonMaterial from 'materials/ButtonMaterial';
import { InputText } from 'components/InputField';
import ModalMaterial from 'materials/ModalMaterial';

type ModalAddCodeReturComponentProps = {
  onCancel: () => void;
  onSave: () => void;
  isShow: boolean;
  dataManifestRetur: Array<any>;
  manifestSafeCode: string;
  setManifestSafeCode: (value: string) => void;
  setManifestSafeCodeId: (value: number) => void;
};

export default function ModalAddCodeReturComponent({
  onCancel,
  onSave,
  isShow,
  dataManifestRetur,
  setManifestSafeCode,
  setManifestSafeCodeId,
  manifestSafeCode,
}: ModalAddCodeReturComponentProps) {
  const columnsTable = [
    {
      title: <b>No</b>,
      render: (record: any, content: any, index: any) => (
        <span>{index + 1}</span>
      ),
      key: 'no',
    },
    {
      title: <b>Kode Brankas</b>,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <b>Aksi</b>,
      key: 'action',
      render: (record: any) => (
        <div>
          <ButtonMaterial
            icon="edit"
            type="normal"
            style={{
              background: '#FFE600',
              color: 'white',
              borderRadius: 8,
              border: 'none',
            }}
            handleSubmit={() => {
              setManifestSafeCode(record.name);
              setManifestSafeCodeId(record.id);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <ModalMaterial
      teksTitle="TAMBAHKAN KODE BRANKAS"
      visible={isShow}
      width="40%"
      onCancel={onCancel}
      content={
        <Form
          onSubmit={onSave}
          initialValues={{
            manifestSafeCode: manifestSafeCode,
          }}
          validate={values => {
            const errors = {} as any;
            if (!values.manifestSafeCode) {
              errors.manifestSafeCode = 'Kode Brankas wajib diisi';
            }
            if (values.manifestSafeCode && values.manifestSafeCode.length < 3) {
              errors.manifestSafeCode = 'Minimal 3 karakter';
            }
            // eslint-disable-next-line
            if (/[^a-zA-Z0-9\-\/]/.test(values.manifestSafeCode)) {
              errors.manifestSafeCode = 'Hanya bisa huruf dan angka';
            }
            return errors;
          }}
        >
          {({ handleSubmit, invalid }) => (
            <form>
              <div className={styles.wrapperContent}>
                <Table
                  columns={columnsTable}
                  style={{ width: '100%' }}
                  dataSource={dataManifestRetur}
                  pagination={false}
                  className={styles.tableList}
                />
                <Field name="manifestSafeCode">
                  {({ input, meta }) => (
                    <div className={styles.wrapperSelectField}>
                      <InputText
                        label="Kode Brankas"
                        placeholder="Masukan kode Brankas"
                        input={input}
                        meta={meta}
                        onChange={value => setManifestSafeCode(value)}
                      />
                    </div>
                  )}
                </Field>
                <div className={styles.wrapperAction}>
                  <Button
                    text="Batal"
                    size="small"
                    borderRadius="small"
                    variant="danger"
                    onClick={() => setManifestSafeCode('')}
                    disabled={invalid}
                  />
                  <Button
                    text="Simpan"
                    size="small"
                    borderRadius="small"
                    onClick={handleSubmit}
                    htmlType="submit"
                    disabled={invalid}
                  />
                </div>
              </div>
            </form>
          )}
        </Form>
      }
    />
  );
}
