import * as ActionPagination from '../../../core/action/Pagination/ActionPagination';
import * as AdminTable from '../../../core/action/ManageAccount/AdminBranch/ActionTableAdminBranch';
import * as CourierTable from '../../../core/action/ManageAccount/Courier/ActionTableCourier';
import * as CustomerTable from '../../../core/action/Customer/ActionTableCustomer';
import * as ActionToggle from '../../../core/action/toggle/index';
import * as LastAccess from '../../../core/action/LastAccess/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import IndexComponent from '../../../components/Content/ManageAccount/IndexComponent';
import { connect } from 'react-redux';

interface IProps {
  actionCourierTable: any;
  actionAdminTable: any;
  actionCustomerTable: any;
  actionLastAccess: any;
  actionPagination: any;
  actionToggle: any;
  Fiture: any;
  showAdminTable: any;
  showCourierTable: any;
  roleAccount: any;
  sideMenus: any;
}

class IndexContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleChangeTabs = this.handleChangeTabs.bind(this);
  }

  handleChangeTabs(event: any) {
    this.props.actionPagination.setPagePagination(1);
    this.props.actionToggle.changeValueSearch(null);
    if (event === '1') {
      this.props.actionLastAccess.changeLastAccessFiture('adminTable');
    } else if (event === '2') {
      this.props.actionLastAccess.changeLastAccessFiture('courierTable');
      this.props.actionCourierTable.getCourierTable();
    } else if (event === '3') {
      this.props.actionLastAccess.changeLastAccessFiture('customerTable');
      this.props.actionCustomerTable.GetTableCustomer();
    }
  }

  render() {
    return (
      <IndexComponent
        roleAccount={this.props.roleAccount}
        lastAccessFiture={this.props.Fiture}
        handleChangeTabs={this.handleChangeTabs}
        sideMenus={this.props.sideMenus}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  Fiture: state.LastAccess.Fiture,
  roleAccount: state.Auth.Role,
  sideMenus: state.Auth.SideMenus,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionCourierTable: bindActionCreators(CourierTable, dispatch),
  actionAdminTable: bindActionCreators(AdminTable, dispatch),
  actionCustomerTable: bindActionCreators(CustomerTable, dispatch),
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IndexContainer);
