import { Col, Row, Table } from 'antd';

import CButton from '../../../../apps/assets/components/CButton';
import CCard from 'apps/assets/components/CCard';
import CSpin from '../../../../apps/assets/components/CSpin';
import React from 'react';
import { TextInputSearch } from 'components/InputField';
import { DropdownBasic } from 'components/DropdownBasic';

function LevelComponent(props: any) {
  const { columns, handlePageAddNewLevel, levelList, isLoading } = props;
  const buttonData = [
    {
      type: 'link',
      className: 'btnLinkAdd',
      onClick: handlePageAddNewLevel,
      isLoading: isLoading,
      content: <b>TAMBAH LEVEL</b>,
      id: 'btnPopupAddComplaint',
    },
    {
      type: 'circle',
      className: 'btnTableAdd',
      onClick: handlePageAddNewLevel,
      isLoading: isLoading,
      icon: 'plus',
      id: 'btnPopupAddComplaint',
    },
  ];
  const dataN = [
    {
      label: 'Aktif',
      key: 'Active',
    },
    {
      label: 'Tidak aktif',
      key: 'Disable',
    },
    {
      label: 'Semua',
      key: 'None',
    },
  ];
  return (
    <>
      <CSpin
        spinning={props.isLoading}
        size={'large'}
        content={
          <div className="defaultSpace">
            <CCard
              cardContent={
                <React.Fragment>
                  <Row>
                    <Col span={10}>
                      <TextInputSearch
                        name="searchValue"
                        idComponent="inputSearch"
                        label="LEVEL GRUP AKSES"
                        className="searchForm"
                        placeholder={'Cari berdasarkan nama atau tipe'}
                        regex={'noSpaceInFirstChar'}
                        value={props.searchGroup}
                        onChange={props.handleSearchGroup}
                        additionalHandleEnterFunc={() =>
                          props.handleSearchLevel(props.searchGroup)
                        }
                      />
                    </Col>
                    <Col span={6}>
                      <DropdownBasic
                        name="filterValue"
                        idComponent="filterValue"
                        dropdownMenu={dataN}
                        triggerAction={'click'}
                        labelTitle={'Aktif'}
                        className="floatLeft"
                        value={props.statusGroup}
                        onChange={props.handleStatusGroup}
                      />
                    </Col>
                    <Col span={8}>
                      <div className="floatRight">
                        <CButton buttonData={buttonData} />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              }
            />
            <Table
              pagination={{
                onChange: page => {
                  props.handlePage(page);
                },
                pageSize: 10,
                total: props.totalPagination,
                current: props.pagePagination === 0 ? 1 : props.pagePagination,
              }}
              bordered={false}
              dataSource={levelList}
              columns={columns}
            />
          </div>
        }
      />
    </>
  );
}

export default LevelComponent;
