export function GetTableCustomerProduct(isActiveType = 'Active') {
  return {
    type: 'GET_TABLE_CUSTOMER_PRODUCT',
    payload: {
      isActiveType,
    },
  };
}
export function SetTableCustomerProduct(TableCustomerProduct: any) {
  return {
    type: 'SET_TABLE_CUSTOMER_PRODUCT',
    TableCustomerProduct,
  };
}
export function ClearTableCustomerProduct() {
  return {
    type: 'CLEAR_TABLE_CUSTOMER_PRODUCT',
  };
}
