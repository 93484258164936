import React, { useState } from 'react';
import styles from './TemplateUploadComponent.module.css';
import { Col, Row, Icon } from 'antd';
import SearchMaterial from '../../../../materials/SearchMatrial';
import CardMaterial from 'materials/CardMaterial';
import { DropdownBasic } from 'components/DropdownBasic';
import ButtonMaterial from 'materials/ButtonMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import { InputSwitch } from 'components/InputField';

type TemplateUploadComponentProps = {
  isLoading: boolean;
  CustomerName: string;
  handleBack: () => void;
  dataCsvList: any[];
  setIsActiveType: (value: string) => void;
  isActiveType: string;
  handleAddTemplateCsv: () => void;
  handleDetailCustomCsv: (id: number) => void;
  handleEditCustomCsv: (id: number) => void;
  handleDownloadSampleCsv: (id: number) => void;
  handleDeleteCustomCsv: (id: number) => void;
  handleChangeStatus: (id: number) => void;
};

const ItemFormatReport = ({
  isActive = false,
  isUpdate = false,
  name,
  onClickDelete,
  onClickEdit,
  onClickDownload,
  onClickStatus,
  onClick,
}: {
  isActive?: boolean;
  isUpdate?: boolean;
  name: string;
  onClickDelete: () => void;
  onClickEdit: () => void;
  onClickDownload: () => void;
  onClickStatus: () => void;
  onClick: () => void;
}) => {
  return (
    <div
      className={
        isUpdate ? styles.itemFormatReportActive : styles.itemFormatReport
      }
      onClick={onClick}
    >
      <div className={styles.wrapperItemFormat}>
        <p className={styles.itemFormatName}>{name}</p>
        <InputSwitch
          meta={{}}
          input={{
            value: isActive,
            onChange: () => onClickStatus(),
          }}
          formStyle={{ margin: 0 }}
        />
        {isActive ? (
          <p className={styles.itemFormatStatusActive}>Aktif</p>
        ) : (
          <p className={styles.itemFormatStatus}>Nonaktif</p>
        )}
      </div>

      <div className={styles.wrapperAction}>
        <ButtonMaterial
          icon="delete"
          type="danger"
          handleSubmit={e => {
            e.stopPropagation();
            onClickDelete();
          }}
          style={{ borderRadius: 8 }}
          disabled={!isUpdate}
        />
        <ButtonMaterial
          icon="edit"
          style={
            isUpdate
              ? {
                  borderRadius: 8,
                  color: '#ffffff',
                  background: '#FCC642',
                }
              : { borderRadius: 8 }
          }
          handleSubmit={e => {
            e.stopPropagation();
            onClickEdit();
          }}
          disabled={!isUpdate}
        />
        <ButtonMaterial
          icon="download"
          handleSubmit={e => {
            e.stopPropagation();
            onClickDownload();
          }}
          style={{ borderRadius: 8, color: '#ffffff', background: '#7956EE' }}
        />
      </div>
    </div>
  );
};

export default function TemplateUploadComponent({
  isLoading = false,
  CustomerName,
  handleBack,
  dataCsvList,
  isActiveType,
  setIsActiveType,
  handleAddTemplateCsv,
  handleDetailCustomCsv,
  handleEditCustomCsv,
  handleDownloadSampleCsv,
  handleDeleteCustomCsv,
  handleChangeStatus,
}: TemplateUploadComponentProps) {
  const [searchName, setSearchName] = useState<string>('');
  const optionsFilter = [
    {
      label: 'Aktif',
      key: 'Active',
    },
    {
      label: 'Tidak aktif',
      key: 'Disable',
    },
    {
      label: 'Semua',
      key: 'None',
    },
  ];

  return (
    <SpinMaterial size="large" spinning={isLoading}>
      <CardMaterial style={{ borderRadius: '12px 12px 0 0' }}>
        <Row>
          <Col span={20}>
            <div className={styles.wrapperTitleSection}>
              <span className={styles.titleSection}>{CustomerName}</span>
              <SearchMaterial
                style={{ width: '40%' }}
                placeholder="Cari berdasarkan Nama Template"
                onChange={e => setSearchName(e.target.value)}
                value={searchName}
                onPressEnter={() => {}}
              />
              <DropdownBasic
                name="filterValue"
                idComponent="filterValue"
                dropdownMenu={optionsFilter}
                triggerAction={'click'}
                className="floatLeft"
                labelTitle={
                  optionsFilter.find(value => value.key === isActiveType)?.label
                }
                onChange={value => setIsActiveType(value.key)}
              />
            </div>
          </Col>
          <Col span={4}>
            <div className={'floatRight'}>
              <ButtonMaterial
                teksButton="Kembali"
                type="danger"
                style={{ borderRadius: 10, fontWeight: 500 }}
                handleSubmit={handleBack}
              />
            </div>
          </Col>
        </Row>
      </CardMaterial>
      <CardMaterial className={styles.wrapperCardMaterialContent}>
        <div className={styles.wrapperContent}>
          <div
            className={styles.buttonAddCustom}
            onClick={handleAddTemplateCsv}
          >
            <Icon type="plus" style={{ color: '#45E42D' }} />
            <p className={styles.textButton}>
              Tambahkan Pengaturan Template Unggah
            </p>
          </div>

          <div className={styles.wrapperItem}>
            {dataCsvList
              .filter(item =>
                searchName
                  ? item.nameCustomCsv
                      .toLowerCase()
                      .includes(searchName.toLowerCase())
                  : true
              )
              .map((item, index) => (
                <ItemFormatReport
                  key={index}
                  name={item.nameCustomCsv}
                  onClickDelete={() => handleDeleteCustomCsv(item.id)}
                  onClickEdit={() => handleEditCustomCsv(item.id)}
                  onClickDownload={() => handleDownloadSampleCsv(item.id)}
                  isActive={item.isActive}
                  isUpdate={!item.defaultStatus}
                  onClick={() => handleDetailCustomCsv(item.id)}
                  onClickStatus={() => handleChangeStatus(item.id)}
                />
              ))}
          </div>
        </div>
      </CardMaterial>
    </SpinMaterial>
  );
}
