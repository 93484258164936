import * as ActionDetailBranch from '../../../../core/action/Branch/ActionDetailBranch';
import * as actionBranch from '../../../../core/action/Branch/ActionAllBranch';
import * as actionGenerateCourierCode from '../../../../core/action/ManageAccount/Courier/ActionGenerateCourierCode';
import * as detailCourier from '../../../../core/action/ManageAccount/Courier/ActionIdCourierBranch';
import * as editCourier from '../../../../core/action/ManageAccount/Courier/ActionEditCourier';
import * as formCourier from '../../../../core/action/ManageAccount/Courier/ActionFormDetailCourier';
import * as toggle from '../../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import EditCourierComponent from '../../../../components/Content/ManageAccount/Courier/EditCourierComponent';
import { connect } from 'react-redux';
import moment from 'moment'

interface IProps {
  GetCourierName: any;
  GetCourierLastName: any;
  GetCourierEmail: any;
  GetCourierPhoneNumber: any;
  GetCourierDob: any;
  GetCourierPhoto: any;
  GetCourierCode: any;
  GetCourierAddress: any;
  GetCourierStatus: any;
  GetBranchCourier: any;
  GetCourierAreaCode: any;
  generateCourierCode: any;

  actionFormCourier: any;
  actionEditCourier: any;
  actionBranch: any;
  IdCourier: any;
  BranchList: any;

  Toggle: any;
  visible: any;
  isLoading: any;
  jenisToggle: any;
  roleAccount: any;
  branchAuth: any;
  actionDetailBranch: any;
}

interface IState {
  ValidateCourierName: any;
  ValidateCourierLastName: any;
  ValidateCourierEmail: any;
  ValidateCourierPhoneNumber: any;
  ValidateCourierDob: any;
  ValidateCourierPhoto: any;
  ValidateCourierCode: any;
  ValidateBranchCourier: any;
  ValidateDob: any;
  ValidateCourierBranchArea: any;
  ValidateCourierAddress: any;
  ValidateCourierStatus: any;

  StateDobCourier: any;
}

class EditCourierContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateCourierName: null,
      ValidateCourierLastName: null,
      ValidateCourierEmail: null,
      ValidateCourierPhoneNumber: null,
      ValidateCourierDob: null,
      ValidateCourierPhoto: null,
      ValidateCourierCode: null,
      ValidateBranchCourier: null,
      ValidateCourierAddress: null,
      ValidateCourierStatus: null,
      ValidateDob: null,
      ValidateCourierBranchArea: null,

      StateDobCourier: null,
    };
    this.handleClearEditCourier = this.handleClearEditCourier.bind(this);
    this.handlePutCourierName = this.handlePutCourierName.bind(this);
    this.handlePutCourierLastName = this.handlePutCourierLastName.bind(this);
    this.handlePutCourierEmail = this.handlePutCourierEmail.bind(this);
    this.handlePutCourierPhoneNumber = this.handlePutCourierPhoneNumber.bind(
      this
    );
    this.handlePutCourierDob = this.handlePutCourierDob.bind(this);
    this.handlePutCourierPhoto = this.handlePutCourierPhoto.bind(this);
    this.handlePutCourierCode = this.handlePutCourierCode.bind(this);
    this.handlePutAreaCode = this.handlePutAreaCode.bind(this);
    this.handleGenerateCourierCode = this.handleGenerateCourierCode.bind(this);
    this.handleSubmitEditDataCourier = this.handleSubmitEditDataCourier.bind(
      this
    );
    this.handleValidateDob = this.handleValidateDob.bind(this);
    this.handleBlurAreaCode = this.handleBlurAreaCode.bind(this);
    this.handlePutAddress = this.handlePutAddress.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
  }

  handleGetBranch() {
    this.props.actionBranch.getAllBranch();
  }

  handleClearEditCourier() {
    this.props.Toggle.changeModal();
    this.props.Toggle.clearJenisToggle();
    this.props.actionDetailBranch.setBranchDetail(null);
    this.props.actionFormCourier.changeClearDataCourierForm();
    this.props.IdCourier.clearDetailCourier();
    this.setState({
      ValidateCourierName: null,
      ValidateCourierLastName: null,
      ValidateCourierEmail: null,
      ValidateCourierPhoneNumber: null,
      ValidateCourierDob: null,
      ValidateCourierPhoto: null,
      ValidateCourierCode: null,
      ValidateCourierAddress: null,
      ValidateBranchCourier: null,
      ValidateDob: null,
      StateDobCourier: null,
    });
  }

  handlePutCourierName(event: any) {
    this.props.actionFormCourier.changeCourierName(event.target.value);
    const RegexMinChar = /(?=^.{3,}$)/;
    if (event.target.value === '') {
      this.setState({
        ValidateCourierName: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateCourierName: 'minchar',
      });
    } else {
      this.setState({
        ValidateCourierName: '',
      });
    }
  }

  handlePutCourierLastName(event: any) {
    this.props.actionFormCourier.changeCourierLastName(event.target.value);
    const RegexMinChar = /(?=^.{3,}$)/;
    if (event.target.value === '') {
      this.setState({
        ValidateCourierLastName: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateCourierLastName: 'minchar',
      });
    } else {
      this.setState({
        ValidateCourierLastName: '',
      });
    }
  }

  handlePutCourierEmail(event: any) {
    const RegexEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;
    this.props.actionFormCourier.changeCourierEmail(event.target.value);

    if (event.target.value === '') {
      this.setState({
        ValidateCourierEmail: 'error',
      });
    } else if (!RegexEmail.test(event.target.value)) {
      this.setState({
        ValidateCourierEmail: 'NotEmail',
      });
    } else {
      this.setState({
        ValidateCourierEmail: '',
      });
    }
  }

  handlePutCourierPhoneNumber(event: any) {
    const number = parseInt(event.target.value || 0, 10);
    const RegexPhone = /^[0-9\b]+$/;

    if (isNaN(number)) {
      return;
    }

    if (!('value' in this.props)) {
      this.props.actionFormCourier.changeCourierPhoneNumber(
        event.target.value.replace(/^0+/, '')
      );
    }

    if (event.target.value === '') {
      this.setState({
        ValidateCourierPhoneNumber: 'error',
      });
    } else if (!RegexPhone.test(event.target.value)) {
      this.setState({
        ValidateCourierPhoneNumber: 'NonNumeric',
      });
    } else {
      this.setState({
        ValidateCourierPhoneNumber: '',
      });
    }
  }

  handlePutCourierDob(event: any) {
    if(event){
      const dateValue = event._d?(moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')):event
      this.props.actionFormCourier.changeCourierDob(dateValue);
    }else{
      this.props.actionFormCourier.changeCourierDob(null);
    }
    this.setState({
      StateDobCourier: event,
    });
    if (event === '') {
      this.setState({
        ValidateCourierDob: 'error',
      });
    } else {
      this.setState({
        ValidateCourierDob: '',
      });
    }
  }

  handleValidateDob(event: any) {
    this.setState({
      ValidateDob: event,
    });
    if (this.state.ValidateDob === true) {
      if (
        this.props.GetCourierDob === null ||
        this.props.GetCourierDob === undefined ||
        this.props.GetCourierDob === 'Invalid date'
      ) {
        this.setState({
          ValidateCourierDob: 'error',
        });
      } else {
        this.setState({
          ValidateCourierDob: '',
        });
      }
    } else {
    }
  }

  handlePutAddress(event: any) {
    this.props.actionFormCourier.changeCourierAddress(event.target.value);
    const panjang = event.target.value.length;
    if (event.target.value === '') {
      this.setState({
        ValidateCourierAddress: 'error',
      });
    } else if (panjang < 10) {
      this.setState({
        ValidateCourierAddress: 'minchar',
      });
    } else {
      this.setState({
        ValidateCourierAddress: '',
      });
    }
  }

  handleCheckBox(event: any) {
    if (event.target.checked === true) {
      this.props.actionFormCourier.changeCourierStatus(1);
    } else {
      this.props.actionFormCourier.changeCourierStatus(0);
    }
  }

  handlePutCourierPhoto(event: any) {
    this.props.actionFormCourier.changeCourierPhoto(event.target.value);
  }

  handlePutCourierCode(event: any) {
    this.props.actionFormCourier.changeCourierCode(event.target.value);
  }

  handlePutAreaCode(event: any) {
    this.props.actionFormCourier.changeAreaCodeId(event);
  }

  handleBlurAreaCode() {
    if (this.props.GetCourierAreaCode === null) {
      this.setState({
        ValidateCourierBranchArea: 'error',
      });
    } else {
      this.setState({
        ValidateCourierBranchArea: null,
      });
    }
  }

  handleGenerateCourierCode() {
    this.props.generateCourierCode.generateCourierCode();
  }

  handleSubmitEditDataCourier() {
    this.props.actionEditCourier.submitEditCourier();
  }

  render() {
    return (
      <EditCourierComponent
        visible={
          this.props.jenisToggle === 'EditCourier' &&
          this.props.visible === true
            ? true
            : false
        }
        roleAccount={this.props.roleAccount}
        BranchAuth={this.props.branchAuth}
        GetCourierAddress={this.props.GetCourierAddress}
        GetCourierStatus={this.props.GetCourierStatus}
        handleClearEditCourier={this.handleClearEditCourier}
        ValidateButton={
          this.props.GetCourierName === null ||
          this.props.GetCourierName === '' ||
          this.props.GetCourierLastName === null ||
          this.props.GetCourierLastName === '' ||
          this.props.GetCourierAreaCode === null ||
          this.props.GetCourierAreaCode === '' ||
          this.props.GetCourierDob === null ||
          this.props.GetCourierDob === 'Invalid date' ||
          this.props.GetCourierPhoneNumber === null ||
          this.props.GetCourierPhoneNumber === '' ||
          this.props.GetCourierAddress === null ||
          this.props.GetCourierAddress === '' ||
          this.state.ValidateCourierPhoneNumber === 'NonNumeric'
            ? true
            : false
        }
        PutCourierName={this.handlePutCourierName}
        GetCourierName={this.props.GetCourierName}
        ValidateCourierName={
          this.state.ValidateCourierName === 'error'
            ? 'error'
            : '' || this.state.ValidateCourierName === 'minchar'
            ? 'error'
            : ''
        }
        ValidateCourierLastName={
          this.state.ValidateCourierLastName === 'error'
            ? 'error'
            : '' || this.state.ValidateCourierLastName === 'minchar'
            ? 'error'
            : ''
        }
        helpCourierName={
          this.state.ValidateCourierName === 'error'
            ? 'Nama depan wajib diisi'
            : '' || this.state.ValidateCourierName === 'minchar'
            ? 'Nama depan harus lebih dari 2 karakter'
            : ''
        }
        helpCourierLastName={
          this.state.ValidateCourierLastName === 'error'
            ? 'Nama belakang wajib diisi'
            : '' || this.state.ValidateCourierLastName === 'minchar'
            ? 'Nama belakang harus lebih dari 2 karakter'
            : ''
        }
        PutCourierLastName={this.handlePutCourierLastName}
        GetCourierLastName={this.props.GetCourierLastName}
        PutCourierEmail={this.handlePutCourierEmail}
        GetCourierEmail={this.props.GetCourierEmail}
        ValidateCourierEmail={
          this.state.ValidateCourierEmail === 'error'
            ? 'error'
            : '' || this.state.ValidateCourierEmail === 'NotEmail'
            ? 'error'
            : ''
        }
        helpCourierEmail={
          this.state.ValidateCourierEmail === 'error'
            ? 'Email Tidak Boleh Kosong'
            : '' || this.state.ValidateCourierEmail === 'NotEmail'
            ? 'Maaf format email salah'
            : ''
        }
        PutCourierPhoneNumber={this.handlePutCourierPhoneNumber}
        GetCourierPhoneNumber={this.props.GetCourierPhoneNumber}
        ValidateCourierPhoneNumber={
          this.state.ValidateCourierPhoneNumber === 'error'
            ? 'error'
            : '' || this.state.ValidateCourierPhoneNumber === 'NonNumeric'
            ? 'error'
            : ''
        }
        helpCourierPhoneNumber={
          this.state.ValidateCourierPhoneNumber === 'error'
            ? 'No Telp Tidak Boleh Kosong'
            : '' || this.state.ValidateCourierPhoneNumber === 'NonNumeric'
            ? 'Tidak boleh selain angka!'
            : ''
        }
        PutCourierDob={this.handlePutCourierDob}
        GetCourierDob={this.props.GetCourierDob}
        ValidateCourierDob={
          this.state.ValidateCourierDob === 'error' ? 'error' : ''
        }
        helpCourierDob={
          this.state.ValidateCourierDob === 'error'
            ? 'Tanggal Lahir Tidak Boleh Kosong'
            : ''
        }
        handleValidateDob={this.handleValidateDob}
        PutCourierPhoto={this.handlePutCourierPhoto}
        GetCourierPhoto={this.props.GetCourierPhoto}
        PutCourierCode={this.handlePutCourierCode}
        GetCourierCode={this.props.GetCourierCode}
        PutCourierAddress={this.handlePutAddress}
        ValidateCourierAddress={
          this.state.ValidateCourierAddress === 'error'
            ? 'error'
            : '' || this.state.ValidateCourierAddress === 'minchar'
            ? 'error'
            : ''
        }
        helpCourierAddress={
          this.state.ValidateCourierAddress === 'error'
            ? 'Alamat lengkap wajib diisi'
            : '' || this.state.ValidateCourierAddress === 'minchar'
            ? 'Alamat lengkap harus lebih dari 10 karakter'
            : ''
        }
        ValueGetWilayah={
          this.props.GetCourierCode === null ? (
            <span style={{ color: '#969696' }}>Pilih wilayah posko</span>
          ) : (
            this.props.GetCourierAreaCode
          )
        }
        GetCourierAreaCode={this.props.GetCourierAreaCode}
        ValidateCourierBranchArea={this.state.ValidateCourierBranchArea}
        handleBlurAreaCode={this.handleBlurAreaCode}
        GetBranchCourier={this.props.GetBranchCourier}
        BranchList={this.props.BranchList}
        handleGenerateCourierCode={this.handleGenerateCourierCode}
        handlePutAreaCode={this.handlePutAreaCode}
        handleGetBranch={this.handleGetBranch}
        handleCheckBox={this.handleCheckBox}
        handleSubmitEditDataCourier={this.handleSubmitEditDataCourier}
        isLoading={this.props.isLoading}
        StateDobCourier={this.state.StateDobCourier}
        jenisToggle={this.props.jenisToggle}
      />
    );
  }
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  roleAccount: state.Auth.Role,
  branchAuth: state.Auth.BranchAuth,

  GetCourierName: state.Courier.FormCourier.CourierName,
  GetCourierLastName: state.Courier.FormCourier.CourierLastName,
  GetCourierEmail: state.Courier.FormCourier.CourierEmail,
  GetCourierPhoneNumber: state.Courier.FormCourier.CourierPhoneNumber,
  GetCourierDob: state.Courier.FormCourier.CourierDob,
  GetCourierPhoto: state.Courier.FormCourier.CourierPhoto,
  GetCourierCode: state.Courier.FormCourier.CourierCode,
  GetCourierAddress: state.Courier.FormCourier.CourierAddress,
  GetCourierStatus: state.Courier.FormCourier.CourierStatus,
  GetBranchCourier: state.Courier.FormCourier.BranchCourier,
  GetCourierAreaCode: state.Courier.FormCourier.AreaCodeId,
  BranchList: state.Branch.AllBranch,
  isLoading: state.Toggle.isLoading,
  jenisToggle: state.Toggle.jenisToggle,
});
const mapDispatchToProps = (dispatch: any) => ({
  Toggle: bindActionCreators(toggle, dispatch),
  actionFormCourier: bindActionCreators(formCourier, dispatch),
  IdCourier: bindActionCreators(detailCourier, dispatch),
  actionDetailBranch: bindActionCreators(ActionDetailBranch, dispatch),

  actionEditCourier: bindActionCreators(editCourier, dispatch),
  generateCourierCode: bindActionCreators(actionGenerateCourierCode, dispatch),
  actionBranch: bindActionCreators(actionBranch, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EditCourierContainer);
