import React, { Component } from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import history from '../../../../core/utils/History';
import * as ActionToggle from '../../../../core/action/toggle/index';
import * as ActionActivationVoip from '../../../../core/action/Customer/Voip/ActionActivationVoip';
import * as ActionListCustomerProduct from '../../../../core/action/CustomerProduct/ActionListCustomerProduct';
import ActivationVoipComponent from '../../../../components/Content/Customer/Voip/ActivationVoipComponent';

interface IProps
{
    actionToggle:any
    actionActivationVoip:any
    actionListCustomerProduct:any
    lastAccessFiture:any
    jenisToggle:any
    visible:any
    listServerVoip:any
    listCustomerProduct:any
    toggleActivation:any
    productType:any
}

interface IState
{
    ArrayValue:any
    ValidateCustomerProduct: any;
    seeMore: any;
}

class ActivationVoipContainer extends Component <IProps, IState> {
    constructor(props:any){
        super (props)
        this.state=
        {
            ArrayValue:null,
            ValidateCustomerProduct:null,
            seeMore:5,
        }
        this.handleBack = this.handleBack.bind(this);
        this.handleActivationToggle = this.handleActivationToggle.bind(this);
        this.handlePutCustomerProduct = this.handlePutCustomerProduct.bind(this);
        this.handlePut = this.handlePut.bind(this);
        this.handleGetCustomerProduct = this.handleGetCustomerProduct.bind(this);
        this.handleBlurSelectProduct = this.handleBlurSelectProduct.bind(this);
        this.handleSeeMore = this.handleSeeMore.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleBack()
    {
        history.push("/customer")
    }
    
    handleSeeMore()
    {
      this.setState({
        seeMore: this.props.listServerVoip.length,
      });
    }
    handleSubmit()
    {
        this.props.actionActivationVoip.addActivationProductVoip()
    }

    handleActivationToggle(event:any)
    {
        this.props.actionActivationVoip.changeToggleActivation(event)
        if(event===false){
          this.props.actionActivationVoip.clearProductActivationVoip()
          this.setState({
            ValidateCustomerProduct: null,
          });
        }
    }
    
    handleGetCustomerProduct()
    {
        this.props.actionListCustomerProduct.getListCustomerProductNoType()
    }

    handlePutCustomerProduct(event:any)
    {
        this.props.actionActivationVoip.changeProductTypeV(event)
        if (event[0] === 'Pilih Semua Produk') {
            this.setState({
              ArrayValue: 'all',
            });
          } else {
            this.setState({
              ArrayValue: 'notAll',
            });
          }
          if (
            this.props.productType === null ||
            this.props.productType === [] ||
            event.length === 0
          ) {
            this.setState({
                ValidateCustomerProduct: 'error',
            });
          } else {
            this.setState({
                ValidateCustomerProduct: null,
            });
          }
    }
    
    handlePut(event:any)
    {
      const searchSelectAll=event.toString().search("SetAllProduct")
      if (searchSelectAll>=0) {
          // this.props.actionInvoice.setCustomerProductId(this.props.listCustomerProduct.map((data:any, index:any)=>data.id))
          this.props.actionActivationVoip.changeProductTypeV(this.props.listCustomerProduct.map((data:any, index:any)=>data.id))
      }else{
        this.props.actionActivationVoip.changeProductTypeV(event)
      }
      
      if(this.props.productType===null||this.props.productType===[]||event.length===0){
          this.setState({
              ValidateCustomerProduct:"error"
          })
      }else{
          this.setState({
              ValidateCustomerProduct:null,
          })
      }
    }

    handleBlurSelectProduct() {
        if (
          this.props.productType === null ||
          this.props.productType === [] ||
          this.props.productType.length === 0
        ) {
          this.setState({
            ValidateCustomerProduct: 'error',
          });
        } else {
          this.setState({
            ValidateCustomerProduct: null,
          });
        }
      }
    componentDidMount(){
      this.props.actionListCustomerProduct.getListCustomerProductNoType()
    }
    componentWillMount()
    {
        this.props.actionActivationVoip.getListActivationServerVoip();
        this.props.actionActivationVoip.getDetailCustomerActivation();
    }

    render() {
        return (
            <div>
                <ActivationVoipComponent
                    visible={this.props.jenisToggle==="defaultDataStatusModal"?true:false}
                    handleBack={this.handleBack}
                    listServerVoip={this.props.listServerVoip}
                    handleActivationToggle={this.handleActivationToggle}
                    handleGetCustomerProduct={this.handleGetCustomerProduct}
                    handlePutCustomerProduct={this.handlePutCustomerProduct}
                    handlePut={this.handlePut}
                    ValueGetProduct={
                        this.props.productType === null || this.props.productType === []
                          ? []
                          : this.props.productType
                      }
                    ValidateCustomerProduct={this.state.ValidateCustomerProduct}
                    helpCustomerProduct={this.state.ValidateCustomerProduct==='error'?'Jenis produk wajib diisi':''}
                    handleBlurSelectProduct={this.handleBlurSelectProduct}
                    handleSubmit={this.handleSubmit}
                    toggleActivation={this.props.toggleActivation}
                    listCustomerProduct={this.props.listCustomerProduct}
                    productType={this.props.productType}
                    ArrayValue={this.state.ArrayValue}
                    seeMore={this.state.seeMore}
                    handleSeeMore={this.handleSeeMore}

                    validateButton={
                      (this.props.productType===null||this.props.productType.length===0)&&this.props.toggleActivation===true?true:false
                    }
                />
            </div>
        )
    }
}

const mapStateToProps = (state:any) => ({
    lastAccessFiture : state.LastAccess.Fiture,
    jenisToggle : state.Toggle.jenisToggle,
    visible : state.Toggle.modal,
    listServerVoip : state.Voip.ListServerVoip,
    toggleActivation : state.Voip.ActivationVoip.toggleActivation,
    productType : state.Voip.ActivationVoip.productType,
    listCustomerProduct: state.CustomerProduct.ListCustomerProduct,
})

const mapDispatchToProps = (dispatch:any) => ({
    actionToggle : bindActionCreators(ActionToggle, dispatch),
    actionActivationVoip : bindActionCreators(ActionActivationVoip, dispatch),
    actionListCustomerProduct : bindActionCreators(ActionListCustomerProduct, dispatch),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
    withConnect
)(ActivationVoipContainer)