import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import IndexPrintWaybillComponent from '../../../../components/Content/Delivery/PrintWaybill/IndexPrintWaybillComponent';
import * as ActionDataDownload from '../../../../core/action/Delivery/Document/ActionDataDownload';
import * as ActionFormPrintWaybill from '../../../../core/action/Delivery/PrintWaybill/ActionFormPrintWaybill';
import * as ActionPagination from '../../../../core/action/Pagination/ActionPagination';
import * as toggle from '../../../../core/action/toggle';

interface IProps
{
    actionFormPrintWaybill:any
    actionDataDownload:any
    actionPagination:any
    DataListPrintWaybill:any
    isLoading:any
    actionToggle:any
    getUploadDate:any
    CountCheckbox:any
    PagePagination:any
    TotalPagePagination:any
}

interface IState
{
    StateDeliveryTime:any
    ValidateUploadDate:any
    page:any
}

class IndexPrintWaybillContainer extends Component<IProps, IState>
{
    constructor(props:any)
    {
        super(props)
        this.state=
        {
            StateDeliveryTime:null,
            ValidateUploadDate:null,
            page:null,
        }

        this.handleSearch = this.handleSearch.bind(this)
        this.handlePutUploadDate = this.handlePutUploadDate.bind(this)
        this.handleValidateUploadDate = this.handleValidateUploadDate.bind(this)
        this.handleDownloadSelectWaybill = this.handleDownloadSelectWaybill.bind(this)
        this.handleReset = this.handleReset.bind(this)
        this.handlePage = this.handlePage.bind(this)
        this.handleModal = this.handleModal.bind(this)
        this.handleModalWaybillPrintType = this.handleModalWaybillPrintType.bind(this)
    }

    handleReset()
    {
    this.props.actionFormPrintWaybill.clearListAndDate()
      this.props.actionFormPrintWaybill.ClearAllCheckbox()
      this.props.actionFormPrintWaybill.clearCountCheckbox()
    }
    handleSearch()
    {
      this.props.actionFormPrintWaybill.getListDataPrintWaybill()
    }
    handleDownloadSelectWaybill()
    {
      this.props.actionFormPrintWaybill.downloadSelectWaybill()
    }
    handlePage(event:any)
    {
        this.props.actionPagination.setPagePagination(event)
        this.setState({
            page:event,
        });
        this.props.actionFormPrintWaybill.getListDataPrintWaybill()
    }
    handlePutUploadDate(event:any)
    {
        this.props.actionFormPrintWaybill.changeUploadDatePW(event)
        
        if(event===""){
            this.setState({
                ValidateUploadDate:"error",
            })
        }else{
            this.setState({
                ValidateUploadDate:null
            })
        }
    }
    handleValidateUploadDate(event:any)
    {
        if(event===true){
            if(this.props.getUploadDate===null||this.props.getUploadDate===undefined||this.props.getUploadDate==="Invalid date"){
                this.setState({
                    ValidateUploadDate:"error",
                })
            }else{
                this.setState({
                    ValidateUploadDate:null
                })
            }
        }else{
            
        }
    }
    handleModal()
    {
        this.props.actionToggle.changeModal()
        this.props.actionToggle.changeJenisToggle("PrintByWaybill")
    }
    
    handleModalWaybillPrintType()
    {
        this.props.actionToggle.changeModal()
        this.props.actionToggle.changeJenisToggle("SelectWaybillPrintType")
    }
    
    render(){
        
        return(
            <React.Fragment>
                <IndexPrintWaybillComponent
                    handleSearch={this.handleSearch}
                    handleModal={this.handleModal}
                    handlePutUploadDate={this.handlePutUploadDate}
                    handleValidateUploadDate={this.handleValidateUploadDate}
                    handleDownloadSelectWaybill={this.handleDownloadSelectWaybill}
                    handleModalWaybillPrintType={this.handleModalWaybillPrintType}
                    ValidateUploadDate={this.state.ValidateUploadDate==="error"?"error":""}
                    helpValidateUploadDate={this.state.ValidateUploadDate==="error"?"Tanggal wajib diisi":""}
                    getUploadDate={this.props.getUploadDate}
                    DataListPrintWaybill={this.props.DataListPrintWaybill}
                    handleReset={this.handleReset}
                    CountCheckbox={this.props.CountCheckbox}
                    handlePage={this.handlePage}
                    page={this.state.page}
                    TotalPagePagination={this.props.TotalPagePagination}
                    PagePagination={this.props.PagePagination}
                    isLoading={this.props.isLoading}
                />
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state:any)=> ({
    DataListPrintWaybill:state.PrintWaybill.FormPrintWaybill.DataListPrintWaybill,
    getUploadDate:state.PrintWaybill.FormPrintWaybill.UploadDate,
    PagePagination:state.Pagination.PagePagination,
    TotalPagePagination:state.Pagination.TotalPagePagination,
    CountCheckbox : state.PrintWaybill.FormPrintWaybill.CountCheckbox,
    isLoading:state.Toggle.isLoading,
})

const mapDispatchToProps = (dispatch:any) => ({
    actionFormPrintWaybill : bindActionCreators(ActionFormPrintWaybill, dispatch),
    actionPagination : bindActionCreators(ActionPagination, dispatch),
    actionToggle : bindActionCreators(toggle, dispatch),
    actionDataDownload : bindActionCreators(ActionDataDownload, dispatch)
})

const withConnect = connect (mapStateToProps, mapDispatchToProps)

export default compose(
    withConnect
)(IndexPrintWaybillContainer)