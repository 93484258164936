import * as ActionLogin from '../../../module/Login/Store';
import * as ActionPagination from '../../action/Pagination/ActionPagination';
import * as ActionTableHoliday from '../../action/Holiday/ActionTableHoliday';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeLoadingFalse,
  changeLoadingTrue,
} from '../../action/toggle/index';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_GetDataTableHoliday() {
  const state = yield select();
  const PagePagination = state.Pagination.PagePagination - 1;
  const SizeDataPagination = state.Pagination.SizeDataPagination;
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Holiday/' +
        PagePagination +
        '/' +
        SizeDataPagination,
      {
        headers: config,
      }
    );

    yield put(ActionTableHoliday.SetTableHoliday(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      yield put(changeLoadingFalse());
      if (error.response.status === 401) {
        yield put(ActionLogin.submitTokenRefrash());
      } else {
        const propsError = ErrorMassage('table holiday');
        const code = propsError.filter((data: any) =>
          data.Code.toString().toLowerCase().includes(error.response.status)
        );
        Modal.error({
          title: (
            <p style={{ color: '#FF0000' }}>
              {code.map((data: any) => data.TitleMassage)}
            </p>
          ),
          content: (
            <div>
              <p style={{ color: '#C0C0C0' }}>
                {code.map((data: any) => data.Massage)}
              </p>
            </div>
          ),
          onOk() {},
        });
      }
    }
  }
}
export function* SM_GetDataTableHoliday() {
  yield takeLatest('GET_TABLE_HOLIDAY', S_GetDataTableHoliday);
}
