import React from 'react';
import { Col, Row } from 'antd';

import CButton from 'apps/assets/components/CButton';
import CCard from 'apps/assets/components/CCard';
import CSpin from 'apps/assets/components/CSpin';
import { InputText, InputSelect } from 'components/InputField';
import { SingleCheckbox } from 'components/SingleCheckbox';
import { TreeField } from 'components/TreeField';
import { Form as FormFinal, Field } from 'react-final-form';
import { accountTypes } from 'lib/constants';

type FormLevelGroupProps = {
  handleBtnBack: () => void;
  handleSubmit?: (value: any) => void;
  setDataMenus?: (value: Array<any>) => void;
  dataAllMenus: Array<any>;
  isEdit?: boolean;
  isView?: boolean;
  isLoadingSubmit?: boolean;
  isLoadingMenus?: boolean;
  dataLevel?: any;
};

const FormLevelGroup = ({
  handleBtnBack,
  handleSubmit = () => undefined,
  dataAllMenus,
  isEdit = false,
  isView = false,
  isLoadingSubmit = false,
  isLoadingMenus = false,
  dataLevel,
  setDataMenus,
}: FormLevelGroupProps) => {
  const btnBack = [
    {
      type: 'primary',
      className: 'btnResetDefault',
      onClick: handleBtnBack,
      isLoading: isLoadingSubmit,
      content: 'Kembali',
      id: 'btnBack',
    },
  ];

  return (
    <CSpin
      spinning={isLoadingMenus}
      size={'large'}
      content={
        <div className="defaultSpace">
          <FormFinal
            onSubmit={handleSubmit}
            initialValues={{
              accountType: dataLevel.accountType,
              levelName: dataLevel.levelName,
              isActive: dataLevel.isActive,
              menus: dataLevel.menus,
            }}
            validate={values => {
              const errors = {} as any;
              if (!values.accountType) {
                errors.accountType = 'Tipe Akun tidak boleh kosong!';
              }
              if (!values.levelName) {
                errors.levelName = 'Nama Level tidak boleh kosong!';
              }
              if (values.levelName && values.levelName.length < 3) {
                errors.levelName = 'Minimal harus 3 karakter!';
              }
              return errors;
            }}
            render={({ handleSubmit, submitting, valid }) => (
              <>
                <CCard
                  cardClassName={'defaultCard'}
                  cardExtra={<CButton buttonData={btnBack} />}
                  cardTitle={
                    <span className="lightBlueColor">
                      {isEdit ? 'Edit' : isView ? 'Detail' : 'Tambah'} Level
                    </span>
                  }
                  cardContent={
                    <Row gutter={16}>
                      <Col span={8}>
                        <Field
                          name="accountType"
                          render={input => (
                            <InputSelect
                              name="accountType"
                              placeholder={'Pilih Tipe Akun'}
                              label={'Tipe Akun'}
                              disabled={isView}
                              data={accountTypes}
                              {...input}
                            />
                          )}
                        />
                      </Col>
                      <Col span={8}>
                        <Field
                          name="levelName"
                          render={input => (
                            <InputText
                              name="levelName"
                              placeholder={'Masukan Nama Level'}
                              label={'Nama Level'}
                              maxlength={30}
                              disabled={isView}
                              {...input}
                            />
                          )}
                        />
                      </Col>
                      <Col span={8}>
                        <Field
                          name="isActive"
                          render={input => (
                            <SingleCheckbox
                              className="spacingMarginCheckbox"
                              name={'checkboxStatus'}
                              idComponent="inputRefreshment"
                              labelRight={'Ceklis untuk aktifkan status'}
                              disabled={isView}
                              {...input}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  }
                />
                <Row className="spacingTop">
                  <CCard
                    cardClassName={'defaultCard'}
                    cardExtra={
                      <CButton
                        buttonData={
                          !isView && [
                            {
                              type: 'primary',
                              className: 'btnDefaultSubmit',
                              onClick: handleSubmit,
                              isLoading: isLoadingSubmit,
                              content: 'Simpan',
                              id: 'btnSubmit',
                              disabled: submitting || !valid,
                            },
                          ]
                        }
                      />
                    }
                    cardTitle={
                      <span className="lightBlueColor">Pilih grup akses</span>
                    }
                    cardContent={
                      <Field
                        name="menus"
                        render={input => (
                          <TreeField
                            name={'treeValue'}
                            idComponent="inputTree"
                            treeData={dataAllMenus}
                            isExpandedAll={isView}
                            expandedKeys={dataLevel.menus}
                            setDataMenuState={setDataMenus}
                            isMultiple
                            isView={isView}
                            {...input}
                          />
                        )}
                      />
                    }
                  />
                </Row>
              </>
            )}
          ></FormFinal>
        </div>
      }
    />
  );
};

export default FormLevelGroup;
