import instance from 'config/interceptor';

export const getCountingScanManifest = async params => {
  try {
    const {
      dateCycle,
      customer,
      username,
      isMonthly,
      deliveryStatus,
      typeDate,
      productIds = [],
    } = params;
    let link = `/GroupManifest/Scan/Counting/${dateCycle}/${customer}/${deliveryStatus}/${isMonthly}/${username}/${typeDate}?`;

    if (productIds?.length > 0) {
      const queryParam = `customerProductIds=${productIds.join(
        '&customerProductIds='
      )}`;
      link = link.concat(`&${queryParam}`);
    }

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const downloadResultCounting = async (data, onDownloadProgress: any) => {
  try {
    const response = await instance.post(
      process.env.REACT_APP_URL + `/GroupManifest/DownloadResultCountManifest`,
      data,
      { responseType: 'arraybuffer', onDownloadProgress }
    );

    return { ...response, fileName: data.conditionResultManifest };
  } catch (error) {
    return error;
  }
};

export const getTableScanManifest = async params => {
  try {
    const {
      dateCycle,
      customer,
      username,
      isMonthly,
      page,
      deliveryStatus,
      typeDate,
      productIds,
      search,
    } = params;
    let link = `/GroupManifest/Scan/Table/${dateCycle}/${customer}/${deliveryStatus}/${isMonthly}/${username}/${typeDate}/${page}/10?`;

    if (productIds?.length > 0) {
      const queryParam = `customerProductIds=${productIds.join(
        '&customerProductIds='
      )}`;
      link = link.concat(`&${queryParam}`);
    }

    if (search) link = link.concat(`&search=${search}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const deleteScanManifest = async data => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + `/GroupManifest/CustomStatus/Inactive`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const addScanManifest = async data => {
  try {
    const response = await instance.post(
      process.env.REACT_APP_URL + `/GroupManifest/AddScan`,
      data
    );

    return response;
  } catch (error) {
    return error;
  }
};
