import { Action } from 'redux';

export interface IFetchComplaintNewListAction extends Action {
  data: object;
}
export interface IFetchComplaintDetailDocAction extends Action {
  data: object;
}
export interface IChangeInvestigationFileAction extends Action {
  data: object;
}

export function fetchComplaintNewList(
  data: object
): IFetchComplaintNewListAction {
  return {
    type: 'FETCH_COMPLAINTNEW_LIST',
    data,
  };
}
export function fetchComplaintDetailDoc(
  data: object
): IFetchComplaintDetailDocAction {
  return {
    type: 'FETCH_COMPLAINT_DETAIL_DOC',
    data,
  };
}
export function changeInvestigationFile(
  data: object
): IChangeInvestigationFileAction {
  return {
    type: 'CHANGE_BASE64_INVESTIGATION_FILE',
    data,
  };
}
export function fetchCourierComplaint(
  data: object
): IFetchComplaintDetailDocAction {
  return {
    type: 'FETCH_COURIER_COMPLAINT',
    data,
  };
}
export function getComplaintList() {
  return {
    type: 'GET_COMPLAINT_LIST_REQUESTED',
  };
}
export function getWaybillComplaint() {
  return {
    type: 'GET_WAYBILL_COMPLAINT',
  };
}
export function getDetailDataComplaint() {
  return {
    type: 'GET_DETAIL_DATA_COMPLAINT',
  };
}
export function getCourierComplaint() {
  return {
    type: 'GET_COURIER_COMPLAINT',
  };
}
export function submitDocComplaint() {
  return {
    type: 'SUBMIT_DOCUMENT_COMPLAINT',
  };
}
export function submitComplaintInQueueToProcess() {
  return {
    type: 'SUBMIT_IN_QUEUE_TO_PROCESS',
  };
}
export function submitProcessToCustomerConfirm() {
  return {
    type: 'SUBMIT_PROCESS_TO_CUSTOMER_CONFIRM',
  };
}
export function submitSearchFilterComplaint() {
  return {
    type: 'SUBMIT_SEARCH_FILTER_COMPLAINT',
  };
}
export function submitChangeCourierInvestigate() {
  return {
    type: 'SUBMIT_CHANGE_COURIER_INVESTIGATE',
  };
}
export function downloadComplaint() {
  return {
    type: 'DOWNLOAD_COMPLAINT',
  };
}
export function clearDetailDocComplaint() {
  return {
    type: 'CLEAR_DETAIL_DOCUMENT_COMPLAINT',
  };
}
export function clearListDocComplaint() {
  return {
    type: 'CLEAR_LIST_DOCUMENT_COMPLAINT',
  };
}
export function clearCourierComplaint() {
  return {
    type: 'CLEAR_COURIER_COMPLAINT',
  };
}
export function clearInvestigationFile() {
  return {
    type: 'CLEAR_BASE64_INVESTIGATION_FILE',
  };
}
export function clearComplaintNewForm() {
  return {
    type: 'RESET_COMPLAINTNEW_FORM',
  };
}
export function clearComplaintDetailForm() {
  return {
    type: 'RESET_COMPLAIN_DETAIL_FORM',
  };
}
