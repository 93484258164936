export function setIdRestoreData(Id: any) {
  return {
    type: 'SET_ID_RESTORE_DATA',
    Id,
  };
}
export function setProductTypeRestoreData(ProductType: any) {
  return {
    type: 'SET_PRODUCT_TYPE_RESTORE_DATA',
    ProductType,
  };
}
export function setUploadDateRestoreData(UploadDate: any) {
  return {
    type: 'SET_UPLOAD_DATE_RESTORE_DATA',
    UploadDate,
  };
}
export function setUploadDateWRestoreData(UploadDateW: any) {
  return {
    type: 'SET_UPLOAD_W_DATE_RESTORE_DATA',
    UploadDateW,
  };
}
export function setRadioRestoreData(RadioButton: any) {
  return {
    type: 'SET_RADIO_RESTORE_DATA',
    RadioButton,
  };
}
export function setRadioRestoreDataW(RadioButtonW: any) {
  return {
    type: 'SET_RADIO_W_RESTORE_DATA',
    RadioButtonW,
  };
}
export function setWaybillRestoreData(Waybill: any) {
  return {
    type: 'SET_WAYBILL_RESTORE_DATA',
    Waybill,
  };
}
export function setSelectAllRestoreData(SelectAll: any) {
  return {
    type: 'SET_SELECT_ALL_RESTORE_DATA',
    SelectAll,
  };
}
export function setSelectAllWRestoreData(SelectAllW: any) {
  return {
    type: 'SET_SELECT_ALL_W_RESTORE_DATA',
    SelectAllW,
  };
}
export function setCountRestoreData(CountFile: any) {
  return {
    type: 'SET_COUNT_RESTORE_DATA',
    CountFile,
  };
}
export function setCountWaybillRestoreData(CountWaybill: any) {
  return {
    type: 'SET_COUNT_WAYBILL_RESTORE_DATA',
    CountWaybill,
  };
}
export function setListRestoreData(ListRestoreData: any) {
  return {
    type: 'SET_LIST_RESTORE_DATA',
    ListRestoreData,
  };
}
export function setListResultDeleteData(ListResultDeleteData: any) {
  return {
    type: 'SET_LIST_RESULT_DELETE_DATA',
    ListResultDeleteData,
  };
}
export function setListWaybillRestoreData(ListWaybillRestoreData: any) {
  return {
    type: 'SET_LIST_WAYBILL_RESTORE_DATA',
    ListWaybillRestoreData,
  };
}
export function clearFormRestoreData() {
  return {
    type: 'CLEAR_FORM_RESTORE_DATA',
  };
}
export function clearDataRestoreData() {
  return {
    type: 'CLEAR_DATA_RESTORE_DATA',
  };
}
export function clearWaybillRestoreData() {
  return {
    type: 'CLEAR_WAYBILL_RESTORE_DATA',
  };
}
export function clearListRestoreData() {
  return {
    type: 'CLEAR_LIST_RESTORE_DATA',
  };
}
export function clearListResultDeleteData() {
  return {
    type: 'CLEAR_LIST_RESULT_DELETE_DATA',
  };
}
export function clearListWaybillRestoreData() {
  return {
    type: 'CLEAR_LIST_WAYBILL_RESTORE_DATA',
  };
}
export function submitGetDataRestoreData() {
  return {
    type: 'SUBMIT_GET_DATA_RESTORE_DATA',
  };
}
export function submitDeleteRestoreData() {
  return {
    type: 'SUBMIT_DELETE_RESTORE_DATA',
  };
}
export function submitDeleteRestoreDataByWaybill() {
  return {
    type: 'SUBMIT_DELETE_RESTORE_DATA_BY_WAYBILL',
  };
}
export function submitDownloadRestoreData() {
  return {
    type: 'SUBMIT_DOWNLOAD_RESTORE_DATA',
  };
}
export function submitRestoreRestoreData() {
  return {
    type: 'SUBMIT_RESTORE_RESTORE_DATA',
  };
}
export function downloadResultDeleteData() {
  return {
    type: 'DOWNLOAD_RESULT_DELETE_FILE',
  };
}
