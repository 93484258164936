import * as ActionComplaint from '../Store/ComplaintAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as SelectorsComplaint from '../../ComplaintNew/Selector/ComplaintSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import { complaintStatusObj } from 'apps/enums';
import { getObjectKey } from 'lib/util';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { change, getFormValues } from 'redux-form';
import { connect, useDispatch } from 'react-redux';

import ComplaintComponent from '../Component/ComplaintComponent';
import { createStructuredSelector } from 'reselect';
import { renderActionComponent } from '../../../apps/assets/components/CButton';

function ComplaintContainer(props) {
  const { complaintAction, pagePagination, componentAction, isLoading } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    complaintAction.submitSearchFilterComplaint();
    dispatch(
      change('complaint', 'complaintStatus', [
        getObjectKey(complaintStatusObj, complaintStatusObj.Proses),
        getObjectKey(complaintStatusObj, complaintStatusObj.DalamAntrian),
      ])
    );
    dispatch(change('complaint', 'filterValue', 'Waybill'));
    // eslint-disable-next-line
  }, [complaintAction]);
  const showStatus = status => {
    return complaintStatusObj[status] || '';
  };
  const renderAction = record => {
    const validateBtn =
      record.stats === complaintStatusObj.KonfirmasiPelanggan ||
      record.stats === complaintStatusObj.Selesai
        ? true
        : false;
    const editClick = () => {
      dispatch(change('complaintForm', 'showType', 'edit'));
      complaintAction.fetchComplaintDetailDoc(record);
      complaintAction.getDetailDataComplaint();
    };
    const handleDetail = () => {
      complaintAction.fetchComplaintDetailDoc(record);
      complaintAction.getDetailDataComplaint();
      dispatch(change('complaintForm', 'showType', 'detail'));
    };

    return renderActionComponent(
      isLoading,
      record.id,
      {
        renderUpdate: true,
        renderInfo: true,
      },
      {
        handleUpdate: editClick,
        handleDetail: handleDetail,
      },
      { validateEdit: validateBtn }
    );
  };
  const looppage = (pagePagination - 1) * 10 + 1;
  const columns = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <b>No</b>
        </div>
      ),
      width: 50,
      render: (text: any, record: any, index: any) => (
        <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
      ),
      key: 'no',
    },
    {
      title: 'Tiket',
      dataIndex: 'tiket',
      render: tiket => <span className="redColor">{tiket}</span>,
      key: 'tiket',
      width: 120,
    },
    {
      title: 'Waybill',
      dataIndex: 'waybillCode',
      key: 'waybillCode',
      width: 110,
    },
    {
      title: 'Subjek Keluhan',
      dataIndex: 'question',
      render: question => <span className="redColor">{question}</span>,
      key: 'question',
      width: 120,
    },
    {
      title: 'Status',
      dataIndex: 'stats',
      key: 'stats',
      render: (stats: any) => showStatus(stats),
      width: 120,
    },
    {
      title: 'Aksi',
      render: (record: any) => renderAction(record),
      width: 120,
    },
  ];
  const handlePage = page => {
    componentAction.setPagePagination(page);
    complaintAction.submitSearchFilterComplaint();
  };
  const showModalCreateComplaint = () => {
    dispatch(change('complaintForm', 'formType', 'inputData'));
    componentAction.openModal('Complaint');
  };
  const handleSubmitSearch = () => {
    componentAction.setPagePagination(0);
    componentAction.setTotalPagePagination(1);
    complaintAction.submitSearchFilterComplaint();
  };
  const handleReset = () => {
    dispatch(
      change('complaint', 'complaintStatus', [
        getObjectKey(complaintStatusObj, complaintStatusObj.Selesai),
        getObjectKey(complaintStatusObj, complaintStatusObj.DalamAntrian),
      ])
    );
    dispatch(change('complaint', 'filterValue', 'Waybill'));
    dispatch(change('complaint', 'searchValueFilter', null));
    complaintAction.submitSearchFilterComplaint();
  };

  return (
    <React.Fragment>
      <ComplaintComponent
        columns={columns}
        handlePage={handlePage}
        handleModalCreateComplaint={showModalCreateComplaint}
        handleSubmitSearch={handleSubmitSearch}
        handleReset={handleReset}
        {...props}
      />
    </React.Fragment>
  );
}

const mapStateToProps = () =>
  createStructuredSelector({
    createComplaintModalIsShow: SelectorsComponent.createComplaintModalIsShow(),
    complaintList: SelectorsComplaint.makeComplaintListSelector(),
    pagePagination: SelectorsComponent.makePagePagination(),
    totalPagination: SelectorsComponent.makeTotalPagePagination(),
    isLoading: SelectorsComponent.makeIsLoading(),
    formValue: getFormValues('complaint'),
  });
const mapDispatchToProps = dispatch => ({
  complaintAction: bindActionCreators(ActionComplaint, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ComplaintContainer);
