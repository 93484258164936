import * as ActionDataActive from '../../../../core/action/Voip/DataActive/ActionDataActive';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionPagination from '../../../../core/action/Pagination/ActionPagination';
import * as ActionToggle from '../../../../core/action/toggle/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import DataActiveComponent from '../../../../components/Content/Voip/DataActive/DataActiveComponent';
import { ErrorMassage } from '../../../../core/ErrorMassage/index';
import { Modal } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';

interface IProps {
  isLoading: any;
  token: any;
  typeToggle: any;
  actionPagination: any;
  ValueSearch: any;
  ListDataActive: any;
  PagePagination: any;
  TotalPagePagination: any;
  actionToggle: any;
  actionDataActive: any;
  actionLogin: any;
}
interface IState {
  sRT: any;
  dataSource: any;
  nameSearch: any;
  page: any;
  key: any;
}
class ServerVoipContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      sRT: null,
      dataSource: this.props.ListDataActive,
      nameSearch: '',
      page: 0,
      key: null,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePutSearch = this.handlePutSearch.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleSyncron = this.handleSyncron.bind(this);
    this.handleViewStatusDelivery = this.handleViewStatusDelivery.bind(this);
  }

  handlePage(event: any) {
    this.props.actionPagination.setPagePagination(event);
    this.setState({
      page: event,
    });
    this.props.actionDataActive.getListDataActive();
  }

  handleSearch() {
    this.props.actionDataActive.submitSearchDataActive();
  }

  handlePutSearch(event: any) {
    this.props.actionToggle.changeValueSearch(event.target.value);
  }

  handleDownload() {
    this.props.actionDataActive.submitDownloadDataActive();
  }

  handleViewStatusDelivery(event: any) {
    const url = '/delivery/status?' + event;
    window.open(url, '_blank');
  }

  handleSyncron() {
    this.props.actionToggle.changeLoadingTrue();
    axios({
      method: 'post',
      url:
        process.env.REACT_APP_URL +
        '/VoipSetting/Syncronize/CreateDataTransaction',
      headers: {
        Authorization: `bearer ${this.props.token}`,
        'Content-type': 'application/json',
      },
    })
      .then(response => {
        if (response.status === 200) {
          this.props.actionToggle.changeLoadingFalse();
          this.props.actionDataActive.getListDataActive();

          Modal.success({
            title: (
              <p style={{ color: '#31E116' }}>
                <b>Sinkronisasi data berhasil</b>
              </p>
            ),

            onOk() {},
          });
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.props.actionLogin.submitTokenRefrash();
          this.props.actionToggle.changeLoadingFalse();
        } else {
          const propsError = ErrorMassage('Sinkronisasi data active');
          const code = propsError.filter((person: any) =>
            person.Code.toString().toLowerCase().includes(error.response.status)
          );
          Modal.error({
            title: (
              <p style={{ color: '#FF0000' }}>
                {code.map((data: any) => data.TitleMassage)}
              </p>
            ),
            content: (
              <div>
                <p style={{ color: '#C0C0C0' }}>
                  {code.map((data: any) => data.Massage)}
                </p>
              </div>
            ),
            onOk() {},
          });
        }
        this.props.actionToggle.changeLoadingFalse();
      });
  }

  render() {
    return (
      <div>
        <DataActiveComponent
          SearchValue={this.props.ValueSearch}
          isLoading={this.props.isLoading}
          page={this.state.page}
          ListDataActive={this.props.ListDataActive}
          PagePagination={this.props.PagePagination}
          TotalPagePagination={this.props.TotalPagePagination}
          handlePage={this.handlePage}
          handleSearch={this.handleSearch}
          handlePutSearch={this.handlePutSearch}
          handleDownload={this.handleDownload}
          handleSyncron={this.handleSyncron}
          handleViewStatusDelivery={this.handleViewStatusDelivery}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  token: state.Auth.Token,
  typeToggle: state.Toggle.jenisToggle,
  ValueSearch: state.Toggle.ValueSearch,
  isLoading: state.Toggle.isLoading,
  ListDataActive: state.Voip.ListDataActive,
  PagePagination: state.Pagination.PagePagination,
  TotalPagePagination: state.Pagination.TotalPagePagination,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionDataActive: bindActionCreators(ActionDataActive, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
  actionLogin: bindActionCreators(ActionLogin, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ServerVoipContainer);
