import instance from 'config/interceptor';

export const updateReviewComplaint = async data => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + `/Courier/UpdateReviewComplaintInvestigation`,
      data
    );
  } catch (error) {
    return error;
  }
};
