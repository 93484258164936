import { createSelector } from 'reselect';

const selectorPickUpProcess = (state: any) => state.PickUpProcessState;
const selectorAssignCourierForm = state => state.form.assignCourierForm.values;

export const makePickUpProcessListSelector = () =>
  createSelector(selectorPickUpProcess, state => state.list);
export const makeDetailPickUpProcessListSelector = () =>
  createSelector(selectorPickUpProcess, state => state.detailPickupList);
export const makeAllBranchListSelector = () =>
  createSelector(selectorPickUpProcess, state => state.allBranchList);
export const makeCourierBranchListSelector = () =>
  createSelector(selectorPickUpProcess, state => state.courierBranchList);
export const makeResultUploadSelector = () =>
  createSelector(selectorPickUpProcess, state => state.resultUpload);
export const makeCustIdSelector = () =>
  createSelector(selectorPickUpProcess, state => state.custId);
export const makeCsvPickupProcessSelector = () =>
  createSelector(selectorPickUpProcess, state => state.csvPickupProcess);
export const makeFileNameCsvPickupProcessSelector = () =>
  createSelector(selectorPickUpProcess, state => state.fileName);

// SELECTOR REDUX FORM
export const makeFormBranchSelector = () =>
  createSelector(selectorAssignCourierForm, state => state.branch);
export const makeFormCourierSelector = () =>
  createSelector(selectorAssignCourierForm, state => state.courier);
