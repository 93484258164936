import * as ActionAuth from '../../action/Auth';
import * as ActionCustomerConfig from '../../action/Customer/ActionTableStatusDefault';
import * as ActionLastAccess from '../../../core/action/LastAccess';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionToggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_submitChangeCustomToDefault(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdCustomer = state.Customer.PropertiesCustomer.IdCustomer;
  const ReasonDeliveryStatus =
    state.Customer.PropertiesCustomer.ReasonDeliveryStatus;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(ActionToggle.changeLoadingTrue());

    yield call(
      axios.put,
      process.env.REACT_APP_URL +
        '/Setting/CustomStatus/deactivates?id=' +
        IdCustomer +
        '&stat=' +
        ReasonDeliveryStatus,
      {
        id: IdCustomer,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionLastAccess.changeLastAccessFiture('defaultDataStatus'));
    yield put(ActionToggle.clearJenisToggle());
    yield put(ActionToggle.changeLoadingFalse());
    yield put(ActionCustomerConfig.getCustomDataStatus());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_submitChangeCustomToDefault() {
  yield takeLatest('SUBMIT_DEFAULT_STATUS', S_submitChangeCustomToDefault);
}
