import instance from 'config/interceptor';

export const downloadWaybillPdfDeliveryType = async (
  data: {
    CodeBrankas: boolean;
    NoTelp: boolean;
    courierId: number | null;
    cycle: string;
    deliveryFile: number[];
    printType: string;
    separator: boolean;
    watermark: boolean;
  },
  onDownloadProgress: any
) => {
  try {
    const response = await instance.post(
      process.env.REACT_APP_URL + `/Delivery/DownloadWaybillPdf/Checklist`,
      data,
      {
        responseType: 'arraybuffer',
        onDownloadProgress,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const downloadWaybillPdfDelivery = async (
  data: {
    Waybill: number;
    WaybillPrintType: any;
    CodeBrankas: boolean;
    SeparatorAddress: boolean;
    NoTelp: boolean;
  },
  onDownloadProgress: any
) => {
  try {
    const response = await instance.get(
      process.env.REACT_APP_URL +
        `/Delivery/DownloadWaybillPdf/${data.Waybill}/${
          data.WaybillPrintType
        }/${true}/${data.CodeBrankas}?Separator=${
          data.SeparatorAddress
        }&NoTelp=${data.NoTelp}`,
      {
        responseType: 'arraybuffer',
        onDownloadProgress,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const downloadWaybillPdfDeliveryMultiple = async (
  data: {
    fileString: string;
    separator: boolean;
    NoTelp: boolean;
    CodeBrankas: boolean;
    watermark: boolean;
  },
  onDownloadProgress: any
) => {
  try {
    const response = await instance.post(
      process.env.REACT_APP_URL + `/Delivery/DownloadWaybillPdf/Upload`,
      data,
      {
        responseType: 'arraybuffer',
        onDownloadProgress,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getDeliveryData = async params => {
  try {
    const {
      StatusDelivery,
      page = 0,
      size = 10,
      TimeStart,
      TimeEnd,
      Customer,
      Product,
      Branch,
      Courier,
      DeliveryTime,
      PhysicalDocument,
      Search,
    } = params;
    const statusDeliverys = `${StatusDelivery.join('%2C')}`;

    let link = `/Delivery/DeliveryData/${statusDeliverys}/${page}/${size}?`;
    if (TimeStart) link = link.concat(`TimeStart=${TimeStart}`);
    if (TimeEnd) link = link.concat(`&TimeEnd=${TimeEnd}`);
    if (Customer) link = link.concat(`&Customer=${Customer}`);
    if (Product) link = link.concat(`&Product=${Product}`);
    if (Branch) link = link.concat(`&Branch=${Branch}`);
    if (Courier) link = link.concat(`&Courier=${Courier}`);
    if (DeliveryTime) link = link.concat(`&DeliveryTime=${DeliveryTime}`);
    if (Search) link = link.concat(`&Name=${Search}`);
    if (PhysicalDocument)
      link = link.concat(`&PhysicalDocument=${PhysicalDocument}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getDeliveryCountData = async params => {
  try {
    const {
      StatusDelivery,
      TimeStart,
      TimeEnd,
      Customer,
      Product,
      Branch,
      Courier,
      DeliveryTime,
      PhysicalDocument,
      Search,
    } = params;
    const statusDeliverys = `${StatusDelivery.join('%2C')}`;

    let link = `/Delivery/GetDeliveryCount/${statusDeliverys}?`;
    if (TimeStart) link = link.concat(`TimeStart=${TimeStart}`);
    if (TimeEnd) link = link.concat(`&TimeEnd=${TimeEnd}`);
    if (Customer) link = link.concat(`&Customer=${Customer}`);
    if (Product) link = link.concat(`&Product=${Product}`);
    if (Branch) link = link.concat(`&Branch=${Branch}`);
    if (Courier) link = link.concat(`&Courier=${Courier}`);
    if (DeliveryTime) link = link.concat(`&DeliveryTime=${DeliveryTime}`);
    if (Search) link = link.concat(`&Name=${Search}`);
    if (PhysicalDocument)
      link = link.concat(`&PhysicalDocument=${PhysicalDocument}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const downloadDeliveryData = async (
  data: {
    timeStart: string;
    timeEnd: string;
    customer: number;
    product: number;
    branch: number;
    courier: number;
    deliveryTime: string;
    deliveryStatus: string;
    typeReportVendor: string;
    physicalDocument: boolean;
  },
  onDownloadProgress: any
) => {
  try {
    const response = await instance.post(
      process.env.REACT_APP_URL + `/Delivery/DownloadDeliveryVendor`,
      data,

      {
        responseType: 'arraybuffer',
        onDownloadProgress,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};
