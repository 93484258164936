import * as ActionAuth from '../../action/Auth';
import * as ActionComplaint from '../../action/Complaint/ActionFormComplaint';
import * as ActionComplaintList from '../../action/Complaint/ActionComplaintList';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_updateTicket(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const complaintId = state.Complaint.complaintProperties.complaintId;
  const complaintResultInvestigation =
    state.Complaint.complaintResultInvestigation;
  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/Complaint/UpdateInvestigasi/' + complaintId,
      {
        answer: complaintResultInvestigation,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(ActionComplaint.clearComplaintForm());
    yield put(ActionComplaintList.getComplaintList());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil Disimpan</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_updateTicket() {
  yield takeLatest('UPDATE_TICKET', S_updateTicket);
}
