import { Checkbox, Col, Form, Row, Select } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../materials/ModalMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';

interface IProps {
  visible: any;
  jenisToggle: any;
  ValidateButton: any;

  GetCustomerName: any;
  PutCustomerNameReport: any;
  ValidateCustomerNameReport: any;
  helpCustomerNameReport: any;
  GetCustomerNameReport: any;

  PutFormatReport: any;
  GetFormatReport: any;
  ValueFormatReport: any;
  ValidateFormatReport: any;
  helpFormatReport: any;
  handleBlurFormatReport: any;
  handleResetFormatValidateReport: any;

  PutPeriodeReport: any;
  GetPeriodeReport: any;
  ValuePeriodeReport: any;
  handleBlurPeriodeReport: any;
  ValidatePeriodeReport: any;
  helpPeriodeReport: any;
  handleResetPeriodeValidateReport: any;

  PutDelimitedReport: any;
  GetDelimitedReport: any;
  ValueDelimitedReport: any;
  handleBlurDelimitedReport: any;
  ValidateDelimitedReport: any;
  helpDelimitedReport: any;
  handleResetDelimitedValidateReport: any;
  handleClearConfigReport: any;

  PutSettingReport: any;
  GetSettingReport: any;
  ValueSettingReport: any;
  helpSettingReport: any;
  ValidateSettingReport: any;
  handleBlurSettingReport: any;

  handlePutCheckbox: any;
  GetWithoutHeader: any;
  GetShowInPortalReport: any;
  handlePutCheckboxShow: any;

  GetCsvList: any;

  handleSubmit: any;

  isLoading: any;
  // handleSaveAdminBranch:any
}

export default class FormCustomerReportComponent extends Component<IProps> {
  render() {
    const { Option } = Select;
    const CsvList = this.props.GetCsvList;
    return (
      <div>
        <ModalMaterial
          className={''}
          width={'70%'}
          style={{}}
          teksTitle={
            this.props.jenisToggle === 'AddFormatReport'
              ? `TAMBAHKAN FORMAT LAPORAN ${this.props.GetCustomerName.toUpperCase()}`
              : this.props.jenisToggle === 'EditFormatReport'
              ? `UBAH FORMAT LAPORAN ${this.props.GetCustomerName.toUpperCase()}`
              : ''
          }
          visible={this.props.visible}
          onCancel={this.props.handleClearConfigReport}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div style={{ paddingTop: '20px', padding: '0 24px' }}>
                  <Row>
                    <Col span={12}>
                      <div style={{ marginRight: 20, marginLeft: 30 }}>
                        <div>
                          <span>
                            <b>Nama Format Laporan</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetCustomerNameReport}
                            className={''}
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Contoh : Laporan RDS Express versi 1'}
                            prefix={''}
                            maxLength={30}
                            style={{ borderRadius: '10px' }}
                            size=""
                            type={''}
                            onChange={this.props.PutCustomerNameReport}
                            onBlur={this.props.PutCustomerNameReport}
                            validatestatus={
                              this.props.ValidateCustomerNameReport
                            }
                            help={this.props.helpCustomerNameReport}
                            onPressEnter={''}
                          />
                        </div>

                        <span>
                          <b>Format File</b>
                        </span>
                        <div>
                          <Form.Item
                            validateStatus={this.props.ValidateFormatReport}
                            help={this.props.helpFormatReport}
                          >
                            <Select
                              style={{ width: '100%', borderRadius: '10px' }}
                              optionFilterProp="children"
                              showSearch
                              value={this.props.ValueFormatReport}
                              onChange={this.props.PutFormatReport}
                              onFocus={this.props.GetFormatReport}
                              onSelect={
                                this.props.handleResetFormatValidateReport
                              }
                              onBlur={this.props.handleBlurFormatReport}
                            >
                              {
                                <Option key={''} value={'Excel'}>
                                  {'EXCEL'}
                                </Option>
                              }
                              {
                                <Option key={''} value={'CSV'}>
                                  {'CSV'}
                                </Option>
                              }
                              {
                                <Option key={''} value={'TXT'}>
                                  {'TXT'}
                                </Option>
                              }
                            </Select>
                          </Form.Item>
                        </div>

                        <span>
                          <b>Pengaturan File</b>
                        </span>
                        <div>
                          <Form.Item
                            validateStatus={this.props.ValidateSettingReport}
                            help={this.props.helpSettingReport}
                          >
                            <Select
                              style={{ width: '100%', borderRadius: '10px' }}
                              optionFilterProp="children"
                              showSearch
                              value={this.props.ValueSettingReport}
                              onChange={this.props.PutSettingReport}
                              // onFocus={this.props.GetFormatReport}
                              // onSelect={this.props.handleResetFormatValidateReport}
                              onBlur={this.props.handleBlurSettingReport}
                            >
                              {CsvList.map((data: any, index: any) => (
                                <Option key={index} value={data.id}>
                                  {data.nameCustomCsv}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div style={{ marginRight: 20, marginLeft: 30 }}>
                        <span>
                          <b>Periode</b>
                        </span>
                        <div>
                          <Form.Item
                            validateStatus={this.props.ValidatePeriodeReport}
                            help={this.props.helpPeriodeReport}
                          >
                            <Select
                              style={{ width: '100%', borderRadius: '10px' }}
                              optionFilterProp="children"
                              showSearch
                              value={this.props.ValuePeriodeReport}
                              onChange={this.props.PutPeriodeReport}
                              onFocus={this.props.GetPeriodeReport}
                              onSelect={
                                this.props.handleResetPeriodeValidateReport
                              }
                              onBlur={this.props.handleBlurPeriodeReport}
                            >
                              <Option key={''} value={'Daily'}>
                                {'Harian'}
                              </Option>
                              <Option key={''} value={'Weekly'}>
                                {'Mingguan'}
                              </Option>
                              <Option key={''} value={'Monthly'}>
                                {'Bulanan'}
                              </Option>
                            </Select>
                          </Form.Item>
                        </div>

                        <span>
                          <b>Delimited</b>
                        </span>
                        <div>
                          <Form.Item
                            validateStatus={this.props.ValidateDelimitedReport}
                            help={this.props.helpDelimitedReport}
                          >
                            <Select
                              style={{ width: '100%', borderRadius: '10px' }}
                              optionFilterProp="children"
                              showSearch
                              disabled={
                                this.props.ValueDelimitedReport === 'Empty'
                                  ? true
                                  : false
                              }
                              value={this.props.ValueDelimitedReport}
                              onChange={this.props.PutDelimitedReport}
                              onFocus={this.props.GetDelimitedReport}
                              onSelect={
                                this.props.handleResetDelimitedValidateReport
                              }
                              onBlur={this.props.handleBlurDelimitedReport}
                            >
                              <Option key={''} value={'SemiColon'}>
                                {'; (Titik Koma)'}
                              </Option>
                              <Option key={''} value={'Comma'}>
                                {', (Koma)'}
                              </Option>
                              <Option key={''} value={'SeparateList'}>
                                {'| (Separator)'}
                              </Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div style={{ paddingTop: '30px' }}>
                          <Checkbox
                            checked={this.props.GetWithoutHeader}
                            onChange={this.props.handlePutCheckbox}
                          />
                          <span style={{ paddingLeft: '10px' }}>
                            Tanpa Header
                          </span>
                          <Checkbox
                            checked={this.props.GetShowInPortalReport}
                            onChange={this.props.handlePutCheckboxShow}
                            style={{ paddingLeft: '20px' }}
                          />
                          <span style={{ paddingLeft: '10px' }}>
                            Tampilkan di Portal Customer
                          </span>
                        </div>
                      </div>
                    </Col>
                    <div
                      style={{
                        textAlign: 'right',
                        marginRight: 20,
                        paddingTop: '250px',
                      }}
                    >
                      <ButtonMaterial
                        disabled={this.props.ValidateButton}
                        handleSubmit={this.props.handleSubmit}
                        teksButton={'Simpan'}
                        size={''}
                        shape={'default'}
                        className={''}
                        type={'primary'}
                        style={
                          this.props.ValidateButton === true
                            ? {
                                height: '35px',
                                borderRadius: '12px',
                                float: 'right',
                              }
                            : {
                                float: 'right',
                                colorFont: '#11BEFF',
                                height: '35px',
                                borderRadius: '12px',
                                background: '#11BEFF',
                                borderColor: '#11BEFF',
                              }
                        }
                        icon={''}
                      />
                    </div>
                  </Row>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
