import * as ActionAuth from '../../action/Auth';
import * as ActionFormResendDelivery from '../../action/Attempt/ActionFormResend';
import * as ActionListResendDelivery from '../../action/Attempt/ActionListResendSubmit';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_addResendDelivery(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const ResendBranchId = state.Resend.FormResendDelivery.ResendBranch;
  const ResendCourierId = state.Resend.FormResendDelivery.ResendCourier;
  const ResendWaybill = state.Resend.FormResendDelivery.ResendWaybill;
  const isChangeCourier = state.Resend.FormResendDelivery.isChangeCourier;
  const isScan = state.Resend.FormResendDelivery.isScan;
  const fileExcel = state.Resend.FormResendDelivery.ResendExcelBase64;
  const ResendDeliveryDate = moment(
    state.Resend.FormResendDelivery.ResendDeliveryDate
  )
    .locale('id')
    .format('YYYY-MM-DDTHH:mm:ss.SSSz');
  const getListResend = state.Resend.ListSubmitResendDelivery;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  try {
    yield put(toggle.changeLoadingTrue());
    let link = '/Delivery/Resend/Upload';
    if (isScan) {
      link = '/Delivery/Resend';
    }
    const { data } = yield call(
      axios.put,
      process.env.REACT_APP_URL + link,
      {
        branchId: ResendBranchId || 0,
        courierId: ResendCourierId || 0,
        waybill: ResendWaybill,
        deliveryDate: ResendDeliveryDate,
        isChangeCourier,
        isScan,
        fileExcel,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeLoadingFalse());
    if (isScan) {
      for (var a = 1; a <= getListResend.length; a++) {}
      yield put(
        ActionListResendDelivery.setListResendSubmit([
          ...getListResend,
          {
            data,
            index: a,
            Status: 'wasfound',
            AddressArray: JSON.parse(data.shipToAddress),
          },
        ])
      );
    }
    if (!isScan) {
      yield put(
        ActionFormResendDelivery.setResendIsSuccess({
          success: data?.success || 0,
          error: data?.failed || 0,
        })
      );
    }
    yield put(ActionFormResendDelivery.setResendListResult(data.listResultCsv));
    yield put(ActionFormResendDelivery.clearResendWaybill());
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.errorHandling(action, error));
    } else {
      if (isScan) {
        for (var i = 1; i <= getListResend.length; i++) {}
        yield put(
          ActionListResendDelivery.setListResendSubmit([
            ...getListResend,
            {
              index: i,
              data: { waybillCode: ResendWaybill },
              Status: 'notfound',
            },
          ])
        );
      }
      if (!isScan) {
        yield put(
          ActionFormResendDelivery.setResendIsSuccess({ success: 1, error: 1 })
        );
      }
      yield put(toggle.changeLoadingFalse());
      yield put(ActionFormResendDelivery.clearResendWaybill());
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_addResendDelivery() {
  yield takeLatest('SUBMIT_ADD_RESEND', S_addResendDelivery);
}
