import * as ActionAuth from '../../../core/action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as Address from '../../action/Address/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_GetProvince(action: any) {
  try {
    yield put(Address.setLoadingAddress());
    yield put(Address.setLoadingTypeAddress('selectProvince'));
    const state = yield select();
    const token = state.Auth.Token;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Address/province/0/100`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(Address.SetProvince(data.data));
    yield put(Address.setLoadingAddress());
    yield put(Address.clearLoadingTypeAddress());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_GetProvince() {
  yield takeLatest('GET_PROVINCE', S_GetProvince);
}
