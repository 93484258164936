export function setLoadingCourier()
{
    return({
        type:"SET_LOADING_COURIER"
    })
}
export function setTypeLoadingCourier(TypeLoadingCourier:any)
{
    return({
        type:"SET_TYPE_LOADING_COURIER",
        TypeLoadingCourier
    })
}
export function clearTypeLoadingCourier()
{
    return({
        type:"CLEAR_TYPE_LOADING_COURIER"
    })
}
export function clearLoadingCourier()
{
    return({
        type:"CLEAR_LOADING_COURIER"
    })
}