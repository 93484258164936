import { Col, Row, Steps, Tag } from 'antd';
import {
  EnumContentPickupTracking,
  EnumHeaderTrackingPickup,
  EnumLabelSearchType,
  prefixValueSearch,
} from 'apps/enums';
import { Field, reduxForm } from 'redux-form';
import { InputSelectPrefix, InputText } from 'apps/assets/components/CInput';
import {
  PickupFinishIcon,
  PickupProccessIcon,
  PickupStatusInQueueIcon,
} from 'apps/assets/image/svgIcon';

import CButton from 'apps/assets/components/CButton';
import CCard from 'apps/assets/components/CCard';
import CSpin from 'apps/assets/components/CSpin';
import React from 'react';
import bgNoDataHistoryDelivery from '../../../../image/bgNoDataHistoryDelivery.png';
import moment from 'moment';

function PickupStatusComponent(props) {
  const { formValue, handleGetPickupStatus, isLoading, pickupStatusDoc } =
    props;
  const { Step } = Steps;
  const inputSearchValue = formValue ? formValue.valueSearchPickup : '';
  const validateSearchBtn = !inputSearchValue ? true : false;

  const btnSearch = [
    {
      type: 'primary',
      className: 'btnSearchDocumentStatus',
      onClick: handleGetPickupStatus,
      isLoading: isLoading,
      disabled: validateSearchBtn,
      icon: 'search',
      content: 'Cari',
      id: 'btnSearch',
    },
  ];
  const showValue = pickupStatusDoc === null ? '-' : pickupStatusDoc;

  const onQueueDate = showValue.itemProcessTimePickup
    ? moment(showValue.itemProcessTimePickup).format('DD/MM/YYYY')
    : '';
  const pickupProcessDate = showValue.itemScanTimePickup
    ? moment(showValue.itemScanTimePickup).format('DD/MM/YYYY')
    : '';
  const pickupFinishDate = showValue.itemFinishedTimePickup
    ? moment(showValue.itemFinishedTimePickup).format('DD/MM/YYYY')
    : '';
  const pickupStatus = status => {
    //status diubah seperti ini agar pengkondisian step lebih mudah
    switch (status) {
      case 'Dalam Antrian': {
        return 1;
      }
      case 'Proses': {
        return 2;
      }
      case 'Selesai': {
        return 3;
      }
    }
    return 0;
  };
  const enableColor = {
    iconColor: '#E5E5E5',
    bgColor: '#11BEFF',
    fontColor: 'lightBlueColor',
  };
  const disableColor = {
    iconColor: '#11BEFF',
    bgColor: '#E5E5E5',
    fontColor: 'greyColor',
  };
  let inQueue =
    pickupStatus(showValue.pickupStatus) >= 1 ? enableColor : disableColor;
  let process =
    pickupStatus(showValue.pickupStatus) >= 2 ? enableColor : disableColor;
  let finish =
    pickupStatus(showValue.pickupStatus) >= 3 ? enableColor : disableColor;
  const prefixSelector = (
    <Field
      name="filterValue"
      component={InputSelectPrefix}
      idComponent="inputFilterValue"
      data={prefixValueSearch}
    />
  );
  const searchValue = formValue ? formValue.filterValue : '';
  const valuePlaceholder = () => {
    switch (searchValue) {
      case 'waybill':
        return 'Waybill';
      case 'barcode':
        return 'Barcode';
      case 'accountNumber':
        return 'No Account';
      case 'referenceNumber':
        return 'No Referensi';
      case 'policeNumber':
        return 'No Polis';
      case 'rekeningNumber':
        return 'No Rekening';
      case 'name':
        return 'Nama';
    }
    return null;
  };

  const history = showValue.historyPickup ? showValue.historyPickup : '';
  const count = history.length - 1;
  const dataInformationDocument = (label: any, content: any) => {
    const showContent = () => {
      if (label === 'Status') {
        return (
          <p className="fontWeight">
            {`:  `}
            {content ? (
              <span>
                <Tag
                  color={
                    content === 'Selesai'
                      ? 'green'
                      : content === 'Proses'
                      ? 'gold'
                      : 'gray'
                  }
                >
                  {content}
                </Tag>
              </span>
            ) : (
              ''
            )}
          </p>
        );
      } else {
        return (
          <p className="fontWeight textPickupStatusDocColor">: {content}</p>
        );
      }
    };
    return (
      <>
        <Col span={9}>
          <p>{label}</p>
        </Col>
        <Col span={15} className="spacingLeft">
          {showContent()}
        </Col>
      </>
    );
  };
  const pickupHistory = () => {
    return (
      <>
        {history.length === 0 ? (
          <div className="textAlignCenter">
            <img
              className="historyImg"
              alt="Data History Delivery"
              src={bgNoDataHistoryDelivery}
            />
          </div>
        ) : (
          history.map((i: any, index: any) => (
            <Row className={index === count ? '' : 'line'}>
              <Col span={5}>
                <div
                  style={{
                    borderRadius: '4px',
                    background: '#7956EE',
                    textAlign: 'center',
                  }}
                >
                  <span className="whiteColor">
                    <div>
                      {moment(i.date === null ? i.timeStamp : i.date).format(
                        'DD-MMM'
                      )}
                    </div>
                    <div>
                      {moment(i.date === null ? i.timeStamp : i.date).format(
                        'HH:mm'
                      )}
                    </div>
                  </span>
                </div>
              </Col>
              <Col span={19}>
                <div style={{ paddingBottom: '15px', marginLeft: '15px' }}>
                  <div className="teksInactive fontWeight">
                    {EnumHeaderTrackingPickup(i)}
                  </div>
                  <Row>
                    <Col span={24}>
                      <div className="blackColor">
                        {EnumContentPickupTracking(i)}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          ))
        )}
      </>
    );
  };
  return (
    <div className="defaultSpace">
      <CSpin
        spinning={isLoading}
        size={'large'}
        content={
          <React.Fragment>
            <Row>
              <CCard
                cardClassName={'defaultCard'}
                cardTitle={
                  <span className="lightBlueColor">STATUS PENGAMBILAN</span>
                }
                cardContent={
                  <Row>
                    <Col span={12}>
                      <Field
                        name="valueSearchPickup"
                        className={'searchValueComplaint'}
                        component={InputText}
                        addonBefore={prefixSelector}
                        idComponent="inputValueSearchPickup"
                        placeholder={`Masukan ${valuePlaceholder()}`}
                        maxLength={50}
                        additionalHandleEnterFunc={handleGetPickupStatus}
                      />
                    </Col>
                    <Col span={12}>
                      <CButton buttonData={btnSearch} />
                    </Col>
                  </Row>
                }
              />
            </Row>
            <Row className="marginTopDefault">
              <CCard
                cardClassName={'defaultCard'}
                cardContent={
                  <div className="spacingTop">
                    <Steps
                      className="spacingIconStep"
                      current={pickupStatus(showValue.pickupStatus) - 1}
                    >
                      <Step
                        icon={PickupStatusInQueueIcon(
                          inQueue.iconColor,
                          inQueue.bgColor
                        )}
                      />
                      <Step
                        icon={PickupProccessIcon(
                          process.iconColor,
                          process.bgColor
                        )}
                      />
                      <Step
                        icon={PickupFinishIcon(
                          finish.iconColor,
                          finish.bgColor
                        )}
                      />
                    </Steps>
                    <Row>
                      <Col span={8} className="spacingLeft">
                        <span className={`fontWeight ${inQueue.fontColor}`}>
                          Dalam Antrian
                        </span>
                        <div
                          className={`fontWeight ${inQueue.fontColor} spacingDateTextQueuePickup`}
                        >
                          {onQueueDate === 'Invalid date' ? '' : onQueueDate}
                        </div>
                      </Col>
                      <Col span={8} className="spacingStepPickup">
                        <span className={`fontWeight ${process.fontColor}`}>
                          Proses Pengambilan
                        </span>
                        <div
                          className={`fontWeight ${inQueue.fontColor} spacingDateTextPickup`}
                        >
                          {pickupProcessDate === 'Invalid date'
                            ? ''
                            : pickupProcessDate}
                        </div>
                      </Col>
                      <Col span={8} className="spacingStepFinishPickup">
                        <span className={`fontWeight ${finish.fontColor}`}>
                          Pengambilan Selesai
                        </span>
                        <div
                          className={`fontWeight ${inQueue.fontColor} spacingDateTextPickup`}
                        >
                          {pickupFinishDate === 'Invalid date'
                            ? ''
                            : pickupFinishDate}
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
              />
            </Row>
            <Row className="marginTopDefault">
              <Col span={14}>
                <CCard
                  cardClassName={'defaultCard'}
                  cardTitle={
                    <span className="lightBlueColor">INFORMASI DOKUMEN</span>
                  }
                  cardContent={
                    <>
                      {dataInformationDocument(
                        'Waybill',
                        showValue.waybillCode
                      )}
                      {dataInformationDocument('Cycle', showValue.cycle)}
                      {dataInformationDocument(
                        'Nama Pemilik',
                        showValue.shipToName
                      )}
                      {dataInformationDocument(
                        'No Telp',
                        showValue.shipToPhone
                      )}
                      {dataInformationDocument(
                        'Alamat',
                        !showValue.shipToAddress
                          ? showValue.shipToAddress
                          : Object.values(
                              JSON.parse(showValue.shipToAddress)
                            ).join(' ')
                      )}
                      {dataInformationDocument('Barcode', showValue.barcode)}
                      {showValue.filterType === 'Empty' ||
                      !showValue.filterType ? (
                        ''
                      ) : (
                        <>
                          {dataInformationDocument(
                            EnumLabelSearchType(showValue.filterType),
                            showValue.valueSearch === null
                              ? '-'
                              : showValue.valueSearch
                          )}
                        </>
                      )}
                      {dataInformationDocument(
                        'Status',
                        showValue.pickupStatus
                      )}
                      {dataInformationDocument('Kurir', showValue.courier)}
                      {dataInformationDocument(
                        'Alamat Pengambilan',
                        showValue.addressPickup
                      )}
                      {dataInformationDocument(
                        'Lama Pengambilan',
                        !showValue.estimatedPickup
                          ? showValue.estimatedPickup
                          : `${showValue.estimatedPickup} Hari`
                      )}
                      {dataInformationDocument(
                        'Lihat Lokasi',
                        !showValue.locationPickup ? (
                          showValue.locationPickup
                        ) : (
                          <a
                            href={`http://maps.google.com/maps?z=18&q=${showValue.locationPickup}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <span>{showValue.locationPickup}</span>
                          </a>
                        )
                      )}
                    </>
                  }
                />
              </Col>
              <Col span={10} className="spacingLeft">
                <CCard
                  cardClassName={'defaultCard historySpacing'}
                  cardTitle={
                    <span className="lightBlueColor">RIWAYAT PENGAMBILAN</span>
                  }
                  cardContent={<>{pickupHistory()}</>}
                />
              </Col>
            </Row>
          </React.Fragment>
        }
      />
    </div>
  );
}

const PickupStatusComponentForm = reduxForm({
  form: 'pickupStatusForm',
  shouldError: () => {
    return true;
  },
  // validate,
})(PickupStatusComponent);
export default PickupStatusComponentForm;
