import React from 'react';
import { Tabs } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import FailedLog from './FailedLog';
import { getTabMenus } from 'lib/util';
import EmailNotification from './EmailNotification';

const { TabPane } = Tabs;
type SmsBlastProps = {
  roleAccount: string;
  sideMenus: any;
};

function SmsBlast({ roleAccount, sideMenus }: SmsBlastProps) {
  const tabMenus = getTabMenus(sideMenus || [], 'SMS Blast') || [];

  const componentTabMenus = {
    'Log Gagal': <FailedLog />,
    'Email Notifikasi': <EmailNotification />,
  };

  return (
    <Tabs style={{ padding: '35px' }} defaultActiveKey={'1'}>
      {tabMenus.map((item, index) => (
        <TabPane tab={<b>{item.label}</b>} key={index + 1}>
          {componentTabMenus[item.label]}
        </TabPane>
      ))}
    </Tabs>
  );
}

const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  sideMenus: state.Auth.SideMenus,
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(SmsBlast);
