import { Action } from 'redux';

const initialState = {
  ActivationVoip:{
    toggleActivation: false,
    customerId: null,
    productType: null,
  },
  ServerVoip: {
    Id: null,
    Name: null,
    IpServer1: null,
    IpServer2: null,
    IpServer3: null,
    IpServer4: null,
    IpPort: null,
    Kuota: null,
    Position: null,
    ToggleServer: false,
  },
  ListServerVoip: [],
  ListDataActive: [],
};

export interface IAuthAction extends Action<string> {}

export interface IChangeToggleActivation extends IAuthAction {
  toggleActivation: any;
}
export interface IChangeCustomerId extends IAuthAction {
  customerId: any;
}
export interface IChangeProductType extends IAuthAction {
  productType: any;
}
export interface ISetServerVoipId extends IAuthAction {
  Id: any;
}
export interface ISetServerVoipName extends IAuthAction {
  Name: any;
}
export interface ISetServerVoipIpServer1 extends IAuthAction {
  IpServer1: any;
}
export interface ISetServerVoipIpServer2 extends IAuthAction {
  IpServer2: any;
}
export interface ISetServerVoipIpServer3 extends IAuthAction {
  IpServer3: any;
}
export interface ISetServerVoipIpServer4 extends IAuthAction {
  IpServer4: any;
}
export interface ISetServerVoipIpPort extends IAuthAction {
  IpPort: any;
}
export interface ISetServerVoipKuota extends IAuthAction {
  Kuota: any;
}
export interface ISetServerVoipPosition extends IAuthAction {
  Position: any;
}
export interface ISetServerVoipToggle extends IAuthAction {
  ToggleServer: any;
}
export interface ISetServerVoipListServerVoip extends IAuthAction {
  ListServerVoip: any;
}
export interface ISetDataActiveListDataActive extends IAuthAction {
  ListDataActive: any;
}

export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_TOGGLE_ACTIVATION':
      const changeToggleActivation = action as IChangeToggleActivation;
      newState.ActivationVoip.toggleActivation = changeToggleActivation.toggleActivation;
      return { ...newState };
    
    case 'CHANGE_PRODUCT_TYPE_V':
      const changeProductType = action as IChangeProductType;
      newState.ActivationVoip.productType = changeProductType.productType;
      return { ...newState };
    
    case 'CHANGE_CUSTOMER_ID_V':
      const changeCustomerId = action as IChangeCustomerId;
      newState.ActivationVoip.customerId = changeCustomerId.customerId;
      return { ...newState };

    case 'SET_SERVER_VOIP_ID':
      const setServerVoipId = action as ISetServerVoipId;
      newState.ServerVoip.Id = setServerVoipId.Id;
      return { ...newState };

    case 'SET_SERVER_VOIP_NAME':
      const setServerVoipName = action as ISetServerVoipName;
      newState.ServerVoip.Name = setServerVoipName.Name;
      return { ...newState };

    case 'SET_SERVER_VOIP_IP_SERVER_1':
      const setServerVoipIpServer1 = action as ISetServerVoipIpServer1;
      newState.ServerVoip.IpServer1 = setServerVoipIpServer1.IpServer1;
      return { ...newState };

    case 'SET_SERVER_VOIP_IP_SERVER_2':
      const setServerVoipIpServer2 = action as ISetServerVoipIpServer2;
      newState.ServerVoip.IpServer2 = setServerVoipIpServer2.IpServer2;
      return { ...newState };

    case 'SET_SERVER_VOIP_IP_SERVER_3':
      const setServerVoipIpServer3 = action as ISetServerVoipIpServer3;
      newState.ServerVoip.IpServer3 = setServerVoipIpServer3.IpServer3;
      return { ...newState };

    case 'SET_SERVER_VOIP_IP_SERVER_4':
      const setServerVoipIpServer4 = action as ISetServerVoipIpServer4;
      newState.ServerVoip.IpServer4 = setServerVoipIpServer4.IpServer4;
      return { ...newState };

    case 'SET_SERVER_VOIP_IP_PORT':
      const setServerVoipIpPort = action as ISetServerVoipIpPort;
      newState.ServerVoip.IpPort = setServerVoipIpPort.IpPort;
      return { ...newState };

    case 'SET_SERVER_VOIP_KUOTA':
      const setServerVoipKuota = action as ISetServerVoipKuota;
      newState.ServerVoip.Kuota = setServerVoipKuota.Kuota;
      return { ...newState };

    case 'SET_SERVER_VOIP_POSITION':
      const setServerVoipPosition = action as ISetServerVoipPosition;
      newState.ServerVoip.Position = setServerVoipPosition.Position;
      return { ...newState };

    case 'SET_SERVER_VOIP_TOGGLE':
      const setServerVoipToggle = action as ISetServerVoipToggle;
      newState.ServerVoip.ToggleServer = setServerVoipToggle.ToggleServer;
      return { ...newState };

    case 'SET_LIST_SERVER_VOIP':
      const setListServerVoip = action as ISetServerVoipListServerVoip;
      newState.ListServerVoip = setListServerVoip.ListServerVoip;
      return { ...newState };

    case 'CLEAR_LIST_SERVER_VOIP':
      newState.ListServerVoip = [];
      return { ...newState };

    case 'CLEAR_ALL_FORM_SERVER_VOIP':
      newState.ServerVoip.Name = null;
      newState.ServerVoip.IpServer1 = null;
      newState.ServerVoip.IpServer2 = null;
      newState.ServerVoip.IpServer3 = null;
      newState.ServerVoip.IpServer4 = null;
      newState.ServerVoip.IpPort = null;
      newState.ServerVoip.Kuota = null;
      newState.ServerVoip.Position = null;
      newState.ServerVoip.ToggleServer = false;
      return { ...newState };
    
    case 'CLEAR_ACTIVATION_VOIP':
      newState.ActivationVoip.customerId = null;
      newState.ActivationVoip.productType = null;
      newState.ActivationVoip.toggleActivation = false;
      return { ...newState };
    
    case 'CLEAR_PRODUCT_ACTIVATION_VOIP':
      newState.ActivationVoip.productType = null;
      return { ...newState };

    case 'SET_LIST_DATA_ACTIVE':
      const setListDataActive = action as ISetDataActiveListDataActive;
      newState.ListDataActive = setListDataActive.ListDataActive;
      return { ...newState };

    case 'CLEAR_LIST_DATA_ACTIVE':
      newState.ListDataActive = [];
      return { ...newState };
  }
  return { ...newState };
}
