export function changeClearIdCustomer()
{
    return({
        type:"CHANGE_CLEAR_ID_CUSTOMER"
    })
}
export function changeDetailCustomer()
{
    return({
        type:"CHANGE_DETAIL_CUSTOMER"
    })
}
export function clearEditCustomer()
{
    return({
        type:"CLEAR_EDIT_CUSTOMER"

    })
}
export function changeCustomerId(idcustomer:any)
{
    return({
        type:"CHANGE_CUSTOMER_ID_DC",
        idcustomer

    })
}

export function changeEditCustomer(editcustomer:any)
{
    return({
        type:"CHANGE_EDIT_CUSTOMER",
        editcustomer

    })
}