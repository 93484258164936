import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import history from 'core/utils/History';
import { getSingleRole, putRole } from 'services/RoleServices';
import { getMenusLevelPermission } from 'services/LevelAccessServices';
import EditRoleFormComponent from '../Component/EditRoleFormComponent';
import ModalStatus from 'materials/ModalStatus';
import { reduceRoleFormat } from 'lib/util';

export default function EditRoleFormContainer() {
  const { id }: { id: string } = useParams();
  const defaultDataLevel = {
    accountType: '',
    levelAkses: '',
    roleName: '',
    isActive: true,
    menus: [],
  };

  const [dataLevel, setDataLevel] = useState<any>(defaultDataLevel);
  const [dataMenus, setDataAllMenus] = useState<Array<any>>([]);
  const [selectedLevel, setSelectedLevel] = useState<any>(null);

  const { data: dataSingleRole, isLoading: isLoadingRole } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataSingleRole'],
    queryFn: () => getSingleRole(id),
  });

  const { mutate: submitLevelGroup, isLoading: isLoadingSubmit } = useMutation(
    putRole,
    {
      onSuccess: res => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Data berhasil disimpan',
          });
          setDataLevel(defaultDataLevel);
          history.push('/role');
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    }
  );

  const { data: dataAllMenus, isFetching: isLoadingMenus } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataAllMenus', { id: selectedLevel }],
    queryFn: ({ queryKey }) => getMenusLevelPermission(queryKey),
    enabled: !!selectedLevel,
  });

  useEffect(() => {
    if (dataSingleRole?.data) {
      const dataRole = dataSingleRole?.data;
      setDataLevel({
        levelAkses: dataRole.levelId,
        roleName: dataRole.roleName,
        accountType: dataRole.accountType,
        isActive: dataRole.isActive,
        menus: dataRole?.selected || [],
      });
      const dataAllMenus = dataRole?.menus || [];
      if (dataAllMenus.length > 0) {
        setDataAllMenus(dataAllMenus);
      }
    }
  }, [dataSingleRole]);

  useEffect(() => {
    if (dataAllMenus?.data && dataAllMenus.data.length > 0) {
      setDataAllMenus(dataAllMenus.data);
    }
  }, [dataAllMenus]);

  const handleBtnBack = () => {
    history.goBack();
  };

  const handleSubmit = value => {
    const { levelAkses, roleName, isActive, accountType } = value;

    if (dataMenus.filter(value => value.permission.view === true).length <= 0) {
      ModalStatus({
        status: 'error',
        title: 'Terjadi Kesalahan!!!',
        content: 'Wajib pilih salah satu hak akses',
      });
      return;
    }

    const menus = reduceRoleFormat(dataMenus);

    const data = {
      idRole: id,
      levelId: levelAkses,
      roleName,
      accountType,
      isActive,
      menus,
    };

    submitLevelGroup(data);
  };

  const handleChangeForm = (name: string, value: any) => {
    setDataLevel(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <EditRoleFormComponent
      handleBtnBack={handleBtnBack}
      handleSubmit={handleSubmit}
      isLoadingSubmit={isLoadingSubmit}
      isLoadingMenus={isLoadingRole || isLoadingMenus}
      dataAllMenus={dataMenus || []}
      dataLevel={dataLevel}
      handleSetDataMenu={value => setDataAllMenus(value)}
      handleSelectedlevel={value => setSelectedLevel(value)}
      handleChangeForm={handleChangeForm}
    />
  );
}
