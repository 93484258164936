import * as ActionCourierTable from '../../../core/action/ManageAccount/Courier/ActionTableCourier';
import * as ActionEmergency from '../../../core/action/ManualProcess/ActionEmergencyProcess';
import * as ActionLastAccess from '../../../core/action/LastAccess/index';
import * as ActionListCustomer from '../../../core/action/Customer/ActionListCustomer';
import * as ActionManualProcess from '../../../core/action/ManualProcess/ActionManualProcess';
import * as ActionManualProcessVendor from '../../../core/action/ManualProcess/ActionManualProcessVendor';
import * as ActionUpdateAddress from '../../../core/action/ManualProcess/ActionUpdateAddress';
import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import IndexManualProcessComponent from 'components/Content/ManualProcess/IndexManualProcessComponent';

interface IProps {
  actionListCustomer: any;
  actionEmergency: any;
  actionLastAccess: any;
  actionCourierTable: any;
  actionManualProcessVendor: any;
  actionManualProcess: any;
  actionUpdateAddress: any;
  Fiture: any;
  sideMenus: any;
}

const IndexManualProcessContainer = (props: IProps) => {
  const handleChangeTabs = (event: any) => {
    props.actionListCustomer.clearListCustomer();
    props.actionEmergency.ClearAllEmergancyForm();
    props.actionCourierTable.clearCourierTable();
    if (event === '1') {
      props.actionLastAccess.changeLastAccessFiture('deliveryManual');
      props.actionEmergency.clearCourierAndBranchEmergencyForm();
      props.actionManualProcessVendor.clearCourierAndBranchVendorForm();
      props.actionManualProcessVendor.clearManualProcessVendorForm();
      props.actionUpdateAddress.clearAllForm();
    } else if (event === '2') {
      props.actionLastAccess.changeLastAccessFiture('deliveryVendor');
      props.actionEmergency.clearCourierAndBranchEmergencyForm();
      props.actionManualProcess.clearCourierAndBranchForm();
      props.actionUpdateAddress.clearAllForm();
    } else if (event === '3') {
      props.actionLastAccess.changeLastAccessFiture('deliveryEmergency');
      props.actionManualProcessVendor.clearCourierAndBranchVendorForm();
      props.actionManualProcess.clearCourierAndBranchForm();
      props.actionManualProcessVendor.clearManualProcessVendorForm();
      props.actionUpdateAddress.clearAllForm();
    } else if (event === '4') {
      props.actionLastAccess.changeLastAccessFiture('deliveryUpdateAddress');
      props.actionEmergency.clearCourierAndBranchEmergencyForm();
      props.actionManualProcessVendor.clearCourierAndBranchVendorForm();
      props.actionManualProcessVendor.clearManualProcessVendorForm();
    } else if (event === '5') {
      props.actionLastAccess.changeLastAccessFiture('deliverySyncData');
      props.actionEmergency.clearCourierAndBranchEmergencyForm();
      props.actionManualProcessVendor.clearCourierAndBranchVendorForm();
      props.actionManualProcessVendor.clearManualProcessVendorForm();
    }
  };

  return (
    <IndexManualProcessComponent
      lastAccessFiture={props.Fiture}
      handleChangeTabs={handleChangeTabs}
      sideMenus={props.sideMenus}
    />
  );
};

const mapStateToProps = (state: any) => ({
  Fiture: state.LastAccess.Fiture,
  sideMenus: state.Auth.SideMenus,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
  actionEmergency: bindActionCreators(ActionEmergency, dispatch),
  actionLastAccess: bindActionCreators(ActionLastAccess, dispatch),
  actionCourierTable: bindActionCreators(ActionCourierTable, dispatch),
  actionManualProcessVendor: bindActionCreators(
    ActionManualProcessVendor,
    dispatch
  ),
  actionManualProcess: bindActionCreators(ActionManualProcess, dispatch),
  actionUpdateAddress: bindActionCreators(ActionUpdateAddress, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IndexManualProcessContainer);
