import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionPagination from '../../../action/Pagination/ActionPagination';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../../action/toggle';

import axios from 'axios';
import { setAdminTable } from '../../../action/ManageAccount/AdminBranch/ActionTableAdminBranch';

export default function* S_GetDataTableAdmin(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const role = state.Auth.Role;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const pagePagination = state.Pagination.PagePagination - 1;
  const sizeDataPagination = state.Pagination.SizeDataPagination;
  try {
    yield put(changeLoadingTrue());
    if (role === 'Admin') {
      const { data } = yield call(
        axios.get,
        process.env.REACT_APP_URL +
          `/Profile/Branch/${pagePagination}/${sizeDataPagination}`,
        { headers: config }
      );
      if (statusTokenRefresh === true) {
        yield put(ActionAuth.changeStatusTokenRefresh(false));
      }
      yield put(setAdminTable(data.data));
      yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
      yield put(changeLoadingFalse());
    }
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_searchTableAdminBranch(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const searchValue = state.Toggle.ValueSearch;
  const pagePagination = state.Pagination.PagePagination;
  const sizeDataPagination = state.Pagination.SizeDataPagination;
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Profile/SearchDataAdminBranch/${pagePagination}/${sizeDataPagination}?NameOrCode=${searchValue}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(setAdminTable(data.data));
    yield put(ActionPagination.setTotalPagePagination(data.totalPages * 10));
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(changeLoadingFalse());
  }
}

export function* SM_TableAdmin() {
  yield takeLatest('GET_TABLE_ADMIN', S_GetDataTableAdmin);
}

export function* SM_SearchTableAdminBranch() {
  yield takeLatest('SUBMIT_SEARCH_ADMIN_BRANCH', S_searchTableAdminBranch);
}
