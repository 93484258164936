import * as ActionAuth from '../../action/Auth';
import * as ActionCustomDataReportCustomer from '../../../core/action/Customer/ActionCustomDataReportCustomer';
import * as ActionLogin from '../../../module/Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import axios from 'axios';

export function* S_DetailCustomReportDataCustomer(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const IdCustomReportCustomer =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .IdCustomReportCustomer;
  try {
    yield put(changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Setting/CustomReportData/${IdCustomReportCustomer}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(ActionCustomDataReportCustomer.setHeaderCdrc(data.name));
    // yield put(
    //   ActionCustomDataReportCustomer.setValueValueCdrc(data.defaultValue)
    // );
    yield put(
      ActionCustomDataReportCustomer.setValueDefaultCdrc(
        data.stringDefaultValue
      )
    );
    yield put(
      ActionCustomDataReportCustomer.setSourceTableCdrc(
        data.tableSource === 'Empty' ? null : data.tableSource
      )
    );
    yield put(
      ActionCustomDataReportCustomer.setSourceColumnCdrc(data.columnName)
    );
    yield put(
      ActionCustomDataReportCustomer.setSourceColumnSecCdrc(data.secondColumn)
    );
    yield put(
      ActionCustomDataReportCustomer.setSubColumnCdrc(data.subColumnName)
    );
    yield put(
      ActionCustomDataReportCustomer.changeCharLengthCdrc(
        data.charMax === 0 ? '' : data.charMax
      )
    );
    yield put(
      ActionCustomDataReportCustomer.setSubColumnSecCdrc(data.secondSubColumn)
    );
    yield put(
      ActionCustomDataReportCustomer.setFormatTypeCdrc(
        data.formatValueType === 'Empty' ? null : data.formatValueType
      )
    );
    yield put(
      ActionCustomDataReportCustomer.setFormatColumnCdrc(data.formatValue)
    );
    yield put(ActionCustomDataReportCustomer.setPositionCdrc(data.position));
    yield put(
      ActionCustomDataReportCustomer.setHistoryTypeCdrc(data.itemState)
    );
    yield put(
      ActionCustomDataReportCustomer.setDeliveryToCdrc(data.deliveryPosition)
    );
    yield put(ActionCustomDataReportCustomer.setFormatCdrc(data.formatData));
    yield put(
      ActionCustomDataReportCustomer.setCheckBoxPetikCdrc(data.character)
    );
    yield put(
      ActionCustomDataReportCustomer.setCheckBoxOthersCdrc(data.freeTextStatus)
    );

    if (data.formatValue !== null) {
      const value = data.formatValue.split(',');
      yield put(ActionCustomDataReportCustomer.setReplaceSuccessCdrc(value[0]));
      yield put(ActionCustomDataReportCustomer.setReplaceReturnCdrc(value[1]));
      yield put(
        ActionCustomDataReportCustomer.setReplaceOnProcessCdrc(value[2])
      );
      yield put(
        ActionCustomDataReportCustomer.setReplaceOnHoldCdrc(value[3] || '')
      );
    }

    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_DetailCustomReportDataCustomer() {
  yield takeLatest('GET_DETAIL_CDRC', S_DetailCustomReportDataCustomer);
}
