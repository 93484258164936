import React from 'react';

export const ComplaintFinishIcon = (iconColor, bgColor) => (
  <svg
    width="2.5em"
    height="2.5em"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="31" cy="31" r="31" fill={bgColor} />
    <path
      d="M41 30.0801V31.0001C40.9988 33.1565 40.3005 35.2548 39.0093 36.9819C37.7182 
      38.7091 35.9033 39.9726 33.8354 40.584C31.7674 41.1954 29.5573 41.122 27.5345 
      40.3747C25.5117 39.6274 23.7847 38.2462 22.611 36.4372C21.4373 34.6281 20.8798 
      32.4882 21.0217 30.3364C21.1636 28.1847 21.9972 26.1364 23.3983 24.4972C24.7994 
      22.8579 26.6928 21.7155 28.7962 21.2403C30.8996 20.765 33.1003 20.9824 35.07 21.8601"
      stroke={iconColor}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41 23L31 33.01L28 30.01"
      stroke={iconColor}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const ComplaintInQueueIcon = (iconColor, bgColor) => (
  <svg
    width="2.5em"
    height="2.5em"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="31" cy="31" r="31" fill={bgColor} />
    <path
      d="M36 40V38C36 36.9391 35.5786 35.9217 34.8284 35.1716C34.0783 34.4214 33.0609 34 
      32 34H24C22.9391 34 21.9217 34.4214 21.1716 35.1716C20.4214 35.9217 20 36.9391 20 38V40"
      stroke={iconColor}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28 30C30.2091 30 32 28.2091 32 26C32 23.7909 30.2091 22 28 22C25.7909 22 24 
      23.7909 24 26C24 28.2091 25.7909 30 28 30Z"
      stroke={iconColor}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M42 39.9999V37.9999C41.9993 37.1136 41.7044 36.2527 41.1614 35.5522C40.6184 
      34.8517 39.8581 34.3515 39 34.1299"
      stroke={iconColor}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M35 22.1299C35.8604 22.3502 36.623 22.8506 37.1676 23.5522C37.7122 24.2538 
      38.0078 25.1167 38.0078 26.0049C38.0078 26.8931 37.7122 27.756 37.1676 28.4576C36.623 
      29.1592 35.8604 29.6596 35 29.8799"
      stroke={iconColor}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const ComplaintInvestigationIcon = (iconColor, bgColor) => (
  <svg
    width="2.5em"
    height="2.5em"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="31" cy="31" r="31" fill={bgColor} />
    <path
      d="M31 34C32.6569 34 34 32.6569 34 31C34 29.3431 32.6569 28 31 28C29.3431 28 28 
      29.3431 28 31C28 32.6569 29.3431 34 31 34Z"
      stroke={iconColor}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.4 34C38.2669 34.3016 38.2272 34.6362 38.286 34.9606C38.3448 35.285 38.4995 
      35.5843 38.73 35.82L38.79 35.88C38.976 36.0657 39.1235 36.2863 39.2241 36.5291C39.3248
       36.7719 39.3766 37.0322 39.3766 37.295C39.3766 37.5578 39.3248 37.8181 39.2241 
       38.0609C39.1235 38.3037 38.976 38.5243 38.79 38.71C38.6043 38.896 38.3837 39.0435 
       38.1409 39.1441C37.8981 39.2448 37.6378 39.2966 37.375 39.2966C37.1122 39.2966 
       36.8519 39.2448 36.6091 39.1441C36.3663 39.0435 36.1457 38.896 35.96 38.71L35.9 
       38.65C35.6643 38.4195 35.365 38.2648 35.0406 38.206C34.7162 38.1472 34.3816 38.1869 
       34.08 38.32C33.7842 38.4468 33.532 38.6572 33.3543 38.9255C33.1766 39.1938 33.0813 
       39.5082 33.08 39.83V40C33.08 40.5304 32.8693 41.0391 32.4942 41.4142C32.1191 41.7893 
       31.6104 42 31.08 42C30.5496 42 30.0409 41.7893 29.6658 41.4142C29.2907 41.0391 29.08 
       40.5304 29.08 40V39.91C29.0723 39.579 28.9651 39.258 28.7725 38.9887C28.5799 38.7194 
       28.3107 38.5143 28 38.4C27.6984 38.2669 27.3638 38.2272 27.0394 38.286C26.715 38.3448 
       26.4157 38.4995 26.18 38.73L26.12 38.79C25.9343 38.976 25.7137 39.1235 25.4709 
       39.2241C25.2281 39.3248 24.9678 39.3766 24.705 39.3766C24.4422 39.3766 24.1819 39.3248 
       23.9391 39.2241C23.6963 39.1235 23.4757 38.976 23.29 38.79C23.104 38.6043 22.9565 
       38.3837 22.8559 38.1409C22.7552 37.8981 22.7034 37.6378 22.7034 37.375C22.7034 37.1122 
       22.7552 36.8519 22.8559 36.6091C22.9565 36.3663 23.104 36.1457 23.29 35.96L23.35 35.9C23.5805 
       35.6643 23.7352 35.365 23.794 35.0406C23.8528 34.7162 23.8131 34.3816 23.68 34.08C23.5532 
       33.7842 23.3428 33.532 23.0745 33.3543C22.8062 33.1766 22.4918 33.0813 22.17 33.08H22C21.4696 33.08 20.9609 
       32.8693 20.5858 32.4942C20.2107 32.1191 20 31.6104 20 31.08C20 30.5496 20.2107 30.0409 
       20.5858 29.6658C20.9609 29.2907 21.4696 29.08 22 29.08H22.09C22.421 29.0723 22.742 
       28.9651 23.0113 28.7725C23.2806 28.5799 23.4857 28.3107 23.6 28C23.7331 27.6984 
       23.7728 27.3638 23.714 27.0394C23.6552 26.715 23.5005 26.4157 23.27 26.18L23.21 26.12C23.024 
       25.9343 22.8765 25.7137 22.7759 25.4709C22.6752 25.2281 22.6234 24.9678 22.6234 24.705C22.6234 
       24.4422 22.6752 24.1819 22.7759 23.9391C22.8765 23.6963 23.024 23.4757 23.21 23.29C23.3957 
       23.104 23.6163 22.9565 23.8591 22.8559C24.1019 22.7552 24.3622 22.7034 24.625 22.7034C24.8878 22.7034 
       25.1481 22.7552 25.3909 22.8559C25.6337 22.9565 25.8543 23.104 26.04 23.29L26.1 23.35C26.3357 
       23.5805 26.635 23.7352 26.9594 23.794C27.2838 23.8528 27.6184 23.8131 27.92 23.68H28C28.2958 
       23.5532 28.548 23.3428 28.7257 23.0745C28.9034 22.8062 28.9987 22.4918 29 22.17V22C29 21.4696 29.2107 
       20.9609 29.5858 20.5858C29.9609 20.2107 30.4696 20 31 20C31.5304 20 32.0391 20.2107 32.4142 20.5858C32.7893 20.9609 33 21.4696 
       33 22V22.09C33.0013 22.4118 33.0966 22.7262 33.2743 22.9945C33.452 23.2628 33.7042 23.4732 34 23.6C34.3016 
       23.7331 34.6362 23.7728 34.9606 23.714C35.285 23.6552 35.5843 23.5005 35.82 23.27L35.88 23.21C36.0657 
       23.024 36.2863 22.8765 36.5291 22.7759C36.7719 22.6752 37.0322 22.6234 37.295 22.6234C37.5578 22.6234 37.8181 
       22.6752 38.0609 22.7759C38.3037 22.8765 38.5243 23.024 38.71 23.21C38.896 23.3957 39.0435 23.6163 39.1441 
       23.8591C39.2448 24.1019 39.2966 24.3622 39.2966 24.625C39.2966 24.8878 39.2448 25.1481 39.1441 
       25.3909C39.0435 25.6337 38.896 25.8543 38.71 26.04L38.65 26.1C38.4195 26.3357 38.2648 26.635 38.206 
       26.9594C38.1472 27.2838 38.1869 27.6184 38.32 27.92V28C38.4468 28.2958 38.6572 28.548 38.9255 28.7257C39.1938 
       28.9034 39.5082 28.9987 39.83 29H40C40.5304 29 41.0391 29.2107 41.4142 29.5858C41.7893 29.9609 42 30.4696 42 31C42 
       31.5304 41.7893 32.0391 41.4142 32.4142C41.0391 32.7893 40.5304 33 40 33H39.91C39.5882 33.0013 39.2738 33.0966 39.0055 33.2743C38.7372 
       33.452 38.5268 33.7042 38.4 34V34Z"
      stroke={iconColor}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const ComplaintReviewIcon = (iconColor, bgColor) => (
  <svg
    width="63"
    height="62"
    viewBox="0 0 63 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="31.5" cy="31" r="31" fill={bgColor} />
    <path
      d="M18.5 17.125C18.5 16.2962 18.8336 15.5013 19.4275 14.9153C20.0214 14.3292 20.8268 14 21.6667 14H34.3333C35.1732 14 35.9786 14.3292 36.5725 14.9153C37.1664 15.5013 37.5 16.2962 37.5 17.125V24L37.4715 24.7653C37.4715 24.7653 37.1597 24.603 36.9865 24.5C36.8133 24.397 36.2162 24.3576 35.9167 24.3576V17.125C35.9167 16.7106 35.7498 16.3132 35.4529 16.0201C35.156 15.7271 34.7533 15.5625 34.3333 15.5625H21.6667C21.2467 15.5625 20.844 15.7271 20.5471 16.0201C20.2501 16.3132 20.0833 16.7106 20.0833 17.125V35.875C20.0833 36.2894 20.2501 36.6868 20.5471 36.9799C20.844 37.2729 21.2467 37.4375 21.6667 37.4375H29.2302C29.6327 38.0282 30.1239 38.5551 30.6869 39H21.6667C20.8268 39 20.0214 38.6708 19.4275 38.0847C18.8336 37.4987 18.5 36.7038 18.5 35.875V17.125Z"
      fill={iconColor}
    />
    <rect x="26.5" y="20" width="7" height="2" rx="1" fill={iconColor} />
    <path
      d="M26.5 27C26.5 26.4477 26.9477 26 27.5 26H30.5476V26C30.9065 26 31.0907 26.4298 30.8432 26.6897L29.5952 28H27.5C26.9477 28 26.5 27.5523 26.5 27V27Z"
      fill={iconColor}
    />
    <path
      d="M24.5 18.9H22.5C21.8925 18.9 21.4 19.3925 21.4 20V22C21.4 22.6075 21.8925 23.1 22.5 23.1H24.5C25.1075 23.1 25.6 22.6075 25.6 22V20C25.6 19.3925 25.1075 18.9 24.5 18.9ZM22.1 20C22.1 19.7791 22.2791 19.6 22.5 19.6H24.5C24.7209 19.6 24.9 19.7791 24.9 20V22C24.9 22.2209 24.7209 22.4 24.5 22.4H22.5C22.2791 22.4 22.1 22.2209 22.1 22V20Z"
      fill={iconColor}
      stroke={iconColor}
      stroke-width="0.2"
    />
    <path
      d="M24.5 24.9H22.5C21.8925 24.9 21.4 25.3925 21.4 26V28C21.4 28.6075 21.8925 29.1 22.5 29.1H24.5C25.1075 29.1 25.6 28.6075 25.6 28V26C25.6 25.3925 25.1075 24.9 24.5 24.9ZM22.1 26C22.1 25.7791 22.2791 25.6 22.5 25.6H24.5C24.7209 25.6 24.9 25.7791 24.9 26V28C24.9 28.2209 24.7209 28.4 24.5 28.4H22.5C22.2791 28.4 22.1 28.2209 22.1 28V26Z"
      fill={iconColor}
      stroke={iconColor}
      stroke-width="0.2"
    />
    <path
      d="M24.5 30.9H22.5C21.8925 30.9 21.4 31.3925 21.4 32V34C21.4 34.6075 21.8925 35.1 22.5 35.1H24.5C25.1075 35.1 25.6 34.6075 25.6 34V32C25.6 31.3925 25.1075 30.9 24.5 30.9ZM22.1 32C22.1 31.7791 22.2791 31.6 22.5 31.6H24.5C24.7209 31.6 24.9 31.7791 24.9 32V34C24.9 34.2209 24.7209 34.4 24.5 34.4H22.5C22.2791 34.4 22.1 34.2209 22.1 34V32Z"
      fill={iconColor}
      stroke={iconColor}
      stroke-width="0.2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.5 33C42.5 36.866 39.5899 40 36 40C32.4101 40 29.5 36.866 29.5 33C29.5 29.134 32.4101 26 36 26C39.5899 26 42.5 29.134 42.5 33ZM36 38.6C38.8719 38.6 41.2 36.0928 41.2 33C41.2 29.9072 38.8719 27.4 36 27.4C33.1281 27.4 30.8 29.9072 30.8 33C30.8 36.0928 33.1281 38.6 36 38.6Z"
      fill={iconColor}
    />
    <rect
      x="38.6016"
      y="41.0156"
      width="3.4612"
      height="7.806"
      rx="1.7306"
      transform="rotate(-30 38.6016 41.0156)"
      fill={iconColor}
    />
    <rect
      x="37.5391"
      y="38.0938"
      width="2.37599"
      height="4.95842"
      rx="1.188"
      transform="rotate(-30 37.5391 38.0938)"
      fill={iconColor}
    />
  </svg>
);
export const ComplaintCustomerConfirmIcon = (iconColor, bgColor) => (
  <svg
    width="2.5em"
    height="2.5em"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="31" cy="31" r="31" fill={bgColor} />
    <path
      d="M35 23H37C37.5304 23 38.0391 23.2107 38.4142 23.5858C38.7893 23.9609 39 24.4696 39 
      25V39C39 39.5304 38.7893 40.0391 38.4142 40.4142C38.0391 40.7893 37.5304 41 37 
      41H25C24.4696 41 23.9609 40.7893 23.5858 40.4142C23.2107 40.0391 23 39.5304 23 
      39V25C23 24.4696 23.2107 23.9609 23.5858 23.5858C23.9609 23.2107 24.4696 23 25 23H27"
      stroke={iconColor}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34 21H28C27.4477 21 27 21.4477 27 22V24C27 24.5523 27.4477 25 28 25H34C34.5523 25 
      35 24.5523 35 24V22C35 21.4477 34.5523 21 34 21Z"
      stroke={iconColor}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const PickupStatusInQueueIcon = (iconColor, bgColor) => (
  <svg
    width="2.5em"
    height="2.5em"
    viewBox="0 0 77 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="38.5" cy="38.5" r="38.5" fill={bgColor} />
    <g clip-path="url(#clip0_1354_13374)">
      <path
        d="M53.9903 26.666L46.6778 19.3535C46.4478 19.1253 46.1365 18.9982 45.8125 19H26.3125C24.2932 
        19 22.6562 20.637 22.6562 22.6563V54.3438C22.6562 56.3631 24.2932 58 26.3125 58H50.6875C52.7068 
        58 54.3437 56.3631 54.3437 54.3438V27.5313C54.3457 27.2073 54.2184 26.896 53.9903 26.666ZM51.9062 
        54.3438C51.9062 55.0169 51.3606 55.5625 50.6875 55.5625H26.3125C25.6394 55.5625 25.0937 55.0169 
        25.0937 54.3438V22.6563C25.0937 21.9831 25.6394 21.4375 26.3125 21.4375H44.5937V26.3125C44.5937 27.6587 45.6851 28.75 47.0312 28.75H51.9062V54.3438Z"
        fill={iconColor}
      />
      <path
        d="M32.4062 32.4062C30.3869 32.4062 28.75 34.0432 28.75 36.0625C28.75 38.0818 30.3869 39.7188 
        32.4062 39.7188C34.4256 39.7188 36.0625 38.0818 36.0625 36.0625C36.0625 34.0433 34.4256 
        32.4062 32.4062 32.4062ZM32.4062 37.2812C31.7331 37.2812 31.1875 36.7356 31.1875 
        36.0625C31.1875 35.3894 31.7331 34.8438 32.4062 34.8438C33.0794 34.8438 33.625 
        35.3894 33.625 36.0625C33.625 36.7356 33.0794 37.2812 32.4062 37.2812Z"
        fill={iconColor}
      />
      <path
        d="M32.4062 44.5938C30.3869 44.5938 28.75 46.2307 28.75 48.25C28.75 50.2693 30.3869 
        51.9062 32.4062 51.9062C34.4256 51.9062 36.0625 50.2693 36.0625 48.25C36.0625 46.2308 
        34.4256 44.5938 32.4062 44.5938ZM32.4062 49.4688C31.7331 49.4688 31.1875 48.9231 
        31.1875 48.25C31.1875 47.5769 31.7331 47.0312 32.4062 47.0312C33.0794 47.0312 33.625 
        47.5769 33.625 48.25C33.625 48.9231 33.0794 49.4688 32.4062 49.4688Z"
        fill={iconColor}
      />
      <path d="M48.25 32.4062H38.5V34.8438H48.25V32.4062Z" fill={iconColor} />
      <path d="M43.375 37.2812H38.5V39.7188H43.375V37.2812Z" fill={iconColor} />
      <path d="M48.25 44.5938H38.5V47.0312H48.25V44.5938Z" fill={iconColor} />
      <path d="M43.375 49.4688H38.5V51.9062H43.375V49.4688Z" fill={iconColor} />
    </g>
    <defs>
      <clipPath id="clip0_1354_13374">
        <rect
          width="39"
          height="39"
          fill="white"
          transform="translate(19 19)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const PickupProccessIcon = (iconColor, bgColor) => (
  <svg
    width="2.5em"
    height="2.5em"
    viewBox="0 0 77 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="38.5" cy="38.5" r="38.5" fill={bgColor} />
    <g clip-path="url(#clip0_1354_13388)">
      <path
        d="M56.6182 43.2855L53.8184 37.7864C53.685 37.5244 53.4159 37.3594 53.1218 37.3594H38.9992H24.8763C24.5823 
        37.3594 24.3132 37.5244 24.1797 37.7864L21.38 43.2855C21.2566 43.5279 21.2681 43.8169 
        21.4102 44.0487C21.5523 44.2806 21.8047 44.4218 22.0765 44.4218H23.0521V58.2191C23.0521 
        58.6507 23.4021 59.0007 23.8338 59.0007H54.1619C54.5936 59.0007 54.9436 58.6507 54.9436 
        58.2191V44.4219H55.9215C56.1934 44.4219 56.4458 44.2806 56.5878 44.0488C56.7301 43.8169 
        56.7415 43.5279 56.6182 43.2855ZM52.6427 38.9227L54.6466 42.8585H42.2781L40.2742 38.9227H52.6427ZM25.3556 
        38.9227H37.7241L35.7202 42.8585H23.3517L25.3556 38.9227ZM53.3805 57.4374H39.7808V53.1053C39.7808 52.6737 
        39.4309 52.3237 38.9992 52.3237C38.5675 52.3237 38.2175 52.6737 38.2175 53.1053V57.4374H24.6156V44.4219H36.1994C36.4935 
        44.4219 36.7626 44.257 36.896 43.9949L38.2175 41.3991V46.0863C38.2175 46.5179 38.5675 
        46.8679 38.9992 46.8679C39.4309 46.8679 39.7808 46.5179 39.7808 46.0863V41.3991L41.1023 
        43.9949C41.2357 44.257 41.5049 44.4219 41.7989 44.4219H53.3805V57.4374Z"
        fill={iconColor}
      />
      <path
        d="M32.3145 53.1562H27.8637C27.432 53.1562 27.082 53.5063 27.082 53.9379C27.082 54.3695 
        27.432 54.7195 27.8637 54.7195H32.3145C32.7462 54.7195 33.0961 54.3695 33.0961 53.9379C33.0961 
        53.5063 32.7462 53.1562 32.3145 53.1562Z"
        fill={iconColor}
      />
      <path
        d="M38.9999 35.0602C43.4228 35.0602 47.0211 31.4574 47.0211 27.029C47.021 22.6018 43.4228 19 
        38.9999 19C34.5759 19 30.9766 22.6018 30.9766 27.029C30.9766 31.4574 34.5758 35.0602 
        38.9999 35.0602ZM38.9999 20.5633C42.5608 20.5633 45.4578 23.4638 45.4578 27.029C45.4578 
        30.5955 42.5608 33.4969 38.9999 33.4969C35.4378 33.4969 32.5398 30.5955 32.5398 
        27.029C32.5398 23.4638 35.4378 20.5633 38.9999 20.5633Z"
        fill={iconColor}
      />
      <path
        d="M35.495 28.2019L37.5754 29.9654C37.7172 30.0856 37.8965 30.1508 38.0809 30.1508C38.1031 30.1508 
        38.1253 30.1499 38.1476 30.148C38.3551 30.1302 38.5468 30.0305 38.6804 29.8708L42.5956 25.1914C42.8726 
        24.8604 42.8288 24.3674 42.4977 24.0903C42.1666 23.8134 41.6737 23.8571 41.3967 24.1882L37.9863 28.2643L36.5059 
        27.0093C36.1766 26.7302 35.6833 26.7707 35.4043 27.1002C35.125 27.4295 35.1657 27.9227 
        35.495 28.2019Z"
        fill={iconColor}
      />
      <path
        d="M23.643 27.8113H27.0248C27.4565 27.8113 27.8064 27.4613 27.8064 27.0297C27.8064 
        26.5981 27.4565 26.248 27.0248 26.248H23.643C23.2113 26.248 22.8613 26.5981 22.8613 
        27.0297C22.8613 27.4613 23.2113 27.8113 23.643 27.8113Z"
        fill={iconColor}
      />
      <path
        d="M23.2785 22.3716L26.6603 24.1552C26.7766 24.2166 26.9013 24.2456 27.0243 
        24.2456C27.3049 24.2456 27.5763 24.0941 27.7164 23.8285C27.9177 23.4466 27.7715 22.9738 
        27.3897 22.7725L24.0077 20.9889C23.6261 20.7875 23.1531 20.9337 22.9517 21.3156C22.7504 
        21.6974 22.8966 22.1702 23.2785 22.3716Z"
        fill={iconColor}
      />
      <path
        d="M22.9515 32.7418C23.0915 33.0076 23.3629 33.1594 23.6437 33.1594C23.7665 33.1594 
        23.8912 33.1304 24.0074 33.0692L27.3892 31.2878C27.7711 31.0866 27.9177 30.6139 27.7165
         30.232C27.5153 29.85 27.0425 29.7035 26.6606 29.9046L23.2788 31.686C22.8969 31.8872
          22.7504 32.3599 22.9515 32.7418Z"
        fill={iconColor}
      />
      <path
        d="M50.9711 27.8113H54.3529C54.7846 27.8113 55.1346 27.4613 55.1346 27.0297C55.1346 26.5981 54.7846 
        26.248 54.3529 26.248H50.9711C50.5394 26.248 50.1895 26.5981 50.1895 27.0297C50.1895 27.4613 50.5394 
        27.8113 50.9711 27.8113Z"
        fill={iconColor}
      />
      <path
        d="M50.9718 24.2457C51.0948 24.2457 51.2195 24.2166 51.3359 24.1553L54.7177 22.3716C55.0995 22.1703 
        55.2458 21.6975 55.0444 21.3156C54.843 20.9338 54.3703 20.7874 53.9884 20.9889L50.6066 
        22.7725C50.2248 22.9739 50.0785 23.4467 50.2799 23.8285C50.4199 24.0941 50.6912 
        24.2457 50.9718 24.2457Z"
        fill={iconColor}
      />
      <path
        d="M50.607 31.2879L53.9888 33.0692C54.105 33.1304 54.2296 33.1594 54.3524 
        33.1594C54.6333 33.1594 54.9046 33.0077 55.0446 32.7419C55.2458 32.36 55.0993 
        31.8872 54.7174 31.6861L51.3355 29.9047C50.9535 29.7035 50.4809 29.85 50.2797 
        30.2321C50.0785 30.614 50.225 31.0867 50.607 31.2879Z"
        fill={iconColor}
      />
      <path
        d="M38.9984 48.8145C38.5667 48.8145 38.2168 49.1645 38.2168 49.5961V49.6005C38.2168 
        50.0322 38.5667 50.38 38.9984 50.38C39.4301 50.38 39.7801 50.0278 39.7801 
        49.5962C39.7801 49.1645 39.4301 48.8145 38.9984 48.8145Z"
        fill={iconColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_1354_13388">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(19 19)"
        />
      </clipPath>
    </defs>
  </svg>
);
export const PickupFinishIcon = (iconColor, bgColor) => (
  <svg
    width="2.5em"
    height="2.5em"
    viewBox="0 0 77 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="38.5" cy="38.5" r="38.5" fill={bgColor} />
    <path
      d="M22.5 19.983C22.5 19.1694 23.169 18.5 24.0053 18.5H39.2095L48.5769 27.3973V51.7093C48.5769 
      52.523 47.9083 53.1923 47.0716 53.1923H24.0053C23.169 53.1923 22.5 52.5229 22.5 
      51.7093V19.983Z"
      fill={bgColor}
      stroke={iconColor}
    />
    <path
      d="M39.7305 19.1541L47.8122 26.7308H41.2727C40.4094 26.7308 39.7305 26.0507 39.7305 
      25.2374V19.1541Z"
      fill={bgColor}
      stroke={iconColor}
    />
    <path
      d="M46.2132 29.9438C46.2132 30.287 45.9348 30.5654 45.5911 30.5654H25.6977C25.3545 
      30.5654 25.0762 30.287 25.0762 29.9438C25.0762 29.6006 25.3545 29.3223 25.6977 
      29.3223H45.5911C45.9348 29.3223 46.2132 29.6006 46.2132 29.9438Z"
      fill={iconColor}
    />
    <path
      d="M46.2132 33.2075C46.2132 33.5507 45.9348 33.829 45.5911 33.829H25.6977C25.3545 
      33.829 25.0762 33.5507 25.0762 33.2075C25.0762 32.8643 25.3545 32.5859 25.6977 
      32.5859H45.5911C45.9348 32.5859 46.2132 32.8643 46.2132 33.2075Z"
      fill={iconColor}
    />
    <path
      d="M46.2132 36.3169C46.2132 36.66 45.9348 36.9384 45.5911 36.9384H25.6977C25.3545 
      36.9384 25.0762 36.66 25.0762 36.3169C25.0762 35.9737 25.3545 35.6953 25.6977 
      35.6953H45.5911C45.9348 35.6948 46.2132 35.9737 46.2132 36.3169Z"
      fill={iconColor}
    />
    <path
      d="M46.2132 39.5805C46.2132 39.9237 45.9348 40.2021 45.5911 40.2021H25.6977C25.3545 
      40.2021 25.0762 39.9237 25.0762 39.5805C25.0762 39.2374 25.3545 38.959 25.6977 
      38.959H45.5911C45.9348 38.9585 46.2132 39.2374 46.2132 39.5805Z"
      fill={iconColor}
    />
    <path
      d="M36.1111 42.2207H25.6977C25.3546 42.2207 25.0762 42.4991 25.0762 42.8423C25.0762 
      43.1854 25.3546 43.4638 25.6977 43.4638H36.1111C36.4548 43.4638 36.7331 43.1854 
      36.7331 42.8423C36.7331 42.4991 36.4546 42.2207 36.1111 42.2207Z"
      fill={iconColor}
    />
    <path
      d="M53.4995 50.9226C53.4995 54.555 50.555 57.4995 46.9226 57.4995C43.2903 57.4995 
      40.3457 54.555 40.3457 50.9226C40.3457 47.2903 43.2903 44.3457 46.9226 44.3457C50.555 
      44.3457 53.4995 47.2903 53.4995 50.9226Z"
      fill={iconColor}
      stroke={iconColor}
    />
    <path
      d="M46.9579 52.1352L45.3724 50.55C45.0583 50.2359 44.5494 50.2359 44.2353 50.55C43.9216 
      50.8637 43.9216 51.373 44.2353 51.6871L45.8209 53.2726C46.1349 53.5867 46.6446 53.5867 
      46.9583 53.2726C47.2726 52.9586 47.2726 52.4496 46.9579 52.1352Z"
      fill={bgColor}
    />
    <path
      d="M49.9504 49.2353C49.6366 48.9216 49.1274 48.9216 48.8136 49.2353L45.8784 
      52.1699C45.564 52.484 45.564 52.9929 45.878 53.3074C46.1921 53.6211 46.7017 53.6211 
      47.0155 53.307L49.9505 50.3724C50.2641 50.058 50.2648 49.5493 49.9504 49.2353Z"
      fill={bgColor}
    />
  </svg>
);
