import { Col, Row, Table } from 'antd';
import { Field, reduxForm } from 'redux-form';
import {
  InputSelect,
  TextInputSearch,
} from '../../../../apps/assets/components/CInput';

import CButton from '../../../../apps/assets/components/CButton';
import CCard from '../../../../apps/assets/components/CCard';
import CSpin from '../../../../apps/assets/components/CSpin';
import { InputDatePicker } from '../../../../apps/assets/components/CInputDate';
import { RadioButton } from '../../../../apps/assets/components/CRadio';
import React from 'react';
import moment from 'moment';
import { pickupStatus } from 'apps/enums';
import validate from '../Validation/PickupDataValidation';

function PickupDataComponent(props) {
  const {
    handleDetailFilter,
    columns,
    isLoading,
    CollapseState,
    handleGetPickupData,
    handleGetCustomerList,
    handleClearCustomerProduct,
    handleGetBranchList,
    handleClearCourier,
    handleGetCustomerProduct,
    handleGetCourier,
    handleResetPickupDataForm,
    handleSearchDataPickupData,
    handlePage,
    countingDataStatus,
    pickupDataList,
    branchList,
    customerList,
    courierList,
    customerProductList,
    formValue,
    errorFormValue,
    pagePagination,
    totalPagination,
  } = props;
  const cycleValue = formValue ? formValue.startDate : '';
  const finishDateValue = formValue ? formValue.finishDate : '';
  // const pickupStatusValue = formValue ? formValue.pickupStatus : '';
  const branchValue = formValue ? formValue.branchPickup : '';
  const courierValue = formValue ? formValue.courierPickup : '';
  const customerValue = formValue ? formValue.customerPickup : '';
  const productValue = formValue ? formValue.customerProduct : '';
  const dateTypeValue = formValue ? formValue.dateType : '';
  const inQueueStats = countingDataStatus ? countingDataStatus.inQueue : null;
  const processStats = countingDataStatus ? countingDataStatus.process : null;
  const finishStats = countingDataStatus ? countingDataStatus.finish : null;

  const validateBtnSearch = () => {
    if (
      !errorFormValue.startDate === true &&
      !errorFormValue.finishDate === true &&
      !errorFormValue.pickupStatus === true
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validateBtnReset = () => {
    if (
      !cycleValue === false ||
      !finishDateValue === false ||
      !branchValue === false ||
      !courierValue === false ||
      !customerValue === false ||
      !productValue === false
    ) {
      return false;
    } else {
      return true;
    }
  };
  const btnForm = [
    {
      type: 'primary',
      className: 'btnResetDefault',
      onClick: handleResetPickupDataForm,
      isLoading: isLoading,
      content: 'Reset',
      disabled: validateBtnReset(),
      id: 'btnReset',
    },
    {
      type: 'primary',
      className: 'btnSubmitSearch',
      onClick: handleGetPickupData,
      isLoading: isLoading,
      disabled: validateBtnSearch(),
      content: 'Cari',
      id: 'btnSearch',
    },
  ];
  const btnLink = [
    {
      type: 'link',
      className: 'floatRight',
      onClick: handleDetailFilter,
      //   isLoading: isLoading,
      content: CollapseState === true ? 'Ubah Filter v' : 'Ubah Filter >',
      id: 'btnFilter',
    },
  ];
  const dateValidation = (d: any) => !d || d.isAfter(moment(new Date()));
  const disableDate = (d: any) =>
    !d ||
    d.isBefore(moment(cycleValue).add(0, 'day').format('YYYY-MM-DD')) ||
    d.isAfter(moment(cycleValue).add(31, 'day').format('YYYY-MM-DD'));
  const contentCard = () => {
    return (
      <Row>
        <Col span={12} className="spacingRight">
          <Field
            name="pickupStatus"
            component={InputSelect}
            mode="multiple"
            idComponent="inputPickupStatus"
            data={pickupStatus}
            placeholder="Semua"
            label={'Status Pengambilan'}
          />
          <Field
            name="customerPickup"
            component={InputSelect}
            idComponent="inputCustomerPickup"
            dafaultItemName="Pilih Pelanggan"
            additionalHandleOnFocusFunc={handleGetCustomerList}
            additionalHandleChangeFunc={handleClearCustomerProduct}
            data={customerList}
            label={'Pelanggan'}
          />
          <Field
            name="customerProduct"
            component={InputSelect}
            idComponent="inputCustomerProduct"
            dafaultItemName="Pilih Produk Pelanggan"
            additionalHandleOnFocusFunc={handleGetCustomerProduct}
            data={customerProductList}
            label={'Produk Pelanggan'}
            disabled={!customerValue ? true : false}
          />
        </Col>
        <Col span={12}>
          <Field
            name="branchPickup"
            component={InputSelect}
            idComponent="inputBranchPickup"
            dafaultItemName="Pilih Posko"
            additionalHandleOnFocusFunc={handleGetBranchList}
            additionalHandleChangeFunc={handleClearCourier}
            data={branchList}
            label={'Posko'}
          />
          <Field
            name="courierPickup"
            component={InputSelect}
            idComponent="inputCourierPickup"
            dafaultItemName="Pilih Kurir"
            customOption={'labelAndCode'}
            additionalHandleOnFocusFunc={handleGetCourier}
            data={courierList}
            label={'Kurir'}
            disabled={!branchValue ? true : false}
          />
        </Col>
      </Row>
    );
  };
  return (
    <div className="defaultSpace">
      <CSpin
        spinning={isLoading}
        size={'large'}
        content={
          <React.Fragment>
            <CCard
              cardClassName={'defaultCard'}
              cardTitle={
                <span className="lightBlueColor">DATA PENGAMBILAN</span>
              }
              cardContent={
                <>
                  <Row>
                    <Row>
                      <Field
                        name="dateType"
                        component={RadioButton}
                        idComponent="radioDateType"
                        data={[
                          {
                            id: true,
                            name: 'Cycle Dokumen',
                          },
                          {
                            id: false,
                            name: 'Pengambilan Dokumen',
                          },
                        ]}
                      />
                    </Row>
                    <Col span={12} className="spacingRight">
                      <Field
                        name="startDate"
                        component={InputDatePicker}
                        idComponent="inputCycleDate"
                        label={`Tanggal ${
                          dateTypeValue === true ? 'Cycle' : 'Ambil'
                        }`}
                        // picker="DD-MM-YYYY"
                        typeDate="datePicker"
                        disabledDate={dateValidation}
                        placeholder={'Masukan tanggal'}
                      />
                    </Col>
                    <Col span={12}>
                      <Field
                        name="finishDate"
                        component={InputDatePicker}
                        idComponent="inputFinishDate"
                        label="Sampai Tanggal"
                        typeDate="datePicker"
                        // format="DD-MM-YYYY"
                        disabledDate={disableDate}
                        disabled={!cycleValue ? true : false}
                        placeholder={'Masukan tanggal'}
                      />
                    </Col>
                    <CButton buttonData={btnLink} />
                    {CollapseState === true ? contentCard() : ''}
                  </Row>
                  <Row className="floatRight">
                    <CButton buttonData={btnForm} />
                  </Row>
                </>
              }
            />
            <CCard
              cardClassName={'defaultCard marginTopDefault'}
              cardContent={
                <Row>
                  <Col span={12}>
                    <Field
                      name="searchValue"
                      component={TextInputSearch}
                      idComponent="inputSearch"
                      label="SEMUA DATA"
                      className="searchForm"
                      additionalHandleEnterFunc={handleSearchDataPickupData}
                      placeholder={'Cari berdasarkan nama'}
                      regex={'noSpaceInFirstChar'}
                    />
                  </Col>
                  <Col span={12}>
                    <Col span={8}>
                      <div className="floatRight">
                        Dalam Antrian :{' '}
                        <span className="lightBlueColor fontWeight">
                          {inQueueStats}
                        </span>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="floatRight">
                        Proses :{' '}
                        <span className="greenText fontWeight">
                          {processStats}
                        </span>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div className="floatRight">
                        Selesai :{' '}
                        <span className="redColor fontWeight">
                          {finishStats}
                        </span>
                      </div>
                    </Col>
                  </Col>
                </Row>
              }
            />
            <Table
              pagination={{
                onChange: page => {
                  handlePage(page);
                },
                pageSize: 10,
                total: totalPagination,
                current: pagePagination === 0 ? 1 : pagePagination,
              }}
              bordered={false}
              dataSource={pickupDataList}
              columns={columns}
            />
          </React.Fragment>
        }
      />
    </div>
  );
}

const PickupDataComponentForm = reduxForm({
  form: 'pickupDataForm',
  shouldError: () => {
    return true;
  },
  validate,
})(PickupDataComponent);
export default PickupDataComponentForm;
