import { Checkbox, Form } from 'antd';

import React from 'react';

interface IPropsCheckBox {
  input?;
  meta?: any;
  label?;
  disabled?;
  defaultValues?;
  className?;
  classNameLabel?;
  formClassName?;
  labelRight?;
  onChange?: (e?: any) => void;
  isOptional?: boolean;
  formStyle?: any;
  value?: any;
  indeterminate?: any;
}
export const SingleCheckbox = (props: IPropsCheckBox) => {
  const {
    input,
    meta,
    label,
    className,
    classNameLabel,
    disabled,
    labelRight,
    isOptional = true,
    onChange = () => undefined,
    formStyle,
    value,
  } = props;

  const handleOnChange = e => {
    input?.onChange(e);
    onChange(e);
  };
  const labelTitle = label ? (
    <span>
      <b className={classNameLabel}>{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={
        !isOptional && meta?.touched && meta?.error !== undefined ? 'error' : ''
      }
      help={
        !isOptional && meta?.touched && meta?.error !== undefined
          ? meta?.error
          : ''
      }
      label={labelTitle}
      style={{ marginBottom: '0px', ...formStyle }}
    >
      <Checkbox
        indeterminate={props.indeterminate}
        onChange={handleOnChange}
        className={className}
        checked={input?.value || value}
        disabled={disabled}
      >
        {labelRight}
      </Checkbox>
    </Form.Item>
  );
};
