import React from 'react';
import { Modal, Icon } from 'antd';
import Button from 'components/Button';
import styles from './ModalConfirmation.module.css';

type ModalConfirmationProps = {
  onCancel?: () => void;
  onConfirm?: () => void;
  title: string;
  description?: string;
  visibleModal: boolean;
  isLoadingYa?: boolean;
  centered?: boolean;
  cancelText?: string;
  okeText?: string;
};

export default function ModalConfirmation({
  onCancel,
  onConfirm,
  title,
  description,
  visibleModal,
  isLoadingYa = false,
  centered = false,
  cancelText = 'BATAL',
  okeText = 'HAPUS',
}: ModalConfirmationProps) {
  return (
    <div>
      <Modal
        title="Basic Modal"
        visible={visibleModal}
        footer={null}
        closable={false}
        centered={centered}
        className={'modalConfirmation'}
      >
        <div className={styles.wrapperModal}>
          <Icon type="exclamation-circle" className={styles.iconModal} />
          <p className={styles.titleModal}>{title}</p>
          {description && <p className={styles.descModal}>{description}</p>}
          <div className={styles.wrapperAction}>
            <Button
              text={cancelText}
              variant="primary"
              borderRadius="large"
              onClick={onCancel}
              loading={isLoadingYa}
            />
            <Button
              text={okeText}
              variant="danger"
              borderRadius="large"
              onClick={onConfirm}
              loading={isLoadingYa}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
