import * as ActionFormPrintWaybill from '../../../../core/action/Delivery/PrintWaybill/ActionFormPrintWaybill';
import * as toggle from '../../../../core/action/toggle';

import React, { useState, useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { downloadWaybillPdfDeliveryType } from 'services/DeliveryService';
import { bindActionCreators, compose } from 'redux';

import FormTypePrintWaybillComponent from '../../../../components/Content/Delivery/PrintWaybill/FormWaybillPrintTypeComponent';
import { connect } from 'react-redux';
import { downloadBlob, processProgressRequest } from 'lib/util';
import ModalStatus from 'materials/ModalStatus';
import moment from 'moment-timezone';
import { Modal } from 'antd';
import ModalDownloadProgress from 'materials/ModalDownloadProgress';

interface IProps {
  waybillPrintType: any;
  getSeparatorAddress: any;
  getWatermark: any;
  visible: any;
  jenisToggle: any;
  actionToggle: any;
  actionFormPrintWaybill: any;
  getNotelp: any;
  getCodeBrankas: any;
  FileData: any;
  CourierId: any;
  NoTelp: any;
  SeparatorAddress: any;
  UploadDate: any;
  RadioButton: any;
}

const FormWaybillPrintTypeContainer = (props: IProps) => {
  const [loadingProgress, setLoadingProgress] = useState(0);
  const progressHandler = processProgressRequest(setLoadingProgress);

  const { mutate: mutateDownload, isLoading: isLoadingDownload } = useMutation(
    (data: any) => downloadWaybillPdfDeliveryType(data, progressHandler),
    {
      onSuccess: res => {
        if (res.status === 200) {
          downloadBlob(
            res.data,
            `Waybill - ${moment().format('YYYY-MM-DD HH:mm:ss')}`,
            'pdf'
          );
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    }
  );

  useEffect(() => {
    if (loadingProgress === 100) {
      Modal.destroyAll();
      setLoadingProgress(0);
    }
  }, [loadingProgress]);

  const handleClearModal = () => {
    props.actionFormPrintWaybill.ClearAllCheckbox();
    props.actionFormPrintWaybill.clearWaybillPrintType();
    props.actionFormPrintWaybill.clearWatermarkPW();
    props.actionToggle.changeModal();
    props.actionToggle.clearJenisToggle();
  };

  const handlePutPrintType = (event: any) => {
    props.actionFormPrintWaybill.changeWaybillPrintType(event.target.value);
  };

  const handlePutSeparatorAddress = (event: any) => {
    props.actionFormPrintWaybill.changeSeparatorAddress(event.target.checked);
  };
  const handlePutWatermark = (event: any) => {
    props.actionFormPrintWaybill.changeWatermark(event.target.checked);
  };

  const handleDownload = () => {
    const courierId = props.CourierId === null ? 0 : props.CourierId;
    const Cycle = moment(props.UploadDate).format('YYYYMMDD');
    const cycle = props.RadioButton === 'Upload' ? null : Cycle;

    mutateDownload({
      deliveryFile: props.FileData,
      printType: props.waybillPrintType,
      courierId,
      cycle,
      separator: props.SeparatorAddress,
      NoTelp: props.NoTelp,
      CodeBrankas: props.getCodeBrankas,
      watermark: true,
    });
  };
  const handlePutNotelp = (event: any) => {
    props.actionFormPrintWaybill.changeNotelp(event.target.checked);
  };
  const handlePutCodeBrankas = (event: any) => {
    props.actionFormPrintWaybill.changeCodeBrankas(event.target.checked);
  };

  return (
    <React.Fragment>
      <FormTypePrintWaybillComponent
        visible={
          props.jenisToggle === 'SelectWaybillPrintType' &&
          props.visible === true
            ? true
            : false
        }
        handleClearModal={handleClearModal}
        handlePutPrintType={handlePutPrintType}
        handlePutSeparatorAddress={handlePutSeparatorAddress}
        handlePutWatermark={handlePutWatermark}
        getWatermark={props.getWatermark}
        handleDownload={handleDownload}
        waybillPrintType={props.waybillPrintType}
        getSeparatorAddress={props.getSeparatorAddress}
        handlePutNotelp={handlePutNotelp}
        getNotelp={props.getNotelp}
        handlePutCodeBrankas={handlePutCodeBrankas}
        getCodeBrankas={props.getCodeBrankas}
      />
      <ModalDownloadProgress
        loading={isLoadingDownload}
        loadingProgress={loadingProgress}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  waybillPrintType: state.PrintWaybill.FormPrintWaybill.WaybillPrintType,
  getSeparatorAddress: state.PrintWaybill.FormPrintWaybill.SeparatorAddress,
  getWatermark: state.PrintWaybill.FormPrintWaybill.Watermark,
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  isLoading: state.Toggle.isLoading,
  getNotelp: state.PrintWaybill.FormPrintWaybill.NoTelp,
  getCodeBrankas: state.PrintWaybill.FormPrintWaybill.CodeBrankas,
  FileData: state.PrintWaybill.CheckboxId,
  CourierId: state.PrintWaybill.FormPrintWaybill.CourierId,
  NoTelp: state.PrintWaybill.FormPrintWaybill.NoTelp,
  SeparatorAddress: state.PrintWaybill.FormPrintWaybill.SeparatorAddress,
  UploadDate: state.PrintWaybill.FormPrintWaybill.UploadDate,
  RadioButton: state.PrintWaybill.FormPrintWaybill.RadioButton,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionFormPrintWaybill: bindActionCreators(ActionFormPrintWaybill, dispatch),
  actionToggle: bindActionCreators(toggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormWaybillPrintTypeContainer);
