import React from 'react';

export const SectionInfo = ({
  label,
  value,
  valueStyle,
}: {
  label: string;
  value: any;
  valueStyle?: any;
}) => {
  return (
    <div>
      <p style={{ margin: 0, fontWeight: 700 }}>{label}</p>
      <p style={{ ...valueStyle, fontSize: 16, margin: 0 }}>{value}</p>
    </div>
  );
};
