import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { Tabs } from 'antd';
import { AxiosResponse } from 'axios';

import BaggingDocument from './BaggingDocument';
import AcceptedBag from './AcceptedBag';
import { getAllBranch } from 'services/GeneralService';

const { TabPane } = Tabs;
type DeliveryBetweenPostsComponentProps = {
  IdBranch: number;
  roleAccount: string;
};

export type BaggingDocumentFormType = {
  typeBag: string | null;
  dateSend: any | null;
  courier: number | null;
  branchFrom: number | null;
  branchTo: number | null;
  barcodeBag: string | null;
  finishBaggingTime?: any | null;
  couriercode?: string | null;
  branchFromName?: string | null;
  branchToName?: string | null;
  baggingStatusDocument?: string | null;
};

export type DataFilterBaggingType = {
  BaggingTypes: string[] | null;
  CreatedBaggingTime: any;
  BaggingStatusDocument: string[] | null;
  OriginalBranch: number | null | undefined;
  DestinationBranch: number | null;
  BarcodeBagging?: string;
};
// DY: this sample created tab
function DeliveryBetweenPostsComponent({
  IdBranch,
  roleAccount,
}: DeliveryBetweenPostsComponentProps) {
  const { data: dataAllBranch, isLoading: isLoadingBranch } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataBranchReport'],
    queryFn: () => getAllBranch(),
  });

  return (
    <div>
      <Tabs style={{ padding: '35px' }} defaultActiveKey={'1'}>
        <TabPane tab={<b>Bagging Dokumen</b>} key="1">
          <BaggingDocument
            dataAllBranch={dataAllBranch?.data || []}
            isLoadingBranch={isLoadingBranch}
            IdBranch={IdBranch}
            roleAccount={roleAccount}
          />
        </TabPane>
        <TabPane tab={<b>Terima Bag</b>} key="2">
          <AcceptedBag
            dataAllBranch={dataAllBranch?.data || []}
            isLoadingBranch={isLoadingBranch}
            IdBranch={IdBranch}
            roleAccount={roleAccount}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  IdBranch: state.Auth.BranchAuth,
  roleAccount: state.Auth.Role,
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(DeliveryBetweenPostsComponent);
