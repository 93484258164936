export function changeRadiobuttonDelivery(RadioButtonDelivery: any) {
  return {
    type: 'CHANGE_RADIO_BUTTON_DELIVERY_V_REPORT_FORM',
    RadioButtonDelivery,
  };
}

export function changeReportType(ReportType: any) {
  return {
    type: 'CHANGE_REPORT_TYPE_V_REPORT_FORM',
    ReportType,
  };
}

export function changeFirstDateVReport(FirstDate: any) {
  return {
    type: 'CHANGE_FIRST_DATE_V_REPORT_FORM',
    FirstDate,
  };
}

export function changeLastDateVReport(LastDate: any) {
  return {
    type: 'CHANGE_LAST_DATE_V_REPORT_FORM',
    LastDate,
  };
}

export function changeBranchVReport(Branch: any) {
  return {
    type: 'CHANGE_BRANCH_V_REPORT_FORM',
    Branch,
  };
}

export function changeStatusVReport(Status: any) {
  return {
    type: 'CHANGE_STATUS_V_REPORT_FORM',
    Status,
  };
}

export function changeGeoTaggingVReport(GeoTagging: any) {
  return {
    type: 'CHANGE_GEO_TAGGING_V_REPORT_FORM',
    GeoTagging,
  };
}

export function changeFotoVReport(Foto: any) {
  return {
    type: 'CHANGE_FOTO_V_REPORT_FORM',
    Foto,
  };
}

export function changeSignatureVReport(Signature: any) {
  return {
    type: 'CHANGE_SIGNATURE_V_REPORT_FORM',
    Signature,
  };
}

export function changeMultipleSelectVReport(MultipleSelect: any) {
  return {
    type: 'CHANGE_MULTIPLE_SELECT_V_REPORT_FORM',
    MultipleSelect,
  };
}

export function downloadValidateReport() {
  return {
    type: 'DOWNLOAD_VALIDATE_REPORT',
  };
}
export function clearBranchValidateReportForm() {
  return {
    type: 'CLEAR_BRANCH_V_REPORT',
  };
}
export function clearValidateReportForm() {
  return {
    type: 'CLEAR_VALIDATE_REPORT_FROM',
  };
}
