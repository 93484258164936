import * as ActionCustomerFiture from '../../../core/action/Customer/ActionCustomerFiture';
import * as ActionNavigation from '../../../core/action/Router/ActionRouter';
import * as toggle from '../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import CustomerConfigFitureComponent from '../../../components/Content/Customer/CustomerConfigFitureComponent';
import { connect } from 'react-redux';
import history from '../../../core/utils/History';

interface IProps {
  GetSmsDelivery: any;
  GetSmsDelivered: any;
  GetSenderAlias: any;
  GetSmsDeliveryBody: any;
  GetSmsDeliveredBody: any;
  GetIdFiture: any;
  GetTypeFiture: any;
  GetCall: any;
  GetCustomerName: any;
  isLoading: any;
  actionCustomerFiture: any;
  actionNavigation: any;
  IsRadius: boolean;
  IsRequestPhone: boolean;
  IsHistory: boolean;
  RadiusMeters: number;
}

interface IState {
  checkBoxSmsDelivery: any;
  checkBoxSmsDelivered: any;
  checkBoxTelepon: any;
  validateSenderAlias: any;
  validateSmsDeliveryBody: any;
  validateSmsDeliveredBody: any;
}

class CustomerConfigCustomFitureContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      checkBoxSmsDelivery: null,
      checkBoxSmsDelivered: null,
      checkBoxTelepon: null,
      validateSenderAlias: null,
      validateSmsDeliveryBody: null,
      validateSmsDeliveredBody: null,
    };

    this.handleCheckboxSmsDelivery = this.handleCheckboxSmsDelivery.bind(this);
    this.handleCheckboxSmsDelivered =
      this.handleCheckboxSmsDelivered.bind(this);
    this.handleCheckboxTelepon = this.handleCheckboxTelepon.bind(this);
    this.handleSenderAlias = this.handleSenderAlias.bind(this);
    this.handleSmsDeliveryBody = this.handleSmsDeliveryBody.bind(this);
    this.handleSmsDeliveredBody = this.handleSmsDeliveredBody.bind(this);
    this.handleSubmitFiture = this.handleSubmitFiture.bind(this);
    this.handleEditFiture = this.handleEditFiture.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleChangeRadiusMeters = this.handleChangeRadiusMeters.bind(this);
    this.handleCheckboxIsRadius = this.handleCheckboxIsRadius.bind(this);
    this.handleCheckboxIsRequestPhone =
      this.handleCheckboxIsRequestPhone.bind(this);
    this.handleCheckboxIsHistory = this.handleCheckboxIsHistory.bind(this);
  }

  handleCheckboxSmsDelivery(event: any) {
    this.props.actionCustomerFiture.changeSmsDelivery(event.target.checked);
    if (event.target.checked === true) {
      this.setState({
        validateSmsDeliveryBody: 'error',
      });
    } else {
      this.setState({
        validateSmsDeliveryBody: '',
      });
    }
    this.props.actionCustomerFiture.changeClearSmsDeliveryBody();
  }

  handleCheckboxSmsDelivered(event: any) {
    this.props.actionCustomerFiture.changeSmsDelivered(event.target.checked);
    if (event.target.checked === true) {
      this.setState({
        validateSmsDeliveredBody: 'error',
      });
    } else {
      this.setState({
        validateSmsDeliveredBody: '',
      });
    }
    this.props.actionCustomerFiture.changeClearSmsDeliveredBody();
  }

  handleCheckboxTelepon(event: any) {
    this.props.actionCustomerFiture.changeCall(event.target.checked);
  }

  handleCheckboxIsRadius(event: any) {
    this.props.actionCustomerFiture.changeIsRadius(event.target.checked);
  }

  handleCheckboxIsRequestPhone(event: any) {
    this.props.actionCustomerFiture.changeIsRequestPhone(event.target.checked);
  }

  handleCheckboxIsHistory(event: any) {
    this.props.actionCustomerFiture.changeIsHistory(event.target.checked);
  }

  handleChangeRadiusMeters(value: number) {
    this.props.actionCustomerFiture.changeRadiusMeters(value);
  }

  handleBack() {
    this.props.actionCustomerFiture.changeClearCustomerFiture();
    history.push('/customer');
    this.props.actionNavigation.setRouter('/customer');
  }

  handleSenderAlias(event: any) {
    if (event.target.value === '') {
      this.setState({
        validateSenderAlias: 'error',
      });
    } else {
      this.setState({
        validateSenderAlias: '',
      });
    }
    this.props.actionCustomerFiture.changeSenderAlias(event.target.value);
  }
  handleSmsDeliveryBody(event: any) {
    this.props.actionCustomerFiture.changeSmsDeliveryBody(event.target.value);
    if (event.target.value === '') {
      this.setState({
        validateSmsDeliveryBody: 'error',
      });
    } else {
      this.setState({
        validateSmsDeliveryBody: '',
      });
    }
  }
  handleSmsDeliveredBody(event: any) {
    this.props.actionCustomerFiture.changeSmsDeliveredBody(event.target.value);
    if (event.target.value === '') {
      this.setState({
        validateSmsDeliveredBody: 'error',
      });
    } else {
      this.setState({
        validateSmsDeliveredBody: '',
      });
    }
  }
  handleSubmitFiture() {
    if (this.props.GetTypeFiture === 'Add') {
      this.props.actionCustomerFiture.changeAddCustomerFiture();
    } else {
      this.props.actionCustomerFiture.changeEditCustomerFiture();
    }
  }
  handleEditFiture() {
    this.props.actionCustomerFiture.changeTypeFiture('Edit');
  }
  handleCancel() {
    this.props.actionCustomerFiture.changeDetailCustomerFiture();
    this.setState({
      validateSenderAlias: null,
      validateSmsDeliveryBody: null,
      validateSmsDeliveredBody: null,
    });
  }
  componentWillMount() {
    this.props.actionCustomerFiture.changeDetailCustomerFiture();
  }
  render() {
    // const DisableDelivery = this.props.GetTypeFiture==="Detail"?true:this.props.GetTypeFiture==="Edit"&&this.props.GetSmsDelivery===true?false:true

    // const DisableFormSmsDeliveryBody = this.props.GetTypeFiture==="Detail"?true:this.props.GetTypeFiture==="Edit"&&
    // this.props.GetSmsDelivery===true?false:this.props.GetTypeFiture==="Add"&&
    // this.props.GetSmsDelivery===true?false:true

    const DeliveryBodyEmpty =
      this.props.GetSmsDeliveryBody === '' ||
      this.props.GetSmsDeliveryBody === null ||
      this.props.GetSmsDeliveryBody === undefined
        ? 'DeliveryBodyEmpty'
        : 'DeliveryBodyNotEmpty';

    const DeliveredBodyEmpty =
      this.props.GetSmsDeliveredBody === '' ||
      this.props.GetSmsDeliveredBody === null ||
      this.props.GetSmsDeliveredBody === undefined
        ? 'DeliveredBodyEmpty'
        : 'DeliveredBodyNotEmpty';

    const SenderAliasEmpty =
      this.props.GetSenderAlias === '' ||
      this.props.GetSenderAlias === null ||
      this.props.GetSenderAlias === undefined
        ? 'SenderAliasEmpty'
        : 'SenderAliasNotEmpty';

    return (
      <div>
        <CustomerConfigFitureComponent
          ValidateButton={
            (this.props.GetSmsDelivery === true &&
              DeliveryBodyEmpty === 'DeliveryBodyEmpty') ||
            (this.props.GetSmsDelivered === true &&
              DeliveredBodyEmpty === 'DeliveredBodyEmpty') ||
            SenderAliasEmpty === 'SenderAliasEmpty' ||
            this.props.GetTypeFiture === 'Detail'
              ? true
              : false
          }
          handleCheckboxSmsDelivery={this.handleCheckboxSmsDelivery}
          handleCheckboxSmsDelivered={this.handleCheckboxSmsDelivered}
          handleCheckboxTelepon={this.handleCheckboxTelepon}
          putSenderAlias={this.handleSenderAlias}
          putSmsDeliveryBody={this.handleSmsDeliveryBody}
          putSmsDeliveredBody={this.handleSmsDeliveredBody}
          messageDeliverySms={
            this.props.GetTypeFiture === 'Detail'
              ? true
              : this.props.GetTypeFiture === 'Edit' &&
                this.props.GetSmsDelivery === true
              ? false
              : this.props.GetTypeFiture === 'Add' &&
                this.props.GetSmsDelivery === true
              ? false
              : true
          }
          messageDeliveredSms={
            this.props.GetTypeFiture === 'Detail'
              ? true
              : this.props.GetTypeFiture === 'Edit' &&
                this.props.GetSmsDelivered === true
              ? false
              : this.props.GetTypeFiture === 'Add' &&
                this.props.GetSmsDelivered === true
              ? false
              : true
          }
          senderAliasStatus={
            this.props.GetTypeFiture === 'Edit' ||
            this.props.GetTypeFiture === 'Add'
              ? false
              : true
          }
          GetSmsDelivery={this.props.GetSmsDelivery}
          GetSmsDelivered={this.props.GetSmsDelivered}
          GetSenderAlias={this.props.GetSenderAlias}
          GetSmsDeliveryBody={this.props.GetSmsDeliveryBody}
          GetSmsDeliveredBody={this.props.GetSmsDeliveredBody}
          GetCall={this.props.GetCall}
          IsRadius={this.props.IsRadius}
          IsRequestPhone={this.props.IsRequestPhone}
          IsHistory={this.props.IsHistory}
          RadiusMeters={this.props.RadiusMeters}
          // CONFIG CUSTOMER
          GetCustomerName={this.props.GetCustomerName}
          typeButton={this.props.GetTypeFiture}
          handleSubmitFiture={this.handleSubmitFiture}
          handleEditFiture={this.handleEditFiture}
          validateSenderAlias={
            this.state.validateSenderAlias === 'error' ? 'error' : ''
          }
          helpSenderAlias={
            this.state.validateSenderAlias === 'error'
              ? 'Nama masking tidak boleh kosong!'
              : ''
          }
          validateSmsDeliveryBody={
            this.state.validateSmsDeliveryBody === 'error' &&
            this.props.GetSmsDelivery === false
              ? ''
              : this.state.validateSmsDeliveryBody === '' &&
                this.props.GetSmsDelivery === true
              ? ''
              : this.state.validateSmsDeliveryBody === 'error' &&
                this.props.GetSmsDelivery === true
              ? 'error'
              : ''
          }
          helpSmsDeliveryBody={
            this.state.validateSmsDeliveryBody === 'error' &&
            this.props.GetSmsDelivery === false
              ? ''
              : this.state.validateSmsDeliveryBody === '' &&
                this.props.GetSmsDelivery === true
              ? ''
              : this.state.validateSmsDeliveryBody === 'error' &&
                this.props.GetSmsDelivery === true
              ? 'Pesan pengiriman tidak boleh kosong'
              : ''
          }
          validateSmsDeliveredBody={
            this.state.validateSmsDeliveredBody === 'error' &&
            this.props.GetSmsDelivered === false
              ? ''
              : this.state.validateSmsDeliveredBody === '' &&
                this.props.GetSmsDelivered === true
              ? ''
              : this.state.validateSmsDeliveredBody === 'error' &&
                this.props.GetSmsDelivered === true
              ? 'error'
              : ''
          }
          helpSmsDeliveredBody={
            this.state.validateSmsDeliveredBody === 'error' &&
            this.props.GetSmsDelivered === false
              ? ''
              : this.state.validateSmsDeliveredBody === '' &&
                this.props.GetSmsDelivered === true
              ? ''
              : this.state.validateSmsDeliveredBody === 'error' &&
                this.props.GetSmsDelivered === true
              ? 'Pesan pengiriman tidak boleh kosong'
              : ''
          }
          handleBack={this.handleBack}
          handleCancel={this.handleCancel}
          isLoading={this.props.isLoading}
          handleChangeRadiusMeters={this.handleChangeRadiusMeters}
          handleCheckboxIsRadius={this.handleCheckboxIsRadius}
          handleCheckboxIsRequestPhone={this.handleCheckboxIsRequestPhone}
          handleCheckboxIsHistory={this.handleCheckboxIsHistory}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  GetTypeFiture: state.Customer.CustomerFiture.TypeFiture,
  GetDisableFormSmsDeliveryBody:
    state.Customer.CustomerFiture.DisableFormSmsDeliveryBody,
  GetIdFiture: state.Customer.CustomerFiture.IdFiture,
  GetSmsDelivery: state.Customer.CustomerFiture.SmsDelivery,
  GetSmsDelivered: state.Customer.CustomerFiture.SmsDelivered,
  GetCall: state.Customer.CustomerFiture.Call,
  IsRadius: state.Customer.CustomerFiture.IsRadius,
  IsRequestPhone: state.Customer.CustomerFiture.IsRequestPhone,
  IsHistory: state.Customer.CustomerFiture.IsHistory,
  RadiusMeters: state.Customer.CustomerFiture.RadiusMeters,
  GetSenderAlias: state.Customer.CustomerFiture.SenderAlias,
  GetSmsDeliveryBody: state.Customer.CustomerFiture.SmsDeliveryBody,
  GetSmsDeliveredBody: state.Customer.CustomerFiture.SmsDeliveredBody,
  GetCustomerName: state.Customer.DetailCustomer.CustomerName,
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
  actionCustomerFiture: bindActionCreators(ActionCustomerFiture, dispatch),
  actionNavigation: bindActionCreators(ActionNavigation, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CustomerConfigCustomFitureContainer);
