export function changeAdminName(AdminName: any) {
  return {
    type: 'CHANGE_ADMIN_NAME_FORM',
    AdminName,
  };
}
export function changeAdminAccountType(AccountType: any) {
  return {
    type: 'CHANGE_ADMIN_ACCOUNT_TYPE_FORM',
    AccountType,
  };
}
export function changeAdminLastName(AdminLastName: any) {
  return {
    type: 'CHANGE_ADMIN_LAST_NAME_FORM',
    AdminLastName,
  };
}
export function changeAdminEmail(AdminEmail: any) {
  return {
    type: 'CHANGE_ADMIN_EMAIL_FORM',
    AdminEmail,
  };
}
export function changeAdminPhoneNumber(AdminPhoneNumber: any) {
  return {
    type: 'CHANGE_ADMIN_PHONE_NUMBER_FORM',
    AdminPhoneNumber,
  };
}
export function changeAdminDob(AdminDob: any) {
  return {
    type: 'CHANGE_ADMIN_DOB_FORM',
    AdminDob,
  };
}
export function changeAdminAddress(AdminAddress: any) {
  return {
    type: 'CHANGE_ADMIN_ADDRESS_FORM',
    AdminAddress,
  };
}
export function changeAdminPhoto(AdminPhoto: any) {
  return {
    type: 'CHANGE_ADMIN_PHOTO_FORM',
    AdminPhoto,
  };
}
export function changeAdminCode(Code: any) {
  return {
    type: 'CHANGE_ADMIN_CODE_FORM',
    Code,
  };
}
export function changeAdminRolePermission(RolePermission: any) {
  return {
    type: 'CHANGE_ADMIN_ROLE_FORM',
    RolePermission,
  };
}
export function changeAdminStatus(IsActive: any) {
  return {
    type: 'CHANGE_ADMIN_STATUS_FORM',
    IsActive,
  };
}

export function changeAdminUsername(Username: any) {
  return {
    type: 'CHANGE_ADMIN_USERNAME_FORM',
    Username,
  };
}

export function changeClearDataAdminForm() {
  return {
    type: 'CHANGE_CLEAR_DATA_ADMIN_FORM',
  };
}
