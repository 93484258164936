export function setServerVoipId(Id: any) {
  return {
    type: 'SET_SERVER_VOIP_ID',
    Id,
  };
}
export function setServerVoipName(Name: any) {
  return {
    type: 'SET_SERVER_VOIP_NAME',
    Name,
  };
}
export function setServerVoipIpServer1(IpServer1: any) {
  return {
    type: 'SET_SERVER_VOIP_IP_SERVER_1',
    IpServer1,
  };
}
export function setServerVoipIpServer2(IpServer2: any) {
  return {
    type: 'SET_SERVER_VOIP_IP_SERVER_2',
    IpServer2,
  };
}
export function setServerVoipIpServer3(IpServer3: any) {
  return {
    type: 'SET_SERVER_VOIP_IP_SERVER_3',
    IpServer3,
  };
}
export function setServerVoipIpServer4(IpServer4: any) {
  return {
    type: 'SET_SERVER_VOIP_IP_SERVER_4',
    IpServer4,
  };
}
export function setServerVoipIpPort(IpPort: any) {
  return {
    type: 'SET_SERVER_VOIP_IP_PORT',
    IpPort,
  };
}
export function setServerVoipKuota(Kuota: any) {
  return {
    type: 'SET_SERVER_VOIP_KUOTA',
    Kuota,
  };
}
export function setServerVoipPosition(Position: any) {
  return {
    type: 'SET_SERVER_VOIP_POSITION',
    Position,
  };
}
export function setServerVoipToggle(ToggleServer: any) {
  return {
    type: 'SET_SERVER_VOIP_TOGGLE',
    ToggleServer,
  };
}
export function setListServerVoip(ListServerVoip: any) {
  return {
    type: 'SET_LIST_SERVER_VOIP',
    ListServerVoip,
  };
}
export function getListServerVoip() {
  return {
    type: 'GET_LIST_SERVER_VOIP',
  };
}
export function submitAddServerVoip() {
  return {
    type: 'SUBMIT_ADD_SERVER_VOIP',
  };
}
export function submitDetailServerVoip() {
  return {
    type: 'SUBMIT_DETAIL_SERVER_VOIP',
  };
}
export function submitEditServerVoip() {
  return {
    type: 'SUBMIT_EDIT_SERVER_VOIP',
  };
}
export function submitCheckServerVoip() {
  return {
    type: 'SUBMIT_CHECK_SERVER_VOIP',
  };
}
export function submitSearchServerVoip() {
  return {
    type: 'SUBMIT_SEARCH_SERVER_VOIP',
  };
}
export function changeClearAllFormServerVoip() {
  return {
    type: 'CLEAR_ALL_FORM_SERVER_VOIP',
  };
}
export function changeClearListServerVoip() {
  return {
    type: 'CLEAR_LIST_SERVER_VOIP',
  };
}
