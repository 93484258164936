import { Button, Checkbox, Col, Icon, Input, Radio, Row, Switch } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SixWaybill from '../../../../image/6Waybill1Page.png';
import ThreeWaybill from '../../../../image/3Waybill1Page.png';

interface IProps {
  visible: any;
  jenisToggle: any;
  isLoading: any;
  handleClearModalForm: any;
  handlePutWaybill: any;
  handleSwitch: any;
  handleDownloadMultipleWaybill: any;
  getSwitchValue: any;
  getDeliveryDate: any;
  getWaybill: any;
  getFileData: any;
  fileName: any;
  ValidateWaybill: any;
  validateButtonDownload: any;
  helpValidateWaybill: any;
  handleConvert: any;
  handleDownloadTemplate: any;
  handleDownloadByWaybill: any;
  handlePutPrintType: any;
  handlePutSeparatorAddress: any;
  handlePutWatermark: any;
  handlePutNotelp: any;
  handlePutCodeBrankas: any;
  getWatermark: any;
  getNotelp: any;
  getCodeBrankas: any;
  waybillPrintType: any;
  getSeparatorAddress: any;
}

export default class FormPrintByWaybillComponent extends Component<IProps> {
  render() {
    return (
      <React.Fragment>
        <ModalMaterial
          className={''}
          width={'40%'}
          style={{}}
          teksTitle={'Cetak Berdasarkan waybill'}
          visible={this.props.visible === true ? true : false}
          onCancel={this.props.handleClearModalForm}
          content={
            <div style={{ padding: '25px' }}>
              <div style={{ paddingBottom: '20px' }}>
                <Switch
                  checked={this.props.getSwitchValue}
                  onChange={this.props.handleSwitch}
                />
                <span style={{ paddingLeft: '15px', fontStyle: 'italic' }}>
                  Tambahkan dengan template
                </span>
              </div>
              {this.props.getSwitchValue !== true ? (
                <div>
                  <Radio.Group
                    onChange={this.props.handlePutPrintType}
                    value={this.props.waybillPrintType}
                    style={{ marginBottom: 10 }}
                  >
                    <Row>
                      <Col span={12}>
                        <Row>
                          <img
                            src={ThreeWaybill}
                            alt="Three Waybill"
                            style={{ width: '100px', marginLeft: '90px' }}
                          />
                        </Row>
                        <Row>
                          <Radio
                            value={'3Waybill'}
                            style={{ textAlign: 'center', paddingLeft: '80px' }}
                          >
                            <br />3 Waybill / halaman
                          </Radio>
                        </Row>
                      </Col>
                      <Col span={12}>
                        <Row>
                          <img
                            src={SixWaybill}
                            alt="Six Waybill"
                            style={{ width: '100px', marginLeft: '90px' }}
                          />
                        </Row>
                        <Row>
                          <Radio
                            value={'6Waybill'}
                            style={{ textAlign: 'center', paddingLeft: '80px' }}
                          >
                            <br />6 Waybill / halaman
                          </Radio>
                        </Row>
                      </Col>
                    </Row>
                  </Radio.Group>
                  <FormInputTextMaterial
                    validateType={true}
                    value={this.props.getWaybill}
                    className={''}
                    disabled={false}
                    addonBefore={''}
                    placeholder={'Masukan waybill'}
                    prefix={''}
                    maxLength={100}
                    style={{ borderRadius: '10px' }}
                    size=""
                    type={''}
                    onChange={this.props.handlePutWaybill}
                    onBlur={this.props.handlePutWaybill}
                    validatestatus={this.props.ValidateWaybill}
                    help={this.props.helpValidateWaybill}
                    onPressEnter={''}
                  />
                </div>
              ) : (
                <React.Fragment>
                  <span>
                    <b>Unggah CSV</b>
                  </span>
                  <div
                    className="fileUploadInput"
                    style={{ paddingBottom: '20px', paddingTop: '20px' }}
                  >
                    <Input
                      onChange={this.props.handleConvert}
                      size="large"
                      value={this.props?.fileName}
                      suffix={<Icon type="upload" />}
                      multiple={false}
                      type="file"
                      accept=".csv"
                    />
                  </div>
                  <div>
                    <span>
                      <Button
                        style={{
                          fontStyle: 'italic',
                          textDecoration: 'underline',
                        }}
                        type="link"
                        onClick={this.props.handleDownloadTemplate}
                      >
                        Download sample file
                      </Button>
                    </span>
                  </div>
                </React.Fragment>
              )}
              <div style={{ float: 'right' }}>
                <ButtonMaterial
                  disabled={this.props.validateButtonDownload}
                  handleSubmit={
                    this.props.getSwitchValue === false
                      ? this.props.handleDownloadByWaybill
                      : this.props.handleDownloadMultipleWaybill
                  }
                  teksButton={<b>Download</b>}
                  size={'middle'}
                  shape={'round'}
                  className={''}
                  type={'primary'}
                  icon={''}
                  style={
                    this.props.validateButtonDownload === true
                      ? {
                          float: 'right',
                          height: '35px',
                          width: '100px',
                        }
                      : {
                          background: '#7956EE',
                          borderColor: '#7956EE',
                          float: 'right',
                          height: '35px',
                          width: '100px',
                          color: '#FFFFFF',
                        }
                  }
                />
                <Checkbox
                  onChange={this.props.handlePutCodeBrankas}
                  style={{
                    paddingLeft: 5,
                    marginBottom: 20,
                    marginTop: 5,
                  }}
                  checked={this.props.getCodeBrankas}
                />
                <span className={'checkboxSpanSeparatorAddress'}>
                  Kode Brankas
                </span>
                <Checkbox
                  onChange={this.props.handlePutNotelp}
                  style={{
                    paddingLeft: 5,
                    marginBottom: 20,
                    marginTop: 5,
                  }}
                  checked={this.props.getNotelp}
                />
                <span className={'checkboxSpanSeparatorAddress'}>No. Telp</span>
                <Checkbox
                  onChange={this.props.handlePutSeparatorAddress}
                  style={{
                    paddingLeft: 5,
                    marginBottom: 20,
                    marginTop: 5,
                  }}
                  checked={this.props.getSeparatorAddress}
                />
                <span className={'checkboxSpanSeparatorAddress'}>
                  Pisah Alamat
                </span>
              </div>
            </div>
          }
        />
      </React.Fragment>
    );
  }
}
