import * as ActionFormPrintWaybill from '../../../../core/action/Delivery/PrintWaybill/ActionFormPrintWaybill';
import * as ActionListCustomer from '../../../../core/action/Customer/ActionListCustomer';
import * as ActionListCustomerProduct from '../../../../core/action/CustomerProduct/ActionListCustomerProduct';
import * as Branch from '../../../../core/action/Branch/ActionAllBranch';
import * as toggle from '../../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import FormPrintWaybillComponent from '../../../../components/Content/Delivery/Document/PrintWaybill/FormPrintWaybillComponent';
import { connect } from 'react-redux';
import moment from 'moment'

interface IProps
{
    getUploadDate:any
    RadioButton: any
    BranchId:any
    CustomerId: any
    CustomerProductId: any
    listBranch: any;
    listCustomer: any
    listCustomerProduct: any
    actionFormPrintWaybill:any
    actionToggle:any
    actionBranch: any;
    actionListCustomer: any;
    actionListCustomerProduct: any;
    LoadingSelectBranch: any;
    LoadingSelectCustomer: any
    LoadingSelectCustomerProduct: any
}

interface IState
{
    ValidateUploadDate:any
    ValidateBranch:any
    ValidateCustomer: any;
    ValidateCustomerProduct: any;
    valueUploadDate: any;
}

class FormPrintWaybillContainer extends Component<IProps, IState>
{
    constructor(props:any)
    {
        super(props)
        this.state=
        {
            ValidateUploadDate:null,
            ValidateBranch:null,
            ValidateCustomer:null,
            ValidateCustomerProduct:null,
            valueUploadDate:null,
        }

        this.handleValidateUploadDate =this.handleValidateUploadDate.bind(this)
        this.handlePutUploadDate =this.handlePutUploadDate.bind(this)
        this.handleChangeRadioButton = this.handleChangeRadioButton.bind(this);
        this.handleGetBranch = this.handleGetBranch.bind(this);
        this.handlePutBranch = this.handlePutBranch.bind(this);
        this.handleBlurBranch = this.handleBlurBranch.bind(this);
        this.handleResetBranch = this.handleResetBranch.bind(this);
        this.handleResetCustomer = this.handleResetCustomer.bind(this);
        this.handlePutCustomer = this.handlePutCustomer.bind(this);
        this.handlePutCustomerProduct = this.handlePutCustomerProduct.bind(this);
        this.handleBlurCustomer = this.handleBlurCustomer.bind(this);
        this.handleBlurCustomerProduct = this.handleBlurCustomerProduct.bind(this);
        this.handleGetCustomer = this.handleGetCustomer.bind(this);
        this.handleGetCustomerProduct = this.handleGetCustomerProduct.bind(this);
        this.handleModalWaybillPrintType =this.handleModalWaybillPrintType.bind(this)
        this.handleModal =this.handleModal.bind(this)
        this.handleReset =this.handleReset.bind(this)
        this.handleSearch =this.handleSearch.bind(this)
    }

    handleValidateUploadDate(event:any)
    {
        if(event===true){
            if(this.props.getUploadDate===null||this.props.getUploadDate===undefined||this.props.getUploadDate==="Invalid date"){
                this.setState({
                    ValidateUploadDate:"error",
                })
            }else{
                this.setState({
                    ValidateUploadDate:null
                })
            }
        }else{
            
        }
    }
    handlePutUploadDate(event:any)
    {
      if(event){
        const dateValue = event._d?(moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')):event
        this.props.actionFormPrintWaybill.changeUploadDatePW(dateValue)
        
      }else{
        this.props.actionFormPrintWaybill.changeUploadDatePW(null)
      }
        this.setState({
          valueUploadDate:event
      })
        if(event===""){
            this.setState({
                ValidateUploadDate:"error",
            })
        }else{
            this.setState({
                ValidateUploadDate:null
            })
        }
    }
    handleChangeRadioButton(event: any) {
      this.props.actionFormPrintWaybill.changeRadioButtonPW(event.target.value);
    }
    handleGetBranch() {
        this.props.actionBranch.getAllBranch();
    }
    
    handlePutBranch(event: any) {
        this.props.actionFormPrintWaybill.changeBranchIdPW(event);
        if (event === null) {
          this.setState({
            ValidateBranch: 'error',
          });
        } else {
          this.setState({
            ValidateBranch: null,
          });
        }
    }
    
    handleBlurBranch() {
        if (this.props.BranchId === null) {
          this.setState({
            ValidateBranch: 'error',
          });
        } else {
          this.setState({
            ValidateBranch: null,
          });
        }
    }
    
    handleResetBranch() {
        // this.props.actionFormPrintWaybill.changeBranchIdPW(null);
    }

    handleResetCustomer() {
        this.props.actionListCustomerProduct.clearListCustomerProduct();
        this.props.actionFormPrintWaybill.clearCustomerProduct();
        this.setState({
          ValidateCustomerProduct: null,
        });
    }
    
    handleGetCustomer() {
        this.props.actionListCustomer.getListCustomer();
    }
    
    handlePutCustomer(event: any) {
        this.props.actionFormPrintWaybill.changeCustomerIdPW(event);
        if (event === null) {
          this.setState({
            ValidateCustomer: 'error',
          });
        } else {
          this.setState({
            ValidateCustomer: null,
          });
        }
    }
    
    handleBlurCustomer() {
        if (this.props.CustomerId === null) {
          this.setState({
            ValidateCustomer: 'error',
          });
        } else {
          this.setState({
            ValidateCustomer: null,
          });
        }
    }
    
    handleGetCustomerProduct() {
        this.props.actionListCustomerProduct.getListCustomerProductNoType();
    }
    
    handlePutCustomerProduct(event: any) {
        const searchSelectAll = event.toString().search('SetAllProduct');
        if (searchSelectAll >= 0) {
          this.props.actionFormPrintWaybill.changeCustomerProductIdPW(
            this.props.listCustomerProduct.map((data: any, index: any) => data.id)
          );
        } else {
          this.props.actionFormPrintWaybill.changeCustomerProductIdPW(
            event
          );
        }
    
        if (
          this.props.CustomerProductId === null ||
          this.props.CustomerProductId === [] ||
          event.length === 0
        ) {
          this.setState({
            ValidateCustomerProduct: 'error',
          });
        } else {
          this.setState({
            ValidateCustomerProduct: null,
          });
        }
    }
    
    handleBlurCustomerProduct() {
        if (
          this.props.CustomerProductId.length === undefined ||
          this.props.CustomerProductId.length < 0
        ) {
          this.setState({
            ValidateCustomerProduct: 'error',
          });
        } else {
          this.setState({
            ValidateCustomerProduct: null,
          });
        }
    }

    handleModal()
    {
        this.props.actionToggle.changeModal()
        this.props.actionToggle.changeJenisToggle("PrintByWaybill")
    }
    
    handleModalWaybillPrintType()
    {
        this.props.actionToggle.changeModal()
        this.props.actionToggle.changeJenisToggle("SelectWaybillPrintType")
    }
    handleSearch()
    {
      this.props.actionFormPrintWaybill.getListDataPrintWaybill()
    }
    handleReset() {
      this.props.actionFormPrintWaybill.ClearPrintWaybillFormPW();
      this.setState({
        valueUploadDate:null
      })
    }

    componentDidMount() {
        this.props.actionFormPrintWaybill.ClearPrintWaybillFormPW();
    }

    render(){
        
        return(
            <div>
                <FormPrintWaybillComponent
                handleValidateUploadDate={this.handleValidateUploadDate}
                getUploadDate={this.props.getUploadDate}
                RadioButton={this.props.RadioButton}
                IdBranch={this.props.BranchId}
                IdCustomer={this.props.CustomerId}
                IdCustomerProduct={this.props.CustomerProductId}
                listBranch={this.props.listBranch}
                listCustomer={this.props.listCustomer}
                listCustomerProduct={this.props.listCustomerProduct}
                loadingSelectBranch={this.props.LoadingSelectBranch}
                loadingSelectCustomer={this.props.LoadingSelectCustomer}
                loadingSelectCustomerProduct={this.props.LoadingSelectCustomerProduct}
                handleChangeRadioButton={this.handleChangeRadioButton}
                handleBlurBranch={this.handleBlurBranch}
                handleGetBranch={this.handleGetBranch}
                handlePutBranch={this.handlePutBranch}
                handleResetBranch={this.handleResetBranch}
                handleResetCustomer={this.handleResetCustomer}
                handlePutCustomer={this.handlePutCustomer}
                handleBlurCustomer={this.handleBlurCustomer}
                handleGetCustomer={this.handleGetCustomer}
                handleGetCustomerProduct={this.handleGetCustomerProduct}
                handleBlurCustomerProduct={this.handleBlurCustomerProduct}
                handlePutCustomerProduct={this.handlePutCustomerProduct}
                validateCustomerProduct={
                    this.state.ValidateCustomerProduct === 'error' ? 'error' : ''
                }
                helpValidateCustomerProduct={
                    this.state.ValidateCustomerProduct === 'error'
                      ? 'Produk pelanggan wajib diisi'
                      : ''
                }
                handlePutUploadDate={this.handlePutUploadDate}
                ValidateUploadDate={this.state.ValidateUploadDate==="error"?"error":""}
                helpValidateUploadDate={this.state.ValidateUploadDate==="error"?"Tanggal wajib diisi":""}
                handleModalWaybillPrintType={this.handleModalWaybillPrintType}
                handleModal={this.handleModal}
                handleReset={this.handleReset}
                handleSearch={this.handleSearch}
                valueUploadDate={this.state.valueUploadDate}
                />
            </div>
        )
    }
}
const mapStateToProps = (state:any)=> ({
    getUploadDate:state.PrintWaybill.FormPrintWaybill.UploadDate,
    RadioButton:state.PrintWaybill.FormPrintWaybill.RadioButton,
    BranchId:state.PrintWaybill.FormPrintWaybill.BranchId,
    CustomerId:state.PrintWaybill.FormPrintWaybill.CustomerId,
    CustomerProductId:state.PrintWaybill.FormPrintWaybill.CustomerProductId,

    listBranch: state.Branch.AllBranch,
    listCustomer: state.Customer.ListCustomer,
    listCustomerProduct: state.CustomerProduct.ListCustomerProduct,
    LoadingSelectCustomer: state.Customer.PropertiesCustomer.LoadingCustomer,
    LoadingSelectCustomerProduct:
      state.CustomerProduct.PropertiesCustomerProduct.LoadingCustomerProduct,
    LoadingSelectBranch: state.Branch.PropertiesBranch.LoadingBranch,
    TypeLoadingSelectBranch: state.Branch.PropertiesBranch.TypeLoadingBranch,
})

const mapDispatchToProps = (dispatch:any) => ({
    actionFormPrintWaybill : bindActionCreators(ActionFormPrintWaybill, dispatch),
    actionBranch: bindActionCreators(Branch, dispatch),
    actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
    actionListCustomerProduct: bindActionCreators(
        ActionListCustomerProduct,
        dispatch
    ),
    actionToggle : bindActionCreators(toggle, dispatch),
})

const withConnect = connect (mapStateToProps, mapDispatchToProps)

export default compose(
    withConnect
)(FormPrintWaybillContainer)