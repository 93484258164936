import * as ActionAuth from '../../action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as ListManualProcess from '../../action/ManualProcess/ActionListMprocess';
import * as ManualProcess from '../../action/ManualProcess/ActionManualProcess';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_submitManualProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const WaybillMprocess = state.ManualProcess.ManualProcessForm.WaybillMprocess;
  const StatusMprocess =
    state.ManualProcess.ManualProcessForm.RadiobuttonStatusMprocess;
  const IdCourierMprocess =
    state.ManualProcess.ManualProcessForm.CourierCodeMprocess;
  const IdCustomerMprocess =
    state.ManualProcess.ManualProcessForm.CustomerMprocess;
  const ReceiverNameMprocess =
    state.ManualProcess.ManualProcessForm.ReceiveNameMprocess;

  const ReasonManualProcess =
    state.ManualProcess.ManualProcessForm.ReasonMprocess;
  const ReasonManualProcessFreeText =
    state.ManualProcess.ManualProcessForm.ReasonOthersMprocess;
  const ReceiverTitleMprocess =
    StatusMprocess === 'Success' || StatusMprocess === 'ReturnToSuccess'
      ? ReasonManualProcess[0].desc
      : null;
  const ReturnedReasonMprocess =
    StatusMprocess === 'Return' || StatusMprocess === 'SuccessToReturn'
      ? ReasonManualProcess[0].desc
      : null;
  const ReturnedReasonId =
    StatusMprocess === 'Success' || StatusMprocess === 'ReturnToSuccess'
      ? ReasonManualProcess[0].id
      : null;

  const DateMprocess = moment(
    state.ManualProcess.ManualProcessForm.DateMprocess
  ).format('YYYY-MM-DDTHH:mm:ss.SSSz');

  const InformationMprocess1 =
    state.ManualProcess.ManualProcessForm.InformationMprocess;
  const InformationMprocess2 =
    state.ManualProcess.ManualProcessForm.InformationOthersMprocess;
  const InformationMprocess =
    InformationMprocess2 === null || InformationMprocess2 === ''
      ? InformationMprocess1
      : `Lain-lain (${InformationMprocess2})`;
  const ListSubmitMprocess = state.ManualProcess.ListSubmitManualProcess;
  const CheckBoxBarcode = state.ManualProcess.ManualProcessForm.BarcodeCheckBox;
  var DateNow = moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss.SSSz');
  const postDate = DateMprocess.substring(0, 11) + DateNow.substring(11, 25);
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/Delivery/manualUpdate`,
      {
        waybillOrBarcode: WaybillMprocess,
        barcode: CheckBoxBarcode,
        type: StatusMprocess,
        courierId: IdCourierMprocess,
        customerId: IdCustomerMprocess,
        receiverName: ReceiverNameMprocess,
        receiverTitle: ReceiverTitleMprocess,
        returnedReason: ReturnedReasonMprocess,
        deliveryNotes: ReasonManualProcessFreeText,
        packageReceivedTime: postDate,
        note: InformationMprocess,
        idCustomStatus: +ReturnedReasonId,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(
      ListManualProcess.setListSubmitMprocess([
        ...ListSubmitMprocess,
        {
          data,
          Status: 'dataFound',
          AddressArray: JSON.parse(data.shipToAddress),
        },
      ])
    );
    yield put(ManualProcess.clearWaybillForm());
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    // if (error.response.status === 401) {
    //     yield put(ActionLogin.errorHandling(action, error));
    // }else{
    //     yield put(ListManualProcess.setListSubmitMprocess([...ListSubmitMprocess, {data:{Waybill:WaybillMprocess},Status:"dataNotFound"} ]))
    //     yield put(toggle.changeLoadingFalse())
    //     const propsError=ErrorMassage("Submit proses manual")
    //     const code=propsError.filter((person:any) =>
    //                     person.Code.toString().toLowerCase().includes(error.response.status))
    //     Modal.error({
    //         title: <p style={{color:'#FF0000'}}>{
    //             code.map((data:any)=>data.TitleMassage)
    //         }</p>,
    //         content: (
    //             <div>
    //                 <p style={{color:'#C0C0C0'}}>{code.map((data:any)=>data.Massage)}</p>
    //             </div>
    //         ),
    //         onOk(){},
    //     });
    // }
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_submitManualProcess() {
  yield takeLatest('SUBMIT_MANUAL_PROCESS', S_submitManualProcess);
}
