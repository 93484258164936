import { DatePicker, Form } from 'antd';

import React from 'react';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

const formatDate = 'YYYY-MM-DD';

interface IPropsDatePicker {
  input?;
  meta?;
  placeholder?;
  idComponent?;
  icon?;
  label?;
  data?;
  disabled?;
  typeDate?;
  disabledDate?;
  formClassName?;
  formatInput?;
  formatPicker?;
  addtionalLabel?;
  className?;
  update?;
  labelIsRequired?;
  additionalHandleChangeFunc?;
}

const convertToMoment: any = val => {
  return moment(val);
  // moment(val).format('YYYY-MM-DD')
};

const processDateRangePicker = params => {
  if (moment.isMoment(params)) {
    return params;
  } else if (params) {
    return convertToMoment(params);
  } else {
    return undefined;
  }
};

const handleDefaultValue = (inputValue, typeDate) => {
  if (inputValue) {
    if (typeDate === 'dateRangePicker') {
      if (typeof inputValue === 'object') {
        const startDate = processDateRangePicker(inputValue[0]);
        const endDate = processDateRangePicker(inputValue[1]);
        return [startDate, endDate];
      }
    } else {
      // console.log('isi neeh ', moment.isMoment(inputValue));
      // console.log('isi yuk ', convertToMoment(inputValue));

      // if (moment.isMoment(inputValue)) {
      //   console.log('yuhu', inputValue);

      //   return inputValue;
      // } else {
      // console.log('hadeh', inputValue);
      return convertToMoment(inputValue);
      // }
    }
  }
  return undefined;
};

export const InputDatePicker = ({
  input,
  meta: { touched, error },
  idComponent,
  label,
  disabled,
  typeDate,
  disabledDate,
  formClassName,
  formatInput,
  formatPicker,
  placeholder,
  addtionalLabel,
  className,
  update,
  labelIsRequired,
  additionalHandleChangeFunc,
}: IPropsDatePicker) => {
  const handleOnChange = e => {
    if (additionalHandleChangeFunc) {
      additionalHandleChangeFunc(e);
    }
    input.onChange(e);
  };
  const lebelRequired = () => {
    if (labelIsRequired) {
      return <span className="labelRequired">*</span>;
    }
    return null;
  };

  const labelTitle = (
    <span>
      <b className="capital">{label}</b>
      <span style={{ color: '#BFBFBF' }}>
        {addtionalLabel ? ` ${addtionalLabel}` : null}
        {lebelRequired()}
      </span>
    </span>
  );
  const inputValue = input.value;
  const renderPicker = () => {
    if ((update && typeof inputValue === 'object') || !update) {
      if (typeDate === 'datePicker') {
        return (
          <DatePicker
            style={{ width: '100%' }}
            locale={locale}
            onChange={handleOnChange}
            onBlur={input.onBlur}
            id={idComponent}
            format={formatInput ? formatInput : formatDate}
            value={handleDefaultValue(inputValue, typeDate)}
            disabled={disabled}
            disabledDate={disabledDate}
            picker={formatPicker}
            placeholder={placeholder}
            className={className}
          />
        );
      } else if (typeDate === 'dateRangePicker') {
        return (
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            onChange={handleOnChange}
            id={idComponent}
            format={formatInput ? formatInput : formatDate}
            value={handleDefaultValue(inputValue, typeDate)}
            disabled={disabled}
            disabledDate={disabledDate}
            picker={formatPicker}
            placeholder={placeholder}
            className={className}
          />
        );
      } else if (typeDate === 'monthPicker') {
        return (
          <DatePicker.MonthPicker
            style={{ width: '100%' }}
            onChange={handleOnChange}
            onBlur={input.onBlur}
            id={idComponent}
            disabled={disabled}
            disabledDate={disabledDate}
          />
        );
      }
      return null;
    }
    return null;
  };
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      className={formClassName}
    >
      {renderPicker()}
    </Form.Item>
  );
};
