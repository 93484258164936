import * as ActionAuth from '../../action/Auth';
import * as ActionComplaint from '../../action/Complaint/ActionFormComplaint';
import * as ActionLogin from '../../../module/Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_generateTicketCode(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Complaint/GenerateTiket',
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComplaint.changeTicketCode(data));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_generateTicketCode() {
  yield takeLatest('GENERATE_TICKET_CODE', S_generateTicketCode);
}
