export function getDataScan()
{
    return({
        type:"GET_DATA_SCAN"
    })
}
export function setListData(ListData:any)
{
    return({
        type:"SET_LIST_DATA_UPLOAD",
        ListData
    })
}
export function clearListData()
{
    return({
        type:"CLEAR_LIST_DATA_UPLOAD"
    })
}
export function clearFileUploadDate()
{
    return({
        type:"CLEAR_FILE_UPLOAD_DATE"
    })
}
export function setFileUploadDate(FileUploadDate:any)
{
    return({
        type:"SET_FILE_UPLOAD_DATE",
        FileUploadDate
    })
}