import instance from 'config/interceptor';

export const getProfileBranch = async params => {
  try {
    const { page = 0, isActive = 'Active', size = 10, name } = params[1];
    let link = `/Profile/Account/${isActive}/${page}/${size}`;
    if (name) link = link.concat(`?name=${name}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getDetailAdmin = async (id: any) => {
  try {
    return await instance.get(
      process.env.REACT_APP_URL + '/Profile/Account/' + id
    );
  } catch (error) {
    return error;
  }
};

export const postAdminBranch = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + '/Profile/Account',
      data
    );
  } catch (error) {
    return error;
  }
};

export const putAdminBranch = async data => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + `/Profile/${data.id}`,
      data
    );
  } catch (error) {
    return error;
  }
};
