import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../../action/toggle';

import axios from 'axios';
import moment from 'moment';

export function* S_downloadCourierBonusReport(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const key = 'download';
  const FirstDate = moment(
    state.Report.CourierBonusReportForm.FirstDate
  ).format('YYYY-MM-DD');
  const LastDate = moment(state.Report.CourierBonusReportForm.LastDate).format(
    'YYYY-MM-DD'
  );

  const TypeReportBonus = state.Report.CourierBonusReportForm.RadioButton;
  const Branch = state.Report.CourierBonusReportForm.Branch;
  const Courier = state.Report.CourierBonusReportForm.Courier;
  const TypeReportBonusName =
    TypeReportBonus === 'Detail' ? 'detail' : 'rekapitulasi';
  const TypeFileName1 = `Laporan Bonus ${TypeReportBonusName} ${FirstDate} ${LastDate}.xls`;
  const TypeFileName2 = `Laporan Bonus ${TypeReportBonusName} ${FirstDate} ${LastDate}.xls`;
  const file_name2 =
    TypeReportBonus === 'Detail' ? TypeFileName2 : TypeFileName1;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = file_name2;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }

  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Courier/DownloadRekapBonus',
      {
        startDate: FirstDate,
        endDate: LastDate,
        typeReportBonus:
          TypeReportBonus === 'Detail' ? 'detail' : 'rekapitulasi',
        branch: Branch,
        courier: Courier,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(changeLoadingFalse());
    if (error.response.data.Message === 'No delivery found!') {
      message.warning({
        content: 'Laporan Tidak Tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_downloadCourierBonusReport() {
  yield takeLatest(
    'DOWNLOAD_REPORT_COURIER_BONUS',
    S_downloadCourierBonusReport
  );
}
