import * as ActionAuth from '../../../action/Auth';
import * as ActionFormPrintWaybill from '../../../action/Delivery/PrintWaybill/ActionFormPrintWaybill';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_submitDeliveryDate(action: any) {
  const state = yield select();
  const DeliveryDate = state.PrintWaybill.FormPrintWaybill.DeliveryDate.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  }).add(7, 'hours');
  const FileData = state.PrintWaybill.CheckboxId[0];
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(toggle.changeLoadingTrue());
    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/Delivery/DeliveryTime',
      {
        deliveryFileId: FileData,
        deliveryTime: DeliveryDate,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionFormPrintWaybill.ClearAllCheckbox());
    // yield put(ActionFormPrintWaybill.clearDeliveryDate())
    yield put(ActionFormPrintWaybill.getListDataPrintWaybill());
    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil Mengatur Tanggal Kirim</b>
        </p>
      ),

      onOk() {},
    });
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeModal());
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_submitDeliveryDate() {
  yield takeLatest('SUBMIT_DELIVERY_DATE_PW', S_submitDeliveryDate);
}
