import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import ServerVoipComponent from '../../../../components/Content/Voip/ServerVoip/ServerVoipComponent';
import * as ActionToggle from '../../../../core/action/toggle/index';
import * as ActionServerVoip from '../../../../core/action/Voip/Server/ActionServer';
import * as ActionPagination from '../../../../core/action/Pagination/ActionPagination';
import * as LastAccess from '../../../../core/action/LastAccess/index';

interface IProps {
  isLoading: any;
  typeToggle: any;
  actionPagination: any;
  actionLastAccess: any;
  ValueSearch: any;
  ListServerVoip: any;
  PagePagination: any;
  TotalPagePagination: any;
  actionToggle: any;
  actionServerVoip: any;
}
interface IState {
  sRT: any;
  dataSource: any;
  nameSearch: any;
  page: any;
  key: any;
}
class ServerVoipContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      sRT: null,
      dataSource: this.props.ListServerVoip,
      nameSearch: '',
      page: 0,
      key: null,
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePutSearch = this.handlePutSearch.bind(this);
    this.handlePage = this.handlePage.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handleCheckServerVoip = this.handleCheckServerVoip.bind(this);
  }

  handleModal() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('AddServerVoip');
    this.props.actionServerVoip.changeClearAllFormServerVoip();
    this.props.actionServerVoip.setServerVoipPosition(
      this.props.ListServerVoip.length + 1
    );
  }

  handleCheckServerVoip(event: any) {
    this.props.actionServerVoip.submitCheckServerVoip();
  }

  handlePage(event: any) {
    this.props.actionPagination.setPagePagination(event);
    this.setState({
      page: event,
    });
  }

  handleSearch() {
    this.props.actionServerVoip.submitSearchServerVoip();
  }

  handlePutSearch(event: any) {
    this.props.actionToggle.changeValueSearch(event.target.value);
  }

  componentWillMount() {
    this.props.actionLastAccess.changeLastAccessFiture('serverVoipTable');
    this.props.actionServerVoip.getListServerVoip();
  }

  render() {
    return (
      <div>
        <ServerVoipComponent
          SearchValue={this.props.ValueSearch}
          isLoading={this.props.isLoading}
          page={this.state.page}
          ListServerVoip={this.props.ListServerVoip}
          PagePagination={this.props.PagePagination}
          TotalPagePagination={this.props.TotalPagePagination}
          handlePage={this.handlePage}
          handleModal={this.handleModal}
          handleSearch={this.handleSearch}
          handlePutSearch={this.handlePutSearch}
          handleCheckServerVoip={this.handleCheckServerVoip}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  typeToggle: state.Toggle.jenisToggle,
  ValueSearch: state.Toggle.ValueSearch,
  isLoading: state.Toggle.isLoading,
  ListServerVoip: state.Voip.ListServerVoip,
  PagePagination: state.Pagination.PagePagination,
  TotalPagePagination: state.Pagination.TotalPagePagination,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionServerVoip: bindActionCreators(ActionServerVoip, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ServerVoipContainer);
