const EditComplaintValidation = (values, props) => {
  const errors: any = {};
  if (!values.resultInvestigation) {
    errors.resultInvestigation = 'Hasil investigasi wajib diisi';
  }
  if (!values.investigationFile) {
    errors.investigationFile = 'Bukti investigasi wajib diisi';
  }
  return errors;
};
export default EditComplaintValidation;
