import { Col, Row, Table } from 'antd';
import { Form, Field } from 'react-final-form';

import CardMaterial from '../../../../../materials/CardMaterial';
import React from 'react';
import SpinMaterial from '../../../../../materials/SpinMaterial';
import InputDatePicker from 'components/InputDatePicker';
import SelectField from 'components/SelectField';
import Button from 'components/Button';
import moment from 'moment-timezone';
import { statusDelivery } from 'lib/constants';

export interface IProps {
  columnData: any;
  currentPage: number;
  dataSyncDataDelivery?: any[];
  handleSyncDataDelivery: (values: any) => void;
  isFetchingCustomers: boolean;
  isFetchingDataSyncDataDelivery: boolean;
  isFetchingProducts: boolean;
  listCustomer?: any[];
  listProduct?: any[];
  setCurrentPage: (value: number) => void;
  setSelectedCustomer: (value: any) => void;
  totalDatas: number;
  totalPages: number;
}

export default function SyncDataComponent(props: IProps) {
  const {
    columnData,
    currentPage,
    dataSyncDataDelivery = [],
    handleSyncDataDelivery,
    isFetchingCustomers,
    isFetchingDataSyncDataDelivery,
    isFetchingProducts,
    listCustomer = [],
    listProduct = [],
    setCurrentPage,
    setSelectedCustomer,
    totalDatas,
    // totalPages,
  } = props;

  const disabledDatePickup = (current, formValues) => {
    const selectedDates = formValues.periodeDelivery;
    if (!selectedDates || selectedDates.length === 0) {
      return (
        !current ||
        current.isAfter(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'))
      );
    } else {
      const startDate = selectedDates[0];
      if (!startDate) {
        return current && current > moment().endOf('day');
      } else {
        return (
          !current ||
          current.isBefore(moment(selectedDates[0])) ||
          current.isAfter(
            moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
          ) ||
          current.isAfter(
            moment(selectedDates[0]).add(31, 'day').format('YYYY-MM-DD')
          )
        );
      }
    }
  };
  const initialValue = React.useMemo(
    () => ({
      typePeriode: 'Cycle',
      periodeDelivery: [null, null],
      statusDelivery: ['Return'],
      customer: null,
      customerProduct: null,
    }),
    []
  );

  return (
    <div>
      <SpinMaterial spinning={false} size="large">
        <CardMaterial
          style={{ borderRadius: '8px' }}
          title={
            <div style={{ color: '#11BEFF' }}>
              <span style={{ textTransform: 'uppercase' }}>
                <b>SYNC API PELANGGAN</b>
              </span>
            </div>
          }
        >
          <Form
            initialValues={initialValue}
            validate={values => {
              const errors = {} as any;

              if (
                !values.periodeDelivery ||
                values.periodeDelivery.length !== 2 ||
                !values.periodeDelivery[0] ||
                !values.periodeDelivery[1]
              ) {
                errors.periodeDelivery = 'Periode Pengirimam wajib dipilih';
              }

              if (values.periodeDelivery) {
                if (!values.customer) {
                  errors.customer = 'Pelanggan wajib dipilih';
                }
                if (values.customer) {
                  if (!values.customerProduct) {
                    errors.customerProduct = 'Produk wajib dipilih';
                  }
                }
              }

              return errors;
            }}
            onSubmit={() => {}}
          >
            {(formProps: any) => {
              const { valid, values, subscribedField, form } = formProps;
              return (
                <Row>
                  <Row gutter={[32, 24]}>
                    <Col span={12}>
                      <Field
                        name="periodeDelivery"
                        subscription={subscribedField}
                      >
                        {({ input, meta }) => (
                          <InputDatePicker
                            label="Periode Pengiriman"
                            firstPlaceholder="Dari tanggal"
                            secondPlaceholder="Sampai tanggal"
                            format="DD-MM-YYYY"
                            type="range"
                            error={meta.error && meta.touched && meta.error}
                            onBlur={input.onBlur}
                            value={input.value || [null, null]}
                            onChange={value => input.onChange(value)}
                            onCalendarChange={value => input.onChange(value)}
                            disabledDate={current =>
                              disabledDatePickup(current, values)
                            }
                            addonBefore={
                              <Field
                                name="typePeriode"
                                subscription={subscribedField}
                              >
                                {({ input }) => (
                                  <SelectField
                                    value={input.value || 'Cycle'}
                                    data={[
                                      { id: 'Cycle', name: 'Cycle' },
                                      { id: 'Process', name: 'Upload' },
                                    ]}
                                    style={{ width: 100 }}
                                    onChange={value => input.onChange(value)}
                                  />
                                )}
                              </Field>
                            }
                          />
                        )}
                      </Field>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                      }}
                    >
                      <Field name="statusDelivery">
                        {({ input, meta }) => (
                          <SelectField
                            label="Status Pengiriman"
                            placeholder="Status Pengiriman"
                            value={input.value}
                            mode="multiple"
                            disabled
                            data={statusDelivery}
                            onBlur={input.onBlur}
                            onChange={value => {
                              input.onChange(value);
                            }}
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            allowClear
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row gutter={[32, 24]}>
                    <Col
                      span={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                      }}
                    >
                      <Field name="customer" subscription={subscribedField}>
                        {({ input, meta }) => (
                          <SelectField
                            label="Pelanggan"
                            placeholder="Pilih Pelanggan"
                            data={listCustomer}
                            value={input.value}
                            onBlur={input.onBlur}
                            onChange={value => {
                              setSelectedCustomer(value);
                              form.change('customerProduct', null);
                              return input.onChange(value);
                            }}
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            disabled={!values.periodeDelivery[0]}
                            loading={isFetchingCustomers}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col
                      span={12}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                      }}
                    >
                      <Field name="customerProduct">
                        {({ input, meta }) => (
                          <SelectField
                            label="Produk Pelanggan"
                            placeholder="Pilih Produk Pelanggan"
                            data={listProduct}
                            value={input.value}
                            onBlur={input.onBlur}
                            onChange={value => input.onChange(value)}
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            disabled={!values.customer}
                            loading={isFetchingProducts}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Col span={24} style={{ padding: '0 16px' }}>
                    <div
                      style={{
                        display: 'flex',
                        gap: 24,
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button
                        text="Sync API"
                        onClick={() => handleSyncDataDelivery(values)}
                        disabled={!valid}
                      />
                    </div>
                  </Col>
                </Row>
              );
            }}
          </Form>
        </CardMaterial>
      </SpinMaterial>
      <SpinMaterial spinning={isFetchingDataSyncDataDelivery} size="large">
        <CardMaterial style={{ borderRadius: '10px 10px 0 0', marginTop: 32 }}>
          <Row>
            <Col span={18}>
              <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
                <span className="titleSearchCard" style={{ padding: 0 }}>
                  RIWAYAT SYNC DATA
                </span>
              </div>
            </Col>
          </Row>
        </CardMaterial>
        <Table
          rowKey={(record: any) => record.id}
          scroll={{ x: 'auto' }}
          pagination={{
            onChange: page => setCurrentPage(page - 1),
            pageSize: 10,
            total: totalDatas,
            current: currentPage === 0 ? 1 : currentPage + 1,
          }}
          bordered={false}
          dataSource={dataSyncDataDelivery || []}
          columns={columnData}
          footer={() => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                gap: 8,
              }}
            >
              <p style={{ margin: 0, fontWeight: 500 }}>
                Total Entri ({totalDatas || 0})
              </p>
            </div>
          )}
        />
      </SpinMaterial>
    </div>
  );
}
