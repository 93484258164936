import * as ActionAuth from '../../action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionPickupProcess from '../../../module/PickUpProcess/Store/PickUpProcessAction';
import * as Branch from '../../action/Branch/ActionAllBranch';
import * as LoadingBranch from '../../action/Branch/ActionLoadingBranch';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_getAllBranch(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(LoadingBranch.setLoadingBranch());
    yield put(LoadingBranch.setTypeLoadingBranch('selectbranch'));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/branchs`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(Branch.setBranch(data));
    yield put(ActionPickupProcess.fetchAllBranchList(data));
    yield put(LoadingBranch.setLoadingBranch());
    yield put(LoadingBranch.clearTypeLoadingBranch());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* S_getBranchGroupList(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(LoadingBranch.setLoadingBranch());
    yield put(LoadingBranch.setTypeLoadingBranch('selectBranchGroup'));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/branchs/groupandnongroup`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(Branch.setBranchGroupList(data));
    yield put(LoadingBranch.setLoadingBranch());
    yield put(LoadingBranch.clearTypeLoadingBranch());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_getAllBranch() {
  yield takeLatest('GET_ALL_BRANCH', S_getAllBranch);
}
export function* SM_getBranchGroupList() {
  yield takeLatest('GET_BRANCH_GRUP_LIST', S_getBranchGroupList);
}
