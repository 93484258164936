import IndexDeliveryStatusComponent from 'module/DeliveryStatus/Component/IndexDeliveryStatusComponent';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

interface IProps {
  isLoading: any;
  getDeliveryStatusBarcode: any;
  getOwnerName: any;
}
function IndexDeliveryStatusContainer({
  isLoading,
  getDeliveryStatusBarcode,
  getOwnerName,
}: IProps) {
  return (
    <div>
      <IndexDeliveryStatusComponent
        isLoading={isLoading}
        getDeliveryStatusBarcode={getDeliveryStatusBarcode}
        getOwnerName={getOwnerName}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  isLoading: state.Toggle.isLoading,
  getDeliveryStatusBarcode: state.DeliveryStatus.DeliveryStatusBarcode,
  getOwnerName: state.DeliveryStatus.OwnerName,
});

const withConnect = connect(mapStateToProps, null);
export default compose(withConnect)(IndexDeliveryStatusContainer);
