import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as ActionToggle from '../../../core/action/toggle/index';
import * as ActionResetPassword from '../../../core/action/ResetPassword/ActionResetPassword';
import * as formCourier from '../../../core/action/ManageAccount/Courier/ActionFormDetailCourier';
import * as detailCourier from '../../../core/action/ManageAccount/Courier/ActionIdCourierBranch';
import * as ActionDetailBranch from '../../../core/action/Branch/ActionDetailBranch';
import ResetPasswordComponent from '../../../components/Content/ResetPassword/ResetPasswordComponent';

interface IProps {
  typeToggle: any;
  visible: any;
  isLoading: any;
  actionToggle: any;
  actionResetPassword: any;
  UserNameAdminBranch: any;
  UserNameCourier: any;
  UserNameCustomer: any;
  Password: any;
  NewPassword: any;
  ReNewPassword: any;

  FormCourier: any;
  IdCourier: any;
  actionDetailBranch: any;
}
interface IState {
  ValidatePassword: any;
  ValidateNewPassword: any;
  ValidateReNewPassword: any;
}

class ResetPasswordContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidatePassword: null,
      ValidateNewPassword: null,
      ValidateReNewPassword: null,
    };
    this.handlePutPassword = this.handlePutPassword.bind(this);
    this.handlePutNewPassword = this.handlePutNewPassword.bind(this);
    this.handlePutReNewPassword = this.handlePutReNewPassword.bind(this);
    this.handleSavePassword = this.handleSavePassword.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handlePutPassword(event: any) {
    this.props.actionResetPassword.setPasswordResetPassword(event.target.value);
    const RegexMinChar = /(?=^.{5,}$)/;
    if (event.target.value === '') {
      this.setState({
        ValidatePassword: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidatePassword: 'Length',
      });
    } else {
      this.setState({
        ValidatePassword: '',
      });
    }
  }

  handlePutNewPassword(event: any) {
    this.props.actionResetPassword.setNewPasswordResetPassword(
      event.target.value
    );
    const RegexMinChar = /(?=^.{8,}$)/;
    const RegexDigit = /(?=.*[0-9])/;
    const RegexUppercase = /(?=.*[A-Z])/;
    const RegexLowercase = /(?=.*[a-z])/;
    const RegexSpecialChar = /(?=.*[^A-Za-z0-9])/;

    if (event.target.value === '') {
      this.setState({
        ValidateNewPassword: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateNewPassword: 'Length',
      });
    } else if (!RegexDigit.test(event.target.value)) {
      this.setState({
        ValidateNewPassword: 'Digit',
      });
    } else if (!RegexUppercase.test(event.target.value)) {
      this.setState({
        ValidateNewPassword: 'Uppercase',
      });
    } else if (!RegexLowercase.test(event.target.value)) {
      this.setState({
        ValidateNewPassword: 'Lowercase',
      });
    } else if (!RegexSpecialChar.test(event.target.value)) {
      this.setState({
        ValidateNewPassword: 'SpecialChar',
      });
    } else {
      this.setState({
        ValidateNewPassword: '',
      });
    }
  }

  handlePutReNewPassword(event: any) {
    this.props.actionResetPassword.setReNewPasswordResetPassword(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateReNewPassword: 'error',
      });
    } else if (event.target.value !== this.props.NewPassword) {
      this.setState({
        ValidateReNewPassword: 'Equals',
      });
    } else {
      this.setState({
        ValidateReNewPassword: '',
      });
    }
  }

  handleCloseModal() {
    this.props.actionResetPassword.changeClearDataResetPassword();
    this.props.FormCourier.changeClearDataCourierForm();
    this.props.IdCourier.clearDetailCourier();
    this.props.actionDetailBranch.setBranchDetail(null);
    this.props.actionToggle.changeModal();
    this.props.actionToggle.clearJenisToggle();
    this.setState({
      ValidatePassword: null,
      ValidateNewPassword: null,
      ValidateReNewPassword: null,
    });
  }

  handleSavePassword() {
    if (this.props.typeToggle === 'ChangePasswordProfile') {
      this.props.actionResetPassword.submitEditResetPassword();
    } else {
      this.props.actionResetPassword.submitEditResetAccountPassword();
    }
    this.setState({
      ValidatePassword: null,
      ValidateNewPassword: null,
      ValidateReNewPassword: null,
    });
  }

  render() {
    return (
      <div>
        <ResetPasswordComponent
          typeToggle={this.props.typeToggle}
          visible={this.props.visible}
          isLoading={this.props.isLoading}
          password={this.props.Password}
          newPassword={this.props.NewPassword}
          reNewPassword={this.props.ReNewPassword}
          userNameAdminBranch={this.props.UserNameAdminBranch}
          userNameCourier={this.props.UserNameCourier}
          userNameCustomer={this.props.UserNameCustomer}
          validatePassword={
            this.state.ValidatePassword === 'Length'
              ? 'error'
              : '' || this.state.ValidatePassword === 'error'
              ? 'error'
              : ''
          }
          validateNewPassword={
            this.state.ValidateNewPassword === 'error'
              ? 'error'
              : '' || this.state.ValidateNewPassword === 'Length'
              ? 'error'
              : '' || this.state.ValidateNewPassword === 'Digit'
              ? 'error'
              : '' || this.state.ValidateNewPassword === 'Uppercase'
              ? 'error'
              : '' || this.state.ValidateNewPassword === 'Lowercase'
              ? 'error'
              : '' || this.state.ValidateNewPassword === 'SpecialChar'
              ? 'error'
              : ''
          }
          validateReNewPassword={
            this.state.ValidateReNewPassword === 'Equals'
              ? 'error'
              : '' || this.state.ValidateReNewPassword === 'error'
              ? 'error'
              : ''
          }
          helpValidatePassword={
            this.state.ValidatePassword === 'Length'
              ? 'Kata sandi harus lebih dari 5 karakter'
              : '' || this.state.ValidatePassword === 'error'
              ? 'Kata sandi wajib diisi'
              : ''
          }
          helpValidateNewPassword={
            this.state.ValidateNewPassword === 'error'
              ? 'Kata sandi baru wajib diisi'
              : '' || this.state.ValidateNewPassword === 'Length'
              ? 'Kata sandi minimal 8 karakter'
              : '' || this.state.ValidateNewPassword === 'Digit'
              ? 'Kata sandi harus memiliki angka (0-9)'
              : '' || this.state.ValidateNewPassword === 'Uppercase'
              ? 'Kata sandi harus memiliki huruf kapital (A-Z)'
              : '' || this.state.ValidateNewPassword === 'Lowercase'
              ? 'Kata sandi harus memiliki huruf kecil (a-z)'
              : '' || this.state.ValidateNewPassword === 'SpecialChar'
              ? 'Kata sandi harus memiliki spesial karakter (!@#$%^&*()+=-[])'
              : ''
          }
          helpValidateReNewPassword={
            this.state.ValidateReNewPassword === 'Equals'
              ? 'Kata sandi baru tidak sama'
              : '' || this.state.ValidateReNewPassword === 'error'
              ? 'Kata sandi baru wajib diisi'
              : ''
          }
          handlePutPassword={this.handlePutPassword}
          handlePutNewPassword={this.handlePutNewPassword}
          handlePutReNewPassword={this.handlePutReNewPassword}
          handleSavePassword={this.handleSavePassword}
          handleCloseModal={this.handleCloseModal}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  typeToggle: state.Toggle.jenisToggle,
  isLoading: state.Toggle.isLoading,
  Password: state.ResetPassword.Password,
  NewPassword: state.ResetPassword.NewPassword,
  ReNewPassword: state.ResetPassword.ReNewPassword,
  UserNameAdminBranch: state.AdminBranch.FormAdmin.Username,
  UserNameCourier: state.Courier.FormCourier.CourierCode,
  UserNameCustomer: state.Customer.CustomerAccount.Username,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionResetPassword: bindActionCreators(ActionResetPassword, dispatch),
  FormCourier: bindActionCreators(formCourier, dispatch),
  IdCourier: bindActionCreators(detailCourier, dispatch),
  actionDetailBranch: bindActionCreators(ActionDetailBranch, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ResetPasswordContainer);
