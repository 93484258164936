import 'moment/locale/id'

import {Col, Row, Table} from 'antd';
import React, { Component } from 'react'

import ButtonDownloadContainer from '../../../../containers/Content/Delivery/PrintWaybill/ButtonDownloadContainer';
import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import DatePickerMaterial from '../../../../materials/DatePickerMaterial';
import FormPrintWaybillContainer from '../../../../containers/Content/Delivery/PrintWaybill/FormPrintByWaybillContainer';
import FormWaybillPrintTypeContainer from '../../../../containers/Content/Delivery/PrintWaybill/FormWaybillPrintTypeContainer';
import SpinMaterial from '../../../../materials/SpinMaterial';
import locale from "antd/es/date-picker/locale/id_ID";
import moment from "moment";

moment.locale('id')

interface IProps
{
    handleSearch:any
    handleModal:any
    handlePutUploadDate:any
    handleValidateUploadDate:any
    handleDownloadSelectWaybill:any
    handleModalWaybillPrintType:any
    getUploadDate:any
    DataListPrintWaybill:any
    ValidateUploadDate:any
    helpValidateUploadDate:any
    handlePage:any
    handleReset:any
    CountCheckbox:any
    page:any
    TotalPagePagination:any
    PagePagination:any
    isLoading:any
}

export default class IndexPrintWaybillComponent extends Component <IProps> {
    render() {
        
        const looppage = (this.props.page-1)*10+1
        const dateFormat = 'YYYY-MM-DD';
        const minDate = (d:any) => !d || d.isBefore("2020-01-01") || d.isAfter(moment(new Date()).add(1, 'day').format("YYYY-MM-DD"))
        const columns = [
            {
            title: <b>No</b>,
            render:(text:any,record:any,index:any)=><span>{
                this.props.page>1?index+looppage:index+1
              }</span>,
            key: 'no',
            width: 50,
            },
            {
              title: <b>Tanggal</b>,
              dataIndex: 'itemProcessTime',
              key: 'itemProcessTime',
              render:(itemProcessTime:any)=><span>{(moment(itemProcessTime).format('YYYY-MM-DD'))}</span>,
              width: 120,
            },
            {
              title: <b>Jenis Produk</b>,
              dataIndex: 'product',
              key: 'product',
              render:(product:any)=><span style={{color: 'red'}}>{product}</span>,
              width: 120,
            },
            {
              title: <b>Admin</b>,
              dataIndex: 'admin',
              key: 'adminCode',
              render:(admin:any)=><span>{admin===null?'-':admin}</span>,
              width: 120,
            },
            {
              title: <b>Jumlah Dokumen</b>,
              dataIndex: 'totalDocument',
              key: 'totalDocument',
              render:(totalDocument:any)=><span style={{color: 'red'}}>{`${totalDocument} Dokumen`}</span>,
              width: 120,
            },
            {
                title: <b>Pilih</b>,
                key: 'pilih',
                width: 120,
                render: (record:any) => (
                <div style={{marginRight:60}}>
                  <ButtonDownloadContainer
                    itemDeliveryTime={record.itemProcessTime}
                    itemProcessTime={record.itemDeliveryTime}
                    courierId={record.courierId}
                    id={record.id}
                    typeButton={'download'}
                  />
                </div>
                ),
              },
          ];
        return (
                <SpinMaterial
                    spinning={this.props.isLoading}
                    style={{}}
                    size={"large"}
                    content={
            <div style={{padding:'35px'}}>
                <FormPrintWaybillContainer/>
                <FormWaybillPrintTypeContainer/>
                <CardMaterial
                    extra={""}
                    title={<span style={{color:'#11beff'}}><b>Tanda Terima</b></span>}
                    style={{borderRadius:'5px'}}
                    content={
                     <div>
                        <Row>
                            <Col span={20} style={{width:'44%', paddingRight:'30px'}}>
                                <div style={{paddingBottom:'5px'}}>
                                    <span><b>Tanggal Upload File</b></span>
                                </div>
                                <DatePickerMaterial
                                style={{ width:"100%" ,backgroundColor:'#F5F5F5', fontWeight:'bold'}}
                                locale={locale}
                                format={dateFormat}
                                defaultPickerValue={moment('YYYY-MM-DD', dateFormat)}
                                placeholder={dateFormat}
                                defaultValue={''}
                                onOpenChange={this.props.handleValidateUploadDate}
                                value={this.props.getUploadDate}
                                onChange={this.props.handlePutUploadDate}
                                validatestatus={this.props.ValidateUploadDate}
                                help={this.props.helpValidateUploadDate}
                                disabledDate={minDate}
                                />
                            </Col>
                            <Col span={4} style={{paddingBottom:'5px'}}>
                                <div style={{paddingBottom:'5px'}}>
                                        <span style={{color:'#FAFAFA'}}>.</span>
                                </div>
                                <ButtonMaterial
                                    disabled={false}
                                    handleSubmit={this.props.handleModal}
                                    teksButton={<u>Cetak berdasarkan waybill</u>}
                                    size={""}
                                    shape={""}
                                    className={""}
                                    type={"link"}
                                    icon={""}
                                    style={{
                                        color: '#11BEFF',
                                    fontWeight:'bold',}}
                                />
                            </Col>
                        </Row>
                            <ButtonMaterial
                                disabled={this.props.getUploadDate===null?true:false}
                                handleSubmit={this.props.handleSearch}
                                teksButton={<b>Cari</b>}
                                size={"middle"}
                                shape={"round"}
                                className={""}
                                type={"primary"}
                                icon={""}
                                style={this.props.getUploadDate===null?
                                    {
                                    float:"right",
                                    height:"30px",
                                    width:"65px",
                                    }
                                    :
                                    {
                                    background:'#11BEFF',
                                    borderColor:'#11BEFF',
                                    float:"right",
                                    height:"30px",
                                    width:"65px",
                                    color: '#FFFFFF'}
                                }
                            />
                     </div>   
                    }
                />
                <Table
                    bordered={false}
                    pagination={{onChange: page => { this.props.handlePage(page)},
                    pageSize:10,
                    total:this.props.TotalPagePagination,
                    current:this.props.PagePagination===0?1:this.props.PagePagination
                    }}
                    dataSource={this.props.DataListPrintWaybill}
                    columns={columns}
                />
            </div>
            }/>
        )
    }
}
