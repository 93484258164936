export function getDeliveryStatus() {
  return {
    type: 'GET_DELIVERY_STATUS',
  };
}
export function getDeliveryStatusBarcode() {
  return {
    type: 'GET_DELIVERY_STATUS_BARCODE',
  };
}
export function getDeliveryStatusFilter() {
  return {
    type: 'GET_DELIVERY_STATUS_FILTER',
  };
}
