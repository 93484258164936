import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { useQuery, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { Modal } from 'antd';
import {
  getCustomerDetailCsvList,
  changeCustomerStatusTemplateReport,
} from 'services/CustomerService';
import TemplateUploadComponent from '../components';
import ModalStatus from 'materials/ModalStatus';
import history from '../../../../core/utils/History';
import * as ActionNavigation from '../../../../core/action/Router/ActionRouter';
import * as ActionCustomCsvCustomer from '../../../../core/action/Customer/ActionSetCustomCsvCustomer';
import * as ActionLastAccess from '../../../../core/action/LastAccess';
import * as ActionDetailCustomCsvCustomer from '../../../../core/action/Customer/ActionDetailCustomCsvCustomer';
import * as ActionDeleteCustomCsvCustomer from '../../../../core/action/Customer/ActionDeleteCustomCsv';

const { confirm } = Modal;

type TemplateUploadProps = {
  CustomerName: string;
  IdCustomer: number;
  actionNavigation: any;
  actionCustomCsvCustomer: any;
  actionLastAccess: any;
  actionDetailCustomCsvCustomer: any;
  actionDeleteCustomCsvCustomer: any;
};

function TemplateUpload({
  CustomerName,
  IdCustomer,
  actionCustomCsvCustomer,
  actionNavigation,
  actionLastAccess,
  actionDetailCustomCsvCustomer,
  actionDeleteCustomCsvCustomer,
}: TemplateUploadProps) {
  const [dataCsvList, setDataCsvList] = useState<any[]>([]);
  const [isActiveType, setIsActiveType] = useState<string>('Active');

  const { isFetching: isLoadingCsvList, refetch } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataCustomerCsvList', IdCustomer, isActiveType],
    queryFn: () => getCustomerDetailCsvList({ IdCustomer, isActiveType }),
    enabled: !!IdCustomer,
    refetchOnWindowFocus: false,
    onSuccess: res => {
      if (res?.data.length > 0) {
        setDataCsvList(res.data);
      } else {
        setDataCsvList([]);
      }
    },
  });

  const { mutate: mutateChangeStatus, isLoading: isLoadingStatus } =
    useMutation(changeCustomerStatusTemplateReport, {
      onSuccess: res => {
        if (res.status === 200) {
          refetch();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const handleBack = () => {
    history.push('/customer');
    actionNavigation.setRouter('/customer');
  };

  const handleAddTemplateCsv = () => {
    actionCustomCsvCustomer.setTypeFormCsv('add');
    actionLastAccess.changeLastAccessFiture('AddCustomCsv');
  };

  const handleDetailCustomCsv = (id: number) => {
    actionCustomCsvCustomer.setIdCsv(id);
    actionCustomCsvCustomer.setTypeFormCsv('detail');
    actionLastAccess.changeLastAccessFiture('DetailCustomCsv');
    actionDetailCustomCsvCustomer.getDetailCustomCsvCustomer();
  };

  const handleEditCustomCsv = (id: number) => {
    actionCustomCsvCustomer.setIdCsv(id);
    actionCustomCsvCustomer.setTypeFormCsv('edit');
    actionLastAccess.changeLastAccessFiture('EditCustomCsv');
    actionDetailCustomCsvCustomer.getDetailCustomCsvCustomer();
  };

  const handleDownloadSampleCsv = async (id: number) => {
    await actionCustomCsvCustomer.setTypeFormCsv('download');
    await actionCustomCsvCustomer.setIdCsv(id);
    await actionDetailCustomCsvCustomer.getDetailCustomCsvCustomer();
  };

  const handleChangeStatus = (id: number) => {
    mutateChangeStatus(id);
  };

  const handleDeleteCustomCsv = (id: number) => {
    actionCustomCsvCustomer.setIdCsv(id);
    confirm({
      title: 'Apakah anda yakin ingin menghapus data ini ?',
      okText: 'Ya',
      okType: 'danger',
      cancelText: 'Tidak',
      onOk: () => {
        actionDeleteCustomCsvCustomer.deleteCustomCsvCustomer(refetch);
      },
      onCancel: () => {},
    });
  };

  return (
    <TemplateUploadComponent
      isLoading={isLoadingCsvList || isLoadingStatus}
      handleBack={handleBack}
      CustomerName={CustomerName}
      dataCsvList={dataCsvList}
      setIsActiveType={setIsActiveType}
      isActiveType={isActiveType}
      handleAddTemplateCsv={handleAddTemplateCsv}
      handleDetailCustomCsv={handleDetailCustomCsv}
      handleEditCustomCsv={handleEditCustomCsv}
      handleDownloadSampleCsv={handleDownloadSampleCsv}
      handleDeleteCustomCsv={handleDeleteCustomCsv}
      handleChangeStatus={handleChangeStatus}
    />
  );
}

const mapStateToProps = (state: any) => ({
  CustomerName: state.Customer.DetailCustomer.CustomerName,
  IdCustomer: state.Customer.PropertiesCustomer.IdCustomer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionNavigation: bindActionCreators(ActionNavigation, dispatch),
  actionCustomCsvCustomer: bindActionCreators(
    ActionCustomCsvCustomer,
    dispatch
  ),
  actionLastAccess: bindActionCreators(ActionLastAccess, dispatch),
  actionDetailCustomCsvCustomer: bindActionCreators(
    ActionDetailCustomCsvCustomer,
    dispatch
  ),
  actionDeleteCustomCsvCustomer: bindActionCreators(
    ActionDeleteCustomCsvCustomer,
    dispatch
  ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(TemplateUpload);
