import { Action } from 'redux';

const initialState = {
  Password: null,
  NewPassword: null,
  ReNewPassword: null,
};

export interface IAuthAction extends Action<string> {}

export interface ISetPasswordResetPassword extends IAuthAction {
  Password: any;
}
export interface ISetNewPasswordResetPassword extends IAuthAction {
  NewPassword: any;
}
export interface ISetReNewPasswordResetPassword extends IAuthAction {
  ReNewPassword: any;
}

export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'SET_PASSWORD_RESET_PASSWORD':
      const setPasswordResetPassword = action as ISetPasswordResetPassword;
      newState.Password = setPasswordResetPassword.Password;
      return { ...newState };

    case 'SET_NEW_PASSWORD_RESET_PASSWORD':
      const setNewPasswordResetPassword = action as ISetNewPasswordResetPassword;
      newState.NewPassword = setNewPasswordResetPassword.NewPassword;
      return { ...newState };

    case 'SET_RE_NEW_PASSWORD_RESET_PASSWORD':
      const setReNewPasswordResetPassword = action as ISetReNewPasswordResetPassword;
      newState.ReNewPassword = setReNewPasswordResetPassword.ReNewPassword;
      return { ...newState };

    case 'CHANGE_CLEAR_DATA_RESET_PASSWORD':
      newState.Password = null;
      newState.NewPassword = null;
      newState.ReNewPassword = null;
      return { ...newState };
  }
  return { ...newState };
}
