import React, { Component } from 'react';
import { Row, Col } from 'antd';
import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import TableCustomerAccountContainer from '../../../../containers/Content/ManageAccount/Customer/TableCustomerAccountContainer';

interface IProps {
  CustomerName: any;
  CustomerCode: any;
  CustomerType: any;
  CustomerTelephone: any;
  CustomerEmail: any;
  CustomerAddress: any;
  isLoading: any;
  typeToggle: any;
  handleBackDetail: any;
}
export default class CustomerDetailComponent extends Component<IProps> {
  render() {
    return (
      <div
        style={{
          paddingTop: '35px',
          paddingLeft: '35px',
          paddingRight: '35px',
        }}
      >
        <CardMaterial
          extra={''}
          title={
            <span style={{ color: '#11BEFF' }}>
              <b>{this.props.CustomerName}</b>
              <ButtonMaterial
                disabled={''}
                handleSubmit={this.props.handleBackDetail}
                teksButton={<b>Kembali</b>}
                size={'small'}
                shape={'round'}
                className={''}
                type={'primary'}
                icon={''}
                style={{
                  float: 'right',
                  colorFont: '#11BEFF',
                  height: '30px',
                  background: '#F5487D',
                  borderColor: '#F5487D',
                }}
              />
            </span>
          }
          style={{ borderRadius: '10px' }}
          content={
            <div>
              <Row>
                <Col span={12} style={{ paddingLeft: '40px' }}>
                  <Row>
                    <Col span={4}>Nama</Col>
                    <Col span={3}>:</Col>
                    <Col span={17}>
                      <b>{this.props.CustomerName}</b>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '5px' }}>
                    <Col span={4}>Jenis</Col>
                    <Col span={3}>:</Col>
                    <Col span={17}>
                      <b>{this.props.CustomerType}</b>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '5px' }}>
                    <Col span={4}>Email</Col>
                    <Col span={3}>:</Col>
                    <Col span={17}>
                      <b>{this.props.CustomerEmail}</b>
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <Row>
                    <Col span={4}>Kode</Col>
                    <Col span={3}>:</Col>
                    <Col span={17}>
                      <b>{this.props.CustomerCode}</b>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '5px' }}>
                    <Col span={4}>No Telp</Col>
                    <Col span={3}>:</Col>
                    <Col span={17}>
                      <b>0{this.props.CustomerTelephone}</b>
                    </Col>
                  </Row>
                  <Row style={{ paddingTop: '5px' }}>
                    <Col span={4}>Alamat</Col>
                    <Col span={3}>:</Col>
                    <Col span={17}>
                      <b>{this.props.CustomerAddress}</b>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          }
        />
        <TableCustomerAccountContainer />
      </div>
    );
  }
}
