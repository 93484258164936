import * as ActionAuth from '../../action/Auth';
import * as ActionFormComplaint from '../../action/Complaint/ActionFormComplaint';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_getDetailTicket(action: any) {
  const state = yield select();
  const TicketId = state.Complaint.complaintProperties.complaintId;
  const token = state.Auth.Token;
  //   const jenisToggle = state.Toggle.jenisToggle;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Complaint/${TicketId}`,
      { headers: config }
    );
    yield put(toggle.changeModal());
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    // const address =
    //   jenisToggle === 'AddNewTicket' ? data.shipToAddress : data.address;
    yield put(ActionFormComplaint.changeTicketCode(data.tiket));
    yield put(ActionFormComplaint.changeComplaintOwnerName(data.shipToName));
    yield put(ActionFormComplaint.changeComplaintWaybill(data.waybillCode));
    yield put(
      ActionFormComplaint.changeComplaintWaybillDetail(data.waybillCode)
    );
    yield put(ActionFormComplaint.changeComplaintBarcodeDetail(data.barcode));
    yield put(
      ActionFormComplaint.changeComplaintNoTelp(data.shipToPhoneAccount)
    );
    yield put(
      ActionFormComplaint.changeComplaintDetailNoTelp(data.shipToPhoneAccount)
    );
    yield put(ActionFormComplaint.changeComplaintDetail(data.question));
    yield put(
      ActionFormComplaint.changeComplaintResultInvestigation(data.answer)
    );
    yield put(ActionFormComplaint.changeComplaintPostCode(data.shipToPostCode));
    yield put(ActionFormComplaint.changeComplaintCity(data.shipToCity));
    yield put(
      ActionFormComplaint.changeComplaintAddress(JSON.parse(data.shipToAddress))
    );
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_getDetailTicket() {
  yield takeLatest('GET_DETAIL_COMPLAINT_TICKET', S_getDetailTicket);
}
