import instance from 'config/interceptor';
import moment from 'moment-timezone';

export const getDataTablePerformanceCourier = async params => {
  try {
    const {
      page,
      startDate,
      endDate,
      customerId,
      productId,
      branchId,
      searchValue,
    } = params;
    let link = `/Delivery/Courier/Performance/${page}/10?`;

    if (startDate)
      link = link.concat(`&startDate=${moment(startDate).format('DDMMYYYY')}`);
    if (endDate)
      link = link.concat(`&endDate=${moment(endDate).format('DDMMYYYY')}`);
    if (customerId) link = link.concat(`&customerId=${customerId}`);

    if (productId?.length > 0) {
      const queryParam = `productId=${productId.join('&productId=')}`;
      link = link.concat(`&${queryParam}`);
    }

    if (branchId?.length > 0) {
      const queryParam = `branchId=${branchId.join('&branchId=')}`;
      link = link.concat(`&${queryParam}`);
    }
    if (searchValue) link = link.concat(`&valueSearch=${searchValue}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const downloadPerformanceCourier = async (
  data,
  onDownloadProgress: any
) => {
  try {
    const {
      filterDateType,
      startDate,
      endDate,
      customerId,
      productId,
      branchId,
      searchValue,
      reportCourierPerformanceType = 'Reguler',
    } = data;

    let link = `/Delivery/Courier/Performance/download?`;

    if (filterDateType) link = link.concat(`&filterDateType=${filterDateType}`);
    if (startDate)
      link = link.concat(`&startDate=${moment(startDate).format('DDMMYYYY')}`);
    if (endDate)
      link = link.concat(`&endDate=${moment(endDate).format('DDMMYYYY')}`);
    if (customerId) link = link.concat(`&customerId=${customerId}`);

    if (productId?.length > 0) {
      const queryParam = `productId=${productId.join('&productId=')}`;
      link = link.concat(`&${queryParam}`);
    }

    if (branchId?.length > 0) {
      const queryParam = `branchId=${branchId.join('&branchId=')}`;
      link = link.concat(`&${queryParam}`);
    }
    if (searchValue) link = link.concat(`&valueSearch=${searchValue}`);
    if (reportCourierPerformanceType)
      link = link.concat(
        `&reportCourierPerformanceType=${reportCourierPerformanceType}`
      );

    const response = await instance.post(
      process.env.REACT_APP_URL + link,
      {},
      {
        responseType: 'arraybuffer',
        onDownloadProgress,
      }
    );

    return {
      ...response,
      startDate: data.startDate,
      endDate: data.endDate,
    };
  } catch (error) {
    return error;
  }
};

export const downloadPerformanceBranch = async (
  data,
  onDownloadProgress: any
) => {
  try {
    const {
      filterDateType,
      startDate,
      endDate,
      customerId,
      productId,
      branchId,
      searchValue,
    } = data;

    let link = `/Delivery/Branch/Performance/download?`;

    if (filterDateType) link = link.concat(`&filterDateType=${filterDateType}`);
    if (startDate)
      link = link.concat(`&startDate=${moment(startDate).format('DDMMYYYY')}`);
    if (endDate)
      link = link.concat(`&endDate=${moment(endDate).format('DDMMYYYY')}`);
    if (customerId) link = link.concat(`&customerId=${customerId}`);

    if (productId?.length > 0) {
      const queryParam = `productId=${productId.join('&productId=')}`;
      link = link.concat(`&${queryParam}`);
    }

    if (branchId?.length > 0) {
      const queryParam = `branchId=${branchId.join('&branchId=')}`;
      link = link.concat(`&${queryParam}`);
    }
    if (searchValue) link = link.concat(`&valueSearch=${searchValue}`);

    const response = await instance.post(
      process.env.REACT_APP_URL + link,
      {},
      {
        responseType: 'arraybuffer',
        onDownloadProgress,
      }
    );

    return {
      ...response,
      startDate: data.startDate,
      endDate: data.endDate,
    };
  } catch (error) {
    return error;
  }
};

export const getDataTablePerformanceBranch = async params => {
  try {
    const {
      filterDateType,
      startDate,
      endDate,
      customerId,
      productId,
      branchId,
      searchValue,
    } = params;
    let link = `/Delivery/Branch/Performance?`;

    if (filterDateType) link = link.concat(`&filterDateType=${filterDateType}`);
    if (startDate)
      link = link.concat(`&startDate=${moment(startDate).format('DDMMYYYY')}`);
    if (endDate)
      link = link.concat(`&endDate=${moment(endDate).format('DDMMYYYY')}`);
    if (customerId) link = link.concat(`&customerId=${customerId}`);

    if (productId?.length > 0) {
      const queryParam = `productId=${productId.join('&productId=')}`;
      link = link.concat(`&${queryParam}`);
    }

    if (branchId?.length > 0) {
      const queryParam = `branchId=${branchId.join('&branchId=')}`;
      link = link.concat(`&${queryParam}`);
    }
    if (searchValue) link = link.concat(`&valueSearch=${searchValue}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};
