export function getListCustomCsvCustomer()
{
    return({
        type:"GET_LIST_CUSTOM_CSV_CUSTOMER"
    })
}
export function setListCustomCsvCustomer(ListCustomCsvCustomer:any)
{
    return({
        type:"SET_LIST_CUSTOM_CSV_CUSTOMER",
        ListCustomCsvCustomer
    })
}
export function clearListCustomCsvCustomer()
{
    return({
        type:"CLEAR_LIST_CUSTOM_CSV_CUSTOMER"
    })
}