export function changeReasonStat(ReasonDeliveryStatus:any)
{
    return({
        type:"CHANGE_REASON_STAT",
        ReasonDeliveryStatus
    })
}
export function changeClearReasonStat()
{
    return({
        type:"CHANGE_CLEAR_REASON_STAT"
    })
}