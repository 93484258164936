import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  Radio,
  Row,
  Select,
  Tooltip,
} from 'antd';

import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import FormInputTextMaterial from 'materials/FormInputTextMaterial';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

const { Option } = Select;
const { MonthPicker } = DatePicker;
interface IProps {
  isLoading: any;
  radioButton: any;
  datePickerMonth: any;
  valuePickupDate: any;
  pickupDate: any;
  customer: any;
  customerProduct: any;
  reportFormat: any;
  fileName: any;
  fileData: any;
  indexColumn: any;
  checkboxBarcode: any;
  checkboxFileName: any;
  checkboxFullData: any;
  CheckBoxToDayData: any;
  collapse: any;
  deliveryStatus: any;
  treatmentDocument: any;
  statusSuccess: any;
  statusReturn: any;
  typeDelivery: any;
  validatePickupDate: any;
  validateCustomer: any;
  validateCustomerProduct: any;
  validateReportFormat: any;
  validateDeliveryStatus: any;
  validateStatusSuccess: any;
  validateStatusReturn: any;
  validateTypeDelivery: any;
  validateColumn: any;
  helpValidatePickupDate: any;
  helpValidateCustomer: any;
  helpValidateCustomerProduct: any;
  helpValidateReportFormat: any;
  helpValidateDeliveryStatus: any;
  helpValidateStatusSuccess: any;
  helpValidateStatusReturn: any;
  helpValidateTypeDelivery: any;
  helpValidateColumn: any;
  loadingSelectCustomer: any;
  loadingSelectCustomerProduct: any;
  loadingSelectReportFormat: any;
  listCustomer: any;
  listCustomerProduct: any;
  listReportFormat: any;
  listStatusSuccess: any;
  listStatusReturn: any;
  handleRadioButton: any;
  handleDatePickerMonth: any;
  handlePutPickupDate: any;
  handlePutCustomer: any;
  handlePutCustomerProduct: any;
  handlePutReportFormat: any;
  handlePutDeliveryStatus: any;
  handlePutTreatmentDocument: any;
  handlePutStatusSuccess: any;
  handlePutStatusReturn: any;
  handlePutTypeDelivery: any;
  handlePutCheckBoxFileName: any;
  handlePutCheckBoxFullData: any;
  handlePutCheckBoxToDayData: any;
  handlePutColumn: any;
  handleBlurCustomer: any;
  handleBlurCustomerProduct: any;
  handleBlurReportFormat: any;
  handleBlurDeliveryStatus: any;
  handleBlurTreatmentDocument: any;
  handleBlurStatusSuccess: any;
  handleBlurStatusReturn: any;
  handleBlurTypeDelivery: any;
  handleGetCustomer: any;
  handleGetCustomerProduct: any;
  handleGetReportFormat: any;
  handleValidatePickupDate: any;
  handleConvert: any;
  handleCheckboxBarcode: any;
  handleCollapse: any;
  handleResetCustomer: any;
  handleReset: any;
  handleDownloadCustomerReport: any;
  helpValidateTreatmentDocument: any;
  validateTreatmentDocument: any;
  handleDownloadReport: any;
}
export default function CustomerReportComponent({
  isLoading,
  radioButton,
  datePickerMonth,
  valuePickupDate,
  pickupDate,
  customer,
  customerProduct,
  reportFormat,
  fileName,
  fileData,
  indexColumn,
  checkboxBarcode,
  checkboxFileName,
  checkboxFullData,
  CheckBoxToDayData,
  collapse,
  deliveryStatus,
  statusSuccess,
  statusReturn,
  typeDelivery,
  validatePickupDate,
  validateCustomer,
  validateCustomerProduct,
  validateReportFormat,
  validateDeliveryStatus,
  validateTypeDelivery,
  validateColumn,
  helpValidatePickupDate,
  helpValidateCustomer,
  helpValidateCustomerProduct,
  helpValidateReportFormat,
  helpValidateDeliveryStatus,
  helpValidateTypeDelivery,
  helpValidateColumn,
  loadingSelectCustomer,
  loadingSelectCustomerProduct,
  loadingSelectReportFormat,
  listCustomer,
  listCustomerProduct,
  listReportFormat,
  listStatusSuccess,
  listStatusReturn,
  handleRadioButton,
  handleDatePickerMonth,
  handlePutPickupDate,
  handlePutCustomer,
  handlePutCustomerProduct,
  handlePutReportFormat,
  handlePutDeliveryStatus,
  handlePutStatusSuccess,
  handlePutStatusReturn,
  handlePutTypeDelivery,
  handlePutCheckBoxFileName,
  handlePutCheckBoxFullData,
  handlePutCheckBoxToDayData,
  handlePutColumn,
  handleBlurCustomer,
  handleBlurCustomerProduct,
  handleBlurReportFormat,
  handleBlurDeliveryStatus,
  handleBlurStatusSuccess,
  handleBlurStatusReturn,
  handleBlurTypeDelivery,
  handleGetCustomer,
  handleGetCustomerProduct,
  handleGetReportFormat,
  handleValidatePickupDate,
  handleConvert,
  handleCheckboxBarcode,
  handleCollapse,
  handleResetCustomer,
  handleReset,
  handleDownloadCustomerReport,
  treatmentDocument,
  handleBlurTreatmentDocument,
  handlePutTreatmentDocument,
  validateTreatmentDocument,
  helpValidateTreatmentDocument,
  handleDownloadReport,
}: IProps) {
  const minDate = (d: any) => !d || d.isBefore(moment('01-01-2020'));
  const isManifest = radioButton === 'Manifest';
  const titleDatePicker =
    radioButton === 'Cycle'
      ? 'Tanggal Cycle'
      : radioButton === 'Process'
      ? 'Tanggal Upload'
      : radioButton === 'Finish'
      ? 'Tanggal Selesai'
      : radioButton === 'Delivery'
      ? 'Tanggal Kirim'
      : radioButton === 'Manifest'
      ? 'Tanggal Scan Manifest'
      : '';
  const phDatePicker =
    radioButton === 'Cycle'
      ? 'Pilih Tanggal Cycle'
      : radioButton === 'Process'
      ? 'Pilih Tanggal Upload'
      : radioButton === 'Finish'
      ? 'Pilih Tanggal Selesai'
      : radioButton === 'Delivery'
      ? 'Pilih Tanggal Kirim'
      : radioButton === 'Manifest'
      ? 'Pilih Tanggal Scan Manifest'
      : '';

  const validateStatusSuccess = deliveryStatus.filter(
    (item: any) => item === 'Success'
  );

  const validateStatusReturn = deliveryStatus.filter(
    (item: any) => item === 'Return'
  );

  const validateButtonDownload =
    pickupDate === null ||
    validatePickupDate === 'error' ||
    customer === null ||
    validateCustomer === 'error' ||
    customerProduct.length === 0 ||
    validateCustomerProduct === 'error' ||
    reportFormat === null ||
    validateReportFormat === 'error' ||
    deliveryStatus === null ||
    validateDeliveryStatus === 'error' ||
    typeDelivery.length === 0 ||
    validateTypeDelivery === 'error' ||
    (isManifest &&
      (treatmentDocument.length === 0 || validateTreatmentDocument === 'error'))
      ? true
      : false;

  const validateButtonReset =
    pickupDate === null &&
    customer === null &&
    customerProduct.length === 0 &&
    reportFormat === null &&
    deliveryStatus.length === 0 &&
    typeDelivery.length === 0
      ? true
      : pickupDate !== null ||
        customer !== null ||
        customerProduct.length !== 0 ||
        reportFormat !== null ||
        deliveryStatus.length !== 0 ||
        typeDelivery.length !== 0
      ? false
      : true;
  const selectedFormatReport = listReportFormat.find(
    value => value.id === reportFormat
  );
  const requestPhoneNumberReport =
    selectedFormatReport?.customReportType === 'CustomRequestPhoneNumber' ||
    false;

  return (
    <div>
      <SpinMaterial
        spinning={isLoading}
        style={{}}
        size={'large'}
        content={
          <CardMaterial
            extra={''}
            title={<b className="titleCard">LAPORAN PELANGGAN</b>}
            style={{ borderRadius: '10px' }}
            content={
              <div>
                <Row>
                  <Col span={12} className="spacingRight">
                    <div>
                      <Radio
                        value={'Cycle'}
                        onChange={handleRadioButton}
                        checked={radioButton === 'Cycle' ? true : false}
                      >
                        Cycle
                      </Radio>
                      <Radio
                        value={'Process'}
                        onChange={handleRadioButton}
                        checked={radioButton === 'Process' ? true : false}
                      >
                        Upload
                      </Radio>
                      <Radio
                        value={'Delivery'}
                        onChange={handleRadioButton}
                        checked={radioButton === 'Delivery' ? true : false}
                      >
                        Kirim
                      </Radio>
                      <Radio
                        value={'Finish'}
                        onChange={handleRadioButton}
                        checked={radioButton === 'Finish' ? true : false}
                      >
                        Selesai
                      </Radio>
                      <Radio
                        value={'Manifest'}
                        onChange={handleRadioButton}
                        checked={radioButton === 'Manifest' ? true : false}
                      >
                        Manifest
                      </Radio>
                    </div>
                    <div className={'spacingTopLeftReport'}>
                      <span>
                        <b>{titleDatePicker}</b>
                      </span>
                      <Row>
                        <Col span={18}>
                          <Form.Item
                            validateStatus={validatePickupDate}
                            help={helpValidatePickupDate}
                          >
                            {datePickerMonth === false ? (
                              <DatePicker
                                className={'datePickerDefault'}
                                locale={locale}
                                format={'YYYY-MM-DD'}
                                placeholder={phDatePicker}
                                onOpenChange={handleValidatePickupDate}
                                value={valuePickupDate}
                                onChange={handlePutPickupDate}
                                disabledDate={minDate}
                              />
                            ) : (
                              <MonthPicker
                                className={'datePickerDefault'}
                                locale={locale}
                                format={'YYYY-MM'}
                                placeholder={phDatePicker}
                                onOpenChange={handleValidatePickupDate}
                                value={valuePickupDate}
                                onChange={handlePutPickupDate}
                                disabledDate={minDate}
                              />
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Tooltip
                            placement="top"
                            title={'Ceklis untuk format bulanan'}
                          >
                            <Checkbox
                              style={{
                                paddingTop: 10,
                                paddingLeft: '24px',
                              }}
                              checked={datePickerMonth}
                              onChange={handleDatePickerMonth}
                            />
                          </Tooltip>
                          <span className={'checkboxSpan'}>Bulanan</span>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <span>
                        <b>Pelanggan</b>
                      </span>
                      <Form.Item
                        validateStatus={validateCustomer}
                        help={helpValidateCustomer}
                      >
                        <Select
                          loading={loadingSelectCustomer}
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          disabled={false}
                          showSearch
                          placeholder={'Pilih Pelanggan'}
                          onSelect={handleResetCustomer}
                          onChange={handlePutCustomer}
                          onBlur={handleBlurCustomer}
                          value={
                            customer === null ? (
                              <span className="colorPlaceholderDropDown">
                                Pilih Pelanggan
                              </span>
                            ) : (
                              customer
                            )
                          }
                          onFocus={handleGetCustomer}
                        >
                          {listCustomer.map((data: any, index: any) => (
                            <Option
                              key={index}
                              value={data.id}
                              label={data.name}
                            >
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Produk</b>
                      </span>
                      <Form.Item
                        validateStatus={validateCustomerProduct}
                        help={helpValidateCustomerProduct}
                      >
                        <Select
                          loading={loadingSelectCustomerProduct}
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          mode="multiple"
                          value={customerProduct}
                          disabled={customer === null ? true : false}
                          placeholder="Pilih Produk Pelanggan"
                          showSearch
                          allowClear={true}
                          onFocus={handleGetCustomerProduct}
                          onDeselect={handleBlurCustomerProduct}
                          onSelect={handleBlurCustomerProduct}
                          onBlur={handlePutCustomerProduct}
                          onChange={handlePutCustomerProduct}
                        >
                          {listCustomerProduct.length ===
                          customerProduct.length ? null : (
                            <Option key={''} value={'SetAllProduct'}>
                              {'Pilih Semua Produk'}
                            </Option>
                          )}
                          {listCustomerProduct.map((data: any, index: any) => (
                            <Option
                              key={index}
                              value={data.id}
                              label={data.name}
                            >
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Format Laporan</b>
                      </span>
                      <Form.Item
                        validateStatus={validateReportFormat}
                        help={helpValidateReportFormat}
                      >
                        <Select
                          loading={loadingSelectReportFormat}
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          disabled={
                            customerProduct === null ||
                            customerProduct.length === 0
                              ? true
                              : false
                          }
                          showSearch
                          placeholder="Pilih Format Laporan"
                          onChange={handlePutReportFormat}
                          onBlur={handleBlurReportFormat}
                          value={
                            reportFormat === null ? (
                              <span className="colorPlaceholderDropDown">
                                Pilih Format Laporan
                              </span>
                            ) : (
                              reportFormat
                            )
                          }
                          onFocus={handleGetReportFormat}
                        >
                          {listReportFormat.map((data: any, index: any) => (
                            <Option key={index} value={data.id}>
                              {data.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col span={12} className="spacingLeft">
                    <div className={'spacingTopRightReport'}>
                      <span>
                        <b>
                          {isManifest ? 'Jenis Manifest' : 'Status Pengiriman'}
                        </b>
                      </span>
                      <Form.Item
                        validateStatus={validateDeliveryStatus}
                        help={helpValidateDeliveryStatus}
                      >
                        <Select
                          style={{ width: '100%' }}
                          mode="multiple"
                          disabled={requestPhoneNumberReport}
                          showSearch
                          allowClear={true}
                          onDeselect={handleBlurDeliveryStatus}
                          onSelect={handleBlurDeliveryStatus}
                          onBlur={handleBlurDeliveryStatus}
                          onChange={handlePutDeliveryStatus}
                          placeholder={
                            isManifest
                              ? 'Pilih Jenis Manifest'
                              : 'Pilih Status Pengiriman'
                          }
                          value={deliveryStatus}
                        >
                          {!isManifest && (
                            <Option key={''} value={'OnHold'}>
                              {'OnHold'}
                            </Option>
                          )}
                          {!isManifest && (
                            <Option key={''} value={'OnProcces'}>
                              {'Proses'}
                            </Option>
                          )}
                          <Option key={''} value={'Success'}>
                            {isManifest ? 'Sukses' : 'Diterima'}
                          </Option>
                          <Option key={''} value={'Return'}>
                            {isManifest ? 'Return' : 'Dikembalikan'}
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>

                    {isManifest && (
                      <div>
                        <span>
                          <b>Perlakuan Dokumen</b>
                        </span>
                        <Form.Item
                          validateStatus={validateTreatmentDocument}
                          help={helpValidateTreatmentDocument}
                        >
                          <Select
                            mode="multiple"
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            disabled={false}
                            showSearch
                            placeholder="Pilih Perlakuan Dokumen"
                            onDeselect={handleBlurTreatmentDocument}
                            onSelect={handleBlurTreatmentDocument}
                            onBlur={handleBlurTreatmentDocument}
                            onChange={handlePutTreatmentDocument}
                            value={treatmentDocument}
                          >
                            <Option
                              key={'ReturnCustomer'}
                              value={'ReturnCustomer'}
                            >
                              {'Dikembalikan ke Pelanggan'}
                            </Option>
                            <Option key={'Destroy'} value={'Destroy'}>
                              {'Dihancurkan'}
                            </Option>
                          </Select>
                        </Form.Item>
                      </div>
                    )}

                    {!isManifest && (
                      <>
                        <div>
                          <span>
                            <b>Status Diterima</b>
                          </span>
                          <Form.Item>
                            <Select
                              style={{ width: '100%' }}
                              mode="multiple"
                              showSearch
                              allowClear={true}
                              disabled={
                                (validateStatusSuccess.length === 0
                                  ? true
                                  : false) || requestPhoneNumberReport
                              }
                              onDeselect={handleBlurStatusSuccess}
                              onSelect={handleBlurStatusSuccess}
                              onBlur={handleBlurStatusSuccess}
                              onChange={handlePutStatusSuccess}
                              placeholder="Pilih Status"
                              value={statusSuccess}
                            >
                              {listStatusSuccess.length ===
                              statusSuccess.length ? null : (
                                <Option key={''} value={'SetAllStatus'}>
                                  {'Pilih Semua Status'}
                                </Option>
                              )}
                              {listStatusSuccess.map(
                                (data: any, index: any) => (
                                  <Option key={index} value={data}>
                                    {data}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                        <div>
                          <span>
                            <b>Status Dikembalikan</b>
                          </span>
                          <Form.Item>
                            <Select
                              style={{ width: '100%' }}
                              mode="multiple"
                              showSearch
                              allowClear={true}
                              disabled={
                                validateStatusReturn.length === 0 ? true : false
                              }
                              onDeselect={handleBlurStatusReturn}
                              onSelect={handleBlurStatusReturn}
                              onBlur={handleBlurStatusReturn}
                              onChange={handlePutStatusReturn}
                              placeholder="Pilih Status"
                              value={statusReturn}
                            >
                              {listStatusReturn.length ===
                              statusReturn.length ? null : (
                                <Option key={''} value={'SetAllStatus'}>
                                  {'Pilih Semua Status'}
                                </Option>
                              )}
                              {listStatusReturn.map((data: any, index: any) => (
                                <Option key={index} value={data}>
                                  {data}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div>
                          <span>
                            <b>Tipe Pengiriman</b>
                          </span>
                          <Form.Item
                            validateStatus={validateTypeDelivery}
                            help={helpValidateTypeDelivery}
                          >
                            <Select
                              style={{ width: '100%' }}
                              mode="multiple"
                              disabled={false}
                              onDeselect={handleBlurTypeDelivery}
                              onSelect={handleBlurTypeDelivery}
                              onBlur={handleBlurTypeDelivery}
                              onChange={handlePutTypeDelivery}
                              placeholder="Pilih Status"
                              value={typeDelivery}
                            >
                              <Option key={''} value={'reguler'}>
                                {'Reguler'}
                              </Option>
                              <Option key={''} value={'KU'}>
                                {'Kirim Ulang'}
                              </Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div>
                          <Row>
                            <Col span={8}>
                              <Checkbox
                                onChange={handlePutCheckBoxFileName}
                                checked={checkboxFileName}
                              />
                              <span className={'checkboxSpan'}>File Name</span>
                            </Col>
                            <Col span={8}>
                              <Checkbox
                                onChange={handlePutCheckBoxFullData}
                                checked={checkboxFullData}
                              />
                              <span className={'checkboxSpan'}>
                                Data Lengkap
                              </span>
                            </Col>
                            <Col span={8}>
                              <Checkbox
                                onChange={handlePutCheckBoxToDayData}
                                checked={CheckBoxToDayData}
                              />
                              <span className={'checkboxSpan'}>To Day</span>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>

                <Row>
                  <div style={{ float: 'left' }}>
                    <Button
                      type="link"
                      onClick={handleCollapse}
                      style={{ color: '#11BEFF' }}
                    >
                      Pencocokan Data
                    </Button>
                    <Icon
                      type={collapse === false ? 'right' : 'down'}
                      style={{ marginLeft: 5, fontSize: 10 }}
                    />
                  </div>
                </Row>
                {collapse === true ? (
                  <Row style={{ marginTop: 15 }}>
                    <Col span={12} className={'spacingRight'}>
                      <div className="fileUploadInput" style={{ marginTop: 5 }}>
                        <span>
                          <b>Cocokan data dengan (xls, csv) </b>
                        </span>
                        <Input
                          onChange={handleConvert}
                          value={fileName}
                          suffix={<Icon type="upload" />}
                          multiple={false}
                          type="file"
                          accept=".xlsx, .xls, .csv"
                        />
                      </div>
                    </Col>
                    <Col span={12} className={'spacingLeft'}>
                      <div>
                        <span>
                          <b>
                            {checkboxBarcode === false ? 'Waybill' : 'Barcode'}{' '}
                            terdapat pada kolom ke -{' '}
                          </b>
                        </span>
                        <Row>
                          <Col span={18}>
                            <FormInputTextMaterial
                              validateType={true}
                              value={indexColumn}
                              className={''}
                              disabled={fileData === null ? true : false}
                              addonBefore={''}
                              placeholder={'Masukan Nomor Index Kolom'}
                              prefix={''}
                              maxLength={'2'}
                              style={{ borderRadius: '10px' }}
                              size=""
                              type={''}
                              onChange={handlePutColumn}
                              onBlur={handlePutColumn}
                              validatestatus={validateColumn}
                              help={helpValidateColumn}
                              onPressEnter={''}
                            />
                          </Col>
                          <Col span={6}>
                            <Tooltip
                              placement="top"
                              title={'Ceklis untuk barcode'}
                            >
                              <Checkbox
                                disabled={fileData === null ? true : false}
                                style={{
                                  paddingTop: 10,
                                  paddingLeft: '24px',
                                }}
                                checked={checkboxBarcode}
                                onChange={handleCheckboxBarcode}
                              />
                            </Tooltip>
                            <span className={'checkboxSpan'}>Barcode</span>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}

                <Row>
                  <div className="spacingDownloadButton">
                    <ButtonMaterial
                      disabled={validateButtonDownload}
                      handleSubmit={handleDownloadCustomerReport}
                      teksButton={<b>Download</b>}
                      size={''}
                      shape={'round'}
                      className={
                        validateButtonDownload === true
                          ? 'validateDownloadButtonDisabled'
                          : 'validateDownloadBtnEnable2'
                      }
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                  <div>
                    <ButtonMaterial
                      disabled={validateButtonReset}
                      handleSubmit={handleReset}
                      teksButton={<b>Reset</b>}
                      size={'middle'}
                      shape={'round'}
                      className={'validateResetButtonEnable'}
                      type={'primary'}
                      icon={''}
                      style={
                        validateButtonReset === true
                          ? {
                              height: '35px',
                              float: 'right',
                              width: '100px',
                            }
                          : {
                              float: 'right',
                              background: '#FF5858',
                              borderColor: '#FF5858',
                              colorFont: '#FFFFFF',
                              height: '35px',
                              width: '100px',
                            }
                      }
                    />
                  </div>
                </Row>
              </div>
            }
          />
        }
      />
    </div>
  );
}
