import * as ActionDeliveryBranch from '../../action/Branch/ActionDeliveryBranch';
import * as ActionLogin from '../../../module/Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_getDeliveryBranch() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/branchs/monitor',
      { headers: config }
    );
    yield put(ActionDeliveryBranch.setDeliveryBranch(data));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      yield put(changeLoadingFalse());
      const propsError = ErrorMassage('pengiriman posko');
      const code = propsError.filter((data: any) =>
        data.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_getDeliveryBranch() {
  yield takeLatest('GET_DELIVERY_BRANCH', S_getDeliveryBranch);
}
