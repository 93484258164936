import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import IndexComponent from '../../../components/Content/Voip/IndexComponent';
import * as LastAccess from '../../../core/action/LastAccess/index';
import * as ActionServerVoip from '../../../core/action/Voip/Server/ActionServer';

interface IProps {
  actionServerVoip: any;
  actionLastAccess: any;
  Fiture: any;
  showAdminTable: any;
  showCourierTable: any;
  roleAccount: any;
}

class IndexContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleChangeTabs = this.handleChangeTabs.bind(this);
  }

  handleChangeTabs(event: any) {
    this.props.actionServerVoip.changeClearListServerVoip();

    if (event === '1') {
      this.props.actionLastAccess.changeLastAccessFiture('dataActiveTable');
    } else {
      this.props.actionLastAccess.changeLastAccessFiture('serverVoipTable');
      this.props.actionServerVoip.getListServerVoip();
    }
  }

  render() {
    return (
      <IndexComponent
        roleAccount={this.props.roleAccount}
        lastAccessFiture={this.props.Fiture}
        handleChangeTabs={this.handleChangeTabs}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  Fiture: state.LastAccess.Fiture,
  roleAccount: state.Auth.Role,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionServerVoip: bindActionCreators(ActionServerVoip, dispatch),
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IndexContainer);
