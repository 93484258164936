import React, { useState } from 'react';
import { Row, Col, Table } from 'antd';
import { SelectOptionsInterface } from 'interface/general';
import { BagStatus, BagTypes } from 'lib/constants';
import CountData from 'components/CountData';
import SelectField from 'components/SelectField';
import SearchMatrial from 'materials/SearchMatrial';
import ButtonMaterial from 'materials/ButtonMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import CardMaterial from 'materials/CardMaterial';
import moment from 'moment-timezone';
import { DataFilterBaggingType } from 'module/DeliveryBetweenPosts/Component';
import { DataTableResProps } from 'interface/general';

type DataCountingBagProps = {
  proccess: number;
  success: number;
  total: number;
};

type BaggingDocumentTableProps = {
  handleShowModalForm: () => void;
  handleDownloadFile: () => void;
  handleShowModalScan: (id: number) => void;
  handleEditBagging: (id: number) => void;
  handleDetailBagging: (id: number) => void;
  handleDeleteBagging: (id: number) => void;
  handleDownloadList: ({
    id,
    barcodeBag,
  }: {
    id: number;
    barcodeBag: string;
  }) => void;
  DownloadBagTypes: SelectOptionsInterface[];
  selectedDownloadBag: string;
  handleSelectedDownloadBag: (value: string) => void;
  isLoadingTableBag: boolean;
  dataTableBag: DataTableResProps;
  dataCountingBag: DataCountingBagProps;
  handleSearchBarcode: (barcode: string) => void;
  handlePage: (value: number) => void;
  dataFilterBagging: DataFilterBaggingType;
};

export default function BaggingDocumentTable({
  handleShowModalForm,
  handleShowModalScan,
  handleEditBagging,
  handleDetailBagging,
  handleDeleteBagging,
  DownloadBagTypes,
  selectedDownloadBag,
  handleSelectedDownloadBag,
  isLoadingTableBag,
  dataTableBag,
  dataCountingBag,
  handleSearchBarcode,
  handlePage,
  handleDownloadFile = () => undefined,
  handleDownloadList = () => undefined,
  dataFilterBagging,
}: BaggingDocumentTableProps) {
  const [barcodeSearch, setBarcodeSearch] = useState('');
  const pagePagination = dataTableBag?.page + 1 || 0;
  const totalPagination = dataTableBag?.totalPages * 10 || 0;
  const looppage = (pagePagination - 1) * 10 + 1;
  const columnsTable = [
    {
      title: 'No',
      render: (text: any, record: any, index: any) => (
        <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
      ),
      width: 80,
    },
    {
      title: 'Barcode Bag',
      dataIndex: 'barcodeBagging',
      width: 200,
    },
    {
      title: 'Posko Asal',
      dataIndex: 'originalBranchName',
    },
    {
      title: 'Posko Tujuan',
      dataIndex: 'destinationBranchName',
    },
    {
      title: 'Status',
      dataIndex: 'baggingStatusDocument',
      render: (text: string) => (
        <p
          style={{
            color: text === 'Process' ? '#1890FF' : '#00AB44',
            margin: 0,
          }}
        >
          {BagStatus.find(value => value.id === text)?.name}
        </p>
      ),
    },
    {
      title: 'Jenis',
      dataIndex: 'baggingType',
      render: (text: string) => BagTypes.find(value => value.id === text)?.name,
    },
    {
      title: 'Pembuat Bag',
      dataIndex: 'createdActor',
    },
    {
      title: 'Tanggal Input',
      dataIndex: 'createdBaggingTime',
      render: (text: string) => <>{moment(text).format('DD-MM-YYYY')}</>,
    },
    {
      title: 'Total Waybill',
      dataIndex: 'totalWaybill',
    },
    {
      title: 'Aksi',
      width: 200,
      render: record => (
        <div style={{ display: 'flex', gap: 8, justifyContent: 'center' }}>
          <ButtonMaterial
            icon="download"
            type="primary"
            handleSubmit={() =>
              handleDownloadList({
                id: record.id,
                barcodeBag: record.barcodeBagging,
              })
            }
            style={{ background: '#11beff', color: 'white', border: 'none' }}
            tooltip="Download List Waybill"
          />
          {record.totalWaybill > 0 ? (
            <ButtonMaterial
              icon="eye"
              type="download"
              style={{ background: '#874FFF', color: 'white' }}
              handleSubmit={() => handleDetailBagging(record.id)}
              tooltip="Lihat Data"
            />
          ) : (
            <ButtonMaterial
              icon="delete"
              type="danger"
              // style={{ background: '#874FFF', color: 'white' }}
              handleSubmit={() => handleDeleteBagging(record.id)}
              tooltip="Hapus Data"
            />
          )}
          <ButtonMaterial
            icon="edit"
            type="download"
            style={{
              ...(record.baggingStatusDocument !== 'Success' && {
                background: '#FFE600',
                color: 'white',
              }),
            }}
            handleSubmit={() => handleEditBagging(record.id)}
            disabled={record.baggingStatusDocument === 'Success'}
            tooltip="Edit Data"
          />
          <ButtonMaterial
            icon="barcode"
            type="success"
            style={{
              ...(record.baggingStatusDocument !== 'Success' && {
                background: '#31E116',
                color: 'white',
              }),
            }}
            handleSubmit={() => handleShowModalScan(record.id)}
            disabled={record.baggingStatusDocument === 'Success'}
            tooltip="Scan Waybill"
          />
        </div>
      ),
    },
  ];

  return (
    <SpinMaterial
      spinning={isLoadingTableBag}
      size={'large'}
      content={
        <div>
          <CardMaterial
            style={{ borderRadius: '10px 10px 0 0' }}
            content={
              <div>
                <Row>
                  <Col span={18}>
                    <div style={{ display: 'flex', gap: 16 }}>
                      <span className="titleSearchCard">SEMUA BAG</span>
                      <SearchMatrial
                        style={{ borderRadius: '10px', width: '50%' }}
                        placeholder="Cari berdasarkan barcode bag"
                        value={barcodeSearch}
                        onChange={e => setBarcodeSearch(e.target.value)}
                        onPressEnter={e => handleSearchBarcode(e.target.value)}
                        maxLength={20}
                      />
                      <CountData
                        label="Proses"
                        value={dataCountingBag?.proccess || 0}
                      />
                      <CountData
                        label="Selesai"
                        value={dataCountingBag?.success || 0}
                        variant="success"
                      />
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className="floatRight">
                      <ButtonMaterial
                        teksButton={<b>TAMBAHKAN BAG BARU</b>}
                        className={'btnLink'}
                        type={'link'}
                        handleSubmit={handleShowModalForm}
                      />

                      <ButtonMaterial
                        shape={'circle'}
                        className={'btnAdd'}
                        icon={'plus'}
                        handleSubmit={handleShowModalForm}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            }
          />
          <Table
            columns={columnsTable}
            bordered
            dataSource={dataTableBag?.data || []}
            scroll={{ x: 1300 }}
            pagination={{
              onChange: page => {
                handlePage(page - 1);
              },
              pageSize: 10,
              total: totalPagination,
              current: pagePagination === 0 ? 1 : pagePagination,
            }}
            footer={() => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                  gap: 8,
                }}
              >
                <SelectField
                  placeholder="Pilih Jenis Form"
                  value={selectedDownloadBag}
                  data={DownloadBagTypes}
                  style={{ width: '200px' }}
                  onChange={value => handleSelectedDownloadBag(value)}
                />
                <ButtonMaterial
                  icon="download"
                  type="download"
                  style={{
                    ...(selectedDownloadBag &&
                      dataTableBag?.data.length >= 0 && {
                        background: '#874FFF',
                        color: 'white',
                      }),
                  }}
                  disabled={
                    !selectedDownloadBag || dataTableBag?.data.length === 0
                  }
                  handleSubmit={handleDownloadFile}
                />
                <p style={{ margin: 0, fontWeight: 500 }}>
                  Total Entri ({dataTableBag?.totalDatas || 0})
                </p>
              </div>
            )}
          />
        </div>
      }
    />
  );
}
