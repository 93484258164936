import { Col, Row } from 'antd';

import CardMaterial from 'materials/CardMaterial';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import { districtCode } from 'core/enumerable/General';
import SelectField from 'components/SelectField';
import InputDatePicker from 'components/InputDatePicker';
import { Field, Form } from 'react-final-form';
import Button from 'components/Button';
import { InitialFormType } from 'module/Report/Container/Mis/MisReportContainer';
import moment from 'moment';

interface IProps {
  isLoading: any;
  //Mee
  initialForm: InitialFormType;
  handleChangeForm: (name: string, value: any) => void;
  handleResetForm: () => void;
  handleDownload: () => void;
  isLoadingProducts: boolean;
  isLoadingCustomers: boolean;
  dataProducts: any[];
  dataCustomers: any[];
}
export default function MisReportComponent({
  isLoading,
  //Mee
  initialForm,
  handleChangeForm,
  handleResetForm,
  handleDownload,
  isLoadingProducts = false,
  isLoadingCustomers = false,
  dataProducts,
  dataCustomers,
}: IProps) {
  const disabledDatePickup = current => {
    const selectedDates = initialForm.datePickup;
    if (!selectedDates || selectedDates.length === 0) {
      return current && current > moment().endOf('day');
    } else {
      const startDate = selectedDates[0];
      if (!startDate) {
        return current && current > moment().endOf('day');
      } else {
        return (
          current < moment(startDate).startOf('day') ||
          (current && current > moment(startDate).add(1, 'month').endOf('day'))
        );
      }
    }
  };

  return (
    <div>
      <SpinMaterial
        spinning={isLoading}
        style={{}}
        size={'large'}
        content={
          <CardMaterial
            extra={''}
            title={<b className="titleCard">MAILING INFORMATION SYSTEM</b>}
            style={{ borderRadius: '10px' }}
            content={
              <Form
                validate={values => {
                  const errors = {} as any;
                  if (!values.customer) {
                    errors.customer = 'Pelanggan wajib dipilih';
                  }
                  if (
                    values?.district?.length === 0 &&
                    values.typeReport !== 'tabulasi'
                  ) {
                    errors.district = 'Wilayah wajib dipilih';
                  }
                  if (
                    !values.datePickup ||
                    values.datePickup.length !== 2 ||
                    !values.datePickup[0] ||
                    !values.datePickup[1]
                  ) {
                    errors.datePickup = 'Tanggal Pickup wajib dipilih';
                  }
                  if (values?.product?.length === 0) {
                    errors.product = 'Produk wajib dipilih';
                  }
                  return errors;
                }}
                initialValues={{ ...initialForm }}
                onSubmit={handleDownload}
              >
                {({ handleSubmit, values, valid, form }) => (
                  <div>
                    <Row gutter={[32, 32]}>
                      <Col
                        span={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 16,
                        }}
                      >
                        <Field name="typeReport">
                          {({ input, meta }) => (
                            <SelectField
                              label="Jenis Laporan MIS"
                              placeholder="Pilih Jenis Laporan MIS"
                              data={[
                                { id: 'mis', label: 'MIS' },
                                { id: 'cn', label: 'CN' },
                                { id: 'tabulasi', label: 'Tabulasi' },
                                { id: 'uob-inv', label: 'UOB Inv' },
                              ]}
                              onChange={value => {
                                handleChangeForm('typeReport', value);
                                if (value === 'tabulasi') {
                                  handleChangeForm('district', []);
                                } else if (values.district.length === 0) {
                                  handleChangeForm('district', ['Jabodetabek']);
                                }
                              }}
                              labelKey="label"
                              value={input.value}
                              onBlur={input.onBlur}
                              validate
                              validateStatus={
                                meta.error && meta.touched
                                  ? 'error'
                                  : 'validating'
                              }
                              errorMessage={
                                meta.error && meta.touched && meta.error
                              }
                            />
                          )}
                        </Field>
                        <Field name="customer">
                          {({ input, meta }) => (
                            <SelectField
                              validate
                              label="Pelanggan"
                              placeholder="Pilih Pelanggan"
                              data={dataCustomers}
                              labelKey="name"
                              valueKey="id"
                              loading={isLoadingCustomers}
                              onChange={value =>
                                handleChangeForm('customer', value)
                              }
                              onBlur={input.onBlur}
                              value={input.value}
                              validateStatus={
                                meta.error && meta.touched
                                  ? 'error'
                                  : 'validating'
                              }
                              errorMessage={
                                meta.error && meta.touched && meta.error
                              }
                            />
                          )}
                        </Field>
                        <Field name="district">
                          {({ input, meta }) => (
                            <SelectField
                              label="Wilayah"
                              placeholder="Masukan Wilayah Lengkap"
                              mode="multiple"
                              disabled={values.typeReport === 'tabulasi'}
                              data={districtCode}
                              labelKey="label"
                              valueKey="value"
                              onBlur={input.onBlur}
                              value={input.value}
                              onChange={value =>
                                handleChangeForm('district', value)
                              }
                              validate
                              validateStatus={
                                meta.error && meta.touched
                                  ? 'error'
                                  : 'validating'
                              }
                              errorMessage={
                                meta.error && meta.touched && meta.error
                              }
                            />
                          )}
                        </Field>
                      </Col>
                      <Col
                        span={12}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 16,
                        }}
                      >
                        <Field name="datePickup">
                          {({ input, meta }) => (
                            <InputDatePicker
                              label="Tanggal Pickup"
                              mode="date"
                              type="range"
                              format="DD-MM-YYYY"
                              firstPlaceholder="Dari tanggal"
                              secondPlaceholder="Sampai tanggal"
                              error={meta.error && meta.touched && meta.error}
                              value={input.value}
                              onChange={value =>
                                handleChangeForm('datePickup', value)
                              }
                              onCalendarChange={value =>
                                handleChangeForm('datePickup', value)
                              }
                              onBlur={input.onBlur}
                              disabledDate={currentDate =>
                                disabledDatePickup(currentDate)
                              }
                            />
                          )}
                        </Field>
                        <Field name="product">
                          {({ input, meta }) => (
                            <SelectField
                              label="Produk"
                              placeholder="Pilih Produk Pelanggan"
                              mode="multiple"
                              data={dataProducts}
                              labelKey="name"
                              valueKey="id"
                              disabled={!values.customer}
                              allowClear
                              loading={isLoadingProducts}
                              onBlur={input.onBlur}
                              onChange={value =>
                                handleChangeForm('product', value)
                              }
                              validate
                              value={input.value}
                              validateStatus={
                                meta.error && meta.touched
                                  ? 'error'
                                  : 'validating'
                              }
                              errorMessage={
                                meta.error && meta.touched && meta.error
                              }
                            />
                          )}
                        </Field>
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'flex-end',
                        gap: 24,
                        marginTop: 24,
                      }}
                    >
                      <Button
                        text="Reset"
                        onClick={() => {
                          handleResetForm();
                          form.restart();
                        }}
                        variant="danger"
                        disabled={!valid}
                      />
                      <Button
                        text="Download"
                        onClick={handleSubmit}
                        disabled={!valid}
                      />
                    </div>
                  </div>
                )}
              </Form>
            }
          />
        }
      />
    </div>
  );
}
