import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import * as toggle from '../../../../core/action/toggle';
import * as ActionServerVoip from '../../../../core/action/Voip/Server/ActionServer';
import ButtonCheckServerVoipComponent from '../../../../components/Content/Voip/ServerVoip/ButtonCheckServerVoipComponent';
import { connect } from 'react-redux';

interface IProps {
  ipAddress: any;
  actionToggle: any;
  actionServerVoip: any;
}

class ButtonCheckServerVoipContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleCheckServerVoip = this.handleCheckServerVoip.bind(this);
  }
  handleCheckServerVoip() {
    const input = this.props.ipAddress;
    const fields = input.split('.');
    const ip1 = fields[0];
    const ip2 = fields[1];
    const ip3 = fields[2];
    const ip4 = fields[3];
    const fields1 = input.split(':');
    const ipPort = fields1[1];
    const ip4new = ip4.replace(':' + ipPort, '');

    this.props.actionServerVoip.setServerVoipIpServer1(ip1);
    this.props.actionServerVoip.setServerVoipIpServer2(ip2);
    this.props.actionServerVoip.setServerVoipIpServer3(ip3);
    this.props.actionServerVoip.setServerVoipIpServer4(ip4new);
    this.props.actionServerVoip.setServerVoipIpPort(ipPort);
    this.props.actionServerVoip.submitCheckServerVoip();
  }
  render() {
    return (
      <React.Fragment>
        <ButtonCheckServerVoipComponent
          handleCheckServerVoip={this.handleCheckServerVoip}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  TypeToggle: state.Toggle.jenisToggle,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
  actionServerVoip: bindActionCreators(ActionServerVoip, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ButtonCheckServerVoipContainer);
