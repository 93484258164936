import React, { Component } from 'react';
import { getTabMenus } from 'lib/util';
import FormRestoreDataContainer from '../../../containers/Content/RestoreData/FormRestoreDataContainer';
import TableRestoreDataContainer from '../../../containers/Content/RestoreData/TableRestoreDataContainer';
import RestoreCustomerContainer from 'module/RestoreData/Customer/Container/RestoreCustomerContainer';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

interface IProps {
  roleAccount: any;
  lastAccessFiture: any;
  handleChangeTabsRestoreData: any;
  sideMenus: any;
}

export default class Index extends Component<IProps> {
  render() {
    let index = '1';
    const tabMenus = getTabMenus(this.props.sideMenus, 'Hapus Data') || [];
    const componentTabMenus = {
      CSV: (
        <div>
          <div>
            <FormRestoreDataContainer />
          </div>
          <div>
            <TableRestoreDataContainer />
          </div>
        </div>
      ),
      Waybill: (
        <div>
          <div>
            <FormRestoreDataContainer />
          </div>
          <div>
            <TableRestoreDataContainer />
          </div>
        </div>
      ),
      'Hapus Data Pelanggan': <RestoreCustomerContainer />,
    };

    if (this.props.lastAccessFiture === 'restoreDataCsv') {
      index = '1';
    } else if (this.props.lastAccessFiture === 'restoreDataWaybill') {
      index = '2';
    } else if (this.props.lastAccessFiture === 'restoreCustomer') {
      index = '3';
    }
    return (
      <div>
        <div>
          <Tabs
            className="tabDefault"
            defaultActiveKey={index}
            onChange={this.props.handleChangeTabsRestoreData}
            activeKey={index}
          >
            {tabMenus.map((item, index) => (
              <TabPane tab={<b>{item.label}</b>} key={index + 1}>
                {componentTabMenus[item.label]}
              </TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    );
  }
}
