import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import IndexComponent from '../../../../../components/Content/Delivery/Resend/UploadSoftcopy/IndexComponent';
import * as LastAccess from '../../../../../core/action/LastAccess/index';
import * as ActionUploadSoftcopy from '../../../../../core/action/Delivery/UploadSoftcopy/ActionUploadSoftcopy';
import * as ActionUnggahDocument from '../../../../../core/action/Delivery/Document/ActionFormUnggahDocument';

interface IProps {
  actionLastAccess: any;
  actionUploadSoftcopy: any;
  actionUnggahDocument: any;
  Fiture: any;
  sideMenus: any;
}

class IndexContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleChangeTabs = this.handleChangeTabs.bind(this);
  }

  handleChangeTabs(event: any) {
    this.props.actionUploadSoftcopy.clearUploadSoftcopy();
    this.props.actionUnggahDocument.clearAllFormUnggahCsv();

    if (event === '1') {
      this.props.actionLastAccess.changeLastAccessFiture('resendDelivery');
    } else if (event === '2') {
      this.props.actionLastAccess.changeLastAccessFiture('resendOneDelivery');
    }
  }

  render() {
    return (
      <IndexComponent
        lastAccessFiture={this.props.Fiture}
        handleChangeTabs={this.handleChangeTabs}
        sideMenus={this.props.sideMenus}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  Fiture: state.LastAccess.Fiture,
  sideMenus: state.Auth.SideMenus,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
  actionUploadSoftcopy: bindActionCreators(ActionUploadSoftcopy, dispatch),
  actionUnggahDocument: bindActionCreators(ActionUnggahDocument, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IndexContainer);
