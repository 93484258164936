export function changeFileCsv(Csv: any) {
  return {
    type: 'CHANGE_FILE_CSV',
    Csv,
  };
}
export function changeCustomerId(CustomerId: any) {
  return {
    type: 'CHANGE_CUSTOMER_ID',
    CustomerId,
  };
}
export function changeProductType(ProductType: any) {
  return {
    type: 'CHANGE_PRODUCT_TYPE',
    ProductType,
  };
}
export function changeCustomerProductId(CustomerProductId: any) {
  return {
    type: 'CHANGE_CUSTOMER_PRODUCT_ID',
    CustomerProductId,
  };
}
export function changeCustomCsvCustomer(CustomCsvCustomer: any) {
  return {
    type: 'CHANGE_CUSTOM_CSV_CUSTOMER',
    CustomCsvCustomer,
  };
}
export function changeCustomerDeliveryDate(DeliveryDate: any) {
  return {
    type: 'CHANGE_CUSTOMER_DELIVERY_DATE',
    DeliveryDate,
  };
}
export function changeCustomerUploadDate(UploadDate: any) {
  return {
    type: 'CHANGE_CUSTOMER_UPLOAD_DATE',
    UploadDate,
  };
}
export function changeCustomerFileName(FileName: any) {
  return {
    type: 'CHANGE_CUSTOMER_FILE_NAME',
    FileName,
  };
}
export function changeCountSuccess(CountSuccess: any) {
  return {
    type: 'CHANGE_COUNT_SUCCESS',
    CountSuccess,
  };
}
export function changeCountFailed(CountFailed: any) {
  return {
    type: 'CHANGE_COUNT_FAILED',
    CountFailed,
  };
}
export function changeResultUploadData(ResultUploadData: any) {
  return {
    type: 'CHANGE_RESULT_UPLOAD_DATA',
    ResultUploadData,
  };
}
export function changeListResultUploadData(ListResultUploadData: any) {
  return {
    type: 'CHANGE_LIST_RESULT_UPLOAD_DATA',
    ListResultUploadData,
  };
}
export function fetchListDataStatusUpload(listStatusUploadDocument: any) {
  return {
    type: 'FETCH_LIST_DATA_STATUS_UPLOAD_DOCUMENT',
    listStatusUploadDocument,
  };
}
export function fetchDetailStatusUploadDocument(
  detailStatusUploadDocument: any
) {
  return {
    type: 'FETCH_DETAIL_STATUS_UPLOAD_DOCUMENT',
    detailStatusUploadDocument,
  };
}
export function fetchDocumentId(documentId: any) {
  return {
    type: 'FETCH_DOCUMENT_ID',
    documentId,
  };
}
export function clearFileCsv() {
  return {
    type: 'CLEAR_FILE_CSV',
  };
}
export function clearCustomerId() {
  return {
    type: 'CLEAR_CUSTOMER_ID',
  };
}
export function clearCustomCsvCustomer() {
  return {
    type: 'CLEAR_CUSTOM_CSV_CUSTOMER',
  };
}
export function clearProductType() {
  return {
    type: 'CLEAR_PRODUCT_TYPE',
  };
}
export function clearCustomerProduct() {
  return {
    type: 'CLEAR_CUSTOMER_PRODUCT',
  };
}
export function clearAllFormUnggahCsv() {
  return {
    type: 'CLEAR_ALL_FORM_UNGGAH_CSV',
  };
}
export function uploadCsv() {
  return {
    type: 'UPLOAD_CSV',
  };
}
export function getListDataStatusUpload() {
  return {
    type: 'GET_LIST_DATA_STATUS_UPLOAD_DOCUMENT',
  };
}
export function getDetailDocumentStatusUpload() {
  return {
    type: 'GET_DETAIL_DOCUMENT_STATUS_UPLOAD_DOCUMENT',
  };
}
export function clearDetailStatusUploadDocument() {
  return {
    type: 'CLEAR_DETAIL_STATUS_UPLOAD_DOCUMENT',
  };
}

export function changeNextUploadCsv() {
  return {
    type: 'CHANGE_NEXT_UPLOAD_CSV',
  };
}

export function downloadResultUploadCsv() {
  return {
    type: 'DOWNLOAD_RESULT_UPLOAD_CSV',
  };
}
