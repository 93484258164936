export function GetTableHoliday()
{
    return({
        type:"GET_TABLE_HOLIDAY"
    })
}
export function SetTableHoliday(TableHoliday:any)
{
    return({
        type:"SET_TABLE_HOLIDAY",
        TableHoliday
    })
}
export function ClearTableHoliday()
{
    return({
        type:"CLEAR_TABLE_HOLIDAY"
    })
}