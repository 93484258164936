export function changeManifestCustomerForm(ManifestCustomerForm: any) {
  return {
    type: 'CHANGE_MANIFEST_CUSTOMER_FORM',
    ManifestCustomerForm,
  };
}
export function changeManifestProductForm(ManifestProductForm: any) {
  return {
    type: 'CHANGE_MANIFEST_PRODUCT_FORM',
    ManifestProductForm,
  };
}
export function changeManifestIsMonthly() {
  return {
    type: 'CHANGE_MANIFEST_IS_MONTHLY',
  };
}
export function downloadResultCountManifest(Condition:any) {
  return {
    type: 'DOWNLOAD_RESULT_COUNT_MANIFEST',
    Condition
  };
}
export function changeManifestBorWForm(ManifestBorWForm: any) {
  return {
    type: 'CHANGE_MANIFEST_B_OR_W_FORM',
    ManifestBorWForm,
  };
}
export function changeManifestGroupForm(ManifestGroupForm: any) {
  return {
    type: 'CHANGE_MANIFEST_GROUP_FORM',
    ManifestGroupForm,
  };
}
export function changeManifestCheckboxForm(ManifestCheckboxForm: any) {
  return {
    type: 'CHANGE_MANIFEST_CHECKBOX_FORM',
    ManifestCheckboxForm,
  };
}
export function changeManifestRadioButtonForm(ManifestRadioButtonForm: any) {
  return {
    type: 'CHANGE_MANIFEST_RADIO_BUTTON_FORM',
    ManifestRadioButtonForm,
  };
}
export function changeManifestCycleDateForm(ManifestCycleDateForm: any) {
  return {
    type: 'CHANGE_MANIFEST_CYCLE_DATE_FORM',
    ManifestCycleDateForm,
  };
}
export function changeListGroup(ListGroup: any) {
  return {
    type: 'CHANGE_LIST_GROUP',
    ListGroup,
  };
}
export function changeTableScanManifest(TableScanManifest: any) {
  return {
    type: 'CHANGE_TABLE_SCAN_MANIFEST',
    TableScanManifest,
  };
}
export function changeScanStatus(ScanStatus: any) {
  return {
    type: 'CHANGE_SCAN_STATUS',
    ScanStatus,
  };
}
export function changeBorWScanned(BorWScanned: any) {
  return {
    type: 'CHANGE_SCANNED_STATUS',
    BorWScanned,
  };
}
export function changeScannedByUser(ScannedByUser: any) {
  return {
    type: 'CHANGE_SCANNED_BY_USER',
    ScannedByUser,
  };
}
export function changeShowTable(ShowTable: any) {
  return {
    type: 'CHANGE_SHOW_TABLE_SCAN_MANIFEST',
    ShowTable,
  };
}
export function changeCountScanned(CountScanned: any) {
  return {
    type: 'CHANGE_COUNT_SCANNED',
    CountScanned,
  };
}
export function changeCountUnScanned(CountUnScanned: any) {
  return {
    type: 'CHANGE_COUNT_UN_SCANNED',
    CountUnScanned,
  };
}
export function changeTotalScanned(TotalScanned: any) {
  return {
    type: 'CHANGE_TOTAL_SCANNED',
    TotalScanned,
  };
}
export function changeScannedId(ScannedId: any) {
  return {
    type: 'CHANGE_SCANNED_ID',
    ScannedId,
  };
}
export function changeLoadingGroupName() {
  return {
    type: 'CHANGE_LOADING_GROUP_NAME',
  };
}
export function changeLoadingTypeGroupName(LoadingTypeGroupName: any) {
  return {
    type: 'CHANGE_LOADING_TYPE_GROUP_NAME',
    LoadingTypeGroupName,
  };
}
export function changeSearchValue(SearchValue: any) {
  return {
    type: 'CHANGE_SEARCH_VALUE_MANIFEST_FORM',
    SearchValue,
  };
}
export function fetchResponseScan(ResponseScan: any) {
  return {
    type: 'FETCH_RESPONSE_SCAN_MANIFEST',
    ResponseScan,
  };
}
export function fetchListMultipleBarcode(ListMultipleBarcode: any) {
  return {
    type: 'FETCH_LIST_MULTIPLE_BARCODE',
    ListMultipleBarcode,
  };
}
export function changeMultipleBarcodeSelected(MultipleBarcodeSelected: any) {
  return {
    type: 'CHANGE_MULTIPLE_BARCODE_SELECTED',
    MultipleBarcodeSelected,
  };
}
export function scanManifestMultipleBarcode() {
  return {
    type: 'SCAN_MANIFEST_MULTIPLE_BARCODE',
  };
}
export function clearMultipleBarcodeSelected() {
  return {
    type: 'CLEAR_MULTIPLE_BARCODE_SELECTED',
  };
}
export function clearResponseScanManifest() {
  return {
    type: 'CLEAR_RESPONSE_SCAN_MANIFEST',
  };
}
export function clearListMultipleBarcode() {
  return {
    type: 'CLEAR_LIST_MULTIPLE_BARCODE',
  };
}
export function getDuplicateBarcodeScanManifest() {
  return {
    type: 'GET_DUPLICATE_BARCODE_SCAN_MANIFEST',
  };
}
export function getSearch() {
  return {
    type: 'GET_SEARCH_MANIFEST_DATA_TABLE',
  };
}
export function clearSearchForm() {
  return {
    type: 'CLEAR_SEARCH_VALUE_MANIFEST_FORM',
  };
}
export function getCountScanned() {
  return {
    type: 'GET_COUNT_SCANNED',
  };
}
export function scanManifest() {
  return {
    type: 'SCAN_MANIFEST',
  };
}
export function getTableScanManifest() {
  return {
    type: 'GET_TABLE_SCAN_MANIFEST',
  };
}
export function deleteScanManifest() {
  return {
    type: 'DELETE_SCAN_MANIFEST',
  };
}
export function clearLoadingTypeGroupName() {
  return {
    type: 'CLEAR_LOADING_TYPE_GROUP_NAME',
  };
}
export function clearScanStatusAndBorW() {
  return {
    type: 'CLEAR_SCAN_STATUS_AND_B_OR_W',
  };
}
export function clearManifestBorWForm() {
  return {
    type: 'CLEAR_MANIFEST_B_OR_W_FORM',
  };
}
export function clearBorW() {
  return {
    type: 'CLEAR_B_OR_W',
  };
}
export function clearCollapse() {
  return {
    type: 'CLEAR_COLLAPSE',
  };
}
export function clearPropertiesScanManifest() {
  return {
    type: 'CLEAR_PROPERTIES_SCAN_MANIFEST',
  };
}
export function clearManifestForm() {
  return {
    type: 'CLEAR_MANIFEST_FORM',
  };
}
export function clearGroupId() {
  return {
    type: 'CLEAR_GROUP_ID',
  };
}
export function clearListGroup() {
  return {
    type: 'CLEAR_LIST_GROUP',
  };
}
export function clearTableManifest() {
  return {
    type: 'CLEAR_TABLE_MANIFEST',
  };
}
export function clearManifestProduct() {
  return {
    type: 'CLEAR_MANIFEST_PRODUCT_FORM',
  };
}
