import * as ActionAuth from '../../../action/Auth';
import * as ActionEmergency from '../../../action/ManualProcess/ActionEmergencyProcess';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle/index';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_SubmiteEmergencyScan(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const Mode = state.ManualProcess.EmergencyProcess.ModeEmergency;
  const DocumentType = state.ManualProcess.EmergencyProcess.DocumentType;
  const District = state.ManualProcess.EmergencyProcess.District;
  const Courier = state.ManualProcess.EmergencyProcess.Courier;
  const DeliveryTime = state.ManualProcess.EmergencyProcess.DeliveryTime;
  const FinishTime = state.ManualProcess.EmergencyProcess.FinishTime;
  const NumberResi = state.ManualProcess.EmergencyProcess.NumberResi;
  const Waybill = state.ManualProcess.EmergencyProcess.Waybill;
  var DateNow = moment(Date.now()).format('YYYY-MM-DDTHH:mm:ss');
  const postDate =
    DeliveryTime !== null
      ? DeliveryTime.substring(0, 11) + DateNow.substring(11, 19)
      : DeliveryTime;
  const postFinishDate =
    FinishTime !== null
      ? FinishTime.substring(0, 11) + DateNow.substring(11, 19)
      : FinishTime;
  const receiverName = state.ManualProcess.EmergencyProcess.ReceiverName;
  const deliveryNote = state.ManualProcess.EmergencyProcess.DeliveryNote;

  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(toggle.changeLoadingTrue());
    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/Delivery/Update/EmergencySingle',
      {
        mode: Mode,
        waybill: Waybill,
        amplop: DocumentType,
        districtType: District === null ? 'Jabodetabek' : District,
        courierId: Courier === null ? 0 : Courier,
        delivDate: postDate,
        receiveDate: postFinishDate,
        noResi: NumberResi,
        receiveName: receiverName,
        keterangan: deliveryNote,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionEmergency.ClearWaybill());
    yield put(toggle.changeLoadingFalse());
    yield put(toggle.clearJenisToggle());
    Modal.success({
      title: <p style={{ color: '#31E116' }}>Update Emergency Berhasil</p>,
      content: (
        <p style={{ color: '#000000' }}>Klik OK untuk langkah selanjutnya</p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* S_SubmiteEmergencyUpload(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const Mode = state.ManualProcess.EmergencyProcess.ModeEmergency;
  const Csv = state.ManualProcess.EmergencyProcess.Csv;
  const DocumentType = state.ManualProcess.EmergencyProcess.DocumentType;
  const District = state.ManualProcess.EmergencyProcess.District;
  const Courier = state.ManualProcess.EmergencyProcess.Courier;
  const Branch = state.ManualProcess.EmergencyProcess.Branch;
  const BarcodeColumn = state.ManualProcess.EmergencyProcess.BarcodeColumn;
  const TypeDelivery = state.ManualProcess.EmergencyProcess.TypeDelivery;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  // let DateNow = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');

  // function ResponseDownload(data:any) {
  //     const url = window.URL.createObjectURL(new Blob([data]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', 'Result No Scan ' + DateNow + '.csv');
  //     document.body.appendChild(link);
  //     link.click();
  // }
  try {
    yield put(toggle.changeLoadingTrue());
    // const key = 'download';
    const body =
      Mode === 'NoScan'
        ? {
            fileCsv: Csv,
            mode: Mode,
            position: BarcodeColumn === null ? 0 : BarcodeColumn,
            districtType: District === null ? 'Jabodetabek' : District,
            courierId: Courier,
            branchId: Branch,
            dimention: DocumentType,
            deliveryType: TypeDelivery === null ? 'Urgent' : TypeDelivery,
          }
        : {
            fileCsv: Csv,
            mode: Mode,
          };
    const link =
      Mode === 'NoScan'
        ? '/Delivery/Distribution/NoScan'
        : '/Delivery/Update/EmergencyMultiple';
    const { data } = yield call(
      axios.put,
      process.env.REACT_APP_URL + link,
      body,
      { headers: config }
    );

    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionEmergency.ClearCsv());
    yield put(toggle.changeLoadingFalse());
    yield put(toggle.clearJenisToggle());
    yield put(ActionEmergency.ChangeFileName(data));
    yield put(ActionEmergency.getCountEmergency());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_GetCountEmergency(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const FileName = state.ManualProcess.EmergencyProcess.FileName;
  try {
    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Delivery/Validation/Emergency',
      {
        fileName: FileName,
      },
      { headers: config }
    );

    yield put(toggle.changeJenisToggle('ModalEmergency'));
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionEmergency.ChangeCountSuccess(data.success));
    yield put(ActionEmergency.ChangeCountFailed(data.failed));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_GetDeleteEmergency() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const FileName = state.ManualProcess.EmergencyProcess.FileName;
  const body = {
    fileName: FileName,
  };
  if (statusTokenRefresh === true) {
    yield put(ActionAuth.changeStatusTokenRefresh(false));
  }
  try {
    yield call(
      axios.delete,
      process.env.REACT_APP_URL + '/Delivery/DeleteEmergencyUpload/Wrong',
      { data: body, headers: config }
    );
    yield put(ActionEmergency.ClearModalEmergency());
    yield put(ActionEmergency.ClearAllEmergancyForm());
  } catch (error) {
    yield put(ActionEmergency.ClearModalEmergency());
    yield put(ActionEmergency.ClearAllEmergancyForm());
  }
}

export function* S_DownloadResultEmergency(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const FileName = state.ManualProcess.EmergencyProcess.FileName;

  const key = 'download';
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = `Result Proses Manual Emergency (${moment().format(
      'YYYY-MM-DD HH:mm:ss'
    )}).txt`;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }

  try {
    yield put(toggle.changeJenisToggle2('DownloadResultEmergency'));
    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Delivery/DownloadEmergencyUpload/Wrong',
      {
        fileName: FileName,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);
          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.clearJenisToggle2());
  } catch (error) {
    yield put(toggle.clearJenisToggle2());
    if (error.response.status === 500) {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_SubmiteEmergencyScan() {
  yield takeLatest('SUBMIT_EMERGENCY_SCAN', S_SubmiteEmergencyScan);
}
export function* SM_SubmiteEmergencyUpload() {
  yield takeLatest('SUBMIT_EMERGENCY_UPLOAD', S_SubmiteEmergencyUpload);
}
export function* SM_GetCountEmergency() {
  yield takeLatest('GET_COUNT_EMERGENCY', S_GetCountEmergency);
}
export function* SM_DownloadResultEmergency() {
  yield takeLatest('DOWNLOAD_RESULT_EMERGENCY', S_DownloadResultEmergency);
}
export function* SM_GetDeleteEmergency() {
  yield takeLatest('GET_DELETE_EMERGENCY', S_GetDeleteEmergency);
}
