import * as ActionFormPrintWaybill from '../../../../core/action/Delivery/PrintWaybill/ActionFormPrintWaybill';
import * as toggle from '../../../../core/action/toggle';

import React, { Component } from 'react'
import {bindActionCreators, compose} from 'redux';

import ButtonDownloadComponent from '../../../../components/Content/Delivery/PrintWaybill/ButtonDownloadComponent';
import {connect} from 'react-redux';
import moment from 'moment-timezone'

interface IProps
{
    itemProcessTime:any
    itemDeliveryTime:any
    courierId:any
    id:any
    typeButton:any
    actionToggle:any
    actionFormPrintWaybill:any
}

interface IState
{

}

class ButtonDownloadContainer extends Component <IProps, IState> {
    constructor(props:any)
    {
        super (props)
        this.state=
        {

        }
        
        this.handleChangeAndPopUpModal = this.handleChangeAndPopUpModal.bind(this)
        this.handleChangeAndPopUpModal2 = this.handleChangeAndPopUpModal2.bind(this)
    }
    handleChangeAndPopUpModal()
    {
        this.props.actionToggle.changeModal()
        this.props.actionToggle.changeJenisToggle("SelectWaybillPrintType")
        this.props.actionFormPrintWaybill.changeSelectedCheckboxDate(this.props.itemProcessTime)
        this.props.actionFormPrintWaybill.changeSelectedCheckboxId(this.props.id)
        this.props.actionFormPrintWaybill.changeCourierIdPW(this.props.courierId)
    }
    handleChangeAndPopUpModal2()
    {
        this.props.actionToggle.changeModal()
        this.props.actionToggle.changeJenisToggle("SetDeliveryDate")
        this.props.actionFormPrintWaybill.changeDeliveryDatePW(moment.utc(this.props.itemDeliveryTime).tz("Asia/Jakarta"))
        this.props.actionFormPrintWaybill.changeSelectedCheckboxId(this.props.id)
    }
    render() {
        return (
            <React.Fragment>
                <ButtonDownloadComponent
                    handleChangeAndPopUpModal={this.handleChangeAndPopUpModal}
                    handleChangeAndPopUpModal2={this.handleChangeAndPopUpModal2}
                    typeButton={this.props.typeButton}
                />
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state:any)=> ({
    TypeToggle:state.Toggle.jenisToggle,
})

const mapDispatchToProps = (dispatch:any) => ({
    actionToggle : bindActionCreators(toggle, dispatch),
    actionFormPrintWaybill : bindActionCreators(ActionFormPrintWaybill, dispatch),
})

const withConnect = connect (mapStateToProps, mapDispatchToProps)

export default compose(
    withConnect
)(ButtonDownloadContainer)