import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionPagination from '../../../action/Pagination/ActionPagination';
import * as ActionScanManifest from '../../../action/Manifest/ScanManifest/ActionScanManifest';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_DeleteScanManifest(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const ScannedId = state.ScanManifest.PropertiesScanManifest.ScannedId;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/GroupManifest/CustomStatus/Inactive',
      {
        id: ScannedId,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionPagination.clearAllPagination());
    yield put(ActionScanManifest.getTableScanManifest());
    yield put(ActionScanManifest.getCountScanned());
    yield put(ActionScanManifest.clearScanStatusAndBorW());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil Menghapus Data</b>
        </p>
      ),
      onOk() {},
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_DeleteScanManifest() {
  yield takeLatest('DELETE_SCAN_MANIFEST', S_DeleteScanManifest);
}
