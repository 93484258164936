import React from 'react';
import styles from './FilterTable.module.css';
import { Row, Col } from 'antd';
import { Form, Field } from 'react-final-form';

import SelectField from 'components/SelectField';
import RadioField from 'components/RadioField';
import InputDatePicker from 'components/InputDatePicker';
import Button from 'components/Button';
import CardMaterial from 'materials/CardMaterial';
import moment from 'moment-timezone';
import { FilterDataSlaProps } from 'module/Report/Ringkasan/container/SlaPengiriman';

type FilterTableProps = {
  dataAllBranch: Array<any>;
  isLoadingBranch?: boolean;
  roleAccount: string;
  selectedBranchId: number | null | undefined;
  dataFilterSla: FilterDataSlaProps;
  handleResetFilter: () => void;
  handleChangeFilter: (name: string, value: any) => void;
  handleSubmitFilter: (value: FilterDataSlaProps) => void;
};

export default function FilterTable({
  dataAllBranch,
  isLoadingBranch,
  roleAccount,
  selectedBranchId,
  dataFilterSla,
  handleResetFilter,
  handleChangeFilter,
  handleSubmitFilter,
}: FilterTableProps) {
  const isBranch = roleAccount === 'Branch';
  const defaultDateSend = isBranch ? moment().subtract(1, 'weeks') : null;
  return (
    <CardMaterial
      title={<b style={{ color: '#11BEFF' }}>RINGKASAN SLA PENGIRIMAN</b>}
      style={{ borderRadius: '10px' }}
    >
      <Form
        initialValues={{
          typeFilter: dataFilterSla.typeFilter,
          dateSend: dataFilterSla.dateSend,
          branch: dataFilterSla.branch,
          dateEnd: dataFilterSla.dateEnd,
        }}
        validate={values => {
          const errors = {} as any;
          if (!values.dateSend) {
            errors.dateSend = 'Tanggal Kirim wajib di isi';
          }
          if (!values.dateEnd) {
            errors.dateEnd = 'Tanggal Sampai wajib di isi';
          }
          return errors;
        }}
        onSubmit={handleSubmitFilter}
      >
        {({ form, handleSubmit, submitting, valid, values }) => (
          <div className={styles.wrapperFilter}>
            <div className={styles.wrapperItemFilter}>
              {!isBranch && (
                <Field name="typeFilter">
                  {({ input }) => (
                    <RadioField
                      data={[
                        { value: 'Courier', label: 'Kurir' },
                        { value: 'Branch', label: 'Posko' },
                      ]}
                      onChange={value => {
                        handleChangeFilter('typeFilter', value);
                        input.onChange(value);
                      }}
                      value={input.value}
                    />
                  )}
                </Field>
              )}
              <Row gutter={24}>
                <Col md={12} className={styles.filterColumn}>
                  <Field name="dateSend">
                    {({ input, meta }) => (
                      <InputDatePicker
                        label="Tanggal Kirim"
                        placeholder="Pilih Tanggal"
                        onOpenChange={input.onBlur}
                        onChange={value => {
                          input.onChange(value);
                        }}
                        value={input.value}
                        error={
                          meta.touched && meta.error !== undefined && meta.error
                        }
                      />
                    )}
                  </Field>
                  {values.typeFilter === 'Courier' && (
                    <Field name="branch">
                      {({ input, meta }) => (
                        <SelectField
                          validate
                          label="Posko"
                          placeholder="Semua Posko"
                          data={dataAllBranch}
                          loading={isLoadingBranch}
                          disabled={isBranch || !values.dateSend}
                          value={input.value}
                          onChange={value => {
                            input.onChange(value);
                          }}
                          onBlur={value => {
                            input.onBlur(value);
                          }}
                          validateStatus={
                            meta.error && meta.touched ? 'error' : 'validating'
                          }
                          errorMessage={
                            meta.error && meta.touched && meta.error
                          }
                          mode="multiple"
                          allowClear
                        />
                      )}
                    </Field>
                  )}
                </Col>
                <Col md={12} className={styles.filterColumn}>
                  <Field name="dateEnd">
                    {({ input, meta }) => (
                      <InputDatePicker
                        label="Tanggal Sampai"
                        placeholder="Pilih Tanggal"
                        onOpenChange={input.onBlur}
                        onChange={value => {
                          input.onChange(value);
                        }}
                        value={input.value}
                        error={
                          meta.touched && meta.error !== undefined && meta.error
                        }
                        disabled={!values.dateSend}
                        disabledDate={d => {
                          return (
                            d.isBefore(
                              moment(values.dateSend)
                                .add(0, 'day')
                                .format('YYYY-MM-DD')
                            ) ||
                            d.isAfter(
                              moment(values.dateSend)
                                .add(31, 'day')
                                .format('YYYY-MM-DD')
                            )
                          );
                        }}
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </div>
            <div className={styles.actionFilter}>
              <Button
                text="Reset"
                variant="danger"
                className={styles.actionWidth}
                onClick={() => {
                  handleResetFilter();
                  form.reset({
                    dateSend: defaultDateSend,
                    typeFilter: values.typeFilter,
                    //@ts-ignore
                    branch: [selectedBranchId],
                  });
                }}
                disabled={!valid}
              />
              <Button
                text="Cari"
                className={styles.actionWidth}
                onClick={handleSubmit}
                disabled={submitting || !valid}
              />
            </div>
          </div>
        )}
      </Form>
    </CardMaterial>
  );
}
