import React, { Component } from 'react';

import ButtonMaterial from '../../../materials/ButtonMaterial';

interface IProps {
  handleBranchDetail: any;
  handleBranchEdit: any;
  typeButton: any;
}

export default class ButtonBranchComponent extends Component<IProps> {
  render() {
    return this.props.typeButton === 'detail' ? (
      <ButtonMaterial
        disabled={''}
        handleSubmit={this.props.handleBranchDetail}
        teksButton={''}
        size={''}
        shape={''}
        className={'btnDetail'}
        type={''}
        icon={'eye'}
        style={{}}
      />
    ) : (
      <ButtonMaterial
        disabled={''}
        handleSubmit={this.props.handleBranchEdit}
        teksButton={''}
        size={''}
        shape={''}
        className={'btnEdit'}
        type={''}
        icon={'edit'}
        style={{}}
      />
    );
  }
}
