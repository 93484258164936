import React from 'react';
import Button from 'components/Button';
import SelectField from 'components/SelectField';
import SpinMaterial from 'materials/SpinMaterial';
import { Form as FormFinal, Field } from 'react-final-form';
import InputDatePicker from 'components/InputDatePicker';
import moment from 'moment';
import { archiveDateTypes } from 'lib/constants';
import ContentAlertWarning from 'components/ContentAlertWarning';

type FormRestoreCustomerComponentProps = {
  isLoading?: boolean;
  dataAllCustomers?: Array<any>;
  dataAllProducts?: Array<any>;
  handleSelectCustomer?: (value: number) => void;
  handleSelectProduct?: (value: Array<any>) => void;
  setSelectedPeriod?: (value: Array<any>) => void;
  setSelectedArchiveType?: (value: string) => void;
  handleSelectYear?: (value: number) => void;
  handleSubmit?: (value: any) => void;
  selectedCustomer?: number | undefined;
  selectedProduct?: Array<any> | undefined;
  selectedYear?: number | undefined;
  selectedPeriod?: Array<any>;
  selectedArchiveType?: string;
};

export default function FormRestoreCustomerComponent({
  isLoading = false,
  dataAllCustomers,
  dataAllProducts,
  selectedCustomer,
  selectedProduct,
  selectedPeriod,
  selectedArchiveType,
  handleSelectCustomer = () => undefined,
  handleSelectProduct = () => undefined,
  setSelectedPeriod = () => undefined,
  setSelectedArchiveType = () => undefined,
  handleSubmit = () => undefined,
}: FormRestoreCustomerComponentProps) {
  return (
    <SpinMaterial
      spinning={isLoading}
      size="large"
      content={
        <FormFinal
          onSubmit={value => handleSubmit(value)}
          initialValues={{
            customer: selectedCustomer,
            product: selectedProduct,
            periode: selectedPeriod,
          }}
          validate={values => {
            const errors = {} as any;
            const startOfTwoMonthsAgo = moment()
              .subtract(2, 'months')
              .startOf('month');
            if (!values.customer) {
              errors.customer = 'Pelanggan wajib dipilih';
            }
            if (!values.product || values.product.length === 0) {
              errors.product = 'Produk wajib dipilih';
            }

            if (
              !values.periode ||
              values.periode.length !== 2 ||
              !values.periode[0] ||
              !values.periode[1]
            ) {
              errors.periode = 'Periode Bulan wajib dipilih';
            }

            if (values.periode[0] && values.periode[0] >= startOfTwoMonthsAgo) {
              errors.periode =
                'Pemilihan periode tidak boleh kurang dari 3 bulan sebelumnya terhitung dari bulan saat ini. Mohon periksa kembali.';
            }

            if (values.periode[1] && values.periode[1] >= startOfTwoMonthsAgo) {
              errors.periode =
                'Pemilihan periode tidak boleh kurang dari 3 bulan sebelumnya terhitung dari bulan saat ini. Mohon periksa kembali.';
            }

            if (values.periode[0] && values.periode[1]) {
              const diffInMonths = values.periode[1].diff(
                values.periode[0],
                'months',
                true
              );

              if (diffInMonths >= 12) {
                errors.periode =
                  'Periode melebihi 12 bulan. Mohon periksa kembali.';
              }
            }
            return errors;
          }}
          render={({ handleSubmit, submitting, valid }) => (
            <div
              style={{
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                gap: 40,
                alignItems: 'flex-end',
              }}
            >
              <ContentAlertWarning
                title="Pemilihan periode tidak boleh kurang dari 3 bulan sebelumnya
                    terhitung dari bulan saat ini."
              >
                <p>
                  Contoh: Saat ini bulan Desember, maka Periode yang dapat
                  dipilih dimulai dari bulan September dan mundur hingga bulan
                  sebelumnya (Agustus, Juli, dst.)
                </p>
              </ContentAlertWarning>

              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 16,
                }}
              >
                <Field
                  name="customer"
                  render={({ input, meta }) => (
                    <SelectField
                      label="Pelanggan"
                      placeholder="Pilih pelanggan"
                      data={dataAllCustomers}
                      value={selectedCustomer}
                      validate
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      errorMessage={meta.error && meta.touched && meta.error}
                      onChange={value => {
                        input.onChange(value);
                        handleSelectCustomer(value);
                        handleSelectProduct([]);
                      }}
                      onBlur={value => {
                        input.onBlur(value);
                      }}
                    />
                  )}
                />
                <Field
                  name="product"
                  render={({ input, meta }) => (
                    <SelectField
                      label="Produk"
                      placeholder="Pilih produk"
                      data={dataAllProducts}
                      value={selectedProduct}
                      validate
                      disabled={!selectedCustomer}
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      errorMessage={meta.error && meta.touched && meta.error}
                      onChange={value => {
                        input.onChange(value);
                        handleSelectProduct(value);
                      }}
                      onBlur={value => {
                        input.onBlur(value);
                      }}
                      mode="multiple"
                      allowClear
                    />
                  )}
                />
                <Field
                  name="periode"
                  render={({ input, meta }) => (
                    <div
                      className="group-addon-fefore"
                      style={{ display: 'flex' }}
                    >
                      <InputDatePicker
                        label="Periode Bulan"
                        firstPlaceholder="Dari bulan"
                        secondPlaceholder="Sampai bulan"
                        type="range"
                        mode="month"
                        error={meta.error && meta.touched && meta.error}
                        onBlur={input.onBlur}
                        value={selectedPeriod}
                        onChange={setSelectedPeriod}
                        addonBefore={
                          <SelectField
                            value={selectedArchiveType}
                            data={archiveDateTypes}
                            style={{ width: 100 }}
                            onChange={setSelectedArchiveType}
                          />
                        }
                      />
                    </div>
                  )}
                />
              </div>
              <Button
                text="Simpan"
                borderRadius="small"
                onClick={handleSubmit}
                disabled={submitting || !valid}
              />
            </div>
          )}
        />
      }
    />
  );
}
