import * as ActionConfigReport from '../../action/Customer/ActionDetailConfigReport';
import * as ActionCustomerReportFormatLoading from '../../action/Customer/ActionListCustomerReportFormat';
import * as ActionLogin from '../../../module/Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_GetListCustomerReportFormat() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const CustomerId = state.Report.CustomerReportForm.CustomerId;
  try {
    yield put(
      ActionCustomerReportFormatLoading.setLoadingCustomerReportFormat()
    );
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Setting/CustomReportFile/Customer/' +
        CustomerId +
        '/Active',
      { headers: config }
    );
    yield put(
      ActionCustomerReportFormatLoading.setLoadingCustomerReportFormat()
    );
    yield put(ActionConfigReport.setListCustomerReport(data));
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
    } else {
      yield put(
        ActionCustomerReportFormatLoading.setLoadingCustomerReportFormat()
      );
    }
  }
}

export function* SM_GetListCustomerCustomerFormat() {
  yield takeLatest(
    'GET_LIST_CUSTOMER_REPORT_FORMAT',
    S_GetListCustomerReportFormat
  );
}
