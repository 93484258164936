export function setIdCustomerProduct(idCustomerProduct: any) {
  return {
    type: 'SET_ID_CUSTOMER_PRODUCT',
    idCustomerProduct,
  };
}
export function clearDetailCustomerProduct() {
  return {
    type: 'CLEAR_DETAIL_CUSTOMER_PRODUCT',
  };
}
