import * as ActionAuth from '../../action/Auth';
import * as ActionComplaint from '../../action/Complaint/ActionFormComplaint';
import * as ActionComplaintList from '../../action/Complaint/ActionComplaintList';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_addTicket(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const TicketCode = state.Complaint.complaintTicketCode;
  const Waybill = state.Complaint.complaintWaybill;
  const NoTelp = state.Complaint.complaintNoTelp;
  const complaintDetail = state.Complaint.complaintDetail;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Complaint/AddIvestigasi',
      {
        tiket: TicketCode,
        waybill: Waybill,
        noTelp: NoTelp,
        question: complaintDetail,
        complaintType: 'Proses',
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(ActionComplaint.clearComplaintForm());
    yield put(ActionComplaintList.getComplaintList());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());
    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil Disimpan Dengan Nomor Tiket #{data}</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    if (
      error.response.status === 500 &&
      error.response.data.Message === 'waybill still in process'
    ) {
      Modal.error({
        title: <p style={{ color: '#FF0000' }}>Gagal membuat ticket</p>,
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              Status waybill masih diproses pada menu komplain
            </p>
          </div>
        ),
        onOk() {},
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_addTicket() {
  yield takeLatest('SUBMIT_COMPLAINT', S_addTicket);
}
