import React from 'react';
import { Col, Row, Checkbox } from 'antd';
import moment from 'moment';
import { Form, Field } from 'react-final-form';

import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { getAllRoles } from 'services/RoleServices';
import { getAllBranch } from 'services/GeneralService';
import SelectField from 'components/SelectField';
import { InputText } from 'components/InputField';
import { accountTypes } from 'lib/constants';
import Button from 'components/Button';
import InputDatePicker from 'components/InputDatePicker';

type FormAdminComponentProps = {
  visible: any;
  jenisToggle: any;
  isLoading: any;
  isView?: boolean;
  handleChange?: (name: string, value: any) => void;
  initialData: FormAdminType;
  handleSubmit?: () => void;
};

export type FormAdminType = {
  firstName: string;
  lastName: string;
  email: string;
  typeAccount: string;
  rolePermission: any;
  phone: any;
  dob: any;
  address: string;
  branchId: any;
  status: boolean;
};

export default function FormAdminComponent({
  isLoading,
  isView,
  handleChange = () => undefined,
  initialData,
  handleSubmit = () => undefined,
}: FormAdminComponentProps) {
  const dateFormat = 'YYYY-MM-DD';
  const minDate = (d: any) =>
    !d || d.isAfter(moment().add(-17, 'year').format('YYYY-MM-DD'));

  const { data, isFetching: isLoadingRoles } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataAllRolesSelected', initialData.typeAccount],
    queryFn: () => getAllRoles({ idAccountType: initialData.typeAccount }),
    enabled: !!initialData.typeAccount,
  });

  const { data: dataAllBranch, isFetching: isLoadingBranch } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataListBranch'],
    queryFn: () => getAllBranch(),
  });

  // if (!RegexEmail.test(event.target.value))

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ ...initialData }}
      validate={values => {
        const errors = {} as any;
        const RegexEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/;

        if (!values.firstName) {
          errors.firstName = 'Nama Depan wajib diisi';
        }
        if (!values.lastName) {
          errors.lastName = 'Nama Belakang wajib diisi';
        }
        if (!values.email) {
          errors.email = 'Email wajib diisi';
        }
        if (values.email && !RegexEmail.test(values.email)) {
          errors.email = 'Format Email Salah';
        }
        if (!values.typeAccount) {
          errors.typeAccount = 'Tipe Akun wajib diisi';
        }
        if (!values.rolePermission) {
          errors.rolePermission = 'Role Akses wajib diisi';
        }
        if (!values.phone) {
          errors.phone = 'No. Telp wajib diisi';
        }
        if (!values.dob) {
          errors.dob = 'Tanggal Lahir wajib diisi';
        }

        return errors;
      }}
    >
      {({ handleSubmit, values }) => (
        <form
          className={'spacingTopDefault spacingColForm'}
          onSubmit={handleSubmit}
        >
          <Row gutter={[32, 32]}>
            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', gap: 18 }}
            >
              <Field name="firstName">
                {({ input, meta }) => (
                  <InputText
                    label="Nama Depan"
                    placeholder="Muhammad Arif"
                    maxlength={15}
                    disabled={isView}
                    formStyle={{ margin: 0 }}
                    input={input}
                    meta={meta}
                    onChange={value => handleChange('firstName', value)}
                  />
                )}
              </Field>

              <Field name="typeAccount">
                {({ input, meta }) => (
                  <SelectField
                    validate
                    label="Tipe Akun"
                    placeholder="Pilih Tipe Akun"
                    data={accountTypes}
                    value={input.value}
                    disabled={isView}
                    onChange={value => {
                      handleChange('typeAccount', value);
                      handleChange('rolePermission', '');
                    }}
                    onBlur={input.onBlur}
                    validateStatus={
                      meta.error && meta.touched ? 'error' : 'validating'
                    }
                    errorMessage={meta.error && meta.touched && meta.error}
                  />
                )}
              </Field>

              <Field name="rolePermission">
                {({ input, meta }) => (
                  <SelectField
                    validate
                    label="Role Akses"
                    placeholder="Pilih Role Akses"
                    data={data?.data || []}
                    value={input.value}
                    onChange={value => handleChange('rolePermission', value)}
                    onBlur={input.onBlur}
                    validateStatus={
                      meta.error && meta.touched ? 'error' : 'validating'
                    }
                    errorMessage={meta.error && meta.touched && meta.error}
                    loading={isLoadingRoles}
                    disabled={!values.typeAccount || isView}
                  />
                )}
              </Field>

              <Field name="dob">
                {({ meta, input }) => (
                  <InputDatePicker
                    label="Tanggal Lahir"
                    placeholder="01/01/2002"
                    format="DD/MM/YYYY"
                    disabledDate={minDate}
                    disabled={isView}
                    defaultPickerValue={moment('01/01/2002', dateFormat)}
                    onChange={value => handleChange('dob', value)}
                    value={input.value}
                    error={
                      meta.touched && meta.error !== undefined && meta.error
                    }
                  />
                )}
              </Field>

              <Field name="branchId">
                {({ input, meta }) => (
                  <SelectField
                    validate
                    label="Penempatan Posko"
                    placeholder="Semua Posko"
                    data={dataAllBranch?.data}
                    loading={isLoadingBranch}
                    disabled={
                      values.typeAccount === 'Admin' || !values.typeAccount
                    }
                    value={input.value}
                    onChange={value => handleChange('branchId', value)}
                    onBlur={value => {
                      input.onBlur(value);
                    }}
                    validateStatus={
                      meta.error && meta.touched ? 'error' : 'validating'
                    }
                    errorMessage={meta.error && meta.touched && meta.error}
                    allowClear
                  />
                )}
              </Field>
            </Col>

            <Col
              span={12}
              style={{ display: 'flex', flexDirection: 'column', gap: 18 }}
            >
              <Field name="lastName">
                {({ input, meta }) => (
                  <InputText
                    label="Nama Belakang"
                    placeholder="Budiman"
                    maxlength={15}
                    disabled={isView}
                    formStyle={{ margin: 0 }}
                    input={input}
                    meta={meta}
                    onChange={value => handleChange('lastName', value)}
                  />
                )}
              </Field>

              <Field name="email">
                {({ input, meta }) => (
                  <InputText
                    label="Email"
                    placeholder="muhammad.budiman@rds.co.id"
                    disabled={isView}
                    formStyle={{ margin: 0 }}
                    input={input}
                    meta={meta}
                    onChange={value => handleChange('email', value)}
                  />
                )}
              </Field>

              <Field name="phone">
                {({ input, meta }) => (
                  <InputText
                    label="No Telp"
                    placeholder="Masukkan No. Telpon"
                    disabled={isView}
                    formStyle={{ margin: 0 }}
                    addonBefore={'+62'}
                    input={input}
                    meta={meta}
                    type="number"
                    onChange={value => handleChange('phone', value)}
                  />
                )}
              </Field>

              <Field name="address">
                {({ input, meta }) => (
                  <InputText
                    label="Alamat Lengkap"
                    placeholder="Contoh : Jl. Jendral Sudirman Jakarta Pusat, No: 4"
                    disabled={isView}
                    formStyle={{ margin: 0 }}
                    textArea
                    input={input}
                    meta={meta}
                    onChange={value => handleChange('address', value)}
                  />
                )}
              </Field>

              <Field name="status">
                {({ input }) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 4,
                    }}
                  >
                    <span>
                      <b>Status Default</b>
                    </span>
                    <Checkbox
                      value={input.value}
                      checked={input.value}
                      onChange={value => handleChange('status', !input.value)}
                      disabled={isView}
                    >
                      <i>Ceklis untuk aktifkan status akun</i>
                    </Checkbox>
                  </div>
                )}
              </Field>
            </Col>
          </Row>

          {!isView && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <Button text="Simpan" htmlType="submit" loading={isLoading} />
            </div>
          )}
        </form>
      )}
    </Form>
  );
}
