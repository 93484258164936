import { Col, Row, Spin, Table } from 'antd';
import React, { Component } from 'react';

import ButtonDownloadContainer from '../../../../containers/Content/Delivery/PrintWaybill/ButtonDownloadContainer';
import FormPrintWaybillContainer from '../../../../containers/Content/Delivery/PrintWaybill/FormPrintByWaybillContainer';
import FormSetDeliveryDateContainert from '../../../../containers/Content/Delivery/PrintWaybill/FormSetDeliveryDateContainert';
import FormWaybillPrintTypeContainer from '../../../../containers/Content/Delivery/PrintWaybill/FormWaybillPrintTypeContainer';
import moment from 'moment';
import moment2 from 'moment-timezone';

interface IProps
{
  data:any
  isLoading:any
  //BATAS CODE LAMA
  TotalPagePagination:any
  PagePagination:any
  handlePage:any
  DataListPrintWaybill:any
  page:any
}
export default class TablePrintWaybillComponent extends Component<IProps> {
    render() {
      const DateNow = moment2.utc(new Date()).tz("Asia/Jakarta")
      const columns = [
        {
          title: <b>Admin</b>,
          dataIndex: 'admin',
          key: 'adminCode',
          render:(admin:any)=><span>{admin===null?'-':admin}</span>,
          width: 120,
        },
        {
          title: <b>Jenis Produk</b>,
          dataIndex: 'product',
          key: 'product',
          render:(product:any)=><span style={{color: 'red'}}>{product}</span>,
          width: 120,
        },
        {
          title: <b>Tanggal Kirim</b>,
          dataIndex: 'itemDeliveryTime',
          key: 'itemDeliveryTime',
          render:(itemDeliveryTime:any)=>
          itemDeliveryTime===null?"-":
          <span>
            {(moment(itemDeliveryTime).format('YYYY-MM-DD'))}
          </span>,
          width: 120,
        },
        {
          title: <b>Kurir</b>,
          dataIndex: 'courierCode',
          key: 'courierCode',
          render:(courierCode:any,record: any)=><span style={{color: 'red'}}>{courierCode === null  ? '-' :`${record.courierName} ( ${courierCode} )`}</span>,
          width: 120,
        },
        {
          title: <b>Nama File</b>,
          dataIndex: 'fileName',
          key: 'fileName',
          render:(fileName:any)=>
          <span style={{color: 'red'}}>{fileName}</span>,
          width: 120,
        },
        {
          title: <b>Jumlah</b>,
          dataIndex: 'totalDocument',
          key: 'totalDocument',
          render:(totalDocument:any,record:any)=><span style={{color: 'red'}}>{
            `${record.documentProcess} / ${totalDocument}`}
            </span>,
          width: 120,
        },
        
        {
            title: <b>Aksi</b>,
            key: 'pilih',
            width: 120,
            render: (record:any) => (
            <div style={{marginRight:15}}>
            <Row>
              <Col span={12}>
                <ButtonDownloadContainer
                  itemDeliveryTime={record.itemDeliveryTime===null?DateNow:record.itemDeliveryTime}
                  itemProcessTime={record.itemProcessTime}
                  courierId={record.courierId}
                  id={record.id}
                  typeButton={'setDeliveryDate'}
                />
              </Col>
              <Col span={12}>
                <ButtonDownloadContainer
                  itemDeliveryTime={record.itemDeliveryTime}
                  itemProcessTime={record.itemProcessTime}
                  courierId={record.courierId}
                  id={record.id}
                  typeButton={'download'}
                />
              </Col>
            </Row>
            </div>
            ),
          },
      ];
        return (
            <div>
              <FormPrintWaybillContainer/>
              <FormWaybillPrintTypeContainer/>
              <FormSetDeliveryDateContainert/>
                <Spin
                    spinning={this.props.isLoading}
                    style={{paddingRight:"50%", paddingTop:"10%"}}
                    size="large">
                        <Table
                            bordered={false}
                            pagination={{onChange: page => { this.props.handlePage(page)},
                            pageSize:10,
                            total:this.props.TotalPagePagination,
                            current:this.props.PagePagination===0?1:this.props.PagePagination
                            }}
                            dataSource={this.props.DataListPrintWaybill}
                            columns={columns}
                        />
                </Spin>
            </div>
        )
    }
}
