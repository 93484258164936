import {
  IChangeInvestigationFileAction,
  IFetchComplaintDetailDocAction,
  IFetchComplaintNewListAction,
} from './ComplaintAction';

import { Action } from 'redux';

const initialState: any = {
  complaintDetailDoc: null,
  investigationFile: null,
  courierComplaint: [],
  list: [],
};

export default function PickUpProcessReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_COMPLAINTNEW_LIST': {
      const fetchComplaintListAction = action as IFetchComplaintNewListAction;
      newState.list = fetchComplaintListAction.data;
      return { ...newState };
    }
    case 'FETCH_COMPLAINT_DETAIL_DOC': {
      const fetchComplaintDetailDocAction =
        action as IFetchComplaintDetailDocAction;
      newState.complaintDetailDoc = fetchComplaintDetailDocAction.data;
      return { ...newState };
    }
    case 'CHANGE_BASE64_INVESTIGATION_FILE': {
      const ChangeInvestigationFileAction =
        action as IChangeInvestigationFileAction;
      newState.investigationFile = ChangeInvestigationFileAction.data;
      return { ...newState };
    }
    case 'FETCH_COURIER_COMPLAINT': {
      const fetchCourierComplaintAction =
        action as IFetchComplaintDetailDocAction;
      newState.courierComplaint = fetchCourierComplaintAction.data;
      return { ...newState };
    }
    case 'CLEAR_BASE64_INVESTIGATION_FILE': {
      newState.investigationFile = null;
      return { ...newState };
    }
    case 'CLEAR_DETAIL_DOCUMENT_COMPLAINT': {
      newState.complaintDetailDoc = null;
      return { ...newState };
    }
    case 'CLEAR_COURIER_COMPLAINT': {
      newState.courierComplaint = [];
      return { ...newState };
    }
    case 'CLEAR_LIST_DOCUMENT_COMPLAINT': {
      newState.list = [];
      return { ...newState };
    }
  }
  return state;
}
