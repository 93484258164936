import * as ActionLogin from '../../../../module/Login/Store';
import * as FormAdmin from '../../../action/ManageAccount/AdminBranch/ActionFormDetailAdminBranch';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_generateAdminCode(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Profile/Branch/generate',
      { headers: config }
    );

    yield put(toggle.changeLoadingFalse());
    yield put(FormAdmin.changeAdminCode(data.code));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_generateAdminCode() {
  yield takeLatest('GENERATE_ADMIN_CODE', S_generateAdminCode);
}
