import { Checkbox, Col, Radio, Row } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SixWaybill from '../../../../image/6Waybill1Page.png';
import ThreeWaybill from '../../../../image/3Waybill1Page.png';

interface IProps {
  handlePutPrintType: any;
  handlePutWatermark: any;
  handlePutSeparatorAddress: any;
  handleDownload: any;
  handlePutNotelp?: any;
  waybillPrintType: any;
  getSeparatorAddress: any;
  getWatermark: any;
  visible: any;
  handleClearModal: any;
  getNotelp?: any;
  handlePutCodeBrankas: any;
  getCodeBrankas: any;
}

export default class FormWaybillPrintTypeComponent extends Component<IProps> {
  render() {
    return (
      <React.Fragment>
        <ModalMaterial
          className={''}
          width={'40%'}
          style={{}}
          teksTitle={'Pilih mode waybill'}
          visible={this.props.visible}
          onCancel={this.props.handleClearModal}
          content={
            <div style={{ padding: 25 }}>
              <Radio.Group
                onChange={this.props.handlePutPrintType}
                value={this.props.waybillPrintType}
                style={{ marginBottom: 10 }}
              >
                <Row>
                  <Col span={12}>
                    <Row>
                      <img
                        src={ThreeWaybill}
                        alt="Three Waybill"
                        style={{ width: '100px', marginLeft: '90px' }}
                      />
                    </Row>
                    <Row>
                      <Radio
                        value={'3Waybill'}
                        style={{ textAlign: 'center', paddingLeft: '80px' }}
                      >
                        <br />3 Waybill / halaman
                      </Radio>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row>
                      <img
                        src={SixWaybill}
                        alt="Six Waybill"
                        style={{ width: '100px', marginLeft: '90px' }}
                      />
                    </Row>
                    <Row>
                      <Radio
                        value={'6Waybill'}
                        style={{ textAlign: 'center', paddingLeft: '80px' }}
                      >
                        <br />6 Waybill / halaman
                      </Radio>
                    </Row>
                  </Col>
                </Row>
              </Radio.Group>
              <div style={{ float: 'right' }}>
                <ButtonMaterial
                  disabled={false}
                  handleSubmit={this.props.handleDownload}
                  teksButton={<b>Download</b>}
                  size={'middle'}
                  shape={'round'}
                  className={''}
                  type={'primary'}
                  icon={''}
                  style={{
                    background: '#7956EE',
                    borderColor: '#7956EE',
                    float: 'right',
                    height: '35px',
                    width: '100px',
                    color: '#FFFFFF',
                  }}
                />
                {/* <Checkbox
                  onChange={this.props.handlePutWatermark}
                  style={{
                    paddingLeft: 5,
                    marginBottom: 20,
                    marginTop: 5,
                  }}
                  checked={this.props.getWatermark}
                />
                <span className={'checkboxSpanSeparatorAddress'}>
                  Watermark
                </span> */}
                <Checkbox
                  onChange={this.props.handlePutCodeBrankas}
                  style={{
                    paddingLeft: 5,
                    marginBottom: 20,
                    marginTop: 5,
                  }}
                  checked={this.props.getCodeBrankas}
                />
                <span className={'checkboxSpanSeparatorAddress'}>
                  Kode Brankas
                </span>
                <Checkbox
                  onChange={this.props.handlePutNotelp}
                  style={{
                    paddingLeft: 5,
                    marginBottom: 20,
                    marginTop: 5,
                  }}
                  checked={this.props.getNotelp}
                />
                <span className={'checkboxSpanSeparatorAddress'}>No. Telp</span>
                <Checkbox
                  onChange={this.props.handlePutSeparatorAddress}
                  style={{
                    paddingLeft: 5,
                    marginBottom: 20,
                    marginTop: 5,
                  }}
                  checked={this.props.getSeparatorAddress}
                />
                <span className={'checkboxSpanSeparatorAddress'}>
                  Pisah Alamat
                </span>
              </div>
            </div>
          }
        />
      </React.Fragment>
    );
  }
}
