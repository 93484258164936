import * as ActionAuth from '../../action/Auth';
import * as ActionCustomDataReportDataCustomer from '../../../core/action/Customer/ActionCustomDataReportCustomer';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionTableCustomReportDataCustomer from '../../../core/action/Customer/ActionTableCustomReportDataCustomer';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeLoadingFalse,
  changeLoadingTrue,
  clearJenisToggle,
} from '../../action/toggle';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_EditCustomReportDataCustomer(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  const GetIdCustomReportCustomer =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .IdCustomReportCustomer;
  const GetHeader =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer.Header;
  const GetFormat =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer.Format;
  const GetPosition =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer.Position;
  const GetValue =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer.Value;
  const GetValueDefault =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .ValueDefault;
  const GetSourceTable =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .SourceTable === null
      ? 'Empty'
      : state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
          .SourceTable;
  const GetFormatType =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .FormatType === null
      ? 'Empty'
      : state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
          .FormatType;
  const GetHistoryType =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .HistoryType === null
      ? 'Empty'
      : state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
          .HistoryType;
  const GetFormatColumn =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .FormatColumn;
  const GetSourceColumn =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .SourceColumn;
  const GetSourceColumnSec =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .SourceColumnSec;
  const GetDeliveryTo =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .DeliveryTo === null
      ? 1
      : state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
          .DeliveryTo;
  const GetSubColumn =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer.SubColumn;
  const GetSubColumnSec =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .SubColumnSec;
  const GetReplaceSuccess =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .ReplaceSuccess;
  const GetReplaceReturn =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .ReplaceReturn;
  const GetReplaceOnProcess =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .ReplaceOnProcess;
  const GetCheckBoxPetik =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .CheckBoxPetik;
  const GetCheckBoxOthers =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .CheckBoxOthers;

  const GetDeliveryStatus = `${GetReplaceSuccess},${GetReplaceReturn},${GetReplaceOnProcess}`;
  const GetFormatValue =
    GetFormatType === 'DeliveryStatus' && GetSourceColumn === 'DeliveryStatus'
      ? GetDeliveryStatus
      : GetFormatColumn;
  const GetCharacterLength =
    state.Customer.FormCustomerConfigReport.CustomDataReportCustomer
      .CharacterLength;

  try {
    yield put(changeLoadingTrue());

    yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/Setting/CustomReportData/${GetIdCustomReportCustomer}`,
      {
        id: GetIdCustomReportCustomer,
        name: GetHeader,
        defaultValue: GetValue,
        stringDefaultValue: GetValueDefault,
        tableSource: GetSourceTable,
        columnName: GetSourceColumn,
        subColumnName: GetSubColumn,
        formatValueType: GetFormatType,
        formatValue: GetFormatValue,
        position: GetPosition,
        customReportFileId: GetIdCustomReportCustomer,
        itemStat: GetHistoryType,
        deliveryPosition: GetDeliveryTo,
        secondColumn: GetSourceColumnSec,
        secondSubColumn: GetSubColumnSec,
        formatData: GetFormat,
        character: GetCheckBoxPetik,
        charMax: !GetCharacterLength === true ? 0 : GetCharacterLength,
        freeTextStatus: GetCheckBoxOthers,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(
      ActionTableCustomReportDataCustomer.ClearTableCustomReportDataCustomer()
    );
    yield put(ActionCustomDataReportDataCustomer.clearAllCdrc());
    yield put(
      ActionTableCustomReportDataCustomer.GetTableCustomReportDataCustomer()
    );
    yield put(clearJenisToggle());
    yield put(changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>Berhasil mengubah custom data report</p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_EditCustomReportDataCustomer() {
  yield takeLatest('SUBMIT_EDIT_CDRC', S_EditCustomReportDataCustomer);
}
