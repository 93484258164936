// DY: DEV CHANGES 4 JUL 24

import React, { useState } from 'react';
import styles from './FormManifestBrankas.module.css';
import { Row, Col, Checkbox } from 'antd';
import { AxiosResponse } from 'axios';
import { Form, Field } from 'react-final-form';
import { useQuery } from '@tanstack/react-query';
import { getProductByCustomers } from 'services/GeneralService';

import { SelectOptionsInterface } from 'interface/general';
import { DataManifestBrankasProps } from '../../container/ModalAddManifestBrankas';
import { InputText } from 'components/InputField';
import SelectField from 'components/SelectField';
import InputDatePicker from 'components/InputDatePicker';
import RadioField from 'components/RadioField';
import Button from 'components/Button';

import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';

type FormManifestBrankasProps = {
  isShowModalAdd: boolean;
  isLoadingForm: boolean;
  setIsShowModalAdd: () => void;
  dataManifestRetur: SelectOptionsInterface[];
  dataCustomers: SelectOptionsInterface[];
  loadingManifestRetur: boolean;
  onClickAddRetur: () => void;
  dataManifestBrankas: DataManifestBrankasProps;
  handleChangeField: (name: string, value: any) => void;
  handleSubmit: (value: DataManifestBrankasProps) => void;
  isEdit?: boolean;
};

export default function FormManifestBrankas({
  isShowModalAdd = false,
  isLoadingForm = false,
  setIsShowModalAdd,
  dataManifestRetur,
  loadingManifestRetur = false,
  isEdit = false,
  onClickAddRetur,
  dataCustomers,
  dataManifestBrankas,
  handleChangeField,
  handleSubmit,
}: FormManifestBrankasProps) {
  const [dataProducts, setDataProducts] = useState<SelectOptionsInterface[]>(
    []
  );
  const { isFetching: isLoadingProducts } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataProductsForm', { id: dataManifestBrankas.customer }],
    queryFn: ({ queryKey }) => getProductByCustomers(queryKey),
    onSuccess(data) {
      const datas = [] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataProducts(datas);
    },
    enabled: !!dataManifestBrankas.customer,
    refetchOnWindowFocus: false,
  });

  return (
    <ModalMaterial
      teksTitle={`${isEdit ? 'EDIT' : 'TAMBAH'} MANIFEST BRANKAS`}
      width="70%"
      visible={isShowModalAdd}
      onCancel={setIsShowModalAdd}
    >
      <SpinMaterial spinning={isLoadingForm} size={'large'}>
        <div style={{ marginTop: 24, padding: '0 24px' }}>
          <Form
            onSubmit={handleSubmit}
            initialValues={{ ...dataManifestBrankas }}
            validate={values => {
              const errors = {} as any;
              if (!values.customer) {
                errors.customer = 'Pelanggan wajib diisi';
              }
              if (
                !values.customerProducts ||
                values.customerProducts.length === 0
              ) {
                errors.customerProducts = 'Produk Pelanggan wajib diisi';
              }
              if (!values.cycleDate) {
                errors.cycleDate = 'Tanggal wajib diisi';
              }
              if (!values.bundleCode) {
                errors.bundleCode = 'Kode Bundel wajib diisi';
              }
              if (!values.manifestSafeId) {
                errors.manifestSafeId = 'Kode Brankas wajib diisi';
              }
              if (values.maxBundle && values.maxBundle < 1) {
                errors.maxBundle = 'Tidak boleh kurang dari 1';
              }
              return errors;
            }}
          >
            {({ values, handleSubmit, valid }) => (
              <div className={styles.wrapperFilter}>
                <div className={styles.wrapperForm}>
                  <Field name="type">
                    {({ input }) => (
                      <RadioField
                        data={[
                          { value: 'Success', label: 'Sukses' },
                          { value: 'Return', label: 'Return' },
                        ]}
                        onChange={value => handleChangeField('type', value)}
                        value={input.value}
                        disabled={isEdit}
                      />
                    )}
                  </Field>
                  <Row gutter={24}>
                    <Col md={12} className={styles.filterColumn}>
                      <Field name="customer">
                        {({ input, meta }) => (
                          <SelectField
                            validate
                            label="Pelanggan"
                            placeholder="Pilih Pelanggan"
                            data={dataCustomers}
                            value={input.value}
                            onChange={value =>
                              handleChangeField('customer', value)
                            }
                            onBlur={value => {
                              input.onBlur(value);
                            }}
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            disabled={isEdit}
                          />
                        )}
                      </Field>
                      <Field name="customerProducts">
                        {({ input, meta }) => (
                          <SelectField
                            validate
                            label="Produk"
                            placeholder="Pilih Produk"
                            data={dataProducts}
                            loading={isLoadingProducts}
                            value={input.value}
                            mode="multiple"
                            onChange={value =>
                              handleChangeField('customerProducts', value)
                            }
                            onBlur={value => {
                              input.onBlur(value);
                            }}
                            validateStatus={
                              meta.error && meta.touched
                                ? 'error'
                                : 'validating'
                            }
                            errorMessage={
                              meta.error && meta.touched && meta.error
                            }
                            disabled={!values.customer || isEdit}
                          />
                        )}
                      </Field>
                      <Field name="maxBundle">
                        {input => (
                          <InputText
                            label="Maksimal Dokumen per Bundel"
                            formStyle={{ margin: 0 }}
                            onChange={value => {
                              handleChangeField('maxBundle', value);
                            }}
                            type="number"
                            {...input}
                            min={0}
                            maxlength={3}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col md={12} className={styles.filterColumn}>
                      <Field name="cycleDate">
                        {({ input, meta }) => (
                          <div className={styles.wrapperDateField}>
                            <InputDatePicker
                              label="Tanggal Cycle"
                              placeholder={`Pilih ${
                                values.isMonthly ? 'Bulan' : 'Tanggal'
                              }`}
                              onOpenChange={input.onBlur}
                              onChange={value =>
                                handleChangeField('cycleDate', value)
                              }
                              value={input.value}
                              error={
                                meta.touched &&
                                meta.error !== undefined &&
                                meta.error
                              }
                              formStyle={{ flex: 1 }}
                              mode={values.isMonthly ? 'month' : 'date'}
                              disabled={isEdit}
                            />
                            <Field name="isMonthly">
                              {({ input: inputCheck }) => (
                                <Checkbox
                                  style={{
                                    marginBottom:
                                      meta.touched &&
                                      meta.error !== undefined &&
                                      meta.error
                                        ? 28
                                        : 10,
                                    width: '25%',
                                  }}
                                  checked={inputCheck.value}
                                  onChange={() =>
                                    handleChangeField(
                                      'isMonthly',
                                      !inputCheck.value
                                    )
                                  }
                                  disabled={isEdit}
                                >
                                  Bulanan
                                </Checkbox>
                              )}
                            </Field>
                          </div>
                        )}
                      </Field>

                      <Field name="bundleCode">
                        {input => (
                          <InputText
                            label="Kode Bundel"
                            placeholder="Masukan Kode bundel"
                            formStyle={{ margin: 0 }}
                            disabled
                            onChange={value => {
                              handleChangeField('bundleCode', value);
                            }}
                            {...input}
                          />
                        )}
                      </Field>
                      <Field name="manifestSafeId">
                        {({ input, meta }) => (
                          <div className={styles.wrapperSelectField}>
                            <SelectField
                              label="Kode Brankas"
                              placeholder="Masukan kode brankas"
                              data={dataManifestRetur}
                              value={input.value || undefined}
                              onChange={value => {
                                handleChangeField('manifestSafeId', value);
                              }}
                              onBlur={value => {
                                input.onBlur(value);
                              }}
                              validate
                              validateStatus={
                                meta.error && meta.touched
                                  ? 'error'
                                  : 'validating'
                              }
                              errorMessage={
                                meta.error && meta.touched && meta.error
                              }
                              loading={loadingManifestRetur}
                            />
                            <p
                              onClick={onClickAddRetur}
                              className={styles.textAddKodeRetur}
                            >
                              Tambah Kode Baru
                            </p>
                          </div>
                        )}
                      </Field>
                    </Col>
                  </Row>
                </div>

                <div className={styles.actionFilter}>
                  <Button
                    text={isEdit ? 'Simpan' : 'Simpan & Scan Manifest'}
                    onClick={handleSubmit}
                    disabled={!valid}
                  />
                </div>
              </div>
            )}
          </Form>
        </div>
      </SpinMaterial>
    </ModalMaterial>
  );
}
