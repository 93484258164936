import { Action } from 'redux';

const initialState = {
  collapsed: false,
  isLoading: false,
  modal: false,
  modal2: false,
  jenisToggle: null,
  jenisToggle2: null,
  ValueSearch: '',
};

export interface IAuthAction extends Action<string> {}

export interface IJenisToggle extends IAuthAction {
  jenisToggle: any;
}
export interface IJenisToggle2 extends IAuthAction {
  jenisToggle2: any;
}
export interface IValueSearch extends IAuthAction {
  ValueSearch: any;
}

export default function AuthReducer(state = initialState, action: IAuthAction) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'ACTION_COLLAPSED':
      newState.collapsed = !state.collapsed;
      return { ...newState };
    case 'CHANGE_LOADING_FALSE':
      newState.isLoading = false;

      return { ...newState };

    case 'CHANGE_LOADING_TRUE':
      newState.isLoading = true;

      return { ...newState };
    case 'CHANGE_MODAL':
      newState.modal = !state.modal;

      return { ...newState };
    case 'CHANGE_MODAL2':
        newState.modal2 = !state.modal2;

      return { ...newState };

    case 'CHANGE_JENIS_TOGGLE':
      const changeJenisToggle = action as IJenisToggle;
      newState.jenisToggle = changeJenisToggle.jenisToggle;

      return { ...newState };
    case 'CHANGE_JENIS_TOGGLE2':
      const changeJenisToggle2 = action as IJenisToggle2;
      newState.jenisToggle2 = changeJenisToggle2.jenisToggle2;

      return { ...newState };

    case 'CHANGE_VALUE_SEARCH':
      const changeValueSearch = action as IValueSearch;
      newState.ValueSearch = changeValueSearch.ValueSearch;

      return { ...newState };
    case 'CLEAR_JENIS_TOGGLE':
      newState.jenisToggle = null;
      newState.modal = false;

      return { ...newState };
    case 'CLEAR_JENIS_TOGGLE2':
      newState.jenisToggle2 = null;
      newState.modal2 = false;

      return { ...newState };

    case 'CLEAR_VALUE_SEARCH':
      newState.ValueSearch = '';

      return { ...newState };
  }
  return { ...newState };
}
