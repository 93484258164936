import React, { Component } from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import history from '../../../core/utils/History';
import * as ActionToggle from '../../../core/action/toggle/index';
import * as ActionReasonStat from '../../../core/action/Customer/ActionReasonStatusCustomer';
import * as ActionGetDataStatus from '../../../core/action/Customer/ActionTableStatusDefault';
import * as ActionCustomerStatus from '../../../core/action/Customer/ActionCustomerStatusConfig'
import * as ActionLastAccess from '../../../core/action/LastAccess';
import * as ActionRadiobutton from '../../../core/action/Customer/ActionRadiobuttonStatus';
import * as ActionChangeType from '../../../core/action/Customer/ActionChangeTypeStatusConfig';
import * as ActionNavigation from '../../../core/action/Router/ActionRouter';
import CustomerStatusConfigComponent from '../../../components/Content/Customer/CustomerStatusConfigComponent';

interface IProps
{
    actionReasonStat:any
    actionGetDataStatus:any
    actionNavigation:any
    actionToggle:any
    GetRadiobutton:any
    GetReasonDeliveryStatus:any
    GetCustomerName:any
    actionCustomerStatus:any
    actionLastAccess:any
    actionRadiobutton:any
    actionChangeType:any
    lastAccessFiture:any
    jenisToggle:any
}

interface IState
{
    radioButton:any
}

class CustomerStatusConfigContainer extends Component <IProps, IState> {
    constructor(props:any){
        super (props)
        this.state=
        {
            radioButton:null,
        }
        
        this.handlePutStatus = this.handlePutStatus.bind(this)
        this.handleGetStatusCustomer = this.handleGetStatusCustomer.bind(this)
        this.handleRadiobutton = this.handleRadiobutton.bind(this)
        this.handleSubmitDefaultStatus = this.handleSubmitDefaultStatus.bind(this)
        this.handleClear = this.handleClear.bind(this)
        this.handleBack = this.handleBack.bind(this)
    }


    handleBack()
    {
        this.props.actionGetDataStatus.clearDefaultDataStatus()
        this.props.actionGetDataStatus.clearCustomDataStatus()
        this.props.actionRadiobutton.clearRadiobutton()
        history.push("/customer")
        this.props.actionNavigation.setRouter("/customer")
    }

    handlePutStatus(event:any)
    {
        this.props.actionReasonStat.changeReasonStat(event)
        this.props.actionGetDataStatus.clearDefaultDataStatus()
        this.props.actionGetDataStatus.clearCustomDataStatus()
    }

    handleGetStatusCustomer()
    {
        this.props.actionChangeType.changeCType("SelectBox")
        this.props.actionGetDataStatus.getCustomDataStatus()
    }
    
    handleRadiobutton(event:any)
    {
        if(event.target.value===1){
            this.props.actionToggle.changeJenisToggle("defaultDataStatusModal")
            this.props.actionToggle.changeModal()
        }else{
            this.props.actionChangeType.changeCType("RadioButton")
            this.props.actionGetDataStatus.getCustomDataStatus()
        }
    }

    handleSubmitDefaultStatus()
    {
        this.props.actionCustomerStatus.submitDefaultStatus()
        this.props.actionChangeType.changeCType("RadioButton")
        this.props.actionLastAccess.changeLastAccessFiture("defaultDataStatus")
    }

    handleClear()
    {
        this.props.actionToggle.clearJenisToggle()
        this.props.actionLastAccess.changeLastAccessFiture("customDataStatus")
        this.props.actionRadiobutton.changeRadiobutton(2)
    }

    render() {
        return (
            <div>
                <CustomerStatusConfigComponent
                visible={this.props.jenisToggle==="defaultDataStatusModal"?true:false}
                handleClear={this.handleClear}
                handlePutStatus={this.handlePutStatus}
                ValueReasonDeliveryStatus={this.props.GetReasonDeliveryStatus===null?<span style={{color:'#969696'}}>Pilih status</span>: this.props.GetReasonDeliveryStatus}
                GetReasonDeliveryStatus={this.props.GetReasonDeliveryStatus}
                GetCustomerName={this.props.GetCustomerName}
                radioButtonValue={this.props.GetRadiobutton}
                disabledRadiobutton={this.props.GetReasonDeliveryStatus===""||this.props.GetReasonDeliveryStatus===null?true:false}
                handleRadiobutton={this.handleRadiobutton}
                handleGetStatusCustomer={this.handleGetStatusCustomer}
                lastAccessFiture={this.props.lastAccessFiture}
                handleSubmitDefaultStatus={this.handleSubmitDefaultStatus}
                handleBack={this.handleBack}
                />
            </div>
        )
    }
}

const mapStateToProps = (state:any) => ({
    GetReasonDeliveryStatus : state.Customer.PropertiesCustomer.ReasonDeliveryStatus,
    GetRadiobutton : state.Customer.PropertiesCustomer.Radiobutton,
    GetCustomerName : state.Customer.DetailCustomer.CustomerName,
    lastAccessFiture : state.LastAccess.Fiture,
    jenisToggle : state.Toggle.jenisToggle,
})

const mapDispatchToProps = (dispatch:any) => ({
    actionReasonStat : bindActionCreators(ActionReasonStat, dispatch),
    actionGetDataStatus : bindActionCreators(ActionGetDataStatus, dispatch),
    actionLastAccess : bindActionCreators(ActionLastAccess, dispatch),
    actionNavigation : bindActionCreators(ActionNavigation, dispatch),
    actionRadiobutton : bindActionCreators(ActionRadiobutton, dispatch),
    actionChangeType : bindActionCreators(ActionChangeType, dispatch),
    actionCustomerStatus : bindActionCreators(ActionCustomerStatus, dispatch),
    actionToggle : bindActionCreators(ActionToggle, dispatch),
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
    withConnect
)(CustomerStatusConfigContainer)