import * as ActionComplaint from '../Store/ComplaintAction';
import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionGeneralData from '../../App/Store/GeneralDataAction';
import * as SelectorsComplaint from '../Selector/ComplaintSelector';
import * as SelectorsComponent from '../../App/Selector/AppSelector';
import * as SelectorsGeneralData from '../../App/Selector/GeneralDataSelector';

import { bindActionCreators, compose } from 'redux';
import { change, getFormSyncErrors, getFormValues } from 'redux-form';
import { connect, useDispatch } from 'react-redux';

import ModalCreateComplaintComponent from '../Component/ModalCreateComplaintComponent';
import React from 'react';
import { createStructuredSelector } from 'reselect';

function ModalCreateComplaintContainer(props) {
  const { componentAction, complaintAction, complaintDetailDoc, formValue } =
    props;
  const dispatch = useDispatch();
  const handleCloseModalCreateComplaint = () => {
    componentAction.openModal('Complaint');
    complaintAction.clearDetailDocComplaint();
    complaintAction.clearCourierComplaint();
    complaintAction.clearComplaintNewForm();
  };
  const handleSearchWaybill = () => {
    complaintAction.getWaybillComplaint();
  };
  const handleClearCourier = () => {
    if (!formValue.branchInvestigate === false) {
      complaintAction.getCourierComplaint();
    }
    dispatch(change('complaintForm', 'courierInvestigate', null));
  };
  const handleSubmit = () => {
    const valueDetailDoc = complaintDetailDoc ? complaintDetailDoc : '';
    if (valueDetailDoc.stats) {
      if (valueDetailDoc.stats === 1) {
        complaintAction.submitComplaintInQueueToProcess();
      } else if (valueDetailDoc.stats === 2) {
        complaintAction.submitProcessToCustomerConfirm();
      }
    } else {
      if (formValue.formType === 'inputData') {
        complaintAction.getCourierComplaint();
        dispatch(
          change(
            'complaintForm',
            'branchInvestigate',
            complaintDetailDoc.branch
          )
        );
        dispatch(
          change(
            'complaintForm',
            'courierInvestigate',
            complaintDetailDoc.courier
          )
        );
        dispatch(change('complaintForm', 'formType', 'assignCourier'));
      } else {
        complaintAction.submitDocComplaint();
      }
    }
  };
  const handleBackToInputInvestigate = () => {
    dispatch(change('complaintForm', 'formType', 'inputData'));
  };
  return (
    <React.Fragment>
      <ModalCreateComplaintComponent
        handleCloseModalCreateComplaint={handleCloseModalCreateComplaint}
        handleSearchWaybill={handleSearchWaybill}
        handleBackToInputInvestigate={handleBackToInputInvestigate}
        handleSubmit={handleSubmit}
        handleClearCourier={handleClearCourier}
        {...props}
      />
    </React.Fragment>
  );
}

const mapStateToProps = () =>
  createStructuredSelector({
    createComplaintModalIsShow: SelectorsComponent.createComplaintModalIsShow(),
    complaintDetailDoc: SelectorsComplaint.makeComplaintDetailDocSelector(),
    courierInvestigationList:
      SelectorsComplaint.makeCourierInvestigationSelector(),
    branchList: SelectorsGeneralData.makeBranchListSelector(),
    isLoading: SelectorsComponent.makeIsLoading(),
    formValue: getFormValues('complaintForm'),
    errorFormValue: getFormSyncErrors('complaintForm'),
  });
const mapDispatchToProps = dispatch => ({
  generalDataAction: bindActionCreators(ActionGeneralData, dispatch),
  componentAction: bindActionCreators(ActionComponent, dispatch),
  complaintAction: bindActionCreators(ActionComplaint, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(ModalCreateComplaintContainer);
