import { Col, Row } from 'antd';
import { Field, reduxForm } from 'redux-form';
import { complaintReportType, complaintStatus } from '../../../../apps/enums';

import CButton from '../../../../apps/assets/components/CButton';
import CCard from '../../../../apps/assets/components/CCard';
import CSpin from '../../../../apps/assets/components/CSpin';
import { InputDatePicker } from '../../../../apps/assets/components/CInputDate';
import { InputSelect } from '../../../../apps/assets/components/CInput';
import { RadioButton } from '../../../../apps/assets/components/CRadio';
import { singleCheckbox } from 'apps/assets/components/CCheckbox';
import React from 'react';
import moment from 'moment';
import validate from 'module/Report/ComplaintReport/Validation/ComplaintReportValidation';

function ComplaintReportComponent(props: any) {
  const {
    formValue,
    errorFormValue,
    handleGetCustomerProductList,
    handleGetCustomerList,
    handleChangeCustomerName,
    handleDownload,
    handleClearForm,
    customerList,
    customerProductList,
    isLoading,
  } = props;
  const valueStartDate = formValue ? formValue.startDate : '';
  const valueFinishDate = formValue ? formValue.finishDate : '';
  const valueReportType = formValue ? formValue.reportType : '';
  const valueCustomer = formValue ? formValue.customer : '';
  const valueProduct = formValue ? formValue.product : '';
  const valueAllCustomer = formValue && formValue.allCustomer;
  const validateBtnDownload = () => {
    if (
      !errorFormValue.startDate === true &&
      !errorFormValue.finishDate === true &&
      !errorFormValue.reportType === true &&
      !errorFormValue.complaintStatus === true &&
      (valueAllCustomer === true ? true : valueProduct.length !== 0)
    ) {
      return false;
    } else {
      return true;
    }
  };
  const validateBtnReset = () => {
    if (
      !valueStartDate === false ||
      !valueFinishDate === false ||
      !valueReportType === false ||
      !valueCustomer === false ||
      (valueAllCustomer === true ? true : valueProduct.length !== 0)
    ) {
      return false;
    } else {
      return true;
    }
  };
  const btnComplaintReport = [
    {
      className: 'btnResetDefault',
      onClick: handleClearForm,
      disabled: validateBtnReset(),
      isLoading: isLoading,
      content: 'Reset',
      id: 'btnResetComplaintReport',
    },
    {
      type: 'primary',
      className: 'btnDownloadDefault',
      onClick: handleDownload,
      isLoading: isLoading,
      disabled: validateBtnDownload(),
      content: 'Download',
      id: 'btnDownloadComplaintReport',
    },
  ];
  const startDate = formValue ? formValue.startDate : '';
  const customerValue = formValue ? formValue.customer : '';

  const dateValidation = (d: any) =>
    !d || d.isAfter(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
  const disableDate = (d: any) =>
    !d ||
    d.isBefore(moment(startDate).add(0, 'day').format('YYYY-MM-DD')) ||
    d.isAfter(moment(startDate).add(31, 'day').format('YYYY-MM-DD'));

  const labelFromDate = (dateType?: string) => {
    switch (dateType) {
      case 'cycle':
        return 'Tanggal Cycle Dari';
      case 'process':
        return 'Tanggal Upload Dari';
      case 'complaint':
        return 'Tanggal Keluhan Dari';
      default:
        return 'Tanggal Cycle Dari';
    }
  };
  const contentCard = () => {
    return (
      <>
        <Row>
          <Field
            name="dateType"
            component={RadioButton}
            idComponent="radioDateType"
            data={[
              {
                id: 'cycle',
                name: 'Cycle',
              },
              {
                id: 'process',
                name: 'Upload',
              },
              {
                id: 'complaint',
                name: 'Keluhan',
              },
            ]}
          />
        </Row>
        <Row>
          <Col span={12} className="spacingRight">
            <Field
              name="startDate"
              component={InputDatePicker}
              idComponent="inputStartDate"
              label={labelFromDate(formValue?.dateType)}
              typeDate="datePicker"
              disabledDate={dateValidation}
              placeholder={'Pilih tanggal'}
            />
            <Field
              name="reportType"
              component={InputSelect}
              idComponent="inputCustomerComplaint"
              dafaultItemName="Pilih Laporan"
              data={complaintReportType}
              label={'Pilih Laporan'}
            />
            <Field
              name="customer"
              component={InputSelect}
              idComponent="inputCustomerComplaint"
              dafaultItemName="Pilih Pelanggan"
              additionalHandleOnFocusFunc={handleGetCustomerList}
              additionalHandleChangeFunc={handleChangeCustomerName}
              data={customerList}
              showsearch={true}
              label={'Pelanggan'}
              formStyle={{ marginBottom: 0 }}
              disabled={formValue?.allCustomer}
            />
            <Field
              name="allCustomer"
              labelRight="Pilih Semua Pelanggan"
              component={singleCheckbox}
              onChange={() => props.handleClearSelectedProduct()}
            />
          </Col>
          <Col span={12}>
            <Field
              name="finishDate"
              component={InputDatePicker}
              idComponent="inputFinishDate"
              label="Sampai Tanggal"
              typeDate="datePicker"
              disabledDate={disableDate}
              disabled={!startDate ? true : false}
              placeholder={'Pilih tanggal'}
            />
            <Field
              name="complaintStatus"
              component={InputSelect}
              mode="multiple"
              idComponent="inputComplaintStatus"
              placeholder="Pilih status pengiriman"
              data={complaintStatus}
              label={'Status Keluhan'}
            />
            <Field
              name="product"
              component={InputSelect}
              idComponent="inputProductComplaint"
              placeholder="Pilih produk pelanggan"
              additionalHandleOnFocusFunc={handleGetCustomerProductList}
              data={customerProductList}
              mode="multiple"
              label={'Produk'}
              disabled={!customerValue || formValue?.allCustomer ? true : false}
            />
          </Col>
        </Row>
        <Row className="floatRight">
          <CButton buttonData={btnComplaintReport} />
        </Row>
      </>
    );
  };
  return (
    <div>
      <CSpin
        spinning={false}
        size={'large'}
        content={
          <>
            <CCard
              cardClassName={'defaultCard'}
              cardTitle={
                <span className="lightBlueColor fontWeight">
                  LAPORAN KELUHAN
                </span>
              }
              cardExtra={''}
              cardContent={<React.Fragment>{contentCard()}</React.Fragment>}
            />
          </>
        }
      />
    </div>
  );
}
const ComplaintReportMenu = reduxForm({
  form: 'complaintReportForm',
  shouldError: () => {
    return true;
  },
  validate,
})(ComplaintReportComponent);
export default ComplaintReportMenu;
