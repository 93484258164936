import React, { useState } from 'react';
import { Row, Col, Table } from 'antd';
import moment from 'moment-timezone';

import SearchMatrial from 'materials/SearchMatrial';
import ButtonMaterial from 'materials/ButtonMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import CardMaterial from 'materials/CardMaterial';
import { DataTableResProps } from 'interface/general';

type AcceptedBagTableProps = {
  isLoading: boolean;
  handlePage: (value: number) => void;
  dataTableBag: DataTableResProps;
  handleDetailBagging: (id: number) => void;
  setSearchBarcodeBag: (value: string) => void;
  searchBarcodeBag: string;
};

export default function AcceptedBagTable({
  isLoading,
  handlePage,
  dataTableBag,
  handleDetailBagging,
  searchBarcodeBag,
  setSearchBarcodeBag,
}: AcceptedBagTableProps) {
  const [barcode, setBarcode] = useState<string>('');
  const pagePagination = dataTableBag?.page + 1 || 0;
  const totalPagination = dataTableBag?.totalPages * 10 || 0;
  const looppage = (pagePagination - 1) * 10 + 1;

  const columnsTable = [
    {
      title: 'No',
      render: (text: any, record: any, index: any) => (
        <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
      ),
      width: 80,
    },
    {
      title: 'Barcode Bag',
      dataIndex: 'barcodeBagging',
      width: 200,
    },
    {
      title: 'Posko Asal',
      dataIndex: 'originalBranchName',
    },

    {
      title: 'Status',
      dataIndex: 'baggingStatusDocument',
      render: (text: string) => (
        <p
          style={{
            color: text === 'Process' ? '#1890FF' : '#00AB44',
            margin: 0,
          }}
        >
          {text === 'Success' ? 'Selesai' : text}
        </p>
      ),
    },
    {
      title: 'Kurir',
      dataIndex: 'courier',
    },
    {
      title: 'Tanggal Kirim',
      dataIndex: 'deliveryBaggingTime',
      render: (text: string) => <>{moment(text).format('DD-MM-YYYY')}</>,
      width: 150,
    },
    {
      title: 'Tanggal Terima',
      dataIndex: 'finishBaggingTime',
      render: (text: string) => <>{moment(text).format('DD-MM-YYYY')}</>,
      width: 150,
    },
    {
      title: 'Aksi',
      render: record => (
        <div style={{ display: 'flex', gap: 8 }}>
          <ButtonMaterial
            icon="eye"
            type="download"
            style={{ background: '#874FFF', color: 'white' }}
            handleSubmit={() => handleDetailBagging(record.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <SpinMaterial
      spinning={isLoading}
      size={'large'}
      content={
        <div>
          <CardMaterial
            style={{ borderRadius: '10px 10px 0 0' }}
            content={
              <div>
                <Row>
                  <Col span={24}>
                    <div style={{ display: 'flex', gap: 16 }}>
                      <span className="titleSearchCard">
                        RIWAYAT TERIMA BAG
                      </span>
                      <SearchMatrial
                        style={{ borderRadius: '10px', width: '50%' }}
                        placeholder="Cari berdasarkan barcode"
                        value={barcode}
                        onChange={e => setBarcode(e.target.value)}
                        onPressEnter={e => setSearchBarcodeBag(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            }
          />
          <Table
            columns={columnsTable}
            bordered
            dataSource={dataTableBag?.data || []}
            scroll={{ x: 1000 }}
            pagination={{
              onChange: page => {
                handlePage(page - 1);
              },
              pageSize: 10,
              total: totalPagination,
              current: pagePagination === 0 ? 1 : pagePagination,
            }}
          />
        </div>
      }
    />
  );
}
