import React, { Component } from 'react';
import { Icon, Input } from 'antd';
import ModalMaterial from '../../../materials/ModalMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';
import FormInputTextMaterial from '../../../materials/FormInputTextMaterial';
import ButtonMaterial from '../../../materials/ButtonMaterial';

interface IProps {
  typeToggle: any;
  visible: any;
  isLoading: any;
  password: any;
  newPassword: any;
  reNewPassword: any;
  userNameAdminBranch: any;
  userNameCourier: any;
  userNameCustomer: any;
  validatePassword: any;
  validateNewPassword: any;
  validateReNewPassword: any;
  helpValidatePassword: any;
  helpValidateNewPassword: any;
  helpValidateReNewPassword: any;
  handlePutPassword: any;
  handlePutNewPassword: any;
  handlePutReNewPassword: any;
  handleSavePassword: any;
  handleCloseModal: any;
}
export default class ResetPasswordComponent extends Component<IProps> {
  render() {
    let ValidateButton =
      this.props.typeToggle === 'ChangePasswordProfile'
        ? this.props.password === null ||
          this.props.validatePassword === 'error' ||
          this.props.newPassword === null ||
          this.props.validateNewPassword === 'error' ||
          this.props.reNewPassword === null ||
          this.props.validateReNewPassword === 'error'
          ? true
          : false
        : this.props.newPassword === null ||
          this.props.validateNewPassword === 'error' ||
          this.props.reNewPassword === null ||
          this.props.validateReNewPassword === 'error'
        ? true
        : false;

    return (
      <div>
        <ModalMaterial
          className={''}
          width={'40%'}
          style={{}}
          teksTitle={'UBAH KATA SANDI'}
          visible={
            this.props.visible === true &&
            this.props.typeToggle === 'ChangePasswordProfile'
              ? true
              : this.props.visible === true &&
                this.props.typeToggle === 'ResetPasswordAdmin'
              ? true
              : this.props.visible === true &&
                this.props.typeToggle === 'ResetPasswordCourier'
              ? true
              : this.props.visible === true &&
                this.props.typeToggle === 'ResetPasswordCustomer'
              ? true
              : false
          }
          onCancel={this.props.handleCloseModal}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div style={{ paddingTop: '5px' }}>
                  <div
                    style={{
                      paddingRight: '20px',
                      paddingLeft: '30px',
                      paddingTop: '20px',
                    }}
                  >
                    {this.props.typeToggle === 'ChangePasswordProfile' ? (
                      <div>
                        <span>
                          <b>Masukan Kata Sandi Lama</b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          onPressEnter={''}
                          className={''}
                          disabled={false}
                          addonBefore={''}
                          placeholder={'Masukan Kata Sandi Lama'}
                          prefix={
                            <Icon
                              type="lock"
                              style={{ color: 'rgba(0,0,0,.25)' }}
                            />
                          }
                          value={this.props.password}
                          maxLength={''}
                          style={{}}
                          size=""
                          onChange={this.props.handlePutPassword}
                          onBlur={this.props.handlePutPassword}
                          validatestatus={this.props.validatePassword}
                          type={'password'}
                          help={this.props.helpValidatePassword}
                        />
                      </div>
                    ) : (
                      <div className="padding-top-form-input">
                        <span>
                          <b>Nama Pengguna</b>
                        </span>
                        <Input
                          style={{
                            background: '#F5F5F5',
                            fontWeight: 'bold',
                          }}
                          value={
                            this.props.typeToggle === 'ResetPasswordAdmin'
                              ? this.props.userNameAdminBranch
                              : this.props.typeToggle === 'ResetPasswordCourier'
                              ? this.props.userNameCourier
                              : this.props.typeToggle ===
                                'ResetPasswordCustomer'
                              ? this.props.userNameCustomer
                              : ''
                          }
                        ></Input>
                      </div>
                    )}

                    <div>
                      <span>
                        <b>Masukan Kata Sandi Baru</b>
                      </span>
                      <FormInputTextMaterial
                        validateType={true}
                        onPressEnter={''}
                        className={''}
                        disabled={false}
                        addonBefore={''}
                        placeholder={'Masukan Kata Sandi Baru'}
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        value={this.props.newPassword}
                        maxLength={''}
                        style={{}}
                        size=""
                        onChange={this.props.handlePutNewPassword}
                        onBlur={this.props.handlePutNewPassword}
                        validatestatus={this.props.validateNewPassword}
                        type={'password'}
                        help={this.props.helpValidateNewPassword}
                      />
                    </div>

                    <div>
                      <span>
                        <b>Konfirmasi Kata Sandi Baru</b>
                      </span>
                      <FormInputTextMaterial
                        validateType={true}
                        onPressEnter={''}
                        className={''}
                        disabled={false}
                        addonBefore={''}
                        placeholder={'Masukan Kata Sandi Baru'}
                        prefix={
                          <Icon
                            type="lock"
                            style={{ color: 'rgba(0,0,0,.25)' }}
                          />
                        }
                        value={this.props.reNewPassword}
                        maxLength={''}
                        style={{}}
                        size=""
                        onChange={this.props.handlePutReNewPassword}
                        onBlur={this.props.handlePutReNewPassword}
                        validatestatus={this.props.validateReNewPassword}
                        type={'password'}
                        help={this.props.helpValidateReNewPassword}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      textAlign: 'right',
                      marginRight: 30,
                      marginTop: 20,
                    }}
                  >
                    <ButtonMaterial
                      disabled={ValidateButton}
                      handleSubmit={this.props.handleSavePassword}
                      teksButton={'Simpan'}
                      size={''}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
