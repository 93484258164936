import * as ActionAuth from '../../../action/Auth';
import * as ActionDetailBranch from '../../../../core/action/Branch/ActionDetailBranch';
import * as ActionLogin from '../../../../module/Login/Store';
import * as formCourier from '../../../action/ManageAccount/Courier/ActionFormDetailCourier';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_formDetailCourier(action: any) {
  const state = yield select();
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdCourier = state.Courier.PropertiesCourier.idCourier;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Courier/' + IdCourier,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(formCourier.changeCourierName(data.firstName));
    yield put(formCourier.changeCourierLastName(data.lastName));
    yield put(formCourier.changeCourierEmail(data.email));
    yield put(formCourier.changeCourierPhoneNumber(data.phone));
    yield put(formCourier.changeCourierDob(data.dob));
    yield put(formCourier.changeCourierCode(data.code));
    yield put(formCourier.changeAreaCodeId(data.id));
    yield put(formCourier.changeCourierAddress(data.address));
    yield put(formCourier.changeCourierStatus(data.isActive));
    yield put(formCourier.changeBranchCourier(data.branchName));
    yield put(toggle.changeModal());
    yield put(ActionDetailBranch.setBranchDetail(true));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_detailCourier() {
  yield takeLatest('CHANGE_COURIER_DETAIL', S_formDetailCourier);
}
