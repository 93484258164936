import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import HolidayComponent from '../Component/HolidayComponent';
import * as ActionToggle from '../../../core/action/toggle/index';
import { HolidayProps } from '../Interface';
import { getDataTableHoliday } from 'services/HolidayServices';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

const HolidayContainer = (props: HolidayProps) => {
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');

  const { data, isLoading, isFetching, refetch } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataHoliday', { page }],
    queryFn: ({ queryKey }) => getDataTableHoliday(queryKey),
  });

  const handleModal = () => {
    props.actionToggle.changeModal();
    props.actionToggle.changeJenisToggle('AddHoliday');
  };
  const handlePage = (event: any) => {
    setPage(event);
  };
  const handleSearch = (event: any) => {
    setSearch(event.target.value.toLowerCase());
  };

  return (
    <div>
      <HolidayComponent
        ValueSearch={search}
        isLoading={isLoading || isFetching}
        DataTableHoliday={data?.data?.data || []}
        PagePagination={data?.data?.page + 1 || 0}
        TotalPagePagination={data?.data?.totalPages * 10 || 0}
        handlePage={handlePage}
        handleModal={handleModal}
        handleSearch={handleSearch}
        refetch={refetch}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(HolidayContainer);
