export function setLoadingAdminBranch()
{
    return({
        type:"SET_LOADING_ADMIN_BRANCH"
    })
}
export function setTypeLoadingAdminBranch(LoadingTypeAdminBranch:any)
{
    return({
        type:"SET_LOADING_TYPE_ADMIN_BRANCH",
        LoadingTypeAdminBranch
    })
}
export function clearTypeLoadingAdminBranch()
{
    return({
        type:"CLEAR_LOADING_TYPE_ADMIN_BRANCH"
    })
}