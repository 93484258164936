import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import ModalDetailBagging from '../ModalDetailBagging';
import {
  getDataTableReceiveBagging,
  getSingleBaggingDocument,
  postReceiveBaggingDocument,
  getDataCountingReceiveBagging,
} from 'services/BaggingDocumentServices';
import AcceptedBagFilter from './Components/AcceptedBagFilter';
import AcceptedBagTable from './Components/AcceptedBagTable';
import { BaggingDocumentFormType } from '..';
import ModalStatus from 'materials/ModalStatus';
import moment from 'moment-timezone';

type AcceptedBagProps = {
  dataAllBranch: Array<any>;
  isLoadingBranch?: boolean;
  IdBranch?: number;
  roleAccount: string;
};

export default function AcceptedBag({
  dataAllBranch,
  isLoadingBranch,
  IdBranch,
  roleAccount,
}: AcceptedBagProps) {
  const [page, setPage] = useState<number>(0);
  const [deliveryCount, setDeliveryCount] = useState<number>(0);
  const [selectedBranchFrom, setSelectedBranchFrom] = useState<number | null>(
    null
  );
  const [responseSuccess, setResponseSuccess] = useState('');
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [barcodeBag, setBarcodeBag] = useState<string>('');
  const [searchBarcodeBag, setSearchBarcodeBag] = useState<string>('');
  const [selectedBagId, setSelectedBagId] = useState<number | null>(null);
  const [dataFormBagging, setDataFormBagging] =
    useState<BaggingDocumentFormType>({
      typeBag: null,
      dateSend: null,
      courier: null,
      branchFrom: null,
      branchTo: null,
      barcodeBag: null,
      finishBaggingTime: null,
      couriercode: null,
      branchFromName: null,
      branchToName: null,
    });

  const {
    data: dataTableBag,
    isFetching: isLoadingTableBag,
    refetch,
  } = useQuery<AxiosResponse>(
    ['TableBarcodeBag', page, selectedBranchFrom, searchBarcodeBag],
    () =>
      getDataTableReceiveBagging({
        page,
        destinationBranch: selectedBranchFrom,
        barcode: searchBarcodeBag,
      }),
    {
      enabled: !!selectedBranchFrom,
    }
  );

  const { isFetching: isLoadingCountingBag, refetch: refetchCounting } =
    useQuery<AxiosResponse>(
      ['TableBarcodeBag', selectedBranchFrom],
      () => getDataCountingReceiveBagging(selectedBranchFrom),
      {
        enabled: !!selectedBranchFrom,
        onSuccess: res => {
          setDeliveryCount(res?.data?.proccess);
        },
      }
    );

  const { mutate: submitReceiveBagging, isLoading: isLoadingReceive } =
    useMutation(postReceiveBaggingDocument, {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          setResponseSuccess(
            `Bag ${res?.data?.barcodeBagging} telah berhasil diterima oleh posko ${res?.data?.destinationBranch}`
          );
          refetch();
          refetchCounting();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const { isFetching: isLoadingSingleBagging } = useQuery<AxiosResponse, Error>(
    ['SingleDataBarcodeBag', selectedBagId],
    () => getSingleBaggingDocument(selectedBagId),
    {
      onSuccess: res => {
        const data = res.data || null;
        setDataFormBagging({
          typeBag: data?.baggingType,
          barcodeBag: data?.barcodeBagging,
          courier: data?.courierId,
          dateSend: moment(data?.deliveryBaggingTime),
          branchFrom: data?.originalBranchId,
          branchTo: data?.destinationBranchId,
          finishBaggingTime: moment(data?.finishBaggingTime),
          couriercode: data?.courier,
          branchFromName: data?.originalBranchName,
          branchToName: data?.destinationBranchName,
          baggingStatusDocument: data?.baggingStatusDocument,
        });
      },
      enabled: !!selectedBagId,
    }
  );

  useEffect(() => {
    if (IdBranch) {
      setSelectedBranchFrom(IdBranch);
    }
  }, [IdBranch]);

  const handleDetailBagging = (id: number) => {
    setSelectedBagId(id);
    setIsDetail(true);
  };

  const handleCloseModalDetail = () => {
    setSelectedBagId(null);
    setIsDetail(false);
  };

  const handleEnterBarcodeBag = barcode => {
    submitReceiveBagging({
      destinationBranch: selectedBranchFrom,
      barcodeBagging: barcode,
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <ModalDetailBagging
        isShowModal={isDetail}
        handleCloseModal={handleCloseModalDetail}
        dataFormBagging={dataFormBagging}
        isLoading={isLoadingSingleBagging}
        selectedBagId={selectedBagId}
      />
      <AcceptedBagFilter
        dataAllBranch={dataAllBranch}
        isLoadingBranch={isLoadingBranch}
        roleAccount={roleAccount}
        selectedBranchFrom={selectedBranchFrom}
        setSelectedBranchFrom={setSelectedBranchFrom}
        barcodeBag={barcodeBag}
        setBarcodeBag={setBarcodeBag}
        handleEnterBarcodeBag={handleEnterBarcodeBag}
        isLoadingScan={isLoadingReceive}
        responseSuccess={responseSuccess}
        deliveryCount={deliveryCount}
      />
      <AcceptedBagTable
        searchBarcodeBag={searchBarcodeBag}
        setSearchBarcodeBag={setSearchBarcodeBag}
        isLoading={isLoadingTableBag || isLoadingCountingBag}
        handlePage={value => setPage(value)}
        dataTableBag={dataTableBag?.data}
        handleDetailBagging={handleDetailBagging}
      />
    </div>
  );
}
