import { Form, Input } from 'antd';
import { Field } from 'react-final-form';

import React from 'react';

interface IProps {
  style?: any;
  disabled?: boolean;
  className?: any;
  placeholder?: any;
  maxLength?: any;
  addonBefore?: any;
  prefix?: any;
  size?: any;
  type?: any;
  validatestatus?: any;
  help?: any;
  validateType?: any;
  onPressEnter?: any;
  leftPrefix?: any;
  name?: any;
}

const { TextArea } = Input;

export default function FinalFormInput({
  validateType = false,
  type,
  className,
  disabled = false,
  addonBefore,
  prefix,
  style,
  placeholder,
  maxLength,
  size,
  onPressEnter,
  leftPrefix,
  name,
}: IProps) {
  return (
    <Field
      name={name}
      render={({ input, meta }) =>
        validateType === true ? (
          <Form.Item
            validateStatus={meta.error && meta.touched ? 'error' : 'validating'}
            help={meta.error && meta.touched && meta.error}
          >
            {type === 'password' ? (
              <Input.Password
                {...input}
                className={className}
                disabled={disabled}
                addonBefore={addonBefore}
                prefix={prefix}
                style={style}
                placeholder={placeholder}
                maxLength={maxLength}
                size={size}
                type={type}
                onPressEnter={onPressEnter}
              />
            ) : type === 'textArea' ? (
              <TextArea
                {...input}
                className={className}
                disabled={disabled}
                prefix={prefix}
                style={style}
                placeholder={placeholder}
                maxLength={maxLength}
                onPressEnter={onPressEnter}
              />
            ) : (
              <Input
                {...input}
                className={className}
                disabled={disabled}
                addonBefore={addonBefore}
                prefix={leftPrefix || prefix}
                style={style}
                placeholder={placeholder}
                maxLength={maxLength}
                size={size}
                type={type}
                onPressEnter={onPressEnter}
              />
            )}
          </Form.Item>
        ) : type === 'allowClear' ? (
          <Input
            {...input}
            className={className}
            disabled={disabled}
            allowClear
            addonBefore={addonBefore}
            prefix={prefix}
            style={style}
            placeholder={placeholder}
            maxLength={maxLength}
            size={size}
            type={type}
            onPressEnter={onPressEnter}
          />
        ) : (
          <Input
            {...input}
            className={className}
            disabled={disabled}
            addonBefore={addonBefore}
            prefix={prefix}
            style={style}
            placeholder={placeholder}
            maxLength={maxLength}
            size={size}
            type={type}
            onPressEnter={onPressEnter}
          />
        )
      }
    />
  );
}
