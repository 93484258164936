export function setListDataActive(ListDataActive: any) {
  return {
    type: 'SET_LIST_DATA_ACTIVE',
    ListDataActive,
  };
}
export function clearListDataActive() {
  return {
    type: 'CLEAR_LIST_DATA_ACTIVE',
  };
}
export function getListDataActive() {
  return {
    type: 'GET_LIST_DATA_ACTIVE',
  };
}
export function submitDownloadDataActive() {
  return {
    type: 'SUBMIT_DOWNLOAD_DATA_ACTIVE',
  };
}
export function submitSyncronDataActive() {
  return {
    type: 'SUBMIT_SYNCRON_DATA_ACTIVE',
  };
}
export function submitSearchDataActive() {
  return {
    type: 'SUBMIT_SEARCH_DATA_ACTIVE',
  };
}
