import instance from 'config/interceptor';

export const getSettingLocations = async params => {
  try {
    const { page = 0, isActiveType = 'Active', size = 10, search } = params;
    let link = `/MappingCourierArea/${isActiveType}/${page}/${size}`;
    if (search) link = link.concat(`?search=${search}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getSingleLocation = async id => {
  try {
    let link = `/MappingCourierArea/${id}`;

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const putSettingLocation = async data => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + `/MappingCourierArea/update`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const postSettingLocation = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/MappingCourierArea/upload`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const downloadExcelLocationTemplate = async () => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/MappingCourierArea/Template`,
      {},
      { responseType: 'arraybuffer' }
    );
  } catch (error) {
    return error;
  }
};
