import '../../../../styles/BlockLine/BlockLine.css';
import '../../../../styles/ProductLogoStyle/index.css';

import { Button, Col, Form, Icon, Input, Row, Select } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';
import GroupingReportContainer from '../../../../containers/Content/Customer/Product/GroupReportContainer';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import { InputSwitch } from 'components/InputField';

const { Option } = Select;

interface IProps {
  handleGetGroupName: any;
  handlePutGroup: any;
  getListGroupName: any;
  typeToggle: any;
  visible: any;
  isLoading: any;
  ProductName: any;
  ProductCode: any;
  ProductType: any;
  ProductTypeOther: any;
  ProductLogo: any;
  ProductBonus: any;
  District1: any;
  District2: any;
  District3: any;
  District4: any;
  District5: any;
  District6: any;
  District7: any;
  District8: any;
  District9: any;
  District10: any;
  District11: any;
  District12: any;
  File: any;
  FileNameLogo: any;
  ListProductBonus: any;
  ValidateProductName: any;
  ValidateProductCode: any;
  ValidateProductType: any;
  ValidateProductTypeOther: any;
  ValidateDistrict1: any;
  ValidateDistrict2: any;
  ValidateDistrict3: any;
  ValidateDistrict4: any;
  ValidateDistrict5: any;
  ValidateDistrict6: any;
  ValidateDistrict7: any;
  ValidateDistrict8: any;
  ValidateDistrict9: any;
  ValidateDistrict10: any;
  ValidateDistrict11: any;
  ValidateDistrict12: any;
  HelpValidateProductName: any;
  HelpValidateProductCode: any;
  HelpValidateProductType: any;
  HelpValidateDistrict1: any;
  HelpValidateDistrict2: any;
  HelpValidateDistrict3: any;
  HelpValidateDistrict4: any;
  HelpValidateDistrict5: any;
  HelpValidateDistrict6: any;
  HelpValidateDistrict7: any;
  HelpValidateDistrict8: any;
  HelpValidateDistrict9: any;
  HelpValidateDistrict10: any;
  HelpValidateDistrict11: any;
  HelpValidateDistrict12: any;
  handleProductName: any;
  handleProductCode: any;
  handleProductType: any;
  handleBlurProductType: any;
  handleProductTypeOther: any;
  handleDistrict1: any;
  handleDistrict2: any;
  handleDistrict3: any;
  handleDistrict4: any;
  handleDistrict5: any;
  handleDistrict6: any;
  handleDistrict7: any;
  handleDistrict8: any;
  handleDistrict9: any;
  handleDistrict10: any;
  handleDistrict11: any;
  handleDistrict12: any;
  handleConvert: any;
  handleCloseModal: any;
  handleEditCustomerProduct: any;
  handleAddCustomerProduct: any;
  handleChangeAddPriceBonus: any;
  handleAddPriceBonus: any;
  handleRemovePriceBonus: any;
  handleReset: any;
  handleModal: any;
  getGroupName: any;
  handleIsDisabled: (value: boolean) => void;
  isDisabled: boolean;
}
export default class FormCustomerProductComponent extends Component<IProps> {
  render() {
    const valueProductType =
      this.props.ProductType === null ? (
        <span style={{ color: '#969696' }}>Pilih jenis produk</span>
      ) : (
        this.props.ProductType
      );
    const disableCodeProduct =
      this.props.typeToggle === 'EditCustomerProduct' ? true : false;

    const ValidateButton =
      this.props.ProductName === null ||
      this.props.ProductName === '' ||
      this.props.ValidateProductName === 'error' ||
      this.props.ProductCode === null ||
      this.props.ProductCode === '' ||
      this.props.ValidateProductCode === 'error' ||
      this.props.ProductType === null ||
      this.props.ProductType === '' ||
      this.props.ValidateProductTypeOther === 'error' ||
      this.props.District1 === null ||
      this.props.District2 === null ||
      this.props.District3 === null ||
      this.props.District4 === null ||
      this.props.District5 === null ||
      this.props.District6 === null ||
      this.props.District7 === null ||
      this.props.District8 === null ||
      this.props.District9 === null ||
      this.props.District10 === null ||
      this.props.District11 === null ||
      this.props.District12 === null ||
      this.props.District1 === '' ||
      this.props.District2 === '' ||
      this.props.District3 === '' ||
      this.props.District4 === '' ||
      this.props.District5 === '' ||
      this.props.District6 === '' ||
      this.props.District7 === '' ||
      this.props.District8 === '' ||
      this.props.District9 === '' ||
      this.props.District10 === '' ||
      this.props.District11 === '' ||
      this.props.District12 === '' ||
      this.props.ValidateDistrict1 === 'error' ||
      this.props.ValidateDistrict2 === 'error' ||
      this.props.ValidateDistrict3 === 'error' ||
      this.props.ValidateDistrict4 === 'error' ||
      this.props.ValidateDistrict5 === 'error' ||
      this.props.ValidateDistrict6 === 'error' ||
      this.props.ValidateDistrict7 === 'error' ||
      this.props.ValidateDistrict8 === 'error' ||
      this.props.ValidateDistrict9 === 'error' ||
      this.props.ValidateDistrict10 === 'error' ||
      this.props.ValidateDistrict11 === 'error' ||
      this.props.ValidateDistrict12 === 'error' ||
      this.props.ListProductBonus === [] ||
      this.props.ListProductBonus.length === 0
        ? true
        : false;

    const validateButtonReset =
      (this.props.District1 === ' ' ||
        this.props.District1 === 3 ||
        this.props.District1 === '3') &&
      (this.props.District2 === ' ' ||
        this.props.District2 === 5 ||
        this.props.District2 === '5') &&
      (this.props.District3 === ' ' ||
        this.props.District3 === 8 ||
        this.props.District3 === '8') &&
      (this.props.District4 === ' ' ||
        this.props.District4 === 3 ||
        this.props.District4 === '3') &&
      (this.props.District5 === ' ' ||
        this.props.District5 === 3 ||
        this.props.District5 === '3') &&
      (this.props.District6 === ' ' ||
        this.props.District6 === 3 ||
        this.props.District6 === '3') &&
      (this.props.District7 === ' ' ||
        this.props.District7 === 3 ||
        this.props.District7 === '3') &&
      (this.props.District8 === ' ' ||
        this.props.District8 === 3 ||
        this.props.District8 === '3') &&
      (this.props.District9 === ' ' ||
        this.props.District9 === 3 ||
        this.props.District9 === '3') &&
      (this.props.District10 === ' ' ||
        this.props.District10 === 3 ||
        this.props.District10 === '3') &&
      (this.props.District11 === ' ' ||
        this.props.District11 === 3 ||
        this.props.District11 === '3') &&
      (this.props.District12 === ' ' ||
        this.props.District12 === 3 ||
        this.props.District12 === '3')
        ? true
        : this.props.District1 !== null ||
          this.props.District2 !== null ||
          this.props.District3 !== null ||
          this.props.District4 !== null ||
          this.props.District5 !== null ||
          this.props.District6 !== null ||
          this.props.District7 !== null ||
          this.props.District8 !== null ||
          this.props.District9 !== null ||
          this.props.District10 !== null ||
          this.props.District11 !== null ||
          this.props.District12 !== null ||
          this.props.District1 !== '' ||
          this.props.District2 !== '' ||
          this.props.District3 !== '' ||
          this.props.District4 !== '' ||
          this.props.District5 !== '' ||
          this.props.District6 !== '' ||
          this.props.District7 !== '' ||
          this.props.District8 !== '' ||
          this.props.District9 !== '' ||
          this.props.District10 !== '' ||
          this.props.District11 !== '' ||
          this.props.District12 !== ''
        ? false
        : true;

    const visible2 =
      this.props.typeToggle === 'EditCustomerProduct' ||
      this.props.typeToggle === 'AddCustomerProduct'
        ? true
        : false;

    return (
      <div>
        <GroupingReportContainer />
        <ModalMaterial
          className={''}
          width={'50%'}
          style={{}}
          teksTitle={
            this.props.typeToggle === 'EditCustomerProduct'
              ? 'UBAH PRODUK PELANGGAN'
              : 'TAMBAHKAN PRODUK PELANGGAN'
          }
          visible={
            this.props.visible === true && visible2 === true ? true : false
          }
          onCancel={this.props.handleCloseModal}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div style={{ padding: '20px' }}>
                  {this.props.typeToggle === 'EditCustomerProduct' && (
                    <>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          minHeight: '100px',
                          background: !this.props.ProductLogo ? '#FAFAFA' : '',
                        }}
                      >
                        {this.props.ProductLogo ? (
                          <img
                            className={'img'}
                            style={{
                              width: '150px',
                              height: '100px',
                              objectFit: 'cover',
                            }}
                            alt="Product Logo"
                            src={`data:image/png;base64, ${this.props.ProductLogo}`}
                          />
                        ) : (
                          <p
                            style={{
                              color: '#9E9E9E',
                              fontSize: 16,
                              fontWeight: 600,
                              margin: 0,
                            }}
                          >
                            No Logo
                          </p>
                        )}
                      </div>
                      <hr></hr>
                    </>
                  )}
                  <div>
                    <InputSwitch
                      input={{
                        value: !this.props.isDisabled,
                        onChange: value => this.props.handleIsDisabled(!value),
                      }}
                      meta={{}}
                      isLabelActive
                      formStyle={{ margin: 0 }}
                    />
                  </div>
                  <Row gutter={[24, 24]} style={{ marginTop: 8 }}>
                    <Col span={12}>
                      <div>
                        <span>
                          <b>Nama </b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          className={''}
                          onPressEnter={''}
                          disabled={false}
                          addonBefore={''}
                          placeholder={'Contoh : RDS Life Insurance'}
                          prefix={''}
                          value={this.props.ProductName}
                          maxLength={30}
                          style={{}}
                          size=""
                          type={''}
                          onChange={this.props.handleProductName}
                          onBlur={this.props.handleProductName}
                          validatestatus={this.props.ValidateProductName}
                          help={this.props.HelpValidateProductName}
                        />
                      </div>
                      <div>
                        <span>
                          <b>Kode</b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          className={''}
                          onPressEnter={''}
                          disabled={disableCodeProduct}
                          addonBefore={''}
                          placeholder={'Contoh : AB'}
                          prefix={''}
                          value={this.props.ProductCode}
                          maxLength={2}
                          style={{}}
                          size=""
                          type={''}
                          onChange={this.props.handleProductCode}
                          onBlur={this.props.handleProductCode}
                          validatestatus={this.props.ValidateProductCode}
                          help={this.props.HelpValidateProductCode}
                        />
                      </div>
                      <div>
                        <span>
                          <b>Grup Laporan</b>
                          <Button
                            onClick={this.props.handleModal}
                            type="link"
                            disabled={false}
                            className={'titleReportGroup'}
                          >
                            <u>Tambah grup baru</u>
                          </Button>
                        </span>
                        <Form.Item>
                          <Select
                            // loading={this.props.loadingSelectCustomer}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            showSearch
                            placeholder={'Pilih Group'}
                            onChange={this.props.handlePutGroup}
                            value={
                              this.props.getGroupName === null ? (
                                <span className="colorPlaceholderDropDown">
                                  Pilih Group
                                </span>
                              ) : (
                                this.props.getGroupName
                              )
                            }
                            onFocus={this.props.handleGetGroupName}
                          >
                            <Option key={0} value={0}>
                              {'-'}
                            </Option>
                            {this.props.getListGroupName.map(
                              (data: any, index: any) => (
                                <Option key={index} value={data.idGroupProduct}>
                                  {data.groupingName}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div>
                        <span>
                          <b>Unggah Logo </b>
                        </span>
                        <Input
                          onChange={this.props.handleConvert}
                          value={this.props.FileNameLogo}
                          suffix={<Icon type="upload" />}
                          multiple={false}
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          style={{ marginTop: 4 }}
                        />
                      </div>
                      <div style={{ paddingTop: '5px' }}>
                        <span
                          style={{
                            fontStyle: 'italic',
                            color: '#969696',
                            fontSize: 12,
                          }}
                        >
                          Ukuran 320 x 120, maksimal size 100 KB
                        </span>
                      </div>
                      <div>
                        <span>
                          <b>Jenis </b>
                        </span>
                        <Form.Item
                          validateStatus={this.props.ValidateProductType}
                          help={this.props.HelpValidateProductType}
                        >
                          <Select
                            style={{ width: '100%' }}
                            onSelect={this.props.handleProductType}
                            optionFilterProp="children"
                            value={valueProductType}
                            onChange={this.props.handleProductType}
                            onBlur={this.props.handleBlurProductType}
                          >
                            <Option value="Card">Kartu</Option>
                            <Option value="Policy">Polis</Option>
                            <Option value="Letter">Surat</Option>
                            <Option value="Others">Lainnya</Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <hr></hr>
                  <div style={{ padding: '20px 0' }}>
                    <span>
                      <b>SLA </b>
                    </span>
                  </div>
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <div>
                        <div>
                          <span>
                            <b>Jabodetabek </b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={''}
                            addonBefore={''}
                            placeholder={'Contoh : 3'}
                            prefix={''}
                            value={this.props.District1}
                            maxLength={3}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleDistrict1}
                            onBlur={this.props.handleDistrict1}
                            validatestatus={this.props.ValidateDistrict1}
                            help={this.props.HelpValidateDistrict1}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Luar Jawa</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={''}
                            addonBefore={''}
                            placeholder={'Contoh : 8'}
                            prefix={''}
                            value={this.props.District3}
                            maxLength={3}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleDistrict3}
                            onBlur={this.props.handleDistrict3}
                            validatestatus={this.props.ValidateDistrict3}
                            help={this.props.HelpValidateDistrict3}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Jakarta</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={''}
                            addonBefore={''}
                            placeholder={'Contoh : 3'}
                            prefix={''}
                            value={this.props.District5}
                            maxLength={3}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleDistrict5}
                            onBlur={this.props.handleDistrict5}
                            validatestatus={this.props.ValidateDistrict5}
                            help={this.props.HelpValidateDistrict5}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Kalimantan dan Sumatra</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={''}
                            addonBefore={''}
                            placeholder={'Contoh : 3'}
                            prefix={''}
                            value={this.props.District7}
                            maxLength={3}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleDistrict7}
                            onBlur={this.props.handleDistrict7}
                            validatestatus={this.props.ValidateDistrict7}
                            help={this.props.HelpValidateDistrict7}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Papua NTT dan NTB</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={''}
                            addonBefore={''}
                            placeholder={'Contoh : 3'}
                            prefix={''}
                            value={this.props.District9}
                            maxLength={3}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleDistrict9}
                            onBlur={this.props.handleDistrict9}
                            validatestatus={this.props.ValidateDistrict9}
                            help={this.props.HelpValidateDistrict9}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Sumapa</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={''}
                            addonBefore={''}
                            placeholder={'Contoh : 3'}
                            prefix={''}
                            value={this.props.District11}
                            maxLength={3}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleDistrict11}
                            onBlur={this.props.handleDistrict11}
                            validatestatus={this.props.ValidateDistrict11}
                            help={this.props.HelpValidateDistrict11}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div>
                        <div>
                          <span>
                            <b>Jawa </b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={''}
                            addonBefore={''}
                            placeholder={'Contoh : 5'}
                            prefix={''}
                            value={this.props.District2}
                            maxLength={3}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleDistrict2}
                            onBlur={this.props.handleDistrict2}
                            validatestatus={this.props.ValidateDistrict2}
                            help={this.props.HelpValidateDistrict2}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Indonesia Timur</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={''}
                            addonBefore={''}
                            placeholder={'Contoh : 3'}
                            prefix={''}
                            value={this.props.District4}
                            maxLength={3}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleDistrict4}
                            onBlur={this.props.handleDistrict4}
                            validatestatus={this.props.ValidateDistrict4}
                            help={this.props.HelpValidateDistrict4}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Bodetabek</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={''}
                            addonBefore={''}
                            placeholder={'Contoh : 3'}
                            prefix={''}
                            value={this.props.District6}
                            maxLength={3}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleDistrict6}
                            onBlur={this.props.handleDistrict6}
                            validatestatus={this.props.ValidateDistrict6}
                            help={this.props.HelpValidateDistrict6}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Sulawesi Maluku dan Bali</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={''}
                            addonBefore={''}
                            placeholder={'Contoh : 3'}
                            prefix={''}
                            value={this.props.District8}
                            maxLength={3}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleDistrict8}
                            onBlur={this.props.handleDistrict8}
                            validatestatus={this.props.ValidateDistrict8}
                            help={this.props.HelpValidateDistrict8}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Sumkal</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={''}
                            addonBefore={''}
                            placeholder={'Contoh : 3'}
                            prefix={''}
                            value={this.props.District10}
                            maxLength={3}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleDistrict10}
                            onBlur={this.props.handleDistrict10}
                            validatestatus={this.props.ValidateDistrict10}
                            help={this.props.HelpValidateDistrict10}
                          />
                        </div>
                        <div>
                          <span>
                            <b>Balinusa</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={''}
                            onPressEnter={''}
                            disabled={''}
                            addonBefore={''}
                            placeholder={'Contoh : 3'}
                            prefix={''}
                            value={this.props.District12}
                            maxLength={3}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.handleDistrict12}
                            onBlur={this.props.handleDistrict12}
                            validatestatus={this.props.ValidateDistrict12}
                            help={this.props.HelpValidateDistrict12}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr></hr>
                  <Row gutter={[24, 24]}>
                    <Col span={12}>
                      <div>
                        <span>
                          <b>Harga Bonus</b>
                        </span>
                        {this.props.ListProductBonus.map(
                          (data: any, index: any) => (
                            <div key={data.index}>
                              <Row>
                                <Col
                                  span={3}
                                  style={{
                                    paddingRight: '10px',
                                    paddingTop: '8px',
                                  }}
                                >
                                  <b style={{ color: '#11BEFF' }}>
                                    H{index + 1}
                                  </b>
                                </Col>
                                <Col style={{ paddingRight: '10px' }} span={18}>
                                  <FormInputTextMaterial
                                    validateType={true}
                                    className={''}
                                    disabled={false}
                                    addonBefore={''}
                                    onPressEnter={''}
                                    placeholder={'Contoh: 500'}
                                    prefix={'Rp. '}
                                    value={data}
                                    maxLength={6}
                                    style={{
                                      background: '#FFFFFF',
                                      paddingLeft: '10px',
                                      fontWeight: 'bold',
                                    }}
                                    size=""
                                    type={''}
                                    onChange={(e: any) =>
                                      this.props.handleChangeAddPriceBonus(
                                        e,
                                        index
                                      )
                                    }
                                    onBlur={''}
                                    validatestatus={''}
                                    help={''}
                                  />
                                </Col>
                                <Col span={3}>
                                  <div style={{ paddingTop: '3px' }}>
                                    <ButtonMaterial
                                      disabled={''}
                                      handleSubmit={() =>
                                        this.props.handleRemovePriceBonus(index)
                                      }
                                      teksButton={''}
                                      size={''}
                                      shape={''}
                                      className={''}
                                      type={'primary'}
                                      icon={'delete'}
                                      style={{
                                        float: 'right',
                                        borderRadius: '8px',
                                        color: '#fafafa',
                                        paddingBottom: '3px',
                                        background: 'red',
                                        borderColor: 'red',
                                      }}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          )
                        )}
                        <Button
                          disabled={
                            this.props.ListProductBonus.length === 7
                              ? true
                              : false
                          }
                          onClick={() => this.props.handleAddPriceBonus()}
                          type={'dashed'}
                          size={'default'}
                          icon={'plus'}
                          style={{
                            width: '100%',
                            color: '#31E116',
                            borderRadius: '12px',
                            marginTop: '3px',
                          }}
                        >
                          <span style={{ color: 'black' }}>Tambahkan Data</span>
                        </Button>
                      </div>
                    </Col>
                    <Col span={12}></Col>
                  </Row>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      gap: 14,
                    }}
                  >
                    <div>
                      <ButtonMaterial
                        disabled={validateButtonReset}
                        handleSubmit={this.props.handleReset}
                        teksButton={<b>Reset SLA</b>}
                        size={''}
                        shape={'round'}
                        className={''}
                        type={'primary'}
                        icon={''}
                        style={
                          validateButtonReset === false
                            ? {
                                background: '#FF5858',
                                borderColor: '#FF5858',
                                colorFont: '#FFFFFF',
                                width: '100px',
                              }
                            : {
                                width: '100px',
                              }
                        }
                      />
                    </div>
                    <div>
                      <ButtonMaterial
                        disabled={ValidateButton}
                        handleSubmit={
                          this.props.typeToggle === 'EditCustomerProduct'
                            ? this.props.handleEditCustomerProduct
                            : this.props.handleAddCustomerProduct
                        }
                        teksButton={<b>Simpan</b>}
                        size={''}
                        shape={'round'}
                        className={''}
                        type={'primary'}
                        icon={''}
                        style={{
                          width: '100px',
                        }}
                      />
                    </div>
                  </div>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
