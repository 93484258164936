import * as ActionDetailCustomer from '../../../core/action/Customer/ActionDetailCustomer'
import * as ActionNavigation from '../../../core/action/Router/ActionRouter'
import * as ActionPagination from '../../../core/action/Pagination/ActionPagination';
import * as ActionToggle from '../../../core/action/toggle/index'

import React, { Component } from 'react'
import {bindActionCreators, compose} from 'redux';

import ButtonCustomerComponent from '../../../components/Content/Customer/ButtonCustomerComponent';
import {connect} from 'react-redux';
import history from '../../../core/utils/History';

interface IProps{
    idCustomer:any
    actionCustomerDetail:any
    actionToggle:any
    actionNavigation:any
    actionPagination:any
    typeButton:any
}
class ButtonCustomerContainer extends Component<IProps> {
    constructor(props:any)
    {
        super(props)
        
        this.handleCustomerDetail = this.handleCustomerDetail.bind(this)
        this.handleCustomerEdit = this.handleCustomerEdit.bind(this)
        this.handleCustomerConfig = this.handleCustomerConfig.bind(this)
    }
    handleCustomerDetail()
    {
        this.props.actionPagination.clearAllPagination();
        this.props.actionCustomerDetail.changeCustomerId(this.props.idCustomer)
        this.props.actionCustomerDetail.changeEditCustomer(true)
        history.push("/customer/detail")
    }
    handleCustomerEdit()
    {
        this.props.actionCustomerDetail.changeCustomerId(this.props.idCustomer)
        this.props.actionToggle.changeModal()
        this.props.actionToggle.changeJenisToggle("EditCustomer")
        this.props.actionCustomerDetail.changeDetailCustomer()
    }
    handleCustomerConfig()
    {
        this.props.actionCustomerDetail.changeCustomerId(this.props.idCustomer)
        this.props.actionCustomerDetail.changeDetailCustomer()
        this.props.actionNavigation.setRouter("/customer")
        history.push("/customer/config")
    }
    render() {
        
        return (
            <ButtonCustomerComponent
            handleCustomerDetail={this.handleCustomerDetail}
            handleCustomerEdit={this.handleCustomerEdit}
            handleCustomerConfig={this.handleCustomerConfig}
            typeButton={this.props.typeButton}/>
        )
    }
}
const mapStateProps = (state:any) => ({

})

const mapDispatchToProps = (dispatch:any) => ({
    actionCustomerDetail : bindActionCreators(ActionDetailCustomer, dispatch),
    actionToggle : bindActionCreators(ActionToggle, dispatch),
    actionNavigation : bindActionCreators(ActionNavigation, dispatch),
    actionPagination : bindActionCreators(ActionPagination, dispatch)
})

const withConnect = connect(mapStateProps, mapDispatchToProps)

export default compose(
    withConnect
)(ButtonCustomerContainer)
