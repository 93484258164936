import * as ActionAuth from '../../../action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as formCourier from '../../../action/ManageAccount/Courier/ActionFormDetailCourier';
import * as table from '../../../action/ManageAccount/Courier/ActionTableCourier';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_submitAddACourier(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const CourierName = state.Courier.FormCourier.CourierName;
  const CourierLastName = state.Courier.FormCourier.CourierLastName;
  const CourierEmail = state.Courier.FormCourier.CourierEmail;
  const CourierPhoneNumber = state.Courier.FormCourier.CourierPhoneNumber;
  const CourierDob = state.Courier.FormCourier.CourierDob;
  const CourierPhoto = state.Courier.FormCourier.CourierPhoto;
  const CourierCode = state.Courier.FormCourier.CourierCode;
  // const CourierUsername = state.Courier.FormCourier.CourierName.replace(" ", "").toLowerCase()+'_'+state.Courier.FormCourier.CourierCode
  const CourierUsername = state.Courier.FormCourier.CourierCode;
  const CourierPassword = state.Courier.FormCourier.CourierCode;
  const CourierAddress = state.Courier.FormCourier.CourierAddress;
  const AreaCode = state.Courier.FormCourier.AreaCodeId;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const CourierStatus = state.Courier.FormCourier.CourierStatus;
  const conditionCB = CourierStatus === 1 ? true : false;

  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Courier',
      {
        firstName: CourierName,
        lastName: CourierLastName,
        photo: CourierPhoto,
        email: CourierEmail,
        phone: CourierPhoneNumber,
        dob: CourierDob,
        code: CourierCode,
        username: CourierUsername,
        password: CourierPassword,
        branchId: AreaCode,
        address: CourierAddress,
        isActive: conditionCB,
      },
      { headers: config }
    );

    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(formCourier.changeClearDataCourierForm());
    yield put(toggle.clearJenisToggle());
    if (state.Auth.Role === 'Branch') {
      yield put(table.getCourierTableBranch());
    } else {
      yield put(table.getCourierTable());
    }
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil di simpan</b>
        </p>
      ),
      onOk() {},
    });
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_addCourier() {
  yield takeLatest('SUBMIT_ADD_COURIER', S_submitAddACourier);
}
