export function setCycleDateStart(CycleDateStart:any)
{
    return({
        type:"SET_CYCLE_DATE_START",
        CycleDateStart
    })
}

export function setChecboxIsMonth(CheckboxIsMonth:any)
{
    return({
        type:"SET_CHECKBOX_IS_MONTH",
        CheckboxIsMonth
    })
}
export function setRadiobuttonIsDetailInvoice(RadiobuttonIsDetailInvoice:any)
{
    return({
        type:"SET_RADIOBUTTON_IS_DETAIL_INVOICE",
        RadiobuttonIsDetailInvoice
    })
}
export function setCustomerId(CustomerId:any)
{
    return({
        type:"SET_CUSTOMER_ID",
        CustomerId
    })
}
export function setCustomerProductId(CustomerProductId:any)
{
    return({
        type:"SET_CUSTOMER_PRODUCT_ID",
        CustomerProductId
    })
}
export function clearAllFormInvoice()
{
    return({
        type:"CLEAR_ALL_FORM_INVOICE"
    })
}
export function clearCustomerProductId()
{
    return({
        type:"CLEAR_CUSTOMER_PRODUCT_ID"
    })
}