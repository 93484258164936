import React from 'react';
import FilterTable from './FilterTable';
import { DataTableResProps } from 'interface/general';
import { FilterDataJamProps } from '../../container/JamPengiriman';
import JamDataTable from './JamDataTable';

type JamPengirimanComponentProps = {
  dataAllBranch: Array<any>;
  isLoadingBranch?: boolean;
  IdBranch?: number;
  roleAccount: string;
  dataFilterJam: FilterDataJamProps;
  handleResetFilter: () => void;
  handleChangeFilter: (name: string, value: any) => void;
  handlePage: (value: number) => void;
  handleSubmitFilter: (value: FilterDataJamProps) => void;
  dataTableJam: DataTableResProps;
  isLoadingTable: boolean;
  handleSearchValue: (value: string) => void;
  selectedDownloadJam: string;
  setSelectedDownloadJam: (value: string) => void;
  handleDownloadFile: () => void;
};

export default function JamPengirimanComponent({
  dataAllBranch,
  isLoadingBranch,
  IdBranch,
  roleAccount,
  dataFilterJam,
  handleResetFilter,
  handleChangeFilter,
  handleSubmitFilter,
  dataTableJam,
  isLoadingTable,
  handlePage,
  handleSearchValue,
  selectedDownloadJam,
  setSelectedDownloadJam,
  handleDownloadFile,
}: JamPengirimanComponentProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <FilterTable
        dataAllBranch={dataAllBranch}
        isLoadingBranch={isLoadingBranch}
        selectedBranchId={IdBranch}
        roleAccount={roleAccount}
        dataFilterJam={dataFilterJam}
        handleResetFilter={handleResetFilter}
        handleChangeFilter={handleChangeFilter}
        handleSubmitFilter={handleSubmitFilter}
      />
      <JamDataTable
        dataTableJam={dataTableJam}
        isLoadingTable={isLoadingTable}
        handlePage={handlePage}
        handleSearchValue={value => handleSearchValue(value)}
        selectedDownloadJam={selectedDownloadJam}
        setSelectedDownloadJam={setSelectedDownloadJam}
        handleDownloadFile={handleDownloadFile}
      />
    </div>
  );
}
