import React from 'react';
import { Row, Col } from 'antd';
import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import SelectField from 'components/SelectField';
import { SingleCheckbox } from 'components/SingleCheckbox';
import { InputSwitch, InputText } from 'components/InputField';
import Button from 'components/Button';
import { Form, Field } from 'react-final-form';

export type InitialValueFormCustomerFormat = {
  isActive: boolean;
  nameFormatReport: string;
  formatFile: string;
  settingFile: string;
  periode: string;
  delimited: string;
  disburseData: string;
  noHeader: boolean;
  showInPortal: boolean;
};

type FormCustomerReportProps = {
  show?: boolean;
  isLoading?: boolean;
  onClickCancel?: () => void;
  isView?: boolean;
  isEdit?: boolean;
  customerName?: string;
  initialValue?: InitialValueFormCustomerFormat;
  dataCsvList: any[];
  handleOnChange?: (name: string, value: any) => void;
  handleSubmit?: () => void;
  isNotModal?: boolean;
};

const FormComponent = ({
  isLoading = false,
  isView = false,
  initialValue,
  dataCsvList = [],
  handleOnChange = () => undefined,
  handleSubmit = () => undefined,
  isNotModal,
}: FormCustomerReportProps) => {
  return (
    <SpinMaterial size="large" spinning={isLoading}>
      <Form
        initialValues={initialValue}
        onSubmit={handleSubmit}
        validate={values => {
          const error = {} as any;
          if (!values.nameFormatReport) {
            error.nameFormatReport = 'Nama Format Laporan tidak boleh kosong';
          }
          if (!values.formatFile) {
            error.formatFile = 'Format File wajib di pilih';
          }
          if (!values.settingFile) {
            error.settingFile = 'Pengaturan File wajib di pilih';
          }
          if (!values.periode) {
            error.periode = 'Periode wajib di pilih';
          }
          if (!values.delimited) {
            error.delimited = 'Delimited wajib di pilih';
          }
          if (!values.disburseData) {
            error.disburseData = 'Penarikan Data wajib di pilih';
          }
          return error;
        }}
      >
        {({ handleSubmit, invalid, values }) => (
          <form
            style={{ padding: isNotModal ? '0' : '20px 24px 0 24px' }}
            onSubmit={handleSubmit}
          >
            {isView ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                <p style={{ fontSize: 16, fontWeight: 600, color: '#1890FF' }}>
                  Status:
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: initialValue?.isActive ? '#00AB44' : '#EA1A1A',
                  }}
                >
                  {initialValue?.isActive ? 'Aktif' : 'Nonaktif'}
                </p>
              </div>
            ) : (
              <Field name="isActive">
                {({ input, meta }) => (
                  <InputSwitch
                    input={input}
                    meta={meta}
                    isLabelActive
                    disabled={isView}
                    onChange={value => handleOnChange('isActive', value)}
                  />
                )}
              </Field>
            )}
            <Row gutter={[32, 32]}>
              <Col
                span={12}
                style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
              >
                <Field name="nameFormatReport">
                  {({ input, meta }) => (
                    <InputText
                      label="Nama Format Laporan"
                      placeholder="Contoh: Laporan RDS Express Versi 1"
                      formStyle={{ margin: 0 }}
                      meta={meta}
                      disabled={isView}
                      input={input}
                      value={initialValue?.nameFormatReport}
                      onChange={value =>
                        handleOnChange('nameFormatReport', value)
                      }
                    />
                  )}
                </Field>
                <Field name="formatFile">
                  {({ input, meta }) => (
                    <SelectField
                      label="Format File"
                      placeholder="Pilih Format File"
                      value={input.value}
                      data={[
                        { name: 'EXCEL', id: 'Excel' },
                        { name: 'CSV', id: 'CSV' },
                        { name: 'TXT', id: 'TXT' },
                      ]}
                      disabled={isView}
                      onBlur={input.onBlur}
                      onChange={value => {
                        if (value === 'Excel') {
                          handleOnChange('delimited', 'Empty');
                        }
                        handleOnChange('formatFile', value);
                      }}
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      errorMessage={meta.error && meta.touched && meta.error}
                      validate
                    />
                  )}
                </Field>
                <Field name="settingFile">
                  {({ input, meta }) => (
                    <SelectField
                      label="Pengaturan File"
                      placeholder="Pilih format file CSV"
                      labelKey="nameCustomCsv"
                      value={input.value}
                      data={dataCsvList}
                      disabled={isView}
                      onBlur={input.onBlur}
                      onChange={value => handleOnChange('settingFile', value)}
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      errorMessage={meta.error && meta.touched && meta.error}
                      validate
                    />
                  )}
                </Field>
              </Col>
              <Col
                span={12}
                style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
              >
                <Field name="periode">
                  {({ input, meta }) => (
                    <SelectField
                      label="Periode"
                      placeholder="Pilih Periode"
                      value={input.value}
                      data={[
                        { name: 'Harian', id: 'Daily' },
                        { name: 'Mingguan', id: 'Weekly' },
                        { name: 'Bulanan', id: 'Monthly' },
                      ]}
                      disabled={isView}
                      onBlur={input.onBlur}
                      onChange={value => handleOnChange('periode', value)}
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      errorMessage={meta.error && meta.touched && meta.error}
                      validate
                    />
                  )}
                </Field>
                <Field name="delimited">
                  {({ input, meta }) => (
                    <SelectField
                      label="Delimited"
                      placeholder="Pilih Delimited"
                      value={input.value}
                      data={[
                        { name: '; (Titik Koma)', id: 'SemiColon' },
                        { name: ', (Koma)', id: 'Comma' },
                        { name: '| (Separator)', id: 'SeparateList' },
                      ]}
                      disabled={isView || values.formatFile === 'Excel'}
                      onBlur={input.onBlur}
                      onChange={value => handleOnChange('delimited', value)}
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      errorMessage={meta.error && meta.touched && meta.error}
                      validate
                    />
                  )}
                </Field>
                <Field name="disburseData">
                  {({ input, meta }) => (
                    <SelectField
                      label="Penarikan Data"
                      placeholder="Pilih Penarikan Data"
                      data={[
                        { name: 'Laporan', id: 'CustomCustomerReport' },
                        {
                          name: 'Permintaan No Hp',
                          id: 'CustomRequestPhoneNumber',
                        },
                      ]}
                      value={input.value}
                      disabled={isView}
                      onBlur={input.onBlur}
                      onChange={value => handleOnChange('disburseData', value)}
                      validateStatus={
                        meta.error && meta.touched ? 'error' : 'validating'
                      }
                      errorMessage={meta.error && meta.touched && meta.error}
                      validate
                    />
                  )}
                </Field>
                <div style={{ display: 'flex', gap: 24, alignItems: 'center' }}>
                  <Field name="noHeader">
                    {({ input, meta }) => (
                      <SingleCheckbox
                        labelRight="Tanpa Header"
                        disabled={isView}
                        input={input}
                        onChange={value =>
                          handleOnChange('noHeader', !input.value)
                        }
                      />
                    )}
                  </Field>
                  <Field name="showInPortal">
                    {({ input, meta }) => (
                      <SingleCheckbox
                        labelRight="Tampilkan di Portal Customer"
                        disabled={isView}
                        input={input}
                        onChange={value =>
                          handleOnChange('showInPortal', !input.value)
                        }
                      />
                    )}
                  </Field>
                </div>
              </Col>
            </Row>
            {!isView && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  width: '100%',
                }}
              >
                <Button text="Simpan" htmlType="submit" disabled={invalid} />
              </div>
            )}
          </form>
        )}
      </Form>
    </SpinMaterial>
  );
};

export default function FormCustomerFormatReport({
  show = false,
  isLoading = false,
  isNotModal = false,
  onClickCancel,
  isView = false,
  isEdit = false,
  customerName = '',
  initialValue,
  dataCsvList = [],
  handleOnChange = () => undefined,
  handleSubmit = () => undefined,
}: FormCustomerReportProps) {
  if (isNotModal) {
    return (
      <FormComponent
        initialValue={initialValue}
        isLoading={isLoading}
        dataCsvList={dataCsvList}
        handleOnChange={handleOnChange}
        handleSubmit={handleSubmit}
        isNotModal={isNotModal}
        isView={isView}
        isEdit={isEdit}
      />
    );
  }

  return (
    <ModalMaterial
      visible={show}
      width={'70%'}
      teksTitle={`${
        isEdit ? 'UBAH' : 'TAMBAHKAN'
      } FORMAT LAPORAN ${customerName.toLocaleUpperCase()}`}
      onCancel={onClickCancel}
    >
      <FormComponent
        initialValue={initialValue}
        isLoading={isLoading}
        dataCsvList={dataCsvList}
        handleOnChange={handleOnChange}
        handleSubmit={handleSubmit}
        isNotModal={isNotModal}
        isView={isView}
        isEdit={isEdit}
      />
    </ModalMaterial>
  );
}
