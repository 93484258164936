import React from 'react';
import FilterTable from './FilterTable';
import DataTablePerforma from './DataTablePerforma';
import { DataTableResProps } from 'interface/general';
import { FilterPerformaKurirProps } from '../../container/PerformaKurir';

type PerformaKurirComponentsProps = {
  dataAllBranch: Array<any>;
  dataCustomers: Array<any>;
  dataProducts: Array<any>;
  isLoadingBranch?: boolean;
  isLoadingCustomers?: boolean;
  isLoadingProducts?: boolean;
  IdBranch?: number;
  roleAccount: string;
  initialValue: any;
  handleResetFilter: () => void;
  handleDownload: () => void;
  handleChangeFilter: (name: string, value: any) => void;
  handleSubmitFilter: (value: FilterPerformaKurirProps) => void;
  dataTable: DataTableResProps;
  isLoadingTable: boolean;
  handlePage: (value: number) => void;
  downloadForm: string;
  setDownloadForm: (value: string) => void;
};

export default function PerformaKurirComponents({
  dataCustomers,
  dataProducts,
  isLoadingCustomers,
  isLoadingProducts,
  isLoadingTable,
  dataTable,
  dataAllBranch,
  isLoadingBranch,
  IdBranch,
  roleAccount,
  initialValue,
  handleChangeFilter,
  handleResetFilter,
  handleSubmitFilter,
  handlePage,
  handleDownload,
  downloadForm,
  setDownloadForm,
}: PerformaKurirComponentsProps) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <FilterTable
        dataAllBranch={dataAllBranch}
        isLoadingBranch={isLoadingBranch}
        selectedBranchId={IdBranch}
        roleAccount={roleAccount}
        initialValue={initialValue}
        handleResetFilter={handleResetFilter}
        handleChangeFilter={handleChangeFilter}
        handleSubmitFilter={handleSubmitFilter}
        dataCustomers={dataCustomers}
        dataProducts={dataProducts}
        isLoadingCustomers={isLoadingCustomers}
        isLoadingProducts={isLoadingProducts}
      />
      <DataTablePerforma
        dataTable={dataTable}
        isLoadingTable={isLoadingTable}
        handlePage={handlePage}
        handleChangeFilter={handleChangeFilter}
        handleDownload={handleDownload}
        downloadForm={downloadForm}
        setDownloadForm={setDownloadForm}
      />
    </div>
  );
}
