export function changeLastAccessFiture(Fiture:any)
{
    return({
        type:"CHANGE_LAST_ACCESS_FITURE",
        Fiture
    })
}
export function changeClearLastAccessFiture()
{
    return({
        type:"CHANGE_CLEAR_LAST_ACCESS_FITURE"
    })
}
export function changeStep(Step:any)
{
    return({
        type:"CHANGE_STEP",
        Step
    })
}
export function changeClearStep()
{
    return({
        type:"CHANGE_CLEAR_STEP"
    })
}