import { Col, Form, Input, Row, Select } from 'antd';
import React, { Component } from 'react';

import TextArea from 'antd/lib/input/TextArea';
import { EnumCustomerAccess } from '../../../core/enumerable/General';
const { Option } = Select;
interface IProps {
  CustomerName: any;
  CustomerCode: any;
  CustomerType: any;
  CustomerTelephone: any;
  CustomerEmail: any;
  CustomerAddress: any;
  CustomerAccess: any;
}

export default class FormDetailCustomerComponent extends Component<IProps> {
  render() {
    return (
      <div>
        <Row>
          <Col span={12}>
            <div style={{ marginRight: 20 }}>
              <Form>
                <div className="padding-top-form-input">
                  <span>
                    <b>Nama</b>
                  </span>
                  <Input
                    style={{ background: '#F5F5F5', fontWeight: 'bold' }}
                    value={this.props.CustomerName}
                  ></Input>
                </div>

                <div className="padding-top-form-input">
                  <span>
                    <b>Kode</b>
                  </span>
                  <Input
                    style={{ background: '#F5F5F5', fontWeight: 'bold' }}
                    value={this.props.CustomerCode}
                  ></Input>
                </div>

                <div className="padding-top-form-input">
                  <span>
                    <b>Jenis</b>
                  </span>
                  <Input
                    style={{ background: '#F5F5F5', fontWeight: 'bold' }}
                    value={this.props.CustomerType}
                  ></Input>
                </div>
              </Form>
            </div>
          </Col>

          <Col span={12}>
            <div style={{ marginLeft: 20 }}>
              <Form>
                <div className="padding-top-form-input">
                  <span>
                    <b>No Telp</b>
                  </span>
                  <Input
                    addonBefore={'+62'}
                    style={{ background: '#F5F5F5', fontWeight: 'bold' }}
                    value={this.props.CustomerTelephone}
                  ></Input>
                </div>

                <div className="padding-top-form-input">
                  <span>
                    <b>Email </b>
                  </span>
                  <Input
                    style={{ background: '#F5F5F5', fontWeight: 'bold' }}
                    value={this.props.CustomerEmail}
                  ></Input>
                </div>
                <div className="padding-top-form-input">
                  <span>
                    <b>Akses Portal Pelanggan </b>
                  </span>
                  <Select
                    style={{ width: '100%', background: '#F5F5F5', fontWeight: 'bold' }}
                    disabled
                    showSearch
                    mode="multiple"
                    placeholder="Pilih akses portal pelanggan"
                    value={this.props.CustomerAccess}
                  >
                    {EnumCustomerAccess.map(
                      (data: any, index: any) => (
                        <Option key={index} value={data.value}>
                          {data.label}
                        </Option>
                      )
                    )}
                  </Select>
                </div>
                {/* <div className='padding-buttom-form'>
                        <span>
                            <b>Lainnya</b>
                        </span>
                        <Input style={{ background:'#F5F5F5', fontWeight:'bold'}}
                            value={this.props.getcustomertypeother}></Input>
                    </div> */}
              </Form>
            </div>
          </Col>
        </Row>
        <div className="padding-top-form-input">
          <span>
            <b>Alamat lengkap</b>
          </span>
          <TextArea
            style={{
              height: '80px',
              background: '#F5F5F5',
              fontWeight: 'bold',
            }}
            value={this.props.CustomerAddress}
          ></TextArea>
        </div>
      </div>
    );
  }
}