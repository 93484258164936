import * as ActionAuth from '../../action/Auth';
import * as ActionCustomerConfig from '../../action/Customer/ActionCustomerStatusConfig';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionTableStatusCustom from '../../../core/action/Customer/ActionTableStatusDefault';
import * as ActionToggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_submitEditCustomStatus(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const CustomerStatusPosition =
    state.Customer.FormCustomCustomerStatus.StatusPosition;
  const CustomerStatusCode = state.Customer.FormCustomCustomerStatus.StatusCode;
  const CustomerStatusDescription =
    state.Customer.FormCustomCustomerStatus.StatusDescription;
  const CustomerStatusLabel =
    state.Customer.FormCustomCustomerStatus.StatusLabel;
  const CustomerStatusAttempt =
    state.Customer.FormCustomCustomerStatus.StatusAttempt;
  const CustomerStatusFreeText =
    state.Customer.FormCustomCustomerStatus.StatusFreeText;
  const CustomerStatusYBS = state.Customer.FormCustomCustomerStatus.StatusYBS;
  const ReasonDeliveryStatus =
    state.Customer.PropertiesCustomer.ReasonDeliveryStatus;
  const IdCustomer = state.Customer.PropertiesCustomer.IdCustomer;
  const IdStatus = state.Customer.PropertiesCustomer.IdStatus;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(ActionToggle.changeLoadingTrue());

    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/Setting/CustomStatus/' + IdStatus,
      {
        id: IdStatus,
        code: CustomerStatusCode,
        description: CustomerStatusDescription,
        position: CustomerStatusPosition,
        status: ReasonDeliveryStatus,
        customerId: IdCustomer,
        attempt: CustomerStatusAttempt,
        label: CustomerStatusLabel,
        additionalText: CustomerStatusFreeText,
        ybs: CustomerStatusYBS,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionToggle.clearJenisToggle());
    yield put(ActionCustomerConfig.changeClearStatusConfigForm());
    yield put(ActionTableStatusCustom.clearCustomDataStatus());
    yield put(ActionToggle.changeLoadingFalse());
    yield put(ActionTableStatusCustom.getCustomDataStatus());

    Modal.success({
      title: <p style={{ color: '#31E116' }}>Berhasil mengubah</p>,

      onOk() {},
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_submitEditCustomStatus() {
  yield takeLatest('SUBMIT_EDIT_STATUS', S_submitEditCustomStatus);
}
