import 'styles/ModalStyle/ModalStyle.css';

import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from 'materials/ButtonMaterial';
import FormInputTextMaterial from 'materials/FormInputTextMaterial';
import GroupBranchFormContainer from 'module/Branch/Container/GroupBranchFormContainer';
import ModalMaterial from 'materials/ModalMaterial';
import SetMapsBranchContainer from 'module/Branch/Container/SetMapsBranchContainer';
import SpinMaterial from 'materials/SpinMaterial';

const { Option } = Select;
interface IProps {
  visible: any;
  typeToggle: any;
  isLoading: any;
  LoadingAddress: any;
  LoadingTypeAddress: any;
  modalLocationBranch: any;
  LoadingSelectAdmin: any;
  LoadingTypeSelectAdmin: any;
  getNameBranch: any;
  getAreaCode: any;
  getPhoneNumber: any;
  getAddress: any;
  getSelectAdminBranch: any;
  getAdminBranch: any;
  getProvince: any;
  getCity: any;
  getSubDistrict: any;
  getVillage: any;
  getPostalCode: any;
  getIdAdminBranch: any;
  getNameAdminBranch: any;
  getLatitudeBranch: any;
  getLongitudeBranch: any;
  getPartner: any;
  getGroupNameBranch: any;
  listProvince: any;
  listCity: any;
  listSubDistrict: any;
  listVillage: any;
  listAdminBranch: any;
  getListGroupName: any;
  ValidateNameBranch: any;
  ValidateAreaCode: null;
  ValidatePhoneNumber: null;
  ValidateAddress: null;
  ValidateProvince: any;
  ValidateCity: any;
  ValidateSubDistrict: any;
  ValidateVillage: any;
  ValidateAdminBranch: any;
  handleModalLocationBranch: any;
  handleCloseModal: any;
  handlePutNameBranch: any;
  handlePutAreaCode: any;
  handlePutPhoneNumber: any;
  handlePutAddress: any;
  handleGetProvince: any;
  handleGetCity: any;
  handleGetSubDistrict: any;
  handleGetVillage: any;
  handleGetAdminBranch: any;
  handlePutSelectAdmin: any;
  handlePutSelectPartner: any;
  handlePutProvince: any;
  handlePutCity: any;
  handlePutSubDistrict: any;
  handlePutVillage: any;
  handlePutAdminBranch: any;
  handleResetProvince: any;
  handleResetCity: any;
  handleResetSubDistrict: any;
  handleResetVillage: any;
  handleResetAdminBranch: any;
  handleBlurProvince: any;
  handleBlurCity: any;
  handleBlurSubDistrict: any;
  handleBlurVillage: any;
  handleBlurAdminBranch: any;
  handleAddBranch: any;
  handleEditBranch: any;
  handleModalGroup: any;
  handleGetGroupName: any;
  handlePutGroup: any;
}
export default class FormBranchComponent extends Component<IProps> {
  render() {
    const disablecity = this.props.getProvince === null ? true : false;
    const disablesubdistrict = this.props.getCity === null ? true : false;
    const disablevillage = this.props.getSubDistrict === null ? true : false;
    const disableAreaCode =
      this.props.typeToggle === 'EditBranch' ? true : false;

    const ValidateButtonSave =
      this.props.getNameBranch === null ||
      this.props.getNameBranch === '' ||
      this.props.getProvince === null ||
      this.props.getProvince === '' ||
      this.props.getCity === null ||
      this.props.getCity === '' ||
      this.props.getSubDistrict === null ||
      this.props.getSubDistrict === '' ||
      this.props.getVillage === null ||
      this.props.getVillage === '' ||
      this.props.getAreaCode === null ||
      this.props.getAreaCode === '' ||
      this.props.ValidateAreaCode === 'minchar' ||
      this.props.ValidateAreaCode === 'huruf' ||
      this.props.getPhoneNumber === null ||
      this.props.getPhoneNumber === '' ||
      this.props.ValidatePhoneNumber === 'nonumeric' ||
      this.props.getAddress === null ||
      this.props.getAddress === ''
        ? true
        : false;

    const ValidateButtonSave2 =
      this.props.getNameBranch === null ||
      this.props.getNameBranch === '' ||
      this.props.getProvince === null ||
      this.props.getProvince === '' ||
      this.props.getCity === null ||
      this.props.getCity === '' ||
      this.props.getSubDistrict === null ||
      this.props.getSubDistrict === '' ||
      this.props.getVillage === null ||
      this.props.getVillage === '' ||
      this.props.getAreaCode === null ||
      this.props.getAreaCode === '' ||
      this.props.ValidateAreaCode === 'minchar' ||
      this.props.ValidateAreaCode === 'huruf' ||
      this.props.getPhoneNumber === null ||
      this.props.getPhoneNumber === '' ||
      this.props.ValidatePhoneNumber === 'nonumeric' ||
      this.props.getAddress === null ||
      this.props.getAddress === ''
        ? true
        : false;

    const valuegetprovince =
      this.props.getProvince === null ? (
        <span style={{ color: '#969696' }}>Pilih provinsi</span>
      ) : (
        this.props.getProvince
      );
    const valuegetcity =
      this.props.getCity === null ? (
        <span style={{ color: '#969696' }}>Pilih kabupaten / kota</span>
      ) : (
        this.props.getCity
      );
    const valuegetsubdistrict =
      this.props.getSubDistrict === null ? (
        <span style={{ color: '#969696' }}>Pilih kecamatan</span>
      ) : (
        this.props.getSubDistrict
      );
    const valuegetvillage =
      this.props.getVillage === null ? (
        <span style={{ color: '#969696' }}>Pilih desa / kelurahan</span>
      ) : (
        this.props.getVillage
      );
    return (
      <div>
        <GroupBranchFormContainer />
        <ModalMaterial
          className={''}
          width={'60%'}
          style={{ paddingTop: '20px' }}
          teksTitle={
            this.props.typeToggle === 'EditBranch'
              ? 'UBAH POSKO'
              : 'TAMBAHKAN POSKO'
          }
          visible={
            (this.props.visible === true &&
              this.props.typeToggle === 'EditBranch') ||
            this.props.typeToggle === 'AddBranch'
              ? true
              : false
          }
          onCancel={this.props.handleCloseModal}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div style={{ paddingTop: '20px' }}>
                  <Row>
                    <Col span={12}>
                      <div style={{ marginRight: 20, marginLeft: 30 }}>
                        <span>
                          <b>Nama Posko</b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          className={''}
                          onPressEnter={''}
                          disabled={false}
                          addonBefore={''}
                          placeholder={'Masukan nama posko'}
                          prefix={''}
                          value={this.props.getNameBranch}
                          maxLength={''}
                          style={{}}
                          size=""
                          type={''}
                          onChange={this.props.handlePutNameBranch}
                          onBlur={this.props.handlePutNameBranch}
                          validatestatus={
                            this.props.ValidateNameBranch === 'error'
                              ? 'error'
                              : ''
                          }
                          help={
                            this.props.ValidateNameBranch === 'error'
                              ? 'Nama posko tidak boleh kosong!!'
                              : ''
                          }
                        />

                        <span>
                          <b>Provinsi</b>
                        </span>
                        <Form.Item
                          validateStatus={
                            this.props.ValidateProvince === 'error'
                              ? 'error'
                              : ''
                          }
                          help={
                            this.props.ValidateProvince === 'error'
                              ? 'Provinsi tidak boleh kosong!!'
                              : ''
                          }
                        >
                          <Select
                            loading={
                              this.props.LoadingAddress === true &&
                              this.props.LoadingTypeAddress === 'selectProvince'
                                ? true
                                : false
                            }
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            showSearch
                            value={valuegetprovince}
                            onChange={this.props.handlePutProvince}
                            onFocus={this.props.handleGetProvince}
                            onSelect={this.props.handleResetProvince}
                            onBlur={this.props.handleBlurProvince}
                          >
                            {this.props.listProvince.map(
                              (data: any, index: any) => (
                                <Option key={index} value={data.id}>
                                  {data.name}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>

                        <span>
                          <b>Kabupaten / Kota</b>
                        </span>
                        <Form.Item
                          validateStatus={
                            this.props.ValidateCity === 'error' ? 'error' : ''
                          }
                          help={
                            this.props.ValidateCity === 'error'
                              ? 'Kabupaten/kota tidak boleh kosong!!'
                              : ''
                          }
                        >
                          <Select
                            loading={
                              this.props.LoadingAddress === true &&
                              this.props.LoadingTypeAddress === 'selectCity'
                                ? true
                                : false
                            }
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            disabled={disablecity}
                            showSearch
                            value={valuegetcity}
                            onChange={this.props.handlePutCity}
                            onFocus={this.props.handleGetCity}
                            onSelect={this.props.handleResetCity}
                            onBlur={this.props.handleBlurCity}
                          >
                            {this.props.listCity.map(
                              (data: any, index: any) => (
                                <Option key={index} value={data.id}>
                                  {data.name}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>

                        <span>
                          <b>Kecamatan</b>
                        </span>
                        <Form.Item
                          validateStatus={
                            this.props.ValidateSubDistrict === 'error'
                              ? 'error'
                              : ''
                          }
                          help={
                            this.props.ValidateSubDistrict === 'error'
                              ? 'Kecamatan tidak boleh kosong!!'
                              : ''
                          }
                        >
                          <Select
                            loading={
                              this.props.LoadingAddress === true &&
                              this.props.LoadingTypeAddress ===
                                'selectSubDistrict'
                                ? true
                                : false
                            }
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            disabled={disablesubdistrict}
                            showSearch
                            value={valuegetsubdistrict}
                            onChange={this.props.handlePutSubDistrict}
                            onFocus={this.props.handleGetSubDistrict}
                            onSelect={this.props.handleResetSubDistrict}
                            onBlur={this.props.handleBlurSubDistrict}
                          >
                            {this.props.listSubDistrict.map(
                              (data: any, index: any) => (
                                <Option key={index} value={data.id}>
                                  {data.name}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>

                        <span>
                          <b>Desa / Kelurahan</b>
                        </span>
                        <Form.Item
                          validateStatus={
                            this.props.ValidateVillage === 'error'
                              ? 'error'
                              : ''
                          }
                          help={
                            this.props.ValidateVillage === 'error'
                              ? 'Desa/kelurahan tidak boleh kosong!!'
                              : ''
                          }
                        >
                          <Select
                            loading={
                              this.props.LoadingAddress === true &&
                              this.props.LoadingTypeAddress === 'selectVillage'
                                ? true
                                : false
                            }
                            style={{ width: '100%' }}
                            disabled={disablevillage}
                            // onSelect={this.props.handlegetpostalcode}
                            optionFilterProp="children"
                            showSearch
                            value={valuegetvillage}
                            onChange={this.props.handlePutVillage}
                            onFocus={this.props.handleGetVillage}
                            onSelect={this.props.handleResetVillage}
                            onBlur={this.props.handleBlurVillage}
                          >
                            {this.props.listVillage.map(
                              (data: any, index: any) => (
                                <Option key={index} value={data.id}>
                                  {data.name}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                        <span>
                          <b>Rekanan</b>
                        </span>
                        <div
                          style={{ paddingTop: '9px', paddingBottom: '20px' }}
                        >
                          <Checkbox
                            checked={this.props.getPartner}
                            onChange={this.props.handlePutSelectPartner}
                          />
                          <span style={{ paddingLeft: '10px' }}>
                            Pilih rekanan
                          </span>
                        </div>
                        <div>
                          <span>
                            <b>Grup Posko</b>
                            <Button
                              onClick={this.props.handleModalGroup}
                              type="link"
                              disabled={false}
                              className={'linkGroup'}
                            >
                              <u>Tambah grup baru</u>
                            </Button>
                          </span>
                          <Form.Item>
                            <Select
                              // loading={this.props.loadingSelectCustomer}
                              style={{ width: '100%' }}
                              optionFilterProp="children"
                              showSearch
                              placeholder={'Pilih Group'}
                              onChange={this.props.handlePutGroup}
                              value={
                                this.props.getGroupNameBranch === null ? (
                                  <span className="colorPlaceholderDropDown">
                                    Pilih Group
                                  </span>
                                ) : (
                                  this.props.getGroupNameBranch
                                )
                              }
                              onFocus={this.props.handleGetGroupName}
                            >
                              <Option key={0} value={0}>
                                {'-'}
                              </Option>
                              {this.props.getListGroupName.map(
                                (data: any, index: any) => (
                                  <Option key={index} value={data.id}>
                                    {data.groupingName}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div style={{ marginRight: 30, marginLeft: 20 }}>
                        <span>
                          <b>Kode Wilayah</b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          className={''}
                          disabled={disableAreaCode}
                          addonBefore={''}
                          placeholder={'Masukan kode wilayah'}
                          prefix={''}
                          value={this.props.getAreaCode}
                          maxLength={3}
                          style={{ textTransform: 'uppercase' }}
                          size=""
                          type={''}
                          onPressEnter={''}
                          onChange={this.props.handlePutAreaCode}
                          onBlur={this.props.handlePutAreaCode}
                          validatestatus={
                            this.props.ValidateAreaCode === 'error' ||
                            this.props.ValidateAreaCode === 'minchar' ||
                            this.props.ValidateAreaCode === 'huruf'
                              ? 'error'
                              : ''
                          }
                          help={
                            this.props.ValidateAreaCode === 'error'
                              ? 'Kode wilayah tidak boleh kosong!!'
                              : '' || this.props.ValidateAreaCode === 'minchar'
                              ? 'Kurang dari 3 karakter huruf!!'
                              : '' || this.props.ValidateAreaCode === 'huruf'
                              ? 'Tidak boleh selain huruf'
                              : ''
                          }
                        />

                        <span>
                          <b>No Telp</b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          className={''}
                          disabled={false}
                          addonBefore={'+62'}
                          placeholder={'Masukan nomor telpon'}
                          prefix={''}
                          onPressEnter={''}
                          value={this.props.getPhoneNumber}
                          maxLength={12}
                          style={{}}
                          size=""
                          type={''}
                          onChange={this.props.handlePutPhoneNumber}
                          onBlur={this.props.handlePutPhoneNumber}
                          validatestatus={
                            this.props.ValidatePhoneNumber === 'error'
                              ? 'error'
                              : '' ||
                                this.props.ValidatePhoneNumber === 'nonumeric'
                              ? 'error'
                              : ''
                          }
                          help={
                            this.props.ValidatePhoneNumber === 'error'
                              ? 'NoTelp tidak boleh kosong!!'
                              : '' ||
                                this.props.ValidatePhoneNumber === 'nonumeric'
                              ? 'Tidak boleh selain angka!!'
                              : ''
                          }
                        />

                        <span>
                          <b>Kode pos</b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          className={''}
                          disabled={true}
                          addonBefore={''}
                          onPressEnter={''}
                          placeholder={'Pilih desa/kel untuk dapatkan kode pos'}
                          prefix={''}
                          value={this.props.getPostalCode}
                          maxLength={''}
                          style={{}}
                          size=""
                          type={''}
                          onChange={''}
                          onBlur={''}
                          validatestatus={''}
                          help={''}
                        />

                        <div>
                          <span>
                            <b>Lokasi Posko</b>
                          </span>
                          <div
                            style={{ paddingTop: '9px', paddingBottom: '20px' }}
                          >
                            <Button
                              type="link"
                              onClick={this.props.handleModalLocationBranch}
                              style={{
                                fontStyle: 'italic',
                                textDecoration: 'underline',
                                paddingLeft: 0,
                              }}
                            >
                              {this.props.getLatitudeBranch === null ||
                              this.props.getLongitudeBranch === null
                                ? 'Klik untuk mendapatkan koordinat lokasi'
                                : `${this.props.getLatitudeBranch}, ${this.props.getLongitudeBranch}`}
                            </Button>
                          </div>
                        </div>

                        <div>
                          <span>
                            <b>Alamat lengkap</b>
                          </span>
                          <Form.Item
                            validateStatus={
                              this.props.ValidateAddress === 'error'
                                ? 'error'
                                : ''
                            }
                            help={
                              this.props.ValidateAddress === 'error'
                                ? 'Alamat tidak boleh kosong!!'
                                : ''
                            }
                          >
                            <Input.TextArea
                              name="alamat"
                              style={{ height: '100px' }}
                              placeholder={'Masukan alamat lengkap'}
                              value={this.props.getAddress}
                              onBlur={this.props.handlePutAddress}
                              onChange={this.props.handlePutAddress}
                            ></Input.TextArea>
                          </Form.Item>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div
                    style={{
                      textAlign: 'right',
                      marginLeft: '30px',
                      marginRight: '30px',
                    }}
                  >
                    <ButtonMaterial
                      disabled={
                        this.props.getPartner === true
                          ? ValidateButtonSave2
                          : ValidateButtonSave
                      }
                      handleSubmit={
                        this.props.typeToggle === 'EditBranch'
                          ? this.props.handleEditBranch
                          : this.props.handleAddBranch
                      }
                      teksButton={'Simpan'}
                      size={''}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                </div>
              }
            ></SpinMaterial>
          }
        ></ModalMaterial>
        <SetMapsBranchContainer
          modalLocationBranch={this.props.modalLocationBranch}
          handleModalLocationBranch={this.props.handleModalLocationBranch}
        />
      </div>
    );
  }
}
