import * as ActionAssigningCourier from 'core/action/AssigningCourier/ActionAssigningCourier';
import * as ActionListBranch from 'core/action/Branch/ActionAllBranch';
import * as ActionListCourier from 'core/action/ManageAccount/Courier/ActionTableCourier';
import * as ActionLogin from 'module/Login/Store';
import * as ActionToggle from 'core/action/toggle/index';

import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import AssigningCourierComponent from 'module/AssigningCourier/Component/AssigningCourierComponent';
import { connect } from 'react-redux';

interface IProps {
  IdBranch: any;
  branchId: any;
  DetailCourierCode: any;
  courierId: any;
  CheckBox: any;
  WorB: any;
  AssignStatus: any;
  DetailWorB: any;
  ListCourier: any;
  ListBranch: any;
  actionToggle: any;
  roleAccount: any;
  isloading: any;
  changeSearch: any;
  actionListCourier: any;
  actionAssigningCourier: any;
  actionListBranch: any;
  actionLogin: any;
}
function AssigningCourierContainer({
  IdBranch,
  branchId,
  DetailCourierCode,
  courierId,
  CheckBox,
  WorB,
  AssignStatus,
  DetailWorB,
  ListCourier,
  ListBranch,
  roleAccount,
  isloading,
  actionListCourier,
  actionAssigningCourier,
  actionListBranch,
}: IProps) {
  const [ValidateCourier, setValidateCourier]: any = useState(null);
  const [ValidateWorB, setValidateWorB]: any = useState(null);

  useEffect(() => {
    actionListBranch.getAllBranch();
    actionAssigningCourier.changeBranchId(IdBranch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSubmit() {
    actionAssigningCourier.submitAssigningCourierWorB();
    actionAssigningCourier.ClearWorBAc();
  }
  function handleReset() {
    if (roleAccount === 'Branch') {
      actionAssigningCourier.ResetFormAcBranch();
    } else {
      actionAssigningCourier.ResetFormAc();
    }
    setValidateCourier(null);
    setValidateWorB(null);
  }
  function handlePutCourier(event: any, label: any) {
    actionAssigningCourier.changeCourierId(event);
    actionAssigningCourier.changeCourierCode(label.props.label);
    if (event === null) {
      setValidateCourier('error');
    } else {
      setValidateCourier(null);
    }
  }
  function handleBlurCourier() {
    if (courierId === null) {
      setValidateCourier('error');
    } else {
      setValidateCourier(null);
    }
  }
  function handlePutWorB(event: any) {
    actionAssigningCourier.changeWorB(event.target.value);

    if (event.target.value === '' || event.target.value === null) {
      setValidateWorB('error');
    } else {
      setValidateWorB('');
    }
  }
  function handlePutCheckbox(event: any) {
    actionAssigningCourier.changeCheckBox(event.target.checked);
  }
  function handleGetCourier() {
    actionListCourier.getCourierTableBranch();
  }

  return (
    <div>
      <AssigningCourierComponent
        isLoading={isloading}
        ListBranch={ListBranch}
        IdBranch={IdBranch}
        branchId={branchId}
        DetailCourierCode={DetailCourierCode}
        ListCourier={ListCourier}
        handlePutCourier={handlePutCourier}
        handleBlurCourier={handleBlurCourier}
        handlePutCheckbox={handlePutCheckbox}
        Courier={
          courierId === null || courierId === undefined || courierId === 0 ? (
            <span style={{ color: '#969696' }}>Pilih Kurir</span>
          ) : (
            courierId
          )
        }
        ValidateCourier={ValidateCourier === 'error' ? 'error' : ''}
        helpValidateCourier={
          ValidateCourier === 'error' ? 'Kurir wajib diisi' : ''
        }
        handlePutWorB={handlePutWorB}
        ValidateWorB={
          ValidateWorB === 'error' || ValidateWorB === 'notMinChar'
            ? 'error'
            : ''
        }
        helpValidateBorW={
          ValidateWorB === 'error'
            ? 'Barcode / Waybill tidak boleh kosong'
            : ''
        }
        handleGetCourier={handleGetCourier}
        CheckBox={CheckBox}
        WorB={WorB}
        AssignStatus={AssignStatus}
        DetailWorB={DetailWorB}
        validateSubmit={
          courierId === null ||
          courierId === undefined ||
          ValidateWorB === 'error' ||
          ValidateWorB === 'notMinChar' ||
          WorB === null
            ? ''
            : handleSubmit
        }
        validateButton={
          courierId === null ||
          courierId === undefined ||
          ValidateWorB === 'error' ||
          ValidateWorB === 'notMinChar' ||
          WorB === null
            ? true
            : false
        }
        validateReset={courierId !== null || WorB !== null ? false : true}
        handleSubmit={handleSubmit}
        handleReset={handleReset}
      />
    </div>
  );
}
const mapStateProps = (state: any) => ({
  IdBranch: state.Auth.BranchAuth,
  roleAccount: state.Auth.Role,
  ListCourier: state.Table.TableCourier,
  ListBranch: state.Branch.AllBranch,
  branchId: state.AssigningCourier.branchId,
  courierId: state.AssigningCourier.courierId,
  WorB: state.AssigningCourier.WorB,
  CheckBox: state.AssigningCourier.CheckBox,
  AssignStatus: state.AssigningCourier.PropertiesAssignCourier.AssignStatus,
  DetailWorB: state.AssigningCourier.PropertiesAssignCourier.DetailWorB,
  DetailCourierCode:
    state.AssigningCourier.PropertiesAssignCourier.DetailCourierCode,
  isloading: state.Toggle.isLoading,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionListCourier: bindActionCreators(ActionListCourier, dispatch),
  actionAssigningCourier: bindActionCreators(ActionAssigningCourier, dispatch),
  actionListBranch: bindActionCreators(ActionListBranch, dispatch),
  actionLogin: bindActionCreators(ActionLogin, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(AssigningCourierContainer);
