export function getExportDeliveryStatus() {
  return {
    type: 'GET_EXPORT_DELIVERY_STATUS',
  };
}
export function getExportDeliveryStatusKurir() {
  return {
    type: 'GET_EXPORT_DELIVERY_STATUS_KURIR',
  };
}
