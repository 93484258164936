import { createSelector } from 'reselect';

const selectorPickUpProcess = (state: any) => state.PickUpDataState;

export const makeCourierPickUpDataListSelector = () =>
  createSelector(selectorPickUpProcess, state => state.courierList);
export const makeCustomerProductPickUpDataListSelector = () =>
  createSelector(selectorPickUpProcess, state => state.customerProductList);
export const makeCountingDataStatusSelector = () =>
  createSelector(selectorPickUpProcess, state => state.countingDataStatus);
export const makePickupDataListSelector = () =>
  createSelector(selectorPickUpProcess, state => state.pickupDataList);
