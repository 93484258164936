import { Action } from 'redux';

export interface IFetchLevelAction extends Action {
  data: object;
}
export function fetchLevelListData(data: object): IFetchLevelAction {
  return {
    type: 'FETCH_LEVEL_LIST_DATA',
    data,
  };
}
export function fetchModuleListData(data: object): IFetchLevelAction {
  return {
    type: 'FETCH_MODULE_LIST_DATA',
    data,
  };
}
export function getLevelDataList() {
  return {
    type: 'GET_LEVEL_DATA_LIST_REQUESTED',
  };
}
export function getModuleList() {
  return {
    type: 'GET_MODULE_LIST_REQUESTED',
  };
}
export function submitLevel() {
  return {
    type: 'SUBMIT_LEVEL_REQUESTED',
  };
}
export function clearLevelListData() {
  return {
    type: 'CLEAR_LEVEL_LIST_DATA',
  };
}
export function clearModuleListData() {
  return {
    type: 'CLEAR_MODULE_LIST_DATA',
  };
}
