import { Button, Checkbox, Col, Form, Icon, Row, Select, Tooltip } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../materials/ButtonMaterial';
import CardMaterial from '../../../materials/CardMaterial';
import FormInputTextMaterial from '../../../materials/FormInputTextMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';

const { Option } = Select;
interface IProps {
  ReverseBarcode: any;
  StatusDefault: any;
  FileSettingName: any;
  DelimitedCsv: any;
  CycleCsv: any;
  CityCsv: any;
  AddressCsv: any;
  ListAddressCsv: any;
  ListAddressOffice: any;
  BarcodeCsv: any;
  OwnerNameCsv: any;
  PostalCodeCsv: any;
  NoTelpCsv: any;
  CallLinkCsv: any;
  AdditionalDataCsv: any;
  ListAdditionalDataCsv: any;
  TypeFormCsv: any;
  GroupingNameCsv: any;
  CustomerNameCsv: any;
  SearchTypeCsv: any;
  SearchKeyCsv: any;
  FormatCycleCsv: any;
  Waybill: any;
  AddressUpdate: any;
  ChekboxCallCenter: any;
  CheckboxCycle: any;
  FileType: any;
  handleCheckboxCycle: any;
  handleChangeDelimitedCsv: any;
  handleChangeFileSettingName: any;
  handleStatusDefaultCsv: any;
  handleChangeCycleCsv: any;
  handleChangeCityCsv: any;
  handleChangeAddress: any;
  handleChangeAddressOffice: any;
  handleChangeBarcodeCsv: any;
  handleChangeOwnerNameCsv: any;
  handleChangePostalCodeCsv: any;
  handleChangeNoTelpCsv: any;
  handleChangeCallLinkCsv: any;
  handleChangeAdditionalDataCsv: any;
  handleChangeGroupNameCsv: any;
  handleChangeFormatCycleCsv: any;
  handleChangeWaybillCsv: any;
  handleChangeCheckboxCallCenter: any;
  handleBlurDelimitedCsv: any;
  handleAddAddress: any;
  handleAddAddressOffice;
  handleRemoveAddressCsv: any;
  handleRemoveAddressOffice: any;
  handleRemoveAdditionalDataCsv: any;
  handleAddAdditionalData: any;
  handleEditCsv: any;
  handleSubmiteAddressCsv: any;
  handleBack: any;
  handleCancel: any;
  handleReverseBarcodeCsv: any;
  handleChangeSearchKeyCsv: any;
  handleChangeSearchTypeCsv: any;
  handleChangeAddressUpdateCsv: any;
  handleBlurFileType: any;
  handleChangeFileType: any;
  ValidateDelimitedCsv: any;
  ValidateCycleCsv: any;
  ValidateFileSettingNameCsv: any;
  ValidateCityCsv: any;
  ValidateAddressCsv: any;
  ValidateBarcodeCsv: any;
  ValidateOwnerNameCsv: any;
  ValidatePostalCodeCsv: any;
  ValidateNoTelpCsv: any;
  ValidateGroupingDelivery: any;
  ValidateFormatCycleCsv: any;
  ValidateWaybill: any;
  ValidateAddressUpdate: any;
  ValidateFileType: any;
}
export default class FormCsvCustomerComponent extends Component<IProps> {
  render() {
    const valueDelimited =
      this.props.DelimitedCsv === 'SemiColon'
        ? '; (Titi koma)'
        : this.props.DelimitedCsv === 'Comma'
        ? ', (Koma)'
        : this.props.DelimitedCsv === 'Empty'
        ? ''
        : '| (Separator)';
    const valueSearchTypeCsv =
      this.props.SearchTypeCsv === 'NomerReferensi'
        ? 'Nomer Referensi'
        : this.props.SearchTypeCsv === 'NomerPolis'
        ? 'Nomer Polis'
        : this.props.SearchTypeCsv === 'NomerRekening'
        ? 'Nomer Rekening'
        : '';

    const valueFileType =
      this.props.FileType === 'CSV'
        ? 'CSV (.csv)'
        : this.props.FileType === 'Excel'
        ? 'Excel (.xls)'
        : this.props.FileType === 'ExcelXLSX'
        ? 'Excel (.xlsx)'
        : 'Teks (.txt)';

    const validateBtn = () => {
      const coreValidate =
        !this.props.FileType === true ||
        !this.props.FileSettingName === true ||
        !this.props.BarcodeCsv === true ||
        !this.props.OwnerNameCsv === true ||
        !this.props.AddressCsv === true ||
        this.props.ListAddressCsv.length === 0 ||
        !this.props.AdditionalDataCsv === true ||
        this.props.ListAdditionalDataCsv.length === 0
          ? true
          : false;

      // delimited & cycle kondisional
      const conditionalValidate =
        !this.props.DelimitedCsv === true || !this.props.CycleCsv === true
          ? true
          : false;

      if (
        (this.props.FileType === 'TXT' ||
          this.props.FileType === 'CSV' ||
          this.props.FileType === null ||
          this.props.FileType === '') &&
        this.props.CheckboxCycle === false
      ) {
        //semua yg required wajib diisi, file type bukan excel (harus isi delimited), checkbox false
        return coreValidate === true || conditionalValidate === true
          ? true
          : false;
      } else if (
        (this.props.FileType === 'TXT' || this.props.FileType === 'CSV') &&
        this.props.CheckboxCycle === true
      ) {
        //file type bukan excel (harus isi delimited), checkbox true
        return coreValidate === true || !this.props.DelimitedCsv === true
          ? true
          : false;
      } else if (
        (this.props.FileType === 'Excel' ||
          this.props.FileType === 'ExcelXLSX') &&
        this.props.CheckboxCycle === false
      ) {
        //file type excel (tidak perlu isi delimited), checkbox false
        return coreValidate === true || !this.props.CycleCsv === true
          ? true
          : false;
      } else {
        // file type excel (tidak perlu isi delimited), checkbox true
        return coreValidate;
      }
    };
    const validateDelimited =
      this.props.FileType === null ||
      this.props.FileType === 'Excel' ||
      this.props.FileType === 'ExcelXLSX'
        ? true
        : false;

    let checkboxValue = false;

    const validateCheckbox = () => {
      if (this.props.TypeFormCsv === 'detail') {
        if (this.props.CycleCsv === null) {
          return (checkboxValue = true);
        } else {
          return (checkboxValue = false);
        }
      } else if (this.props.TypeFormCsv === 'edit') {
        if (this.props.CycleCsv === null) {
          return (checkboxValue = true);
        } else if (this.props.CycleCsv !== null) {
          return (checkboxValue = this.props.CheckboxCycle);
        }
      } else if (this.props.TypeFormCsv === 'add') {
        return (checkboxValue = this.props.CheckboxCycle);
      }
      return null;
    };

    return (
      <div>
        {validateCheckbox()}
        <CardMaterial
          extra={
            <Row>
              <Col span={12}></Col>
              <Col span={12}>
                <div className="floatRight">
                  <ButtonMaterial
                    disabled={false}
                    handleSubmit={this.props.handleBack}
                    teksButton={<b>Kembali</b>}
                    size={'small'}
                    shape={'round'}
                    className={'btnBackCustomCsv'}
                    type={'primary'}
                    icon={''}
                    style={{
                      height: '30px',
                    }}
                  />
                </div>
              </Col>
            </Row>
          }
          title={
            <span className="lightBlueColor">
              <b>{this.props.CustomerNameCsv}</b>
            </span>
          }
          style={{ borderRadius: '10px' }}
          content={
            <SpinMaterial
              spinning={false}
              style={{}}
              size={'large'}
              content={
                <div className="spacingTopDefault">
                  <Row>
                    <Col span={12}>
                      <div className="spacingColForm">
                        <div>
                          <div>
                            <span>
                              <b>
                                Nama Pengaturan File
                                <span style={{ color: 'red' }}>*</span>
                              </b>
                            </span>
                            <FormInputTextMaterial
                              validateType={true}
                              className={
                                this.props.TypeFormCsv === 'detail' &&
                                'fieldStyleDetailCustomCsv'
                              }
                              disabled={false}
                              placeholder={'Contoh : Format RDS versi 1'}
                              value={this.props.FileSettingName}
                              maxLength={20}
                              type={''}
                              onChange={
                                this.props.TypeFormCsv === 'detail'
                                  ? ''
                                  : this.props.handleChangeFileSettingName
                              }
                              onBlur={
                                this.props.TypeFormCsv === 'detail'
                                  ? ''
                                  : this.props.handleChangeFileSettingName
                              }
                              validatestatus={
                                this.props.ValidateFileSettingNameCsv ===
                                'error'
                                  ? 'error'
                                  : ''
                              }
                              help={
                                this.props.ValidateFileSettingNameCsv ===
                                'error'
                                  ? 'Nama wajib diisi'
                                  : ''
                              }
                            />
                          </div>
                          <span>
                            <b>
                              Tipe File
                              <span className="dotCustomCsv">*</span>
                            </b>
                          </span>
                          {this.props.TypeFormCsv === 'detail' ? (
                            <FormInputTextMaterial
                              validateType={true}
                              className={
                                this.props.TypeFormCsv === 'detail' &&
                                'fieldStyleDetailCustomCsv'
                              }
                              disabled={false}
                              placeholder={'Pilih Tipe FIle'}
                              value={valueFileType}
                            />
                          ) : (
                            <Form.Item
                              validateStatus={
                                this.props.ValidateFileType === 'error'
                                  ? 'error'
                                  : ''
                              }
                              help={
                                this.props.ValidateFileType === 'error'
                                  ? 'Tipe File wajib diisi'
                                  : ''
                              }
                            >
                              <Select
                                className="widthFull"
                                onSelect={this.props.handleChangeFileType}
                                optionFilterProp="children"
                                showSearch
                                value={
                                  this.props.FileType === null ? (
                                    <span className="colorPlaceholderDropDown">
                                      Pilih Tipe File
                                    </span>
                                  ) : (
                                    this.props.FileType
                                  )
                                }
                                onChange={this.props.handleChangeFileType}
                                onBlur={this.props.handleBlurFileType}
                              >
                                <Option value="CSV">CSV (.csv)</Option>
                                <Option value="TXT">Teks (.txt)</Option>
                                <Option value="Excel">Excel (.xls)</Option>
                                <Option value="ExcelXLSX">Excel (.xlsx)</Option>
                              </Select>
                            </Form.Item>
                          )}
                          <span>
                            <b>
                              Delimited{' '}
                              {validateDelimited === true ||
                              this.props.FileType === null ? (
                                ''
                              ) : (
                                <span className="dotCustomCsv">*</span>
                              )}
                            </b>
                          </span>
                          {this.props.TypeFormCsv === 'detail' ? (
                            <FormInputTextMaterial
                              validateType={true}
                              className={
                                this.props.TypeFormCsv === 'detail' &&
                                'fieldStyleDetailCustomCsv'
                              }
                              disabled={false}
                              placeholder={'Pilih Delimited'}
                              value={valueDelimited}
                            />
                          ) : (
                            <Form.Item
                              validateStatus={
                                this.props.ValidateDelimitedCsv === 'error'
                                  ? 'error'
                                  : ''
                              }
                              help={
                                this.props.ValidateDelimitedCsv === 'error'
                                  ? 'Delimited wajib diisi'
                                  : ''
                              }
                            >
                              <Select
                                className="widthFull"
                                onSelect={this.props.handleChangeDelimitedCsv}
                                optionFilterProp="children"
                                showSearch
                                disabled={validateDelimited}
                                value={
                                  this.props.DelimitedCsv === null ? (
                                    <span className="colorPlaceholderDropDown">
                                      Pilih Delimited
                                    </span>
                                  ) : (
                                    this.props.DelimitedCsv
                                  )
                                }
                                onChange={this.props.handleChangeDelimitedCsv}
                                onBlur={this.props.handleBlurDelimitedCsv}
                              >
                                <Option value="Comma">, (Koma)</Option>
                                <Option value="SemiColon">
                                  ; (Titik Koma)
                                </Option>
                                <Option value="SeparateList">
                                  | (Separator)
                                </Option>
                              </Select>
                            </Form.Item>
                          )}
                          <div>
                            <span>
                              <b>
                                Barcode<span className="dotCustomCsv">*</span>
                              </b>
                            </span>
                            <Row>
                              <Col span={22}>
                                <FormInputTextMaterial
                                  validateType={true}
                                  className={
                                    this.props.TypeFormCsv === 'detail' &&
                                    'fieldStyleDetailCustomCsv'
                                  }
                                  disabled={false}
                                  addonBefore={''}
                                  onPressEnter={''}
                                  placeholder={
                                    'Contoh : Nomor Referensi / Barcode'
                                  }
                                  prefix={''}
                                  value={this.props.BarcodeCsv}
                                  maxLength={30}
                                  style={{}}
                                  size=""
                                  type={''}
                                  onChange={
                                    this.props.TypeFormCsv === 'detail'
                                      ? ''
                                      : this.props.handleChangeBarcodeCsv
                                  }
                                  onBlur={
                                    this.props.TypeFormCsv === 'detail'
                                      ? ''
                                      : this.props.handleChangeBarcodeCsv
                                  }
                                  validatestatus={
                                    this.props.ValidateBarcodeCsv === 'error'
                                      ? 'error'
                                      : ''
                                  }
                                  help={
                                    this.props.ValidateBarcodeCsv === 'error'
                                      ? 'Barcode wajib diisi'
                                      : ''
                                  }
                                />
                              </Col>
                              <Tooltip
                                placement="top"
                                title={
                                  'Putar balik kolom barcode, jika diperlukan'
                                }
                              >
                                <Col span={2}>
                                  <Checkbox
                                    className="checkboxCustomCsv"
                                    disabled={
                                      this.props.TypeFormCsv === 'detail'
                                        ? true
                                        : false
                                    }
                                    checked={this.props.ReverseBarcode}
                                    onChange={
                                      this.props.handleReverseBarcodeCsv
                                    }
                                  />
                                </Col>
                              </Tooltip>
                            </Row>
                          </div>
                          <div>
                            <span>
                              <b>
                                Nama Pemilik
                                <span className="dotCustomCsv">*</span>
                              </b>
                            </span>
                            <FormInputTextMaterial
                              validateType={true}
                              className={
                                this.props.TypeFormCsv === 'detail' &&
                                'fieldStyleDetailCustomCsv'
                              }
                              disabled={false}
                              addonBefore={''}
                              onPressEnter={''}
                              placeholder={'Contoh :  owner_name'}
                              prefix={''}
                              value={this.props.OwnerNameCsv}
                              maxLength={30}
                              style={{}}
                              size=""
                              type={''}
                              onChange={
                                this.props.TypeFormCsv === 'detail'
                                  ? ''
                                  : this.props.handleChangeOwnerNameCsv
                              }
                              onBlur={
                                this.props.TypeFormCsv === 'detail'
                                  ? ''
                                  : this.props.handleChangeOwnerNameCsv
                              }
                              validatestatus={
                                this.props.ValidateOwnerNameCsv === 'error'
                                  ? 'error'
                                  : ''
                              }
                              help={
                                this.props.ValidateOwnerNameCsv === 'error'
                                  ? 'Nama pemilik wajib diisi'
                                  : ''
                              }
                            />
                          </div>
                          <div>
                            <span>
                              <b>
                                Cycle
                                {this.props.CheckboxCycle === true ? (
                                  ''
                                ) : (
                                  <span className="dotCustomCsv">*</span>
                                )}
                              </b>
                            </span>
                            <Row>
                              <Col span={22}>
                                <FormInputTextMaterial
                                  validateType={true}
                                  className={
                                    this.props.TypeFormCsv === 'detail' &&
                                    'fieldStyleDetailCustomCsv'
                                  }
                                  disabled={
                                    this.props.CheckboxCycle === true
                                      ? true
                                      : false
                                  }
                                  placeholder={'Contoh : cycle_date'}
                                  value={this.props.CycleCsv}
                                  maxLength={30}
                                  style={{}}
                                  onChange={
                                    this.props.TypeFormCsv === 'detail'
                                      ? ''
                                      : this.props.handleChangeCycleCsv
                                  }
                                  onBlur={
                                    this.props.TypeFormCsv === 'detail'
                                      ? ''
                                      : this.props.handleChangeCycleCsv
                                  }
                                  validatestatus={
                                    this.props.ValidateCycleCsv === 'error'
                                      ? 'error'
                                      : ''
                                  }
                                  help={
                                    this.props.ValidateCycleCsv === 'error'
                                      ? 'Cycle wajib diisi'
                                      : ''
                                  }
                                />
                              </Col>
                              <Tooltip
                                placement="top"
                                title={'Ceklist untuk generate cycle otomatis'}
                              >
                                <Col span={2}>
                                  <Checkbox
                                    className="checkboxCustomCsv"
                                    disabled={
                                      this.props.TypeFormCsv === 'detail'
                                        ? true
                                        : false
                                    }
                                    checked={checkboxValue}
                                    onChange={this.props.handleCheckboxCycle}
                                  />
                                </Col>
                              </Tooltip>
                            </Row>
                          </div>
                        </div>

                        <div>
                          <span>
                            <b className="paddingRightCustomCsv">
                              Format Cycle
                            </b>
                            <Tooltip
                              title={
                                <div>
                                  <div>
                                    <span>Contoh :</span>
                                  </div>
                                  <div>
                                    <span>dd/MM/yyyy</span>
                                  </div>
                                  <div>
                                    <span>MM/dd/yyyy</span>
                                  </div>
                                </div>
                              }
                            >
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.FormatCycleCsv}
                            className={
                              this.props.TypeFormCsv === 'detail' &&
                              'fieldStyleDetailCustomCsv'
                            }
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Contoh : dd/MM/yyyy'}
                            prefix={''}
                            maxLength={30}
                            style={{}}
                            size=""
                            type={''}
                            onChange={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangeFormatCycleCsv
                            }
                            onBlur={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangeFormatCycleCsv
                            }
                            validatestatus={this.props.ValidateFormatCycleCsv}
                            help={
                              this.props.ValidateFormatCycleCsv === 'error'
                                ? 'Format cycle wajib diisi'
                                : ''
                            }
                            onPressEnter={''}
                          />
                        </div>
                        <div>
                          <span>
                            <b className="paddingRightCustomCsv">Waybill</b>
                            <Tooltip
                              title={
                                <div>
                                  <span>
                                    Diisi dengan kolom header yang mewakili
                                    waybill untuk keperluan Attempt Call Center
                                  </span>
                                </div>
                              }
                            >
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.Waybill}
                            className={
                              this.props.TypeFormCsv === 'detail' &&
                              'fieldStyleDetailCustomCsv'
                            }
                            disabled={
                              this.props.ChekboxCallCenter === false
                                ? true
                                : false
                            }
                            addonBefore={''}
                            placeholder={'Contoh : waybill'}
                            prefix={''}
                            maxLength={50}
                            style={{}}
                            size=""
                            type={''}
                            onChange={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangeWaybillCsv
                            }
                            onBlur={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangeWaybillCsv
                            }
                            onPressEnter={''}
                          />
                        </div>
                        <div>
                          <span>
                            <b>
                              Alamat Nasabah
                              <span className="dotCustomCsv">*</span>
                            </b>
                          </span>
                          {this.props.ListAddressCsv.map(
                            (address: any, index: any) => (
                              <div key={index}>
                                <Row>
                                  <Col
                                    className="paddingRightCustomCsv2"
                                    span={21}
                                  >
                                    <FormInputTextMaterial
                                      validateType={true}
                                      className={
                                        this.props.TypeFormCsv === 'detail' &&
                                        'fieldStyleDetailCustomCsv'
                                      }
                                      disabled={false}
                                      addonBefore={''}
                                      onPressEnter={''}
                                      placeholder={`Contoh : address ${
                                        index + 1
                                      }`}
                                      prefix={''}
                                      value={address}
                                      maxLength={30}
                                      style={{}}
                                      size=""
                                      type={''}
                                      onChange={
                                        this.props.TypeFormCsv === 'detail'
                                          ? ''
                                          : (e: any) =>
                                              this.props.handleChangeAddress(
                                                e,
                                                index
                                              )
                                      }
                                      onBlur={''}
                                      validatestatus={''}
                                      help={''}
                                      styleForm={{ marginBottom: 8 }}
                                    />
                                  </Col>
                                  <Col span={3}>
                                    <div className="paddingTopCustomCsv">
                                      {this.props.TypeFormCsv === 'detail' ? (
                                        <ButtonMaterial
                                          disabled={true}
                                          handleSubmit={''}
                                          teksButton={''}
                                          size={''}
                                          shape={''}
                                          className={
                                            'btnDetailDeleteAddressCsv'
                                          }
                                          type={'primary'}
                                          icon={'delete'}
                                          style={{}}
                                        />
                                      ) : (
                                        <ButtonMaterial
                                          disabled={''}
                                          handleSubmit={() =>
                                            this.props.handleRemoveAddressCsv(
                                              index
                                            )
                                          }
                                          teksButton={''}
                                          size={''}
                                          shape={''}
                                          className={'btnDeleteAddressCsv'}
                                          type={'primary'}
                                          icon={'delete'}
                                          style={{}}
                                        />
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )
                          )}
                          <div className="btnContentDynamicAddressCsv">
                            <Button
                              disabled={
                                this.props.TypeFormCsv === 'detail'
                                  ? true
                                  : false
                              }
                              onClick={() => this.props.handleAddAddress()}
                              type={'dashed'}
                              icon={'plus'}
                              className={
                                this.props.TypeFormCsv === 'detail'
                                  ? 'widthFull'
                                  : 'btnAddAddressCustomCsv'
                              }
                            >
                              <span
                                className={
                                  this.props.TypeFormCsv === 'detail'
                                    ? ''
                                    : 'blackColor'
                                }
                              >
                                Tambah Alamat Nasabah
                              </span>
                            </Button>
                          </div>
                        </div>
                        <div>
                          <b>Alamat Kantor</b>
                          {this.props.ListAddressOffice.map(
                            (address: any, index: any) => (
                              <div key={index}>
                                <Row>
                                  <Col
                                    className="paddingRightCustomCsv2"
                                    span={21}
                                  >
                                    <FormInputTextMaterial
                                      validateType={true}
                                      className={
                                        this.props.TypeFormCsv === 'detail' &&
                                        'fieldStyleDetailCustomCsv'
                                      }
                                      disabled={false}
                                      addonBefore={''}
                                      onPressEnter={''}
                                      placeholder={`Contoh : address ${
                                        index + 1
                                      }`}
                                      prefix={''}
                                      value={address}
                                      maxLength={30}
                                      style={{}}
                                      size=""
                                      type={''}
                                      onChange={
                                        this.props.TypeFormCsv === 'detail'
                                          ? ''
                                          : (e: any) =>
                                              this.props.handleChangeAddressOffice(
                                                e,
                                                index
                                              )
                                      }
                                      onBlur={''}
                                      validatestatus={''}
                                      help={''}
                                      styleForm={{ marginBottom: 8 }}
                                    />
                                  </Col>
                                  <Col span={3}>
                                    <div className="paddingTopCustomCsv">
                                      {this.props.TypeFormCsv === 'detail' ? (
                                        <ButtonMaterial
                                          disabled={true}
                                          handleSubmit={''}
                                          teksButton={''}
                                          size={''}
                                          shape={''}
                                          className={
                                            'btnDetailDeleteAddressCsv'
                                          }
                                          type={'primary'}
                                          icon={'delete'}
                                          style={{}}
                                        />
                                      ) : (
                                        <ButtonMaterial
                                          disabled={''}
                                          handleSubmit={() =>
                                            this.props.handleRemoveAddressOffice(
                                              index
                                            )
                                          }
                                          teksButton={''}
                                          size={''}
                                          shape={''}
                                          className={'btnDeleteAddressCsv'}
                                          type={'primary'}
                                          icon={'delete'}
                                          style={{}}
                                        />
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )
                          )}
                          <div className="btnContentDynamicAddressCsv">
                            <Button
                              disabled={
                                this.props.TypeFormCsv === 'detail'
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                this.props.handleAddAddressOffice()
                              }
                              type={'dashed'}
                              icon={'plus'}
                              className={
                                this.props.TypeFormCsv === 'detail'
                                  ? 'widthFull'
                                  : 'btnAddAddressCustomCsv'
                              }
                            >
                              <span
                                className={
                                  this.props.TypeFormCsv === 'detail'
                                    ? ''
                                    : 'blackColor'
                                }
                              >
                                Tambah Alamat Kantor
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="colRightCustomCsv">
                        <div>
                          <span>
                            <b>Kota</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={
                              this.props.TypeFormCsv === 'detail'
                                ? 'fieldStyleDetailCustomCsv'
                                : ''
                            }
                            disabled={false}
                            placeholder={'Contoh : city'}
                            value={this.props.CityCsv}
                            maxLength={30}
                            onChange={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangeCityCsv
                            }
                            onBlur={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangeCityCsv
                            }
                          />
                        </div>

                        <div>
                          <span>
                            <b>Kode Pos</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={
                              this.props.TypeFormCsv === 'detail'
                                ? 'fieldStyleDetailCustomCsv'
                                : ''
                            }
                            disabled={false}
                            addonBefore={''}
                            onPressEnter={''}
                            placeholder={'Contoh : postal_code'}
                            prefix={''}
                            value={this.props.PostalCodeCsv}
                            maxLength={30}
                            style={{}}
                            size=""
                            type={''}
                            onChange={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangePostalCodeCsv
                            }
                            onBlur={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangePostalCodeCsv
                            }
                          />
                        </div>
                        <div>
                          <span>
                            <b>No Telp</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={
                              this.props.TypeFormCsv === 'detail'
                                ? 'fieldStyleDetailCustomCsv'
                                : ''
                            }
                            disabled={false}
                            addonBefore={''}
                            onPressEnter={''}
                            placeholder={'Contoh : no_telp'}
                            prefix={''}
                            value={this.props.NoTelpCsv}
                            maxLength={30}
                            style={{}}
                            size=""
                            type={''}
                            onChange={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangeNoTelpCsv
                            }
                            onBlur={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangeNoTelpCsv
                            }
                          />
                        </div>
                        <div>
                          <span>
                            <b>Tautan (Call-Link)</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={
                              this.props.TypeFormCsv === 'detail'
                                ? 'fieldStyleDetailCustomCsv'
                                : ''
                            }
                            placeholder={'Contoh: https://rds-express.co.id'}
                            value={this.props.CallLinkCsv}
                            onChange={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangeCallLinkCsv
                            }
                            onBlur={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangeCallLinkCsv
                            }
                          />
                        </div>
                        <div>
                          <span>
                            <b>Grouping Pengiriman</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={
                              this.props.TypeFormCsv === 'detail'
                                ? 'fieldStyleDetailCustomCsv'
                                : ''
                            }
                            disabled={false}
                            addonBefore={''}
                            onPressEnter={''}
                            placeholder={'Contoh : nomor_polis'}
                            prefix={''}
                            value={this.props.GroupingNameCsv}
                            maxLength={30}
                            style={{}}
                            size=""
                            type={''}
                            onChange={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangeGroupNameCsv
                            }
                            onBlur={''}
                          />
                        </div>
                        <span>
                          <b>Jenis Pencarian</b>
                        </span>
                        {this.props.TypeFormCsv === 'detail' ? (
                          <FormInputTextMaterial
                            validateType={true}
                            className={
                              this.props.TypeFormCsv === 'detail'
                                ? 'fieldStyleDetailCustomCsv'
                                : ''
                            }
                            disabled={false}
                            addonBefore={''}
                            onPressEnter={''}
                            placeholder={'Pilih jenis pencarian kedua'}
                            prefix={''}
                            value={valueSearchTypeCsv}
                            maxLength={''}
                            style={{}}
                            size=""
                            type={''}
                            onChange={''}
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                          />
                        ) : (
                          <Form.Item validateStatus={''} help={''}>
                            <Select
                              className="widthFull"
                              onSelect={this.props.handleChangeSearchTypeCsv}
                              optionFilterProp="children"
                              showSearch
                              value={
                                this.props.SearchTypeCsv === null ? (
                                  <span className="colorPlaceholderDropDown">
                                    Pilih Jenis Pencarian Kedua
                                  </span>
                                ) : (
                                  this.props.SearchTypeCsv
                                )
                              }
                              onChange={this.props.handleChangeSearchTypeCsv}
                            >
                              <Option value="NomerReferensi">
                                Nomer referensi
                              </Option>
                              <Option value="NomerPolis">Nomer polis</Option>
                              <Option value="NomerRekening">
                                Nomer rekening
                              </Option>
                            </Select>
                          </Form.Item>
                        )}
                        <div>
                          <span>
                            <b>Kata kunci Pencarian</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            className={
                              this.props.TypeFormCsv === 'detail'
                                ? 'fieldStyleDetailCustomCsv'
                                : ''
                            }
                            disabled={false}
                            addonBefore={''}
                            onPressEnter={''}
                            placeholder={'Contoh : no_ref'}
                            prefix={''}
                            value={this.props.SearchKeyCsv}
                            maxLength={''}
                            style={{}}
                            size=""
                            type={''}
                            onChange={
                              this.props.TypeFormCsv === 'detail'
                                ? ''
                                : this.props.handleChangeSearchKeyCsv
                            }
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                          />
                        </div>

                        <div>
                          <span>
                            <b className="paddingRightCustomCsv">
                              Flag Perubahan Alamat
                            </b>
                            <Tooltip
                              title={
                                <div>
                                  <span>
                                    Diisi dengan kolom header yang mewakili flag
                                    perubahan alamat untuk membedakan data
                                    Attempt atau Kirim Ulang Call Center
                                  </span>
                                </div>
                              }
                            >
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          </span>
                          <Row>
                            <Col span={22}>
                              <FormInputTextMaterial
                                validateType={true}
                                value={this.props.AddressUpdate}
                                className={
                                  this.props.TypeFormCsv === 'detail' &&
                                  'fieldStyleDetailCustomCsv'
                                }
                                disabled={
                                  this.props.ChekboxCallCenter === false
                                    ? true
                                    : false
                                }
                                placeholder={'Contoh : address'}
                                maxLength={30}
                                onChange={
                                  this.props.TypeFormCsv === 'detail'
                                    ? ''
                                    : this.props.handleChangeAddressUpdateCsv
                                }
                                onBlur={
                                  this.props.TypeFormCsv === 'detail'
                                    ? ''
                                    : this.props.handleChangeAddressUpdateCsv
                                }
                                onPressEnter={''}
                              />
                            </Col>
                            <Tooltip
                              placement="top"
                              title={
                                'Ceklist untuk membuat template unggah Call Center'
                              }
                            >
                              <Col span={2}>
                                <Checkbox
                                  className="checkboxCustomCsv"
                                  disabled={
                                    this.props.TypeFormCsv === 'detail'
                                      ? true
                                      : false
                                  }
                                  checked={this.props.ChekboxCallCenter}
                                  onChange={
                                    this.props.handleChangeCheckboxCallCenter
                                  }
                                />
                              </Col>
                            </Tooltip>
                          </Row>
                        </div>

                        <div className="spacingAdditionalData">
                          <span>
                            <b>
                              Data Tambahan
                              <span className="dotCustomCsv">*</span>
                            </b>
                          </span>

                          {this.props.ListAdditionalDataCsv.map(
                            (data: any, index: any) => (
                              <div key={data.index}>
                                <Row>
                                  <Col
                                    className="paddingRightCustomCsv2"
                                    span={21}
                                  >
                                    <FormInputTextMaterial
                                      validateType={true}
                                      className={
                                        this.props.TypeFormCsv === 'detail' &&
                                        'fieldStyleDetailCustomCsv'
                                      }
                                      disabled={false}
                                      addonBefore={''}
                                      onPressEnter={''}
                                      placeholder={'Contoh : jumlah_halaman'}
                                      prefix={''}
                                      value={data}
                                      maxLength={30}
                                      style={{}}
                                      size=""
                                      type={''}
                                      onChange={
                                        this.props.TypeFormCsv === 'detail'
                                          ? ''
                                          : (e: any) =>
                                              this.props.handleChangeAdditionalDataCsv(
                                                e,
                                                index
                                              )
                                      }
                                      onBlur={''}
                                      validatestatus={''}
                                      help={''}
                                      styleForm={{ marginBottom: 8 }}
                                    />
                                  </Col>
                                  <Col span={3}>
                                    <div className="paddingTopCustomCsv">
                                      {this.props.TypeFormCsv === 'detail' ? (
                                        <ButtonMaterial
                                          disabled={true}
                                          handleSubmit={''}
                                          teksButton={''}
                                          size={''}
                                          shape={''}
                                          className={
                                            'btnDetailDeleteAddressCsv'
                                          }
                                          type={'primary'}
                                          icon={'delete'}
                                          style={{}}
                                        />
                                      ) : (
                                        <ButtonMaterial
                                          disabled={''}
                                          handleSubmit={() =>
                                            this.props.handleRemoveAdditionalDataCsv(
                                              index
                                            )
                                          }
                                          teksButton={''}
                                          size={''}
                                          shape={''}
                                          className={'btnDeleteAddressCsv'}
                                          type={'primary'}
                                          icon={'delete'}
                                          style={{}}
                                        />
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )
                          )}
                          <div className="btnContentDynamicAddressCsv">
                            <Button
                              disabled={
                                this.props.TypeFormCsv === 'detail'
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                this.props.handleAddAdditionalData()
                              }
                              type={'dashed'}
                              icon={'plus'}
                              className={
                                this.props.TypeFormCsv === 'detail'
                                  ? 'widthFull'
                                  : 'btnAddAddressCustomCsv'
                              }
                              style={{}}
                            >
                              <span
                                className={
                                  this.props.TypeFormCsv === 'detail'
                                    ? ''
                                    : 'blackColor'
                                }
                              >
                                Tambah Data
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="btnContentSaveCsv">
                    <ButtonMaterial
                      disabled={
                        this.props.TypeFormCsv === 'detail'
                          ? true
                          : validateBtn()
                      }
                      handleSubmit={this.props.handleSubmiteAddressCsv}
                      teksButton={<b>Simpan</b>}
                      size={''}
                      shape={'round'}
                      className={
                        this.props.TypeFormCsv === 'detail' ||
                        validateBtn() === true
                          ? 'btnSaveDisableCsv'
                          : 'btnSaveCsv'
                      }
                      type={'primary'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                  {this.props.TypeFormCsv === 'edit' && (
                    <div style={{ float: 'right' }}>
                      <ButtonMaterial
                        disabled={false}
                        handleSubmit={this.props.handleCancel}
                        teksButton={<b>Reset</b>}
                        size={'middle'}
                        shape={''}
                        className={'btnResetCsv'}
                        type={'primary'}
                        icon={''}
                        style={{}}
                      />
                    </div>
                  )}
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
