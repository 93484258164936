export function changeRadioButton(radioButton: any) {
  return {
    type: 'CHANGE_RADIO_BUTTON_UA',
    radioButton,
  };
}
export function changeWaybillSearch(waybill: any) {
  return {
    type: 'CHANGE_WAYBILL_UA',
    waybill,
  };
}
export function changeWaybillForm(waybillForm: any) {
  return {
    type: 'CHANGE_WAYBILL_FORM_UA',
    waybillForm,
  };
}
export function changeName(name: any) {
  return {
    type: 'CHANGE_NAME_UA',
    name,
  };
}
export function changePhoneNumber(phoneNumber: any) {
  return {
    type: 'CHANGE_PHONE_NUMBER_UA',
    phoneNumber,
  };
}
export function changeCustomer(customer: any) {
  return {
    type: 'CHANGE_CUSTOMER_UA',
    customer,
  };
}
export function changeCustomerProduct(customerProduct: any) {
  return {
    type: 'CHANGE_CUSTOMER_PRODUCT_UA',
    customerProduct,
  };
}
export function changeTemplateUpload(templateUpload: any) {
  return {
    type: 'CHANGE_TEMPLATE_UPLOAD_UA',
    templateUpload,
  };
}
export function changeCycleDateUpload(cycleDate: any) {
  return {
    type: 'CHANGE_CYCLE_DATE_UA',
    cycleDate,
  };
}
export function changeFileUpload(fileUpload: any) {
  return {
    type: 'CHANGE_FILE_UPLOAD_UA',
    fileUpload,
  };
}
export function changeFileName(fileName: any) {
  return {
    type: 'CHANGE_FILE_NAME_UA',
    fileName,
  };
}
export function changeAddress(address: any) {
  return {
    type: 'CHANGE_ADDRESS_UA',
    address,
  };
}
export function fetchCustomerProductList(customerProductList: any) {
  return {
    type: 'FETCH_CUSTOMER_PRODUCT_LIST_UA',
    customerProductList,
  };
}
export function fetchTemplateUploadList(templateUploadList: any) {
  return {
    type: 'FETCH_TEMPLATE_UPLOAD_LIST_UA',
    templateUploadList,
  };
}
export function fetchResultUpload(resultUpload: any) {
  return {
    type: 'FETCH_RESULT_UPLOAD_UA',
    resultUpload,
  };
}
export function downloadResult() {
  return {
    type: 'DOWNLOAD_RESULT_UPLOAD_ADDRESS_UA',
  };
}
export function clearMultipleDataForm() {
  return {
    type: 'CLEAR_MULTIPLE_DATA_FORM_UA',
  };
}
export function getTemplateUpload() {
  return {
    type: 'GET_TEMPLATE_UPLOAD_UA',
  };
}
export function clearAllForm() {
  return {
    type: 'CLEAR_ALL_FORM_UA',
  };
}
export function clearFormExceptWaybill() {
  return {
    type: 'CLEAR_FORM_EXCEPT_WAYBILL_UA',
  };
}
export function searchWaybill() {
  return {
    type: 'SEARCH_WAYBILL_UA',
  };
}
export function submitUpdateAddress() {
  return {
    type: 'SUBMIT_UPDATE_ADDRESS',
  };
}
export function getListCustomerProduct() {
  return {
    type: 'GET_LIST_CUSTOMER_PRODUCT_UPDATE_ADDRESS',
  };
}
export function submitUpdateAddressUpload() {
  return {
    type: 'SUBMIT_UPDATE_ADDRESS_UPLOAD',
  };
}
