import * as ActionAuth from '../../action/Auth';
import * as ActionLoadingCustomerProduct from '../../action/CustomerProduct/ActionCustomerProductLoading';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionSetCustomerProduct from '../../action/CustomerProduct/ActionListCustomerProduct';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_GetListCustomerProduct(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  let IdCustomer =
    state.Delivery.UnggahDocument.CustomerId === null
      ? state.Delivery.DistributionDocument.Customer
      : state.Delivery.UnggahDocument.CustomerId;

  let ProductType =
    state.Delivery.UnggahDocument.ProductType === null
      ? state.Delivery.DistributionDocument.ProductType
      : state.Delivery.UnggahDocument.ProductType;
  try {
    yield put(ActionLoadingCustomerProduct.setLoadingCustomerProduct());
    yield put(
      ActionLoadingCustomerProduct.setTypeLoadingCustomerProduct(
        'selectCustomerProduct'
      )
    );

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Customer/product/customer/status/${ProductType}/${IdCustomer}/Active`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionSetCustomerProduct.setListCustomerProduct(data));
    yield put(ActionLoadingCustomerProduct.clearLoadingCustomerProduct());
    yield put(ActionLoadingCustomerProduct.clearTypeLoadingCustomerProduct());
  } catch (error) {
    if (error.response.status !== 401) {
      yield put(ActionLoadingCustomerProduct.clearLoadingCustomerProduct());
      yield put(ActionLoadingCustomerProduct.clearTypeLoadingCustomerProduct());
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* S_GetListCustomerProductNoType(action: any) {
  try {
    yield put(ActionLoadingCustomerProduct.setLoadingCustomerProduct());
    yield put(
      ActionLoadingCustomerProduct.setTypeLoadingCustomerProduct(
        'selectCustomerProduct'
      )
    );
    const state = yield select();
    const token = state.Auth.Token;
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    const config = {
      Authorization: `bearer ${token}`,
    };
    const IdCustomer =
      state.router.location.pathname === '/IndexManifest'
        ? state.ScanManifest.ScanManifestForm.ManifestCustomerForm === null
          ? state.Manifest.CustomerManifestForm.Customer
          : state.ScanManifest.ScanManifestForm.ManifestCustomerForm
        : state.Report.DigitalWaybillReportForm.Customer !== null
        ? state.Report.DigitalWaybillReportForm.Customer
        : state.router.location.pathname === '/dataDelivery'
        ? state.DataDelivery.Filter.Customer
        : state.router.location.pathname === '/manageCustomerAccount/detail' ||
          state.router.location.pathname === '/customer/config'
        ? state.Customer.PropertiesCustomer.IdCustomer
        : state.router.location.pathname === '/delivery/uploadSoftcopy'
        ? state.Delivery.UnggahDocument.CustomerId
        : state.router.location.pathname === '/delivery/document'
        ? state.PrintWaybill.FormPrintWaybill.CustomerId
        : state.Report.InvoiceReport.CustomerId === null
        ? state.Report.CourierReportForm.CustomerIdR ||
          state.Report.CustomerReportForm.CustomerId ||
          state.Report.MisReportForm.CustomerId ||
          state.ReportBast.CustomerId
        : state.Report.InvoiceReport.CustomerId;

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Customer/product/customer/${IdCustomer}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionSetCustomerProduct.setListCustomerProduct(data.data));
    yield put(ActionLoadingCustomerProduct.setLoadingCustomerProduct());
    yield put(ActionLoadingCustomerProduct.clearTypeLoadingCustomerProduct());
  } catch (error) {
    if (error.response.status !== 401) {
      yield put(ActionLoadingCustomerProduct.setLoadingCustomerProduct());
      yield put(ActionLoadingCustomerProduct.clearTypeLoadingCustomerProduct());
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_GetListCustomerProductNoType() {
  yield takeLatest(
    'GET_LIST_CUSTOMER_PRODUCT_NO_TYPE',
    S_GetListCustomerProductNoType
  );
}
export function* SM_GetListCustomerProduct() {
  yield takeLatest('GET_LIST_CUSTOMER_PRODUCT', S_GetListCustomerProduct);
}
