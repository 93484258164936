import React from 'react';
import { Button as CustomButton, Icon, Tooltip } from 'antd';

type ButtonProps = {
  variant?: 'primary' | 'danger' | 'info' | 'success' | 'primary-outline';
  type?: 'primary' | 'danger' | 'link' | 'default' | 'ghost' | 'dashed';
  borderRadius?: 'large' | 'medium' | 'small';
  size?: 'large' | 'medium' | 'small';
  text?: string;
  className?: any;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  startIcon?: string;
  endIcon?: string;
  htmlType?: 'button' | 'reset' | 'submit' | undefined;
  icon?: string;
  tooltip?: any;
  styles?: any;
};

export default function Button({
  text,
  variant = 'primary',
  className,
  borderRadius,
  size = 'medium',
  onClick,
  disabled = false,
  loading = false,
  startIcon,
  endIcon,
  htmlType = 'button',
  type,
  icon,
  tooltip,
  styles,
}: ButtonProps) {
  let configStyles = {
    color: '#FFFFFF',
    fontWeight: 500,
    fontSize: '14px',
    padding: '8px 24px',
    height: 'auto',
  } as any;
  switch (variant) {
    case 'primary':
      configStyles = {
        ...configStyles,
        background: '#11BEFF',
      };
      break;
    case 'primary-outline':
      configStyles = {
        ...configStyles,
        background: '#FFFFFF',
        color: '#1890FF',
        border: ' 1px solid #1890FF',
      };
      break;
    case 'danger':
      configStyles = {
        ...configStyles,
        background: '#F40000',
      };
      break;
    case 'info':
      configStyles = {
        ...configStyles,
        background: '#7956EE',
      };
      break;

    default:
      break;
  }

  if (!icon) {
    switch (borderRadius) {
      case 'large':
        configStyles = {
          ...configStyles,
          borderRadius: '25px',
          padding: '12px 32px',
        };
        break;
      case 'medium':
        configStyles = {
          ...configStyles,
          borderRadius: '20px',
        };
        break;
      case 'small':
        configStyles = {
          ...configStyles,
          borderRadius: '10px',
        };
        break;

      default:
        configStyles = {
          ...configStyles,
          borderRadius: '20px',
        };
        break;
    }
  }

  switch (size) {
    case 'small':
      configStyles = {
        ...configStyles,
        padding: '8px 16px',
        fontSize: '14px',
      };
      break;
    default:
      configStyles = {
        ...configStyles,
      };
      break;
  }

  if (disabled) {
    configStyles = {
      ...configStyles,
      background: '#f5f5f5',
      color: 'rgba(0, 0, 0, 0.25)',
      borderColor: '#d9d9d9',
    };
  }

  const RenderEmpty = ({
    title,
    children,
  }: {
    title?: string;
    children: React.ReactNode;
  }) => <>{children}</>;

  const IsTooltip = tooltip ? Tooltip : RenderEmpty;

  return (
    <IsTooltip title={!disabled && tooltip ? tooltip : ''}>
      <CustomButton
        className={[className].join(' ')}
        style={{
          ...styles,
          ...configStyles,
          ...(icon
            ? { width: '32px', height: '32px', padding: 0, borderRadius: 8 }
            : {}),
        }}
        onClick={onClick}
        disabled={disabled}
        loading={loading}
        htmlType={htmlType}
        type={type}
        icon={icon}
      >
        {startIcon && <Icon type={startIcon} />}
        {text}
        {endIcon && <Icon type={endIcon} />}
      </CustomButton>
    </IsTooltip>
  );
}
