import { Checkbox, Col, Form, Row, Select, Tooltip } from 'antd';

import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import FormInputTextMaterial from 'materials/FormInputTextMaterial';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';

const { Option } = Select;
interface IProps {
  isLoading: any;
  validateButton: any;
  handleSubmit: any;
  validateSubmit: any;
  DetailCourierCode: any;
  AssignStatus: any;
  DetailWorB: any;
  IdBranch: any;
  branchId: any;
  Courier: any;
  CheckBox: any;
  WorB: any;
  handlePutCourier: any;
  handleBlurCourier: any;
  handlePutCheckbox: any;
  handlePutWorB: any;
  ValidateCourier: any;
  helpValidateCourier: any;
  ValidateWorB: any;
  helpValidateBorW: any;
  ListCourier: any;
  ListBranch: any;
  handleGetCourier: any;
  handleReset: any;
  validateReset: any;
}

export default function AssigningCourierComponent({
  isLoading,
  validateButton,
  handleSubmit,
  validateSubmit,
  DetailCourierCode,
  AssignStatus,
  DetailWorB,
  IdBranch,
  branchId,
  Courier,
  CheckBox,
  WorB,
  handlePutCourier,
  handleBlurCourier,
  handlePutCheckbox,
  handlePutWorB,
  ValidateCourier,
  helpValidateCourier,
  ValidateWorB,
  helpValidateBorW,
  ListCourier,
  ListBranch,
  handleGetCourier,
  handleReset,
  validateReset,
}: IProps) {
  return (
    <div style={{ padding: '35px' }}>
      <SpinMaterial
        spinning={isLoading}
        style={{}}
        size={'large'}
        content={
          <React.Fragment>
            <CardMaterial
              extra={''}
              style={{ borderRadius: '8px' }}
              title={
                <div style={{ color: '#11BEFF' }}>
                  <span>
                    <b>SCAN KURIR</b>
                  </span>
                </div>
              }
              content={
                <div>
                  <Row>
                    <Col span={12}>
                      <div>
                        <span>
                          <b>Posko</b>
                        </span>
                        <Form.Item validateStatus={''} help={''}>
                          <Select
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            showSearch
                            disabled={true}
                            value={
                              IdBranch !== null && IdBranch !== 0
                                ? IdBranch
                                : branchId
                            }
                          >
                            {ListBranch.map((data: any, index: any) => (
                              <Option key={index} value={data.id}>
                                {data.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                      <div>
                        <span>
                          <b>Kurir</b>
                        </span>
                        <Form.Item
                          validateStatus={ValidateCourier}
                          help={helpValidateCourier}
                        >
                          <Select
                            disabled={false}
                            // loading={LoadingListCourier}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            showSearch
                            value={Courier}
                            onBlur={handleBlurCourier}
                            onChange={handlePutCourier}
                            onFocus={handleGetCourier}
                          >
                            {ListCourier.map((data: any, index: any) => (
                              <Option
                                key={index}
                                value={data.id}
                                label={data.code}
                              >
                                {data.firstName} ({data.code})
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>

                    <Col span={12} style={{ paddingLeft: '25px' }}>
                      <div>
                        <Row>
                          <Col span={18}>
                            <span>
                              {CheckBox === true ? (
                                <b>Barcode</b>
                              ) : (
                                <b>Waybill</b>
                              )}
                            </span>
                            <FormInputTextMaterial
                              validateType={true}
                              className={''}
                              onPressEnter={handleSubmit}
                              disabled={false}
                              addonBefore={''}
                              placeholder={
                                CheckBox === true
                                  ? 'Masukkan Barcode'
                                  : 'Masukkan Waybill'
                              }
                              prefix={''}
                              value={WorB}
                              maxLength={29}
                              style={{}}
                              size=""
                              type={''}
                              onChange={handlePutWorB}
                              onBlur={handlePutWorB}
                              validatestatus={ValidateWorB}
                              help={helpValidateBorW}
                            />
                          </Col>
                          <Col span={6}>
                            <Row>
                              <Tooltip
                                placement="top"
                                title={'Ceklist untuk barcode'}
                              >
                                <Checkbox
                                  style={{
                                    paddingTop: 30,
                                    paddingLeft: '24px',
                                  }}
                                  checked={CheckBox}
                                  onChange={handlePutCheckbox}
                                />
                              </Tooltip>
                              <span
                                style={{
                                  paddingLeft: '18px',
                                  fontStyle: 'italic',
                                  color: '#969696',
                                }}
                              >
                                Barcode
                              </span>
                            </Row>
                          </Col>
                        </Row>
                        <Row style={{ float: 'left', marginTop: '28px' }}>
                          {AssignStatus === 'Success' ? (
                            <p>
                              <b>
                                <span style={{ color: '#11BEFF' }}>
                                  {DetailWorB}
                                </span>{' '}
                                <span style={{ color: '#31E116' }}>
                                  Dikirim oleh {DetailCourierCode}
                                </span>
                              </b>
                            </p>
                          ) : AssignStatus === 'Failed' ? (
                            <p>
                              <b>
                                <span style={{ color: '#11BEFF' }}>
                                  {DetailWorB}
                                </span>{' '}
                                <span style={{ color: 'red' }}>Gagal Scan</span>
                              </b>
                            </p>
                          ) : AssignStatus === 'FailedNotFound' ? (
                            <p>
                              <b>
                                <span style={{ color: '#11BEFF' }}>
                                  {DetailWorB}
                                </span>{' '}
                                <span style={{ color: 'red' }}>
                                  Tidak ditemukan
                                </span>
                              </b>
                            </p>
                          ) : (
                            ''
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div style={{ marginLeft: 20, float: 'right' }}>
                      <ButtonMaterial
                        disabled={validateButton}
                        handleSubmit={handleSubmit}
                        teksButton={<b>Simpan</b>}
                        size={'middle'}
                        shape={'round'}
                        className={''}
                        type={'primary'}
                        icon={''}
                        style={{
                          height: '35px',
                          width: '100px',
                        }}
                      />
                    </div>
                    <div style={{ float: 'right' }}>
                      <ButtonMaterial
                        disabled={validateReset}
                        handleSubmit={handleReset}
                        teksButton={<b>Reset</b>}
                        size={'middle'}
                        shape={'round'}
                        className={''}
                        type={'primary'}
                        icon={''}
                        style={
                          validateReset === false
                            ? {
                                background: '#FF5858',
                                borderColor: '#FF5858',
                                colorFont: '#FFFFFF',
                                height: '35px',
                                width: '100px',
                              }
                            : {
                                height: '35px',
                                width: '100px',
                              }
                        }
                      />
                    </div>
                  </Row>
                </div>
              }
            />
          </React.Fragment>
        }
      />
    </div>
  );
}
