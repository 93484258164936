import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_synchronizeDataActive(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  try {
    yield put(toggle.changeLoadingTrue());
    yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/VoipSetting/Syncronize/CreateDataTransaction`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Sinkronisasi data berhasil</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_synchronizeDataActive() {
  yield takeLatest('SUBMIT_SYNCRON_DATA_ACTIVE', S_synchronizeDataActive);
}
