import { Checkbox, Col, Icon, Input, Row, Select, Form } from 'antd';

import ButtonMaterial from 'materials/ButtonMaterial';
import ModalMaterial from 'materials/ModalMaterial';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';

interface IProps {
  fileCsv: any;
  fileName: any;
  checkboxBarcode: any;
  checkboxBarcodeReverse: any;
  countSuccess: any;
  countFailed: any;
  handleCheckboxBarcode: any;
  handleCheckboxBarcodeReverse: any;
  handleConvert: any;
  handleDownload: any;
  handleDownloadTemplate: any;
  handleDownloadResult: any;
  validateFlagFileName: any;
  helpFlagFileName: any;
  handlePutFlagFileName: any;
  handleBlurFlagFilename: any;
  getFlagFileNameUpload: any;
  validationBtnDownload: any;
  visible: any;
  jenisToggle: any;
  handleClearModal: any;
  handleClearResult: any;
  isLoading: any;
  LastAccess: any;
}

export default function DigitalWaybillByUploadComponent({
  fileCsv,
  fileName,
  checkboxBarcode,
  checkboxBarcodeReverse,
  countSuccess,
  countFailed,
  handleCheckboxBarcode,
  handleCheckboxBarcodeReverse,
  handleConvert,
  handleDownload,
  handleDownloadTemplate,
  handleDownloadResult,
  validationBtnDownload,
  validateFlagFileName,
  helpFlagFileName,
  handlePutFlagFileName,
  handleBlurFlagFilename,
  getFlagFileNameUpload,
  visible,
  jenisToggle,
  handleClearModal,
  handleClearResult,
  isLoading,
  LastAccess,
}: IProps) {
  const { Option } = Select;
  return (
    <React.Fragment>
      <ModalMaterial
        className={''}
        width={'40%'}
        style={{}}
        teksTitle={'EXPORT BERDASARKAN WAYBILL/BARCODE'}
        visible={visible}
        onCancel={handleClearModal}
        content={
          <SpinMaterial
            spinning={
              isLoading === true && jenisToggle === 'WaybillDigitalByUpload'
                ? true
                : false
            }
            style={{}}
            size={'large'}
            content={
              <div className={'contentWBUpload'}>
                <Row>
                  <div>
                    <div>
                      <span>
                        <b>Penamaan File</b>
                      </span>
                      <Form.Item
                        validateStatus={validateFlagFileName}
                        help={helpFlagFileName}
                      >
                        <Select
                          style={{ width: '100%' }}
                          disabled={false}
                          onBlur={handleBlurFlagFilename}
                          onChange={handlePutFlagFileName}
                          placeholder="Pilih penamaan file"
                          value={
                            getFlagFileNameUpload === null ? (
                              <span className="colorPlaceholderDropDown">
                                Pilih penamaan file
                              </span>
                            ) : (
                              getFlagFileNameUpload
                            )
                          }
                        >
                          <Option key={''} value={'waybill'}>
                            Waybill
                          </Option>
                          <Option key={''} value={'polis'}>
                            No Polis
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="fileUploadInput">
                      <span>
                        <b>
                          Unggah Excel -{' '}
                          {checkboxBarcode === false ? 'Waybill' : 'Barcode'}
                        </b>
                      </span>
                      <Row>
                        <Col span={18}>
                          <Input
                            onChange={handleConvert}
                            value={fileName}
                            suffix={<Icon type="upload" />}
                            multiple={false}
                            type="file"
                            accept=".xlsx, .xls, .csv"
                          />
                        </Col>
                        <Col span={6}>
                          <Checkbox
                            className={'checkboxWD'}
                            checked={checkboxBarcode}
                            onChange={handleCheckboxBarcode}
                          />
                          <span className={'checkboxSpan'}>Barcode</span>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div>
                    <ButtonMaterial
                      disabled={false}
                      handleSubmit={handleDownloadTemplate}
                      teksButton={<u>Download sample file</u>}
                      size={''}
                      shape={''}
                      className={'linkWBTemplate'}
                      type={'link'}
                      icon={''}
                      style={{}}
                    />
                  </div>
                </Row>
                <Row>
                  <Checkbox
                    disabled={checkboxBarcode === false ? true : false}
                    className={'checkboxWDBarcode'}
                    checked={checkboxBarcodeReverse}
                    onChange={handleCheckboxBarcodeReverse}
                  />
                  <span className={'checkboxSpan'}>Barcode Terbalik</span>
                </Row>

                <div>
                  <ButtonMaterial
                    disabled={validationBtnDownload}
                    handleSubmit={handleDownload}
                    teksButton={<b>Download</b>}
                    size={'middle'}
                    shape={'round'}
                    className={
                      validationBtnDownload === false
                        ? 'validateDownloadButtonEnable'
                        : 'validateDownloadButtonDisabled'
                    }
                    type={'primary'}
                    icon={''}
                    style={{}}
                  />
                </div>
              </div>
            }
          />
        }
      />
      <ModalMaterial
        className={''}
        width={'30%'}
        style={{}}
        teksTitle={<span className={'greenText'}>{'Status Unggah Data'}</span>}
        visible={jenisToggle === 'WaybillDigitalByUploadResult' ? true : false}
        onCancel={handleClearResult}
        content={
          <SpinMaterial
            spinning={
              isLoading === true &&
              jenisToggle === 'WaybillDigitalByUploadResult'
                ? true
                : false
            }
            style={{}}
            size={'large'}
            content={
              <Row>
                <div>
                  <div className={'modalResultWD'}>
                    <p>
                      <span className={'subTitleBlack'}>
                        Berhasil : {countSuccess}
                      </span>
                    </p>
                    <p>
                      <span className={'subTitleBlack'}>
                        Gagal : {countFailed}
                      </span>
                    </p>
                  </div>
                  <Col>
                    <div className={'layoutButtonResultWD'}>
                      <ButtonMaterial
                        disabled={false}
                        handleSubmit={handleClearResult}
                        teksButton={<b>Tutup</b>}
                        size={'small'}
                        shape={''}
                        className={'buttonCloseModalResultWD'}
                        type={'primary'}
                        icon={''}
                        style={{}}
                      />
                    </div>
                    <div className={'layoutButtonResultWD'}>
                      <ButtonMaterial
                        disabled={''}
                        handleSubmit={handleDownloadResult}
                        teksButton={<b>Detail Laporan</b>}
                        size={'small'}
                        shape={''}
                        className={'buttonDownloadModalResultWD'}
                        type={'primary'}
                        icon={''}
                        style={{}}
                      />
                    </div>
                  </Col>
                </div>
              </Row>
            }
          />
        }
      />
    </React.Fragment>
  );
}
