export function changePickupDateCourierR(PickupDateCourierR: any) {
  return {
    type: 'CHANGE_PICKUP_DATE_COURIER_R_FORM',
    PickupDateCourierR,
  };
}

export function changeRadioButtonCourierR(RadioButtonCourierR: any) {
  return {
    type: 'CHANGE_RADIO_BUTTON_COURIER_R_FORM',
    RadioButtonCourierR,
  };
}

export function changeDeliveryDateCourierR(DeliveryDateCourierR: any) {
  return {
    type: 'CHANGE_DELIVERY_DATE_COURIER_R_FORM',
    DeliveryDateCourierR,
  };
}

export function changeBranchCourierR(BranchCourierR: any) {
  return {
    type: 'CHANGE_BRANCH_COURIER_R_FORM',
    BranchCourierR,
  };
}

export function changeCourierR(CourierR: any) {
  return {
    type: 'CHANGE_COURIER_R_FORM',
    CourierR,
  };
}

export function changeFormatFileR(FormatFileR: any) {
  return {
    type: 'CHANGE_FORMAT_FILE_R_FORM',
    FormatFileR,
  };
}

export function changeCustomerIdR(CustomerIdR: any) {
  return {
    type: 'CHANGE_CUSTOMER_ID_R_FORM',
    CustomerIdR,
  };
}

export function changeCustomerProductIdR(CustomerProductIdR: any) {
  return {
    type: 'CHANGE_CUSTOMER_PRODUCT_ID_R_FORM',
    CustomerProductIdR,
  };
}

export function changeStatusR(StatusR: any) {
  return {
    type: 'CHANGE_STATUS_R_FORM',
    StatusR,
  };
}

export function changeRingkasanR(RingkasanR: any) {
  return {
    type: 'CHANGE_RINGKASAN_R_FORM',
    RingkasanR,
  };
}

export function changeSlaR(SlaR: any) {
  return {
    type: 'CHANGE_SLA_R_FORM',
    SlaR,
  };
}

export function clearCustomerProductR() {
  return {
    type: 'CLEAR_CUSTOMER_PRODUCT_R_FROM',
  };
}

export function clearCourierR() {
  return {
    type: 'CLEAR_COURIER_R',
  };
}

export function clearCourierRForm() {
  return {
    type: 'CLEAR_COURIER_R_FORM',
  };
}

export function clearCourierBranchRForm() {
  return {
    type: 'CLEAR_COURIER_BRANCH_R_FORM',
  };
}

export function getCourierMultipleBranch()
{
    return({
        type:"GET_COURIER_MULTIPLE_BRANCH"
    })
}

export function downloadCourierR() {
  return {
    type: 'DOWNLOAD_COURIER_R',
  };
}