import { Modal } from 'antd';
import React from 'react';

interface IProps {
  content;
  teksTitle;
  visible;
  onCancel;
  className?;
  titleClassName?;
  width?;
}

export default function CModal(props: IProps) {
  const {
    content,
    teksTitle,
    visible,
    onCancel,
    className,
    titleClassName,
    width,
  } = props;

  return (
    <Modal
      centered
      className={className}
      title={
        <div className={titleClassName ? titleClassName : 'titleModal'}>
          {teksTitle}
        </div>
      }
      width={width}
      visible={visible}
      onCancel={onCancel}
      maskClosable={false}
      footer={null}
    >
      {content}
    </Modal>
  );
}
