import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_downloadValidateReport(action: any) {
  const state = yield select();
  const key = 'download';
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const FirstDate = state.Report.ValidateReportForm.FirstDate;
  const LastDate = state.Report.ValidateReportForm.LastDate;
  const Foto = state.Report.ValidateReportForm.Foto;
  const Signature = state.Report.ValidateReportForm.Signature;
  const MultipleSelect = state.Report.ValidateReportForm.MultipleSelect;
  const GeoTagging = state.Report.ValidateReportForm.GeoTagging;
  const Branch = state.Report.ValidateReportForm.Branch;
  const Status = state.Report.ValidateReportForm.Status;
  const Cycle = state.Report.ValidateReportForm.RadioButtonDelivery;
  const ReportType = state.Report.ValidateReportForm.ReportType;
  const valueReportType = ReportType === 'Summary' ? true : false;

  const file_name2 = 'Laporan Validasi.xls';
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = file_name2;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Delivery/Report/Validation`,
      {
        cycle: Cycle,
        summary: valueReportType,
        itemDeliveryTimeFrom:
          moment(FirstDate).format('YYYY-MM-DD') + 'T00:00:01.000Z',
        itemDeliveryTimeTo:
          moment(LastDate).format('YYYY-MM-DD') + 'T00:00:01.000Z',
        image: Foto,
        signature: Signature,
        multipleKey: MultipleSelect,
        geotaging: GeoTagging,
        branch: Branch,
        deliveryStats: Status,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 500) {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_downloadValidateReport() {
  yield takeLatest('DOWNLOAD_VALIDATE_REPORT', S_downloadValidateReport);
}
