import React, { useState } from 'react';
import cx from 'classnames';
import styles from './IndexScanManifest.module.css';
import SpinMaterial from 'materials/SpinMaterial';
import CardMaterial from 'materials/CardMaterial';
import { Row, Col, Table, Popconfirm } from 'antd';
import { Form, Field } from 'react-final-form';

import RadioField from 'components/RadioField';
import SelectField from 'components/SelectField';
import InputDatePicker from 'components/InputDatePicker';
import { SingleCheckbox } from 'components/SingleCheckbox';
import SearchMatrial from 'materials/SearchMatrial';
import { InputText } from 'components/InputField';
import Button from 'components/Button';

import { archiveDateTypes, treatmentDocument } from 'lib/constants';
import {
  DataCountingScanType,
  DataScanType,
  ResponseAddScanType,
} from '../container';
import {
  SelectOptionsInterface,
  DataTableResProps,
  ColumnAlignTable,
} from 'interface/general';
import { openNewTab, trimText } from 'lib/util';

type ScanManifestComponentProps = {
  dataScan: DataScanType;
  handleChangeData: (name: string, value: any) => void;
  isLoadingCustomer: boolean;
  isLoadingProducts: boolean;
  isLoadingForm: boolean;
  isLoadingTableScan: boolean;
  dataCustomers: SelectOptionsInterface[];
  dataProducts: SelectOptionsInterface[];
  handleGetCountingScan: () => void;
  dataCountingScan: DataCountingScanType;
  handleDownloadCounting: (condition: string) => void;
  handleGetTableScan: (page?: number, search?: string) => void;
  dataTableScanManifest: DataTableResProps | null;
  isShowTable: boolean;
  handleDeleteScan: (id?: number) => void;
  handleAddScan: () => void;
  responseAddScan: ResponseAddScanType;
};

const ContentAction = ({
  onClick,
  isLink = false,
  label,
  children,
}: {
  onClick?: () => void;
  isLink?: boolean;
  label?: string;
  children?: React.ReactNode;
}) => {
  return (
    <>
      {label && (
        <p
          className={cx(isLink && styles.wrapperActionLink, styles.labelAction)}
          onClick={onClick}
        >
          {label}
        </p>
      )}
      {children && children}
    </>
  );
};

const ActionScan = ({
  label,
  children,
  isLink = false,
  disabled = false,
  onClickDownload,
  onClick = () => undefined,
  title,
}: {
  title?: string;
  label?: string;
  children?: React.ReactNode;
  isLink?: boolean;
  disabled?: boolean;
  onClickDownload?: () => void;
  onClick?: () => void;
}) => {
  return (
    <Col
      span={8}
      className={cx(disabled && styles.actionDisabled, styles.wrapperAction)}
    >
      {isLink ? (
        <ContentAction
          label={label}
          children={children}
          onClick={() => !disabled && onClick()}
          isLink
        />
      ) : (
        <Popconfirm
          placement="topLeft"
          title={title}
          okText="YA"
          onConfirm={onClickDownload}
          cancelText="Tidak"
          disabled={disabled}
        >
          <div className={styles.wrapperAction}>
            <ContentAction label={label} children={children} />
          </div>
        </Popconfirm>
      )}
    </Col>
  );
};

export default function ScanManifestComponent({
  isLoadingForm = false,
  isLoadingTableScan = false,
  dataScan,
  handleChangeData,
  dataCustomers,
  dataProducts,
  isLoadingCustomer,
  isLoadingProducts,
  handleGetCountingScan,
  dataCountingScan,
  handleDownloadCounting,
  handleGetTableScan,
  isShowTable,
  dataTableScanManifest,
  handleDeleteScan,
  handleAddScan,
  responseAddScan,
}: ScanManifestComponentProps) {
  const [searchTable, setSearchTable] = useState<string>('');
  const pagePagination = (dataTableScanManifest?.page || 0) + 1 || 0;
  const totalPagination = (dataTableScanManifest?.totalPages || 0) * 10 || 0;
  const looppage = (pagePagination - 1) * 10 + 1;
  const columns = [
    {
      title: 'No',
      render: (text: any, record: any, index: any) => (
        <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
      ),
      width: 80,
    },
    {
      title: 'Waybill',
      dataIndex: 'waybill',
      align: 'center' as ColumnAlignTable,
    },
    {
      title: 'Barcode',
      dataIndex: 'barcode',
      width: 150,
      align: 'center' as ColumnAlignTable,
    },
    {
      title: 'Produk',
      dataIndex: 'customerProduct',
      align: 'center' as ColumnAlignTable,
    },
    {
      title: 'Tanggal Cycle',
      dataIndex: 'cycle',
      align: 'center' as ColumnAlignTable,
    },
    {
      title: 'Nama',
      dataIndex: 'ownerName',
      align: 'center' as ColumnAlignTable,
    },
    {
      title: 'Aksi',
      key: 'aksi',
      width: 80,
      align: 'center' as ColumnAlignTable,
      render: (record: any) => (
        <div style={{ display: 'flex', gap: 8 }}>
          <Button
            icon="eye"
            variant="info"
            onClick={() => openNewTab('/delivery/status?' + record.waybill)}
          />
          <Button
            icon="delete"
            variant="danger"
            onClick={() => handleDeleteScan(record.id)}
          />
        </div>
      ),
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        paddingBottom: 24,
      }}
    >
      <SpinMaterial spinning={isLoadingForm} size="large">
        <CardMaterial
          title={
            <b style={{ color: '#11BEFF' }}>
              Tanda Terima Pengembalian Dokumen Pelanggan
            </b>
          }
          style={{ borderRadius: '10px' }}
        >
          <Form
            initialValues={{ ...dataScan }}
            validate={values => {
              const errors = {} as any;
              if (!values.dateCycle) {
                errors.dateCycle = 'Tanggal cycle wajib diisi';
              }
              if (!values.treatmentDocument) {
                errors.treatmentDocument = 'Perlakuan Dokumen wajib diisi';
              }
              if (!values.customer) {
                errors.customer = 'Pelanggan wajib diisi';
              }
              if (!values.product || values.product.length === 0) {
                errors.product = 'Produk wajib diisi';
              }
              return errors;
            }}
            onSubmit={() => {}}
          >
            {({ values, valid }) => (
              <Row gutter={[24, 24]}>
                <Col span={24}>
                  <Field name="typeScan">
                    {({ input }) => (
                      <RadioField
                        value={input.value}
                        onChange={value => handleChangeData('typeScan', value)}
                        data={[
                          { value: 'Success', label: 'Sukses' },
                          { value: 'Return', label: 'Return' },
                        ]}
                      />
                    )}
                  </Field>
                </Col>
                <Col
                  span={12}
                  style={{ gap: 24, display: 'flex', flexDirection: 'column' }}
                >
                  <Field name="customer">
                    {({ input, meta }) => (
                      <SelectField
                        label="Pelanggan"
                        placeholder="Pilih Pelanggan"
                        value={input.value}
                        data={dataCustomers}
                        onChange={value => {
                          handleChangeData('customer', value);
                          handleChangeData('product', []);
                        }}
                        onBlur={input.onBlur}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        loading={isLoadingCustomer}
                        errorMessage={meta.error && meta.touched && meta.error}
                        validate
                      />
                    )}
                  </Field>
                  <Field name="product">
                    {({ input, meta }) => (
                      <SelectField
                        label="Produk"
                        placeholder="Pilih Produk"
                        mode="multiple"
                        allowClear
                        data={dataProducts}
                        loading={isLoadingProducts}
                        disabled={!values.customer}
                        value={input.value}
                        onChange={value => handleChangeData('product', value)}
                        onBlur={input.onBlur}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                        validate
                      />
                    )}
                  </Field>
                  <Field name="waybill">
                    {input => (
                      <div
                        style={{
                          display: 'flex',
                          gap: 8,
                          alignItems: 'flex-end',
                        }}
                      >
                        <InputText
                          label={values.isBarcode ? 'Barcode' : 'Waybill'}
                          placeholder={`Scan ${
                            values.isBarcode ? 'Barcode' : 'Waybill'
                          }`}
                          formStyle={{ margin: 0 }}
                          disabled={!valid}
                          additionalHandleEnterFunc={() => handleAddScan()}
                          value={dataScan.waybill}
                          onChange={value => handleChangeData('waybill', value)}
                          {...input}
                        />
                        <Field name="isBarcode">
                          {({ input: inputIsBarcode }) => (
                            <SingleCheckbox
                              labelRight="Barcode"
                              value={inputIsBarcode.value}
                              onChange={() =>
                                handleChangeData(
                                  'isBarcode',
                                  !inputIsBarcode.value
                                )
                              }
                            />
                          )}
                        </Field>
                      </div>
                    )}
                  </Field>
                </Col>
                <Col
                  span={12}
                  style={{ gap: 24, display: 'flex', flexDirection: 'column' }}
                >
                  <Field
                    name="dateCycle"
                    render={({ input, meta }) => (
                      <div
                        className="group-addon-fefore"
                        style={{
                          display: 'flex',
                          gap: 8,
                          alignItems: 'flex-end',
                        }}
                      >
                        <InputDatePicker
                          label="Tanggal"
                          placeholder="Pilih Tanggal"
                          mode={values.isMonthly ? 'month' : 'date'}
                          error={meta.error && meta.touched && meta.error}
                          onBlur={input.onBlur}
                          value={input.value}
                          onChange={value =>
                            handleChangeData('dateCycle', value)
                          }
                          addonBefore={
                            <Field name="typeDate">
                              {({ input: inputType }) => (
                                <SelectField
                                  value={inputType.value}
                                  data={archiveDateTypes}
                                  style={{ width: 100 }}
                                  onChange={value =>
                                    handleChangeData('typeDate', value)
                                  }
                                />
                              )}
                            </Field>
                          }
                        />
                        <Field name="isMonthly">
                          {({ input: inputIsMonthly }) => {
                            const isErrorDate =
                              meta.error && meta.touched && meta.error;
                            return (
                              <SingleCheckbox
                                labelRight="Bulanan"
                                formStyle={{
                                  marginBottom: isErrorDate ? 23 : 4,
                                }}
                                value={inputIsMonthly.value}
                                onChange={() =>
                                  handleChangeData(
                                    'isMonthly',
                                    !inputIsMonthly.value
                                  )
                                }
                              />
                            );
                          }}
                        </Field>
                      </div>
                    )}
                  />
                  <Field name="treatmentDocument">
                    {({ input, meta }) => (
                      <SelectField
                        label="Perlakuan Dokumen"
                        placeholder="Pilih Perlakuan Dokumen"
                        value={input.value}
                        onBlur={input.onBlur}
                        data={treatmentDocument}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        onChange={value =>
                          handleChangeData('treatmentDocument', value)
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                        validate
                      />
                    )}
                  </Field>
                  <div className={styles.wrapperRefresh}>
                    <Row gutter={[24, 24]} justify="center">
                      <ActionScan
                        label="Sudah di-scan:"
                        disabled={
                          !dataCountingScan.isSuccessScan ||
                          !dataCountingScan.scan
                        }
                        title="Download Yang Sudah di Scan"
                        onClickDownload={() => handleDownloadCounting('Scan')}
                      >
                        <p style={{ color: '#00AB44' }}>
                          {dataCountingScan.scan}
                        </p>
                      </ActionScan>
                      <ActionScan
                        label="Belum di-scan:"
                        disabled={
                          !dataCountingScan.isSuccessScan ||
                          !dataCountingScan.unScan
                        }
                        title="Download Yang Belum di Scan"
                        onClickDownload={() => handleDownloadCounting('UnScan')}
                      >
                        <p style={{ color: '#EA1A1A' }}>
                          {dataCountingScan.unScan}
                        </p>
                      </ActionScan>
                      <ActionScan
                        label="Total:"
                        disabled={
                          !dataCountingScan.isSuccessScan ||
                          !dataCountingScan.total
                        }
                        title="Download Total"
                        onClickDownload={() => handleDownloadCounting('Total')}
                      >
                        <p>{dataCountingScan.total}</p>
                      </ActionScan>
                    </Row>
                    <Button
                      icon="reload"
                      disabled={!valid}
                      onClick={handleGetCountingScan}
                      tooltip="Muat ulang perhitungan"
                    />
                  </div>
                  <Row gutter={[24, 24]} justify="center">
                    <ActionScan
                      label="Tampilkan Table"
                      isLink
                      disabled={!valid}
                      onClick={() => handleGetTableScan(0)}
                    />
                    <ActionScan
                      label="Di Scan User:"
                      disabled={
                        !dataCountingScan.isSuccessScan ||
                        !dataCountingScan.userScan
                      }
                      title="Download Di Scan User"
                      onClickDownload={() => handleDownloadCounting('ScanUser')}
                    >
                      <p>
                        <span style={{ color: '#00AB44' }}>
                          {dataCountingScan.userScan}
                        </span>
                        /{dataCountingScan.total}
                      </p>
                    </ActionScan>
                  </Row>
                </Col>
                {responseAddScan.isShowResponse && (
                  <Col span={24} className={styles.wrapperWaybill}>
                    <p>{responseAddScan.waybill}</p>
                    <p
                      style={{
                        color: responseAddScan.isSuccess
                          ? '#31E116'
                          : '#EA1A1A',
                      }}
                    >
                      {responseAddScan.message}
                    </p>
                  </Col>
                )}
              </Row>
            )}
          </Form>
        </CardMaterial>
      </SpinMaterial>

      <SpinMaterial spinning={isLoadingTableScan} size="large">
        {isShowTable && (
          <>
            <CardMaterial>
              <Row>
                <Col span={16}>
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: 16 }}
                  >
                    <span className="titleSearchCard" style={{ padding: 0 }}>
                      SEMUA DATA
                    </span>

                    <SearchMatrial
                      style={{ borderRadius: '10px', width: '50%' }}
                      placeholder="Cari berdasarkan waybill, barcode, dan nama"
                      value={searchTable}
                      onChange={e => setSearchTable(trimText(e.target.value))}
                      onPressEnter={e => {
                        handleGetTableScan(0, searchTable);
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </CardMaterial>
            <Table
              pagination={{
                onChange: page => {
                  handleGetTableScan(page - 1, searchTable);
                },
                pageSize: 10,
                total: totalPagination,
                current: pagePagination === 0 ? 1 : pagePagination,
              }}
              bordered={false}
              dataSource={dataTableScanManifest?.data || []}
              columns={columns}
            />
          </>
        )}
      </SpinMaterial>
    </div>
  );
}
