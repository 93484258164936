export function clearAllFieldBranch() {
  return {
    type: 'CLEAR_ALL_DETAIL_BRANCH',
  };
}
export function clearPostalCode() {
  return {
    type: 'CLEAR_POSTAL_CODE',
  };
}
export function clearBranchProvince() {
  return {
    type: 'BRANCH_BRANCH_PROVINCE',
  };
}
export function clearBranchCity() {
  return {
    type: 'CLEAR_BRANCH_CITY',
  };
}
export function clearBranchSubDistrict() {
  return {
    type: 'CLEAR_BRANCH_SUBDISTRICT',
  };
}
export function clearBranchVillage() {
  return {
    type: 'CLEAR_BRANCH_VILLAGE',
  };
}
export function clearAdminBranch() {
  return {
    type: 'CLEAR_ADMIN_BRANCH',
  };
}
export function clearCheckboxChooseAdmin() {
  return {
    type: 'CLEAR_CHECKBOX_CHOOSE_ADMIN',
  };
}
export function clearNameAdminBranch() {
  return {
    type: 'CLEAR_NAME_ADMIN_BRANCH',
  };
}
export function selectAdminBranch() {
  return {
    type: 'SELECT_ADMIN_BRANCH',
  };
}
export function getSelectAdminBranch() {
  return {
    type: 'GET_SELECT_ADMIN_BRANCH',
  };
}
export function getNameAdminBranch() {
  return {
    type: 'GET_NAME_ADMIN_BRANCH',
  };
}
export function changeGroupId(GroupBranchId: any) {
  return {
    type: 'CHANGE_GROUP_BRANCH_ID',
    GroupBranchId,
  };
}
export function changeGroupName(GroupName: any) {
  return {
    type: 'CHANGE_GROUP_NAME_B',
    GroupName,
  };
}
export function changeGroupNameBranch(GroupNameBranch: any) {
  return {
    type: 'CHANGE_GROUP_NAME_BRANCH',
    GroupNameBranch,
  };
}
export function setNameBranch(NameBranch: any) {
  return {
    type: 'SET_NAME_BRANCH',
    NameBranch,
  };
}

export function setAreaCode(AreaCode: any) {
  return {
    type: 'SET_AREA_CODE',
    AreaCode,
  };
}

export function setAddress(Address: any) {
  return {
    type: 'SET_ADDRESS',
    Address,
  };
}

export function setPhoneNumber(PhoneNumber: any) {
  return {
    type: 'SET_PHONE_NUMBER',
    PhoneNumber,
  };
}

export function setBranchProvince(Province: any) {
  return {
    type: 'SET_BRANCH_PROVINCE',
    Province,
  };
}

export function setBranchCity(City: any) {
  return {
    type: 'SET_BRANCH_CITY',
    City,
  };
}

export function setBranchSubDistrict(SubDistrict: any) {
  return {
    type: 'SET_BRANCH_SUBDISTRICT',
    SubDistrict,
  };
}

export function setBranchVillage(Village: any) {
  return {
    type: 'SET_BRANCH_VILLAGE',
    Village,
  };
}

export function setAdminBranch(AdminBranch: any) {
  return {
    type: 'SET_ADMIN_BRANCH',
    AdminBranch,
  };
}

export function setNameAdminBranch(NameAdminBranch: any) {
  return {
    type: 'SET_NAME_ADMIN_BRANCH',
    NameAdminBranch,
  };
}

export function setPostalCode(PostalCode: any) {
  return {
    type: 'SET_POSTAL_CODE',
    PostalCode,
  };
}
export function setLatitudeBranch(LatitudeBranch: any) {
  return {
    type: 'SET_LATITUDE_BRANCH',
    LatitudeBranch,
  };
}
export function setLongitudeBranch(LongitudeBranch: any) {
  return {
    type: 'SET_LONGITUDE_BRANCH',
    LongitudeBranch,
  };
}
export function setPartner() {
  return {
    type: 'SET_PARTNER',
  };
}
export function getGroupNameList() {
  return {
    type: 'GET_GROUP_NAME_BRANCH_LIST',
  };
}
export function setGroupNameList(ListGroupName: any) {
  return {
    type: 'SET_GROUP_NAME_BRANCH_LIST',
    ListGroupName,
  };
}
export function submitGroupNameBranch() {
  return {
    type: 'SUBMIT_GROUP_NAME_BRANCH',
  };
}
export function submitEditGroupNameBranch() {
  return {
    type: 'SUBMIT_EDIT_GROUP_NAME_BRANCH',
  };
}
export function clearGroupNameList() {
  return {
    type: 'CLEAR_GROUP_NAME_BRANCH_LIST',
  };
}
export function clearBranchGroupFrom() {
  return {
    type: 'CLEAR_BRANCH_GROUP_FORM',
  };
}
export function clearBranchGroupName() {
  return {
    type: 'CLEAR_BRANCH_GROUP_NAME',
  };
}
