import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Modal } from 'antd';

import ScanManifestComponent from '../components';
import {
  getAllCustomers,
  getProductByCustomers,
} from 'services/GeneralService';
import {
  getCountingScanManifest,
  downloadResultCounting,
  getTableScanManifest,
  deleteScanManifest,
  addScanManifest,
} from 'services/ManifestScanService';
import { SelectOptionsInterface, DataTableResProps } from 'interface/general';
import ModalStatus from 'materials/ModalStatus';
import ModalDownloadProgress from 'materials/ModalDownloadProgress';
import ModalConfirmation from 'components/ModalConfirmation';
import ModalSelectBarcodeMultiple from 'components/ModalSelectBarcodeMultiple';
import {
  downloadBlob,
  processProgressRequest,
  getNameFileCountingManifest,
} from 'lib/util';

export type DataScanType = {
  typeScan: string;
  customer: number | null;
  product: number[];
  typeDate: string;
  dateCycle: any;
  isMonthly: boolean;
  treatmentDocument: string;
  waybill: string;
  isBarcode: boolean;
};

export type DataCountingScanType = {
  isSuccessScan: boolean;
  scan: number;
  userScan: number;
  unScan: number;
  total: number;
};

export type ResponseAddScanType = {
  isShowResponse: boolean;
  isSuccess: boolean;
  waybill: string;
  message: string;
};

const defaultDataScan = {
  typeScan: 'Success',
  customer: null,
  product: [],
  typeDate: 'Cycle',
  dateCycle: null,
  isMonthly: false,
  treatmentDocument: '',
  waybill: '',
  isBarcode: false,
};

const defaultCountingScan = {
  isSuccessScan: false,
  scan: 0,
  userScan: 0,
  unScan: 0,
  total: 0,
};

const defaultResponseAddScan = {
  isShowResponse: false,
  isSuccess: false,
  waybill: '',
  message: '',
};

function ScanManifest({ username }: { username: string }) {
  const [dataScan, setDataScan] = useState<DataScanType>(defaultDataScan);
  const [dataCustomers, setDataCustomers] = useState<SelectOptionsInterface[]>(
    []
  );
  const [dataProducts, setDataProducs] = useState<SelectOptionsInterface[]>([]);
  const [dataTableScanManifest, setDataTableScanManifest] =
    useState<DataTableResProps | null>(null);
  const [isShowTable, setIsShowTable] = useState<boolean>(false);
  const [selectedIdScan, setSelectedIdScan] = useState<any>(null);
  const [dataCountingScan, setDataCountingScan] = useState(defaultCountingScan);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [responseAddScan, setResponseAddScan] = useState<ResponseAddScanType>(
    defaultResponseAddScan
  );
  const [isShowModalMultipleWaybill, setIsShowModalMultipleWaybill] =
    useState<boolean>(false);
  const [dataWaybillMultiple, setDataWaybillMultiple] = useState<any[]>([]);

  const progressHandler = processProgressRequest(setLoadingProgress);

  const { isFetching: isLoadingCustomer } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataCustomers'],
    queryFn: () => getAllCustomers(),
    onSuccess(data) {
      const datas = [] as any;
      data?.data?.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataCustomers(datas);
    },
    refetchOnWindowFocus: false,
  });

  const { isFetching: isLoadingProducts } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataProducts', { id: dataScan.customer }],
    queryFn: ({ queryKey }) => getProductByCustomers(queryKey),
    onSuccess(data) {
      const datas = [{ id: 0, name: 'Pilih Semua' }] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
          productType: item.productType,
        });
      });
      setDataProducs(datas);
    },
    enabled: !!dataScan.customer,
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateAddScan, isLoading: isLoadingAddScan } = useMutation(
    addScanManifest,
    {
      onSuccess: res => {
        if (res.status === 200) {
          if (!!res?.data?.isMultiple) {
            setIsShowModalMultipleWaybill(!isShowModalMultipleWaybill);
            setDataWaybillMultiple(res?.data?.data || []);
          } else {
            handleChangeData('waybill', '');
            setIsShowModalMultipleWaybill(false);
            setDataWaybillMultiple([]);
            handleGetCountingScan();
            handleGetTableScan();
            setResponseAddScan({
              isShowResponse: true,
              isSuccess: true,
              waybill: dataScan.waybill,
              message: 'Berhasil Ditambahkan',
            });
          }
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
          setResponseAddScan({
            isShowResponse: true,
            isSuccess: false,
            waybill: dataScan.waybill,
            message: 'Gagal Ditambahkan',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    }
  );

  const { mutate: mutateCountingScan, isLoading: isLoadingCountingScan } =
    useMutation(getCountingScanManifest, {
      onSuccess: res => {
        if (res.status === 200) {
          setDataCountingScan({ ...res.data, isSuccessScan: true });
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const { mutate: mutateTableScan, isLoading: isLoadingTableScan } =
    useMutation(getTableScanManifest, {
      onSuccess: res => {
        if (res.status === 200) {
          setDataTableScanManifest(res.data);
          setIsShowTable(true);
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const { mutate: mutateDeleteScan, isLoading: isLoadingDeleteScan } =
    useMutation(deleteScanManifest, {
      onSuccess: res => {
        if (res.status === 200) {
          handleGetTableScan();
          setSelectedIdScan(null);
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const { mutate: mutateDownloadFile, isLoading: isLoadingDownload } =
    useMutation((data: any) => downloadResultCounting(data, progressHandler), {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          downloadBlob(
            res.data,
            getNameFileCountingManifest(res.fileName),
            'xls'
          );
          ModalStatus({
            status: 'success',
            title: 'Download berhasil',
          });
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });

  useEffect(() => {
    if (loadingProgress === 100) {
      Modal.destroyAll();
      setLoadingProgress(0);
    }
  }, [loadingProgress]);

  const handleChangeData = (name: string, value: any) => {
    setDataScan(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleGetCountingScan = () => {
    mutateCountingScan({
      dateCycle: dataScan.dateCycle.format(
        dataScan.isMonthly ? 'YYYYMM' : 'YYYYMMDD'
      ),
      customer: dataScan.customer,
      username: username,
      isMonthly: dataScan.isMonthly,
      typeDate: dataScan.typeDate,
      productIds: dataScan.product,
      deliveryStatus: dataScan.typeScan,
    });
  };

  const handleGetTableScan = (page = 0, search = '') => {
    mutateTableScan({
      dateCycle: dataScan.dateCycle.format(
        dataScan.isMonthly ? 'YYYYMM' : 'YYYYMMDD'
      ),
      customer: dataScan.customer,
      username: username,
      isMonthly: dataScan.isMonthly,
      page,
      deliveryStatus: dataScan.typeScan,
      typeDate: dataScan.typeDate,
      productIds: dataScan.product,
      search,
    });
  };

  const handleDownloadCounting = (condition = 'Scan') => {
    mutateDownloadFile({
      conditionResultManifest: condition,
      filterDateType: dataScan.typeDate,
      deliveryStatus: dataScan.typeScan,
      date: dataScan.dateCycle.format(
        dataScan.isMonthly ? 'YYYYMM' : 'YYYYMMDD'
      ),
      customerId: dataScan.customer,
      customerProductIds: dataScan.product,
      monthly: dataScan.isMonthly,
      userCode: username,
    });
  };

  const handleDeleteScan = (id: number) => {
    mutateDeleteScan({
      id,
    });
  };

  const handleAddScan = () => {
    mutateAddScan({
      isBarcode: dataScan.isBarcode,
      barcodeOrWaybill: [dataScan.waybill],
      deliveryStatus: dataScan.typeScan,
      date: dataScan.dateCycle.format(
        dataScan.isMonthly ? 'YYYYMM' : 'YYYYMMDD'
      ),
      filterDateType: dataScan.typeDate,
      customerId: dataScan.customer,
      customerProductIds: dataScan.product,
      isMonth: dataScan.isMonthly,
      treatmentDocument: dataScan.treatmentDocument,
      userCode: username,
    });
  };

  const handleAddScanMultiple = (waybill: any[]) => {
    mutateAddScan({
      isBarcode: false,
      barcodeOrWaybill: waybill,
      deliveryStatus: dataScan.typeScan,
      date: dataScan.dateCycle.format(
        dataScan.isMonthly ? 'YYYYMM' : 'YYYYMMDD'
      ),
      filterDateType: dataScan.typeDate,
      customerId: dataScan.customer,
      customerProductIds: dataScan.product,
      isMonth: dataScan.isMonthly,
      treatmentDocument: dataScan.treatmentDocument,
      userCode: username,
    });
  };

  const handleShowModalMultipleWaybill = () => {
    setDataWaybillMultiple([]);
    setIsShowModalMultipleWaybill(!isShowModalMultipleWaybill);
  };

  return (
    <>
      <ScanManifestComponent
        dataScan={dataScan}
        handleChangeData={handleChangeData}
        isLoadingCustomer={isLoadingCustomer}
        isLoadingProducts={isLoadingProducts}
        isLoadingTableScan={isLoadingTableScan || isLoadingDeleteScan}
        dataCustomers={dataCustomers}
        dataProducts={dataProducts}
        isLoadingForm={
          isLoadingCountingScan || isLoadingDownload || isLoadingAddScan
        }
        handleGetCountingScan={handleGetCountingScan}
        dataCountingScan={dataCountingScan}
        handleDownloadCounting={handleDownloadCounting}
        handleGetTableScan={handleGetTableScan}
        dataTableScanManifest={dataTableScanManifest}
        isShowTable={isShowTable}
        handleDeleteScan={setSelectedIdScan}
        handleAddScan={handleAddScan}
        responseAddScan={responseAddScan}
      />
      <ModalDownloadProgress
        loading={isLoadingDownload}
        loadingProgress={loadingProgress}
      />
      <ModalConfirmation
        title="Anda yakin, Ingin menghapus ?"
        visibleModal={!!selectedIdScan}
        onConfirm={() => setSelectedIdScan(null)}
        onCancel={() => handleDeleteScan(selectedIdScan)}
        centered
        cancelText="YA"
        okeText="TIDAK"
        isLoadingYa={isLoadingDeleteScan}
      />
      <ModalSelectBarcodeMultiple
        isShowModal={isShowModalMultipleWaybill}
        handleCloseModal={handleShowModalMultipleWaybill}
        dataScanBarcode={dataWaybillMultiple}
        handleSubmit={value => handleAddScanMultiple(value)}
        isLoadingScan={isLoadingAddScan}
      />
    </>
  );
}

const mapStateToProps = (state: any) => ({
  username: state.Auth.UsernameAuth,
});

const mapDispatchToProps = (dispatch: any) => ({});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ScanManifest);
