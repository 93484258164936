import { createSelector } from 'reselect';

const selectorComponent = (state: any) => state.ComponentState;
const selectorNavigation = (state: any) => state.Navigation;
const selectorRouterHistory = (state: any) => state.router;
const selectorAuth = (state: any) => state.Auth;

export const makeSidebarIsColapseSelector = () =>
  createSelector(selectorComponent, state => state.sidebarIsColapse);
export const makeIsLoading = () =>
  createSelector(selectorComponent, state => state.isLoading);
export const makeCountingNotif = () =>
  createSelector(selectorComponent, state => state.countingNotif);
export const makePagePagination = () =>
  createSelector(selectorComponent, state => state.pagePagination);
export const makeTotalPagePagination = () =>
  createSelector(selectorComponent, state => state.totalPagePagination);
export const makeSizeDataPagination = () =>
  createSelector(selectorComponent, state => state.sizeDataPagination);

// SELECTOR NAVIGATION ROUTER
export const makeIsRouter = () =>
  createSelector(selectorNavigation, state => state.router);

// SELECTOR ROUTER HISTORY
export const makeIsRouterHistory = () =>
  createSelector(selectorRouterHistory, state => state.location.pathname);
export const makeIsLocationSearch = () =>
  createSelector(selectorRouterHistory, state => state.location.search);

// SELECTOR AUTH
export const makeIsAuthRole = () =>
  createSelector(selectorAuth, state => state.Role);
export const makeIsAuthUsername = () =>
  createSelector(selectorAuth, state => state.UsernameAuth);
export const makeIsAuthBranch = () =>
  createSelector(selectorAuth, state => state.BranchAuth);
export const makeIsAuthStatusTokenRefresh = () =>
  createSelector(selectorAuth, state => state.StatusTokenRefresh);
export const makeIsAuthToken = () =>
  createSelector(selectorAuth, state => state.Token);

// SELECTOR MODAL
export const resultUploadModalIsShow = () =>
  createSelector(selectorComponent, state => state.showModalResultUpload);
export const notificationModalIsShow = () =>
  createSelector(selectorComponent, state => state.showModalNotification);
export const assignCourierModalIsShow = () =>
  createSelector(selectorComponent, state => state.showModalAssignCourier);
export const createComplaintModalIsShow = () =>
  createSelector(selectorComponent, state => state.showModalComplaint);
export const editComplaintModalIsShow = () =>
  createSelector(selectorComponent, state => state.showModalComplaintEdit);
export const assignCourierComplaintModalIsShow = () =>
  createSelector(
    selectorComponent,
    state => state.showModalAssignCourierComplaint
  );
export const chooseBarcodeManifestModalIsShow = () =>
  createSelector(
    selectorComponent,
    state => state.showModalChooseBarcodeManifest
  );
export const uploadForAssignModalIsShow = () =>
  createSelector(
    selectorComponent,
    state => state.showModalUploadForAssignCourier
  );
