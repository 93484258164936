export function changeWaybill(waybill: any) {
    return {
      type: 'CHANGE_WAYBILL_RS',
      waybill,
    };
  }
export function changeDeliveryDate(deliveryDate: any) {
    return {
      type: 'CHANGE_DELIVERY_DATE_RS',
      deliveryDate,
    };
  }
export function changeName(name: any) {
    return {
      type: 'CHANGE_NAME_RS',
      name,
    };
  }
export function changePhoneNumber(phoneNumber: any) {
    return {
      type: 'CHANGE_PHONE_NUMBER_RS',
      phoneNumber,
    };
  }
export function changeAddress(address: any) {
    return {
      type: 'CHANGE_ADDRESS_RS',
      address,
    };
  }
export function changeConvertAddress(converAddress: any) {
    return {
      type: 'CHANGE_CONVERT_ADDRESS_RS',
      converAddress,
    };
  }
export function changeDisableForm(disableForm: any) {
    return {
      type: 'CHANGE_DISABLE_FORM_RS',
      disableForm,
    };
  }
export function clearhWaybill() {
    return {
      type: 'CLEAR_WAYBILL_RS',
    };
  }
export function clearhAddress() {
    return {
      type: 'CLEAR_ADDRESS_RS',
    };
  }
export function clearFormExceptProperties() {
    return {
      type: 'CLEAR_FORM_EXCEPT_PROPERTIES_RS',
    };
  }
export function clearDeliveryDate() {
    return {
      type: 'CLEAR_DELIVERY_DATE_RS',
    };
  }
export function clearClearAllForm() {
    return {
      type: 'CLEAR_ALL_FORM_RS',
    };
  }
export function searchWaybill() {
    return {
      type: 'SEARCH_WAYBILL_RS',
    };
  }
export function generateNewWaybill() {
    return {
      type: 'GENERATE_NEW_WAYBILL_RS',
    };
  }