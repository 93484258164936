import * as GenerateAdminCode from '../../../../core/action/ManageAccount/AdminBranch/ActionGenerateAdminBranch';
import * as addAdmin from '../../../../core/action/ManageAccount/AdminBranch/ActionAddAdminBranch';
import * as formAdmin from '../../../../core/action/ManageAccount/AdminBranch/ActionFormDetailAdminBranch';
import * as toggle from '../../../../core/action/toggle';
import * as detailAdmin from '../../../../core/action/ManageAccount/AdminBranch/ActionIdAdminBranch';
import ModalStatus from 'materials/ModalStatus';
import { putAdminBranch } from 'services/AccountServices';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { useMutation, useQuery } from '@tanstack/react-query';

import EditAdminBranchComponent from 'components/Content/ManageAccount/AdminBranch/EditAdminBranchComponent';
import { connect } from 'react-redux';
import moment from 'moment';
import { getDetailAdmin } from 'services/AccountServices';
import { AxiosResponse } from 'axios';
import { FormAdminType } from 'components/Content/ManageAccount/AdminBranch/FormAdminComponent';

interface IProps {
  actionToggle: any;
  visible: any;
  jenisToggle: any;
  actionFormAdmin: any;

  GetAdminName: any;
  AccountType: any;
  GetAdminLastName: any;
  GetAdminEmail: any;
  GetAdminPhoneNumber: any;
  GetAdminDob: any;
  GetAdminAddress: any;
  GetAdminPhoto: any;
  GetRoleAkses?: any;
  GetPosko?: any;
  GetStatus?: any;
  GetAdminCode?: any;

  addAdmin: any;
  generateAdminCode: any;

  isLoading: any;
  refetch: any;
  idAdmin: any;
}

const EditAdminContainer = (props: IProps) => {
  const defaultValue = {
    firstName: '',
    lastName: '',
    email: '',
    typeAccount: '',
    rolePermission: null,
    phone: null,
    dob: null,
    address: '',
    branchId: 0,
    status: false,
  };
  const [dataFormAdmin, setDataFormAdmin] =
    useState<FormAdminType>(defaultValue);

  const { isLoading } = useQuery<AxiosResponse, Error>({
    queryKey: ['detailAdmin', props.idAdmin],
    queryFn: () => getDetailAdmin(props.idAdmin),
    onSuccess: res => {
      const response = res.data;
      setDataFormAdmin({
        firstName: response?.firstName,
        lastName: response?.lastName,
        email: response?.email,
        typeAccount: response?.accountType,
        rolePermission: response?.rolePermission,
        phone: response?.phone,
        dob: moment(response?.dob),
        address: response?.address,
        branchId: response?.branchId,
        status: response?.isActive,
      });
    },
    enabled: !!props.idAdmin,
  });

  const { mutate: submitAdminBranch, isLoading: isLoadingSubmit } = useMutation(
    putAdminBranch,
    {
      onSuccess: res => {
        if (res.status === 200) {
          props.refetch();
          handleClear();
          ModalStatus({
            status: 'success',
            title: 'Data berhasil disimpan',
          });
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    }
  );

  const handleChange = (name: string, value: any) => {
    setDataFormAdmin(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClear = () => {
    props.actionToggle.changeModal();
    props.actionToggle.clearJenisToggle();
    props.actionFormAdmin.changeClearDataAdminForm();
    setDataFormAdmin(defaultValue);
  };

  const handleSaveAdminBranch = () => {
    const data = {
      ...dataFormAdmin,
      id: props.idAdmin,
      photo: null,
      code: null,
      username: null,
      password: null,
      pinCourier: 0,
      productBlock: [0],
      accountType: dataFormAdmin.typeAccount,
      isActive: dataFormAdmin.status,
    };
    submitAdminBranch(data);
  };

  return (
    <div>
      <EditAdminBranchComponent
        visible={
          props.jenisToggle === 'EditAdmin' && props.visible === true
            ? true
            : false
        }
        jenisToggle={props.jenisToggle}
        handleChange={handleChange}
        isLoading={isLoading || isLoadingSubmit}
        handleClear={handleClear}
        handleSubmit={handleSaveAdminBranch}
        initialData={dataFormAdmin}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  GetAdminName: state.AdminBranch.FormAdmin.AdminName,
  AccountType: state.AdminBranch.FormAdmin.AccountType,
  GetAdminLastName: state.AdminBranch.FormAdmin.AdminLastName,
  GetAdminEmail: state.AdminBranch.FormAdmin.AdminEmail,
  GetAdminPhoneNumber: state.AdminBranch.FormAdmin.AdminPhoneNumber,
  GetAdminDob: state.AdminBranch.FormAdmin.AdminDob,
  GetAdminAddress: state.AdminBranch.FormAdmin.AdminAddress,
  GetAdminPhoto: state.AdminBranch.FormAdmin.AdminPhoto,
  GetAdminCode: state.AdminBranch.FormAdmin.Code,
  GetRoleAkses: state.AdminBranch.FormAdmin.RolePermission,
  GetStatus: state.AdminBranch.FormAdmin.IsActive,
  isLoading: state.Toggle.isLoading,
  idAdmin: state.AdminBranch.PropertiesAdminBranch.idAdmin,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
  actionFormAdmin: bindActionCreators(formAdmin, dispatch),
  addAdmin: bindActionCreators(addAdmin, dispatch),
  IdAdmin: bindActionCreators(detailAdmin, dispatch),
  generateAdminCode: bindActionCreators(GenerateAdminCode, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EditAdminContainer);
