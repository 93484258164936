import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import FormCustomerFormatReport, {
  InitialValueFormCustomerFormat,
} from '../../components/FormCustomerFormatReport';
import {
  editCustomerFormatReport,
  detailCustomerFormatReport,
} from 'services/CustomerService';
import ModalStatus from 'materials/ModalStatus';

type EditCustomerFormatReportProps = {
  show: number | null;
  onClickCancel: () => void;
  customerName: string;
  dataCsvList: any[];
  customerId: number;
  refetch: () => void;
};

export default function EditCustomerFormatReport({
  show,
  onClickCancel,
  customerName,
  dataCsvList = [],
  customerId,
  refetch,
}: EditCustomerFormatReportProps) {
  const defaultForm = {
    isActive: false,
    nameFormatReport: '',
    formatFile: '',
    settingFile: '',
    periode: '',
    delimited: '',
    disburseData: '',
    noHeader: false,
    showInPortal: false,
  };
  const [customerFormatData, setCustomerFormatData] =
    useState<InitialValueFormCustomerFormat>(defaultForm);

  const { isFetching: isLoadingDetail } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataDetailCustomerConfig', show],
    queryFn: () => detailCustomerFormatReport(show),
    enabled: !!show,
    refetchOnWindowFocus: false,
    onSuccess: res => {
      if (res.data) {
        const response = res.data;
        setCustomerFormatData(prevState => ({
          ...prevState,
          nameFormatReport: response.name,
          formatFile: response.format,
          settingFile: response.customCsvId,
          periode: response.periode,
          delimited: response.delimited,
          noHeader: response.headerHide,
          showInPortal: response.reportPortal,
          isActive: response.isActive,
          disburseData: response.customReportType,
        }));
      }
    },
  });

  const { mutate: mutateFormatReport, isLoading } = useMutation(
    editCustomerFormatReport,
    {
      onSuccess: res => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Berhasil mengubah format laporan',
          });
          refetch();
          onClickCancel();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    }
  );

  const handleOnChange = (name: string, value: any) => {
    setCustomerFormatData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClearForm = () => {
    setCustomerFormatData(defaultForm);
  };

  const handleSubmit = () => {
    const data = {
      id: show,
      name: customerFormatData.nameFormatReport,
      format: customerFormatData.formatFile,
      delimited: customerFormatData.delimited,
      periode: customerFormatData.periode,
      customerId: customerId,
      customCsvId: customerFormatData.settingFile,
      headerHide: customerFormatData.noHeader,
      reportPortal: customerFormatData.showInPortal,
      isActive: customerFormatData.isActive,
      customReportType: customerFormatData.disburseData,
    };
    mutateFormatReport(data);
  };

  return (
    <FormCustomerFormatReport
      isEdit
      isLoading={isLoading || isLoadingDetail}
      show={!!show}
      onClickCancel={() => {
        onClickCancel();
        handleClearForm();
      }}
      customerName={customerName}
      dataCsvList={dataCsvList}
      handleOnChange={handleOnChange}
      initialValue={customerFormatData}
      handleSubmit={handleSubmit}
    />
  );
}
