import { Action } from 'redux';

export interface IFetchLogActivityAction extends Action {
  data: object;
}
export function fetchLogActivityListData(
  data: object
): IFetchLogActivityAction {
  return {
    type: 'FETCH_LOG_ACTIVITY_LIST_DATA',
    data,
  };
}
export function getLogActivityDataList() {
  return {
    type: 'GET_LOG_ACTIVITY_DATA_LIST_REQUESTED',
  };
}
export function hitLogActivityLogout() {
  return {
    type: 'HIT_LOG_ACTIVITY_LOGOUT_REQUESTED',
  };
}
export function downloadLogActivityData() {
  return {
    type: 'DOWNLOAD_LOG_ACTIVITY_DATA_REQUESTED',
  };
}
export function clearLogActivityListData() {
  return {
    type: 'CLEAR_LOG_ACTIVITY_LIST_DATA',
  };
}
export function resetFormLogActivity() {
  return {
    type: 'RESET_FORM_LOG_ACTIVITY',
  };
}
