import * as ActionAuth from 'core/action/Auth';
import * as ActionLogin from 'module/Login/Store';
import * as adminDetail from 'core/action/ManageAccount/AdminBranch/ActionIdAdminBranch';
import * as toggle from 'core/action/toggle/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import ButtonDetailAdminBranchComponent from 'components/Content/ManageAccount/AdminBranch/ButtonAdminBranchComponent';
import { connect } from 'react-redux';

interface IProps {
  idAdmin: any;
  actionToggle: any;
  actionDetailAdmin: any;
  actionAuth: any;
  actionLogin: any;
  typeButton: any;
}

class ButtonDetailAdminBranchContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);

    this.handleDetailAdmin = this.handleDetailAdmin.bind(this);
    this.handleEditAdmin = this.handleEditAdmin.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  handleDetailAdmin() {
    this.props.actionDetailAdmin.changeAdminDetail(this.props.idAdmin);
    this.props.actionToggle.changeJenisToggle('DetailAdmin');
  }

  handleEditAdmin() {
    this.props.actionDetailAdmin.changeAdminDetail(this.props.idAdmin);
    this.props.actionToggle.changeJenisToggle('EditAdmin');
  }
  handleResetPassword() {
    this.props.actionDetailAdmin.changeAdminDetail(this.props.idAdmin);
    this.props.actionToggle.changeJenisToggle('ResetPasswordAdmin');
  }

  render() {
    return (
      <div>
        <ButtonDetailAdminBranchComponent
          handleDetailAdmin={this.handleDetailAdmin}
          handleEditAdmin={this.handleEditAdmin}
          handleResetPassword={this.handleResetPassword}
          typeButton={this.props.typeButton}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  actionDetailAdmin: bindActionCreators(adminDetail, dispatch),
  actionAuth: bindActionCreators(ActionAuth, dispatch),
  actionLogin: bindActionCreators(ActionLogin, dispatch),
  actionToggle: bindActionCreators(toggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ButtonDetailAdminBranchContainer);
