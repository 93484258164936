import * as ActionAuth from '../../action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionSetCustomCsvCustomer from '../../action/Customer/ActionSetCustomCsvCustomer';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { message } from 'antd';

const convertResponse = (
  base64: any,
  format: any,
  customerName: any,
  fileSettingName: any
) => {
  const extention = () => {
    if (format === 'ExcelXLSX') {
      return '.xlsx';
    } else if (format === 'TXT') {
      return '.txt';
    } else if (format === 'Excel') {
      return '.xls';
    } else {
      return '.csv';
    }
  };
  const url = `data:text/csv;base64,${base64}`;
  fetch(url)
    .then(res => res.blob())
    .then(blob => {
      const url2 = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url2;
      link.setAttribute(
        'download',
        `${customerName} ${fileSettingName}${extention()}`
      );
      document.body.appendChild(link);
      link.click();
    });
};

function* S_downloadCustomCsvCustomer(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const key = 'download';
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const IdCustomCsv = state.Customer.FormCustomCsvCustomer.IdCsv;
  const customerName = state.Customer.FormCustomCsvCustomer.CustomerNameCsv;
  const fileSettingName = state.Customer.FormCustomCsvCustomer.FileSettingName;
  try {
    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Setting/CustomCsv/ResultCsv/${IdCustomCsv}`,
      {},
      {
        headers: config,
      }
    );
    convertResponse(
      data.base64,
      data.formatFileType,
      customerName,
      fileSettingName
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    message.success({
      content: 'Download Berhasil',
      key,
      duration: 1.5,
    });
    yield put(ActionSetCustomCsvCustomer.clearAllDataCustomCsvCustomer());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    message.error({
      content: 'Download gagal',
      key,
      duration: 1.5,
    });
  }
}

export function* SM_downloadCustomCsvCustomer() {
  yield takeLatest('DOWNLOAD_CUSTOM_CSV_CUSTOMER', S_downloadCustomCsvCustomer);
}
