import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import { getTabMenus } from 'lib/util';

import DashboardContainer from './Pengiriman/DashboardContainer';
import ProgressCourierContainer from './Pengiriman/ProgressCourierContainer';
import DashboardKpi from './Kpi';

const { TabPane } = Tabs;
type DashboardProps = {
  roleAccount: string;
  sideMenus: any;
};

const Dashboard = ({ roleAccount, sideMenus }: DashboardProps) => {
  const isBranch = roleAccount === 'Branch';

  const tabMenus = getTabMenus(sideMenus || [], 'Beranda') || [];

  const componentTabMenus = {
    'Dashboard Pengiriman': isBranch ? (
      <ProgressCourierContainer />
    ) : (
      <DashboardContainer />
    ),
    'Dashboard KPI': <DashboardKpi />,
  };

  return (
    <Tabs style={{ padding: '35px' }} defaultActiveKey={'1'}>
      {tabMenus.map((item, index) => (
        <TabPane tab={<b>{item.label}</b>} key={index + 1}>
          {componentTabMenus[item.label]}
        </TabPane>
      ))}
    </Tabs>
  );
};

const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  sideMenus: state.Auth.SideMenus,
});

const withConnect = connect(mapStateToProps);
export default compose(withConnect)(Dashboard);
