import { Col, Form, Row, Select, Checkbox } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';
import DatePickerMaterial from '../../../../materials/DatePickerMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/en_US';
import moment from 'moment';

interface IProps {
  LoadingSelectBranch: any;
  TypeLoadingSelectBranch: any;
  visible: any;
  handleClearDetailCourier: any;
  jenisToggle: any;
  BranchList: any;

  ValueGetWilayah: any;

  GetCourierName: any;
  GetCourierLastName: any;
  GetCourierEmail: any;
  GetCourierPhoneNumber: any;
  StateDobCourier: any;
  GetCourierPhoto: any;
  GetCourierAddress: any;
  PutCourierCode: any;
  GetCourierCode: any;
  GetCourierAreaCode: any;
  roleAccount: any;
  branchAuth: any;
  GetCourierStatus: any;

  ValidateCourierName: any;
  ValidateCourierLastName: any;
  ValidateCourierEmail: any;
  ValidateCourierPhoneNumber: any;
  ValidateCourierDob: any;
  ValidateCourierBranchArea: any;
  ValidateCourierAddress: any;
  ValidateButton: any;

  helpCourierName: any;
  helpCourierLastName: any;
  helpCourierEmail: any;
  helpCourierPhoneNumber: any;
  helpCourierDob: any;
  helpCourierAddress: any;

  PutCourierName: any;
  PutCourierLastName: any;
  PutCourierEmail: any;
  PutCourierPhoneNumber: any;
  PutCourierDob: any;
  PutCourierPhoto: any;
  PutCourierAddress: any;
  isLoading: any;
  handleSaveCourier: any;
  handleValidateDob: any;
  handleGetBranch: any;
  handlePutAreaCode: any;
  handleGenerateCourierCode: any;
  handleBlurAreaCode: any;
  handleCheckBox: any;
}

export default class AddCourierComponent extends Component<IProps> {
  render() {
    const minDate = (d: any) =>
      !d || d.isAfter(moment().add(-17, 'year').format('YYYY-MM-DD'));
    const dateFormat = 'DD/MM/YYYY';
    const { Option } = Select;
    const BranchListValue = this.props.BranchList;

    return (
      <div>
        <ModalMaterial
          className={''}
          width={'80%'}
          style={{}}
          teksTitle={'TAMBAHKAN KURIR POSKO'}
          visible={this.props.visible}
          onCancel={this.props.handleClearDetailCourier}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div className={'spacingTopDefault'}>
                  <Row>
                    <Col span={12}>
                      <div className={'spacingColForm'}>
                        <div>
                          <span>
                            <b>Nama Depan</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetCourierName}
                            className={'borderRadiusDefault'}
                            disabled={false}
                            placeholder={'Masukan nama depan'}
                            maxLength={'30'}
                            onChange={this.props.PutCourierName}
                            onBlur={this.props.PutCourierName}
                            validatestatus={this.props.ValidateCourierName}
                            help={this.props.helpCourierName}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Kode Kurir</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetCourierCode}
                            className={'borderRadiusDefault'}
                            disabled={true}
                            placeholder={'Kode Kurir'}
                            onChange={this.props.PutCourierCode}
                            onBlur={this.props.PutCourierCode}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Tanggal Lahir</b>
                          </span>
                          <DatePickerMaterial
                            style={{ width: '100%' }}
                            locale={locale}
                            format={'DD/MM/YYYY'}
                            defaultPickerValue={moment(
                              '01/01/2002',
                              dateFormat
                            )}
                            placeholder={'01/01/2002'}
                            defaultValue={''}
                            onOpenChange={this.props.handleValidateDob}
                            value={this.props.StateDobCourier}
                            onChange={this.props.PutCourierDob}
                            validatestatus={this.props.ValidateCourierDob}
                            help={this.props.helpCourierDob}
                            disabledDate={minDate}
                            className={'datePickerDefault'}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Alamat Lengkap</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetCourierAddress}
                            className={'borderRadiusDefault'}
                            disabled={false}
                            placeholder={'Masukan alamat lengkap'}
                            maxLength={225}
                            type={'textArea'}
                            onChange={this.props.PutCourierAddress}
                            onBlur={this.props.PutCourierAddress}
                            validatestatus={this.props.ValidateCourierAddress}
                            help={this.props.helpCourierAddress}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div className={'spacingColForm'}>
                        <div>
                          <span>
                            <b>Nama Belakang</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetCourierLastName}
                            className={'borderRadiusDefault'}
                            disabled={false}
                            addonBefore={''}
                            onPressEnter={''}
                            placeholder={'Masukan nama belakang'}
                            prefix={''}
                            maxLength={'30'}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.PutCourierLastName}
                            onBlur={this.props.PutCourierLastName}
                            validatestatus={this.props.ValidateCourierLastName}
                            help={this.props.helpCourierLastName}
                          />
                        </div>

                        <span>
                          <b>Wilayah</b>
                        </span>
                        <div>
                          <Form.Item
                            validateStatus={
                              this.props.ValidateCourierBranchArea === 'error'
                                ? 'error'
                                : ''
                            }
                            help={
                              this.props.ValidateCourierBranchArea === 'error'
                                ? 'Wilayah posko wajib diisi'
                                : ''
                            }
                          >
                            <Select
                              loading={
                                this.props.LoadingSelectBranch === true &&
                                this.props.TypeLoadingSelectBranch ===
                                  'selectbranch'
                                  ? true
                                  : false
                              }
                              className={'dropdownDefault'}
                              style={{}}
                              optionFilterProp="children"
                              showSearch
                              disabled={
                                this.props.roleAccount === 'Branch'
                                  ? true
                                  : false
                              }
                              value={
                                this.props.branchAuth !== null &&
                                this.props.branchAuth !== 0
                                  ? this.props.branchAuth
                                  : this.props.ValueGetWilayah
                              }
                              onChange={this.props.handlePutAreaCode}
                              onFocus={this.props.handleGetBranch}
                              onSelect={this.props.handleGenerateCourierCode}
                              onBlur={this.props.handleBlurAreaCode}
                            >
                              {BranchListValue.map((data: any, index: any) => (
                                <Option key={index} value={data.id}>
                                  {data.name}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>

                        <div className={'formTelpDefault'}>
                          <span>
                            <b>No Telp</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetCourierPhoneNumber}
                            className={'borderRadiusDefault'}
                            disabled={false}
                            onPressEnter={''}
                            addonBefore={'+62'}
                            placeholder={'Masukan no telp'}
                            prefix={''}
                            maxLength={'15'}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.PutCourierPhoneNumber}
                            onBlur={this.props.PutCourierPhoneNumber}
                            validatestatus={
                              this.props.ValidateCourierPhoneNumber
                            }
                            help={this.props.helpCourierPhoneNumber}
                          />
                        </div>

                        <div className={'spacingBottom'}>
                          <br />
                          <Checkbox
                            disabled={
                              this.props.roleAccount === 'Branch' ? true : false
                            }
                            onChange={this.props.handleCheckBox}
                            style={{}}
                            checked={!!this.props.GetCourierStatus}
                            className={'spacingCheckboxDetailCourier'}
                          />
                          <span className={'textCheckboxItalic'}>
                            Ceklis untuk aktifkan status akun
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className={'btnSubmitModalForm'}>
                    <ButtonMaterial
                      disabled={this.props.ValidateButton}
                      handleSubmit={this.props.handleSaveCourier}
                      teksButton={'Simpan'}
                      size={''}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      style={{}}
                      icon={''}
                    />
                  </div>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
