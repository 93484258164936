export function getCourierTable()
{
    return({
        type:"GET_TABLE_COURIER"
    })
}
export function getCourierTableBranch()
{
    return({
        type:"GET_TABLE_COURIER_BRANCH"
    })
}
export function SubmitSearchCourier() {
    return({
        type: "SUBMIT_SEARCH_COURIER"
    })
}
export function setCourierTable(TableCourier:any)
{
    return({
        type:"SET_TABLE_COURIER",
        TableCourier
    })
}
export function clearCourierTable()
{
    return({
        type:"CLEAR_TABLE_COURIER"
    })
}