import React, {Component} from 'react';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import * as toggle from '../../../../core/action/toggle/index';
import * as courierDetail from '../../../../core/action/ManageAccount/Courier/ActionIdCourierBranch';
import * as ActionDetailBranch from '../../../../core/action/Branch/ActionDetailBranch';
import ButtonDetailCourierComponent from '../../../../components/Content/ManageAccount/Courier/ButtonCourierComponent';

interface IProps
{
    idCourier:any
    actionToggle:any
    actionDetailCourier:any
    typeButton:any
    actionDetailBranch:any
}

class ButtonDetailCourierContainer extends Component<IProps>
{
    constructor(props:any)
    {
        super(props)
        
        this.handleDetailCourier = this.handleDetailCourier.bind(this)
        this.handleEditCourier = this.handleEditCourier.bind(this)
        this.handleResetPassword = this.handleResetPassword.bind(this)
    }

    handleDetailCourier()
    {
        this.props.actionDetailCourier.changeCourierDetail(this.props.idCourier)
        this.props.actionToggle.changeJenisToggle("DetailCourier")
    }

    handleEditCourier()
    {
        this.props.actionDetailCourier.changeCourierDetail(this.props.idCourier)
        this.props.actionToggle.changeJenisToggle("EditCourier")
    }
    handleResetPassword() {
        this.props.actionDetailCourier.changeCourierDetail(this.props.idCourier)
        this.props.actionToggle.changeJenisToggle('ResetPasswordCourier');
    }

    render(){
        return(
            <div>
                <ButtonDetailCourierComponent
                handleDetailCourier={this.handleDetailCourier}
                handleEditCourier={this.handleEditCourier}
                handleResetPassword={this.handleResetPassword}
                typeButton={this.props.typeButton}
                />
            </div>
        )
    }
}
const mapStateToProps = (state:any)=> ({

})

const mapDispatchToProps = (dispatch:any) => ({
    actionDetailCourier : bindActionCreators(courierDetail, dispatch),
    actionToggle : bindActionCreators(toggle, dispatch),
    actionDetailBranch : bindActionCreators(ActionDetailBranch, dispatch),
})

const withConnect = connect (mapStateToProps, mapDispatchToProps)

export default compose(
    withConnect
)(ButtonDetailCourierContainer)