const ErrorMassage=(props:any)=>[
    {
        Code:400,
        TitleMassage:"Kesalahan Parameter Data !!!",
        Massage:`Cek data yang dikirimkan ke service ${props}`
    },
    {
        Code:401,
        TitleMassage:"Autentikasi Gagal !!!",
        Massage:`Autentikasi tidak di izinkan pada ${props}`
    },
    {
        Code:403,
        TitleMassage:"Tidak di izinkan !!!",
        Massage:`Akses ${props} tidak di izinkan`
    },
    {
        Code:404,
        TitleMassage:"Gagal Terhubung Ke Service !!!",
        Massage:`Cek url service pada ${props}`
    },
    {
        Code:408,
        TitleMassage:"Koneksi Lambat !!!",
        Massage:"Perikas koneksi anda"
    },
    {
        Code:500,
        TitleMassage:"Tidak Terhubung Ke Server !!!",
        Massage:"Cek kondisi server"
    }
]
export {
    ErrorMassage,
}