import * as ActionFieldCustomerProduct from '../../../../core/action/CustomerProduct/ActionFieldCustomerProduct';
import * as toggle from '../../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import GroupingReportComponent from '../../../../components/Content/Customer/Product/GroupingReportComponent';
import { connect } from 'react-redux';

interface IProps {
  getGroupProductId: any;
  getGroupName: any;
  getListGroupName: any;
  visible: any;
  typeToggle: any;
  actionFieldCustomerProduct: any;
  actionToggle: any;
}
interface IState {
  validateGroupName: any;
  page: any;
}
class GroupReportContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: null,
      validateGroupName: null,
    };
    this.handleClearGroupForm = this.handleClearGroupForm.bind(this);
    this.handlePutGroupName = this.handlePutGroupName.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGetDetailGroupName = this.handleGetDetailGroupName.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  handleClearGroupForm() {
    this.props.actionToggle.changeModal2();
    this.props.actionToggle.clearJenisToggle2();
    this.props.actionFieldCustomerProduct.clearGroupProductId();
    this.props.actionFieldCustomerProduct.clearGroupName();
    this.setState({
      validateGroupName: null,
    });
  }
  handlePutGroupName(event: any) {
    this.props.actionFieldCustomerProduct.setGroupName(event.target.value);
    if (event.target.value === '') {
      this.setState({
        validateGroupName: 'error',
      });
    } else if (event.target.value.length < 3) {
      this.setState({
        validateGroupName: 'notMinChar',
      });
    } else {
      this.setState({
        validateGroupName: '',
      });
    }
  }
  handleGetDetailGroupName(id: any, groupName: any) {
    this.props.actionFieldCustomerProduct.setGroupProductId(id);
    this.props.actionFieldCustomerProduct.setGroupName(groupName);
  }
  handleCancel() {
    this.props.actionFieldCustomerProduct.clearGroupProductId();
    this.props.actionFieldCustomerProduct.clearGroupName();
    this.setState({
      validateGroupName: null,
    });
  }
  handleSubmit() {
    if (this.props.getGroupProductId === null) {
      this.props.actionFieldCustomerProduct.submitGroupName();
    } else {
      this.props.actionFieldCustomerProduct.submitEditGroupName();
    }
  }
  render() {
    return (
      <React.Fragment>
        <GroupingReportComponent
          page={this.state.page}
          visible={
            this.props.visible === true &&
            this.props.typeToggle === 'AddNewReportGrup'
              ? true
              : false
          }
          handleClearGroupForm={this.handleClearGroupForm}
          getGroupProductId={this.props.getGroupProductId}
          getGroupName={this.props.getGroupName}
          getListGroupName={this.props.getListGroupName}
          putGroupName={this.handlePutGroupName}
          validateGroupName={
            this.props.getGroupName === '' ||
            this.state.validateGroupName === 'notMinChar' ||
            this.state.validateGroupName === 'error'
              ? 'error'
              : ''
          }
          stateValidate={this.state.validateGroupName}
          helpValidateGroupName={
            this.state.validateGroupName === 'error'
              ? 'Nama wajib diisi'
              : this.state.validateGroupName === 'notMinChar'
              ? 'Nama minimal 3 karakter'
              : ''
          }
          validateButton={
            this.props.getGroupName === null ||
            this.props.getGroupName === '' ||
            this.state.validateGroupName === 'notMinChar' ||
            this.state.validateGroupName === 'error'
              ? true
              : false
          }
          validateButtonCancel={
            this.props.getGroupProductId === null ? true : false
          }
          handleGetDetailGroupName={this.handleGetDetailGroupName}
          handleSubmit={this.handleSubmit}
          handleCancel={this.handleCancel}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  getGroupProductId: state.CustomerProduct.DetailCustomerProduct.GroupProductId,
  getGroupName: state.CustomerProduct.DetailCustomerProduct.GroupName,
  getListGroupName: state.CustomerProduct.ListGroupName,
  visible: state.Toggle.modal2,
  typeToggle: state.Toggle.jenisToggle2,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionFieldCustomerProduct: bindActionCreators(
    ActionFieldCustomerProduct,
    dispatch
  ),
  actionToggle: bindActionCreators(toggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(GroupReportContainer);
