export function changeRadioButtonCustomerReport(RadioButton: any) {
  return {
    type: 'CHANGE_RADIO_BUTTON_CUSTOMER_REPORT_FORM',
    RadioButton,
  };
}

export function changeDatePickerMonthCustomerReport() {
  return {
    type: 'CHANGE_DATE_PICKER_MONTH_CUSTOMER_REPORT_FORM',
  };
}

export function changePickupDateCustomerReport(PickupDate: any) {
  return {
    type: 'CHANGE_PICKUP_DATE_CUSTOMER_REPORT_FORM',
    PickupDate,
  };
}

export function changeCustomerIdCustomerReport(CustomerId: any) {
  return {
    type: 'CHANGE_CUSTOMER_ID_CUSTOMER_REPORT_FORM',
    CustomerId,
  };
}

export function changeCustomerNameCustomerReport(CustomerName: any) {
  return {
    type: 'CHANGE_CUSTOMER_NAME_CUSTOMER_REPORT_FORM',
    CustomerName,
  };
}

export function changeCustomerProductIdCustomerReport(CustomerProductId: any) {
  return {
    type: 'CHANGE_CUSTOMER_PRODUCT_ID_CUSTOMER_REPORT_FORM',
    CustomerProductId,
  };
}

export function changeReportFormatCustomerReport(ReportFormat: any) {
  return {
    type: 'CHANGE_REPORT_FORMAT_CUSTOMER_REPORT_FORM',
    ReportFormat,
  };
}

export function changeDeliveryStatusCustomerReport(DeliveryStatus: any) {
  return {
    type: 'CHANGE_DELIVERY_STATUS_CUSTOMER_REPORT_FORM',
    DeliveryStatus,
  };
}

export function changeTreatmentDocumentCustomerReport(TreatmentDocument: any) {
  return {
    type: 'CHANGE_TREATMENT_DOCUMENT_CUSTOMER_REPORT_FORM',
    TreatmentDocument,
  };
}

export function changeFileDataCustomerReport(FileData: any) {
  return {
    type: 'CHANGE_FILE_DATA_CUSTOMER_REPORT_FORM',
    FileData,
  };
}

export function changeIndexColumnCustomerReport(IndexColumn: any) {
  return {
    type: 'CHANGE_INDEX_COLUMN_CUSTOMER_REPORT_FORM',
    IndexColumn,
  };
}

export function changeListStatusSuccessCustomerReport(ListStatusSuccess: any) {
  return {
    type: 'CHANGE_LIST_STATUS_SUCCESS_CUSTOMER_REPORT_FORM',
    ListStatusSuccess,
  };
}

export function changeStatusSuccessCustomerReport(StatusSuccess: any) {
  return {
    type: 'CHANGE_STATUS_SUCCESS_CUSTOMER_REPORT_FORM',
    StatusSuccess,
  };
}

export function changeListStatusReturnCustomerReport(ListStatusReturn: any) {
  return {
    type: 'CHANGE_LIST_STATUS_RETURN_CUSTOMER_REPORT_FORM',
    ListStatusReturn,
  };
}

export function changeStatusReturnCustomerReport(StatusReturn: any) {
  return {
    type: 'CHANGE_STATUS_RETURN_CUSTOMER_REPORT_FORM',
    StatusReturn,
  };
}

export function changeTypeDeliveryCustomerReport(TypeDelivery: any) {
  return {
    type: 'CHANGE_TYPE_DELIVERY_CUSTOMER_REPORT_FORM',
    TypeDelivery,
  };
}

export function changeCheckBoxFileNameCustomerReport() {
  return {
    type: 'CHANGE_CHECK_BOX_FILE_NAME_CUSTOMER_REPORT_FORM',
  };
}

export function changeCheckBoxFullDataCustomerReport() {
  return {
    type: 'CHANGE_CHECK_BOX_FULL_DATA_CUSTOMER_REPORT_FORM',
  };
}

export function changeCheckBoxToDayCustomerReport() {
  return {
    type: 'CHANGE_CHECK_TO_DAY_DATA_CUSTOMER_REPORT_FORM',
  };
}

export function getStatusDeliverySuccessCustomerReport() {
  return {
    type: 'GET_STATUS_DELIVERY_SUCCESS_CUSTOMER_REPORT_FORM',
  };
}

export function getStatusDeliveryReturnCustomerReport() {
  return {
    type: 'GET_STATUS_DELIVERY_RETURN_CUSTOMER_REPORT_FORM',
  };
}

export function changeCheckBoxBarcodeCustomerReport() {
  return {
    type: 'CHANGE_CHECKBOX_BARCODE_CUSTOMER_REPORT_FORM',
  };
}

export function clearCustomerIdCustomerReportForm() {
  return {
    type: 'CLEAR_CUSTOMER_ID_CUSTOMER_REPORT_FROM',
  };
}

export function clearReportFormatCustomerReportForm() {
  return {
    type: 'CLEAR_REPORT_FORMAT_CUSTOMER_REPORT_FROM',
  };
}

export function clearListStatusDeliveryCustomerReportForm() {
  return {
    type: 'CLEAR_LIST_STATUS_DELIVERY_CUSTOMER_REPORT_FROM',
  };
}

export function clearCustomerReportForm() {
  return {
    type: 'CLEAR_CUSTOMER_REPORT_FROM',
  };
}

export function downloadCustomerReportForm() {
  return {
    type: 'DOWNLOAD_CUSTOMER_REPORT_FORM',
  };
}
