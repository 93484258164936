import { Button, Card, Col, Row } from 'antd';
import React, { Component } from 'react'

import ButtonMaterial from '../../../materials/ButtonMaterial';
import FormInputTextMaterial from '../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../materials/ModalMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';
import moment from "moment";

interface IProps
{
    handleCloseModal:any
    handlePutWaybill:any
    handleGetDetailDocument:any
    handleSubmitPriority:any
    validateWaybillForm:any
    helpWaybillForm:any
    handleViewStatusDelivery:any
    getWaybillForm:any
    getWaybillCode:any
    getBarcode:any
    getCustomerName:any
    getCourierName:any
    getCourierCode:any
    getAddress:any
    getTicketStatus:any
    getCreatedTime:any
    validateButtonPriority:any
    validateButtonSubmitPriority:any
    validateButton:any
    typeToggle:any
    visible:any
    isLoading:any
}

export default class DeliveryPriorityaddFormComponent extends Component <IProps> {
    render() {
        
        const address=this.props.getAddress===null?"":`${ Object.values(this.props.getAddress).join(" ") }`
        const TicketStatus = this.props.getTicketStatus===true?'Proses':'Selesai'
        const courierName = this.props.getCourierName===null?'':`${this.props.getCourierName} (${this.props.getCourierCode})`
        const renderData = (label:any, content:any) => {
            return (
              <>
            <Row style={{paddingBottom: '6px',paddingTop: '1px'}}>
                <Col span={7}>
                    <div>
                        <span>{label}</span>
                    </div>
                </Col>
                <Col span={1}>
                    <span>:</span>
                </Col>
                <Col span={16} style={{paddingLeft:'10px'}}>
                    <div>
                        <span><b>{content}</b></span>
                    </div>
                </Col>
            </Row>
              </>
            );
          };
        return (
            <div>
                <ModalMaterial
                    className={""}
                    width={"40%"}
                    style={{ paddingTop: "20px" }}
                    teksTitle={this.props.validateButtonPriority===true?"DETAIL TIKET":"TAMBAHKAN TIKET"}
                    visible={this.props.visible}
                    onCancel={this.props.handleCloseModal}
                    content={
                        <SpinMaterial
                        spinning={this.props.isLoading}
                        style={{}}
                        size={"large"}
                        content={
                            <div style={{padding:20}}>
                                {this.props.validateButtonPriority===true?'':
                                <Row>
                                    <Col span={18}>
                                        <div>
                                            <span>
                                                <b>Nomor Waybill</b>
                                            </span>
                                            <FormInputTextMaterial
                                            validateType={true}
                                            value={this.props.getWaybillForm}
                                            className={""}
                                            disabled={this.props.validateButtonPriority}
                                            addonBefore={""}
                                            placeholder={"Masukan Waybill"}
                                            prefix={""}
                                            maxLength={"30"}
                                            style={{borderRadius: "10px"}}
                                            size=""
                                            type={""}
                                            onChange={this.props.handlePutWaybill}
                                            onBlur={this.props.handlePutWaybill}
                                            validatestatus={this.props.validateWaybillForm}
                                            help={this.props.helpWaybillForm}
                                            onPressEnter={this.props.validateButton===true?'':
                                            this.props.handleGetDetailDocument}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <Button
                                            onClick={this.props.handleGetDetailDocument}
                                            style={{ marginLeft:10,
                                                marginTop:25,
                                                paddingLeft:'10px',
                                                borderRadius:'10px'}}
                                            type="primary"
                                            icon="search"
                                            disabled={this.props.validateButton}>Cari Waybill</Button>
                                    </Col>
                                </Row>
                                }
                                <div>
                                <Card title={<span style={{color:'#F5F5F5'}}>INFORMASI KIRIMAN</span>} 
                                    bordered={false} 
                                    headStyle={{backgroundColor: '#6A6A6A' }} 
                                    style={{background:'#F5F5F5',borderRadius: 10}}
                                >
                                    <React.Fragment>
                                        <Row>
                                            <Row style={{paddingBottom: '6px',paddingTop: '1px'}}>
                                            <Col span={7}>
                                                <div style={{}}>
                                                <span>Waybill</span>
                                                </div>
                                            </Col>
                                            <Col span={1}>
                                                <span>:</span>
                                            </Col>
                                            <Col span={16}>
                                                <div
                                                style={{
                                                    color: '#000000',
                                                }}
                                                >
                                                <span
                                                    onClick={() =>
                                                    this.props.handleViewStatusDelivery(
                                                        this.props.getWaybillCode
                                                    )
                                                    }
                                                    style={{
                                                        paddingLeft:'10px',
                                                        paddingTop: '40px',
                                                        fontStyle: 'italic',
                                                        textDecoration: 'underline',
                                                        color: '#11BEFF',
                                                    }}
                                                >
                                                    {this.props.getWaybillCode}
                                                </span>
                                                </div>
                                            </Col>
                                            </Row>
                                            {renderData('Nama Nasabah', this.props.getCustomerName)}
                                            {renderData('Kurir', courierName)}
                                            {renderData('Barcode', this.props.getBarcode)}
                                            {renderData('Alamat', address)}
                                            {this.props.typeToggle==='AddPriority'?'':
                                            <React.Fragment>
                                            {renderData('Status Tiket',TicketStatus )}
                                            {renderData('Tanggal Dibuat',(moment(this.props.getCreatedTime).format('DD-MM-YYYY,HH:mm')) )}
                                            </React.Fragment>
                                            }
                                        </Row>
                                        {this.props.validateButtonPriority===true?'':
                                        <ButtonMaterial
                                            disabled={this.props.getWaybillCode===null||this.props.validateButtonSubmitPriority===true?true:false}
                                            handleSubmit={this.props.handleSubmitPriority}
                                            teksButton={<b>Jadikan Prioritas</b>}
                                            size={""}
                                            shape={"round"}
                                            className={""}
                                            type={"primary"}
                                            icon={""}
                                            style={
                                                this.props.getWaybillCode===null||this.props.validateButtonSubmitPriority===true ?
                                            {
                                                marginTop:10,
                                                float:'right',}:
                                            {
                                                marginTop:10,
                                                float:'right',
                                                colorFont:'#11BEFF',
                                                background:"#11BEFF",
                                                borderColor:'#11BEFF'}}
                                        />
                                    }
                                    </React.Fragment>
                                </Card>
                                </div>
                            </div>
                        }/>
                    }/>
            </div>
        )
    }
}
