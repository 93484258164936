import React, { Component } from 'react'
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import * as ActionCustomStatus from '../../../core/action/Customer/ActionDetailCustomStatus';
import * as ActionToggle from '../../../core/action/toggle/index';
import * as deleteStatus from '../../../core/action/Customer/ActionDeleteCustomStatus';
import * as detailCustomStatus from '../../../core/action/Customer/ActionDetailCustomStatus';
import ButtonCustomStatusComponent from '../../../components/Content/Customer/ButtonCustomStatusComponent';

interface IProps{
    IdStatus:any
    actionCustomStatus:any
    actionToggle:any
    actionDeleteStatus:any
    actionDetailCustomStatus:any
    typeButton:any
}
class ButtonCustomStatusContainer extends Component<IProps> {
    constructor(props:any)
    {
        super(props)
        
        this.handleCustomStatusEdit = this.handleCustomStatusEdit.bind(this)
        this.handleCustomStatusDelete = this.handleCustomStatusDelete.bind(this)
        this.handleDeleteStatus = this.handleDeleteStatus.bind(this)
        this.handleClearDeleteStatus = this.handleClearDeleteStatus.bind(this)
    }
    handleCustomStatusEdit()
    {
        this.props.actionCustomStatus.changeIdStatus(this.props.IdStatus)
        this.props.actionCustomStatus.changeDetailStatus()
    }
    handleCustomStatusDelete()
    {
        this.props.actionCustomStatus.changeIdStatus(this.props.IdStatus)
    }
    handleDeleteStatus()
    {
        this.props.actionDeleteStatus.changeDeleteStatus()
    }
    handleClearDeleteStatus()
    {
        this.props.actionDetailCustomStatus.changeClearIdStatus()
    }
    render() {
        
        return (
            <ButtonCustomStatusComponent
            handleCustomStatusEdit={this.handleCustomStatusEdit}
            handleCustomStatusDelete={this.handleCustomStatusDelete}
            handleClearDeleteStatus={this.handleClearDeleteStatus}
            handleDeleteStatus={this.handleDeleteStatus}
            typeButton={this.props.typeButton}/>
        )
    }
}
const mapStateProps = (state:any) => ({

})

const mapDispatchToProps = (dispatch:any) => ({
    actionCustomStatus : bindActionCreators(ActionCustomStatus, dispatch),
    actionToggle : bindActionCreators(ActionToggle, dispatch),
    actionDeleteStatus : bindActionCreators(deleteStatus, dispatch),
    actionDetailCustomStatus : bindActionCreators(detailCustomStatus, dispatch),
})

const withConnect = connect(mapStateProps, mapDispatchToProps)

export default compose(
    withConnect
)(ButtonCustomStatusContainer)
