import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionCourierTable from '../../../action/ManageAccount/Courier/ActionTableCourier';
import * as ActionLogin from '../../../../module/Login/Store';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../../action/toggle';

import axios from 'axios';
import moment from 'moment';

export function* S_downloadCourierReport(action: any) {
  const state = yield select();
  const key = 'download';
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const ListCourier = state.Table.TableCourier;
  const PickupDateCourierForm =
    state.Report.CourierReportForm.PickupDateCourierR;
  const RadioButtonCourierR =
    state.Report.CourierReportForm.RadioButtonCourierR;
  const PickupDateCourierTo =
    state.Report.CourierReportForm.DeliveryDateCourierR;
  const CourierR = state.Report.CourierReportForm.CourierR;
  const AllCourier: any[] = [];
  if (CourierR[0] === 'Pilih Semua Kurir') {
    for (var i = 0; i < ListCourier.length; i++) {
      AllCourier.push(ListCourier[i].id);
    }
  } else {
  }
  const StatusR = state.Report.CourierReportForm.StatusR;
  const CustomerId = state.Report.CourierReportForm.CustomerIdR;
  const CustomerProductId = state.Report.CourierReportForm.CustomerProductIdR;
  const FormatFile = state.Report.CourierReportForm.FormatFileR;
  // const FormatFileR = state.Report.CourierReportForm.FormatFileR;
  const CourierPost =
    CourierR[0] === 'Pilih Semua Kurir' ? AllCourier : CourierR;
  const RingkasanR = state.Report.CourierReportForm.RingkasanR;
  const SlaR = state.Report.CourierReportForm.SlaR;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const file_name2 =
    'LaporanKurir -' +
    moment(PickupDateCourierForm).format('YYYYMMDD') +
    ' s/d ' +
    moment(PickupDateCourierTo).format('YYYYMMDD') +
    '.' +
    FormatFile;
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = file_name2;
    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }

  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Delivery/Report/courier',
      {
        waybillCode: 'string',
        customerCode: 'string',
        deliveryStatus: 'string',
        delivery: 'string',
        typeDate: 'PickupDate',
        cycleDateFrom:
          RadioButtonCourierR === 'Process'
            ? 'string'
            : moment(PickupDateCourierForm).format('yyyyMMDD').toString(),
        cycleDateTo:
          RadioButtonCourierR === 'Process'
            ? 'string'
            : moment(PickupDateCourierTo).format('yyyyMMDD').toString(),
        itemDeliveryTimeFrom:
          moment(PickupDateCourierForm).format('YYYY-MM-DD') + 'T00:00:01.000Z',
        itemDeliveryTimeTo:
          moment(PickupDateCourierTo).format('YYYY-MM-DD') + 'T23:59:01.000Z',
        couriers: CourierPost,
        deliveryStats: StatusR,
        summary: RingkasanR,
        sla: SlaR,
        filterDateType: RadioButtonCourierR,
        customerId: CustomerId === null ? 0 : CustomerId,
        customerProducts:
          CustomerProductId.length === 0 ? [] : CustomerProductId,
        csv: FormatFile === 'csv' ? true : false,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(changeLoadingFalse());
    if (error.response.data.Message === 'No delivery found!') {
      message.warning({
        content: 'Laporan Tidak Tersedia',
        key,
        duration: 3,
      });
    } else {
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* S_getCourierMultipleBranch(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const ListBranch = state.Branch.AllBranch;
  const BranchCourierR = state.Report.CourierReportForm.BranchCourierR;
  const AllBranch: any[] = [];
  if (BranchCourierR[0] === 'Pilih Semua Posko') {
    for (var i = 0; i < ListBranch.length; i++) {
      AllBranch.push(ListBranch[i].id);
    }
  }
  const BranchPostValue =
    BranchCourierR[0] === 'Pilih Semua Posko' ? AllBranch : BranchCourierR;
  const BranchPost =
    'BranchId=' + Object.values(BranchPostValue).join('&BranchId=');
  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + `/GetBranch/branchId?${BranchPost}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionCourierTable.setCourierTable(data));
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_getCourierMultipleBranch() {
  yield takeLatest('GET_COURIER_MULTIPLE_BRANCH', S_getCourierMultipleBranch);
}

export function* SM_downloadCourierReport() {
  yield takeLatest('DOWNLOAD_COURIER_R', S_downloadCourierReport);
}
