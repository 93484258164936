import * as ActionFieldCustomerProduct from '../../action/CustomerProduct/ActionFieldCustomerProduct';
import * as ActionLogin from '../../../module/Login/Store';
import * as tableCustomerProduct from '../../action/CustomerProduct/ActionTableCustomerProduct';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_EditCustomerProduct(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const idCustomer = state.Customer.PropertiesCustomer.IdCustomer;
  const groupProductId =
    state.CustomerProduct.DetailCustomerProduct.GroupNameProduct === null
      ? 0
      : state.CustomerProduct.DetailCustomerProduct.GroupNameProduct;
  const idCustomerProduct =
    state.CustomerProduct.PropertiesCustomerProduct.idCustomerProduct;
  const productCode =
    state.CustomerProduct.DetailCustomerProduct.CustomerProductCode;
  const productName =
    state.CustomerProduct.DetailCustomerProduct.CustomerProductName.toLowerCase()
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(' ');
  const isDisabled = state.CustomerProduct.DetailCustomerProduct.isDisabled;
  const productType =
    state.CustomerProduct.DetailCustomerProduct.CustomerProductType;
  const productLogo =
    state.CustomerProduct.DetailCustomerProduct.CustomerProductLogo;
  const productBonus =
    state.CustomerProduct.DetailCustomerProduct.CustomerProductBonus;
  const District1 = state.CustomerProduct.DetailCustomerProduct.District1;
  const District2 = state.CustomerProduct.DetailCustomerProduct.District2;
  const District3 = state.CustomerProduct.DetailCustomerProduct.District3;
  const District4 = state.CustomerProduct.DetailCustomerProduct.District4;
  const District5 = state.CustomerProduct.DetailCustomerProduct.District5;
  const District6 = state.CustomerProduct.DetailCustomerProduct.District6;
  const District7 = state.CustomerProduct.DetailCustomerProduct.District7;
  const District8 = state.CustomerProduct.DetailCustomerProduct.District8;
  const District9 = state.CustomerProduct.DetailCustomerProduct.District9;
  const District10 = state.CustomerProduct.DetailCustomerProduct.District10;
  const District11 = state.CustomerProduct.DetailCustomerProduct.District11;
  const District12 = state.CustomerProduct.DetailCustomerProduct.District12;
  const sla = `${District1};${District2};${District3};${District4};${District5};${District6};${District7};${District8};${District9};${District10};${District11};${District12}`;

  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/Customer/product/update`,
      {
        id: idCustomerProduct,
        productCode: productCode,
        name: productName,
        bonus: productBonus,
        image: productLogo,
        productType: productType,
        customerId: idCustomer,
        sla: sla,
        idGroupProduct: groupProductId,
        isDisabled,
      },
      { headers: config }
    );

    yield put(ActionFieldCustomerProduct.ClearCustomerProductTypeOther());
    yield put(toggle.changeModal());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());
    yield put(ActionFieldCustomerProduct.changeClearFieldCustomerProduct());
    yield put(tableCustomerProduct.ClearTableCustomerProduct());
    yield put(tableCustomerProduct.GetTableCustomerProduct());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil Merubah Data Produk</b>
        </p>
      ),
      onOk() {},
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_EditCustomerProduct() {
  yield takeLatest('EDIT_CUSTOMER_PRODUCT_SUBMIT', S_EditCustomerProduct);
}
