import React, { Component } from 'react';
import { Tabs } from 'antd';
import { getTabMenus } from 'lib/util';
import AdminTableContainer from '../../../containers/Content/ManageAccount/AdminBranch/AdminBranchContainer';
import CourierTableContainer from '../../../containers/Content/ManageAccount/Courier/CourierBranchContainer';
import CustomerTableContainer from '../../../containers/Content/ManageAccount/Customer/TableManageCustomerContainer';

const { TabPane } = Tabs;
interface IProps {
  roleAccount: any;
  lastAccessFiture: any;
  handleChangeTabs: any;
  sideMenus: any;
}

export default class Index extends Component<IProps> {
  render() {
    const isBranch = this.props.roleAccount === 'Branch';
    const tabMenus =
      getTabMenus(this.props.sideMenus, 'Pengaturan Akun', 'Akun') || [];

    const componentTabMenus = {
      Admin: <AdminTableContainer />,
      Kurir: <CourierTableContainer />,
      Pelanggan: <CustomerTableContainer />,
    };

    return (
      <div>
        <Tabs
          className="tabDefault"
          defaultActiveKey={
            this.props.lastAccessFiture === 'adminTable'
              ? '1'
              : this.props.lastAccessFiture === 'courierTable'
              ? '2'
              : this.props.lastAccessFiture === 'customerTable'
              ? '3'
              : '1'
          }
          onChange={this.props.handleChangeTabs}
        >
          {tabMenus
            .filter(value => {
              if (isBranch && value.label === 'Kurir') {
                return value;
              }

              if (!isBranch) {
                return value;
              }

              return null;
            })
            .map((item, index) => (
              <TabPane
                tab={<b>{item.label}</b>}
                key={
                  isBranch && item.label === 'Insentif Kurir' ? 6 : index + 1
                }
              >
                {componentTabMenus[item.label]}
              </TabPane>
            ))}
        </Tabs>
      </div>
    );
  }
}
