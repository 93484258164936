import ComplaintContainer from '../Container/ComplaintContainer';
import DetailComplaintContainer from '../Container/DetailComplaintContainer';
import React from 'react';

export default function IndexComplaintComponent(props) {
  const { formValue } = props;
  const dataValue = formValue ? formValue : '';
  const valueShowType = dataValue.showType ? dataValue.showType : '';

  const viewContent = () => {
    if (valueShowType === 'edit' || valueShowType === 'detail') {
      return <DetailComplaintContainer />;
    } else {
      return <ComplaintContainer />;
    }
  };
  return <React.Fragment>{viewContent()}</React.Fragment>;
}
