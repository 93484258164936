export function setCourierIdProgressCourier(Courierid: any) {
  return {
    type: 'SET_COURIER_ID_PROGRESS_COURIER',
    Courierid,
  };
}
export function setStartDateProgressCourier(StartDate: any) {
  return {
    type: 'SET_START_DATE_PROGRESS_COURIER',
    StartDate,
  };
}
export function setListProgressCourier(ListProgressCourier: any) {
  return {
    type: 'SET_LIST_PROGRESS_COURIER',
    ListProgressCourier,
  };
}
export function getDataProgressCourier() {
  return {
    type: 'GET_DATA_PROGRESS_COURIER',
  };
}
export function changeClearProgressCourier() {
  return {
    type: 'CHANGE_CLEAR_PROGRESS_COURIER',
  };
}
