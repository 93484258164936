import * as ActionLogActivity from '../../module/LogActivity/Store/LogActivityAction';
import * as ClearAuth from '../../core/action/Logout/index';
import * as Router from '../../core/action/Router/ActionRouter';
import * as Toggle from '../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import HeaderComponent from '../../components/Header/HeaderComponent';
import { connect } from 'react-redux';
import history from '../../core/utils/History';

interface IProps {
  GetUsername: any;
  GetPhoto: any;
  GetInitials: any;
  actionLogActivity: any;
  ActionClearAuth: any;
  ActionToggle: any;
  ActionRouter: any;
  isLoading: any;
}

class HeaderContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleClearAuth = this.handleClearAuth.bind(this);
    this.handleActionProfile = this.handleActionProfile.bind(this);
  }

  async handleClearAuth() {
    await this.props.actionLogActivity.hitLogActivityLogout();
    this.props.ActionToggle.changeJenisToggle('logout');
    this.props.ActionToggle.clearJenisToggle();
    this.props.ActionClearAuth.changeClearToken();
    this.props.ActionClearAuth.changeClearTokenRefresh();
    this.props.ActionClearAuth.changeClearUsernameAuth();
    this.props.ActionClearAuth.changeClearDataProfileAuth();
  }

  handleActionProfile() {
    this.props.ActionRouter.setRouter('/profile');
    history.push('/profile');
  }

  render() {
    return (
      <HeaderComponent
        GetUsername={this.props.GetUsername}
        GetPhoto={this.props.GetPhoto}
        GetInitials={this.props.GetInitials}
        handleClearAuth={this.handleClearAuth}
        handleActionProfile={this.handleActionProfile}
        isLoading={this.props.isLoading}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  GetUsername: state.Auth.UsernameAuth,
  GetPhoto: state.Auth.PhotoAuth,
  GetInitials: state.Auth.InitialsAuth,
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  ActionToggle: bindActionCreators(Toggle, dispatch),
  ActionRouter: bindActionCreators(Router, dispatch),
  ActionClearAuth: bindActionCreators(ClearAuth, dispatch),
  actionLogActivity: bindActionCreators(ActionLogActivity, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(HeaderContainer);
