export function getAllBranch()
{
    return({
        type:"GET_ALL_BRANCH"
    })
}
export function setBranch(AllBranch:any)
{
    return({
        type:"SET_BRANCH",
        AllBranch
    })
}
export function getBranchGroupList()
{
    return({
        type:"GET_BRANCH_GRUP_LIST"
    })
}
export function setBranchGroupList(BranchGroupList:any)
{
    return({
        type:"SET_BRANCH_GROUP_LIST",
        BranchGroupList
    })
}
export function clearBranch()
{
    return({
        type:"CLEAR_BRANCH"
    })
}