import { DatePicker, Form } from 'antd';
import React, { Component } from 'react';

interface IProps {
  style: any;
  locale: any;
  format: any;
  defaultPickerValue: any;
  placeholder: any;
  defaultValue: any;
  onOpenChange: any;
  value: any;
  onChange: any;
  disabledDate: any;
  validatestatus: any;
  help: any;
  disabled?: boolean;
}

export default class DatePickerMaterial extends Component<IProps> {
  render() {
    return (
      <div>
        <Form.Item
          validateStatus={this.props.validatestatus}
          help={this.props.help}
        >
          <DatePicker
            style={this.props.style}
            locale={this.props.locale}
            format={this.props.format}
            defaultPickerValue={this.props.defaultPickerValue}
            placeholder={this.props.placeholder}
            defaultValue={this.props.defaultValue}
            onOpenChange={this.props.onOpenChange}
            value={this.props.value}
            onChange={this.props.onChange}
            disabledDate={this.props.disabledDate}
            disabled={this.props.disabled}
          />
        </Form.Item>
      </div>
    );
  }
}
