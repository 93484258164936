const LogActivityValidation = (values, props) => {
  const errors: any = {};
  if (!values.startDateLogActivity) {
    errors.startDateLogActivity = 'Tanggal wajib diisi';
  }
  if (!values.finishDateLogActivity) {
    errors.finishDateLogActivity = 'Tanggal wajib diisi';
  }
  if (!values.logType) {
    errors.logType = null;
  }
  if (!values.user) {
    errors.user = null;
  }
  return errors;
};
export default LogActivityValidation;
