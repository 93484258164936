import * as ActionAdminBranch from '../../action/ManageAccount/AdminBranch/ActionAllAdminBranch';
import * as ActionAuth from '../../action/Auth';
import * as ActionDetailBranch from '../../action/Branch/ActionDetailBranch';
import * as ActionFieldBranch from '../../action/Branch/ActionFieldBranch';
import * as ActionLoadingSelectAdmin from '../../action/ManageAccount/AdminBranch/ActionLoadingSelectAdmin';
import * as ActionLogin from '../../../module/Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import axios from 'axios';

// import { Modal } from 'antd';
// import React from 'react';

export function* S_GetDetailBranch(action: any) {
  try {
    yield put(changeLoadingTrue());
    const state = yield select();
    const token = state.Auth.Token;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    const idBranch = state.Branch.PropertiesBranch.idBranch;

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Branch/${idBranch}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionFieldBranch.changeGroupNameBranch(data.groupName));
    yield put(ActionFieldBranch.setNameBranch(data.name));
    yield put(ActionFieldBranch.setAreaCode(data.uniqueCode));
    yield put(ActionFieldBranch.setAddress(data.address));
    yield put(ActionFieldBranch.setPhoneNumber(data.phoneNumber));
    yield put(ActionFieldBranch.setPostalCode(data.postalCode));
    yield put(ActionDetailBranch.setIdAdminBranch(data.headIdBranch));
    yield put(ActionDetailBranch.setIdVillage(data.branchVillage));
    yield put(ActionDetailBranch.changeAdminBranchName());
    yield put(ActionDetailBranch.changeDetailVillage());
    if (data.isPartner === true) {
      yield put(ActionFieldBranch.setPartner());
    }
    yield put(
      ActionFieldBranch.setLatitudeBranch(data.geoLocation.split(',')[-0])
    );
    yield put(
      ActionFieldBranch.setLongitudeBranch(data.geoLocation.split(',')[1])
    );
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(changeLoadingFalse());
  }
}

export function* S_GetNameAdminBranch(action: any) {
  try {
    const state = yield select();
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    const AdminBranch = state.Branch.PropertiesBranch.idAdminBranch;
    const token = state.Auth.Token;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Profile/Branch/${AdminBranch}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionFieldBranch.setNameAdminBranch(data.firstName));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_GetDetailVillage(action: any) {
  try {
    const state = yield select();
    const token = state.Auth.Token;
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };
    const idVillage = state.Branch.PropertiesBranch.idVillage;

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Address/village/${idVillage}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionFieldBranch.setBranchProvince(data.provinceName));
    yield put(ActionFieldBranch.setBranchCity(data.cityName));
    yield put(ActionFieldBranch.setBranchSubDistrict(data.subDistrictName));
    yield put(ActionFieldBranch.setBranchVillage(data.name));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* S_GetAllAdminBranch(action: any) {
  try {
    yield put(ActionLoadingSelectAdmin.setLoadingAdminBranch());
    yield put(
      ActionLoadingSelectAdmin.setTypeLoadingAdminBranch('selectAdminBranch')
    );
    const state = yield select();
    const token = state.Auth.Token;
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Profile/Branch',
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionAdminBranch.setAllAdminBranch(data.data));
    yield put(ActionLoadingSelectAdmin.setLoadingAdminBranch());
    yield put(ActionLoadingSelectAdmin.clearTypeLoadingAdminBranch());
  } catch (error) {
    yield put(ActionLoadingSelectAdmin.setLoadingAdminBranch());
    yield put(ActionLoadingSelectAdmin.clearTypeLoadingAdminBranch());
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* S_getGroupNameBranchList(action: any) {
  try {
    const state = yield select();
    const token = state.Auth.Token;
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/GroupProduct/ByCustomer/10036`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionFieldBranch.setGroupNameList(data));
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(changeLoadingFalse());
  }
}

export function* SM_GetDetailBranch() {
  yield takeLatest('CHANGE_DETAIL_BRANCH', S_GetDetailBranch);
}
export function* SM_GetNameAdminBranch() {
  yield takeLatest('CHANGE_ADMIN_BRANCH_NAME', S_GetNameAdminBranch);
}
export function* SM_GetDetailVillage() {
  yield takeLatest('CHANGE_DETAIL_VILLAGE', S_GetDetailVillage);
}
export function* SM_GetAllAdminBranch() {
  yield takeLatest('GET_SELECT_ADMIN_BRANCH', S_GetAllAdminBranch);
}
export function* SM_getGroupNameBranchList() {
  yield takeLatest('GET_GROUP_NAME_BRANCH_LIST', S_getGroupNameBranchList);
}
