import * as ActionAuth from '../../action/Auth';
import * as ActionConfigReport from '../../action/Customer/ActionDetailConfigReport';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

export function* S_detailEditConfigReport(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const router = state.router.location.pathname;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdConfigReport =
    router === '/laporan/download'
      ? state.Report.CustomerReportForm.ReportFormat
      : state.Customer.FormCustomerConfigReport.IdCustomerReport;
  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Setting/CustomReportFile/${IdConfigReport}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionConfigReport.changeIdCustomerReport(data.id));
    yield put(ActionConfigReport.changeCustomerNameReport(data.name));
    yield put(ActionConfigReport.changeFormatReport(data.format));
    yield put(ActionConfigReport.changePeriodeReport(data.periode));
    yield put(ActionConfigReport.changeDelimitedReport(data.delimited));
    yield put(ActionConfigReport.changeSettingReport(data.customCsvId));
    yield put(ActionConfigReport.changeSettingReportName(data.nameCustomCsv));
    yield put(ActionConfigReport.changeWithoutHeaderReport(data.headerHide));
    yield put(ActionConfigReport.changeWithoutHeaderReport(data.headerHide));
    yield put(ActionConfigReport.changeShowInPortalReport(data.reportPortal));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_detailEditConfigReport() {
  yield takeLatest(
    'CHANGE_DETAIL_EDIT_CUSTOMER_CONFIG_REPORT',
    S_detailEditConfigReport
  );
}
