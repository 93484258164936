import * as ActionCustomerAccount from '../../../action/ManageAccount/Customer/ActionCustomerAccount';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_statusCustomerAccount() {
  const state = yield select();

  const Username = state.Customer.CustomerAccount.Username;
  const StatusAccount = state.Customer.CustomerAccount.StatusAccount;

  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/Profile/status',
      {
        userName: Username,
        status: StatusAccount,
      },
      { headers: config }
    );
    yield put(ActionCustomerAccount.clearDataCustomerAccount());
    yield put(ActionCustomerAccount.getCustomerAccount());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      const propsError = ErrorMassage('Akun Pelanggan di non aktifkan');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_statusCustomerAccount() {
  yield takeLatest('STATUS_CUSTOMER_ACCOUNT', S_statusCustomerAccount);
}
