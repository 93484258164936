
import * as toggle from '../../../core/action/toggle';
import * as ActionDeliveryPriority from '../../../core/action/DeliveryPriority/ActionDeliveryPriority';

import React, { Component } from 'react'
import {bindActionCreators, compose} from 'redux';

import ButtonDetailComponent from '../../../components/Content/DeliveryPriority/ButtonDetailComponent';
import {connect} from 'react-redux';

interface IProps
{
    id:any
    getTicketId:any
    actionToggle:any
    actionDeliveryPriority:any
}

interface IState
{

}

class ButtonDownloadContainer extends Component <IProps, IState> {
    constructor(props:any)
    {
        super (props)
        this.state=
        {

        }
        
        this.handleChangeAndPopUpModal = this.handleChangeAndPopUpModal.bind(this)
    }
    handleChangeAndPopUpModal()
    {
        this.props.actionDeliveryPriority.setTicketId(this.props.id)
        this.props.actionDeliveryPriority.getDetailDeliveryPriority()
        this.props.actionToggle.changeModal()
        this.props.actionToggle.changeJenisToggle("detailTicket")
    }
    render() {
        return (
            <React.Fragment>
                <ButtonDetailComponent
                handleChangeAndPopUpModal={this.handleChangeAndPopUpModal}
                />
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state:any)=> ({
    TypeToggle:state.Toggle.jenisToggle,
    getTicketId:state.DeliveryPriority.Properties.TicketId,
})

const mapDispatchToProps = (dispatch:any) => ({
    actionToggle : bindActionCreators(toggle, dispatch),
    actionDeliveryPriority : bindActionCreators(ActionDeliveryPriority, dispatch),
})

const withConnect = connect (mapStateToProps, mapDispatchToProps)

export default compose(
    withConnect
)(ButtonDownloadContainer)