export function getListCustomerCsvName()
{
    return({
        type:"GET_LIST_CUSTOMER_CSV_TYPE"
    })
}
export function setCsvTemplate(CsvTemplate:any)
{
    return({
        type:"SET_CSV_TEMPLATE",
        CsvTemplate
    })
}
export function setListCustomerCsvType(ListCustomerCsvType:any)
{
    return({
        type:"SET_LIST_CUSTOMER_CSV_TYPE",
        ListCustomerCsvType
    })
}
export function clearCsvTemplate()
{
    return({
        type:"CLEAR_CSV_TEMPLATE"
    })
}
export function clearListCustomerCsvType()
{
    return({
        type:"CLEAR_LIST_CUSTOMER_CSV_TYPE"
    })
}