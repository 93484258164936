import { Card, Col, Row, Tag } from 'antd';

import ButtonMaterial from 'materials/ButtonMaterial';
import FormInputTextMaterial from 'materials/FormInputTextMaterial';
import ModalMaterial from 'materials/ModalMaterial';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';

interface IProps {
  visible: any;
  isLoading: any;
  typeToggle: any;
  ValidateButton: any;
  ValidateButtonDownload: any;
  handleCloseModal: any;
  handleUpdate: any;
  handleUpdateTicketForm: any;
  handleSubmit: any;
  handleSearchWaybill: any;
  handleDownload: any;
  validateIcon: any;
  ValidateButtonUpdate: any;
  ValidateButtonUpdateForm: any;

  getComplaintStatus: any;
  getComplaintTicketCode: any;
  getComplaintWaybillDetail: any;
  getComplaintOwnerName: any;
  getComplaintAddress: any;
  getComplaintBarcodeDetail: any;
  getComplaintNoTelpDetail: any;
  getComplaintPostCode: any;
  getComplaintCity: any;
  disableWaybillForm: any;
  disableComplaintDetailForm: any;

  PutWaybill: any;
  getComplaintWaybill: any;
  ValidateWaybillForm: any;
  helpWaybillForm: any;

  PutPhoneNumber: any;
  getComplaintNoTelp: any;
  ValidatePhoneNumberForm: any;
  helpPhoneNumberForm: any;

  PutComplaintDetail: any;
  getComplaintDetail: any;
  ValidateComplaintDetailForm: any;
  helpComplaintDetailForm: any;

  PutResultInvestigation: any;
  getComplaintResultInvestigation: any;
  ValidateResultInvestigationForm: any;
  helpResultInvestigationForm: any;
}
export default function FormComplaintComponent({
  visible,
  isLoading,
  typeToggle,
  ValidateButton,
  ValidateButtonDownload,
  handleCloseModal,
  handleUpdate,
  handleUpdateTicketForm,
  handleSubmit,
  handleSearchWaybill,
  handleDownload,
  validateIcon,
  ValidateButtonUpdate,
  ValidateButtonUpdateForm,

  getComplaintStatus,
  getComplaintTicketCode,
  getComplaintWaybillDetail,
  getComplaintOwnerName,
  getComplaintAddress,
  getComplaintBarcodeDetail,
  getComplaintNoTelpDetail,
  getComplaintPostCode,
  getComplaintCity,
  disableWaybillForm,
  disableComplaintDetailForm,

  PutWaybill,
  getComplaintWaybill,
  ValidateWaybillForm,
  helpWaybillForm,

  PutPhoneNumber,
  getComplaintNoTelp,
  ValidatePhoneNumberForm,
  helpPhoneNumberForm,

  PutComplaintDetail,
  getComplaintDetail,
  ValidateComplaintDetailForm,
  helpComplaintDetailForm,

  PutResultInvestigation,
  getComplaintResultInvestigation,
  ValidateResultInvestigationForm,
  helpResultInvestigationForm,
}: IProps) {
  const validateUpdate =
    getComplaintStatus === 'Selesai' || ValidateButtonUpdate === true
      ? true
      : false;
  const validateUpdate2 =
    getComplaintStatus === 'Selesai' || ValidateButtonUpdateForm === true
      ? true
      : false;
  const renderData = (label: any, content: any) => {
    return (
      <>
        <Row
          style={{
            paddingBottom: '6px',
            paddingTop: '1px',
            fontSize: '12px',
          }}
        >
          <Col span={7}>
            <div>
              <span>{label}</span>
            </div>
          </Col>
          <Col span={1} style={{ fontSize: '12px' }}>
            <span>:</span>
          </Col>
          <Col span={16} style={{ paddingLeft: '10px', fontSize: '12px' }}>
            <div>
              <span>
                <b>{content}</b>
              </span>
            </div>
          </Col>
        </Row>
      </>
    );
  };
  return (
    <div>
      <ModalMaterial
        className={''}
        width={'60%'}
        style={{ paddingTop: '20px' }}
        teksTitle={
          typeToggle === 'AddNewTicket'
            ? 'TAMBAHKAN TIKET BARU'
            : typeToggle === 'EditTicket'
            ? 'UBAH STATUS TIKET'
            : typeToggle === 'EditFormTicket'
            ? 'UBAH TIKET'
            : ''
        }
        visible={visible}
        onCancel={handleCloseModal}
        content={
          <SpinMaterial
            spinning={isLoading}
            style={{}}
            size={'large'}
            content={
              <div style={{ paddingTop: '20px' }}>
                <Row>
                  <Col
                    span={12}
                    style={{ paddingLeft: '30px', paddingRight: '20px' }}
                  >
                    {/* <span style={{paddingRight:'20px'}}>#TIKET</span> */}
                    <Tag
                      color={
                        getComplaintStatus === 'Baru' ||
                        getComplaintStatus === 'Proses'
                          ? 'green'
                          : 'grey'
                      }
                    >
                      <span style={{ fontSize: '14px' }}>
                        <b>{getComplaintStatus}</b>
                      </span>
                    </Tag>

                    <div
                      style={
                        typeToggle !== 'EditTicket'
                          ? { paddingTop: '15px' }
                          : { paddingTop: '50px' }
                      }
                    >
                      <span>
                        <b>Waybill</b>
                      </span>
                      <Row>
                        <Col span={22} style={{ paddingRight: '10px' }}>
                          <FormInputTextMaterial
                            validateType={true}
                            value={getComplaintWaybill}
                            className={''}
                            disabled={disableWaybillForm}
                            addonBefore={''}
                            onPressEnter={handleSearchWaybill}
                            placeholder={'Cari waybill'}
                            prefix={''}
                            maxLength={''}
                            style={{ borderRadius: '10px' }}
                            size=""
                            type={''}
                            onChange={PutWaybill}
                            onBlur={PutWaybill}
                            validatestatus={ValidateWaybillForm}
                            help={helpWaybillForm}
                          />
                        </Col>
                        <Col span={2} style={{ paddingTop: '5px' }}>
                          <ButtonMaterial
                            disabled={disableWaybillForm}
                            handleSubmit={handleSearchWaybill}
                            teksButton={''}
                            size={'small'}
                            shape={'circle'}
                            className={''}
                            type={'primary'}
                            icon={'search'}
                            style={
                              typeToggle !== 'EditTicket'
                                ? {
                                    borderRadius: '15px',
                                    float: 'left',
                                    color: '#fafafa',
                                  }
                                : {
                                    borderRadius: '15px',
                                    float: 'left',
                                    color: '#969696',
                                  }
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                    {typeToggle === 'AddNewTicket' ? (
                      <div>
                        <span>
                          <b>No Telp</b>
                        </span>
                        <FormInputTextMaterial
                          validateType={true}
                          value={getComplaintNoTelp}
                          className={''}
                          disabled={false}
                          addonBefore={'+62'}
                          onPressEnter={''}
                          placeholder={'Masukan nomor telpon'}
                          prefix={''}
                          maxLength={15}
                          style={{ borderRadius: '10px' }}
                          size=""
                          type={''}
                          onChange={PutPhoneNumber}
                          onBlur={PutPhoneNumber}
                          validatestatus={ValidatePhoneNumberForm}
                          help={helpPhoneNumberForm}
                        />
                      </div>
                    ) : (
                      <div style={{ marginBottom: '53px' }}></div>
                    )}
                    <div>
                      <Card
                        title={
                          <span style={{ color: '#F5F5F5', fontSize: '14px' }}>
                            Detail Waybill
                          </span>
                        }
                        bordered={false}
                        headStyle={{ backgroundColor: '#6A6A6A' }}
                        style={{ background: '#F5F5F5' }}
                      >
                        <React.Fragment>
                          <Row>
                            {renderData('Barcode', getComplaintBarcodeDetail)}
                            {renderData(
                              'Alamat',
                              getComplaintAddress !== null
                                ? `${Object.values(getComplaintAddress).join(
                                    ' '
                                  )} ` +
                                    (getComplaintCity === null ||
                                    getComplaintCity === '' ||
                                    getComplaintCity === undefined
                                      ? ''
                                      : getComplaintCity) +
                                    ' ' +
                                    (getComplaintPostCode === null ||
                                    getComplaintPostCode === '' ||
                                    getComplaintPostCode === undefined
                                      ? ''
                                      : getComplaintPostCode)
                                : ''
                            )}
                            {renderData('Nama Pemilik', getComplaintOwnerName)}
                            {renderData('No. Telp', getComplaintNoTelpDetail)}
                          </Row>
                        </React.Fragment>
                      </Card>
                    </div>
                  </Col>

                  <Col
                    span={12}
                    style={{
                      paddingLeft: '20px',
                      paddingRight: '30px',
                      paddingTop: '35px',
                    }}
                  >
                    <div>
                      <span>
                        <b>Komplain</b>
                      </span>
                      <FormInputTextMaterial
                        validateType={true}
                        value={getComplaintDetail}
                        className={''}
                        disabled={disableComplaintDetailForm}
                        addonBefore={''}
                        onPressEnter={''}
                        placeholder={'Masukan komplain'}
                        prefix={''}
                        maxLength={300}
                        style={{ borderRadius: '10px', height: '120px' }}
                        size=""
                        type={'textArea'}
                        onChange={PutComplaintDetail}
                        onBlur={PutComplaintDetail}
                        validatestatus={ValidateComplaintDetailForm}
                        help={helpComplaintDetailForm}
                      />
                    </div>
                    <div style={{ paddingTop: '8px' }}>
                      <span>
                        <b>Hasil Investigasi</b>
                      </span>
                      <FormInputTextMaterial
                        validateType={true}
                        value={getComplaintResultInvestigation}
                        className={''}
                        disabled={
                          typeToggle === 'AddNewTicket' ||
                          typeToggle === 'EditFormTicket' ||
                          getComplaintStatus === 'Selesai'
                            ? true
                            : false
                        }
                        addonBefore={''}
                        onPressEnter={''}
                        placeholder={''}
                        prefix={''}
                        maxLength={300}
                        style={{ borderRadius: '10px', height: '130px' }}
                        size=""
                        type={'textArea'}
                        onChange={PutResultInvestigation}
                        onBlur={PutResultInvestigation}
                        validatestatus={ValidateResultInvestigationForm}
                        help={helpResultInvestigationForm}
                      />
                    </div>
                    {typeToggle === 'AddNewTicket' ? (
                      <ButtonMaterial
                        disabled={ValidateButton}
                        handleSubmit={handleSubmit}
                        teksButton={<b>Simpan</b>}
                        size={''}
                        shape={'round'}
                        className={''}
                        type={'primary'}
                        icon={''}
                        style={
                          ValidateButton === true
                            ? {
                                float: 'right',
                              }
                            : {
                                float: 'right',
                                colorFont: '#11BEFF',
                                background: '#11BEFF',
                                borderColor: '#11BEFF',
                              }
                        }
                      />
                    ) : typeToggle === 'EditTicket' ||
                      typeToggle === 'EditFormTicket' ? (
                      <React.Fragment>
                        <ButtonMaterial
                          disabled={ValidateButtonDownload}
                          handleSubmit={handleDownload}
                          teksButton={<b>Download</b>}
                          size={''}
                          shape={'round'}
                          className={''}
                          type={'primary'}
                          icon={''}
                          style={
                            ValidateButtonDownload === true
                              ? {
                                  width: '120px',
                                  float: 'right',
                                }
                              : {
                                  width: '120px',
                                  float: 'right',
                                  colorFont: '#fafafa',
                                  background: '#7956EE',
                                  borderColor: '#7956EE',
                                }
                          }
                        />

                        <ButtonMaterial
                          disabled={
                            typeToggle === 'EditTicket'
                              ? validateUpdate
                              : validateUpdate2
                          }
                          handleSubmit={
                            typeToggle === 'EditTicket'
                              ? handleUpdate
                              : handleUpdateTicketForm
                          }
                          teksButton={
                            <b>
                              {typeToggle === 'EditTicket'
                                ? 'Ubah Status'
                                : 'Ubah Tiket'}
                            </b>
                          }
                          size={''}
                          shape={'round'}
                          className={''}
                          type={'primary'}
                          icon={''}
                          style={
                            ValidateButtonUpdate === true ||
                            ValidateButtonUpdateForm === true ||
                            getComplaintStatus === 'Selesai'
                              ? {
                                  marginRight: '20px',
                                  width: '120px',
                                  float: 'right',
                                }
                              : {
                                  marginRight: '20px',
                                  width: '120px',
                                  float: 'right',
                                  colorFont: '#fafafa',
                                  background: '#FCC642',
                                  borderColor: '#FCC642',
                                }
                          }
                        />
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              </div>
            }
          />
        }
      />
    </div>
  );
}
