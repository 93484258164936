import React from 'react';
import styles from './FilterTable.module.css';
import { Row, Col } from 'antd';
import { Form, Field } from 'react-final-form';

import SelectField from 'components/SelectField';
import InputDatePicker from 'components/InputDatePicker';
import Button from 'components/Button';
import CardMaterial from 'materials/CardMaterial';
import moment from 'moment-timezone';
import { FilterPerformaKurirProps } from 'module/Report/Ringkasan/container/PerformaKurir';

type FilterTableProps = {
  dataAllBranch: Array<any>;
  dataCustomers: Array<any>;
  dataProducts: Array<any>;
  isLoadingBranch?: boolean;
  isLoadingCustomers?: boolean;
  isLoadingProducts?: boolean;
  roleAccount: string;
  selectedBranchId: number | null | undefined;
  initialValue: any;
  handleResetFilter: () => void;
  handleSubmitFilter: (value: FilterPerformaKurirProps) => void;
  handleChangeFilter: (name: string, value: any) => void;
};

export default function FilterTable({
  dataCustomers,
  dataProducts,
  isLoadingCustomers,
  isLoadingProducts,
  dataAllBranch,
  isLoadingBranch,
  roleAccount,
  selectedBranchId,
  initialValue,
  handleResetFilter,
  handleSubmitFilter,
  handleChangeFilter,
}: FilterTableProps) {
  const isBranch = roleAccount === 'Branch';
  const defaultDateSend = isBranch ? moment().subtract(1, 'weeks') : null;
  return (
    <CardMaterial
      title={<b style={{ color: '#11BEFF' }}>RINGKASAN PERFORMA POSKO</b>}
      style={{ borderRadius: '10px' }}
    >
      <Form
        initialValues={{
          ...initialValue,
        }}
        validate={values => {
          const errors = {} as any;
          if (!values.startDate) {
            errors.startDate = 'Tanggal wajib di isi';
          }
          if (!values.endDate) {
            errors.endDate = 'Tanggal wajib di isi';
          }
          return errors;
        }}
        onSubmit={handleSubmitFilter}
      >
        {({ form, handleSubmit, submitting, valid, values }) => (
          <form className={styles.wrapperFilter} onSubmit={handleSubmit}>
            <div className={styles.wrapperItemFilter}>
              <Row gutter={24}>
                <Col md={12} className={styles.filterColumn}>
                  <Field name="startDate">
                    {({ input, meta }) => (
                      <InputDatePicker
                        label={'Tanggal Periode'}
                        placeholder="Masukkan Tanggal"
                        onOpenChange={input.onBlur}
                        onChange={value => {
                          handleChangeFilter('startDate', value);
                          handleChangeFilter('endDate', null);
                        }}
                        value={input.value}
                        error={
                          meta.touched && meta.error !== undefined && meta.error
                        }
                      />
                    )}
                  </Field>
                  <Field name="customerId">
                    {({ input, meta }) => (
                      <SelectField
                        validate
                        label="Pelanggan"
                        placeholder="Semua Pelanggan"
                        value={input.value}
                        onChange={value => {
                          handleChangeFilter('customerId', value);
                        }}
                        onBlur={value => {
                          input.onBlur(value);
                        }}
                        validateStatus={
                          meta.touched && meta.error ? 'error' : 'validating'
                        }
                        errorMessage={meta.touched && meta.error && meta.error}
                        loading={isLoadingCustomers}
                        data={dataCustomers}
                      />
                    )}
                  </Field>
                  <Field name="productId">
                    {({ input, meta }) => (
                      <SelectField
                        validate
                        label="Produk"
                        placeholder="Semua Produk"
                        value={input.value}
                        onChange={value => {
                          handleChangeFilter('productId', value);
                        }}
                        onBlur={value => {
                          input.onBlur(value);
                        }}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                        disabled={!values.customerId}
                        data={dataProducts}
                        loading={isLoadingProducts}
                        mode="multiple"
                        allowClear
                      />
                    )}
                  </Field>
                </Col>
                <Col md={12} className={styles.filterColumn}>
                  <Field name="endDate">
                    {({ input, meta }) => (
                      <InputDatePicker
                        label="Sampai Tanggal"
                        placeholder="Masukkan Tanggal"
                        onOpenChange={input.onBlur}
                        onChange={value => {
                          handleChangeFilter('endDate', value);
                        }}
                        value={input.value}
                        error={
                          meta.touched && meta.error !== undefined && meta.error
                        }
                        disabled={!values.startDate}
                        disabledDate={d => {
                          const startDateMonth = moment(
                            values.startDate
                          ).format('M'); // Get the month of the start date
                          const selectedDateMonth = moment(d).format('M'); // Get the month of the selected date

                          // Disable the date if it's not in the same month as the start date
                          return (
                            startDateMonth !== selectedDateMonth ||
                            d.isBefore(moment(values.startDate).startOf('day'))
                          );
                        }}
                      />
                    )}
                  </Field>
                  <Field name="branchId">
                    {({ input, meta }) => (
                      <SelectField
                        validate
                        label="Posko"
                        placeholder="Semua Posko"
                        data={dataAllBranch}
                        loading={isLoadingBranch}
                        disabled={isBranch}
                        value={input.value}
                        onChange={value => {
                          handleChangeFilter('branchId', value);
                        }}
                        onBlur={value => {
                          input.onBlur(value);
                        }}
                        validateStatus={
                          meta.error && meta.touched ? 'error' : 'validating'
                        }
                        errorMessage={meta.error && meta.touched && meta.error}
                        mode="multiple"
                        allowClear
                      />
                    )}
                  </Field>
                </Col>
              </Row>
            </div>
            <div className={styles.actionFilter}>
              <Button
                text="Reset"
                variant="danger"
                className={styles.actionWidth}
                onClick={() => {
                  handleResetFilter();
                  form.reset({
                    startDate: defaultDateSend,
                    //@ts-ignore
                    branchId: [selectedBranchId],
                  });
                }}
                disabled={!valid}
              />
              <Button
                text="Cari"
                className={styles.actionWidth}
                disabled={submitting || !valid}
                htmlType="submit"
              />
            </div>
          </form>
        )}
      </Form>
    </CardMaterial>
  );
}
