import {Action} from 'redux'

const initialState = 
{
    ZoomMap:null,
    TypeRealTime:null,
    ToggleMaps:null,
    ErrorConnectionMaps:null,
    CourierTracking:{
        DetailCourier:[],
        SelectCourier:null,
        CourierDeliveryData:{
            CourierCode:null,
            CourierPhone:null,
            CourierBranch:null,
            CourierLastSeen:null,
            DeliverySuccess:null,
            DeliveryReturn:null,
            DeliveryPending:null,
            DeliveryTotal:null
        }
    },
    BranchTracking:{
        BranchLat:null,
        BranchLng:null
    }
}

export interface IAuthAction extends Action<string>
{

}

export interface ISetDetailCourier extends IAuthAction
{
    detailCourier: any
}
export interface ISetSelectCourier extends IAuthAction
{
    selectCourier: any
}
export interface ISetTypeRealTime extends IAuthAction
{
    typeRealTime: any
}
export interface ISetCourierCode extends IAuthAction
{
    CourierCode: any
}
export interface ISetCourierPhone extends IAuthAction
{
    CourierPhone: any
}
export interface ISetCourierBranch extends IAuthAction
{
    CourierBranch: any
}
export interface ISetCourierLastSeen extends IAuthAction
{
    CourierLastSeen: any
}
export interface ISetDeliverySuccess extends IAuthAction
{
    DeliverySuccess: any
}
export interface ISetDeliveryReturn extends IAuthAction
{
    DeliveryReturn: any
}
export interface ISetDeliveryPending extends IAuthAction
{
    DeliveryPending: any
}
export interface ISetDeliveryTotal extends IAuthAction
{
    DeliveryTotal: any
}
export interface ISetZoomMap extends IAuthAction
{
    setZoomMap: any
}
export interface ISetToggleMaps extends IAuthAction
{
    ToggleMaps: any
}
export interface ISetErrorConnectionMaps extends IAuthAction
{
    ErrorConnectionMaps: any
}
export interface ISetBranchLat extends IAuthAction
{
    BranchLat: any
}
export interface ISetBranchLng extends IAuthAction
{
    BranchLng: any
}

export default function AuthReducer(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "DETAIL_COURIER":
            const setDetailCourier = action as ISetDetailCourier
            newState.CourierTracking.DetailCourier = setDetailCourier.detailCourier
        return {...newState}

        case "SELECT_COURIER":
            const setSelectCourier = action as ISetSelectCourier
            newState.CourierTracking.SelectCourier = setSelectCourier.selectCourier
        return {...newState}

        case "SET_TYPE_REALTIME":
            const setTypeRealTime = action as ISetTypeRealTime
            newState.TypeRealTime = setTypeRealTime.typeRealTime
        return {...newState}

        case "SET_ZOOM_MAP":
            const setZoomMap = action as ISetZoomMap
            newState.ZoomMap = setZoomMap.setZoomMap
        return {...newState}

        case "SET_COURIER_CODE":
            const setCourierCode = action as ISetCourierCode
            newState.CourierTracking.CourierDeliveryData.CourierCode = setCourierCode.CourierCode
        return {...newState}

        case "SET_COURIER_PHONE":
            const setCourierPhone = action as ISetCourierPhone
            newState.CourierTracking.CourierDeliveryData.CourierPhone = setCourierPhone.CourierPhone
        return {...newState}

        case "SET_COURIER_BRANCH":
            const setCourierBranch = action as ISetCourierBranch
            newState.CourierTracking.CourierDeliveryData.CourierBranch = setCourierBranch.CourierBranch
        return {...newState}

        case "SET_COURIER_LAST_SEEN":
            const setCourierLastSeen = action as ISetCourierLastSeen
            newState.CourierTracking.CourierDeliveryData.CourierLastSeen = setCourierLastSeen.CourierLastSeen
        return {...newState}

        case "SET_DELIVERY_SUCCESS":
            const setDeliverySuccess = action as ISetDeliverySuccess
            newState.CourierTracking.CourierDeliveryData.DeliverySuccess = setDeliverySuccess.DeliverySuccess
        return {...newState}

        case "SET_DELIVERY_RETURN":
            const setDeliveryReturn = action as ISetDeliveryReturn
            newState.CourierTracking.CourierDeliveryData.DeliveryReturn = setDeliveryReturn.DeliveryReturn
        return {...newState}

        case "SET_DELIVERY_PENDING":
            const setDeliveryPending = action as ISetDeliveryPending
            newState.CourierTracking.CourierDeliveryData.DeliveryPending = setDeliveryPending.DeliveryPending
        return {...newState}

        case "SET_DELIVERY_TOTAL":
            const setDeliveryTotal = action as ISetDeliveryTotal
            newState.CourierTracking.CourierDeliveryData.DeliveryTotal = setDeliveryTotal.DeliveryTotal
        return {...newState}

        case "SET_TOGGLE_MAPS":
            const setToggleMaps = action as ISetToggleMaps
            newState.ToggleMaps = setToggleMaps.ToggleMaps
        return {...newState}

        case "SET_ERROR_CONNECTION_MAPS":
            const setErrorConnectionMaps = action as ISetErrorConnectionMaps
            newState.ErrorConnectionMaps = setErrorConnectionMaps.ErrorConnectionMaps
        return {...newState}

        case "SET_BRANCH_LAT":
            const setBranchLat = action as ISetBranchLat
            newState.BranchTracking.BranchLat = setBranchLat.BranchLat
        return {...newState}

        case "SET_BRANCH_LNG":
            const setBranchLng = action as ISetBranchLng
            newState.BranchTracking.BranchLng = setBranchLng.BranchLng
        return {...newState}

        case "CLEAR_ERROR_CONNECTION_MAPS":
            newState.ErrorConnectionMaps = null
        return {...newState}

        case "CLEAR_TOGGLE_MAPS":
            newState.ToggleMaps = null
        return {...newState}

        case "CLEAR_ZOOM_MAP":
            newState.ZoomMap = null
        return {...newState}

        case "CLEAR_ALL_BRANCH_TRACKING":
            newState.BranchTracking.BranchLat = null
            newState.BranchTracking.BranchLng = null
        return {...newState}

        case "CLEAR_COURIER_DELIVERY_DATA":
            newState.CourierTracking.CourierDeliveryData.CourierCode = null
            newState.CourierTracking.CourierDeliveryData.CourierBranch = null
            newState.CourierTracking.CourierDeliveryData.CourierPhone = null
            newState.CourierTracking.CourierDeliveryData.CourierLastSeen = null
            newState.CourierTracking.CourierDeliveryData.DeliverySuccess = null
            newState.CourierTracking.CourierDeliveryData.DeliveryReturn = null
            newState.CourierTracking.CourierDeliveryData.DeliveryPending = null
            newState.CourierTracking.CourierDeliveryData.DeliveryTotal = null
            newState.CourierTracking.CourierDeliveryData.DeliveryTotal = null
        return {...newState}

        case "CLEAR_TYPE_REALTIME":
            newState.TypeRealTime = null
        return {...newState}

        case "CLEAR_SELECT_COURIER":
            newState.CourierTracking.SelectCourier = null
        return {...newState}

        case "CLEAR_DETAIL_COURIER":
            newState.CourierTracking.DetailCourier = []
        return {...newState}
    }
    return {...newState}
}