import * as ActionAuth from '../../action/Auth';
import * as ActionDetailBranch from '../../action/Branch/ActionDetailBranch';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionTable from '../../action/Branch/ActionTableBranch';
import * as ActionToggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_AddBranch(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const GroupNameBranch = state.Branch.DetailBranch.GroupNameBranch;
  const NameBranch = state.Branch.DetailBranch.NameBranch;
  const AreaCode = state.Branch.DetailBranch.AreaCode;
  const Address = state.Branch.DetailBranch.Address;
  const PhoneNumber = state.Branch.DetailBranch.PhoneNumber;
  const PostalCode = state.Branch.DetailBranch.PostalCode;
  const Province = state.Branch.DetailBranch.Province;
  const City = state.Branch.DetailBranch.City;
  const SubDistrict = state.Branch.DetailBranch.SubDistrict;
  const Village = state.Branch.DetailBranch.Village;
  const LatitudeBranch = state.Branch.DetailBranch.LatitudeBranch;
  const LongitudeBranch = state.Branch.DetailBranch.LongitudeBranch;
  const AdminBranch =
    state.Branch.DetailBranch.SelectAdmin === false
      ? 0
      : state.Branch.DetailBranch.AdminBranch;
  try {
    yield put(changeLoadingTrue());

    yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Branch',
      {
        groupingBranch: GroupNameBranch === null ? 0 : GroupNameBranch,
        name: NameBranch,
        uniqueCode: AreaCode,
        address: Address,
        phoneNumber: PhoneNumber,
        postalCode: PostalCode,
        branchVillage: Village,
        branchDistrict: SubDistrict,
        branchProvince: Province,
        branchCity: City,
        headIdBranch: AdminBranch,
        latitude: LatitudeBranch,
        longitude: LongitudeBranch,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionDetailBranch.clearAllBranch());
    yield put(ActionTable.ClearTableBranch());
    yield put(ActionToggle.clearJenisToggle());
    yield put(changeLoadingFalse());
    yield put(ActionTable.GetTableBranch());

    Modal.success({
      title: <p style={{ color: '#31E116' }}>Berhasil di simpan</p>,

      onOk() {},
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(changeLoadingFalse());
  }
}
export function* S_AddAccountAdmin(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const Admin = state.Branch.DetailBranch.NameAdminBranch;
  const AreaCode = state.Branch.DetailBranch.AreaCode;
  try {
    yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Accounts`,
      {
        username: `${Admin}_${AreaCode}`,
        password: `${Admin}_${AreaCode}`,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_AddBranch() {
  yield takeLatest('SUBMIT_ADD_BRANCH', S_AddBranch);
}
export function* SM_AddAccountAdmin() {
  yield takeLatest('ADD_ACCOUNT_ADMIN', S_AddAccountAdmin);
}
