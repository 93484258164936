// DY: DEV CHANGES 4 JUL 24

import React, { useState } from 'react';
import { Row, Col, Table, Icon, Tooltip } from 'antd';
import moment from 'moment-timezone';
import SpinMaterial from 'materials/SpinMaterial';
import CardMaterial from 'materials/CardMaterial';
import SearchMatrial from 'materials/SearchMatrial';
import ButtonMaterial from 'materials/ButtonMaterial';
import { DataTableResProps } from 'interface/general';
import { trimText } from 'lib/util';

type TableManifestBrankasProps = {
  handleSearchName: (value: string) => void;
  dataTable: DataTableResProps | null;
  page: number;
  handlePage: (value: number) => void;
  handleShowModalDownloadListManifest: (data: Record<any, any>) => void;
  handleShowModalEdit: (id: number) => void;
  isLoadingTableManifestBrankas: boolean;
  setIsShowModalAdd: () => void;
  setIsShowModalScan: (id: number) => void;
  setIsShowModalDelete: (id: number) => void;
};

const RenderMultipleProduct = ({ products = [] }: { products: any[] }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: 8,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <p
        style={{
          marginBottom: 0,
          fontSize: '14px',
          fontWeight: 600,
          color: '#1890FF',
        }}
      >
        {products.length > 1 ? `${products.length} Produk` : products[0]}
      </p>
      {products.length > 1 && (
        <Tooltip
          placement="rightTop"
          title={
            <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
              {products.map((product, index) => (
                <p style={{ marginBottom: 0 }} key={index}>
                  {product}
                </p>
              ))}
            </div>
          }
        >
          <Icon
            type="question-circle"
            style={{ color: '#1890FF', fontSize: 14 }}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default function TableManifestBrankas({
  handleSearchName,
  dataTable,
  page,
  handlePage,
  handleShowModalDownloadListManifest,
  handleShowModalEdit,
  isLoadingTableManifestBrankas,
  setIsShowModalAdd,
  setIsShowModalScan,
  setIsShowModalDelete,
}: TableManifestBrankasProps) {
  const [nameSearch, setNameSearch] = useState('');
  const pagePagination = (dataTable && dataTable?.page + 1) || 0;
  const totalPagination = (dataTable && dataTable?.totalPages * 10) || 0;
  const looppage = (pagePagination - 1) * 10 + 1;
  const columnsTable = [
    {
      title: 'No',
      render: (text: any, record: any, index: any) => (
        <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
      ),
      width: 80,
    },
    {
      title: 'Tanggal Manifest',
      dataIndex: 'createdAt',
      render: (text: string) => <>{moment(text).format('DD-MM-YYYY')}</>,
    },
    {
      title: 'Kode Bundel',
      dataIndex: 'bundleCode',
      width: 170,
    },
    {
      title: 'Dibuat oleh',
      dataIndex: 'createdBy',
    },
    {
      title: 'Produk',
      width: 180,
      dataIndex: 'customerProductNames',
      render: item => <RenderMultipleProduct products={item} />,
    },

    {
      title: 'Jumlah Dokumen',
      dataIndex: 'totalDocument',
    },

    {
      title: 'Kode Brankas',
      dataIndex: 'brankasCode',
    },

    {
      title: 'Aksi',
      width: 180,
      render: record => (
        <div style={{ display: 'flex', gap: 8 }}>
          <ButtonMaterial
            icon="download"
            type="download"
            style={{ background: '#874FFF', color: 'white' }}
            tooltip="Download Manifest Brankas"
            handleSubmit={() => handleShowModalDownloadListManifest(record)}
          />

          <ButtonMaterial
            icon="barcode"
            type="success"
            tooltip="Scan Dokumen"
            style={{ background: '#31E116', color: 'white' }}
            handleSubmit={() => setIsShowModalScan(record.id)}
          />
          <ButtonMaterial
            icon="delete"
            type="danger"
            handleSubmit={() => setIsShowModalDelete(record.id)}
            tooltip="Hapus Manifest"
            disabled={record.totalDocument > 0}
          />
          <ButtonMaterial
            icon="edit"
            type="download"
            handleSubmit={() => handleShowModalEdit(record.id)}
            tooltip="Edit Manifest"
            style={{ background: '#FFE600', color: 'white' }}
          />
        </div>
      ),
    },
  ];

  return (
    <SpinMaterial spinning={isLoadingTableManifestBrankas} size={'large'}>
      <CardMaterial style={{ borderRadius: '10px 10px 0 0' }}>
        <Row>
          <Col span={18}>
            <div style={{ display: 'flex', gap: 16 }}>
              <span className="titleSearchCard">SEMUA DATA</span>
              <SearchMatrial
                style={{ borderRadius: '10px', width: '50%' }}
                placeholder="Cari berdasarkan bundel/nama/brankas"
                value={nameSearch}
                onChange={e => setNameSearch(trimText(e.target.value))}
                onPressEnter={e => handleSearchName(e.target.value)}
              />
            </div>
          </Col>
          <Col span={6}>
            <div className="floatRight">
              <ButtonMaterial
                teksButton={<b>TAMBAH MANIFEST</b>}
                className={'btnLink'}
                type={'link'}
                handleSubmit={setIsShowModalAdd}
              />

              <ButtonMaterial
                shape={'circle'}
                className={'btnAdd'}
                icon={'plus'}
                handleSubmit={setIsShowModalAdd}
              />
            </div>
          </Col>
        </Row>
      </CardMaterial>
      <Table
        columns={columnsTable}
        bordered
        dataSource={dataTable?.data || []}
        pagination={{
          onChange: page => {
            handlePage(page - 1);
          },
          pageSize: 10,
          total: totalPagination,
          current: pagePagination === 0 ? 1 : pagePagination,
        }}
        footer={() => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              gap: 8,
            }}
          >
            <p style={{ margin: 0, fontWeight: 500 }}>
              Total Entri ({dataTable?.totalDatas || 0})
            </p>
          </div>
        )}
        scroll={{ x: 1200 }}
      />
    </SpinMaterial>
  );
}
