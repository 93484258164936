import * as ActionAssigningCourier from 'core/action/AssigningCourier/ActionAssigningCourier';
import * as ActionAuth from 'core/action/Auth';
import * as ActionListBranch from 'core/action/Branch/ActionAllBranch';
import * as ActionListCourier from 'core/action/ManageAccount/Courier/ActionTableCourier';
import * as ActionLogin from 'module/Login/Store';
import * as ActionToggle from 'core/action/toggle/index';

import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import AssigningCourierSAComponent from 'module/AssigningCourier/Component/AsigningCourierSAComponent';
import { connect } from 'react-redux';

interface IProps {
  Weight: any;
  courierId: any;
  deliveryType: any;
  envelopeType: any;
  area: any;
  branchId: any;
  WorB: any;
  Koli: any;
  ListKoli: any;
  CheckBox: any;
  CheckBoxKoli: any;
  ListBranch: any;
  ListCourier: any;
  AssignStatus: any;
  DetailWorB: any;
  DetailCourierCode: any;
  actionToggle: any;
  actionListCourier: any;
  actionAssigningCourier: any;
  actionListBranch: any;
  actionLogin: any;
  actionAuth: any;
}

function AssigningCourierSAContainer({
  Weight,
  courierId,
  deliveryType,
  envelopeType,
  area,
  branchId,
  WorB,
  Koli,
  ListKoli,
  CheckBox,
  CheckBoxKoli,
  ListBranch,
  ListCourier,
  AssignStatus,
  DetailWorB,
  DetailCourierCode,
  actionToggle,
  actionListCourier,
  actionAssigningCourier,
  actionListBranch,
  actionLogin,
  actionAuth,
}: IProps) {
  const [ValidateDeliveryType, setValidateDeliveryType]: any = useState(null);
  const [ValidateEnvelopeType, setValidateEnvelopeType]: any = useState(null);
  const [ValidateArea, setValidateArea]: any = useState(null);
  const [ValidateCourier, setValidateCourier]: any = useState(null);
  const [ValidateBranch, setValidateBranch]: any = useState(null);
  const [ValidateWorB, setValidateWorB]: any = useState(null);

  useEffect(() => {
    actionListBranch.getAllBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit() {
    await actionAuth.changeStatusTokenRefresh(true);
    await actionAssigningCourier.submitAssigningCourierWorBSA();
    actionAssigningCourier.ClearWorBAc();
    actionLogin.submitTokenRefrash();
  }
  function handleReset() {
    actionAssigningCourier.ResetFormAc();
    setValidateDeliveryType(null);
    setValidateEnvelopeType(null);
    setValidateArea(null);
    setValidateCourier(null);
    setValidateBranch(null);
    setValidateWorB(null);
  }
  function handlePutDeliveryType(event: any) {
    actionAssigningCourier.changeDeliveryType(event);
    if (event === null) {
      setValidateDeliveryType('error');
    } else {
      setValidateDeliveryType(null);
    }
  }
  function handleBlurDeliveryType() {
    if (deliveryType === null) {
      setValidateDeliveryType('error');
    } else {
      setValidateDeliveryType(null);
    }
  }
  function handlePutEnvelopeType(event: any) {
    actionAssigningCourier.changeEnvelopeType(event);
    if (event === null) {
      setValidateEnvelopeType('error');
    } else {
      setValidateEnvelopeType(null);
    }
  }
  function handleBlurEnvelopeType() {
    if (envelopeType === null) {
      setValidateEnvelopeType('error');
    } else {
      setValidateEnvelopeType(null);
    }
  }
  function handlePutArea(event: any) {
    actionAssigningCourier.changeArea(event);
    if (event === null) {
      setValidateArea('error');
    } else {
      setValidateArea(null);
    }
  }
  function handleBlurArea() {
    if (area === null) {
      setValidateArea('error');
    } else {
      setValidateArea(null);
    }
  }
  function handlePutBranch(event: any) {
    actionAssigningCourier.changeBranchId(event);
    if (event === null) {
      setValidateBranch('error');
    } else {
      setValidateBranch(null);
    }
  }
  function handleBlurBranch() {
    if (branchId === null) {
      setValidateBranch('error');
    } else {
      setValidateBranch(null);
    }
  }
  function handlePutCourier(event: any, label: any) {
    actionAssigningCourier.changeCourierId(event);
    actionAssigningCourier.changeCourierCode(label.props.label);
    if (event === null) {
      setValidateCourier('error');
    } else {
      setValidateCourier(null);
    }
  }
  function handleBlurCourier() {
    if (courierId === null) {
      setValidateCourier('error');
    } else {
      setValidateCourier(null);
    }
  }
  function handlePutWorB(event: any) {
    actionAssigningCourier.changeWorB(event.target.value);

    if (event.target.value === '' || event.target.value === null) {
      setValidateWorB('error');
    } else if (event.target.value.length < 8) {
      setValidateWorB('notMinChar');
    } else {
      setValidateWorB('');
    }
  }
  function handlePutCheckbox2(event: any) {
    actionAssigningCourier.changeCheckBox2(event.target.checked);
    if (event.target.checked === false) {
      actionAssigningCourier.ClearKoliOrWeightAc();
    }
  }
  function handlePutCheckbox(event: any) {
    actionAssigningCourier.changeCheckBox(event.target.checked);
  }
  function handleGetCourier() {
    actionListCourier.getCourierTableBranch();
  }
  function handlePutKoli(e: any, index: any) {
    if (CheckBoxKoli === true) {
      const valueAddKoli = e;
      const reg = /^-?\d*(\.\d*)?$/;
      if (reg.test(valueAddKoli)) {
        ListKoli[index] = valueAddKoli;

        actionAssigningCourier.changeListKoli(ListKoli);
        actionAssigningCourier.changeKoli(ListKoli.join(';'));
      }
    } else {
      actionAssigningCourier.changeWeight(e);
    }
  }
  function handleAddListKoli() {
    actionAssigningCourier.changeListKoli([...ListKoli, '']);
  }
  function handleRemoveKoli(event: any) {
    actionAssigningCourier.changeListKoli(ListKoli.splice(event, 1));
    if (event > 0) {
      actionAssigningCourier.changeListKoli(ListKoli);
      actionAssigningCourier.changeKoli(ListKoli.join(';'));
    } else if (event === 0 && ListKoli.length > 1) {
      actionAssigningCourier.changeListKoli(ListKoli);
      actionAssigningCourier.changeKoli(ListKoli.join(';'));
    } else if (event === 0 && ListKoli.length === 0) {
      actionAssigningCourier.ClearListKoliAc();
      actionAssigningCourier.ClearKoliAc();
    }
  }

  return (
    <AssigningCourierSAComponent
      DeliveryType={
        deliveryType === null || deliveryType === undefined ? (
          <span style={{ color: '#969696' }}>Pilih paket pengiriman</span>
        ) : (
          deliveryType
        )
      }
      handlePutDeliveryType={handlePutDeliveryType}
      handleBlurDeliveryType={handleBlurDeliveryType}
      ValidateDeliveryType={ValidateDeliveryType === 'error' ? 'error' : ''}
      helpValidateDeliveryType={
        ValidateDeliveryType === 'error' ? 'Jenis pengiriman wajib diisi' : ''
      }
      EnvelopeType={
        envelopeType === null || envelopeType === undefined ? (
          <span style={{ color: '#969696' }}>Pilih jenis amplop</span>
        ) : (
          envelopeType
        )
      }
      handlePutEnvelopeType={handlePutEnvelopeType}
      handleBlurEnvelopeType={handleBlurEnvelopeType}
      ValidateEnvelopeType={ValidateEnvelopeType === 'error' ? 'error' : ''}
      helpValidateEnvelopeType={
        ValidateEnvelopeType === 'error' ? 'Jenis amplop wajib diisi' : ''
      }
      Area={
        area === null || area === undefined ? (
          <span style={{ color: '#969696' }}>Pilih wilayah</span>
        ) : (
          area
        )
      }
      handlePutArea={handlePutArea}
      handleBlurArea={handleBlurArea}
      ValidateArea={ValidateArea === 'error' ? 'error' : ''}
      helpValidateArea={
        ValidateArea === 'error' ? 'Jenis amplop wajib diisi' : ''
      }
      ListBranch={ListBranch}
      Branch={
        branchId === null || branchId === undefined ? (
          <span style={{ color: '#969696' }}>Pilih posko</span>
        ) : (
          branchId
        )
      }
      handlePutBranch={handlePutBranch}
      handleBlurBranch={handleBlurBranch}
      ValidateBranch={ValidateBranch === 'error' ? 'error' : ''}
      helpValidateBranch={ValidateBranch === 'error' ? 'Posko wajib diisi' : ''}
      handleGetCourier={handleGetCourier}
      ListCourier={ListCourier}
      Courier={
        courierId === null || courierId === undefined ? (
          <span style={{ color: '#969696' }}>Pilih kurir</span>
        ) : (
          courierId
        )
      }
      handlePutCourier={handlePutCourier}
      handleBlurCourier={handleBlurCourier}
      ValidateCourierForm={
        branchId === null || branchId === '' || branchId === undefined
          ? true
          : false
      }
      ValidateCourier={ValidateCourier === 'error' ? 'error' : ''}
      helpValidateCourier={
        ValidateCourier === 'error' ? 'Posko wajib diisi' : ''
      }
      WorB={WorB}
      handlePutWorB={handlePutWorB}
      ValidateWorB={
        ValidateWorB === 'error' || ValidateWorB === 'notMinChar' ? 'error' : ''
      }
      helpValidateBorW={
        ValidateWorB === 'error'
          ? 'Barcode / Waybill tidak boleh kosong'
          : ValidateWorB === 'notMinChar'
          ? 'Waybill atau barcode kurang dari 7'
          : ''
      }
      handlePutCheckbox={handlePutCheckbox}
      CheckBox={CheckBox}
      handlePutCheckbox2={handlePutCheckbox2}
      CheckBoxKoli={CheckBoxKoli}
      ListKoli={ListKoli}
      handleAddListKoli={handleAddListKoli}
      handlePutKoli={handlePutKoli}
      handleRemoveKoli={handleRemoveKoli}
      Koli={Koli}
      Weight={Weight}
      AssignStatus={AssignStatus}
      DetailWorB={DetailWorB}
      DetailCourierCode={DetailCourierCode}
      validateReset={
        deliveryType !== null ||
        area !== null ||
        branchId !== null ||
        courierId !== null ||
        WorB !== null ||
        Weight !== 0 ||
        Koli !== 0
          ? false
          : true
      }
      validateSubmit={
        deliveryType === null ||
        envelopeType === null ||
        area === null ||
        branchId === null ||
        WorB === null ||
        WorB === '' ||
        (CheckBoxKoli === false &&
        (Weight === 0 || Weight === '') &&
        (Koli === 0 || Koli === '')
          ? true
          : false) ||
        (CheckBoxKoli === true &&
          (Koli === 0 || Koli === '') &&
          (Weight === 0 || Weight === ''))
          ? true
          : false
      }
      handleReset={handleReset}
      handleSubmit={handleSubmit}
    />
  );
}

const mapStateProps = (state: any) => ({
  deliveryType: state.AssigningCourier.deliveryType,
  envelopeType: state.AssigningCourier.envelopeType,
  area: state.AssigningCourier.area,
  branchId: state.AssigningCourier.branchId,
  courierId: state.AssigningCourier.courierId,
  WorB: state.AssigningCourier.WorB,
  Koli: state.AssigningCourier.koli,
  Weight: state.AssigningCourier.weight,
  ListKoli: state.AssigningCourier.ListKoli,
  CheckBox: state.AssigningCourier.CheckBox,
  CheckBoxKoli: state.AssigningCourier.CheckBoxKoli,
  ListBranch: state.Branch.AllBranch,
  ListCourier: state.Table.TableCourier,
  AssignStatus: state.AssigningCourier.PropertiesAssignCourier.AssignStatus,
  DetailWorB: state.AssigningCourier.PropertiesAssignCourier.DetailWorB,
  DetailCourierCode:
    state.AssigningCourier.PropertiesAssignCourier.DetailCourierCode,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionListCourier: bindActionCreators(ActionListCourier, dispatch),
  actionAssigningCourier: bindActionCreators(ActionAssigningCourier, dispatch),
  actionListBranch: bindActionCreators(ActionListBranch, dispatch),
  actionLogin: bindActionCreators(ActionLogin, dispatch),
  actionAuth: bindActionCreators(ActionAuth, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(AssigningCourierSAContainer);
