import * as ActionAuth from '../../action/Auth';
import * as ActionComplaintList from "../../action/Complaint/ActionComplaintList";
import * as ActionFormComplaint from '../../action/Complaint/ActionFormComplaint';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_submitEditComplaint(action:any) {
  const state = yield select();
    const complaintId = state.Complaint.complaintProperties.complaintId
    const complaintDetail = state.Complaint.complaintDetail
    const token = state.Auth.Token;
    const config = {
      Authorization: `bearer ${token}`,
    };
    const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/Complaint/UpdateInvestigasiQuestion/'+complaintId,

      {
        question: complaintDetail
      },
      { headers: config }
    );
    if(statusTokenRefresh===true)
        {
            yield put(ActionAuth.changeStatusTokenRefresh(false))
        }
    yield put(ActionFormComplaint.clearComplaintForm());
    yield put(ActionComplaintList.getComplaintList())
    yield put(toggle.changeModal());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil Mengubah Komplain Tiket</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));

  }
}
export function* SM_submitEditComplaint() {

  yield takeLatest('SUBMIT_EDIT_COMPLAINT', S_submitEditComplaint);
}
