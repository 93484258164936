import * as ActionAuth from '../../action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionPagination from '../../action/Pagination/ActionPagination';
import * as ActionRestoreData from '../../action/RestoreData/ActionRestoreData';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  changeJenisToggle,
  changeLoadingFalse,
  changeLoadingTrue,
} from '../../action/toggle';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_getDataTableRestoreData(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const jenisToggle = state.Toggle.jenisToggle;

  const LastAccess = state.LastAccess.Fiture;
  const uploadDate = moment(
    state.RestoreData.FormRestoreData.UploadDate
  ).format('YYYYDDMM');
  const uploadDateW = moment(
    state.RestoreData.FormRestoreData.UploadDateW
  ).format('YYYYDDMM');
  const radioButton =
    LastAccess === null || LastAccess === 'restoreDataCsv'
      ? state.RestoreData.FormRestoreData.RadioButton === 'Delete'
        ? true
        : false
      : state.RestoreData.FormRestoreData.RadioButtonW === 'Delete'
      ? true
      : false;

  yield put(ActionPagination.setPagePagination(1));
  try {
    yield put(changeLoadingTrue());
    const { data } =
      LastAccess === null || LastAccess === 'restoreDataCsv'
        ? yield call(
            axios.get,
            `${process.env.REACT_APP_URL}/Delivery/GetDeleteRestoreDataFile/${uploadDate}/${radioButton}`,
            { headers: config }
          )
        : yield call(
            axios.get,
            `${process.env.REACT_APP_URL}/Delivery/GetDeleteRestoreDataWaybill/${uploadDateW}`,
            { headers: config }
          );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    const newData =
      LastAccess === null || LastAccess === 'restoreDataCsv'
        ? data.map(opt => ({ ...opt, selected: false }))
        : data.map(opt => ({
            ...opt,
            selected: false,
            waybill: `${opt.waybillCode}`,
          }));
    const ListData = newData.filter(item => item.deleted === !radioButton);

    yield put(
      LastAccess === null || LastAccess === 'restoreDataCsv'
        ? ActionRestoreData.setCountRestoreData(ListData.length)
        : ActionRestoreData.setCountWaybillRestoreData(ListData.length)
    );
    yield put(
      LastAccess === null || LastAccess === 'restoreDataCsv'
        ? ActionRestoreData.setListRestoreData(ListData)
        : ActionRestoreData.setListWaybillRestoreData(ListData)
    );
    if (jenisToggle === 'searchMenuHapusRestore') {
      if (ListData.length === 0) {
        Modal.error({
          title: <p style={{ color: '#FF0000' }}>Pencarian Tidak Ditemukan</p>,
          onOk() {},
        });
      }
    }
    yield put(changeJenisToggle(null));
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(changeLoadingFalse());
  }
}

export function* SM_getDataTableRestoreData() {
  yield takeLatest('SUBMIT_GET_DATA_RESTORE_DATA', S_getDataTableRestoreData);
}
