export function getCustomerAccount()
{
    return({
        type:"GET_CUSTOMER_ACCOUNT"
    })
}
export function setCustomerAccount(CustomerListAccount:any)
{
    return({
        type:"SET_CUSTOMER_ACCOUNT",
        CustomerListAccount
    })
}
export function changeIdCustomerAccount(IdCustomerAccount:any)
{
    return({
        type:"CHANGE_ID_CUSTOMER_ACCOUNT",
        IdCustomerAccount
    })
}
export function changeFirstNameAccount(FirstName:any)
{
    return({
        type:"CHANGE_FIRST_NAME_ACCOUNT",
        FirstName
    })
}
export function changeLastNameAccount(LastName:any)
{
    return({
        type:"CHANGE_LAST_NAME_ACCOUNT",
        LastName
    })
}
export function changePhoneNumberAccount(PhoneNumber:any)
{
    return({
        type:"CHANGE_PHONE_NUMBER_ACCOUNT",
        PhoneNumber
    })
}
export function changeEmailAccount(Email:any)
{
    return({
        type:"CHANGE_EMAIL_ACCOUNT",
        Email
    })
}
export function changeUserNameAccount(Username:any)
{
    return({
        type:"CHANGE_USERNAME_ACCOUNT",
        Username
    })
}
export function changeAddressAccount(Address:any)
{
    return({
        type:"CHANGE_ADDRESS_ACCOUNT",
        Address
    })
}
export function changeStatusAccount(StatusAccount:any)
{
    return({
        type:"CHANGE_STATUS_ACCOUNT",
        StatusAccount
    })
}
export function changeCustomerProduct(CustomerProductId:any)
{
    return({
        type:"CHANGE_CUSTOMER_PRODUCT_ID",
        CustomerProductId
    })
}
export function generateUsernameCustomerAccount()
{
    return({
        type:"GENERATE_CUSTOMER_CUSTOMER_ACCOUNT"
    })
}
export function getCustomerAccountDetail()
{
    return({
        type:"GET_CUSTOMER_ACCOUNT_DETAIL"
    })
}
export function clearDataCustomerAccount()
{
    return({
        type:"CLEAR_DATA_CUSTOMER_ACCOUNT"
    })
}
export function clearCustomerAccount()
{
    return({
        type:"CLEAR_CUSTOMER_ACCOUNT"
    })
}