import * as ActionProgressCourier from 'core/action/Beranda/ActionProgressCourier';
import * as ActionToggle from 'core/action/toggle/index';

import React, { useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';

import ProgressCourierComponent from 'module/Dashboard/Component/Pengiriman/ProgressCourierComponent';
import { connect } from 'react-redux';
// import jwt_decode from 'jwt-decode';
import moment from 'moment';

interface IProps {
  actionProgressCourier: any;
  isloading: any;
  actionToggle: any;
  toggleType: any;
  StartDate: any;
  IdBranch: any;
  listProgressCourier: any;
  ValueSearch: any;
}
// interface IState {
//   sRT: any;
//   searchProgressCourier: any;
//   nameSearch: any;
// }
function ProgressCourierContainer({
  listProgressCourier,
  actionToggle,
  actionProgressCourier,
  StartDate,
  IdBranch,
  isloading,
  ValueSearch,
  toggleType,
}: IProps) {
  // constructor(props: any) {
  //   super(props);
  //   this.state = {
  //     sRT: null,
  //     searchProgressCourier: listProgressCourier,
  //     nameSearch: false,
  //   };
  //   this.handleSearch = this.handleSearch.bind(this);
  //   this.handleResetSearch = this.handleResetSearch.bind(this);
  //   this.handlePutStartDate = this.handlePutStartDate.bind(this);
  //   this.handleGetDataOnSearch = this.handleGetDataOnSearch.bind(this);
  //   this.handleDetailCourier = this.handleDetailCourier.bind(this);
  // }

  useEffect(() => {
    const month = new Date();
    const onemonth = month.setMonth(month.getMonth() - 1);
    actionProgressCourier.setStartDateProgressCourier(moment(onemonth));
    actionProgressCourier.getDataProgressCourier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSearch(event: any) {
    actionToggle.changeValueSearch(event.target.value.toLowerCase());
  }

  function handleResetSearch(clearFilters: any) {
    clearFilters();
    // this.setState({ sRT: null });
  }

  function handlePutStartDate(event: any) {
    actionProgressCourier.setStartDateProgressCourier(event);
  }

  function handleGetDataOnSearch() {
    actionProgressCourier.getDataProgressCourier();
  }

  // function getDecodedAccessToken(token: string): any {
  //   try {
  //     return jwt_decode(token);
  //   } catch (Error) {
  //     return null;
  //   }
  // }

  // async componentWillMount() {
  //   const month = new Date();
  //   const onemonth = month.setMonth(month.getMonth() - 1);

  //   await actionProgressCourier.setStartDateProgressCourier(
  //     moment(onemonth)
  //   );
  //   actionProgressCourier.getDataProgressCourier();
  // }

  // componentWillUnmount() {
  //   actionProgressCourier.changeClearProgressCourier();
  // }

  function handleDetailCourier(event: any) {
    const dateNow = new Date();
    const url =
      '/dataDelivery?' +
      moment(StartDate).format('YYYYMMDD') +
      '?' +
      moment(dateNow).format('YYYYMMDD') +
      '?' +
      IdBranch +
      '?' +
      event;
    window.open(url, '_blank');
  }
  return (
    <div>
      <ProgressCourierComponent
        listProgressCourier={listProgressCourier}
        StartDate={StartDate}
        isloading={isloading}
        ValueSearch={ValueSearch}
        toggleType={toggleType}
        nameSearch={false}
        searchProgressCourier={listProgressCourier}
        handlePutStartDate={handlePutStartDate}
        handleSearch={handleSearch}
        handleResetSearch={handleResetSearch}
        handleGetDataOnSearch={handleGetDataOnSearch}
        handleDetailCourier={handleDetailCourier}
      />
    </div>
  );
}
const mapStateProps = (state: any) => ({
  isloading: state.Toggle.isLoading,
  IdBranch: state.Auth.BranchAuth,
  ValueSearch: state.Toggle.ValueSearch,
  toggleType: state.Toggle.jenisToggle,
  StartDate: state.Beranda.ProgressCourier.StartDate,
  listProgressCourier: state.Beranda.ListProgressCourier,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionProgressCourier: bindActionCreators(ActionProgressCourier, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(ProgressCourierContainer);
