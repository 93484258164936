import {Action} from 'redux';

const initialState = 
{
    complaintProperties:{
        key:null,
        complaintId:null,
        complaintStatus:"new",
        filterStatus:"0%2C1",
    },
    complaintTicketCode : null,
    complaintWaybill : null,
    complaintWaybillDetail : null,
    complaintBarcodeDetail : null,
    complaintNoTelpDetail : null,
    complaintNoTelp : null,
    complaintDetail : null,
    complaintResultInvestigation : null,
    complaintOwnerName : null,
    complaintPostCode : null,
    complaintCity : null,
    complaintAddress : null,
    complaintList : [],
}

export interface IAuthAction extends Action<string>
{

}

export interface IChangeKey extends IAuthAction
{
    key : any
}
export interface IChangeComplaintStatus extends IAuthAction
{
    complaintStatus : any
}
export interface IChangeComplaintId extends IAuthAction
{
    complaintId : any
}
export interface IChangeFilterStatus extends IAuthAction
{
    filterStatus : any
}
export interface IChangeComplaintTicketCode extends IAuthAction
{
    complaintTicketCode : any
}
export interface IChangeComplaintWaybill extends IAuthAction
{
    complaintWaybill : any
}

export interface IChangeComplaintDetailWaybill extends IAuthAction
{
    complaintWaybillDetail : any
}
export interface IChangeComplaintDetailBarcode extends IAuthAction
{
    complaintBarcodeDetail : any
}

export interface IChangeComplaintNoTelp extends IAuthAction
{
    complaintNoTelp : any
}
export interface IChangeComplaintDetailNoTelp extends IAuthAction
{
    complaintNoTelpDetail : any
}

export interface IChangeComplaintDetail extends IAuthAction
{
    complaintDetail : any
}
export interface IChangeComplaintResultInvestigation extends IAuthAction
{
    complaintResultInvestigation : any
}
export interface IChangeComplaintOwnerName extends IAuthAction
{
    complaintOwnerName : any
}
export interface IChangeComplaintPostCode extends IAuthAction
{
    complaintPostCode : any
}
export interface IChangeComplaintCity extends IAuthAction
{
    complaintCity : any
}
export interface IChangeComplaintAddress extends IAuthAction
{
    complaintAddress : any
}
export interface ISetComplaintList extends IAuthAction
{
    complaintList : any
}

export default function AuthReducerComplaint(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "CHANGE_COMPLAINT_ID":
            const changeComplaintId = action as IChangeComplaintId
            newState.complaintProperties.complaintId = changeComplaintId.complaintId
            
        return {...newState}
        case "CHANGE_COMPLAINT_KEY":
            const changeComplaintKey = action as IChangeKey
            newState.complaintProperties.key = changeComplaintKey.key
            
        return {...newState}
        case "CHANGE_COMPLAINT_STATUS":
            const changeComplaintStatus = action as IChangeComplaintStatus
            newState.complaintProperties.complaintStatus = changeComplaintStatus.complaintStatus
            
        return {...newState}
        case "CHANGE_FILTER_STATUS":
            const changeFilterStatus = action as IChangeFilterStatus
            newState.complaintProperties.filterStatus = changeFilterStatus.filterStatus
            
        return {...newState}
        case "CHANGE_COMPLAINT_TICKET_CODE":
            const changeComplaintTicketCode = action as IChangeComplaintTicketCode
            newState.complaintTicketCode = changeComplaintTicketCode.complaintTicketCode
            
        return {...newState}
        
        case "CHANGE_COMPLAINT_WAYBILL":
            const changeComplaintWaybill = action as IChangeComplaintWaybill
            newState.complaintWaybill = changeComplaintWaybill.complaintWaybill
            
        return {...newState}
        
        case "CHANGE_COMPLAINT_WAYBILL_DETAIL":
            const changeComplaintWaybillDetail = action as IChangeComplaintDetailWaybill
            newState.complaintWaybillDetail = changeComplaintWaybillDetail.complaintWaybillDetail
            
        return {...newState}
        
        case "CHANGE_COMPLAINT_BARCODE_DETAIL":
            const changeComplaintBarcodeDetail = action as IChangeComplaintDetailBarcode
            newState.complaintBarcodeDetail = changeComplaintBarcodeDetail.complaintBarcodeDetail
            
        return {...newState}
        
        case "CHANGE_COMPLAINT_NO_TELP":
            const changeComplaintNoTelp = action as IChangeComplaintNoTelp
            newState.complaintNoTelp = changeComplaintNoTelp.complaintNoTelp
            
        return {...newState}
        
        case "CHANGE_COMPLAINT_NO_TELP_DETAIL":
            const changeComplaintNoTelpDetail = action as IChangeComplaintDetailNoTelp
            newState.complaintNoTelpDetail = changeComplaintNoTelpDetail.complaintNoTelpDetail
            
        return {...newState}
        
        case "CHANGE_COMPLAINT_DETAIL":
            const changeComplaintDetail = action as IChangeComplaintDetail
            newState.complaintDetail = changeComplaintDetail.complaintDetail
            
        return {...newState}
        
        case "CHANGE_COMPLAINT_RESULT_INVESTIGATION":
            const changeComplaintResultInvestigation = action as IChangeComplaintResultInvestigation
            newState.complaintResultInvestigation = changeComplaintResultInvestigation.complaintResultInvestigation
            
        return {...newState}
        
        case "CHANGE_COMPLAINT_OWNER_NAME":
            const changeComplaintOwnerName = action as IChangeComplaintOwnerName
            newState.complaintOwnerName = changeComplaintOwnerName.complaintOwnerName
            
        return {...newState}

        case "CHANGE_COMPLAINT_POST_CODE":
            const changeComplaintPostCode = action as IChangeComplaintPostCode
            newState.complaintPostCode = changeComplaintPostCode.complaintPostCode
            
        return {...newState}
        
        case "CHANGE_COMPLAINT_CITY":
            const changeComplaintCity = action as IChangeComplaintCity
            newState.complaintCity = changeComplaintCity.complaintCity
            
        return {...newState}
        
        case "CHANGE_COMPLAINT_ADDRESS":
            const changeComplaintAddress = action as IChangeComplaintAddress
            newState.complaintAddress = changeComplaintAddress.complaintAddress
            
        return {...newState}

        case "SET_COMPLAINT_LIST":
            const setComplaintList = action as ISetComplaintList
            newState.complaintList = setComplaintList.complaintList
            
        return {...newState}

        case "CLEAR_COMPLAINT_FORM":
            
            newState.complaintTicketCode = null
            newState.complaintWaybill = null
            newState.complaintWaybillDetail = null
            newState.complaintBarcodeDetail = null
            newState.complaintNoTelp = null
            newState.complaintNoTelpDetail = null
            newState.complaintDetail = null
            newState.complaintResultInvestigation = null
            newState.complaintOwnerName = null
            newState.complaintPostCode = null
            newState.complaintCity = null
            newState.complaintAddress = null
            
        return {...newState}
        
        case "CLEAR_COMPLAINT_PROPERTIES":

            newState.complaintProperties.complaintId = null
            newState.complaintProperties.key = null
            newState.complaintProperties.complaintStatus = "new"
            newState.complaintProperties.filterStatus = "0%2C1"
            
        return {...newState}

        case "CLEAR_COMPLAINT_LIST":
            
            newState.complaintList = []
            
        return {...newState}
        
    }
    return state
}