import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import FormCustomerFormatReport, {
  InitialValueFormCustomerFormat,
} from '../../components/FormCustomerFormatReport';
import { postCustomerFormatReport } from 'services/CustomerService';
import ModalStatus from 'materials/ModalStatus';

type AddCustomerFormatReportProps = {
  show: boolean;
  onClickCancel: () => void;
  customerName: string;
  dataCsvList: any[];
  customerId: number;
  refetch: () => void;
};

export default function AddCustomerFormatReport({
  show,
  onClickCancel,
  customerName,
  dataCsvList = [],
  customerId,
  refetch,
}: AddCustomerFormatReportProps) {
  const defaultForm = {
    isActive: false,
    nameFormatReport: '',
    formatFile: '',
    settingFile: '',
    periode: '',
    delimited: '',
    disburseData: '',
    noHeader: false,
    showInPortal: false,
  };
  const [customerFormatData, setCustomerFormatData] =
    useState<InitialValueFormCustomerFormat>(defaultForm);

  const { mutate: mutateFormatReport, isLoading } = useMutation(
    postCustomerFormatReport,
    {
      onSuccess: res => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Berhasil menambahkan format laporan',
          });
          refetch();
          onClickCancel();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    }
  );

  const handleOnChange = (name: string, value: any) => {
    setCustomerFormatData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClearForm = () => {
    setCustomerFormatData(defaultForm);
  };

  const handleSubmit = () => {
    const data = {
      name: customerFormatData.nameFormatReport,
      format: customerFormatData.formatFile,
      delimited: customerFormatData.delimited,
      periode: customerFormatData.periode,
      customerId: customerId,
      customerCsvId: customerFormatData.settingFile,
      headerHide: customerFormatData.noHeader,
      reportPortal: customerFormatData.showInPortal,
      isActive: customerFormatData.isActive,
      customReportType: customerFormatData.disburseData,
    };
    mutateFormatReport(data);
  };

  return (
    <FormCustomerFormatReport
      isLoading={isLoading}
      show={show}
      onClickCancel={() => {
        onClickCancel();
        handleClearForm();
      }}
      customerName={customerName}
      dataCsvList={dataCsvList}
      handleOnChange={handleOnChange}
      initialValue={customerFormatData}
      handleSubmit={handleSubmit}
    />
  );
}
