export function changeGetDataCustomerManifest() {
  return {
    type: 'CHANGE_GET_DATA_CUSTOMER_MANIFEST',
  };
}
export function changeRadioButtonCustomerManifestForm(RadioButton: any) {
  return {
    type: 'CHANGE_RADIO_BUTTON_CUSTOMER_MANIFEST_FORM',
    RadioButton,
  };
}
export function changeScanDateCustomerManifestForm(ScanDate: any) {
  return {
    type: 'CHANGE_SCAN_DATE_CUSTOMER_MANIFEST_FORM',
    ScanDate,
  };
}
export function changeCustomerCustomerManifestForm(Customer: any) {
  return {
    type: 'CHANGE_CUSTOMER_CUSTOMER_MANIFEST_FORM',
    Customer,
  };
}
export function changeTreatmentDocumentCustomerManifestForm(
  TreatmentDocument: any
) {
  return {
    type: 'CHANGE_TREATMENT_DOCUMENT_MANIFEST_FORM',
    TreatmentDocument,
  };
}
export function changeProductCustomerManifestForm(Product: any) {
  return {
    type: 'CHANGE_PRODUCT_CUSTOMER_MANIFEST_FORM',
    Product,
  };
}
export function changeCountCustomerManifestForm(Count: any) {
  return {
    type: 'CHANGE_COUNT_CUSTOMER_MANIFEST_FORM',
    Count,
  };
}
export function changeReturnNameCustomerManifestForm(ReturnName: any) {
  return {
    type: 'CHANGE_RETURN_NAME_CUSTOMER_MANIFEST_FORM',
    ReturnName,
  };
}
export function changeReturnAddressCustomerManifestForm(Address: any) {
  return {
    type: 'CHANGE_RETURN_ADDRESS_CUSTOMER_MANIFEST_FORM',
    Address,
  };
}
export function changeReturnCityCustomerManifestForm(City: any) {
  return {
    type: 'CHANGE_RETURN_CITY_CUSTOMER_MANIFEST_FORM',
    City,
  };
}
export function changeReturnDateCustomerManifestForm(ReturnDate: any) {
  return {
    type: 'CHANGE_RETURN_DATE_CUSTOMER_MANIFEST_FORM',
    ReturnDate,
  };
}
export function changeTableCustomerManifestForm(TableCustomerManifest: any) {
  return {
    type: 'CHANGE_TABLE_CUSTOMER_MANIFEST_FORM',
    TableCustomerManifest,
  };
}
export function changeListCustomerManifestForm(ListCustomerManifest: any) {
  return {
    type: 'CHANGE_LIST_CUSTOMER_MANIFEST_FORM',
    ListCustomerManifest,
  };
}
export function removeListCustomerManifestForm(ListCustomerManifest: any) {
  return {
    type: 'REMOVE_LIST_CUSTOMER_MANIFEST_FORM',
    ListCustomerManifest,
  };
}
export function downloadCustomerManifestForm(treatmentDocument?: string) {
  return {
    type: 'DOWNLOAD_CUSTOMER_MANIFEST_FORM',
    treatmentDocument,
  };
}
export function clearCustomerManifestForm() {
  return {
    type: 'CLEAR_CUSTOMER_MANIFEST_FORM',
  };
}
export function clearTableCustomerManifestForm() {
  return {
    type: 'CLEAR_TABLE_CUSTOMER_MANIFEST_FORM',
  };
}
export function clearListCustomerrManifestForm() {
  return {
    type: 'CLEAR_LIST_CUSTOMER_MANIFEST_FORM',
  };
}
export function clearCustomerManifestPopupForm() {
  return {
    type: 'CLEAR_CUSTOMER_MANIFEST_POPUP_FORM',
  };
}
