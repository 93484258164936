import { Col, DatePicker, Form, Icon, Input, Row, Select } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../../materials/CardMaterial';
import SpinMaterial from '../../../../../materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

const { Option } = Select;
interface IProps {
  isLoading: any;

  uploadDate: any;
  customer: any;
  documentType: any;
  customerProduct: any;
  formatCsv: any;
  defaultCsv: any;
  fileCsv: any;
  fileName: any;
  formatFile: any;
  valueUploadDate: any;
  valueDeliveryDate: any;

  validateUploadDate: any;
  validateCustomer: any;
  validateDocumentType: any;
  validateCustomerProduct: any;
  validateFormatCsv: any;

  helpValidateUploadDate: any;
  helpValidateCustomer: any;
  helpValidateDocumentType: any;
  helpValidateCustomerProduct: any;
  helpValidateFormatCsv: any;

  loadingSelectCustomer: any;
  loadingSelectCustomerProduct: any;
  loadingTypeSelectCustomerProduct: any;

  listCustomer: any;
  listCustomerProduct: any;
  listFormatCsv: any;

  handlePutDeliveryDate: any;
  handleConvert: any;
  handlePutUploadDate: any;
  handlePutCustomer: any;
  handlePutDocumentType: any;
  handlePutCustomerProduct: any;
  handlePutFormatCsv: any;

  handleBlurCustomer: any;
  handleBlurDocumentType: any;
  handleBlurCustomerProduct: any;
  handleBlurFormatCsv: any;

  handleGetCustomer: any;
  handleGetCustomerProduct: any;
  handleGetFormatCsv: any;

  handleValidateUploadDate: any;
  handleResetCustomer: any;
  handleResetDocumentType: any;
  handleResetCustomerProduct: any;
  handleResetFormatCsv: any;
  handleReset: any;
  handleSubmitUploadSoftcopy: any;
}
export default class UploadSoftcopyComponent extends Component<IProps> {
  render() {
    const DefaultCsv =
      this.props.listFormatCsv.length === 0
        ? null
        : this.props.listFormatCsv[0];
    const IdDefaultCsv =
      DefaultCsv === null
        ? null
        : this.props.defaultCsv === true
        ? DefaultCsv.id === undefined
          ? null
          : DefaultCsv.id
        : this.props.formatCsv;
    const minDate2 = (d: any) =>
      !d || d.isAfter(moment(new Date()).add(31, 'day').format('YYYY-MM-DD'));
    const disableDocument = this.props.customer === null ? true : false;

    const disableProduct =
      this.props.customer === null || this.props.documentType === null
        ? true
        : false;

    const disableFormat =
      this.props.customer === null ||
      this.props.documentType === null ||
      this.props.customerProduct === null
        ? true
        : false;

    const validateButtonSubmit =
      this.props.customer === null ||
      this.props.validateCustomer === 'error' ||
      this.props.documentType === null ||
      this.props.validateDocumentType === 'error' ||
      this.props.customerProduct === null ||
      this.props.validateCustomerProduct === 'error' ||
      this.props.validateUploadDate === 'error' ||
      this.props.valueUploadDate === null ||
      this.props.formatCsv === null ||
      this.props.validateFormatCsv === 'error' ||
      this.props.fileCsv === null
        ? true
        : false;
    const validateButtonReset =
      this.props.customer === null &&
      this.props.documentType === null &&
      this.props.customerProduct === null &&
      this.props.formatCsv === null &&
      this.props.fileCsv === null
        ? true
        : this.props.customer !== null ||
          this.props.documentType !== null ||
          this.props.customerProduct !== null ||
          this.props.formatCsv !== null ||
          this.props.fileCsv !== null
        ? false
        : true;

    return (
      <div>
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <CardMaterial
              extra={''}
              title={<b style={{ color: '#11BEFF' }}>KIRIM ULANG</b>}
              style={{ borderRadius: '10px' }}
              content={
                <div>
                  <Row>
                    <Col span={12} style={{ paddingRight: '20px' }}>
                      <div>
                        <span>
                          <b>Pelanggan</b>
                        </span>
                        <Form.Item
                          validateStatus={this.props.validateCustomer}
                          help={this.props.helpValidateCustomer}
                        >
                          <Select
                            loading={this.props.loadingSelectCustomer}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            disabled={false}
                            showSearch
                            placeholder={'Pilih Pelanggan'}
                            onSelect={this.props.handleResetCustomer}
                            onChange={this.props.handlePutCustomer}
                            onBlur={this.props.handleBlurCustomer}
                            value={
                              this.props.customer === null ? (
                                <span style={{ color: '#969696' }}>
                                  Pilih Pelanggan
                                </span>
                              ) : (
                                this.props.customer
                              )
                            }
                            onFocus={this.props.handleGetCustomer}
                          >
                            {this.props.listCustomer.map(
                              (data: any, index: any) => (
                                <Option
                                  key={index}
                                  value={data.id}
                                  label={data.name}
                                >
                                  {data.name}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                      <div>
                        <span>
                          <b>Jenis Dokumen</b>
                        </span>
                        <Form.Item
                          validateStatus={this.props.validateDocumentType}
                          help={this.props.helpValidateDocumentType}
                        >
                          <Select
                            style={{ width: '100%' }}
                            disabled={disableDocument}
                            onSelect={this.props.handleResetDocumentType}
                            optionFilterProp="children"
                            value={
                              this.props.documentType === null ? (
                                <span style={{ color: '#969696' }}>
                                  Pilih Jenis Dokumen
                                </span>
                              ) : (
                                this.props.documentType
                              )
                            }
                            onChange={this.props.handlePutDocumentType}
                            onBlur={this.props.handleBlurDocumentType}
                          >
                            <Option value="Card">Kartu</Option>
                            <Option value="Policy">Polis</Option>
                            <Option value="Letter">Surat</Option>
                            <Option value="Others">Lainnya</Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div>
                        <span>
                          <b>Produk</b>
                        </span>
                        <Form.Item
                          validateStatus={this.props.validateCustomerProduct}
                          help={this.props.helpValidateCustomerProduct}
                        >
                          <Select
                            loading={this.props.loadingSelectCustomerProduct}
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            placeholder="Pilih Produk"
                            disabled={disableProduct}
                            showSearch
                            value={
                              this.props.customerProduct === null ? (
                                <span style={{ color: '#969696' }}>
                                  Pilih Produk
                                </span>
                              ) : (
                                this.props.customerProduct
                              )
                            }
                            onFocus={this.props.handleGetCustomerProduct}
                            onSelect={this.props.handleResetCustomerProduct}
                            onBlur={this.props.handleBlurCustomerProduct}
                            onChange={this.props.handlePutCustomerProduct}
                          >
                            {this.props.listCustomerProduct.map(
                              (data: any, index: any) => (
                                <Option key={index} value={data.id}>
                                  {data.name}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12} style={{ paddingLeft: '20px' }}>
                      <div>
                        <span>
                          <b>Template Unggah</b>
                        </span>
                        <Form.Item
                          validateStatus={this.props.validateFormatCsv}
                          help={this.props.helpValidateFormatCsv}
                        >
                          <Select
                            loading={
                              this.props.loadingSelectCustomerProduct ===
                                true &&
                              this.props.loadingTypeSelectCustomerProduct ===
                                'selectCustomerProduct'
                                ? true
                                : false
                            }
                            style={{ width: '100%' }}
                            optionFilterProp="children"
                            disabled={disableFormat}
                            showSearch
                            onSelect={this.props.handleResetFormatCsv}
                            value={
                              IdDefaultCsv === null ||
                              this.props.formatCsv === null ? (
                                <span style={{ color: '#969696' }}>
                                  Pilih template unggah
                                </span>
                              ) : (
                                IdDefaultCsv
                              )
                            }
                            onChange={this.props.handlePutFormatCsv}
                            onFocus={this.props.handleGetFormatCsv}
                            onBlur={this.props.handleBlurFormatCsv}
                          >
                            {this.props.listFormatCsv.map(
                              (data: any, index: any) => (
                                <Option key={index} value={data.id}>
                                  {data.nameCustomCsv}
                                </Option>
                              )
                            )}
                          </Select>
                        </Form.Item>
                      </div>
                      <div>
                        <span>
                          <b>Unggah File</b>
                        </span>
                        <div
                          className="fileUploadInput"
                          style={{ paddingTop: 4 }}
                        >
                          <Input
                            onChange={this.props.handleConvert}
                            value={this.props.fileName}
                            suffix={<Icon type="upload" />}
                            multiple={false}
                            type="file"
                            accept={this.props.formatFile}
                          />
                        </div>
                      </div>

                      <div style={{ marginTop: 27 }}>
                        <span>
                          <b>Tanggal Kirim</b>
                        </span>
                        <Form.Item>
                          <DatePicker
                            style={{
                              width: '100%',
                              backgroundColor: '#F5F5F5',
                              fontWeight: 'bold',
                            }}
                            locale={locale}
                            format={'YYYY-MM-DD'}
                            placeholder={'Pilih Tanggal'}
                            // onOpenChange={this.props.handleValidateUploadDate}
                            value={this.props.valueDeliveryDate}
                            onChange={this.props.handlePutDeliveryDate}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <span>
                          <b>Tanggal Upload</b>
                        </span>
                        <Form.Item
                          validateStatus={this.props.validateUploadDate}
                          help={this.props.helpValidateUploadDate}
                        >
                          <DatePicker
                            style={{
                              width: '100%',
                              backgroundColor: '#F5F5F5',
                              fontWeight: 'bold',
                            }}
                            locale={locale}
                            format={'YYYY-MM-DD'}
                            placeholder={'Pilih Tanggal'}
                            // onOpenChange={this.props.handleValidateUploadDate}
                            defaultPickerValue={moment(
                              this.props.valueUploadDate,
                              'YYYY-MM-DD'
                            )}
                            value={this.props.valueUploadDate}
                            onChange={this.props.handlePutUploadDate}
                            disabledDate={minDate2}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div style={{ marginLeft: 20, float: 'right' }}>
                      <ButtonMaterial
                        disabled={validateButtonSubmit}
                        handleSubmit={this.props.handleSubmitUploadSoftcopy}
                        teksButton={'Simpan'}
                        size={'middle'}
                        shape={'round'}
                        className={''}
                        type={'primary'}
                        icon={''}
                        style={{ width: '100px' }}
                      />
                    </div>
                    <div>
                      <ButtonMaterial
                        disabled={validateButtonReset}
                        handleSubmit={this.props.handleReset}
                        teksButton={'Reset'}
                        size={'middle'}
                        shape={'round'}
                        className={''}
                        type={'primary'}
                        icon={''}
                        style={
                          validateButtonReset === true
                            ? {
                                // borderRadius:'12px',
                                float: 'right',
                                width: '100px',
                              }
                            : {
                                float: 'right',
                                background: '#FF5858',
                                borderColor: '#FF5858',
                                colorFont: '#FFFFFF',
                                width: '100px',
                              }
                        }
                      />
                    </div>
                  </Row>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
