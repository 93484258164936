import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Icon,
  Input,
  Radio,
  Row,
  Select,
} from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CIcon from '../../../../apps/assets/components/CIcon';
import CardMaterial from '../../../../materials/CardMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/id_ID';

interface IProps {
  handleConvert: any;
  handleChangeWaybill: any;
  handleBlurWaybill: any;
  getWaybill: any;
  validateWaybill: any;
  helpWaybill: any;
  handleReset: any;
  handleSearch: any;
  handleSubmit: any;
  getName: any;
  getPhoneNumber: any;
  handlePutAddress: any;
  getAddress: any;
  validateBtnReset: any;
  validateBtnSubmit: any;
  isLoading: any;
  handleChangeRadioButton: any;
  getRadioButton: any;
  handleGetCustomer: any;
  listCustomer: any;
  listCustomerProduct: any;
  getCustomer: any;
  handlePutCustomer: any;
  validateCustomer: any;
  helpCustomer: any;
  handleClearCustomerProduct: any;
  handleGetCustomerProduct: any;
  getCustomerProduct: any;
  validateCustomerProduct: any;
  helpCustomerProduct: any;
  handlePutCustomerProduct: any;
  validateForm: any;
  handleGetTemplateUpload: any;
  handlePutTemplateUpload: any;
  listTemplateUpload: any;
  getTemplateUpload: any;
  handlePutCycleDate: any;
  getCycleDate: any;
  formatFile: any;
  fileName: any;
  handleSubmitUpload: any;
  handleCloseModal: any;
  visible: any;
  jenisToggle: any;
  resultUpload: any;
  handleDownloadResult: any;
  handleBlurCustomer: any;
  handleBlurCustomerProduct: any;
  handleBlurTemplateUpload: any;
  handleBlurCycleDate: any;
  validateCycleDate: any;
  helpCycleDate: any;
}

export default class UpdateAddressComponent extends Component<IProps> {
  render() {
    const validateSearchBtn = this.props.getWaybill
      ? this.props.getWaybill.length
      : 1;
    const convertAddress =
      this.props.getAddress === null ? [] : Object.keys(this.props.getAddress);
    const convAddress =
      this.props.getAddress === null
        ? []
        : Object.keys(this.props.getAddress)[0];
    let vAddress =
      this.props.getAddress !== null
        ? this.props.getAddress[`${convAddress}`]
        : '';
    let vldBtnSbmt = vAddress.length !== 0 ? false : true;
    const validateUpload =
      !this.props.getCustomer ||
      !this.props.getTemplateUpload ||
      !this.props.getCustomerProduct ||
      !this.props.getCycleDate ||
      !this.props.fileName
        ? true
        : false;
    const customerValue =
      this.props.getCustomer === null ? (
        <span style={{ color: '#969696' }}>Pilih Pelanggan</span>
      ) : (
        this.props.getCustomer
      );
    const customerProduct =
      this.props.getCustomerProduct === null ? (
        <span style={{ color: '#969696' }}>Pilih Produk</span>
      ) : (
        this.props.getCustomerProduct
      );
    const { Option } = Select;
    const popupResultUpload = () => {
      const totalSuccess =
        this.props.resultUpload !== null ? this.props.resultUpload.success : '';
      const totalFailed =
        this.props.resultUpload !== null ? this.props.resultUpload.failed : '';
      return (
        <>
          <ModalMaterial
            className={''}
            width={'50%'}
            style={{}}
            teksTitle={'STATUS PERBAHARUI ALAMAT'}
            visible={
              this.props.jenisToggle === 'resultUploadUpdateAddress' &&
              this.props.visible === true
                ? true
                : false
            }
            onCancel={this.props.handleCloseModal}
            content={
              <div className="defaultPaddingModal">
                <Row>
                  <Col span={12} className="spacingRightAlertCallCenter">
                    <Alert
                      className="alertSuccess"
                      message={
                        <React.Fragment>
                          <Col span={6}>
                            <CIcon
                              className={'iconNotifSuccess'}
                              type="check-square"
                            />
                          </Col>
                          <Col span={18} className="greenText spacingTop">
                            <Row>
                              Berhasil : <b>{totalSuccess}</b>
                            </Row>
                          </Col>
                        </React.Fragment>
                      }
                      type="success"
                    />
                  </Col>
                  <Col span={12} className="spacingLeftAlertCallCenter">
                    <Alert
                      className="alertSuccess"
                      message={
                        <React.Fragment>
                          <Col span={6}>
                            <CIcon
                              className={'iconNotifFailed'}
                              type="close-square"
                            />
                          </Col>
                          <Col span={18} className="redText spacingTop">
                            <Row>
                              Gagal : <b>{totalFailed}</b>
                            </Row>
                          </Col>
                        </React.Fragment>
                      }
                      type="error"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={12} className="spacingRight">
                    <ButtonMaterial
                      disabled={totalFailed === 0 ? true : false}
                      className="btnDownloadResultDelete"
                      handleSubmit={this.props.handleDownloadResult}
                      teksButton={'Download'}
                      shape={'round'}
                      type={'primary'}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonMaterial
                      disabled={false}
                      className="btnCloseResultDelete marginLeft"
                      handleSubmit={this.props.handleCloseModal}
                      teksButton={'Tutup'}
                      shape={'round'}
                      type={'primary'}
                    />
                  </Col>
                </Row>
              </div>
            }
          />
        </>
      );
    };
    const showContent = () => {
      if (this.props.getRadioButton === 'multiple') {
        return (
          <>
            <Col span={12}>
              <span>
                <b>Pelanggan</b>
              </span>
              <Form.Item
                validateStatus={this.props.validateCustomer}
                help={this.props.helpCustomer}
              >
                <Select
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  placeholder="Pilih Pelanggan"
                  showSearch
                  value={customerValue}
                  onFocus={this.props.handleGetCustomer}
                  onSelect={this.props.handleClearCustomerProduct}
                  onBlur={this.props.handleBlurCustomer}
                  onChange={this.props.handlePutCustomer}
                >
                  {this.props.listCustomer.map((data: any, index: any) => (
                    <Option key={index} value={data.id}>
                      {data.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <span>
                <b>Produk</b>
              </span>
              <Form.Item
                validateStatus={this.props.validateCustomerProduct}
                help={this.props.helpCustomerProduct}
              >
                <Select
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  placeholder="Pilih Produk"
                  disabled={this.props.validateForm}
                  showSearch
                  value={customerProduct}
                  onFocus={this.props.handleGetCustomerProduct}
                  onBlur={this.props.handleBlurCustomerProduct}
                  onChange={this.props.handlePutCustomerProduct}
                >
                  {this.props.listCustomerProduct.map(
                    (data: any, index: any) => (
                      <Option key={index} value={data.id}>
                        {data.name}
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>
              <span>
                <b>Unggah File</b>
              </span>
              <div className="fileUploadInput" style={{ marginTop: 3 }}>
                <Form.Item>
                  <Input
                    onChange={this.props.handleConvert}
                    value={this.props.fileName}
                    suffix={<Icon type="upload" />}
                    multiple={false}
                    type="file"
                    accept={this.props.formatFile}
                    disabled={
                      this.props.getTemplateUpload === null ? true : false
                    }
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={12} className={'spacingLeft'}>
              <span>
                <b>Template Unggah</b>
              </span>
              <Form.Item>
                <Select
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                  placeholder="Pilih Pelanggan"
                  disabled={this.props.validateForm}
                  showSearch
                  value={
                    this.props.getTemplateUpload === null ? (
                      <span style={{ color: '#969696' }}>
                        Pilih template upload
                      </span>
                    ) : (
                      this.props.getTemplateUpload
                    )
                  }
                  onFocus={this.props.handleGetTemplateUpload}
                  onBlur={this.props.handleBlurTemplateUpload}
                  onChange={this.props.handlePutTemplateUpload}
                >
                  {this.props.listTemplateUpload.map(
                    (data: any, index: any) => (
                      <Option key={index} value={data.id}>
                        {data.nameCustomCsv}
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>
              <span>
                <b>Tanggal Cycle</b>
              </span>
              <Form.Item
                validateStatus={this.props.validateCycleDate}
                help={this.props.helpCycleDate}
              >
                <DatePicker
                  style={{ width: '100%' }}
                  locale={locale}
                  disabled={this.props.validateForm}
                  format={'DD/MM/YYYY'}
                  placeholder={'Pilih tanggal'}
                  value={this.props.getCycleDate}
                  onOpenChange={this.props.handleBlurCycleDate}
                  onChange={this.props.handlePutCycleDate}
                />
              </Form.Item>
            </Col>
            <Button
              onClick={this.props.handleSubmitUpload}
              style={{ marginTop: '90px' }}
              className={'btnSubmitSearch floatRight'}
              type="primary"
              disabled={validateUpload}
            >
              Simpan
            </Button>
          </>
        );
      } else {
        return (
          <>
            <Col span={12}>
              <div>
                <span>
                  <b>Nama Pemilik</b>
                </span>
                <FormInputTextMaterial
                  validateType={true}
                  value={this.props.getName}
                  className={'borderRadiusDefault'}
                  disabled={true}
                  placeholder={'Masukan nama pemilik'}
                />
              </div>
              <div>
                <span>
                  <b>No Telp</b>
                </span>
                <FormInputTextMaterial
                  validateType={true}
                  value={this.props.getPhoneNumber}
                  className={'borderRadiusDefault'}
                  disabled={true}
                  placeholder={'Masukan nomor telepon'}
                  maxLength={14}
                />
              </div>
            </Col>
            <Col span={12} className={'spacingLeft'}>
              {this.props.getAddress === null ? (
                <React.Fragment>
                  <span>
                    <b>Alamat</b>
                  </span>
                  <FormInputTextMaterial
                    validateType={true}
                    disabled={true}
                    placeholder={'Masukan alamat'}
                    style={{ borderRadius: '10px' }}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {convertAddress.map(data => {
                    return (
                      <div>
                        <div>
                          <span>
                            <b>{data ? data : ''}</b>
                          </span>
                          <Form.Item>
                            <Input
                              placeholder="Masukan alamat"
                              defaultValue={this.props.getAddress[data]}
                              disabled={false}
                              style={
                                !data
                                  ? {
                                      borderRadius: '10px',
                                      marginTop: 26,
                                    }
                                  : {
                                      borderRadius: '10px',
                                    }
                              }
                              onChange={(e: any) =>
                                this.props.handlePutAddress(e, data)
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                    );
                  })}
                </React.Fragment>
              )}
            </Col>
          </>
        );
      }
    };

    return (
      <React.Fragment>
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <React.Fragment>
              {popupResultUpload()}
              <CardMaterial
                extra={''}
                title={<span className={'titleCard'}>PERBAHARUI ALAMAT</span>}
                style={{}}
                className={'borderRadiusDefault'}
                content={
                  <React.Fragment>
                    <Row>
                      <div className="spacingBottom">
                        <Radio.Group
                          onChange={this.props.handleChangeRadioButton}
                          value={this.props.getRadioButton}
                        >
                          <Radio value={'single'}>Satuan</Radio>
                          <Radio value={'multiple'}>Unggah</Radio>
                        </Radio.Group>
                      </div>
                      {this.props.getRadioButton === 'single' ? (
                        <>
                          <Col span={12}>
                            <div>
                              <span>
                                <b>Waybill</b>
                              </span>
                              <FormInputTextMaterial
                                validateType={true}
                                value={this.props.getWaybill}
                                className={'borderRadiusDefault'}
                                disabled={false}
                                onPressEnter={this.props.handleSearch}
                                placeholder={'Masukan waybill'}
                                maxLength={30}
                                onChange={this.props.handleChangeWaybill}
                                onBlur={this.props.handleBlurWaybill}
                                validatestatus={this.props.validateWaybill}
                                help={this.props.helpWaybill}
                              />
                            </div>
                          </Col>
                          <Col span={12}>
                            <Button
                              onClick={this.props.handleSearch}
                              style={{}}
                              className={'btnSubmitSearch'}
                              type="primary"
                              disabled={validateSearchBtn > 15 ? false : true}
                            >
                              Cari
                            </Button>
                          </Col>
                        </>
                      ) : (
                        ''
                      )}
                    </Row>
                  </React.Fragment>
                }
              />
              <CardMaterial
                extra={''}
                title={null}
                style={{}}
                className={'cardFormAddress'}
                content={
                  <div>
                    <Row>{showContent()}</Row>
                    {this.props.getAddress !== null ? (
                      <Row>
                        <div className="spacingDownloadButton">
                          <ButtonMaterial
                            disabled={vldBtnSbmt}
                            handleSubmit={this.props.handleSubmit}
                            teksButton={'Simpan'}
                            shape={'round'}
                            className={'validateSubmitButtonEnable'}
                            type={'primary'}
                            style={{}}
                          />
                        </div>
                        <div>
                          <ButtonMaterial
                            disabled={this.props.validateBtnReset}
                            handleSubmit={this.props.handleReset}
                            teksButton={'Reset'}
                            size={'middle'}
                            shape={'round'}
                            className={
                              this.props.validateBtnReset === true
                                ? 'validateResetButtonDisabled'
                                : 'validateResetButtonEnable'
                            }
                            type={'primary'}
                            icon={''}
                            style={{}}
                          />
                        </div>
                      </Row>
                    ) : (
                      ''
                    )}
                  </div>
                }
              />
            </React.Fragment>
          }
        />
      </React.Fragment>
    );
  }
}
