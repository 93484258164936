import instance from 'config/interceptor';

export const downloadExcelAttempt = async () => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/Delivery/DownloadTemplateUploadAttempt`,
      {},
      { responseType: 'arraybuffer' }
    );
  } catch (error) {
    return error;
  }
};
