import * as ActionAuth from '../../../action/Auth';
import * as ActionComponent from '../../../../module/App/Store/ComponentAction';
import * as ActionLogin from '../../../../module/Login/Store';
import * as ActionScanManifest from '../../../action/Manifest/ScanManifest/ActionScanManifest';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import moment from 'moment';

export function* S_ScanManifest(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdManifestGroup = state.ScanManifest.ScanManifestForm.ManifestGroupForm;
  const RadioButton =
    state.ScanManifest.ScanManifestForm.ManifestRadioButtonForm === 'Success'
      ? true
      : false;
  const Cycle = moment(
    state.ScanManifest.ScanManifestForm.ManifestCycleDateForm
  ).format(
    state.ScanManifest.ScanManifestForm.ManifestMonthly ? 'YYYYMM' : 'YYYYMMDD'
  );
  const Customer =
    state.ScanManifest.ScanManifestForm.ManifestCustomerForm === null
      ? 0
      : state.ScanManifest.ScanManifestForm.ManifestCustomerForm;
  const Product =
    state.ScanManifest.ScanManifestForm.ManifestProductForm === null
      ? 0
      : state.ScanManifest.ScanManifestForm.ManifestProductForm;
  const Checkbox = state.ScanManifest.ScanManifestForm.ManifestCheckboxForm;
  const BorW = state.ScanManifest.ScanManifestForm.ManifestBorWForm;
  const documentTreat = state.ScanManifest.ScanManifestForm.ManifestGroupForm;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const Monthly = state.ScanManifest.ScanManifestForm.ManifestMonthly;
  const username = state.Auth.UsernameAuth;

  try {
    yield put(toggle.changeLoadingTrue());

    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/GroupManifest/AddScan`,
      {
        groupManifest: IdManifestGroup,
        barcode: Checkbox,
        barcodeOrWaybill: BorW,
        success: RadioButton,
        cycle: Cycle,
        customerId: Customer,
        customerProductId: Product,
        monthly: Monthly,
        treatmentDocument: documentTreat,
        userCode: username,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    if (data.duplicate === true) {
      yield put(ActionComponent.openModal('ChooseBarcodeManifest'));
      yield put(ActionScanManifest.fetchResponseScan(data));
      yield put(ActionScanManifest.getDuplicateBarcodeScanManifest());
    } else {
      yield put(ActionScanManifest.changeScanStatus('Success'));
      yield put(ActionScanManifest.changeBorWScanned(BorW));
      yield put(ActionScanManifest.getCountScanned());
      yield put(ActionScanManifest.clearManifestBorWForm());
      yield put(toggle.changeLoadingFalse());
      yield put(toggle.changeJenisToggle(null));
    }
  } catch (error) {
    yield put(toggle.changeJenisToggle(null));
    if (
      (error.response.status === 500 &&
        error.response.data.Message === BorW + ' not found in deliverys') ||
      (error.response.status === 500 &&
        error.response.data.Message === 'Customer product not matching') ||
      (error.response.status === 500 &&
        error.response.data.Message === 'Delivery not return')
    ) {
      yield put(ActionScanManifest.changeScanStatus('Failed'));
      yield put(ActionScanManifest.changeBorWScanned(BorW));
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(ActionScanManifest.changeScanStatus('Failed'));
      yield put(ActionScanManifest.changeBorWScanned(BorW));
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}
export function* S_ScanManifestMultipleBarcode(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };

  const documentTreat = state.ScanManifest.ScanManifestForm.ManifestGroupForm;
  const dataValue = state.ScanManifest.MultipleBarcodeSelected;
  const barcodeValue = state.ScanManifest.ScanManifestForm.ManifestBorWForm;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const username = state.Auth.UsernameAuth;
  const RadioButton =
    state.ScanManifest.ScanManifestForm.ManifestRadioButtonForm === 'Success'
      ? true
      : false;

  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/GroupManifest/AddScan/Multiple/Barcode`,
      {
        barcode: barcodeValue,
        cycle: moment(dataValue).format('YYYYMMDD'),
        userCode: username,
        success: RadioButton,
        treatmentDocument: documentTreat,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComponent.openModal('ChooseBarcodeManifest'));
    yield put(ActionScanManifest.changeScanStatus('Success'));
    yield put(ActionScanManifest.changeBorWScanned(barcodeValue));
    yield put(ActionScanManifest.changeManifestCheckboxForm(true));
    yield put(ActionScanManifest.getCountScanned());
    yield put(ActionScanManifest.clearManifestBorWForm());
    yield put(toggle.changeLoadingFalse());
    yield put(toggle.changeJenisToggle(null));
  } catch (error) {
    yield put(toggle.changeJenisToggle(null));
    if (
      (error.response.status === 500 &&
        error.response.data.Message ===
        barcodeValue + ' not found in deliverys') ||
      (error.response.status === 500 &&
        error.response.data.Message === 'Customer product not matching') ||
      (error.response.status === 500 &&
        error.response.data.Message === 'Delivery not return')
    ) {
      yield put(ActionScanManifest.changeScanStatus('Failed'));
      yield put(ActionScanManifest.changeBorWScanned(dataValue.barcode));
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(ActionScanManifest.changeScanStatus('Failed'));
      yield put(ActionScanManifest.changeBorWScanned(dataValue.barcode));
      yield put(ActionLogin.errorHandling(action, error));
    }
  }
}

export function* SM_ScanManifestMultipleBarcode() {
  yield takeLatest(
    'SCAN_MANIFEST_MULTIPLE_BARCODE',
    S_ScanManifestMultipleBarcode
  );
}
export function* SM_ScanManifest() {
  yield takeLatest('SCAN_MANIFEST', S_ScanManifest);
}
