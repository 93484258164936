export function setToggleMaps(ToggleMaps:any)
{
    return({
        type:"SET_TOGGLE_MAPS",
        ToggleMaps
    })
}
export function setErrorConnectionMaps(ErrorConnectionMaps:any)
{
    return({
        type:"SET_ERROR_CONNECTION_MAPS",
        ErrorConnectionMaps
    })
}
export function setTypeRealTime(typeRealTime:any)
{
    return({
        type:"SET_TYPE_REALTIME",
        typeRealTime
    })
}
export function setZoomMap(setZoomMap:any)
{
    return({
        type:"SET_ZOOM_MAP",
        setZoomMap
    })
}
export function clearZoomMap()
{
    return({
        type:"CLEAR_ZOOM_MAP",
        
    })
}
export function clearTypeRealTime()
{
    return({
        type:"CLEAR_TYPE_REALTIME"
    })
}
export function clearErrorConnectionMaps()
{
    return({
        type:"CLEAR_ERROR_CONNECTION_MAPS"
    })
}
export function clearToggleMaps()
{
    return({
        type:"CLEAR_TOGGLE_MAPS"
    })
}