import * as ActionBranch from 'core/action/Branch/ActionAllBranch';
import * as ActionValidate from 'core/action/Report/ValidateReport/ActionValidateForm';

import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import ValidateComponent from 'module/Report/Component/Validate/ValidateComponent';
import { connect } from 'react-redux';
import moment from 'moment';

interface IProps {
  getRadioButtonDelivery: any;
  getReportType: any;
  getFirstDate: any;
  getLastDate: any;
  getBranch: any;
  getStatus: any;
  getGeoTagging: any;
  getFoto: any;
  getSignature: any;
  MultipleSelect: any;
  BranchList: any;
  actionValidate: any;
  actionBranch: any;
  isLoading: any;
}

function ValidateContainer({
  getRadioButtonDelivery,
  getReportType,
  getFirstDate,
  getLastDate,
  getBranch,
  getStatus,
  getGeoTagging,
  getFoto,
  getSignature,
  BranchList,
  actionValidate,
  actionBranch,
  isLoading,
  MultipleSelect,
}: IProps) {
  const [ValueFirstDate, setValueFirstDate]: any = useState(null);
  const [ValidateFirstDate, setValidateFirstDate]: any = useState(null);
  const [ValidateFirstDate2, setValidateFirstDate2]: any = useState(null);
  const [ValueLastDate, setValueLastDate]: any = useState(null);
  const [ValidateLastDate, setValidateLastDate]: any = useState(null);
  const [ValidateLastDate2, setValidateLastDate2]: any = useState(null);
  const [ValidateBranch, setValidateBranch]: any = useState(null);
  const [ValidateStatus, setValidateStatus]: any = useState(null);
  const [ValidateReportType, setValidateReportType]: any = useState(null);
  const [ArrayValue, setArrayValue]: any = useState(null);

  console.log(ValidateReportType);

  useEffect(() => {
    if (getBranch === undefined) {
      actionValidate.clearBranchValidateReportForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBranch]);

  function handlePutFirstDate(event: any) {
    setValueFirstDate(event);
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      const dateValueConvert = moment(dateValue).format('YYYYMMDD');
      const lastDateValueConvert = moment(getLastDate).format('YYYYMMDD');
      actionValidate.changeFirstDateVReport(dateValue);
      if (dateValueConvert > lastDateValueConvert) {
        setValueLastDate(event);
        actionValidate.changeLastDateVReport(dateValue);
      } else if (dateValueConvert === lastDateValueConvert) {
        setValueLastDate(event);
      } else {
        setValueLastDate(null);
        actionValidate.changeLastDateVReport(null);
      }
      setValidateFirstDate(null);
    } else {
      setValidateFirstDate('error');
      setValidateLastDate(null);
      setValueLastDate(null);
      actionValidate.changeLastDateVReport(null);
      actionValidate.changeFirstDateVReport(null);
    }
  }
  function handleValidateFirstDate(event: any) {
    setValidateFirstDate2(event);
    if (ValidateFirstDate2 === true) {
      if (
        getFirstDate === null ||
        getFirstDate === undefined ||
        getFirstDate === 'Invalid date'
      ) {
        setValidateFirstDate('error');
      } else {
        setValidateFirstDate('');
      }
    } else {
    }
  }
  function handlePutLastDate(event: any) {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      actionValidate.changeLastDateVReport(dateValue);
    } else {
      actionValidate.changeLastDateVReport(null);
    }
    setValueLastDate(event);

    if (event === '' || event === null) {
      setValidateLastDate('error');
    } else {
      setValidateLastDate(null);
    }
  }
  function handleValidateLastDate(event: any) {
    setValidateLastDate2(event);
    if (ValidateLastDate2 === true) {
      if (
        getLastDate === null ||
        getLastDate === undefined ||
        getLastDate === 'Invalid date'
      ) {
        setValidateLastDate('error');
      } else {
        setValidateLastDate2(null);
      }
    } else {
    }
  }
  function handleGetBranch() {
    actionBranch.getAllBranch();
  }

  function handlePutBranch(event: any) {
    const searchSelectAll = event.toString().search('Pilih Semua Posko');
    if (searchSelectAll >= 0) {
      actionValidate.changeBranchVReport(
        BranchList.map((data: any, index: any) => data.id)
      );
    } else {
      actionValidate.changeBranchVReport(event);
    }
    // actionValidate.changeBranchVReport(event)
    if (getBranch === null || getBranch === [] || event.length === 0) {
      setValidateBranch('error');
    } else {
      setValidateBranch(null);
    }
  }

  function handleBlurBranch() {
    if (getBranch === null || getBranch === [] || getBranch.length === 0) {
      setValidateBranch('error');
    } else {
      setValidateBranch(null);
    }
  }

  function handlePutStatus(event: any) {
    actionValidate.changeStatusVReport(event);

    if (
      getStatus === null ||
      getStatus === [] ||
      event === null ||
      event.length === 0
    ) {
      setValidateStatus('error');
    } else {
      setValidateStatus(null);
    }
  }

  function handleBlurStatus() {
    if (
      getStatus === null ||
      getStatus === undefined ||
      getStatus.length === 0
    ) {
      setValidateStatus('error');
    } else {
      setValidateStatus(null);
    }
  }

  function handlePutGeoTagging(event: any) {
    actionValidate.changeGeoTaggingVReport(event.target.checked);
  }
  function handlePutFoto(event: any) {
    actionValidate.changeFotoVReport(event.target.checked);
  }
  function handlePutSignature(event: any) {
    actionValidate.changeSignatureVReport(event.target.checked);
  }
  function handlePutMultipleSelect(event: any) {
    actionValidate.changeMultipleSelectVReport(event.target.checked);
  }
  function handlePutReportType(event: any) {
    actionValidate.changeReportType(event);
    if (event === null) {
      setValidateReportType('error');
    } else {
      setValidateReportType(null);
    }
  }
  function handleRadioButton(event: any) {
    actionValidate.changeRadiobuttonDelivery(event.target.value);
  }
  function handleDownload() {
    actionValidate.downloadValidateReport();
  }
  function handleReset() {
    actionValidate.clearValidateReportForm();
    setValueFirstDate(null);
    setValueLastDate(null);
    setValidateReportType(null);
    setValidateFirstDate(null);
    setValidateFirstDate2(null);
    setValidateLastDate(null);
    setValidateLastDate2(null);
    setValidateBranch(null);
    setValidateStatus(null);
    setArrayValue(null);
  }

  return (
    <ValidateComponent
      handlePutFirstDate={handlePutFirstDate}
      ValueFirstDate={ValueFirstDate}
      ValueLastDate={ValueLastDate}
      GetFirstDate={getFirstDate}
      handleValidateFirstDate={handleValidateFirstDate}
      ValidateFirstDate={ValidateFirstDate === 'error' ? 'error' : ''}
      helpFirstDate={
        ValidateFirstDate === 'error' ? 'Tanggal kirim wajib diisi' : ''
      }
      handlePutLastDate={handlePutLastDate}
      GetLastDate={getLastDate}
      handleValidateLastDate={handleValidateLastDate}
      ValidateLastDate={ValidateLastDate === 'error' ? 'error' : ''}
      helpLastDate={ValidateLastDate === 'error' ? 'Tanggal wajib diisi' : ''}
      handlePutBranch={handlePutBranch}
      handleGetBranch={handleGetBranch}
      handleBlurBranch={handleBlurBranch}
      ArrayValue={ArrayValue}
      BranchList={BranchList}
      getBranch={getBranch}
      // ValueGetBranch={getBranch===null?<span style={{color:'#969696'}}>Pilih Posko</span>: getBranch}
      ValueGetBranch={getBranch}
      ValidateBranch={ValidateBranch === 'error' ? 'error' : ''}
      helpValidateBranch={ValidateBranch === 'error' ? 'Posko wajib diisi' : ''}
      handlePutStatus={handlePutStatus}
      handleBlurStatus={handleBlurStatus}
      ValueGetStatus={
        getStatus === null || getStatus === [] || getStatus.length === 0
          ? []
          : getStatus
      }
      ValidateStatus={ValidateStatus === 'error' ? 'error' : ''}
      helpValidateStatus={
        ValidateStatus === 'error' ? 'Status wajib diisi' : ''
      }
      handlePutReportType={handlePutReportType}
      ValueReportType={getReportType}
      handleRadioButton={handleRadioButton}
      ValueRadioButton={getRadioButtonDelivery}
      handlePutGeoTagging={handlePutGeoTagging}
      getGeoTagging={getGeoTagging}
      handlePutFoto={handlePutFoto}
      getFoto={getFoto}
      handlePutSignature={handlePutSignature}
      handlePutMultipleSelect={handlePutMultipleSelect}
      MultipleSelect={MultipleSelect}
      getSignature={getSignature}
      handleDownload={handleDownload}
      handleReset={handleReset}
      isLoading={isLoading}
      validateDownloadButton={
        getFirstDate === null ||
        getLastDate === null ||
        getBranch === null ||
        getStatus === null ||
        getStatus.length === 0 ||
        (getGeoTagging === false &&
          getFoto === false &&
          getSignature === false &&
          MultipleSelect === false)
          ? true
          : false
      }
      validateResetButton={
        getFirstDate !== null ||
        getLastDate !== null ||
        getBranch.length !== 0 ||
        getStatus !== null ||
        getGeoTagging !== false ||
        getFoto !== false ||
        getSignature !== false
          ? false
          : true
      }
    />
  );
}

const mapStateToProps = (state: any) => ({
  getRadioButtonDelivery: state.Report.ValidateReportForm.RadioButtonDelivery,
  getReportType: state.Report.ValidateReportForm.ReportType,
  getFirstDate: state.Report.ValidateReportForm.FirstDate,
  getLastDate: state.Report.ValidateReportForm.LastDate,
  getBranch: state.Report.ValidateReportForm.Branch,
  getStatus: state.Report.ValidateReportForm.Status,
  getGeoTagging: state.Report.ValidateReportForm.GeoTagging,
  getFoto: state.Report.ValidateReportForm.Foto,
  getSignature: state.Report.ValidateReportForm.Signature,
  MultipleSelect: state.Report.ValidateReportForm.MultipleSelect,
  BranchList: state.Branch.AllBranch,
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionValidate: bindActionCreators(ActionValidate, dispatch),
  actionBranch: bindActionCreators(ActionBranch, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ValidateContainer);
