const LoginValidation = (values, props) => {
  const { username, password } = values;
  const errors: any = {};
  if (!username) {
    errors.username = 'Nama Pengguna Tidak Boleh Kosong!';
  } else {
    if (username.length < 7) {
      errors.username = 'Nama pengguna kurang dari 7 karakter';
    }
  }
  if (!password) {
    errors.password = 'Password Tidak Boleh Kosong!';
  }
  return errors;
};
export default LoginValidation;
