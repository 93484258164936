import * as ActionAuth from '../../action/Auth';
import * as ActionDetailListCsvType from '../../../core/action/Customer/ActionDetailListCsvType';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_deleteCustomCsv(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const IdCustomCsv = state.Customer.FormCustomCsvCustomer.IdCsv;

  try {
    yield put(toggle.changeLoadingTrue());
    yield call(
      axios.delete,
      process.env.REACT_APP_URL + '/Setting/CustomCsv/' + IdCustomCsv,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(ActionDetailListCsvType.clearListCustomerCsvType());
    yield put(ActionDetailListCsvType.getListCustomerCsvName());
    yield put(toggle.clearJenisToggle());
    yield put(toggle.changeLoadingFalse());

    if (action?.payload?.refetch) {
      action.payload.refetch();
    }

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil di hapus</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}

export function* SM_deleteCustomCsv() {
  yield takeLatest('DELETE_CUSTOM_CSV_CUSTOMER', S_deleteCustomCsv);
}
