/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Input, Row, Table } from 'antd';
import React, { Component } from 'react';
import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import '../../../../styles/TableStyle/index.css';

interface IProps {
  isLoading: any;
  ListDataActive: any;
  SearchValue: any;
  PagePagination: any;
  TotalPagePagination: any;
  handleSearch: any;
  handlePutSearch: any;
  handlePage: any;
  handleDownload: any;
  handleSyncron: any;
  handleViewStatusDelivery: any;
  page: any;
}

export default class DataActiveComponent extends Component<IProps> {
  render() {
    const columns = [
      {
        title: 'ID Number',
        dataIndex: 'idNumber',
        render: (idNumber: any) => <span>{idNumber}</span>,
        key: 'idNumber',
        width: 80,
      },
      {
        title: 'Produk',
        dataIndex: 'productName',
        render: (productName: any) => <span>{productName}</span>,
        key: 'productName',
        width: 60,
      },
      {
        title: 'Waybill',
        dataIndex: 'waybillCode',
        render: (waybillCode: any) => (
          <span
            style={{ color: '#4FACFB' }}
            onClick={() => this.props.handleViewStatusDelivery(waybillCode)}
          >
            <a>{waybillCode}</a>
          </span>
        ),
        key: 'waybillCode',
        width: 100,
      },
      {
        title: 'Masking',
        dataIndex: 'noMasking',
        render: (noMasking: any) => (
          <span style={{ color: '#31E116' }}>{'*#' + noMasking}</span>
        ),
        key: 'noMasking',
        width: 40,
      },
      {
        title: 'Server',
        dataIndex: 'serverName',
        render: (serverName: any) => <span>{serverName}</span>,
        key: 'serverName',
        width: 60,
      },
    ];
    return (
      <div style={{ paddingBottom: '35px' }}>
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <div>
              <CardMaterial
                extra={''}
                title={''}
                style={{}}
                content={
                  <div>
                    <Row>
                      <Col span={16}>
                        <div>
                          <span
                            style={{ color: '#11BEFF', paddingRight: '20px' }}
                          >
                            <b>DATA TERDAFTAR</b>
                          </span>
                          <Input.Search
                            style={{ borderRadius: '10px', width: '50%' }}
                            placeholder={'Cari berdasarkan waybill'}
                            onChange={this.props.handlePutSearch}
                            onPressEnter={this.props.handleSearch}
                            value={this.props.SearchValue}
                          />
                        </div>
                      </Col>
                      <Col span={8}>
                        <div style={{ float: 'right' }}>
                          <ButtonMaterial
                            disabled={''}
                            handleSubmit={this.props.handleSyncron}
                            teksButton={''}
                            size={''}
                            shape={''}
                            className={''}
                            type={'primary'}
                            icon={'sync'}
                            style={{
                              color: '#fafafa',
                              paddingBottom: '3px',
                              background: '#31E116',
                              borderColor: '#31E116',
                              marginRight: 12,
                              height: 30,
                            }}
                          />

                          <ButtonMaterial
                            disabled={
                              this.props.ListDataActive === null ||
                              this.props.ListDataActive.length === 0
                                ? true
                                : false
                            }
                            handleSubmit={this.props.handleDownload}
                            teksButton={''}
                            size={''}
                            shape={''}
                            className={''}
                            type={'primary'}
                            icon={'download'}
                            style={
                              this.props.ListDataActive === null ||
                              this.props.ListDataActive.length === 0
                                ? {
                                    color: '#C1C1C1',
                                    paddingBottom: '3px',
                                    height: 30,
                                  }
                                : {
                                    color: '#fafafa',
                                    paddingBottom: '3px',
                                    background: '#7956EE',
                                    borderColor: '#7956EE',
                                    height: 30,
                                  }
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
              ></CardMaterial>
              <Table
                pagination={{
                  onChange: page => {
                    this.props.handlePage(page);
                  },
                  pageSize: 10,
                  total: this.props.TotalPagePagination,
                  current:
                    this.props.PagePagination === 0
                      ? 1
                      : this.props.PagePagination,
                }}
                bordered={false}
                dataSource={this.props.ListDataActive}
                columns={columns}
              />
            </div>
          }
        ></SpinMaterial>
      </div>
    );
  }
}
