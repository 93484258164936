export function changeTypeFormConfigReport(TypeFormConfigReport:any)
{
    return({
        type:"CHANGE_TYPE_FORM_CONFIG_REPORT",
        TypeFormConfigReport
    })
}
export function changeIdCustomerReport(IdCustomerReport:any)
{
    return({
        type:"CHANGE_ID_CUSTOMER_REPORT",
        IdCustomerReport
    })
}
export function changeCustomerNameReport(CustomerNameReport:any)
{
    return({
        type:"CHANGE_CUSTOMER_NAME_REPORT",
        CustomerNameReport
    })
}
export function changeFormatReport(FormatReport:any)
{
    return({
        type:"CHANGE_FORMAT_REPORT",
        FormatReport
    })
}
export function changeSettingReport(SettingReport:any)
{
    return({
        type:"CHANGE_SETTING_REPORT",
        SettingReport
    })
}
export function changeSettingReportName(SettingReportName:any)
{
    return({
        type:"CHANGE_SETTING_REPORT_NAME",
        SettingReportName
    })
}
export function changePeriodeReport(PeriodeReport:any)
{
    return({
        type:"CHANGE_PERIODE_REPORT",
        PeriodeReport
    })
}
export function changeDelimitedReport(DelimitedReport:any)
{
    return({
        type:"CHANGE_DELIMITED_REPORT",
        DelimitedReport
    })
}
export function changeWithoutHeaderReport(WithoutHeader:any)
{
    return({
        type:"CHANGE_WITHOUT_HEADER_REPORT",
        WithoutHeader
    })
}
export function changeShowInPortalReport(ShowInPortal:any)
{
    return({
        type:"CHANGE_SHOW_IN_PORTAL_REPORT",
        ShowInPortal
    })
}
export function setListCustomerReport(ListCustomerReport:any)
{
    return({
        type:"SET_LIST_CUSTOMER_REPORT",
        ListCustomerReport
    })
}
export function changeDetailCustomerConfigReport()
{
    return({
        type:"CHANGE_DETAIL_CUSTOMER_CONFIG_REPORT"
    })
}
export function changeDetailEditCustomerConfigReport()
{
    return({
        type:"CHANGE_DETAIL_EDIT_CUSTOMER_CONFIG_REPORT"
    })
}
export function submitAddConfigReport()
{
    return({
        type:"SUBMIT_ADD_CONFIG_REPORT"
    })
}
export function submitEditConfigReport()
{
    return({
        type:"SUBMIT_EDIT_CONFIG_REPORT"
    })
}
export function changeClearCustomerConfigReport()
{
    return({
        type:"CLEAR_CUSTOMER_CONFIG_REPORT"
    })
}
export function changeClearCustomerTypeFormConfigReport()
{
    return({
        type:"CLEAR_CUSTOMER_TYPE_FORM_CONFIG_REPORT"
    })
}
export function clearListCustomerReport()
{
    return({
        type:"CLEAR_LIST_CUSTOMER_REPORT"
    })
}
export function clearDelimitedReport()
{
    return({
        type:"CLEAR_DELIMITED_REPORT"
    })
}
