import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import EmergencyProcessComponent from '../../../../components/Content/ManualProcess/Emergency/EmergencyProcessComponent';
import * as ActionBranch from '../../../../core/action/Branch/ActionAllBranch';
import * as ActionCourier from '../../../../core/action/ManageAccount/Courier/ActionTableCourier';
import * as ActionAmergency from '../../../../core/action/ManualProcess/ActionEmergencyProcess';
import * as toggle from '../../../../core/action/toggle';

interface IProps {
  // GetBranch:any
  // GetCourier:any
  ListBranch: any;
  ListCourier: any;
  ListKoli: any;
  actionToggle: any;
  actionBranch: any;
  actionCourier: any;
  actionAmergency: any;
  isLoading: any;
  LoadingSelectBranch: any;
  TypeLoadingSelectBranch: any;
  LoadingSelectCourier: any;
  TypeLoadingSelectCourier: any;
  ValueMode: any;
  ValueDocumentType: any;
  ValueDistrict: any;
  ValueBranch: any;
  ValueCourier: any;
  ValueDeliveryTime: any;
  ValueFinishTime: any;
  ValueNumberResi: any;
  ValueWaybill: any;
  ValueCsv: any;
  ValueBarcodeColumn: any;
  ValueTypeDelivery: any;
  ValueReceiverName: any;
  ValueDeliveryNote: any;
  ValueWeight: any;
  ValueKoli: any;
  jenisToggle: any;
  jenisToggle2: any;
  CountSuccess: any;
  CountFailed: any;
}
interface IState {
  ValueScan: any;
  ValueCheckBoxKoli: any;
  file: any;
  fileName: any;
  base64URL: any;
  validateMode: any;
  validateAmplopType: any;
  validateDistrict: any;
  validateBranch: any;
  validateCourier: any;
  validateDeliveryTime: any;
  validateFinishTime: any;
  validateConditionDeliveryTime: any;
  validateConditionFinishTime: any;
  validateResiNumber: any;
  validateWaybill: any;
  validateCsv: any;
  validateBarcodeColumn: any;
  validateTypeDelivery: any;
  validateReceiverName: any;
  validateDeliveryNote: any;
  valueLDeliveryTime: any;
  valueLFinishTime: any;
}
export class EmergencyProcessContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValueScan: false,
      ValueCheckBoxKoli: false,
      file: null,
      fileName: '',
      base64URL: null,
      validateMode: null,
      validateAmplopType: null,
      validateDistrict: null,
      validateBranch: null,
      validateCourier: null,
      validateDeliveryTime: null,
      validateFinishTime: null,
      validateConditionDeliveryTime: null,
      validateConditionFinishTime: null,
      validateResiNumber: null,
      validateWaybill: null,
      validateCsv: null,
      validateBarcodeColumn: null,
      validateTypeDelivery: null,
      validateReceiverName: null,
      validateDeliveryNote: null,
      valueLDeliveryTime: null,
      valueLFinishTime: null,
    };
    this.handleSetValueScan = this.handleSetValueScan.bind(this);
    this.handlePutMode = this.handlePutMode.bind(this);
    this.handlePutDocumentType = this.handlePutDocumentType.bind(this);
    this.handlePutDistict = this.handlePutDistict.bind(this);
    this.handlePutTypeDelivery = this.handlePutTypeDelivery.bind(this);
    this.handleSelectKoli = this.handleSelectKoli.bind(this);
    this.handleGetBranch = this.handleGetBranch.bind(this);
    this.handlePutBranch = this.handlePutBranch.bind(this);
    this.handleGetCourier = this.handleGetCourier.bind(this);
    this.handlePutCourier = this.handlePutCourier.bind(this);
    this.handlePutDeliveryTime = this.handlePutDeliveryTime.bind(this);
    this.handlePutFinishTime = this.handlePutFinishTime.bind(this);
    this.handlePutNumberResi = this.handlePutNumberResi.bind(this);
    this.handlePutWaybill = this.handlePutWaybill.bind(this);
    this.handlePutCsv = this.handlePutCsv.bind(this);
    this.handleChangeBarcodeColumn = this.handleChangeBarcodeColumn.bind(this);
    this.handleChangeReceiverName = this.handleChangeReceiverName.bind(this);
    this.handleChangeDeliveryNote = this.handleChangeDeliveryNote.bind(this);
    this.handlePutKoli = this.handlePutKoli.bind(this);
    this.handleRemoveKoli = this.handleRemoveKoli.bind(this);
    this.handleAddListKoli = this.handleAddListKoli.bind(this);
    this.handleResetFormEmergency = this.handleResetFormEmergency.bind(this);
    this.handleSubmitFormEmergency = this.handleSubmitFormEmergency.bind(this);
    this.handleBlurMode = this.handleBlurMode.bind(this);
    this.handleBlurAmplopType = this.handleBlurAmplopType.bind(this);
    this.handleBlurDistrict = this.handleBlurDistrict.bind(this);
    this.handleBlurTypeDelivery = this.handleBlurTypeDelivery.bind(this);
    this.handleBlurBranch = this.handleBlurBranch.bind(this);
    this.handleBlurCourier = this.handleBlurCourier.bind(this);
    this.handleBlurDeliveryTime = this.handleBlurDeliveryTime.bind(this);
    this.handleBlurFinishTime = this.handleBlurFinishTime.bind(this);
    this.handleBlurResiNumber = this.handleBlurResiNumber.bind(this);
    this.handleBlurWaybill = this.handleBlurWaybill.bind(this);
    this.handleBlurCsv = this.handleBlurCsv.bind(this);
    this.handleResetMode = this.handleResetMode.bind(this);
    this.handleResetAmplopType = this.handleResetAmplopType.bind(this);
    this.handleResetDistrict = this.handleResetDistrict.bind(this);
    this.handleResetBranch = this.handleResetBranch.bind(this);
    this.handleResetCourier = this.handleResetCourier.bind(this);
    this.handleResetCsv = this.handleResetCsv.bind(this);
    this.handleResetTypeDelivery = this.handleResetTypeDelivery.bind(this);
    this.handleValidateDeliveryDate =
      this.handleValidateDeliveryDate.bind(this);
    this.handleValidateFinishDate = this.handleValidateFinishDate.bind(this);
    this.handleDownloadSample = this.handleDownloadSample.bind(this);
    this.handleClearModalForm = this.handleClearModalForm.bind(this);
    this.handleDownloadResultUpload =
      this.handleDownloadResultUpload.bind(this);
    // this.handleConditionButton=this.handleConditionButton.bind(this)
  }
  handleDownloadSample() {
    this.props.actionAmergency.DownloadTemplateEmergencyUpload();
  }
  handleBlurMode() {
    if (this.props.ValueMode === null) {
      this.setState({
        validateMode: 'error',
      });
    } else {
      this.setState({
        validateMode: null,
      });
    }
  }
  handleBlurAmplopType() {
    if (this.props.ValueDocumentType === null) {
      this.setState({
        validateAmplopType: 'error',
      });
    } else {
      this.setState({
        validateAmplopType: null,
      });
    }
  }
  handleBlurDistrict() {
    if (this.props.ValueDistrict === null) {
      this.setState({
        validateDistrict: 'error',
      });
    } else {
      this.setState({
        validateDistrict: null,
      });
    }
  }
  handleBlurTypeDelivery() {
    if (this.props.ValueTypeDelivery === null) {
      this.setState({
        validateTypeDelivery: 'error',
      });
    } else {
      this.setState({
        validateTypeDelivery: null,
      });
    }
  }
  handleBlurBranch() {
    if (this.props.ValueBranch === null) {
      this.setState({
        validateBranch: 'error',
      });
    } else {
      this.setState({
        validateBranch: null,
      });
    }
  }
  handleBlurCourier() {
    if (this.props.ValueCourier === null) {
      this.setState({
        validateCourier: 'error',
      });
    } else {
      this.setState({
        validateCourier: null,
      });
    }
  }
  handleBlurDeliveryTime() {
    if (
      this.props.ValueDeliveryTime === null ||
      this.props.ValueDeliveryTime === undefined ||
      this.props.ValueDeliveryTime === 'Invalid date'
    ) {
      this.setState({
        validateDeliveryTime: 'error',
      });
    } else {
      this.setState({
        validateDeliveryTime: null,
      });
    }
  }
  handleBlurFinishTime() {
    if (
      this.props.ValueFinishTime === null ||
      this.props.ValueFinishTime === undefined ||
      this.props.ValueFinishTime === 'Invalid date'
    ) {
      this.setState({
        validateFinishTime: 'error',
      });
    } else {
      this.setState({
        validateFinishTime: null,
      });
    }
  }
  handleBlurResiNumber() {
    if (
      this.props.ValueNumberResi === null ||
      this.props.ValueNumberResi === ''
    ) {
      this.setState({
        validateResiNumber: 'error',
      });
    } else {
      this.setState({
        validateResiNumber: null,
      });
    }
  }
  handleBlurWaybill() {
    if (this.props.ValueWaybill === null || this.props.ValueWaybill === '') {
      this.setState({
        validateWaybill: 'error',
      });
    } else {
      this.setState({
        validateWaybill: null,
      });
    }
  }
  handleBlurCsv() {
    if (this.props.ValueCsv === null) {
      this.setState({
        validateCsv: 'error',
      });
    } else {
      this.setState({
        validateCsv: null,
      });
    }
  }
  handleResetMode() {
    this.setState({
      validateMode: null,
      validateAmplopType: null,
      validateDistrict: null,
      validateBranch: null,
      validateCourier: null,
      validateDeliveryTime: null,
      validateFinishTime: null,
      validateConditionDeliveryTime: null,
      validateConditionFinishTime: null,
      validateResiNumber: null,
      validateBarcodeColumn: null,
      validateTypeDelivery: null,
      valueLDeliveryTime: null,
      valueLFinishTime: null,
      validateReceiverName: null,
      validateDeliveryNote: null,
      validateWaybill: null,
    });
    this.props.actionAmergency.ClearConditionMode();
  }
  handleResetAmplopType() {
    this.setState({
      validateAmplopType: null,
    });
  }
  handleResetDistrict() {
    this.setState({
      validateDistrict: null,
    });
  }
  handleResetTypeDelivery() {
    this.setState({
      validateTypeDelivery: null,
    });
  }
  handleResetBranch() {
    this.setState({
      validateBranch: null,
    });
    this.props.actionAmergency.ClearCourier();
  }
  handleResetCourier() {
    this.setState({
      validateCourier: null,
    });
  }
  handleResetCsv() {
    this.setState({
      validateCsv: null,
    });
  }
  handleValidateDeliveryDate(event: any) {
    this.setState({
      validateConditionDeliveryTime: event,
    });
    if (this.state.validateConditionDeliveryTime === true) {
      if (
        this.props.ValueDeliveryTime === null ||
        this.props.ValueDeliveryTime === undefined ||
        this.props.ValueDeliveryTime === 'Invalid date'
      ) {
        this.setState({
          validateDeliveryTime: 'error',
        });
      } else {
        this.setState({
          validateDeliveryTime: null,
        });
      }
    } else {
    }
  }
  handleValidateFinishDate(event: any) {
    this.setState({
      validateConditionFinishTime: event,
    });
    if (this.state.validateConditionFinishTime === true) {
      if (
        this.props.ValueFinishTime === null ||
        this.props.ValueFinishTime === undefined ||
        this.props.ValueFinishTime === 'Invalid date'
      ) {
        this.setState({
          validateFinishTime: 'error',
        });
      } else {
        this.setState({
          validateFinishTime: null,
        });
      }
    } else {
    }
  }
  handlePutMode(event: any) {
    this.props.actionAmergency.changeModeEmergency(event);
    if (
      event === 'NoScan' ||
      event === 'UpdateBarcode' ||
      event === 'UpdateCycle'
    ) {
      this.setState({
        ValueScan: false,
      });
    } else if (event === 'UpdateReceiveName') {
      this.setState({
        ValueScan: true,
      });
    } else {
      this.setState({
        ValueScan: false,
      });
    }
  }
  handlePutDocumentType(event: any) {
    this.props.actionAmergency.changeDocumentType(event);
  }
  handlePutDistict(event: any) {
    this.props.actionAmergency.changeDistrict(event);
  }
  handlePutTypeDelivery(event: any) {
    this.props.actionAmergency.ChangeTypeDelivery(event);
  }
  handleSetValueScan() {
    this.setState({
      ValueScan: !this.state.ValueScan,
    });
    if (this.state.ValueScan) {
      this.setState({
        file: null,
        fileName: '',
        base64URL: null,
        validateCsv: null,
        validateBarcodeColumn: null,
        validateMode: null,
        validateAmplopType: null,
        validateDistrict: null,
        validateBranch: null,
        validateCourier: null,
        validateDeliveryTime: null,
        validateFinishTime: null,
        validateConditionDeliveryTime: null,
        validateConditionFinishTime: null,
        validateResiNumber: null,
        validateTypeDelivery: null,
        valueLDeliveryTime: null,
        valueLFinishTime: null,
      });
      this.props.actionAmergency.ClearCsv();
      this.props.actionAmergency.ClearConditionMode();
    } else {
      this.props.actionAmergency.ClearWaybill();
      this.setState({
        validateWaybill: null,
      });
    }
  }
  handleSelectKoli() {
    if (this.state.ValueCheckBoxKoli === false) {
      this.props.actionAmergency.ClearWeight();
    } else {
      this.props.actionAmergency.ClearKoli();
      this.props.actionAmergency.ClearListKoli();
    }
    this.setState({
      ValueCheckBoxKoli: !this.state.ValueCheckBoxKoli,
    });
  }
  handleGetBranch() {
    this.props.actionBranch.getAllBranch();
  }
  handlePutBranch(event: any) {
    this.props.actionAmergency.ChangeBranch(event);
  }
  handleGetCourier() {
    this.props.actionCourier.getCourierTableBranch();
  }
  handlePutCourier(event: any) {
    this.props.actionAmergency.ChangeCourier(event);
  }
  handlePutDeliveryTime(event: any) {
    this.setState({
      valueLDeliveryTime: event,
    });
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      this.props.actionAmergency.ChangeDeliveryTime(dateValue);
    } else {
      this.props.actionAmergency.ChangeDeliveryTime(null);
    }
  }
  handlePutFinishTime(event: any) {
    this.setState({
      valueLFinishTime: event,
    });
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      this.props.actionAmergency.ChangeFinishTime(dateValue);
    } else {
      this.props.actionAmergency.ChangeFinishTime(null);
    }
  }
  handlePutNumberResi(event: any) {
    this.props.actionAmergency.ChangeNumberResi(event.target.value);
    if (event.target.value === null || event.target.value === '') {
      this.setState({
        validateResiNumber: 'error',
      });
    } else {
      this.setState({
        validateResiNumber: null,
      });
    }
  }
  handlePutWaybill(event: any) {
    this.props.actionAmergency.ChangeWaybill(event.target.value);
    if (event.target.value === null || event.target.value === '') {
      this.setState({
        validateWaybill: 'error',
      });
    } else {
      this.setState({
        validateWaybill: null,
      });
    }
  }
  getBase64 = (file: any) => {
    return new Promise(resolve => {
      let baseURL;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  handlePutCsv(e: any) {
    this.setState({
      fileName: e.target.value,
    });
    let { file } = this.state;
    file = e.target.files[0];
    this.getBase64(file)
      .then((result: any) => {
        file['base64'] = result.toString().split(',')[1];
        this.props.actionAmergency.ChangeCsv(result.split(',')[1]);
        this.setState({
          base64URL: result.split(',')[1],
          file,
        });
      })
      .catch(err => {
        console.log(err);
      });

    if (e.target.files[0] !== undefined) {
      this.setState({
        file: e.target.files[0],
        validateCsv: null,
      });
    } else {
      this.setState({
        file: null,
        validateCsv: 'error',
      });
      this.props.actionAmergency.ChangeCsv(null);
    }
  }
  handlePutKoli(e: any, index: any) {
    if (this.state.ValueCheckBoxKoli) {
      const valueAddKoli = e;
      const reg = /^-?\d*(\.\d*)?$/;
      if (reg.test(valueAddKoli)) {
        this.props.ListKoli[index] = valueAddKoli;
        this.props.actionAmergency.ChangeListKoli(this.props.ListKoli);
        this.props.actionAmergency.ChangeKoli(this.props.ListKoli.join(';'));
      } else {
        if (e === '' || e === null) {
          this.props.actionAmergency.ChangeWeight(0);
        } else {
          this.props.actionAmergency.ChangeWeight(e);
        }
      }
    }
  }
  handleChangeBarcodeColumn(event: any) {
    const reg = /^-?\d*(\\d*)?$/;

    if (reg.test(event.target.value)) {
      this.props.actionAmergency.ChangeBarcodeColumn(event.target.value);

      if (event.target.value === '') {
        this.setState({
          validateBarcodeColumn: 'error',
        });
      } else {
        this.setState({
          validateBarcodeColumn: '',
        });
      }
    }
  }
  handleChangeReceiverName(event: any) {
    this.props.actionAmergency.ChangeReceiverName(event.target.value);

    if (event.target.value === '') {
      this.setState({
        validateReceiverName: 'error',
      });
    } else if (event.target.value.length < 3) {
      this.setState({
        validateReceiverName: 'minchar',
      });
    } else {
      this.setState({
        validateReceiverName: '',
      });
    }
  }

  handleChangeDeliveryNote(event: any) {
    this.props.actionAmergency.ChangeDeliveryNote(event.target.value);

    if (event.target.value === '') {
      this.setState({
        validateDeliveryNote: 'error',
      });
    } else if (event.target.value.length < 6) {
      this.setState({
        validateDeliveryNote: 'minchar',
      });
    } else {
      this.setState({
        validateDeliveryNote: '',
      });
    }
  }
  handleResetFormEmergency() {
    this.props.actionAmergency.ClearAllEmergancyForm();
    this.setState({
      ValueScan: false,
      ValueCheckBoxKoli: false,
      file: null,
      fileName: '',
      base64URL: null,
      validateMode: null,
      validateAmplopType: null,
      validateDistrict: null,
      validateBranch: null,
      validateCourier: null,
      validateDeliveryTime: null,
      validateFinishTime: null,
      validateConditionDeliveryTime: null,
      validateConditionFinishTime: null,
      validateResiNumber: null,
      validateWaybill: null,
      validateCsv: null,
      validateBarcodeColumn: null,
      validateTypeDelivery: null,
      validateReceiverName: null,
      validateDeliveryNote: null,
      valueLDeliveryTime: null,
      valueLFinishTime: null,
    });
  }
  handleSubmitFormEmergency() {
    if (this.state.ValueScan) {
      this.props.actionAmergency.SubmitEmergencyScan();
    } else {
      this.props.actionAmergency.SubmitEmergencyUpload();
    }
  }
  handleRemoveKoli(event: any) {
    this.props.actionAmergency.ChangeListKoli(
      this.props.ListKoli.splice(event, 1)
    );
    if (event > 0) {
      this.props.actionAmergency.ChangeListKoli(this.props.ListKoli);
      this.props.actionAmergency.ChangeKoli(this.props.ListKoli.join(';'));
    } else if (event === 0 && this.props.ListKoli.length > 1) {
      this.props.actionAmergency.ChangeListKoli(this.props.ListKoli);
      this.props.actionAmergency.ChangeKoli(this.props.ListKoli.join(';'));
    } else if (event === 0 && this.props.ListKoli.length === 0) {
      this.props.actionAmergency.ClearListKoli();
      this.props.actionAmergency.ClearKoli();
    }
  }

  handleAddListKoli() {
    this.props.actionAmergency.ChangeListKoli([...this.props.ListKoli, '']);
  }

  handleClearModalForm() {
    this.props.actionAmergency.getDeleteEmergency();
    this.props.actionToggle.clearJenisToggle();
    this.props.actionToggle.clearJenisToggle2();
    this.handleResetFormEmergency();
  }

  handleDownloadResultUpload() {
    this.props.actionAmergency.DownloadResultEmergency();
  }

  componentWillMount() {
    this.props.actionToggle.clearJenisToggle();
    this.props.actionToggle.clearJenisToggle2();
  }

  render() {
    return (
      <div>
        <EmergencyProcessComponent
          ConditionButton={
            this.state.ValueScan
              ? this.props.ValueMode !== null
                ? this.props.ValueMode === 'UpdateAmplop'
                  ? this.props.ValueDocumentType !== null &&
                    this.props.ValueWaybill !== null
                    ? this.props.ValueWaybill === ''
                      ? true
                      : false
                    : true
                  : this.props.ValueMode === 'UpdateArea'
                  ? this.props.ValueDistrict !== null &&
                    this.props.ValueWaybill !== null
                    ? this.props.ValueWaybill === ''
                      ? true
                      : false
                    : true
                  : this.props.ValueMode === 'UpdateKurir'
                  ? this.props.ValueCourier !== null &&
                    this.props.ValueWaybill !== null
                    ? this.props.ValueWaybill === ''
                      ? true
                      : false
                    : true
                  : this.props.ValueMode === 'UpdateNoResi'
                  ? this.props.ValueNumberResi !== null &&
                    this.props.ValueWaybill !== null
                    ? this.props.ValueWaybill === ''
                      ? true
                      : false
                    : true
                  : this.props.ValueMode === 'UpdateTglKirim'
                  ? this.props.ValueDeliveryTime !== null &&
                    this.props.ValueWaybill !== null
                    ? this.props.ValueWaybill === ''
                      ? true
                      : false
                    : true
                  : this.props.ValueMode === 'UpdateTglSampai'
                  ? this.props.ValueFinishTime !== null &&
                    this.props.ValueWaybill !== null
                    ? this.props.ValueWaybill === ''
                      ? true
                      : false
                    : true
                  : this.props.ValueMode === 'UpdateReceiveName'
                  ? this.props.ValueReceiverName !== null &&
                    this.props.ValueDeliveryNote !== null &&
                    this.props.ValueWaybill !== null
                    ? this.props.ValueWaybill === ''
                      ? true
                      : false
                    : true
                  : this.props.ValueMode === 'RestoreData'
                  ? this.props.ValueWaybill === null ||
                    this.props.ValueWaybill === ''
                    ? true
                    : false
                  : this.props.ValueMode === 'NoScan'
                  ? this.props.ValueBarcodeColumn === null ||
                    this.props.ValueBranch === null ||
                    this.props.ValueCourier === null ||
                    this.props.ValueDistrict === null ||
                    this.props.ValueDocumentType === null ||
                    this.props.ValueTypeDelivery === null
                    ? true
                    : false
                  : true
                : true
              : this.props.ValueCsv === null
              ? true
              : this.props.ValueMode === null
              ? true
              : false
          }
          loadingSelectBranch={
            this.props.LoadingSelectBranch === true &&
            this.props.TypeLoadingSelectBranch === 'selectbranch'
              ? true
              : false
          }
          loadingSelectCourier={
            this.props.LoadingSelectCourier === true &&
            this.props.TypeLoadingSelectCourier === 'selectCourier'
              ? true
              : false
          }
          // GetBranch={this.props.GetBranch}
          // GetCourier={this.props.GetCourier}
          ListBranch={this.props.ListBranch}
          ListCourier={this.props.ListCourier}
          isLoading={this.props.isLoading}
          ValueScan={this.state.ValueScan}
          ValueMode={
            this.props.ValueMode === null ? (
              <span style={{ color: '#969696' }}>Pilih Mode Emergency</span>
            ) : (
              this.props.ValueMode
            )
          }
          ValueModeProps={this.props.ValueMode}
          ValueDocumentType={
            this.props.ValueDocumentType === null ? (
              <span style={{ color: '#969696' }}>Pilih Tipe Dokumen</span>
            ) : (
              this.props.ValueDocumentType
            )
          }
          ValueDistrict={
            this.props.ValueDistrict === null ? (
              <span style={{ color: '#969696' }}>Pilih Wilayah</span>
            ) : (
              this.props.ValueDistrict
            )
          }
          ValueBranch={
            this.props.ValueBranch === null ? (
              <span style={{ color: '#969696' }}>Pilih Posko</span>
            ) : (
              this.props.ValueBranch
            )
          }
          ValueBranchProps={this.props.ValueBranch}
          ValueCourier={
            this.props.ValueCourier === null ? (
              <span style={{ color: '#969696' }}>Pilih Kurir</span>
            ) : (
              this.props.ValueCourier
            )
          }
          ValueDeliveryTime={this.props.ValueDeliveryTime}
          ValueFinishTime={this.props.ValueFinishTime}
          ValueNumberResi={this.props.ValueNumberResi}
          ValueWaybill={this.props.ValueWaybill}
          ValueCsv={this.props.ValueCsv}
          ValueBarcodeColumn={this.props.ValueBarcodeColumn}
          ValueTypeDelivery={
            this.props.ValueTypeDelivery === null ? (
              <span style={{ color: '#969696' }}>Pilih Jenis Pengiriman</span>
            ) : (
              this.props.ValueTypeDelivery
            )
          }
          ValueReceiverName={this.props.ValueReceiverName}
          ValueDeliveryNote={this.props.ValueDeliveryNote}
          fileName={this.state.fileName}
          ListKoli={this.props.ListKoli}
          ValueWeight={this.props.ValueWeight}
          ValueCheckBoxKoli={this.state.ValueCheckBoxKoli}
          valueLDeliveryTime={this.state.valueLDeliveryTime}
          valueLFinishTime={this.state.valueLFinishTime}
          validateMode={this.state.validateMode}
          validateAmplopType={this.state.validateAmplopType}
          validateDistrict={this.state.validateDistrict}
          validateBranch={this.state.validateBranch}
          validateCourier={this.state.validateCourier}
          validateDeliveryTime={this.state.validateDeliveryTime}
          validateFinishTime={this.state.validateFinishTime}
          validateResiNumber={this.state.validateResiNumber}
          validateWaybill={this.state.validateWaybill}
          validateCsv={this.state.validateCsv}
          validateBarcodeColumn={this.state.validateBarcodeColumn}
          validateTypeDelivery={this.state.validateTypeDelivery}
          validateReceiverName={
            this.state.validateReceiverName === 'error'
              ? 'error'
              : '' || this.state.validateReceiverName === 'minchar'
              ? 'error'
              : ''
          }
          validateDeliveryNote={
            this.state.validateDeliveryNote === 'error'
              ? 'error'
              : '' || this.state.validateDeliveryNote === 'minchar'
              ? 'error'
              : ''
          }
          helpValidateReceiverName={
            this.state.validateReceiverName === 'error'
              ? 'Nama penerima wajib diisi !!!'
              : '' || this.state.validateReceiverName === 'minchar'
              ? 'Nama penerima minimal 3 karakter'
              : ''
          }
          helpValidateDeliveryNote={
            this.state.validateDeliveryNote === 'error'
              ? 'Keterangan wajib diisi !!!'
              : '' || this.state.validateDeliveryNote === 'minchar'
              ? 'Keterangan minimal 6 karakter'
              : ''
          }
          handleGetBranch={this.handleGetBranch}
          handlePutBranch={this.handlePutBranch}
          handlePutTypeDelivery={this.handlePutTypeDelivery}
          handleGetCourier={this.handleGetCourier}
          handleSetValueScan={this.handleSetValueScan}
          handleSelectKoli={this.handleSelectKoli}
          handlePutMode={this.handlePutMode}
          handlePutDocumentType={this.handlePutDocumentType}
          handlePutDistict={this.handlePutDistict}
          handlePutCourier={this.handlePutCourier}
          handlePutDeliveryTime={this.handlePutDeliveryTime}
          handlePutFinishTime={this.handlePutFinishTime}
          handlePutNumberResi={this.handlePutNumberResi}
          handlePutWaybill={this.handlePutWaybill}
          handlePutCsv={this.handlePutCsv}
          handleChangeBarcodeColumn={this.handleChangeBarcodeColumn}
          handleChangeReceiverName={this.handleChangeReceiverName}
          handleChangeDeliveryNote={this.handleChangeDeliveryNote}
          handlePutKoli={this.handlePutKoli}
          handleRemoveKoli={this.handleRemoveKoli}
          handleAddListKoli={this.handleAddListKoli}
          handleResetFormEmergency={this.handleResetFormEmergency}
          handleSubmitFormEmergency={this.handleSubmitFormEmergency}
          handleBlurMode={this.handleBlurMode}
          handleBlurAmplopType={this.handleBlurAmplopType}
          handleBlurDistrict={this.handleBlurDistrict}
          handleBlurTypeDelivery={this.handleBlurTypeDelivery}
          handleBlurBranch={this.handleBlurBranch}
          handleBlurCourier={this.handleBlurCourier}
          handleBlurDeliveryTime={this.handleBlurDeliveryTime}
          handleBlurFinishTime={this.handleBlurFinishTime}
          handleBlurResiNumber={this.handleBlurResiNumber}
          handleBlurWaybill={this.handleBlurWaybill}
          handleBlurCsv={this.handleBlurCsv}
          handleResetMode={this.handleResetMode}
          handleResetAmplopType={this.handleResetAmplopType}
          handleResetDistrict={this.handleResetDistrict}
          handleResetBranch={this.handleResetBranch}
          handleResetCourier={this.handleResetCourier}
          handleResetCsv={this.handleResetCsv}
          handleResetTypeDelivery={this.handleResetTypeDelivery}
          handleValidateDeliveryDate={this.handleValidateDeliveryDate}
          handleValidateFinishDate={this.handleValidateFinishDate}
          handleDownloadSample={this.handleDownloadSample}
          handleConditionButton={false}
          visible={this.props.jenisToggle === 'ModalEmergency' ? true : false}
          handleClearModal={this.handleClearModalForm}
          jenisToggle={this.props.jenisToggle}
          jenisToggle2={this.props.jenisToggle2}
          countSuccess={this.props.CountSuccess}
          countFailed={this.props.CountFailed}
          handleDownloadResult={this.handleDownloadResultUpload}
          handleClearResult={this.handleClearModalForm}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  ListBranch: state.Branch.AllBranch,
  ListCourier: state.Table.TableCourier,
  ListKoli: state.ManualProcess.EmergencyProcess.ListKoli,
  isLoading: state.Toggle.isLoading,
  LoadingSelectBranch: state.Branch.PropertiesBranch.LoadingBranch,
  TypeLoadingSelectBranch: state.Branch.PropertiesBranch.TypeLoadingBranch,
  LoadingSelectCourier: state.Courier.PropertiesCourier.LoadingCourier,
  TypeLoadingSelectCourier: state.Courier.PropertiesCourier.TypeLoadingCourier,
  ValueMode: state.ManualProcess.EmergencyProcess.ModeEmergency,
  ValueDocumentType: state.ManualProcess.EmergencyProcess.DocumentType,
  ValueDistrict: state.ManualProcess.EmergencyProcess.District,
  ValueBranch: state.ManualProcess.EmergencyProcess.Branch,
  ValueCourier: state.ManualProcess.EmergencyProcess.Courier,
  ValueDeliveryTime: state.ManualProcess.EmergencyProcess.DeliveryTime,
  ValueFinishTime: state.ManualProcess.EmergencyProcess.FinishTime,
  ValueNumberResi: state.ManualProcess.EmergencyProcess.NumberResi,
  ValueWaybill: state.ManualProcess.EmergencyProcess.Waybill,
  ValueCsv: state.ManualProcess.EmergencyProcess.Csv,
  ValueBarcodeColumn: state.ManualProcess.EmergencyProcess.BarcodeColumn,
  ValueTypeDelivery: state.ManualProcess.EmergencyProcess.TypeDelivery,
  ValueReceiverName: state.ManualProcess.EmergencyProcess.ReceiverName,
  ValueDeliveryNote: state.ManualProcess.EmergencyProcess.DeliveryNote,
  ValueWeight: state.ManualProcess.EmergencyProcess.Weight,
  ValueKoli: state.ManualProcess.EmergencyProcess.Koli,
  jenisToggle: state.Toggle.jenisToggle,
  jenisToggle2: state.Toggle.jenisToggle2,
  CountSuccess: state.ManualProcess.EmergencyProcess.CountSuccess,
  CountFailed: state.ManualProcess.EmergencyProcess.CountFailed,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionBranch: bindActionCreators(ActionBranch, dispatch),
  actionCourier: bindActionCreators(ActionCourier, dispatch),
  actionAmergency: bindActionCreators(ActionAmergency, dispatch),
  actionToggle: bindActionCreators(toggle, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(EmergencyProcessContainer);
