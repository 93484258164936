import { Modal } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import ButtonRestoreDataComponent from '../../../components/Content/RestoreData/ButtonRestoreDataComponent';
import * as ActionRestoreData from '../../../core/action/RestoreData/ActionRestoreData';
import * as toggle from '../../../core/action/toggle';

const { confirm } = Modal;
interface IProps {
  idRestoreData: any;
  waybillCode: any;
  productType: any;
  typeButton: any;
  actionToggle: any;
  actionRestoreData: any;
}

class ButtonRestoreDataContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleButtonConfirm = this.handleButtonConfirm.bind(this);
    this.handleDownloadRestoreData = this.handleDownloadRestoreData.bind(this);
  }

  handleButtonConfirm() {
    this.props.actionRestoreData.setIdRestoreData(this.props.idRestoreData);

    confirm({
      title:
        this.props.typeButton === 'RestoreData'
          ? 'Apakah anda yakin ingin merestore data ini ?'
          : 'Apakah anda yakin ingin menghapus data ini ?',
      okText: 'Ya',
      okType: this.props.typeButton === 'RestoreData' ? 'primary' : 'danger',
      cancelText: 'Tidak',
      onOk: () => {
        if (this.props.typeButton === 'RestoreData') {
          this.props.actionRestoreData.submitRestoreRestoreData();
        } else {
          this.props.actionRestoreData.submitDeleteRestoreData();
        }
      },
      onCancel: () => {
        this.props.actionRestoreData.clearDataRestoreData();
      },
    });
  }

  handleDownloadRestoreData() {
    this.props.actionRestoreData.setIdRestoreData(this.props.idRestoreData);
    this.props.actionRestoreData.setProductTypeRestoreData(
      this.props.productType
    );
    this.props.actionRestoreData.setWaybillRestoreData(this.props.waybillCode);
    this.props.actionRestoreData.submitDownloadRestoreData();
  }

  render() {
    return (
      <React.Fragment>
        <ButtonRestoreDataComponent
          handleButtonConfirm={this.handleButtonConfirm}
          handleDownloadRestoreData={this.handleDownloadRestoreData}
          typeButton={this.props.typeButton}
          modal={this.props.actionToggle}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state: any) => ({
  TypeToggle: state.Toggle.jenisToggle,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
  actionRestoreData: bindActionCreators(ActionRestoreData, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ButtonRestoreDataContainer);
