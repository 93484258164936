import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../../action/toggle';

import axios from 'axios';

export function* S_downloadSampleManualProcessVendor(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const key = 'download';
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const Checkbox = state.ManualProcess.ManualProcessVendorForm.CheckBox;
  const FormatDate = state.ManualProcess.ManualProcessVendorForm.FormatDate;
  const format = Checkbox === true ? '.zip' : '.csv';

  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Sample File' + format);
    document.body.appendChild(link);
    link.click();
  }

  try {
    yield put(changeLoadingTrue());
    const { data } = yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Delivery/Template/ManualUpdate/Vendor',
      {
        zipFile: Checkbox,
        formatDate: FormatDate,
      },
      {
        responseType: 'blob',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);

          // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

          if (percentProgress < 100) {
            message.loading({
              content: 'Loading ' + percentProgress + '%',
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(changeLoadingFalse());
  } catch (error) {
    yield put(changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_downloadSampleManualProcessVendor() {
  yield takeLatest(
    'DOWNLOAD_SAMPLE_MANUAL_PROCESS_VENDOR_FROM',
    S_downloadSampleManualProcessVendor
  );
}
