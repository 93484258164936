import { Button, Col, Row, Select, Table } from 'antd';

import CardMaterial from 'materials/CardMaterial';
import React, { useState } from 'react';
import SearchMaterial from 'materials/SearchMatrial';
import SpinMaterial from 'materials/SpinMaterial';
import { DataTableResProps } from 'interface/general';
import { DataDeliveryCountType } from '.';

interface TableDataDeliveryTypes {
  isLoading: boolean;
  dataTableDelivery: DataTableResProps | null;
  selectedDownloadtype: string | null;
  setSelectedDownloadType: (value: string) => void;
  handleSearchName: (value: string) => void;
  handlePage: (value: number) => void;
  handleDownload: () => void;
  disableDownloadButton: boolean;
  dataDeliveryCount: DataDeliveryCountType;
}
export default function TableDataDeliveryComponent({
  isLoading,
  selectedDownloadtype,
  setSelectedDownloadType,
  dataTableDelivery,
  handleSearchName,
  handlePage,
  handleDownload,
  disableDownloadButton,
  dataDeliveryCount,
}: TableDataDeliveryTypes) {
  const [searchName, setSearchName] = useState<string>('');
  const { Option } = Select;
  const statusDelivery = (props: any) =>
    props === 'OnProcces'
      ? 'Proses'
      : props === 'Success'
      ? 'Diterima'
      : props === 'OnHold'
      ? 'On Hold'
      : 'Dikembalikan';

  const pagePagination = (dataTableDelivery?.page || 0) + 1 || 0;
  const totalPagination = (dataTableDelivery?.totalPages || 0) * 10 || 0;
  const looppage = (pagePagination - 1) * 10 + 1;

  const handleViewStatusDelivery = (event: any) => {
    const url = '/delivery/status?' + event;
    window.open(url, '_blank');
  };

  const columns = [
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <b>No</b>
        </div>
      ),
      width: 50,
      render: (text: any, record: any, index: any) => (
        <span>{pagePagination > 1 ? index + looppage : index + 1}</span>
      ),
      key: 'no',
    },
    {
      title: <b>Waybill</b>,
      dataIndex: 'waybillCode',
      key: 'waybillCode',
      render: (waybillCode: any) => (
        <span style={{ color: 'red' }}>{waybillCode}</span>
      ),
      width: 120,
    },
    {
      title: <b>Nama</b>,
      dataIndex: 'shipToName',
      key: 'shipToName',
      width: 80,
    },
    {
      title: <b>Alamat</b>,
      dataIndex: 'shipToAddress',
      key: 'shipToAddress',
      render: (alamat: any) => (
        <span style={{ color: 'red' }}>{`${Object.values(JSON.parse(alamat))
          .join(' ')
          .substring(0, 30)}`}</span>
      ),
      width: 80,
    },
    {
      title: <b>Status</b>,
      dataIndex: 'deliveryStatus',
      key: 'deliveryStatus',
      render: (status: any) => (
        <span
          style={{
            color:
              status === 'OnHold'
                ? '#9E9E9E'
                : status === 'OnProcces'
                ? '#ECA722'
                : status === 'Success'
                ? '#00AB44'
                : status === 'Return'
                ? '#EA1A1A'
                : '#000000',
          }}
        >
          {statusDelivery(status)}
        </span>
      ),
      width: 100,
    },
    {
      title: <b>Aksi</b>,
      dataIndex: 'waybillCode',
      key: 'action',
      width: 90,
      render: (waybillCode: any) => (
        <div>
          <Button
            onClick={() => handleViewStatusDelivery(waybillCode)}
            icon={'eye'}
            style={{
              borderRadius: '8px',
              color: '#fafafa',
              paddingBottom: '3px',
              background: '#7956EE',
              borderColor: '#7956EE',
            }}
          />
        </div>
      ),
    },
  ];
  return (
    <div>
      <SpinMaterial
        spinning={isLoading}
        style={{}}
        size={'large'}
        content={
          <div>
            <CardMaterial
              extra={''}
              title={''}
              style={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
              }}
              content={
                <div>
                  <Row>
                    <Col span={12}>
                      <div>
                        <span
                          style={{ color: '#11BEFF', paddingRight: '20px' }}
                        >
                          <b>SEMUA DATA</b>
                        </span>
                        <SearchMaterial
                          value={searchName}
                          style={{ borderRadius: '10px', width: '50%' }}
                          placeholder={'Cari berdasarkan nama'}
                          onChange={e => setSearchName(e.target.value)}
                          onPressEnter={() => handleSearchName(searchName)}
                        />
                      </div>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <Col span={6}>
                          <div style={{ color: '#9E9E9E' }}>
                            On Hold :{' '}
                            <span>
                              <b>{dataDeliveryCount.onHold}</b>
                            </span>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div style={{ color: '#ECA722' }}>
                            Proses :{' '}
                            <span>
                              <b>{dataDeliveryCount.inProcess}</b>
                            </span>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div style={{ color: '#00AB44' }}>
                            Diterima :{' '}
                            <span>
                              <b>{dataDeliveryCount.success}</b>
                            </span>
                          </div>
                        </Col>
                        <Col span={6}>
                          <div style={{ color: '#EA1A1A' }}>
                            Dikembalikan :{' '}
                            <span>
                              <b>{dataDeliveryCount.return}</b>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              }
            ></CardMaterial>
            <Table
              rowKey={record => record.id}
              pagination={{
                onChange: page => {
                  handlePage(page - 1);
                },
                pageSize: 10,
                total: totalPagination,
                current: pagePagination === 0 ? 1 : pagePagination,
              }}
              footer={() =>
                (dataTableDelivery?.data.length || 0) > 0 && (
                  <div>
                    <Select
                      style={{
                        width: '20%',
                        borderRadius: '10px',
                        paddingRight: '10px',
                      }}
                      placeholder="Pilih Download"
                      showSearch
                      value={selectedDownloadtype || undefined}
                      onChange={setSelectedDownloadType}
                    >
                      <Option key={1} value={'letter'}>
                        Format Surat (*.xls)
                      </Option>
                      <Option key={2} value={'polis'}>
                        Format Polis (*.xls)
                      </Option>
                    </Select>
                    <Button
                      onClick={handleDownload}
                      disabled={disableDownloadButton}
                      style={
                        disableDownloadButton
                          ? {}
                          : {
                              paddingBottom: '3px',
                              borderColor: '#7956EE',
                              background: '#7956EE',
                            }
                      }
                      type="primary"
                      shape="circle"
                      icon="download"
                    ></Button>
                    <span
                      style={{
                        fontStyle: 'italic',
                        color: '#969696',
                        paddingLeft: '10px',
                      }}
                    >
                      Total pencarian <b>({dataTableDelivery?.data.length})</b>
                    </span>
                  </div>
                )
              }
              bordered={false}
              dataSource={dataTableDelivery?.data || []}
              columns={columns}
            />
          </div>
        }
      ></SpinMaterial>
    </div>
  );
}
