import { Button, Col, DatePicker, Row, Select, Steps } from 'antd';

import CardMatrial from 'materials/CardMaterial';
import FormInputTextMaterial from 'materials/FormInputTextMaterial';
import React from 'react';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

const { Step } = Steps;
interface IProps {
  CycleDS: any;
  Waybill: any;
  isLoading: any;
  getProcessTime: any;
  getDeliveryTime: any;
  getFinishTime: any;
  handleSearchTypeDS: any;
  SearchTypeDS: any;
  parsingWaybill: any;
  handleGetDeliveryStatus: any;
  handlePutWaybill: any;
  handleRadioButtonDS: any;
  handleCycleDS: any;
  validateButton: any;
  ValidateWaybill: any;
  helpWaybill: any;
}

export default function DeliveryStatusComponent({
  Waybill,
  getProcessTime,
  getDeliveryTime,
  getFinishTime,
  handleSearchTypeDS,
  SearchTypeDS,
  handleGetDeliveryStatus,
  handlePutWaybill,
  handleCycleDS,
  validateButton,
  ValidateWaybill,
  helpWaybill,
}: IProps) {
  const { Option } = Select;
  const prefixSelector = (
    <Select
      defaultValue="Waybill"
      onChange={handleSearchTypeDS}
      value={SearchTypeDS === 'waybill' ? 'Waybill' : SearchTypeDS}
      style={{ width: 120 }}
    >
      <Option value="waybill">Waybill</Option>
      <Option value="barcode">Barcode</Option>
      <Option value="NomerReferensi">No Referensi</Option>
      <Option value="NomerPolis">No Polis</Option>
      <Option value="NomerRekening">No Rekening</Option>
      <Option value="Name">Nama</Option>
    </Select>
  );
  const StatusStep =
    getProcessTime === null
      ? -1
      : getDeliveryTime === null
      ? getFinishTime === null
        ? 0
        : 2
      : getFinishTime === null
      ? 1
      : 2;
  const DateProcess = moment(getProcessTime).format('DD/MM/YYYY');
  const DateDelivery = moment(getDeliveryTime).format('DD/MM/YYYY');
  const DateFinish = moment(getFinishTime).format('DD/MM/YYYY');
  const placeHolderForm =
    SearchTypeDS === 'waybill'
      ? 'Masukan waybill'
      : SearchTypeDS === 'barcode'
      ? 'Masukan barcode'
      : SearchTypeDS === 'NomerPolis'
      ? 'Masukan nomor polis'
      : SearchTypeDS === 'NomerRekening'
      ? 'Masukan nomor rekening'
      : SearchTypeDS === 'NomerAccount'
      ? 'Masukan nomor akun'
      : SearchTypeDS === 'NomerReferensi'
      ? 'Masukan nomor referensi'
      : 'Masukan nama';
  const minDate = (d: any) => !d || d.isBefore('2020-01-01');
  const DocumentProcessIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.5em"
      height="2.5em"
      viewBox="0 0 381 381"
    >
      <g
        id="Group_757"
        data-name="Group 757"
        transform="translate(-0.381 -0.164)"
      >
        <circle
          id="Ellipse_345"
          data-name="Ellipse 345"
          cx="190.5"
          cy="190.5"
          r="190.5"
          transform="translate(0.381 0.164)"
          fill={
            StatusStep === 0
              ? '#11beff'
              : StatusStep === 1
              ? '#11beff'
              : StatusStep === 2
              ? '#11beff'
              : '#F6F6F6'
          }
        />
        <g
          id="calendar_1_"
          data-name="calendar (1)"
          transform="translate(115.36 120.148)"
        >
          <path
            id="Path_271"
            data-name="Path 271"
            d="M146.045,88.551V13.938A4.545,4.545,0,0,0,141.5,9.393H118.472V4.545a4.545,4.545,0,1,0-9.09,0V9.393H91.2V4.545a4.545,4.545,0,1,0-9.09,0V9.393H63.933V4.545a4.545,4.545,0,0,0-9.09,0V9.393H36.663V4.545a4.545,4.545,0,1,0-9.09,0V9.393H4.545A4.545,4.545,0,0,0,0,13.938V132.41a4.545,4.545,0,0,0,4.545,4.545h75.69a40.889,40.889,0,1,0,65.81-48.4ZM27.573,18.483v4.545a4.545,4.545,0,0,0,9.09,0V18.483h18.18v4.545a4.545,4.545,0,0,0,9.09,0V18.483h18.18v4.545a4.545,4.545,0,1,0,9.09,0V18.483h18.18v4.545a4.545,4.545,0,0,0,9.09,0V18.483h18.483v18.18H9.09V18.483ZM9.09,127.865V45.753H136.955V80.235A40.857,40.857,0,0,0,79.913,91.994a4.518,4.518,0,0,0-2.043-.489H68.78a4.545,4.545,0,1,0,0,9.09h6.885a40.565,40.565,0,0,0-2.085,9.09h-4.8a4.545,4.545,0,1,0,0,9.09h4.8a40.5,40.5,0,0,0,2.085,9.09Zm105.14,18.18a31.815,31.815,0,1,1,31.815-31.815A31.85,31.85,0,0,1,114.23,146.045Zm0,0"
            fill={
              StatusStep === 0
                ? '#FFFFFF'
                : StatusStep === 1
                ? '#FFFFFF'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
          />
          <path
            id="Path_272"
            data-name="Path 272"
            d="M384.725,320.18H371.09V306.545a4.545,4.545,0,1,0-9.09,0v18.18a4.545,4.545,0,0,0,4.545,4.545h18.18a4.545,4.545,0,1,0,0-9.09Zm0,0"
            transform="translate(-252.315 -210.494)"
            fill={
              StatusStep === 0
                ? '#FFFFFF'
                : StatusStep === 1
                ? '#FFFFFF'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
          />
          <path
            id="Path_273"
            data-name="Path 273"
            d="M104.635,182h-9.09a4.545,4.545,0,1,0,0,9.09h9.09a4.545,4.545,0,1,0,0-9.09Zm0,0"
            transform="translate(-63.427 -126.854)"
            fill={
              StatusStep === 0
                ? '#FFFFFF'
                : StatusStep === 1
                ? '#FFFFFF'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
          />
          <path
            id="Path_274"
            data-name="Path 274"
            d="M104.635,242h-9.09a4.545,4.545,0,0,0,0,9.09h9.09a4.545,4.545,0,0,0,0-9.09Zm0,0"
            transform="translate(-63.427 -168.674)"
            fill={
              StatusStep === 0
                ? '#FFFFFF'
                : StatusStep === 1
                ? '#FFFFFF'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
          />
          <path
            id="Path_275"
            data-name="Path 275"
            d="M104.635,302h-9.09a4.545,4.545,0,1,0,0,9.09h9.09a4.545,4.545,0,1,0,0-9.09Zm0,0"
            transform="translate(-63.427 -210.494)"
            fill={
              StatusStep === 0
                ? '#FFFFFF'
                : StatusStep === 1
                ? '#FFFFFF'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
          />
          <path
            id="Path_276"
            data-name="Path 276"
            d="M216.545,191.09h9.09a4.545,4.545,0,0,0,0-9.09h-9.09a4.545,4.545,0,0,0,0,9.09Zm0,0"
            transform="translate(-147.764 -126.854)"
            fill={
              StatusStep === 0
                ? '#FFFFFF'
                : StatusStep === 1
                ? '#FFFFFF'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
          />
          <path
            id="Path_277"
            data-name="Path 277"
            d="M216.545,251.09h9.09a4.545,4.545,0,0,0,0-9.09h-9.09a4.545,4.545,0,0,0,0,9.09Zm0,0"
            transform="translate(-147.764 -168.674)"
            fill={
              StatusStep === 0
                ? '#FFFFFF'
                : StatusStep === 1
                ? '#FFFFFF'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
          />
          <path
            id="Path_278"
            data-name="Path 278"
            d="M104.635,362h-9.09a4.545,4.545,0,1,0,0,9.09h9.09a4.545,4.545,0,1,0,0-9.09Zm0,0"
            transform="translate(-63.427 -252.315)"
            fill={
              StatusStep === 0
                ? '#FFFFFF'
                : StatusStep === 1
                ? '#FFFFFF'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
          />
          <path
            id="Path_279"
            data-name="Path 279"
            d="M336.545,191.09h9.09a4.545,4.545,0,1,0,0-9.09h-9.09a4.545,4.545,0,1,0,0,9.09Zm0,0"
            transform="translate(-231.405 -126.854)"
            fill={
              StatusStep === 0
                ? '#FFFFFF'
                : StatusStep === 1
                ? '#FFFFFF'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
          />
        </g>
      </g>
    </svg>
  );
  const OnDeliveryIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.5em"
      height="2.5em"
      viewBox="0 0 381 381"
    >
      <g
        id="Group_758"
        data-name="Group 758"
        transform="translate(-0.164 -0.164)"
      >
        <circle
          id="Ellipse_346"
          data-name="Ellipse 346"
          cx="190.5"
          cy="190.5"
          r="190.5"
          transform="translate(0.164 0.164)"
          fill={
            StatusStep === 0
              ? '#F6F6F6'
              : StatusStep === 1
              ? '#11beff'
              : StatusStep === 2
              ? '#11beff'
              : '#F6F6F6'
          }
        />
        <path
          id="truck"
          d="M19.15,138.1H10.037A10.057,10.057,0,0,1,0,128.058V35.295A10.057,10.057,0,0,1,10.037,25.258h16.68a47.357,47.357,0,0,1,83.22,0h16.68a10.059,10.059,0,0,1,10.037,10.037V60.984H162.11a18.6,18.6,0,0,1,11.826,4.55,21.844,21.844,0,0,1,7.086,11.045q5.417,20.9,10.838,41.806a19.79,19.79,0,0,1,.59,3.4,14.54,14.54,0,0,1-2.811,10.166A15.517,15.517,0,0,1,181,137.483l-.328.082a20.539,20.539,0,0,1-40.47.53H59.529a20.54,20.54,0,0,1-40.379,0ZM68.327,87.628a3.76,3.76,0,1,0,3.76,3.76A3.76,3.76,0,0,0,68.327,87.628ZM57.365,90.163A3.7,3.7,0,0,1,52.7,92.514a47.388,47.388,0,0,1-29.26-59.719h-13.4a2.517,2.517,0,0,0-2.5,2.5v92.763a2.517,2.517,0,0,0,2.5,2.5H19.15a20.539,20.539,0,0,1,40.378,0h69.587V35.295a2.517,2.517,0,0,0-2.5-2.5h-13.4A47.381,47.381,0,0,1,84.452,92.338a3.779,3.779,0,0,1-2.566-7.089,39.765,39.765,0,1,0-26.7.148,3.713,3.713,0,0,1,2.18,4.766Zm4.687-70.618V47.829A3.769,3.769,0,0,0,65.82,51.6H82.9a3.769,3.769,0,0,0,0-7.539H69.589V19.544a3.769,3.769,0,0,0-7.537,0ZM180.418,129.782a7.514,7.514,0,0,0,3.244-2.365,7.208,7.208,0,0,0,1.279-5.044,12.307,12.307,0,0,0-.354-2.1L178.931,98.45H136.654v32.106H140.2a20.541,20.541,0,0,1,40.22-.774Zm-10.84-4.648a13,13,0,1,0,3.807,9.191A12.954,12.954,0,0,0,169.578,125.134Zm-121.048,0a13,13,0,1,0,3.807,9.191A12.958,12.958,0,0,0,48.53,125.134Zm88.124-34.221h40.323l-3.228-12.45a14.211,14.211,0,0,0-4.643-7.158,11.1,11.1,0,0,0-7-2.782H136.654Zm0,0"
          transform="translate(94.755 111.915)"
          fill={
            StatusStep === 0
              ? '#11beff'
              : StatusStep === 1
              ? '#FFFFFF'
              : StatusStep === 2
              ? '#FFFFFF'
              : '#11beff'
          }
          stroke={
            StatusStep === 0
              ? '#11beff'
              : StatusStep === 1
              ? '#FFFFFF'
              : StatusStep === 2
              ? '#FFFFFF'
              : '#11beff'
          }
          strokeWidth="0.3"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
  const DeliveredIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.5em"
      height="2.5em"
      viewBox="0 0 381 381"
    >
      <g
        id="Group_759"
        data-name="Group 759"
        transform="translate(0.053 -0.164)"
      >
        <circle
          id="Ellipse_347"
          data-name="Ellipse 347"
          cx="190.5"
          cy="190.5"
          r="190.5"
          transform="translate(-0.053 0.164)"
          fill={
            StatusStep === 0
              ? '#F6F6F6'
              : StatusStep === 1
              ? '#F6F6F6'
              : StatusStep === 2
              ? '#11beff'
              : '#F6F6F6'
          }
        />
        <g
          id="tick_1_"
          data-name="tick (1)"
          transform="translate(117.393 107.414)"
        >
          <path
            id="Path_280"
            data-name="Path 280"
            d="M365.888,391.146a2.249,2.249,0,1,0,2.878-3.458L332.9,357.823a2.25,2.25,0,1,0-2.879,3.458Zm0,0"
            transform="translate(-231.803 -251.585)"
            fill={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            stroke={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            strokeWidth="2"
          />
          <path
            id="Path_281"
            data-name="Path 281"
            d="M48.552,391.666a2.241,2.241,0,0,0,1.439-.52l35.87-29.866a2.249,2.249,0,1,0-2.879-3.457L47.113,387.688a2.249,2.249,0,0,0,1.439,3.978Zm0,0"
            transform="translate(-32.602 -251.585)"
            fill={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            stroke={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            strokeWidth="2"
          />
          <path
            id="Path_282"
            data-name="Path 282"
            d="M139.11,215.124H186.5a2.25,2.25,0,0,0,0-4.5H139.11a2.25,2.25,0,1,0,0,4.5Zm0,0"
            transform="translate(-96.366 -148.306)"
            fill={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            stroke={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            strokeWidth="2"
          />
          <path
            id="Path_283"
            data-name="Path 283"
            d="M205.1,254.781H139.109a2.25,2.25,0,1,0,0,4.5H205.1a2.25,2.25,0,1,0,0-4.5Zm0,0"
            transform="translate(-96.365 -179.398)"
            fill={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            stroke={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            strokeWidth="2"
          />
          <path
            id="Path_284"
            data-name="Path 284"
            d="M139.11,126.808H172.1a2.25,2.25,0,0,0,0-4.5H139.11a2.25,2.25,0,1,0,0,4.5Zm0,0"
            transform="translate(-96.366 -86.121)"
            fill={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            stroke={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            strokeWidth="2"
          />
          <path
            id="Path_285"
            data-name="Path 285"
            d="M211.617,168.715a2.25,2.25,0,0,0,2.25,2.249H230.14a2.25,2.25,0,0,0,0-4.5H213.867A2.251,2.251,0,0,0,211.617,168.715Zm0,0"
            transform="translate(-149.005 -117.212)"
            fill={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            stroke={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            strokeWidth="2"
          />
          <path
            id="Path_286"
            data-name="Path 286"
            d="M139.11,170.964h14.619a2.25,2.25,0,1,0,0-4.5H139.11a2.25,2.25,0,1,0,0,4.5Zm0,0"
            transform="translate(-96.366 -117.212)"
            fill={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            stroke={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            strokeWidth="2"
          />
          <path
            id="Path_287"
            data-name="Path 287"
            d="M362.793,58.941,350.736,87.074l-10.7-12.737a2.249,2.249,0,1,0-3.444,2.894l13.045,15.535a2.249,2.249,0,0,0,1.722.8,2.277,2.277,0,0,0,.33-.024,2.249,2.249,0,0,0,1.738-1.34l13.5-31.493a2.25,2.25,0,1,0-4.135-1.772Zm0,0"
            transform="translate(-236.634 -40.541)"
            fill={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            stroke={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            strokeWidth="2"
          />
          <path
            id="Path_288"
            data-name="Path 288"
            d="M149.537,52.771l-4.68-3.9a33.555,33.555,0,0,0-1.168-32.418,2.25,2.25,0,0,0-3.857,2.318,29.158,29.158,0,1,1-5.2-6.395,2.25,2.25,0,0,0,3.055-3.3A33.558,33.558,0,0,0,94.567,7.005l-.4-.333a28.8,28.8,0,0,0-36.867,0l-1.011.844H35.221a18.589,18.589,0,0,0-18.57,18.566V40.67L1.431,53.342c-.045.018-.09.037-.135.059A2.247,2.247,0,0,0,0,55.438v61.872a2.25,2.25,0,0,0,4.5,0V60.239L57.3,104.2a28.8,28.8,0,0,0,36.864,0l52.8-43.965v74.89a11.873,11.873,0,0,1-11.86,11.86H16.36A11.873,11.873,0,0,1,4.5,135.129v-8.821a2.25,2.25,0,0,0-4.5,0v8.821a16.379,16.379,0,0,0,16.36,16.36H135.111a16.379,16.379,0,0,0,16.36-16.36V55.5c.028-1.09-.31-1.371-1.935-2.724ZM87.456,7.516H64.016A24.307,24.307,0,0,1,87.456,7.516Zm-70.8,39.008V64.5L5.856,55.513Zm74.637,54.223a24.3,24.3,0,0,1-31.106,0l-39.031-32.5V26.082a14.085,14.085,0,0,1,14.07-14.068h54.06a33.593,33.593,0,0,0,32.108,54.693,33.252,33.252,0,0,0,8.934-3.13v4.668ZM134.823,64.5V60.772a33.773,33.773,0,0,0,7.711-7.976l3.174,2.642Zm0,0"
            transform="translate(0 0)"
            fill={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            stroke={
              StatusStep === 0
                ? '#11beff'
                : StatusStep === 1
                ? '#11beff'
                : StatusStep === 2
                ? '#FFFFFF'
                : '#11beff'
            }
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  );
  return (
    <div>
      {/* <SpinMaterial
          spinning={isLoading}
          style={{}}
          size={"large"}
          content={ */}
      <div>
        <CardMatrial
          extra={''}
          title={''}
          style={{ borderRadius: '10px' }}
          content={
            <span style={{ color: '#11BEFF' }}>
              <b>STATUS PENGIRIMAN</b>
            </span>
          }
        />
        <div style={{ paddingTop: '20px' }}>
          <CardMatrial
            extra={''}
            title={
              <div style={{ paddingBottom: '20px' }}>
                <Row>
                  <Col
                    span={SearchTypeDS === 'barcode' ? 13 : 14}
                    style={{ paddingTop: '24px' }}
                  >
                    <Row>
                      <Col span={18}>
                        <FormInputTextMaterial
                          validateType={true}
                          onPressEnter={
                            ValidateWaybill === 'error'
                              ? ''
                              : handleGetDeliveryStatus
                          }
                          disabled={false}
                          addonBefore={prefixSelector}
                          placeholder={placeHolderForm}
                          // value={parsingWaybill!==""&&Waybill===null?parsingWaybill:Waybill}
                          value={Waybill}
                          maxLength={100}
                          style={{ width: '100%' }}
                          onChange={handlePutWaybill}
                          onBlur={handlePutWaybill}
                          validatestatus={ValidateWaybill}
                          help={helpWaybill}
                        />
                      </Col>
                      <Col span={6}>
                        <Button
                          onClick={handleGetDeliveryStatus}
                          style={{
                            paddingLeft: '10px',
                            borderRadius: '10px',
                          }}
                          type="primary"
                          icon="search"
                          // disabled={parsingWaybill === undefined ? validateButton : parsingWaybill === undefined ? true : false}>
                          disabled={validateButton}
                        >
                          Cari
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                  {SearchTypeDS === 'barcode' ? (
                    <Col span={11}>
                      <div>
                        <Row>
                          <Col span={3} style={{ paddingTop: '30px' }}>
                            <span>Cycle</span>
                          </Col>
                          <Col span={21} style={{ paddingTop: '24px' }}>
                            <DatePicker
                              style={{ width: '100%' }}
                              locale={locale}
                              format={'YYYY/MM/DD'}
                              placeholder={'YYYY/MM/DD'}
                              disabledDate={minDate}
                              // onOpenChange={handleValidateCycleStart}
                              // value={CycleStart}
                              onChange={handleCycleDS}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
              </div>
            }
            style={{ borderRadius: '10px' }}
            content={
              <div>
                <Steps
                  style={{ paddingLeft: '35px', paddingRight: '35px' }}
                  current={StatusStep}
                >
                  <Step icon={<DocumentProcessIcon />} />
                  <Step icon={<OnDeliveryIcon />} />
                  <Step icon={<DeliveredIcon />} />
                </Steps>
                <Row>
                  <Col
                    style={{
                      paddingLeft: '10px',
                      color: '#000000',
                      fontWeight: 'bold',
                    }}
                    span={8}
                  >
                    <span
                      style={{
                        color:
                          StatusStep === 0
                            ? '#11beff'
                            : StatusStep === 1
                            ? '#11beff'
                            : StatusStep === 2
                            ? '#11beff'
                            : '#8d8d8d',
                        fontWeight: 'bold',
                      }}
                    >
                      Proses Dokumen
                    </span>
                    <div>
                      <span
                        style={{
                          paddingLeft: '15px',
                          color:
                            StatusStep === 0
                              ? '#11beff'
                              : StatusStep === 1
                              ? '#11beff'
                              : StatusStep === 2
                              ? '#11beff'
                              : '#8d8d8d',
                          fontWeight: 'bold',
                        }}
                      >
                        {DateProcess === 'Invalid date' ? '' : DateProcess}
                      </span>
                    </div>
                  </Col>
                  <Col span={8}>
                    <span
                      style={{
                        paddingLeft: '38%',
                        paddingRight: '62%',
                        color:
                          StatusStep === 1
                            ? '#11beff'
                            : StatusStep === 2
                            ? '#11beff'
                            : '#8d8d8d',
                        fontWeight: 'bold',
                      }}
                    >
                      Pengiriman
                    </span>
                    <div>
                      <span
                        style={{
                          paddingLeft: '38%',
                          paddingRight: '62%',
                          color:
                            StatusStep === 1
                              ? '#11beff'
                              : StatusStep === 2
                              ? '#11beff'
                              : '#8d8d8d',
                          fontWeight: 'bold',
                        }}
                      >
                        {DateDelivery === 'Invalid date' ? '' : DateDelivery}
                      </span>
                    </div>
                  </Col>
                  <Col span={8}>
                    <span
                      style={{
                        float: 'right',
                        color: StatusStep === 2 ? '#11beff' : '#8d8d8d',
                        fontWeight: 'bold',
                      }}
                    >
                      Dokumen Selesai
                      <div>
                        <span
                          style={{
                            paddingLeft: '19%',
                            color:
                              StatusStep === 1
                                ? '#11beff'
                                : StatusStep === 2
                                ? '#11beff'
                                : '#8d8d8d',
                            fontWeight: 'bold',
                          }}
                        >
                          {DateFinish === 'Invalid date' ? '' : DateFinish}
                        </span>
                      </div>
                    </span>
                    {/* <div>
                        <span
                          style={{
                            float: 'right',
                            paddingRight: '10px',
                            color: StatusStep === 2 ? '#11beff' : '#8d8d8d',
                            fontWeight: 'bold',
                          }}
                        >
                          {DateFinish === 'Invalid date' ? '' : DateFinish}
                        </span>
                      </div> */}
                  </Col>
                </Row>
              </div>
            }
          />
        </div>
        {/* <Row>
            <Col span={14} style={{ paddingTop: '20px' }}>
              <DocumentInfoContainer />
            </Col>
            <Col span={10} style={{ paddingTop: '20px', paddingLeft: '20px' }}>
              <TrackingCardContainer />
            </Col>
          </Row> */}
      </div>
    </div>
  );
}
