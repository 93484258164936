import * as ActionDeliveryStatus from 'module/DeliveryStatus/Store/ActionSetDeliveryStatus';
import * as ActionExportDeliveryStatus from '../Store/ActionExportDeliveryStatus';
import * as ActionToggle from '../../../core/action/toggle/index';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import DocumentInfoComponent from 'module/DeliveryStatus/Component/DocumentInfoComponent';
import { connect } from 'react-redux';

interface IProps {
  visible: any;
  typeToggle: any;
  getProcessTime: any;
  getDeliveryTime: any;
  getFinishTime: any;
  getDateEstimation: any;
  getBarcode: any;
  getDataDocument: any;
  getWaybill: any;
  getWaybillForm: any;
  getDocumentType: any;
  getDeliveryType: any;
  getProductImage: any;
  getDeliveryPosition: any;
  getCourierCode: any;
  getCourierName: any;
  getOwnerName: any;
  getNoTelp: any;
  getAddress: any;
  getCity: any;
  getZipCode: any;
  getDeliveryDuration: any;
  getImage: any;
  getSignature: any;
  getStatusDelivery: any;
  getReceiverName: any;
  getReceiverTitle: any;
  getReturnedReason: any;
  getReceiverLocation: any;
  getAdditionalData: any;
  getOtherData: any;
  getExportDeliveryStatus: any;
  getFilterTypeDS: any;
  getValueTypeDS: any;
  getDistrictDS: any;
  getDimention: any;
  getAttempt: any;
  getReceiptNumber: any;
  getTotalGroupDocument: any;
  getTotalWeight: any;
  getKoli: any;
  getComplaint: any;
  actionToggle: any;
  actionDeliveryStatus: any;
  actionExportDeliveryStatus: any;
}

function DocumentInfoContainer({
  visible,
  typeToggle,
  getProcessTime,
  getDeliveryTime,
  getFinishTime,
  getDateEstimation,
  getBarcode,
  getDataDocument,
  getWaybill,
  getWaybillForm,
  getDocumentType,
  getDeliveryType,
  getProductImage,
  getDeliveryPosition,
  getCourierCode,
  getCourierName,
  getOwnerName,
  getNoTelp,
  getAddress,
  getCity,
  getZipCode,
  getDeliveryDuration,
  getImage,
  getSignature,
  getStatusDelivery,
  getReceiverName,
  getReceiverTitle,
  getReturnedReason,
  getReceiverLocation,
  getAdditionalData,
  getOtherData,
  getExportDeliveryStatus,
  getFilterTypeDS,
  getValueTypeDS,
  getDistrictDS,
  getDimention,
  getAttempt,
  getReceiptNumber,
  getTotalGroupDocument,
  getTotalWeight,
  getKoli,
  getComplaint,
  actionToggle,
  actionDeliveryStatus,
  actionExportDeliveryStatus,
}: IProps) {
  const [rotation, setRotation] = useState(0);
  const [CollapsListOther, setCollapsListOther] = useState(false);
  const [CollapsKeyListOther, setCollapsKeyListOther] = useState(null);
  const [iconSlection, setIconSlection] = useState(false);

  function handleIconSlection() {
    setIconSlection(!iconSlection);
  }
  function handleSlectionExportPdf(event: any) {
    actionDeliveryStatus.setExportDeliveryStatus(event.key);
  }
  function handleExportPdf() {
    if (getExportDeliveryStatus === 'waybillPelanggan') {
      actionExportDeliveryStatus.getExportDeliveryStatus();
    } else if (getExportDeliveryStatus === 'waybillKurir') {
      actionExportDeliveryStatus.getExportDeliveryStatusKurir();
    }
  }
  function handleCollapsListOther(event: any) {
    setCollapsListOther(!CollapsListOther);
    setCollapsKeyListOther(event);
  }
  function handleModalImage() {
    actionToggle.changeModal();
    actionToggle.changeJenisToggle('image');
  }
  function handleModalSignature() {
    actionToggle.changeModal();
    actionToggle.changeJenisToggle('signature');
  }
  function handleModalAdditionalData() {
    actionToggle.changeModal();
    actionToggle.changeJenisToggle('additionalData');
  }
  function handleCloseModal() {
    actionToggle.changeModal();
    actionToggle.clearJenisToggle();
    setRotation(0);
  }
  return (
    <div>
      <DocumentInfoComponent
        rotation={rotation}
        visible={visible}
        typeToggle={typeToggle}
        getDataDocument={getDataDocument}
        getFilterTypeDS={getFilterTypeDS}
        getValueTypeDS={getValueTypeDS}
        getDistrictDS={getDistrictDS}
        getDimention={getDimention}
        getTotalGroupDocument={getTotalGroupDocument}
        getTotalWeight={getTotalWeight}
        getKoli={getKoli}
        getProcessTime={getProcessTime}
        getDeliveryTime={getDeliveryTime}
        getFinishTime={getFinishTime}
        getDateEstimation={getDateEstimation}
        getBarcode={getBarcode}
        getWaybill={getWaybill}
        getWaybillForm={getWaybillForm}
        getDocumentType={getDocumentType}
        getDeliveryType={getDeliveryType}
        getDeliveryPosition={getDeliveryPosition}
        getCourierCode={getCourierCode}
        getCourierName={getCourierName}
        getOwnerName={getOwnerName}
        getNoTelp={getNoTelp}
        getAddress={getAddress}
        getCity={getCity}
        getZipCode={getZipCode}
        getDeliveryDuration={getDeliveryDuration}
        getImage={getImage}
        getSignature={getSignature}
        getStatusDelivery={getStatusDelivery}
        getReceiverName={getReceiverName}
        getReceiverTitle={getReceiverTitle}
        getReturnedReason={getReturnedReason}
        getReceiverLocation={getReceiverLocation}
        getAdditionalData={getAdditionalData}
        getProductImage={getProductImage}
        getOtherData={getOtherData}
        getExportDeliveryStatus={getExportDeliveryStatus}
        getAttempt={getAttempt}
        getReceiptNumber={getReceiptNumber}
        getComplaint={getComplaint}
        CollapsListOther={CollapsListOther}
        CollapsKeyListOther={CollapsKeyListOther}
        iconSlection={iconSlection}
        handleCollapsListOther={handleCollapsListOther}
        handleModalImage={handleModalImage}
        handleModalSignature={handleModalSignature}
        handleModalAdditionalData={handleModalAdditionalData}
        handleCloseModal={handleCloseModal}
        handleSlectionExportPdf={handleSlectionExportPdf}
        handleIconSlection={handleIconSlection}
        handleExportPdf={handleExportPdf}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  typeToggle: state.Toggle.jenisToggle,
  getProcessTime: state.DeliveryStatus.ProcessTime,
  getDeliveryTime: state.DeliveryStatus.DeliveryTime,
  getFinishTime: state.DeliveryStatus.FinishTime,
  getDateEstimation: state.DeliveryStatus.DateEstimation,
  getFilterTypeDS: state.DeliveryStatus.FilterTypeDS,
  getValueTypeDS: state.DeliveryStatus.ValueTypeDS,
  getDistrictDS: state.DeliveryStatus.DistrictDS,
  getDimention: state.DeliveryStatus.Dimention,
  getAttempt: state.DeliveryStatus.Attempt,
  getReceiptNumber: state.DeliveryStatus.ReceiptNumber,
  getTotalGroupDocument: state.DeliveryStatus.TotalGroupDocument,
  getTotalWeight: state.DeliveryStatus.TotalWeight,
  getKoli: state.DeliveryStatus.Koli,
  getBarcode: state.DeliveryStatus.Barcode,
  getDataDocument: state.DeliveryStatus.DataDocument,
  getWaybill: state.DeliveryStatus.Waybill,
  getWaybillForm: state.DeliveryStatus.WaybillForm,
  getDocumentType: state.DeliveryStatus.DocumentType,
  getDeliveryType: state.DeliveryStatus.DeliveryType,
  getDeliveryPosition: state.DeliveryStatus.DeliveryPosition,
  getCourierCode: state.DeliveryStatus.CourierCode,
  getCourierName: state.DeliveryStatus.CourierName,
  getOwnerName: state.DeliveryStatus.OwnerName,
  getNoTelp: state.DeliveryStatus.NoTelp,
  getAddress: state.DeliveryStatus.Address,
  getCity: state.DeliveryStatus.City,
  getZipCode: state.DeliveryStatus.ZipCode,
  getDeliveryDuration: state.DeliveryStatus.DeliveryDuration,
  getImage: state.DeliveryStatus.Image,
  getSignature: state.DeliveryStatus.Signature,
  getProductImage: state.DeliveryStatus.ProductImage,
  getStatusDelivery: state.DeliveryStatus.StatusDelivery,
  getReceiverName: state.DeliveryStatus.ReceiverName,
  getReceiverTitle: state.DeliveryStatus.ReceiverTitle,
  getReturnedReason: state.DeliveryStatus.ReturnedReason,
  getReceiverLocation: state.DeliveryStatus.ReceiverLocation,
  getAdditionalData: state.DeliveryStatus.AdditionalData,
  getOtherData: state.DeliveryStatus.OtherData,
  getComplaint: state.DeliveryStatus.Complaint,
  getExportDeliveryStatus: state.DeliveryStatus.Properties.ExportDeliveryStatus,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionDeliveryStatus: bindActionCreators(ActionDeliveryStatus, dispatch),
  actionExportDeliveryStatus: bindActionCreators(
    ActionExportDeliveryStatus,
    dispatch
  ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DocumentInfoContainer);
