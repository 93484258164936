import instance from 'config/interceptor';

export const getAllManifestSaveCode = async () => {
  try {
    return await instance.get(process.env.REACT_APP_URL + `/All/false`);
  } catch (error) {
    return error;
  }
};

export const postManifestSaveCode = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + '/ManifestSafeCode',
      data
    );
  } catch (error) {
    return error;
  }
};

export const putManifestSaveCode = async data => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + '/ManifestSafeCode',
      data
    );
  } catch (error) {
    return error;
  }
};

export const deleteManifestSaveCode = async id => {
  try {
    return await instance.delete(
      process.env.REACT_APP_URL + `/ManifestSafeBox/${id}`
    );
  } catch (error) {
    return error;
  }
};

export const postSetManifestSaveCode = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + '/Manifest/SetManifestSafeCode',
      data
    );
  } catch (error) {
    return error;
  }
};

export const downloadListManifest = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + '/Manifest/DrafManifest',
      data,
      { responseType: 'arraybuffer' }
    );
  } catch (error) {
    return error;
  }
};
