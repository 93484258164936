import * as ActionAuth from '../../action/Auth';
import * as ActionCustomCsvCustomer from '../../../core/action/Customer/ActionSetCustomCsvCustomer';
import * as ActionLastAccess from '../../../core/action/LastAccess';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionSetCustomCsv from '../../action/Customer/ActionSetCustomCsvCustomer';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

// import *as ActionDetailCustomCsv from '../../action/Customer/ActionDetailCustomCsvCustomer';

export function* S_AddCustomCsvCustomer(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const IdCustomer = state.Customer.PropertiesCustomer.IdCustomer;
  const DelimitedCsv = state.Customer.FormCustomCsvCustomer.DelimitedCsv;
  const FileType = state.Customer.FormCustomCsvCustomer.FileType;
  const FileSettingName = state.Customer.FormCustomCsvCustomer.FileSettingName;
  const StatusDefault = state.Customer.FormCustomCsvCustomer.StatusDefault;
  const ReverseBarcode = state.Customer.FormCustomCsvCustomer.ReverseBarcode;
  const CycleCsv = state.Customer.FormCustomCsvCustomer.CycleCsv;
  const FormatCycleCsv = state.Customer.FormCustomCsvCustomer.FormatCycleCsv;
  const CityCsv = state.Customer.FormCustomCsvCustomer.CityCsv;
  const ListAddressCsv = state.Customer.FormCustomCsvCustomer.ListAddressCsv;
  const ListAddressOffice =
    state.Customer.FormCustomCsvCustomer.ListAddressOffice;
  const ListAdditionalDataCsv =
    state.Customer.FormCustomCsvCustomer.ListAdditionalDataCsv;
  const ListAddress = ListAddressCsv.map((address: any) => address.trim());
  const _ListAddressOffice = ListAddressOffice.map((address: any) =>
    address.trim()
  );
  const ListAdditionalData = ListAdditionalDataCsv.map((additionaldata: any) =>
    additionaldata.trim()
  );
  yield put(ActionSetCustomCsv.setAddressCsv(ListAddress.join(';')));
  yield put(ActionSetCustomCsv.setAddressOffice(_ListAddressOffice.join(';')));
  yield put(
    ActionSetCustomCsv.setAdditionalDataCsv(ListAdditionalData.join(';'))
  );
  const BarcodeCsv = state.Customer.FormCustomCsvCustomer.BarcodeCsv;
  const OwnerNameCsv = state.Customer.FormCustomCsvCustomer.OwnerNameCsv;
  const PostalCodeCsv = state.Customer.FormCustomCsvCustomer.PostalCodeCsv;
  const NoTelpCsv = state.Customer.FormCustomCsvCustomer.NoTelpCsv;
  const CallLinkCsv = state.Customer.FormCustomCsvCustomer.CallLinkCsv;
  const AddressCsv = state.Customer.FormCustomCsvCustomer.AddressCsv;
  const AddressOffice = state.Customer.FormCustomCsvCustomer.AddressOffice;
  const AdditionalDataCsv =
    state.Customer.FormCustomCsvCustomer.AdditionalDataCsv;
  const GroupNameCsv = state.Customer.FormCustomCsvCustomer.GroupNameCsv;
  const SearchTypeCsv = state.Customer.FormCustomCsvCustomer.SearchTypeCsv;
  const SearchKeyCsv = state.Customer.FormCustomCsvCustomer.SearchKeyCsv;
  const CheckboxCallCenter =
    state.Customer.FormCustomCsvCustomer.CheckboxCallCenter;
  const WaybillCallCenter = state.Customer.FormCustomCsvCustomer.Waybill;
  const AddressCallCenter = state.Customer.FormCustomCsvCustomer.AddressUpdate;
  try {
    yield put(changeLoadingTrue());
    // this.props.actionCustomCsvCustomer.setListAddressCsv(this.props.ListAddressCsv)
    // this.props.actionCustomCsvCustomer.setAddressCsv(List.join(";"))

    yield call(
      axios.post,
      process.env.REACT_APP_URL + '/Setting/CustomCsv',
      {
        delimited: DelimitedCsv === null ? 'Empty' : DelimitedCsv,
        cycle: CycleCsv === null || CycleCsv === '' ? null : CycleCsv.trim(),
        formatCycle: FormatCycleCsv,
        nameCustomCsv: FileSettingName,
        barcode:
          BarcodeCsv === null || BarcodeCsv === '' ? null : BarcodeCsv.trim(),
        barcodeReverse: ReverseBarcode,
        defaultStatus: StatusDefault,
        sendToName:
          OwnerNameCsv === null || OwnerNameCsv === ''
            ? null
            : OwnerNameCsv.trim(),
        address: AddressCsv,
        additionalAddress: AddressOffice ? AddressOffice : null,
        city: CityCsv === null || CityCsv === '' ? null : CityCsv.trim(),
        postalCode:
          PostalCodeCsv === null || PostalCodeCsv === ''
            ? null
            : PostalCodeCsv.trim(),
        phone: NoTelpCsv === null || NoTelpCsv === '' ? null : NoTelpCsv.trim(),
        callLink: CallLinkCsv || null,
        additionalData: AdditionalDataCsv,
        groupName: GroupNameCsv,
        customerId: IdCustomer,
        specificSearch: SearchTypeCsv === null ? 'Empty' : SearchTypeCsv,
        keySearch: SearchKeyCsv,
        callCentreWaybill: WaybillCallCenter,
        callCentreAddress: AddressCallCenter,
        callCentreSetting: CheckboxCallCenter,
        formatFileType: FileType,
        isActive: true,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    // yield put(ActionDetailCustomCsv.getDetailCustomCsvCustomer())
    // yield put(ActionLastAccess.changeLastAccessFiture('DetailCustomCsv'))
    // yield put(ActionSetCustomCsvCustomer.setTypeFormCsv("detail"))
    yield put(ActionLastAccess.changeClearLastAccessFiture());
    yield put(ActionCustomCsvCustomer.clearAllDataCustomCsvCustomer());
    yield put(changeLoadingFalse());
    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>Template unggah berhasil di simpan</p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_AddCustomCsvCustomer() {
  yield takeLatest('SUBMIT_ADD_CUSTOM_CSV_CUSTOMER', S_AddCustomCsvCustomer);
}
