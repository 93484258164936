import * as ActionFormPrintWaybill from '../../../../core/action/Delivery/PrintWaybill/ActionFormPrintWaybill';
import * as toggle from '../../../../core/action/toggle';

import React, { Component } from 'react'
import {bindActionCreators, compose} from 'redux';

import FormSetDeliveryDateComponent from '../../../../components/Content/Delivery/PrintWaybill/FormSetDeliveryDateComponent'
import {connect} from 'react-redux';

interface IProps
{
    waybillPrintType:any
    deliveryDate:any
    visible:any
    jenisToggle:any
    actionToggle:any
    actionFormPrintWaybill:any
}

interface IState
{

}

class FormWaybillPrintTypeContainer extends Component <IProps,IState> {

    constructor(props:any){
        super (props)
        this.state=
        {

        }
        this.handleClearModal = this.handleClearModal.bind(this)
        this.handlePutPrintType = this.handlePutPrintType.bind(this)
        this.handlePutDeliveryDate = this.handlePutDeliveryDate.bind(this)
        this.handleSubmitDeliveryDate = this.handleSubmitDeliveryDate.bind(this)
    }

    handleClearModal()
    {
        this.props.actionFormPrintWaybill.clearWaybillPrintType()
        this.props.actionToggle.changeModal()
        this.props.actionToggle.clearJenisToggle()
    }
    
    handlePutPrintType(event:any)
    {
        this.props.actionFormPrintWaybill.changeWaybillPrintType()
    }
    handlePutDeliveryDate(event:any)
    {
        this.props.actionFormPrintWaybill.changeDeliveryDatePW(event)
    }
    
    handleSubmitDeliveryDate()
    {
        this.props.actionFormPrintWaybill.submitEditDeliveryDate()
    }

    render() {
        return (
            <React.Fragment>
                <FormSetDeliveryDateComponent
                    visible={this.props.jenisToggle==="SetDeliveryDate"&&this.props.visible===true?true:false}
                    handleClearModal={this.handleClearModal}
                    handleSubmitDeliveryDate={this.handleSubmitDeliveryDate}
                    deliveryDate={this.props.deliveryDate}
                    handlePutDeliveryDate={this.handlePutDeliveryDate}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state:any) => ({
    waybillPrintType : state.PrintWaybill.FormPrintWaybill.WaybillPrintType,
    deliveryDate : state.PrintWaybill.FormPrintWaybill.DeliveryDate,
    visible : state.Toggle.modal,
    jenisToggle : state.Toggle.jenisToggle,
    isLoading: state.Toggle.isLoading,
})

const mapDispatchToProps = (dispatch:any) => ({
    actionFormPrintWaybill : bindActionCreators(ActionFormPrintWaybill, dispatch),
    actionToggle : bindActionCreators(toggle, dispatch),
})

const withConnect = connect(mapStateToProps,mapDispatchToProps)

export default compose (
    withConnect
)(FormWaybillPrintTypeContainer)