import React, { useState } from 'react';
import ModalEditLocation from 'module/SettingLocation/components/ModalEditLocation';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getSingleLocation,
  putSettingLocation,
} from 'services/SettingLocationService';
import ModalStatus from 'materials/ModalStatus';
import { getBranches } from 'services/BranchServices';

type EditLocationProps = {
  handleModal: () => void;
  show: number | null;
  refetch: () => void;
};

export type InitialValueLocation = {
  postalCode: string;
  sortCode: string;
  isActive: boolean;
  province: string;
  city: string;
  subDistric: string;
  village: string;
  id: number | null;
};

const defaultValue = {
  postalCode: '',
  sortCode: '',
  isActive: false,
  province: '',
  city: '',
  subDistric: '',
  village: '',
  id: null,
};

export default function EditLocation(props: EditLocationProps) {
  const [initialValue, setInitialValue] =
    useState<InitialValueLocation>(defaultValue);

  const { data: dataBranch } = useQuery(['getBranches', 0, 100], getBranches);

  const { isFetching: isLoading } = useQuery(
    ['GetSingleLocation', props.show],
    () => getSingleLocation(props.show),
    {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          if (res.data) {
            const response = res.data;
            if (response) {
              response.branch =
                dataBranch &&
                dataBranch.find(
                  (item: any) => item.uniqueCode === response.branch
                )?.id;
              setInitialValue(response);
            }
          }
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi kesalahan',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      enabled: !!props.show,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: submitSettingLocation, isLoading: isLoadingSubmit } =
    useMutation(putSettingLocation, {
      onSuccess: res => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Data berhasil disimpan',
          });
          setInitialValue(defaultValue);
          props.handleModal();
          props.refetch();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const handleOnChange = (name: string, value: any) => {
    setInitialValue(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    submitSettingLocation(initialValue);
  };

  const listBranch =
    (dataBranch &&
      dataBranch.map((data: any) => ({
        id: data.id,
        name: `${data.name} (${data.uniqueCode})`,
      }))) ||
    [];

  return (
    <ModalEditLocation
      {...props}
      handleSubmit={handleSubmit}
      handleOnChange={handleOnChange}
      initialValue={initialValue}
      isLoading={isLoading || isLoadingSubmit}
      listBranch={listBranch}
    />
  );
}
