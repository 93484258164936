import { Button, Popconfirm } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../materials/ButtonMaterial';

interface IProps {
  handleCustomStatusEdit: any;
  handleCustomStatusDelete: any;
  handleDeleteStatus: any;
  handleClearDeleteStatus: any;
  typeButton: any;
}
export default class ButtonCustomStatusComponent extends Component<IProps> {
  render() {
    return this.props.typeButton === 'edit' ? (
      <ButtonMaterial
        handleSubmit={this.props.handleCustomStatusEdit}
        teksButton={''}
        size={''}
        shape={''}
        className={''}
        type={'primary'}
        icon={'edit'}
        style={{
          borderRadius: '8px',
          float: 'left',
          paddingBottom: '3px',
          // color: '#FFF' ,
          // background:'#FCC642',
          // borderColor:'#FCC642',
        }}
      />
    ) : (
      <Popconfirm
        title="Apakah anda yakin ?"
        onConfirm={this.props.handleDeleteStatus}
        onCancel={this.props.handleClearDeleteStatus}
        okText="Ya"
        cancelText="Tidak"
        placement="topLeft"
      >
        <Button
          onClick={this.props.handleCustomStatusDelete}
          type={'primary'}
          icon={'delete'}
          style={{
            float: 'left',
            borderRadius: '8px',
            color: '#FFF',
            paddingBottom: '3px',
            background: 'red',
            borderColor: 'red',
          }}
        ></Button>
      </Popconfirm>
    );
  }
}
