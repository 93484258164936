import axios from 'axios';
import { store } from '../core/store/ConfigStore';
import * as ActionAuth from '../core/action/Auth';
import * as ClearAuth from '../core/action/Logout/index';
import * as toggle from '../core/action/toggle/index';
import { notification } from 'antd';

let isRefreshing = false;
let failedQueue: any = [];

const openNotification = () => {
  notification.warning({
    message: 'Sesi berakhir',
    description: 'Silahkan login kembali',
    onClick: () => {
      console.log('Notification Clicked!');
    },
  });
};

const processQueue = (error: any, token = null) => {
  if (error) {
    store.dispatch(ClearAuth.changeClearToken());
    store.dispatch(ClearAuth.changeClearTokenRefresh());
    store.dispatch(ClearAuth.changeClearUsernameAuth());
    store.dispatch(ClearAuth.changeClearDataProfileAuth());
    store.dispatch(toggle.changeJenisToggle('logout'));
    store.dispatch(toggle.clearJenisToggle());
    openNotification();
  } else {
    store.dispatch(ActionAuth.changeToken(token));
    store.dispatch(ActionAuth.changeStatusTokenRefresh(token));
  }
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// INTERCEPTOR REQUEST
instance.interceptors.request.use(
  async (config: any) => {
    const unAuthenticatedUrls = ['login', 'license', 'Setting'];
    const token = store.getState().Auth.Token;
    if (unAuthenticatedUrls.indexOf(config.url) === -1) {
      config.headers['Authorization'] = 'Bearer ' + token;
    } else {
      delete config.headers['Authorization'];
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return instance(originalRequest);
            })
            .catch(err => {
              return err;
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise(function (resolve, reject) {
          const token = store.getState().Auth.Token;
          const tokenRefresh = store.getState().Auth.TokenRefresh;
          axios
            .post(
              `${process.env.REACT_APP_URL}/Token/refresh/${tokenRefresh}`,
              `"${token}"`,
              {
                headers: {
                  'Content-Type': 'application/json',
                },
              }
            )
            .then(({ data }) => {
              instance.defaults.headers.common['Authorization'] =
                'Bearer ' + data.token;
              originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
              processQueue(null, data.token);
              resolve(instance(originalRequest));
            })
            .catch(err => {
              console.log('DY: this is error happend 2');
              processQueue(err, null);
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      } else if (
        error.response.status === 500 &&
        (error.response.data.message ===
          'Token Signature could not be verified.' ||
          error.response.data.message === 'The token has been blacklisted')
      ) {
        // window.location.replace('/login');
        console.log('Session expired. Please login again.');
        // toast.error("Session expired. Please login again.");
      }
    } else {
      console.log(error.message);
      //   toast.error(error.message);
    }

    return Promise.reject(error);
  }
);

export default instance;
