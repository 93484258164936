import React, { Component } from 'react';
import { getTabMenus } from 'lib/util';

import FormPrintWaybillContainer from '../../../../containers/Content/Delivery/Document/FormPrintWaybillContainer';
import Distribution from 'module/DeliveryDocument/Distribution/container';
import FormUnggahDataContainer from '../../../../containers/Content/Delivery/Document/UploadData/FormUnggahDocumentContainer';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

interface IProps {
  lastAccessFiture: any;
  handleChangeTabs: any;
  sideMenus: any;
}

export default class IndexDeliveryComponent extends Component<IProps> {
  componentDidUpdate() {
    const handleChange = () => {
      if (this.props.lastAccessFiture === 'deliveryUploadData') {
        this.props.handleChangeTabs('1');
      } else if (this.props.lastAccessFiture === 'deliveryDistribution') {
        this.props.handleChangeTabs('2');
      } else if (this.props.lastAccessFiture === 'deliveryPrintWaybill') {
        this.props.handleChangeTabs('3');
      }
    };
    if (this.props.lastAccessFiture !== null) {
      handleChange();
    }
  }
  render() {
    let a = '1';
    const tabMenus =
      getTabMenus(this.props.sideMenus, 'Pengiriman', 'Dokumen') || [];

    if (this.props.lastAccessFiture === 'deliveryPrintWaybill') {
      a = '3';
    } else if (this.props.lastAccessFiture === 'deliveryDistribution') {
      a = '2';
    } else {
      a = '1';
    }

    return (
      <div>
        <Tabs
          style={{ padding: '35px' }}
          defaultActiveKey={a}
          onChange={this.props.handleChangeTabs}
          activeKey={a}
        >
          {tabMenus.map((item, index) => (
            <TabPane tab={<b>{item.label}</b>} key={index + 1}>
              {item.label === 'Unggah Data' ? (
                <FormUnggahDataContainer />
              ) : item.label === 'Distribusi' ? (
                <Distribution />
              ) : item.label === 'Cetak Waybill' ? (
                <FormPrintWaybillContainer />
              ) : null}
            </TabPane>
          ))}
        </Tabs>
      </div>
    );
  }
}
