import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionLogin from '../../../../module/Login/Store';
import * as CourierTable from '../../../action/ManageAccount/Courier/ActionTableCourier';
import * as FormCourier from '../../../action/ManageAccount/Courier/ActionFormDetailCourier';
import * as toggle from '../../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_submitEditCourier(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const IdCourier = state.Courier.PropertiesCourier.idCourier;
  const CourierName = state.Courier.FormCourier.CourierName;
  const CourierLastName = state.Courier.FormCourier.CourierLastName;
  const CourierEmail = state.Courier.FormCourier.CourierEmail;
  const CourierPhoneNumber = state.Courier.FormCourier.CourierPhoneNumber;
  const CourierDob = state.Courier.FormCourier.CourierDob;
  const CourierAddress = state.Courier.FormCourier.CourierAddress;
  const CourierStatus = state.Courier.FormCourier.CourierStatus;
  // const CourierPhoto = state.Courier.FormCourier.CourierPhoto
  const CourierCode = state.Courier.FormCourier.CourierCode;
  const AreaCode = state.Courier.FormCourier.AreaCodeId;
  const idBranch = state.Branch.PropertiesBranch.idBranch;

  const conditionCB = CourierStatus === 1 ? true : false;
  try {
    yield put(toggle.changeLoadingTrue());
    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/Courier/update',
      {
        id: IdCourier,
        code: CourierCode,
        firstName: CourierName,
        lastName: CourierLastName,
        dob: CourierDob,
        phone: CourierPhoneNumber,
        email: CourierEmail,
        // photo : CourierPhoto,
        branchId: AreaCode,
        address: CourierAddress,
      },
      { headers: config }
    );

    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/Profile/status',
      {
        userName: CourierCode,
        status: conditionCB,
      },
      { headers: config }
    );

    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(FormCourier.changeClearDataCourierForm());

    yield put(toggle.changeModal());
    yield put(toggle.clearJenisToggle());
    yield put(CourierTable.clearCourierTable());
    if (state.Auth.Role === 'Branch') {
      yield put(CourierTable.getCourierTableBranch());
    } else {
      if (idBranch === null) {
        yield put(CourierTable.getCourierTable());
      } else {
        yield put(CourierTable.getCourierTableBranch());
      }
    }
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Berhasil Mengubah Data Kurir</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_submitEditCourier() {
  yield takeLatest('SUBMIT_EDIT_COURIER', S_submitEditCourier);
}
