import * as ActionFieldBranch from 'core/action/Branch/ActionFieldBranch';
import * as ActionToggle from 'core/action/toggle/index';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import GroupBranchFormComponent from 'module/Branch/Component/GroupBranchFormComponent';
import { connect } from 'react-redux';

interface IProps {
  visible: any;
  typeToggle: any;
  actionToggle: any;
  actionFieldBranch: any;
  isLoading: any;
  groupBranchId: any;
  groupName: any;
  getListGroupName: any;
}

function GroupBranchFormContainer({
  visible,
  typeToggle,
  actionToggle,
  actionFieldBranch,
  isLoading,
  groupBranchId,
  groupName,
  getListGroupName,
}: IProps) {
  const [validateGroupName, setValidateGroupName]: any = useState(null);
  const [page, setpage]: any = useState(null);

  console.log(setpage);

  function handleCloseModal() {
    actionFieldBranch.clearBranchGroupFrom();
    setValidateGroupName(null);
    actionToggle.changeModal2();
    actionToggle.clearJenisToggle2();
  }
  function handleCancel() {
    actionFieldBranch.clearBranchGroupFrom();
    setValidateGroupName(null);
  }
  function handlePutGroupName(event: any) {
    actionFieldBranch.changeGroupName(event.target.value);
    if (event.target.value === '') {
      setValidateGroupName('error');
    } else if (event.target.value.length < 3) {
      setValidateGroupName('notMinChar');
    } else {
      setValidateGroupName('');
    }
  }
  function handleSubmit() {
    if (groupBranchId === null) {
      actionFieldBranch.submitGroupNameBranch();
    } else {
      actionFieldBranch.submitEditGroupNameBranch();
    }
  }
  return (
    <React.Fragment>
      <GroupBranchFormComponent
        visible={
          visible === true && typeToggle === 'addBranchGroup' ? true : false
        }
        page={page}
        handleCloseModal={handleCloseModal}
        isLoading={isLoading}
        groupBranchId={groupBranchId}
        groupName={groupName}
        getListGroupName={getListGroupName}
        handlePutGroupName={handlePutGroupName}
        stateValidate={validateGroupName}
        helpValidateGroupName={
          validateGroupName === 'error'
            ? 'Nama wajib diisi'
            : validateGroupName === 'notMinChar'
            ? 'Nama minimal 3 karakter'
            : ''
        }
        validateButton={
          groupName === null ||
          groupName === '' ||
          validateGroupName === 'notMinChar' ||
          validateGroupName === 'error'
            ? true
            : false
        }
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal2,
  typeToggle: state.Toggle.jenisToggle2,
  isLoading: state.Toggle.isLoading,
  groupBranchId: state.Branch.DetailBranch.GroupBranchId,
  groupName: state.Branch.DetailBranch.GroupName,
  getListGroupName: state.Branch.ListGroupName,
});
const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionFieldBranch: bindActionCreators(ActionFieldBranch, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(GroupBranchFormContainer);
