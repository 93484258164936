import * as ActionActivationVoip from '../../../../core/action/Customer/Voip/ActionActivationVoip';
import * as ActionLogin from '../../../../module/Login/Store';
import * as toggle from '../../../action/toggle';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_GetDetailCustomerActivation() {
  const state = yield select();
  const token = state.Auth.Token;
  const customerId = state.Customer.PropertiesCustomer.IdCustomer;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(toggle.changeLoadingTrue());
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + `/VoipSetting/activeProduct/${customerId}`,
      { headers: config }
    );
    yield put(ActionActivationVoip.changeProductTypeV(data.productId));
    yield put(ActionActivationVoip.changeToggleActivation(data.status));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(toggle.changeLoadingFalse());
    } else {
      yield put(toggle.changeLoadingFalse());
      const propsError = ErrorMassage('Detail Produk Aktifasi VOIP');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_GetDetailCustomerActivation() {
  yield takeLatest(
    'GET_DETAIL_CUSTOMER_ACTIVATION',
    S_GetDetailCustomerActivation
  );
}
