import React, { Component } from 'react';
import { Table, Popconfirm, Button } from 'antd';
import SpinMaterial from '../../../materials/SpinMaterial';
import ButtonMaterial from '../../../materials/ButtonMaterial';
import CardMaterial from '../../../materials/CardMaterial';
import FormCustomDataReportCustomerContainer from '../../../containers/Content/Customer/FormCustomDataReportCustomerContainer';
const ButtonGroup = Button.Group;

interface IProps {
  handleFormCustomReportDataCustomer: any;
  handleEditFormCustomReportDataCustomer: any;
  dataTableCustomReportData: any;
  handleDeleteCustomReport: any;
  handleClearDeleteCustomReport: any;
  handlePutIdCustomReport: any;
  getCustomername: any;
  visible: any;
  jenisToggle: any;
  isLoading: any;
}
export default class TableDetailConfigReportComponent extends Component<
  IProps
> {
  render() {
    const columns = [
      {
        title: <b>Posisi</b>,
        key: 'position',
        dataIndex: 'position',
        render: (position: any) => (
          <span style={{ color: 'red' }}>{position}</span>
        ),
        width: 120,
      },
      {
        title: <b>Judul Kolom</b>,
        dataIndex: 'name',
        key: 'name',
        width: 200,
      },
      {
        title: <b>Nama Kolom</b>,
        key: 'columnName',
        dataIndex: 'columnName',
        render: (columnName: any) => (
          <span style={{ color: 'red' }}>
            {columnName === null ? '-' : columnName}
          </span>
        ),
        width: 120,
      },
      {
        title: <b>Format</b>,
        key: 'formatValue',
        dataIndex: 'formatValue',
        width: 130,
        render: (formatValue: any) => (
          <span>{formatValue === null ? '-' : formatValue}</span>
        ),
      },
      {
        title: <b>Aksi</b>,
        key: 'action',
        width: 130,
        render: (record: any) => (
          <ButtonGroup>
            <div style={{ float: 'left', paddingRight: '15px' }}>
              <ButtonMaterial
                disabled={false}
                handleSubmit={() =>
                  this.props.handleEditFormCustomReportDataCustomer(record.id)
                }
                teksButton={''}
                size={''}
                shape={''}
                className={''}
                type={'primary'}
                icon={'edit'}
                style={{
                  float: 'left',
                  borderRadius: '8px',
                  color: '#FFF',
                  paddingBottom: '3px',
                  background: '#FCC642',
                  borderColor: '#FCC642',
                }}
              />
            </div>
            <div style={{ float: 'right' }}>
              <Popconfirm
                title="Apakah anda yakin ?"
                onConfirm={this.props.handleDeleteCustomReport}
                onCancel={this.props.handleClearDeleteCustomReport}
                okText="Ya"
                cancelText="Tidak"
                placement="topLeft"
              >
                <Button
                  onClick={() => this.props.handlePutIdCustomReport(record.id)}
                  type={'primary'}
                  icon={'delete'}
                  style={{
                    float: 'left',
                    borderRadius: '8px',
                    color: '#FFF',
                    paddingBottom: '3px',
                    background: 'red',
                    borderColor: 'red',
                  }}
                ></Button>
              </Popconfirm>
            </div>
          </ButtonGroup>
        ),
      },
    ];
    return (
      <div>
        <FormCustomDataReportCustomerContainer />
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <div>
              <CardMaterial
                extra={''}
                title={''}
                style={{}}
                content={
                  <div>
                    <div style={{ float: 'left', paddingTop: '8px' }}>
                      <span style={{ color: '#11BEFF', fontWeight: 'bold' }}>
                        DATA
                      </span>
                    </div>
                    <div style={{ float: 'right' }}>
                      <ButtonMaterial
                        disabled={false}
                        handleSubmit={
                          this.props.handleFormCustomReportDataCustomer
                        }
                        teksButton={<b>TAMBAHKAN KOLOM</b>}
                        size={''}
                        shape={''}
                        className={''}
                        type={'link'}
                        icon={''}
                        style={{
                          color: '#11BEFF',
                          paddingRight: '15px',
                        }}
                      />

                      <ButtonMaterial
                        disabled={false}
                        handleSubmit={
                          this.props.handleFormCustomReportDataCustomer
                        }
                        teksButton={''}
                        size={''}
                        shape={'circle'}
                        className={''}
                        type={'primary'}
                        icon={'plus'}
                        style={{
                          color: '#fafafa',
                          paddingBottom: '3px',
                          background: '#31E116',
                          borderColor: '#31E116',
                        }}
                      />
                    </div>
                  </div>
                }
              />
              <Table
                pagination={{ onChange: page => {}, pageSize: 10 }}
                bordered={false}
                dataSource={this.props.dataTableCustomReportData}
                columns={columns}
              />
            </div>
          }
        />
      </div>
    );
  }
}
