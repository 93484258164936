import React from 'react';
import { Radio } from 'antd';

type RadioFieldProps = {
  onChange?: (value: any) => void;
  value?: any;
  data: Array<{ value: any; label: any }>;
  disabled?: boolean;
};

export default function RadioField({
  onChange = () => undefined,
  value,
  data = [],
  disabled = false,
}: RadioFieldProps) {
  return (
    <Radio.Group
      onChange={e => onChange(e.target.value)}
      value={value}
      style={{ display: 'flex', gap: 42, alignItems: 'center' }}
      disabled={disabled}
    >
      {data.map((item, index) => (
        <Radio value={item.value} key={index}>
          {item.label}
        </Radio>
      ))}
    </Radio.Group>
  );
}
