import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import FormServerVoipComponent from '../../../../components/Content/Voip/ServerVoip/FormServerVoipComponent';
import * as ActionToggle from '../../../../core/action/toggle/index';
import * as ActionServerVoip from '../../../../core/action/Voip/Server/ActionServer';

interface IProps {
  typeToggle: any;
  visible: any;
  isLoading: any;
  actionToggle: any;
  actionServerVoip: any;
  Name: any;
  IpServer1: any;
  IpServer2: any;
  IpServer3: any;
  IpServer4: any;
  IpPort: any;
  Kuota: any;
  Position: any;
  ToggleServer: any;
}
interface IState {
  ValidateName: any;
  ValidateIpServer1: any;
  ValidateIpServer2: any;
  ValidateIpServer3: any;
  ValidateIpServer4: any;
  ValidateIpPort: any;
  ValidateKuota: any;
  ValidatePosition: any;
}

class FormServerVoipContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateName: null,
      ValidateKuota: null,
      ValidateIpServer1: null,
      ValidateIpServer2: null,
      ValidateIpServer3: null,
      ValidateIpServer4: null,
      ValidateIpPort: null,
      ValidatePosition: null,
    };
    this.handlePutName = this.handlePutName.bind(this);
    this.handleIpServer1 = this.handleIpServer1.bind(this);
    this.handleIpServer2 = this.handleIpServer2.bind(this);
    this.handleIpServer3 = this.handleIpServer3.bind(this);
    this.handleIpServer4 = this.handleIpServer4.bind(this);
    this.handleIpPort = this.handleIpPort.bind(this);
    this.handlePutKuota = this.handlePutKuota.bind(this);
    this.handlePutPosition = this.handlePutPosition.bind(this);
    this.handleToggleServer = this.handleToggleServer.bind(this);
    this.handleSubmitServerVoip = this.handleSubmitServerVoip.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handlePutName(event: any) {
    this.props.actionServerVoip.setServerVoipName(event.target.value);
    const RegexMinChar = /(?=^.{5,}$)/;
    if (event.target.value === '') {
      this.setState({
        ValidateName: 'error',
      });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({
        ValidateName: 'Length',
      });
    } else {
      this.setState({
        ValidateName: '',
      });
    }
  }

  handleIpServer1(event: any) {
    this.props.actionServerVoip.setServerVoipIpServer1(event.target.value);
    const regexNumber = /^[0-9][A-Za-z0-9 -]*$/;
    if (event.target.value === '') {
      this.setState({
        ValidateIpServer1: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateIpServer1: 'number',
      });
    } else if (event.target.value > 255) {
      this.setState({
        ValidateIpServer1: 'regip',
      });
    } else {
      this.setState({
        ValidateIpServer1: '',
      });
    }
  }

  handleIpServer2(event: any) {
    this.props.actionServerVoip.setServerVoipIpServer2(event.target.value);
    const regexNumber = /^[0-9][A-Za-z0-9 -]*$/;
    if (event.target.value === '') {
      this.setState({
        ValidateIpServer2: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateIpServer2: 'number',
      });
    } else if (event.target.value > 255) {
      this.setState({
        ValidateIpServer2: 'regip',
      });
    } else {
      this.setState({
        ValidateIpServer2: '',
      });
    }
  }

  handleIpServer3(event: any) {
    this.props.actionServerVoip.setServerVoipIpServer3(event.target.value);
    const regexNumber = /^[0-9][A-Za-z0-9 -]*$/;
    if (event.target.value === '') {
      this.setState({
        ValidateIpServer3: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateIpServer3: 'number',
      });
    } else if (event.target.value > 255) {
      this.setState({
        ValidateIpServer3: 'regip',
      });
    } else {
      this.setState({
        ValidateIpServer3: '',
      });
    }
  }

  handleIpServer4(event: any) {
    this.props.actionServerVoip.setServerVoipIpServer4(event.target.value);
    const regexNumber = /^[0-9][A-Za-z0-9 -]*$/;

    if (event.target.value === '') {
      this.setState({
        ValidateIpServer4: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateIpServer4: 'number',
      });
    } else if (event.target.value > 255) {
      this.setState({
        ValidateIpServer4: 'regip',
      });
    } else {
      this.setState({
        ValidateIpServer4: '',
      });
    }
  }

  handleIpPort(event: any) {
    this.props.actionServerVoip.setServerVoipIpPort(event.target.value);
    const regexNumber = /^[0-9][A-Za-z0-9 -]*$/;

    if (event.target.value === '') {
      this.setState({
        ValidateIpPort: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateIpPort: 'number',
      });
    } else {
      this.setState({
        ValidateIpPort: '',
      });
    }
  }

  handlePutKuota(event: any) {
    this.props.actionServerVoip.setServerVoipKuota(event.target.value);
    const regexNumber = /^[0-9][A-Za-z0-9 -]*$/;
    if (event.target.value === '') {
      this.setState({
        ValidateKuota: 'error',
      });
    } else if (
      event.target.value === 0 ||
      event.target.value === '0' ||
      event.target.value === '00' ||
      event.target.value === '000'
    ) {
      this.setState({
        ValidateKuota: 'zero',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateKuota: 'number',
      });
    } else {
      this.setState({
        ValidateKuota: '',
      });
    }
  }

  handlePutPosition(event: any) {
    const regexNumber = /^[0-9][A-Za-z0-9 -]*$/;
    this.props.actionServerVoip.setServerVoipPosition(event.target.value);
    if (event.target.value === '') {
      this.setState({
        ValidatePosition: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidatePosition: 'number',
      });
    } else {
      this.setState({
        ValidatePosition: '',
      });
    }
  }

  handleToggleServer(event: any) {
    this.props.actionServerVoip.setServerVoipToggle(event);
  }

  handleCloseModal() {
    this.props.actionServerVoip.changeClearAllFormServerVoip();
    this.props.actionToggle.changeModal();
    this.props.actionToggle.clearJenisToggle();
    this.setState({
      ValidateName: null,
      ValidateKuota: null,
      ValidateIpServer1: null,
      ValidateIpServer2: null,
      ValidateIpServer3: null,
      ValidateIpServer4: null,
      ValidateIpPort: null,
      ValidatePosition: null,
    });
  }

  handleSubmitServerVoip() {
    if (this.props.typeToggle === 'AddServerVoip') {
      this.props.actionServerVoip.submitAddServerVoip();
    } else {
      this.props.actionServerVoip.submitEditServerVoip();
    }
    this.setState({
      ValidateName: null,
      ValidateKuota: null,
      ValidateIpServer1: null,
      ValidateIpServer2: null,
      ValidateIpServer3: null,
      ValidateIpServer4: null,
      ValidateIpPort: null,
      ValidatePosition: null,
    });
  }

  render() {
    return (
      <div>
        <FormServerVoipComponent
          typeToggle={this.props.typeToggle}
          visible={this.props.visible}
          isLoading={this.props.isLoading}
          name={this.props.Name}
          ipServer1={this.props.IpServer1}
          ipServer2={this.props.IpServer2}
          ipServer3={this.props.IpServer3}
          ipServer4={this.props.IpServer4}
          ipPort={this.props.IpPort}
          kuota={this.props.Kuota}
          position={this.props.Position}
          toggleServer={this.props.ToggleServer}
          handlePutName={this.handlePutName}
          handleIpServer1={this.handleIpServer1}
          handleIpServer2={this.handleIpServer2}
          handleIpServer3={this.handleIpServer3}
          handleIpServer4={this.handleIpServer4}
          handleIpPort={this.handleIpPort}
          handlePutKuota={this.handlePutKuota}
          handlePutPosition={this.handlePutPosition}
          handleToggleServer={this.handleToggleServer}
          validateName={
            this.state.ValidateName === 'Length'
              ? 'error'
              : '' || this.state.ValidateName === 'error'
              ? 'error'
              : ''
          }
          validateIpServer1={this.state.ValidateIpServer1}
          validateIpServer2={this.state.ValidateIpServer2}
          validateIpServer3={this.state.ValidateIpServer3}
          validateIpServer4={this.state.ValidateIpServer4}
          validateIpPort={this.state.ValidateIpPort}
          validateKuota={
            this.state.ValidateKuota === 'error'
              ? 'error'
              : '' || this.state.ValidateKuota === 'zero'
              ? 'error'
              : '' || this.state.ValidateKuota === 'number'
              ? 'error'
              : ''
          }
          validatePosition={
            this.state.ValidatePosition === 'error'
              ? 'error'
              : '' || this.state.ValidatePosition === 'number'
              ? 'error'
              : ''
          }
          helpValidateName={
            this.state.ValidateName === 'Length'
              ? 'Nama harus lebih dari 5 karakter'
              : '' || this.state.ValidateName === 'error'
              ? 'Nama sandi wajib diisi'
              : ''
          }
          helpValidateKuota={
            this.state.ValidateKuota === 'zero'
              ? 'Tidak boleh kosong'
              : '' || this.state.ValidateKuota === 'number'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateKuota === 'error'
              ? 'Kuota wajib diisi'
              : ''
          }
          helpValidatePosition={
            this.state.ValidatePosition === 'error'
              ? 'Urutan wajib diisi'
              : '' || this.state.ValidatePosition === 'number'
              ? 'Tidak boleh selain angka'
              : ''
          }
          handleSubmitServerVoip={this.handleSubmitServerVoip}
          handleCloseModal={this.handleCloseModal}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  typeToggle: state.Toggle.jenisToggle,
  isLoading: state.Toggle.isLoading,
  Name: state.Voip.ServerVoip.Name,
  IpServer1: state.Voip.ServerVoip.IpServer1,
  IpServer2: state.Voip.ServerVoip.IpServer2,
  IpServer3: state.Voip.ServerVoip.IpServer3,
  IpServer4: state.Voip.ServerVoip.IpServer4,
  IpPort: state.Voip.ServerVoip.IpPort,
  Kuota: state.Voip.ServerVoip.Kuota,
  Position: state.Voip.ServerVoip.Position,
  ToggleServer: state.Voip.ServerVoip.ToggleServer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionServerVoip: bindActionCreators(ActionServerVoip, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormServerVoipContainer);
