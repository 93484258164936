import * as ActionDetailBranch from 'core/action/Branch/ActionDetailBranch';
import * as ActionRouter from 'core/action/Router/ActionRouter';

import { bindActionCreators, compose } from 'redux';

import ButtonDetailBranchComponent from 'module/Dashboard/Component/Pengiriman/ButtonDetailBranchComponent';
import React from 'react';
import { connect } from 'react-redux';
import history from 'core/utils/History';

interface IProps {
  actionRouter: any;
  actionDetailBranch: any;
  idBranch: any;
  roleAccount: any;
}
function ButtonDetailBranchContainer({
  actionDetailBranch,
  actionRouter,
  idBranch,
}: IProps) {
  function handleDetail() {
    actionDetailBranch.setIdBranch(idBranch);
    actionDetailBranch.changeEditBranch(true);
    actionDetailBranch.changeDetailBranch();
    actionRouter.setRouter('/branch');
    history.push('/branch/detail');
  }
  return (
    <div>
      <ButtonDetailBranchComponent handleDetailBranch={handleDetail} />
    </div>
  );
}
const mapStateProps = (state: any) => ({
  roleAccount: state.Auth.Role,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionDetailBranch: bindActionCreators(ActionDetailBranch, dispatch),
  actionRouter: bindActionCreators(ActionRouter, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(ButtonDetailBranchContainer);
