import React, { useState, useEffect } from 'react';

import AddLevelFormComponent from '../Component/AddLevelFormComponent';
import history from '../../../../core/utils/History';
import { getAllMenus, postLevelGroup } from 'services/LevelAccessServices';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useMutation } from '@tanstack/react-query';
import ModalStatus from 'materials/ModalStatus';
import { reduceRoleFormat } from 'lib/util';

function AddLevelFromContainer() {
  const { data: dataAllMenus, isLoading: isLoadingMenus } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: ['dataAllMenus'],
    queryFn: getAllMenus,
  });
  const defaultDataLevel = {
    levelName: '',
    accountType: '',
    isActive: true,
  };
  const [dataLevel, setDataLevel] = useState<any>(defaultDataLevel);
  const [dataMenus, setDataMenus] = useState<Array<any>>([]);

  const { mutate: submitLevelGroup, isLoading } = useMutation(postLevelGroup, {
    onSuccess: res => {
      if (res.status === 200) {
        ModalStatus({
          status: 'success',
          title: 'Data berhasil disimpan',
        });
        setDataLevel(defaultDataLevel);
        history.push('/manageLevelAccount');
      } else {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan!!!',
          content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
        });
      }
    },
    onError: () => {
      ModalStatus({
        status: 'error',
        title: 'Internal Server Error',
        content: 'Silahkan Hubungi Admin',
      });
    },
  });

  const handleBtnBack = () => {
    history.push('/manageLevelAccount');
  };

  useEffect(() => {
    if (dataAllMenus?.data && dataAllMenus.data.length > 0) {
      dataAllMenus.data.forEach(menus => {
        if (!menus.permission) {
          menus.permission = { view: false };
        }
        if (menus.subMenus.length > 0) {
          menus.subMenus.forEach(submenus => {
            submenus.permission = { view: false };
            if (submenus.tabMenus.length > 0) {
              submenus.tabMenus.forEach(tabmenus => {
                tabmenus.permission = { view: false };
              });
            }
          });
        }
        if (menus.tabMenus.length > 0) {
          menus.tabMenus.forEach(tabmenus => {
            tabmenus.permission = { view: false };
          });
        }
      });
      setDataMenus(dataAllMenus.data);
    }
  }, [dataAllMenus]);

  const handleSubmit = value => {
    const { levelName, isActive, accountType } = value;
    if (dataMenus.filter(value => value.permission.view === true).length <= 0) {
      ModalStatus({
        status: 'error',
        title: 'Terjadi Kesalahan!!!',
        content: 'Wajib pilih salah satu group akses',
      });
      return;
    }
    let menusReduce = [];
    menusReduce = reduceRoleFormat(dataMenus);

    setDataLevel({
      levelName,
      isActive,
      accountType,
    });
    const data = {
      levelName,
      isActive,
      accountType,
      menus: menusReduce,
    };
    submitLevelGroup(data);
  };

  return (
    <AddLevelFormComponent
      handleBtnBack={handleBtnBack}
      handleSubmit={handleSubmit}
      dataAllMenus={dataMenus || []}
      isLoadingSubmit={isLoading}
      isLoadingMenus={isLoadingMenus}
      dataLevel={dataLevel}
      setDataMenus={setDataMenus}
    />
  );
}

export default AddLevelFromContainer;
