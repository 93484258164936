import * as ActionAuth from '../../../core/action/Auth';
import * as ActionCustomerProduct from '../../action/CustomerProduct/ActionFieldCustomerProduct';
import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_submitGroupNameProduct(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const customerId = state.Customer.PropertiesCustomer.IdCustomer;
  const groupName = state.CustomerProduct.DetailCustomerProduct.GroupName;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;

  try {
    yield put(toggle.changeLoadingTrue());
    yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/GroupProduct`,
      {
        groupingName: groupName,
        customerId: customerId,
      },
      { headers: config }
    );
    yield put(ActionCustomerProduct.clearGroupName());
    yield put(toggle.clearJenisToggle2());
    yield put(toggle.changeModal2());
    yield put(toggle.changeLoadingFalse());
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    Modal.success({
      title: <p className={'titleModalNotifSuccess'}>Berhasil di simpan</p>,
      onOk() {},
    });
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}
export function* S_getGroupNameList(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const typeTooggle = state.Toggle.jenisToggle2;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const customerId = state.Customer.PropertiesCustomer.IdCustomer;
  try {
    if (typeTooggle === 'AddNewReportGrup') {
      yield put(toggle.changeLoadingTrue());
    }
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/GroupProduct/ByCustomer/${customerId}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionCustomerProduct.setListGroupName(data));
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}
export function* S_submitEditGroupName(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const customerId = state.Customer.PropertiesCustomer.IdCustomer;
  const groupName = state.CustomerProduct.DetailCustomerProduct.GroupName;
  const groupProductId =
    state.CustomerProduct.DetailCustomerProduct.GroupProductId;
  try {
    yield put(toggle.changeLoadingTrue());
    yield call(
      axios.put,
      `${process.env.REACT_APP_URL}/GroupProduct`,
      {
        idGroupProduct: groupProductId,
        groupingName: groupName,
        customerId: customerId,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(toggle.changeModal2());
    yield put(ActionCustomerProduct.clearGroupName());
    yield put(ActionCustomerProduct.clearGroupProductId());
    yield put(toggle.clearJenisToggle2());
    Modal.success({
      title: (
        <p className={'titleModalNotifSuccess'}>Berhasil Mengubah Nama Grup</p>
      ),
      onOk() {},
    });
    yield put(toggle.changeLoadingFalse());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(toggle.changeLoadingFalse());
  }
}
export function* SM_submitGroupNameProduct() {
  yield takeLatest('SUBMIT_GROUP_NAME', S_submitGroupNameProduct);
}
export function* SM_getGroupNameList() {
  yield takeLatest('GET_GROUP_NAME_LIST', S_getGroupNameList);
}
export function* SM_submitEditGroupName() {
  yield takeLatest('SUBMIT_EDIT_GROUP_NAME', S_submitEditGroupName);
}
