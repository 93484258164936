import {Action} from 'redux';

const initialState = 
{
    PagePagination : 0,
    TotalPagePagination : 1,
    SizeDataPagination : 10,
}

export interface IAuthAction extends Action<string>
{

}

export interface ISetPagePagination extends IAuthAction
{
    PagePagination : any
}

export interface ISetTotalPagePagination extends IAuthAction
{
    TotalPagePagination : any
}

export interface ISetSizeDataPagination extends IAuthAction
{
    SizeDataPagination : any
}


export default function AuthReducerDetailCourier(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "SET_PAGE_PAGINATION":
            const setPagePagination = action as ISetPagePagination
            newState.PagePagination = setPagePagination.PagePagination
        return {...newState}

        case "SET_TOTAL_PAGE_PAGINATION":
            const setTotalPagePagination = action as ISetTotalPagePagination
            newState.TotalPagePagination = setTotalPagePagination.TotalPagePagination
        return {...newState}

        case "SET_SIZE_DATA_PAGINATION":
            const setSizeDataPagination = action as ISetSizeDataPagination
            newState.SizeDataPagination = setSizeDataPagination.SizeDataPagination
        return {...newState}

        case "CLEAR_ALL_PAGINATION":
            newState.PagePagination = 0
            newState.TotalPagePagination = 1
            newState.SizeDataPagination = 10
        return {...newState}
    }
    return {...newState}
}