export function changeRadiobuttonTypeMprocess(RadiobuttonTypeMprocess:any)
{
    return({
        type:"CHANGE_RADIO_BUTTON_TYPE_MANUAL_PROCESS",
        RadiobuttonTypeMprocess
    })
}
export function changeRadiobuttonStatusMprocess(RadiobuttonStatusMprocess:any)
{
    return({
        type:"CHANGE_RADIO_BUTTON_STATUS_MANUAL_PROCESS",
        RadiobuttonStatusMprocess
    })
}
export function changeAreaCodeIdMprocess(AreaCodeIdMprocess:any)
{
    return({
        type:"CHANGE_AREA_CODE_ID_MANUAL_PROCESS",
        AreaCodeIdMprocess
    })
}
export function changeCourierCodeMprocess(CourierCodeMprocess:any)
{
    return({
        type:"CHANGE_COURIER_CODE_MANUAL_PROCESS",
        CourierCodeMprocess
    })
}
export function changeCustomer(CustomerMprocess:any)
{
    return({
        type:"CHANGE_CUSTOMER_MANUAL_PROCESS",
        CustomerMprocess
    })
}
export function changeReason(ReasonMprocess:any)
{
    return({
        type:"CHANGE_REASON_MANUAL_PROCESS",
        ReasonMprocess
    })
}
export function changeReasonOthers(ReasonOthersMprocess:any)
{
    return({
        type:"CHANGE_REASON_OTHERS_MANUAL_PROCESS",
        ReasonOthersMprocess
    })
}
export function changeDateManualProcess(DateMprocess:any)
{
    return({
        type:"CHANGE_DATE_MANUAL_PROCESS",
        DateMprocess
    })
}
export function changeReceiveName(ReceiveNameMprocess:any)
{
    return({
        type:"CHANGE_RECEIVE_NAME_MANUAL_PROCESS",
        ReceiveNameMprocess
    })
}
export function changeWaybillManualProcess(WaybillMprocess:any)
{
    return({
        type:"CHANGE_WAYBILL_MANUAL_PROCESS",
        WaybillMprocess
    })
}
export function changeInfoManualProcess(InformationMprocess:any)
{
    return({
        type:"CHANGE_INFORMATION_MANUAL_PROCESS",
        InformationMprocess
    })
}
export function changeInfoOthersManualProcess(InformationOthersMprocess:any)
{
    return({
        type:"CHANGE_INFORMATION_OTHERS_MANUAL_PROCESS",
        InformationOthersMprocess
    })
}
export function changeCheckBoxWaybillOrBarcode()
{
    return({
        type:"CHANGE_CHECKBOX_WAYBILL_OR_BARCODE"
    })
}
export function clearCourierForm()
{
    return({
        type:"CLEAR_COURIER_CODE_MANUAL_PROCESS"
    })
}
export function clearCourierAndBranchForm()
{
    return({
        type:"CLEAR_BRANCH_AND_COURIER_MANUAL_PROCESS"
    })
}
export function clearReasonForm()
{
    return({
        type:"CLEAR_REASON_MANUAL_PROCESS"
    })
}
export function clearReasonOthersForm()
{
    return({
        type:"CLEAR_REASON_OTHERS_MANUAL_PROCESS"
    })
}
export function clearInfoOthersForm()
{
    return({
        type:"CLEAR_INFORMATION_OTHERS_MANUAL_PROCESS"
    })
}
export function clearWaybillForm()
{
    return({
        type:"CLEAR_WAYBILL_MANUAL_PROCESS"
    })
}
export function clearReceiveNameForm()
{
    return({
        type:"CLEAR_RECEIVE_NAME_MANUAL_PROCESS_FORM"
    })
}
export function clearManualProcessForm()
{
    return({
        type:"CLEAR_MANUAL_PROCESS_FORM"
    })
}
export function clearFormWhenChangeRadiobutton()
{
    return({
        type:"CLEAR_FORM_WHEN_CHANGE_RADIO_BUTTON"
    })
}
export function clearRadioButtonStatus()
{
    return({
        type:"CLEAR_RADIO_BUTTON_STATUS"
    })
}
export function clearRadioButtonType()
{
    return({
        type:"CLEAR_RADIO_BUTTON_TYPE"
    })
}