import {Action} from 'redux';

const initialState = 
{
    Properties:{
        ticketId:null,
        deliveryId:null,
        filterType:null,
    },
    DeliveryPriorityForm:{
        waybillForm:null,
        waybillCode:null,
        barcode:null,
        customerName:null,
        courierName:null,
        courierCode:null,
        address:null,
        ticketStatus:null,
        documentStatus:null,
        createdTime:null,
    },
    ListDeliveryPriority:[],
}

export interface IAuthAction extends Action<string>
{

}

export interface ISetTicketId extends IAuthAction
{
    ticketId : any
}
export interface ISetDeliveryId extends IAuthAction
{
    deliveryId : any
}
export interface IChangeFilterType extends IAuthAction
{
    filterType : any
}
export interface IChangeWaybillForm extends IAuthAction
{
    waybillForm : any
}
export interface ISetWaybillCode extends IAuthAction
{
    waybillCode : any
}
export interface ISetBarcode extends IAuthAction
{
    barcode : any
}
export interface ISetCustomerName extends IAuthAction
{
    customerName : any
}
export interface ISetCourierName extends IAuthAction
{
    courierName : any
}
export interface ISetCourierCode extends IAuthAction
{
    courierCode : any
}
export interface ISetAddress extends IAuthAction
{
    address : any
}
export interface ISetTicketStatus extends IAuthAction
{
    ticketStatus : any
}
export interface ISetDocumentStatus extends IAuthAction
{
    documentStatus : any
}
export interface ISetCreatedTime extends IAuthAction
{
    createdTime : any
}
export interface ISetListDeliveryPriority extends IAuthAction
{
    ListDeliveryPriority : any
}
export default function AuthReducerDetailId(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "SET_TICKET_ID_DP":
            const setTicketId = action as ISetTicketId
            newState.Properties.ticketId = setTicketId.ticketId
            
        return {...newState}
        
        case "SET_DELIVERY_ID_DP":
            const setDeliveryId = action as ISetDeliveryId
            newState.Properties.deliveryId = setDeliveryId.deliveryId
            
        return {...newState}
        
        case "CHANGE_FILTER_DP":
            const setFilterType = action as IChangeFilterType
            newState.Properties.filterType = setFilterType.filterType
            
        return {...newState}
        
        case "CHANGE_WAYBILL_FORM_DP":
            const changeWaybillForm = action as IChangeWaybillForm
            newState.DeliveryPriorityForm.waybillForm = changeWaybillForm.waybillForm
            
        return {...newState}
        
        case "SET_WAYBILL_CODE_DP":
            const setWaybillCode = action as ISetWaybillCode
            newState.DeliveryPriorityForm.waybillCode = setWaybillCode.waybillCode
            
        return {...newState}
        
        case "SET_BARCODE_DP":
            const setBarcode = action as ISetBarcode
            newState.DeliveryPriorityForm.barcode = setBarcode.barcode
            
        return {...newState}
        
        case "SET_CUSTOMER_NAME_DP":
            const setCustomerName = action as ISetCustomerName
            newState.DeliveryPriorityForm.customerName = setCustomerName.customerName
            
        return {...newState}
        
        case "SET_COURIER_NAME_DP":
            const setCourierName = action as ISetCourierName
            newState.DeliveryPriorityForm.courierName = setCourierName.courierName
            
        return {...newState}
        
        case "SET_COURIER_CODE_DP":
            const setCourierCode = action as ISetCourierCode
            newState.DeliveryPriorityForm.courierCode = setCourierCode.courierCode
            
        return {...newState}
        
        case "SET_ADDRESS_DP":
            const setAddress = action as ISetAddress
            newState.DeliveryPriorityForm.address = setAddress.address
            
        return {...newState}
        
        case "SET_TICKET_STATUS_DP":
            const setTicketStatus = action as ISetTicketStatus
            newState.DeliveryPriorityForm.ticketStatus = setTicketStatus.ticketStatus
            
        return {...newState}
        
        case "SET_DOCUMENT_STATUS_DP":
            const setDocumentStatus = action as ISetDocumentStatus
            newState.DeliveryPriorityForm.documentStatus = setDocumentStatus.documentStatus
            
        return {...newState}
        
        case "SET_CREATED_TIME_DP":
            const setCreatedTime = action as ISetCreatedTime
            newState.DeliveryPriorityForm.createdTime = setCreatedTime.createdTime
            
        return {...newState}
        
        case "SET_LIST_DELIVERY_PRIORITY_DP":
            const setListDeliveryPriority = action as ISetListDeliveryPriority
            newState.ListDeliveryPriority = setListDeliveryPriority.ListDeliveryPriority
            
        return {...newState}

        case "CLEAR_DATA_FORM_DP":

            newState.Properties.ticketId = null
            newState.Properties.deliveryId = null
            newState.Properties.filterType = null
            newState.DeliveryPriorityForm.waybillForm = null
            newState.DeliveryPriorityForm.waybillCode = null
            newState.DeliveryPriorityForm.barcode = null
            newState.DeliveryPriorityForm.customerName = null
            newState.DeliveryPriorityForm.courierName = null
            newState.DeliveryPriorityForm.courierCode = null
            newState.DeliveryPriorityForm.address = null
            newState.DeliveryPriorityForm.ticketStatus = null
            newState.DeliveryPriorityForm.documentStatus = null
            newState.DeliveryPriorityForm.createdTime = null
            
        return {...newState}
        
        case "CLEAR_DATA_TABLE_DP":

            newState.ListDeliveryPriority = []
            
        return {...newState}
        
        case "CLEAR_WAYBILL_FORM_DP":

            newState.DeliveryPriorityForm.waybillForm = null
            
        return {...newState}
        
    }
    return state
}