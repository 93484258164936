import { Action } from 'redux';

const initialState = {
  PropertiesCourier: {
    idCourier: null,
    LoadingCourier: false,
    TypeLoadingCourier: null,
  },
  FormCourier: {
    CourierName: null,
    CourierLastName: null,
    CourierEmail: null,
    CourierPhoneNumber: null,
    CourierDob: null,
    CourierPhoto: null,
    CourierCode: null,
    CourierAddress: null,
    CourierStatus: 1,
    AreaCodeId: null,
    BranchCourier: null,
  },
};

export interface IAuthAction extends Action<string> {}

export interface IChangeIdCourier extends IAuthAction {
  idCourier: any;
}

export interface ISetTypeLoadingCourier extends IAuthAction {
  TypeLoadingCourier: any;
}

export interface IChangeCourierName extends IAuthAction {
  CourierName: any;
}

export interface IChangeCourierLastName extends IAuthAction {
  CourierLastName: any;
}

export interface IChangeCourierEmail extends IAuthAction {
  CourierEmail: any;
}

export interface IChangeCourierPhoneNumber extends IAuthAction {
  CourierPhoneNumber: any;
}

export interface IChangeCourierDob extends IAuthAction {
  CourierDob: any;
}

export interface IChangeCourierPhoto extends IAuthAction {
  CourierPhoto: any;
}

export interface IChangeCourierCode extends IAuthAction {
  CourierCode: any;
}
export interface IChangeCourierAddress extends IAuthAction {
  CourierAddress: any;
}
export interface IChangeCourierStatus extends IAuthAction {
  CourierStatus: any;
}

export interface IChangeAreaCodeId extends IAuthAction {
  AreaCodeId: any;
}

export interface IChangeBranchCourier extends IAuthAction {
  BranchCourier: any;
}

export default function AuthReducerDetailCourier(
  state = initialState,
  action: IAuthAction
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_COURIER_DETAIL':
      const changeDetailCourierId = action as IChangeIdCourier;
      newState.PropertiesCourier.idCourier = changeDetailCourierId.idCourier;

      return { ...newState };

    case 'SET_LOADING_COURIER':
      newState.PropertiesCourier.LoadingCourier =
        !newState.PropertiesCourier.LoadingCourier;

      return { ...newState };
    case 'SET_TYPE_LOADING_COURIER':
      const setTypeLoadingCourier = action as ISetTypeLoadingCourier;
      newState.PropertiesCourier.TypeLoadingCourier =
        setTypeLoadingCourier.TypeLoadingCourier;

      return { ...newState };

    case 'CHANGE_COURIER_NAME_FORM':
      const changeCourierName = action as IChangeCourierName;
      newState.FormCourier.CourierName = changeCourierName.CourierName;

      return { ...newState };

    case 'CHANGE_COURIER_LAST_NAME_FORM':
      const changeCourierLastName = action as IChangeCourierLastName;
      newState.FormCourier.CourierLastName =
        changeCourierLastName.CourierLastName;

      return { ...newState };

    case 'CHANGE_COURIER_EMAIL_FORM':
      const changeCourierEmail = action as IChangeCourierEmail;
      newState.FormCourier.CourierEmail = changeCourierEmail.CourierEmail;

      return { ...newState };

    case 'CHANGE_COURIER_PHONE_NUMBER_FORM':
      const changeCourierPhoneNumber = action as IChangeCourierPhoneNumber;
      newState.FormCourier.CourierPhoneNumber =
        changeCourierPhoneNumber.CourierPhoneNumber;

      return { ...newState };

    case 'CHANGE_COURIER_DOB_FORM':
      const changeCourierDob = action as IChangeCourierDob;
      newState.FormCourier.CourierDob = changeCourierDob.CourierDob;

      return { ...newState };

    case 'CHANGE_COURIER_PHOTO_FORM':
      const changeCourierPhoto = action as IChangeCourierPhoto;
      newState.FormCourier.CourierPhoto = changeCourierPhoto.CourierPhoto;

      return { ...newState };

    case 'CHANGE_COURIER_CODE_FORM':
      const changeCourierCode = action as IChangeCourierCode;
      newState.FormCourier.CourierCode = changeCourierCode.CourierCode;

      return { ...newState };

    case 'CHANGE_COURIER_ADDRESS_FORM':
      const changeCourierAddress = action as IChangeCourierAddress;
      newState.FormCourier.CourierAddress = changeCourierAddress.CourierAddress;

      return { ...newState };

    case 'CHANGE_COURIER_STATUS_FORM':
      const changeCourierStatus = action as IChangeCourierStatus;
      newState.FormCourier.CourierStatus = changeCourierStatus.CourierStatus;

      return { ...newState };

    case 'CHANGE_AREA_CODE_ID_FORM':
      const changeAreaCodeId = action as IChangeAreaCodeId;
      newState.FormCourier.AreaCodeId = changeAreaCodeId.AreaCodeId;

      return { ...newState };

    case 'CHANGE_BRANCH_COURIER_FORM':
      const changeBranchCourier = action as IChangeBranchCourier;
      newState.FormCourier.BranchCourier = changeBranchCourier.BranchCourier;

      return { ...newState };

    case 'CHANGE_CLEAR_DATA_COURIER_FORM':
      newState.FormCourier.CourierName = null;
      newState.FormCourier.CourierLastName = null;
      newState.FormCourier.CourierEmail = null;
      newState.FormCourier.CourierPhoneNumber = null;
      newState.FormCourier.CourierDob = null;
      newState.FormCourier.CourierPhoto = null;
      newState.FormCourier.CourierCode = null;
      newState.FormCourier.CourierAddress = null;
      newState.FormCourier.CourierStatus = 1;
      newState.FormCourier.AreaCodeId = null;
      newState.FormCourier.BranchCourier = null;
      return { ...newState };

    case 'CLEAR_LOADING_COURIER':
      newState.PropertiesCourier.LoadingCourier = false;
      newState.PropertiesCourier.TypeLoadingCourier = null;
      return { ...newState };
  }
  return state;
}
