import React, { Component } from 'react';
import { Layout, Menu, Row, Col, Avatar, Affix, Dropdown, Icon, Button } from 'antd';
import SpinMaterial from '../../materials/SpinMaterial';
const { Header } = Layout;

interface IProps {
  handleClearAuth: any;
  handleActionProfile: any;
  GetUsername: any;
  GetPhoto: any;
  GetInitials: any;
  isLoading: any;
}

export default class HeaderComponent extends Component<IProps> {
  render() {
    const menu = (
      <Menu>
        <Menu.Item>
          <Button 
            type='link' 
            onClick={this.props.handleActionProfile}
            style={{color:'black'}}
          >
            <Icon type="user" />
            &nbsp; Profil
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button 
            type='link' 
            onClick={this.props.handleClearAuth}
            style={{color:'black'}}
          >
            <Icon type="logout" />
            &nbsp; Keluar
          </Button>
        </Menu.Item>
      </Menu>
    );
    return (
      <SpinMaterial
        spinning={false}
        style={{}}
        size={'large'}
        content={
          <div>
            <Affix offsetTop={1}>
              <Header style={{ background: '#FCFCFC', padding: 0 }}>
                <Row justify="space-around" align="middle">
                  {/* <Col span={12}>
                                <Row justify="space-around" align="middle">
                                    <Col span={12}>
                                        <Row>
                                            <Col span={8}>
                                                <Icon type="message"
                                                    style={{fontSize: '23px', paddingRight:'50%', paddingLeft:'50%'}}
                                                    className="trigger"/>
                                            </Col>
                                            <Col span={8}>
                                                <Icon type="setting"
                                                    style={{fontSize: '23px', paddingRight:'50%', paddingLeft:'50%'}}
                                                    className="trigger"/>
                                            </Col>
                                            <Col span={8}>
                                                <Icon type="bell"
                                                    style={{fontSize: '23px', paddingRight:'50%', paddingLeft:'50%'}}
                                                    className="trigger"/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                    </Col>
                                </Row>
                            </Col> */}
                  <Col>
                    <div
                      className="icon-profil"
                      style={{ float: 'right', paddingRight: '30px' }}
                    >
                      <span style={{ margin: '24px 16px', color: '#11BEFF' }}>
                        <Dropdown overlay={menu} trigger={['click']}>
                          <Button 
                            type='link' 
                            className="ant-dropdown-link" 
                            >
                            {this.props.GetUsername}&nbsp;
                            <Icon type="down" />
                          </Button>
                        </Dropdown>
                      </span>
                      <Avatar
                        src={'data:image/jpeg;base64, ' + this.props.GetPhoto}
                        style={{ color: '#fafafa', backgroundColor: '#11BEFF' }}
                      >
                        <span style={{ fontSize: 10 }}>
                          <b>{this.props.GetInitials}</b>
                        </span>
                      </Avatar>
                    </div>
                  </Col>
                </Row>
              </Header>
            </Affix>
          </div>
        }
      />
    );
  }
}
