export function changeGetDataCourierManifest() {
  return {
    type: 'CHANGE_GET_DATA_COURIER_MANIFEST',
  };
}
export function changeDeliveryDateCourierManifestForm(DeliveryDate: any) {
  return {
    type: 'CHANGE_DELIVERY_DATE_COURIER_MANIFEST_FORM',
    DeliveryDate,
  };
}
export function changeBranchIdCourierManifestForm(BranchId: any) {
  return {
    type: 'CHANGE_BRANCH_ID_COURIER_MANIFEST_FORM',
    BranchId,
  };
}
export function changeBranchNameCourierManifestForm(BranchName: any) {
  return {
    type: 'CHANGE_BRANCH_NAME_COURIER_MANIFEST_FORM',
    BranchName,
  };
}
export function changeTableCourierManifestForm(TableCourierManifest: any) {
  return {
    type: 'CHANGE_TABLE_COURIER_MANIFEST_FORM',
    TableCourierManifest,
  };
}
export function changeListCourierManifestForm(ListCourierManifest: any) {
  return {
    type: 'CHANGE_LIST_COURIER_MANIFEST_FORM',
    ListCourierManifest,
  };
}
export function removeListCourierManifestForm(ListCourierManifest: any) {
  return {
    type: 'REMOVE_LIST_COURIER_MANIFEST_FORM',
    ListCourierManifest,
  };
}
export function changeCountCourierManifestForm(Count: any) {
  return {
    type: 'CHANGE_COUNT_COURIER_MANIFEST_FORM',
    Count,
  };
}
export function downloadCourierManifestForm() {
  return {
    type: 'DOWNLOAD_COURIER_MANIFEST_FORM',
  };
}
export function clearCourierManifestForm() {
  return {
    type: 'CLEAR_COURIER_MANIFEST_FORM',
  };
}
export function clearTableCourierManifestForm() {
  return {
    type: 'CLEAR_TABLE_COURIER_MANIFEST_FORM',
  };
}
export function clearListCourierManifestForm() {
  return {
    type: 'CLEAR_LIST_COURIER_MANIFEST_FORM',
  };
}
