import * as ActionCustomerAccount from '../../../../core/action/ManageAccount/Customer/ActionCustomerAccount';
import * as ActionDetailCustomer from '../../../../core/action/Customer/ActionDetailCustomer';
import * as ActionEditCustomerAccount from '../../../../core/action/ManageAccount/Customer/ActionEditCustomerAccount';
import * as ActionListCustomerProduct from '../../../../core/action/CustomerProduct/ActionListCustomerProduct';
import * as ActionPagination from '../../../../core/action/Pagination/ActionPagination';
import * as ActionToggle from '../../../../core/action/toggle/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import ButtonManageCustomerAccountComponent from '../../../../components/Content/ManageAccount/Customer/ButtonManageCustomerAccountComponent';
import { connect } from 'react-redux';
import history from '../../../../core/utils/History';

interface IProps {
  idCustomer: any;
  idCustomerAccount: any;
  GetAccountStatus: any;
  ListCustomerProduct: any;
  actionEditCustomerAccount: any;
  actionPagination: any;
  actionCustomerAccount: any;
  actionCustomerDetail: any;
  actionListCustomerProduct: any;
  actionToggle: any;
  typeButton: any;
  route: any;
}
class ButtonManageCustomerAccountContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);

    this.handleCustomerDetail = this.handleCustomerDetail.bind(this);
    this.handleCustomerEditAccount = this.handleCustomerEditAccount.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }
  handleCustomerDetail() {
    this.props.actionCustomerDetail.changeCustomerId(this.props.idCustomer);
    this.props.actionPagination.clearAllPagination();
    history.push('/manageCustomerAccount/detail');
  }
  handleCustomerEditAccount() {
    this.props.actionCustomerAccount.changeUserNameAccount(
      this.props.idCustomerAccount
    );
    if(this.props.ListCustomerProduct.length===0){
      this.props.actionListCustomerProduct.getListCustomerProductNoType()
    }
    this.props.actionCustomerAccount.getCustomerAccountDetail();
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('EditCustomerAccount');
  }
  handleResetPassword() {
    this.props.actionCustomerAccount.changeUserNameAccount(
      this.props.idCustomerAccount
    );
    this.props.actionCustomerAccount.getCustomerAccountDetail();
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('ResetPasswordCustomer');
  }
  render() {
    return (
      <ButtonManageCustomerAccountComponent
        handleCustomerDetail={this.handleCustomerDetail}
        handleCustomerEditAccount={this.handleCustomerEditAccount}
        handleResetPassword={this.handleResetPassword}
        typeButton={this.props.typeButton}
        route={this.props.route}
      />
    );
  }
}
const mapStateProps = (state: any) => ({
  GetAccountStatus: state.Customer.CustomerAccount.StatusAccount,
  ListCustomerProduct: state.CustomerProduct.ListCustomerProduct,
  route: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionCustomerDetail: bindActionCreators(ActionDetailCustomer, dispatch),
  actionListCustomerProduct: bindActionCreators(ActionListCustomerProduct, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
  actionCustomerAccount: bindActionCreators(ActionCustomerAccount, dispatch),
  actionEditCustomerAccount: bindActionCreators(
    ActionEditCustomerAccount,
    dispatch
  ),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(ButtonManageCustomerAccountContainer);
