import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { getProfileBranch } from 'services/AccountServices';
import AdminTableComponent from '../../../../components/Content/ManageAccount/AdminBranch/AdminBranchComponent';
import * as toggle from '../../../../core/action/toggle';

const AdminBranchContainer = props => {
  const [page, setPage] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [searchGroup, setSearchGroup] = useState<string>('');
  const [statusGroup, setStatusGroup] = useState<any>(null);

  const { data, isLoading, isFetching, refetch } = useQuery<
    AxiosResponse,
    Error
  >({
    queryKey: [
      'dataAdminList',
      { page, name: search, isActive: statusGroup?.key },
    ],
    queryFn: ({ queryKey }) => getProfileBranch(queryKey),
    refetchOnWindowFocus: false,
  });

  const handlePage = (event: any) => {
    setPage(event !== 0 ? event - 1 : event);
  };

  const handleFilterStatus = value => {
    setPage(0);
    setStatusGroup({ label: value.item.props.children, key: value.key });
    setSearch('');
    setSearchGroup('');
  };

  const handleFilterSearch = value => {
    setSearch(value);
    setPage(0);
  };

  const handleModal = () => {
    props.actionToggle.changeModal();
    props.actionToggle.changeJenisToggle('AddAdmin');
  };

  return (
    <AdminTableComponent
      data={data?.data?.data || []}
      PagePagination={data?.data?.page + 1 || 0}
      TotalPagePagination={data?.data?.totalDatas || 0}
      handlePage={handlePage}
      handleSearchName={handleFilterSearch}
      handleModal={handleModal}
      searchGroup={searchGroup}
      isLoading={isLoading || isFetching}
      handleSearchGroup={value => setSearchGroup(value)}
      statusGroup={statusGroup}
      handleStatusGroup={handleFilterStatus}
      refetch={refetch}
    />
  );
};

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AdminBranchContainer);
