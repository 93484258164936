import React from 'react';
import { Button } from 'antd';
import FormInputTextMaterial from '../../../materials/FormInputTextMaterial';
import ButtonMaterial from '../../../materials/ButtonMaterial';

interface IProps {
  YearSynchronized: any;
  ValidateYearSynchronized: any;
  handlePutYear: any;
  handleValidateYear: any;
  handleDisbleFormSynchronized: any;
  handleAddHolodaySynchronized: any;
}

export default function FormSynchronizedWeeklyComponent(props: IProps) {
  const ValidateButtonSave =
    props.YearSynchronized === null ||
    props.ValidateYearSynchronized === 'error' ||
    props.ValidateYearSynchronized === 'minchar' ||
    props.ValidateYearSynchronized === 'nonumeric'
      ? true
      : false;

  return (
    <div>
      <span>
        <b>Tahun</b>
      </span>
      <FormInputTextMaterial
        validateType={true}
        className={''}
        disabled={false}
        addonBefore={''}
        placeholder={'Masukan tahun'}
        prefix={''}
        value={props.YearSynchronized}
        maxLength={4}
        style={{}}
        size=""
        type={''}
        onPressEnter={''}
        onChange={props.handlePutYear}
        onBlur={props.handlePutYear}
        validatestatus={
          props.ValidateYearSynchronized === 'error' ||
          props.ValidateYearSynchronized === 'minchar'
            ? 'error'
            : ''
        }
        help={
          props.ValidateYearSynchronized === 'error'
            ? 'Tahun tidak boleh kosong!!'
            : '' || props.ValidateYearSynchronized === 'minchar'
            ? 'Tahun tidak sesuai'
            : ''
        }
      />
      <div>
        <span>
          <Button
            type="link"
            onClick={props.handleDisbleFormSynchronized}
            style={{ fontStyle: 'italic' }}
          >
            <u>Kembali ke manual hari libur</u>
          </Button>
        </span>
      </div>
      <div
        style={{ textAlign: 'right', marginLeft: '30px', marginTop: '10px' }}
      >
        <ButtonMaterial
          disabled={ValidateButtonSave}
          handleSubmit={props.handleAddHolodaySynchronized}
          teksButton={'Simpan'}
          size={''}
          shape={'round'}
          className={''}
          type={'primary'}
          style={
            ValidateButtonSave === true
              ? { height: '35px' }
              : {
                  colorFont: '#11BEFF',
                  height: '35px',
                  background: '#11BEFF',
                  borderColor: '#11BEFF',
                }
          }
          icon={''}
        />
      </div>
    </div>
  );
}
