import * as ActionPagination from '../../../core/action/Pagination/ActionPagination';
import * as ActionRestoreData from '../../../core/action/RestoreData/ActionRestoreData';
import * as ActionToggle from '../../../core/action/toggle/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import { Modal } from 'antd';
import TableRestoreDataComponent from '../../../components/Content/RestoreData/TableRestoreDataComponent';
import { connect } from 'react-redux';

const { confirm } = Modal;
interface IProps {
  isLoading: any;
  typeToggle: any;
  visibleModal: any;
  LastAccess: any;
  ValueSearch: any;
  radioButton: any;
  radioButtonW: any;
  SelectAll: any;
  SelectAllW: any;
  CountFile: any;
  CountWaybill: any;
  modal: any;
  actionPagination: any;
  listResultDeleteData: any;
  ListRestoreData: any;
  ListWaybillRestoreData: any;
  PagePagination: any;
  TotalPagePagination: any;
  actionToggle: any;
  actionRestoreData: any;
}
interface IState {
  sRT: any;
  dataSource: any;
  nameSearch: any;
  page: any;
  key: any;
}
class TableRestoreDataContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      sRT: null,
      dataSource: this.props.ListRestoreData,
      nameSearch: '',
      page: 0,
      key: null,
    };
    this.handlePage = this.handlePage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleRestore = this.handleRestore.bind(this);
    this.handleDownloadDeleteResult =
      this.handleDownloadDeleteResult.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handlePage(event: any) {
    this.props.actionPagination.setPagePagination(event);
    this.setState({
      page: event,
    });
  }
  handleCloseModal() {
    this.props.actionToggle.changeModal2();
    this.props.actionToggle.changeJenisToggle2(null);
    this.props.actionRestoreData.clearListResultDeleteData();
  }
  handleDownloadDeleteResult() {
    this.props.actionRestoreData.downloadResultDeleteData();
  }

  handleSearch(event: any) {
    this.props.actionToggle.changeValueSearch(event.target.value.toLowerCase());
  }

  handleCheckbox = valueId => event => {
    if (
      this.props.LastAccess === null ||
      this.props.LastAccess === 'restoreDataCsv'
    ) {
      const newArray = this.props.ListRestoreData.map(item => {
        if (item.id === valueId) {
          return { ...item, selected: event.target.checked };
        } else {
          return item;
        }
      });
      this.props.actionRestoreData.setListRestoreData(newArray);
    } else {
      const newArray = this.props.ListWaybillRestoreData.map(item => {
        if (item.id === valueId) {
          return { ...item, selected: event.target.checked };
        } else {
          return item;
        }
      });
      this.props.actionRestoreData.setListWaybillRestoreData(newArray);
    }
  };

  handleSelectAll(event) {
    if (
      this.props.LastAccess === null ||
      this.props.LastAccess === 'restoreDataCsv'
    ) {
      let array: any;
      this.props.actionRestoreData.setSelectAllRestoreData(
        event.target.checked
      );
      if (event.target.checked) {
        array = this.props.ListRestoreData.map(opt => ({
          ...opt,
          selected: true,
        }));
      } else {
        array = this.props.ListRestoreData.map(opt => ({
          ...opt,
          selected: false,
        }));
      }
      this.props.actionRestoreData.setListRestoreData(array);
    } else {
      let array: any;
      this.props.actionRestoreData.setSelectAllWRestoreData(
        event.target.checked
      );
      if (event.target.checked) {
        array = this.props.ListWaybillRestoreData.map(opt => ({
          ...opt,
          selected: true,
        }));
      } else {
        array = this.props.ListWaybillRestoreData.map(opt => ({
          ...opt,
          selected: false,
        }));
      }
      this.props.actionRestoreData.setListWaybillRestoreData(array);
    }
  }

  handleDelete() {
    if (
      this.props.LastAccess === null ||
      this.props.LastAccess === 'restoreDataCsv'
    ) {
      confirm({
        title: 'Apakah anda yakin ingin menghapus data ini ?',
        okText: 'Ya',
        okType: 'danger',
        cancelText: 'Tidak',
        onOk: () => {
          this.props.actionRestoreData.submitDeleteRestoreData();
        },
        onCancel: () => {},
      });
    } else {
      confirm({
        title: 'Apakah anda yakin ingin menghapus waybill ini ?',
        okText: 'Ya',
        okType: 'danger',
        cancelText: 'Tidak',
        onOk: () => {
          this.props.actionRestoreData.submitDeleteRestoreData();
        },
        onCancel: () => {},
      });
    }
  }

  handleRestore() {
    if (
      this.props.LastAccess === null ||
      this.props.LastAccess === 'restoreDataCsv'
    ) {
      confirm({
        title: 'Apakah anda yakin ingin merestore data ini ?',
        okText: 'Ya',
        okType: 'primary',
        cancelText: 'Tidak',
        onOk: () => {
          this.props.actionRestoreData.submitRestoreRestoreData();
        },
        onCancel: () => {},
      });
    } else {
      confirm({
        title: 'Apakah anda yakin ingin merestore waybill ini ?',
        okText: 'Ya',
        okType: 'primary',
        cancelText: 'Tidak',
        onOk: () => {
          this.props.actionRestoreData.submitRestoreRestoreData();
        },
        onCancel: () => {},
      });
    }
  }

  componentDidUpdate() {
    if (
      this.props.LastAccess === null ||
      this.props.LastAccess === 'restoreDataCsv'
    ) {
      if (this.props.CountFile === null || this.props.CountFile === 0) {
        this.props.actionRestoreData.setSelectAllRestoreData(false);
      } else {
        const data = this.props.ListRestoreData.filter(
          item => item.selected === true
        );
        if (data.length === this.props.CountFile) {
          this.props.actionRestoreData.setSelectAllRestoreData(true);
        } else {
          this.props.actionRestoreData.setSelectAllRestoreData(false);
        }
      }
    } else {
      if (this.props.CountWaybill === null || this.props.CountWaybill === 0) {
        this.props.actionRestoreData.setSelectAllWRestoreData(false);
      } else {
        const data = this.props.ListWaybillRestoreData.filter(
          item => item.selected === true
        );
        if (data.length === this.props.CountWaybill) {
          this.props.actionRestoreData.setSelectAllWRestoreData(true);
        } else {
          this.props.actionRestoreData.setSelectAllWRestoreData(false);
        }
      }
    }
  }

  render() {
    return (
      <div>
        <TableRestoreDataComponent
          LastAccess={this.props.LastAccess}
          valueSearch={this.props.ValueSearch}
          isLoading={this.props.isLoading}
          typeToggle={this.props.typeToggle}
          listResultDeleteData={this.props.listResultDeleteData}
          visibleModal={this.props.visibleModal}
          radioButton={this.props.radioButton}
          radioButtonW={this.props.radioButtonW}
          selectAll={this.props.SelectAll}
          selectAllW={this.props.SelectAllW}
          modal={this.props.modal}
          page={this.state.page}
          ListRestoreData={this.props.ListRestoreData}
          ListWaybillRestoreData={this.props.ListWaybillRestoreData}
          PagePagination={this.props.PagePagination}
          TotalPagePagination={this.props.TotalPagePagination}
          handleCloseModal={this.handleCloseModal}
          handleCheckbox={this.handleCheckbox}
          handleSelectAll={this.handleSelectAll}
          handlePage={this.handlePage}
          handleSearch={this.handleSearch}
          handleDelete={this.handleDelete}
          handleRestore={this.handleRestore}
          handleDownloadDeleteResult={this.handleDownloadDeleteResult}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  typeToggle: state.Toggle.jenisToggle2,
  visibleModal: state.Toggle.modal2,
  LastAccess: state.LastAccess.Fiture,
  modal: state.Toggle.modal,
  isLoading: state.Toggle.isLoading,
  ValueSearch: state.Toggle.ValueSearch,
  CountFile: state.RestoreData.CountFile,
  CountWaybill: state.RestoreData.CountWaybill,
  ListRestoreData: state.RestoreData.ListRestoreData,
  ListWaybillRestoreData: state.RestoreData.ListWaybillRestoreData,
  radioButton: state.RestoreData.FormRestoreData.RadioButton,
  radioButtonW: state.RestoreData.FormRestoreData.RadioButtonW,
  SelectAll: state.RestoreData.FormRestoreData.SelectAll,
  SelectAllW: state.RestoreData.FormRestoreData.SelectAllW,
  listResultDeleteData: state.RestoreData.ListResultDeleteData,
  PagePagination: state.Pagination.PagePagination,
  TotalPagePagination: state.Pagination.TotalPagePagination,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionRestoreData: bindActionCreators(ActionRestoreData, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(TableRestoreDataContainer);
