import React from 'react';
import { Form, Input } from 'antd';
import cx from 'classnames';

type InputNumberFieldProps = {
  error?: any;
  label?: string;
  width?: any;
  maxLength?: number;
  value?: number;
  onChange?: (value: number) => void;
  className?: any;
  styleInput?: any;
  disabled?: boolean;
  isDropdown?: boolean;
  max?: number;
  placeholder?: string;
};

export default function InputNumberField({
  error,
  label,
  width = 'auto',
  maxLength = 0,
  value,
  onChange = () => undefined,
  className,
  styleInput,
  disabled,
  isDropdown = false,
  max,
  placeholder,
}: InputNumberFieldProps) {
  const handleOnChange = (e?: any) => {
    if (
      ((e.target.value.length <= maxLength && maxLength !== 0) ||
        (e.target.value.length > 0 && maxLength === 0)) &&
      e.target.value >= 0
    ) {
      if (max && e.target.value > max) {
        onChange(100);
        return;
      }
      onChange(parseInt(e.target.value) || 0);
    }
  };
  return (
    <div style={{ width: width }}>
      {label && (
        <span>
          <b>{label}</b>
        </span>
      )}
      <Form.Item
        validateStatus={error ? 'error' : ''}
        help={error ? error : ''}
        style={{ margin: 0 }}
        className={cx('input-text', className)}
      >
        <Input
          min={0}
          style={{
            borderRadius: 8,
            width: width,
            ...styleInput,
          }}
          value={value}
          onChange={e => handleOnChange(e)}
          type={isDropdown ? 'number' : 'text'}
          disabled={disabled}
          max={max}
          placeholder={placeholder}
        />
      </Form.Item>
    </div>
  );
}
