import * as ActionAddCustomerProduct from '../../../../core/action/CustomerProduct/ActionAddCustomerProduct';
import * as ActionDetailCustomerProduct from '../../../../core/action/CustomerProduct/ActionDetailCustomerProduct';
import * as ActionEditCustomerProduct from '../../../../core/action/CustomerProduct/ActionEditCustomerProduct';
import * as ActionFieldCustomerProduct from '../../../../core/action/CustomerProduct/ActionFieldCustomerProduct';
import * as ActionToggle from '../../../../core/action/toggle/index';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import FormCustomerProductComponent from '../../../../components/Content/Customer/Product/FormCustomerProductComponent';
import { Modal } from 'antd';
import { connect } from 'react-redux';

const regexNumber = /^\d*(\.\d+)?$/;
const RegexHuruf = /^[a-zA-Z ]*$/;
interface IProps {
  getGroupName: any;
  getListGroupName: any;
  typeToggle: any;
  visible: any;
  isLoading: any;
  ProductName: any;
  ProductCode: any;
  ProductType: any;
  ProductTypeOther: any;
  ProductLogo: any;
  ProductBonus: any;
  District1: any;
  District2: any;
  District3: any;
  District4: any;
  District5: any;
  District6: any;
  District7: any;
  District8: any;
  District9: any;
  District10: any;
  District11: any;
  District12: any;
  ListProductBonus: any;
  actionFieldCustomerProduct: any;
  actionDetailCustomerProduct: any;
  actionAddCustomerProduct: any;
  actionEditCustomerProduct: any;
  actionToggle: any;
  isDisabled: boolean;
  setIsActiveType: (value: string) => void;
}
interface IState {
  ValidateProductName: any;
  ValidateProductCode: any;
  ValidateProductType: any;
  ValidateProductTypeOther: any;
  ValidateProductDistrict1: any;
  ValidateProductDistrict2: any;
  ValidateProductDistrict3: any;
  ValidateProductDistrict4: any;
  ValidateProductDistrict5: any;
  ValidateProductDistrict6: any;
  ValidateProductDistrict7: any;
  ValidateProductDistrict8: any;
  ValidateProductDistrict9: any;
  ValidateProductDistrict10: any;
  ValidateProductDistrict11: any;
  ValidateProductDistrict12: any;
  file: any;
  fileName: any;
  base64URL: any;
}

class FormCustomerProductContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ValidateProductName: null,
      ValidateProductCode: null,
      ValidateProductType: null,
      ValidateProductTypeOther: null,
      ValidateProductDistrict1: null,
      ValidateProductDistrict2: null,
      ValidateProductDistrict3: null,
      ValidateProductDistrict4: null,
      ValidateProductDistrict5: null,
      ValidateProductDistrict6: null,
      ValidateProductDistrict7: null,
      ValidateProductDistrict8: null,
      ValidateProductDistrict9: null,
      ValidateProductDistrict10: null,
      ValidateProductDistrict11: null,
      ValidateProductDistrict12: null,
      file: null,
      fileName: '',
      base64URL: null,
    };
    this.handleGetGroupName = this.handleGetGroupName.bind(this);
    this.handleProductName = this.handleProductName.bind(this);
    this.handleIsDisabled = this.handleIsDisabled.bind(this);
    this.handleProductCode = this.handleProductCode.bind(this);
    this.handleProductType = this.handleProductType.bind(this);
    this.handleBlurProductType = this.handleBlurProductType.bind(this);
    this.handleProductTypeOther = this.handleProductTypeOther.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleEditCustomerProduct = this.handleEditCustomerProduct.bind(this);
    this.handleAddCustomerProduct = this.handleAddCustomerProduct.bind(this);
    this.handleGetBase64 = this.handleGetBase64.bind(this);
    this.handleConvert = this.handleConvert.bind(this);
    this.handleChangeAddPriceBonus = this.handleChangeAddPriceBonus.bind(this);
    this.handleAddPriceBonus = this.handleAddPriceBonus.bind(this);
    this.handleRemovePriceBonus = this.handleRemovePriceBonus.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleDistrict1 = this.handleDistrict1.bind(this);
    this.handleDistrict2 = this.handleDistrict2.bind(this);
    this.handleDistrict3 = this.handleDistrict3.bind(this);
    this.handleDistrict4 = this.handleDistrict4.bind(this);
    this.handleDistrict5 = this.handleDistrict5.bind(this);
    this.handleDistrict6 = this.handleDistrict6.bind(this);
    this.handleDistrict7 = this.handleDistrict7.bind(this);
    this.handleDistrict8 = this.handleDistrict8.bind(this);
    this.handleDistrict9 = this.handleDistrict9.bind(this);
    this.handleDistrict10 = this.handleDistrict10.bind(this);
    this.handleDistrict11 = this.handleDistrict11.bind(this);
    this.handleDistrict12 = this.handleDistrict12.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.handlePutGroup = this.handlePutGroup.bind(this);
  }

  componentDidMount() {
    if (this.props.ProductLogo === null) {
      this.setState({
        file: null,
        fileName: '',
        base64URL: null,
      });
    }
  }

  handleGetGroupName() {
    this.props.actionFieldCustomerProduct.getGroupNameList();
  }
  handlePutGroup(event: any) {
    this.props.actionFieldCustomerProduct.setGroupNameProduct(event);
  }
  handleProductName(event: any) {
    const productName = event.target.value
      .split(' ')
      .map((s: any) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
    this.props.actionFieldCustomerProduct.setCustomerProductName(productName);
    const RegexMinChar = /^.{6,}$/;
    if (event.target.value === '' || null) {
      this.setState({ ValidateProductName: 'error' });
    } else if (!RegexMinChar.test(event.target.value)) {
      this.setState({ ValidateProductName: 'minchar' });
    } else if (!RegexHuruf.test(event.target.value)) {
      this.setState({ ValidateProductName: 'huruf' });
    } else {
      this.setState({ ValidateProductName: '' });
    }
  }

  handleIsDisabled(value) {
    this.props.actionFieldCustomerProduct.setCustomerIsDisabled(value);
  }

  handleProductCode(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductCode(
      event.target.value.toUpperCase()
    );

    if (event.target.value.toUpperCase() === '') {
      this.setState({ ValidateProductCode: 'error' });
    } else if (!RegexHuruf.test(event.target.value.toUpperCase())) {
      this.setState({ ValidateProductCode: 'huruf' });
    } else if (event.target.value.length < 2) {
      this.setState({ ValidateProductCode: 'minchar' });
    } else {
      this.setState({ ValidateProductCode: '' });
    }
  }

  handleProductType(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductType(event);
  }

  handleBlurProductType() {
    if (this.props.ProductType === null) {
      this.setState({ ValidateProductType: 'error' });
    } else {
      this.setState({ ValidateProductType: null });
    }
  }

  handleProductTypeOther(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductTypeOther(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateProductTypeOther: 'error',
      });
    } else {
      this.setState({
        ValidateProductTypeOther: null,
      });
    }
  }

  handleConvert(e: any) {
    this.setState({
      fileName: e.target.value,
    });
    let { file } = this.state;
    file = e.target.files[0];
    this.handleGetBase64(file)
      .then((result: any) => {
        file['base64'] = result.toString().split(',')[1];
        this.props.actionFieldCustomerProduct.setCustomerProductLogo(
          result.split(',')[1]
        );
        this.setState({
          base64URL: result.split(',')[1],
          file,
        });
      })
      .catch(error => {
        console.log(error);
      });

    this.setState({
      file: e.target.files[0],
    });
  }

  handleGetBase64 = (file: any) => {
    return new Promise(resolve => {
      let baseURL;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  handleChangeAddPriceBonus(e: any, index: any) {
    const valueAddPriceBonus = e.target.value;
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(valueAddPriceBonus)) {
      if (valueAddPriceBonus.startsWith(' ') === true) {
        this.props.ListProductBonus[index] = valueAddPriceBonus.trim();
      } else {
        this.props.ListProductBonus[index] = valueAddPriceBonus;
      }

      this.props.actionFieldCustomerProduct.setListCustomerProductBonus(
        this.props.ListProductBonus
      );
      this.props.actionFieldCustomerProduct.setCustomerProductBonus(
        this.props.ListProductBonus.join(';')
      );
    }
  }

  handleAddPriceBonus() {
    this.props.actionFieldCustomerProduct.setListCustomerProductBonus([
      ...this.props.ListProductBonus,
      '',
    ]);
  }

  handleRemovePriceBonus(event: any) {
    this.props.actionFieldCustomerProduct.setListCustomerProductBonus(
      this.props.ListProductBonus.splice(event, 1)
    );
    if (event > 0) {
      this.props.actionFieldCustomerProduct.setListCustomerProductBonus(
        this.props.ListProductBonus
      );
      this.props.actionFieldCustomerProduct.setCustomerProductBonus(
        this.props.ListProductBonus.join(';')
      );
    } else if (event === 0 && this.props.ListProductBonus.length > 1) {
      this.props.actionFieldCustomerProduct.setListCustomerProductBonus(
        this.props.ListProductBonus
      );
      this.props.actionFieldCustomerProduct.setCustomerProductBonus(
        this.props.ListProductBonus.join(';')
      );
    } else if (event === 0 && this.props.ListProductBonus.length === 0) {
      this.props.actionFieldCustomerProduct.changeClearListCustomerProductBonus();
      this.props.actionFieldCustomerProduct.changeClearCustomerProductBonus();
    }
  }

  handleEditCustomerProduct() {
    if (this.props.ListProductBonus.indexOf('') >= 0) {
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>Input Harga bonus wajib diisi</p>
        ),
        content: (
          <p style={{ color: '#C0C0C0' }}>Silakan isi atau hapus kolom input</p>
        ),
      });
    } else if (this.props.ListProductBonus.indexOf('') === -1) {
      this.props.setIsActiveType('Active');
      this.props.actionEditCustomerProduct.submitEditCustomerProduct();
    }
  }

  handleAddCustomerProduct() {
    if (this.props.ListProductBonus.indexOf('') >= 0) {
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>Input Harga bonus wajib diisi</p>
        ),
        content: (
          <p style={{ color: '#C0C0C0' }}>Silakan isi atau hapus kolom input</p>
        ),
      });
    } else if (this.props.ListProductBonus.indexOf('') === -1) {
      this.props.setIsActiveType('Active');
      this.props.actionAddCustomerProduct.submitAddCustomerProduct();
    }
  }

  handleDistrict1(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict1(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateProductDistrict1: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateProductDistrict1: 'number',
      });
    } else if (event.target.value > 50) {
      this.setState({
        ValidateProductDistrict1: 'max',
      });
    } else {
      this.setState({
        ValidateProductDistrict1: '',
      });
    }
  }

  handleDistrict2(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict2(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateProductDistrict2: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateProductDistrict2: 'number',
      });
    } else if (event.target.value > 50) {
      this.setState({
        ValidateProductDistrict2: 'max',
      });
    } else {
      this.setState({
        ValidateProductDistrict2: '',
      });
    }
  }

  handleDistrict3(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict3(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateProductDistrict3: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateProductDistrict3: 'number',
      });
    } else if (event.target.value > 50) {
      this.setState({
        ValidateProductDistrict3: 'max',
      });
    } else {
      this.setState({
        ValidateProductDistrict3: '',
      });
    }
  }

  handleDistrict4(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict4(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateProductDistrict4: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateProductDistrict4: 'number',
      });
    } else if (event.target.value > 50) {
      this.setState({
        ValidateProductDistrict4: 'max',
      });
    } else {
      this.setState({
        ValidateProductDistrict4: '',
      });
    }
  }

  handleDistrict5(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict5(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateProductDistrict5: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateProductDistrict5: 'number',
      });
    } else if (event.target.value > 50) {
      this.setState({
        ValidateProductDistrict5: 'max',
      });
    } else {
      this.setState({
        ValidateProductDistrict5: '',
      });
    }
  }

  handleDistrict6(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict6(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateProductDistrict6: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateProductDistrict6: 'number',
      });
    } else if (event.target.value > 50) {
      this.setState({
        ValidateProductDistrict6: 'max',
      });
    } else {
      this.setState({
        ValidateProductDistrict6: '',
      });
    }
  }

  handleDistrict7(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict7(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateProductDistrict7: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateProductDistrict7: 'number',
      });
    } else if (event.target.value > 50) {
      this.setState({
        ValidateProductDistrict7: 'max',
      });
    } else {
      this.setState({
        ValidateProductDistrict7: '',
      });
    }
  }

  handleDistrict8(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict8(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateProductDistrict8: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateProductDistrict8: 'number',
      });
    } else if (event.target.value > 50) {
      this.setState({
        ValidateProductDistrict8: 'max',
      });
    } else {
      this.setState({
        ValidateProductDistrict8: '',
      });
    }
  }

  handleDistrict9(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict9(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateProductDistrict9: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateProductDistrict9: 'number',
      });
    } else if (event.target.value > 50) {
      this.setState({
        ValidateProductDistrict9: 'max',
      });
    } else {
      this.setState({
        ValidateProductDistrict9: '',
      });
    }
  }

  handleDistrict10(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict10(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateProductDistrict10: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateProductDistrict10: 'number',
      });
    } else if (event.target.value > 50) {
      this.setState({
        ValidateProductDistrict10: 'max',
      });
    } else {
      this.setState({
        ValidateProductDistrict10: '',
      });
    }
  }

  handleDistrict11(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict11(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateProductDistrict11: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateProductDistrict11: 'number',
      });
    } else if (event.target.value > 50) {
      this.setState({
        ValidateProductDistrict11: 'max',
      });
    } else {
      this.setState({
        ValidateProductDistrict11: '',
      });
    }
  }

  handleDistrict12(event: any) {
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict12(
      event.target.value
    );
    if (event.target.value === '') {
      this.setState({
        ValidateProductDistrict12: 'error',
      });
    } else if (!regexNumber.test(event.target.value)) {
      this.setState({
        ValidateProductDistrict12: 'number',
      });
    } else if (event.target.value > 50) {
      this.setState({
        ValidateProductDistrict12: 'max',
      });
    } else {
      this.setState({
        ValidateProductDistrict12: '',
      });
    }
  }

  handleReset() {
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict1(3);
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict2(5);
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict3(8);
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict4(3);
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict5(3);
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict6(3);
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict7(3);
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict8(3);
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict9(3);
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict10(3);
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict11(3);
    this.props.actionFieldCustomerProduct.setCustomerProductDistrict12(3);
    this.setState({
      ValidateProductDistrict1: null,
      ValidateProductDistrict2: null,
      ValidateProductDistrict3: null,
      ValidateProductDistrict4: null,
      ValidateProductDistrict5: null,
      ValidateProductDistrict6: null,
      ValidateProductDistrict7: null,
      ValidateProductDistrict8: null,
      ValidateProductDistrict9: null,
      ValidateProductDistrict10: null,
      ValidateProductDistrict11: null,
      ValidateProductDistrict12: null,
    });
  }

  handleCloseModal() {
    this.props.actionFieldCustomerProduct.changeClearFieldCustomerProduct();
    this.props.actionToggle.changeModal();
    this.props.actionToggle.clearJenisToggle();
    this.setState({
      ValidateProductName: null,
      ValidateProductCode: null,
      ValidateProductType: null,
      ValidateProductTypeOther: null,
      ValidateProductDistrict1: null,
      ValidateProductDistrict2: null,
      ValidateProductDistrict3: null,
      ValidateProductDistrict4: null,
      ValidateProductDistrict5: null,
      ValidateProductDistrict6: null,
      ValidateProductDistrict7: null,
      ValidateProductDistrict8: null,
      ValidateProductDistrict9: null,
      ValidateProductDistrict10: null,
      ValidateProductDistrict11: null,
      ValidateProductDistrict12: null,
      file: null,
      fileName: '',
      base64URL: null,
    });
  }

  handleModal() {
    this.props.actionToggle.changeJenisToggle2('AddNewReportGrup');
    this.props.actionToggle.changeModal2();
    this.props.actionFieldCustomerProduct.getGroupNameList();
  }

  render() {
    return (
      <div>
        <FormCustomerProductComponent
          typeToggle={this.props.typeToggle}
          visible={this.props.visible}
          isLoading={this.props.isLoading}
          isDisabled={this.props.isDisabled}
          ProductName={this.props.ProductName}
          ProductCode={this.props.ProductCode}
          ProductType={this.props.ProductType}
          ProductTypeOther={this.props.ProductTypeOther}
          ProductLogo={this.props.ProductLogo}
          ProductBonus={this.props.ProductBonus}
          District1={this.props.District1}
          District2={this.props.District2}
          District3={this.props.District3}
          District4={this.props.District4}
          District5={this.props.District5}
          District6={this.props.District6}
          District7={this.props.District7}
          District8={this.props.District8}
          District9={this.props.District9}
          District10={this.props.District10}
          District11={this.props.District11}
          District12={this.props.District12}
          File={this.state.file}
          FileNameLogo={this.state.fileName}
          ListProductBonus={this.props.ListProductBonus}
          ValidateProductName={
            this.state.ValidateProductName === 'minchar'
              ? 'error'
              : '' || this.state.ValidateProductName === 'huruf'
              ? 'error'
              : '' || this.state.ValidateProductName === 'error'
              ? 'error'
              : ''
          }
          ValidateProductCode={
            this.state.ValidateProductCode === 'minchar'
              ? 'error'
              : '' || this.state.ValidateProductCode === 'huruf'
              ? 'error'
              : '' || this.state.ValidateProductCode === 'error'
              ? 'error'
              : ''
          }
          ValidateProductType={
            this.state.ValidateProductType === 'error' ? 'error' : ''
          }
          ValidateProductTypeOther={this.state.ValidateProductTypeOther}
          ValidateDistrict1={
            this.state.ValidateProductDistrict1 === 'error'
              ? 'error'
              : '' || this.state.ValidateProductDistrict1 === 'number'
              ? 'error'
              : '' || this.state.ValidateProductDistrict1 === 'max'
              ? 'error'
              : ''
          }
          ValidateDistrict2={
            this.state.ValidateProductDistrict2 === 'error'
              ? 'error'
              : '' || this.state.ValidateProductDistrict2 === 'number'
              ? 'error'
              : '' || this.state.ValidateProductDistrict2 === 'max'
              ? 'error'
              : ''
          }
          ValidateDistrict3={
            this.state.ValidateProductDistrict3 === 'error'
              ? 'error'
              : '' || this.state.ValidateProductDistrict3 === 'number'
              ? 'error'
              : '' || this.state.ValidateProductDistrict3 === 'max'
              ? 'error'
              : ''
          }
          ValidateDistrict4={
            this.state.ValidateProductDistrict4 === 'error'
              ? 'error'
              : '' || this.state.ValidateProductDistrict4 === 'number'
              ? 'error'
              : '' || this.state.ValidateProductDistrict4 === 'max'
              ? 'error'
              : ''
          }
          ValidateDistrict5={
            this.state.ValidateProductDistrict5 === 'error'
              ? 'error'
              : '' || this.state.ValidateProductDistrict5 === 'number'
              ? 'error'
              : '' || this.state.ValidateProductDistrict5 === 'max'
              ? 'error'
              : ''
          }
          ValidateDistrict6={
            this.state.ValidateProductDistrict6 === 'error'
              ? 'error'
              : '' || this.state.ValidateProductDistrict6 === 'number'
              ? 'error'
              : '' || this.state.ValidateProductDistrict6 === 'max'
              ? 'error'
              : ''
          }
          ValidateDistrict7={
            this.state.ValidateProductDistrict7 === 'error'
              ? 'error'
              : '' || this.state.ValidateProductDistrict7 === 'number'
              ? 'error'
              : '' || this.state.ValidateProductDistrict7 === 'max'
              ? 'error'
              : ''
          }
          ValidateDistrict8={
            this.state.ValidateProductDistrict8 === 'error'
              ? 'error'
              : '' || this.state.ValidateProductDistrict8 === 'number'
              ? 'error'
              : '' || this.state.ValidateProductDistrict8 === 'max'
              ? 'error'
              : ''
          }
          ValidateDistrict9={
            this.state.ValidateProductDistrict9 === 'error'
              ? 'error'
              : '' || this.state.ValidateProductDistrict9 === 'number'
              ? 'error'
              : '' || this.state.ValidateProductDistrict9 === 'max'
              ? 'error'
              : ''
          }
          ValidateDistrict10={
            this.state.ValidateProductDistrict10 === 'error'
              ? 'error'
              : '' || this.state.ValidateProductDistrict10 === 'number'
              ? 'error'
              : '' || this.state.ValidateProductDistrict10 === 'max'
              ? 'error'
              : ''
          }
          ValidateDistrict11={
            this.state.ValidateProductDistrict11 === 'error'
              ? 'error'
              : '' || this.state.ValidateProductDistrict11 === 'number'
              ? 'error'
              : '' || this.state.ValidateProductDistrict11 === 'max'
              ? 'error'
              : ''
          }
          ValidateDistrict12={
            this.state.ValidateProductDistrict12 === 'error'
              ? 'error'
              : '' || this.state.ValidateProductDistrict12 === 'number'
              ? 'error'
              : '' || this.state.ValidateProductDistrict12 === 'max'
              ? 'error'
              : ''
          }
          HelpValidateProductName={
            this.state.ValidateProductName === 'minchar'
              ? 'Nama produk harus lebih dari 5 karakter'
              : '' || this.state.ValidateProductName === 'huruf'
              ? 'Nama produk menggunakan huruf!'
              : '' || this.state.ValidateProductName === 'error'
              ? 'Nama produk wajib diisi'
              : ''
          }
          HelpValidateProductCode={
            this.state.ValidateProductCode === 'minchar'
              ? 'Kode produk harus 2 karakter'
              : '' || this.state.ValidateProductCode === 'huruf'
              ? 'Kode produk menggunakan huruf!'
              : '' || this.state.ValidateProductCode === 'error'
              ? 'Kode produk wajib diisi'
              : ''
          }
          HelpValidateProductType={
            this.state.ValidateProductType === 'error'
              ? 'Jenis produk wajib diisi'
              : ''
          }
          HelpValidateDistrict1={
            this.state.ValidateProductDistrict1 === 'error'
              ? 'Jabodetabek Wajib diisi'
              : '' || this.state.ValidateProductDistrict1 === 'number'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateProductDistrict1 === 'max'
              ? 'Tidak valid'
              : ''
          }
          HelpValidateDistrict2={
            this.state.ValidateProductDistrict2 === 'error'
              ? 'Jawa Wajib diisi'
              : '' || this.state.ValidateProductDistrict2 === 'number'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateProductDistrict2 === 'max'
              ? 'Tidak valid'
              : ''
          }
          HelpValidateDistrict3={
            this.state.ValidateProductDistrict3 === 'error'
              ? 'Luar Jawa Wajib diisi'
              : '' || this.state.ValidateProductDistrict3 === 'number'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateProductDistrict3 === 'max'
              ? 'Tidak valid'
              : ''
          }
          HelpValidateDistrict4={
            this.state.ValidateProductDistrict4 === 'error'
              ? 'Indonesia Timur Wajib diisi'
              : '' || this.state.ValidateProductDistrict4 === 'number'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateProductDistrict4 === 'max'
              ? 'Tidak valid'
              : ''
          }
          HelpValidateDistrict5={
            this.state.ValidateProductDistrict5 === 'error'
              ? 'Jakarta Wajib diisi'
              : '' || this.state.ValidateProductDistrict5 === 'number'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateProductDistrict5 === 'max'
              ? 'Tidak valid'
              : ''
          }
          HelpValidateDistrict6={
            this.state.ValidateProductDistrict6 === 'error'
              ? 'Bodetabek Wajib diisi'
              : '' || this.state.ValidateProductDistrict6 === 'number'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateProductDistrict6 === 'max'
              ? 'Tidak valid'
              : ''
          }
          HelpValidateDistrict7={
            this.state.ValidateProductDistrict7 === 'error'
              ? 'Kalimantan dan Sumatra Wajib diisi'
              : '' || this.state.ValidateProductDistrict7 === 'number'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateProductDistrict7 === 'max'
              ? 'Tidak valid'
              : ''
          }
          HelpValidateDistrict8={
            this.state.ValidateProductDistrict8 === 'error'
              ? 'Sulawesi Maluku dan Bali Wajib diisi'
              : '' || this.state.ValidateProductDistrict8 === 'number'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateProductDistrict8 === 'max'
              ? 'Tidak valid'
              : ''
          }
          HelpValidateDistrict9={
            this.state.ValidateProductDistrict9 === 'error'
              ? 'Papua NTT dan NTB Wajib diisi'
              : '' || this.state.ValidateProductDistrict9 === 'number'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateProductDistrict9 === 'max'
              ? 'Tidak valid'
              : ''
          }
          HelpValidateDistrict10={
            this.state.ValidateProductDistrict10 === 'error'
              ? 'Sumkal Wajib diisi'
              : '' || this.state.ValidateProductDistrict10 === 'number'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateProductDistrict10 === 'max'
              ? 'Tidak valid'
              : ''
          }
          HelpValidateDistrict11={
            this.state.ValidateProductDistrict11 === 'error'
              ? 'Sumapa Wajib diisi'
              : '' || this.state.ValidateProductDistrict11 === 'number'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateProductDistrict11 === 'max'
              ? 'Tidak valid'
              : ''
          }
          HelpValidateDistrict12={
            this.state.ValidateProductDistrict12 === 'error'
              ? 'Balinusa Wajib diisi'
              : '' || this.state.ValidateProductDistrict12 === 'number'
              ? 'Tidak boleh selain angka'
              : '' || this.state.ValidateProductDistrict12 === 'max'
              ? 'Tidak valid'
              : ''
          }
          handleProductName={this.handleProductName}
          handleIsDisabled={this.handleIsDisabled}
          handleProductCode={this.handleProductCode}
          handleProductType={this.handleProductType}
          handleBlurProductType={this.handleBlurProductType}
          handleProductTypeOther={this.handleProductTypeOther}
          handleDistrict1={this.handleDistrict1}
          handleDistrict2={this.handleDistrict2}
          handleDistrict3={this.handleDistrict3}
          handleDistrict4={this.handleDistrict4}
          handleDistrict5={this.handleDistrict5}
          handleDistrict6={this.handleDistrict6}
          handleDistrict7={this.handleDistrict7}
          handleDistrict8={this.handleDistrict8}
          handleDistrict9={this.handleDistrict9}
          handleDistrict10={this.handleDistrict10}
          handleDistrict11={this.handleDistrict11}
          handleDistrict12={this.handleDistrict12}
          handleConvert={this.handleConvert}
          handleCloseModal={this.handleCloseModal}
          handleEditCustomerProduct={this.handleEditCustomerProduct}
          handleAddCustomerProduct={this.handleAddCustomerProduct}
          handleChangeAddPriceBonus={this.handleChangeAddPriceBonus}
          handleAddPriceBonus={this.handleAddPriceBonus}
          handleRemovePriceBonus={this.handleRemovePriceBonus}
          handleReset={this.handleReset}
          handleModal={this.handleModal}
          getListGroupName={this.props.getListGroupName}
          handleGetGroupName={this.handleGetGroupName}
          handlePutGroup={this.handlePutGroup}
          getGroupName={this.props.getGroupName}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  typeToggle: state.Toggle.jenisToggle,
  isLoading: state.Toggle.isLoading,
  isDisabled: state.CustomerProduct.DetailCustomerProduct.isDisabled,
  ProductName: state.CustomerProduct.DetailCustomerProduct.CustomerProductName,
  ProductCode: state.CustomerProduct.DetailCustomerProduct.CustomerProductCode,
  ProductType: state.CustomerProduct.DetailCustomerProduct.CustomerProductType,
  ProductTypeOther:
    state.CustomerProduct.DetailCustomerProduct.CustomerProductTypeOther,
  ProductLogo: state.CustomerProduct.DetailCustomerProduct.CustomerProductLogo,
  ProductBonus:
    state.CustomerProduct.DetailCustomerProduct.CustomerProductBonus,
  District1: state.CustomerProduct.DetailCustomerProduct.District1,
  District2: state.CustomerProduct.DetailCustomerProduct.District2,
  District3: state.CustomerProduct.DetailCustomerProduct.District3,
  District4: state.CustomerProduct.DetailCustomerProduct.District4,
  District5: state.CustomerProduct.DetailCustomerProduct.District5,
  District6: state.CustomerProduct.DetailCustomerProduct.District6,
  District7: state.CustomerProduct.DetailCustomerProduct.District7,
  District8: state.CustomerProduct.DetailCustomerProduct.District8,
  District9: state.CustomerProduct.DetailCustomerProduct.District9,
  District10: state.CustomerProduct.DetailCustomerProduct.District10,
  District11: state.CustomerProduct.DetailCustomerProduct.District11,
  District12: state.CustomerProduct.DetailCustomerProduct.District12,
  ListProductBonus:
    state.CustomerProduct.DetailCustomerProduct.ListCustomerProductBonus,
  getGroupName: state.CustomerProduct.DetailCustomerProduct.GroupNameProduct,
  getListGroupName: state.CustomerProduct.ListGroupName,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionFieldCustomerProduct: bindActionCreators(
    ActionFieldCustomerProduct,
    dispatch
  ),
  actionDetailCustomerProduct: bindActionCreators(
    ActionDetailCustomerProduct,
    dispatch
  ),
  actionAddCustomerProduct: bindActionCreators(
    ActionAddCustomerProduct,
    dispatch
  ),
  actionEditCustomerProduct: bindActionCreators(
    ActionEditCustomerProduct,
    dispatch
  ),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FormCustomerProductContainer);
