import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { SelectOptionsInterface } from 'interface/general';
import { useQuery, useMutation } from '@tanstack/react-query';
import PerformaKurirComponents from '../../components/PerformaKurir';
import { getAllCustomers } from 'services/GeneralService';
import { getProductByCustomers } from 'services/GeneralService';
import {
  getDataTablePerformanceCourier,
  downloadPerformanceCourier,
} from 'services/PerformanceReportService';
import ModalStatus from 'materials/ModalStatus';
import { downloadBlob, processProgressRequest } from 'lib/util';
import ModalDownloadProgress from 'materials/ModalDownloadProgress';
import moment from 'moment-timezone';
import { Modal } from 'antd';

type PerformaKurirProps = {
  dataAllBranch: Array<any>;
  isLoadingBranch?: boolean;
  IdBranch: number;
  roleAccount: string;
  GetUsername: string;
};

export type FilterPerformaKurirProps = {
  startDate: any;
  endDate: any;
  customerId?: number | null;
  productId?: number[];
  branchId?: number[];
  searchValue?: string;
};

export default function PerformaKurir({
  dataAllBranch,
  isLoadingBranch,
  IdBranch,
  roleAccount,
  GetUsername,
}: PerformaKurirProps) {
  const isBranch = roleAccount === 'Branch';
  const defaultFilter = {
    startDate: null,
    endDate: null,
    customerId: null,
    productId: [],
    branchId: [],
    searchValue: '',
  };

  const [dataFilter, setDataFilter] =
    useState<FilterPerformaKurirProps>(defaultFilter);
  const [dataFilterStore, setDataFilterStore] =
    useState<FilterPerformaKurirProps>(defaultFilter);

  const [dataCustomers, setDataCustomers] = useState<SelectOptionsInterface[]>(
    []
  );
  const [dataProducs, setDataProducs] = useState<SelectOptionsInterface[]>([]);
  const [page, setPage] = useState<number>(0);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [downloadForm, setDownloadForm] = useState<string>('');
  const progressHandler = processProgressRequest(setLoadingProgress);

  const { data: dataTable, isFetching: isLoadingTable } = useQuery(
    ['GetTablePerformanceCourier', page, dataFilter],
    () => getDataTablePerformanceCourier({ page, ...dataFilter }),
    {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          if (res.data) {
            const response = res.data;
            if (response?.data?.length > 0) {
              ModalStatus({
                status: 'success',
                title: 'Pencarian Berhasil',
                content: `Data berhasil ditemukan (${response.totalDatas})`,
              });
            } else {
              ModalStatus({
                status: 'error',
                title: 'Tidak Ditemukan',
                content: 'Data yang Anda cari tidak ditemukan Mohon ulangi',
              });
            }
          }
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi kesalahan',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      enabled: !!(dataFilter.startDate && dataFilter.endDate),
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: mutateDownloadList, isLoading: isLoadingDownload } =
    useMutation(
      (data: any) => downloadPerformanceCourier(data, progressHandler),
      {
        onSuccess: res => {
          if (res.status === 200) {
            downloadBlob(
              res.data,
              `PerformaKurir_${moment(res.startDate).format(
                'YYYYMMDD'
              )}-${moment(res.endDate).format('YYYYMMDD')}`,
              'xlsx'
            );
          } else {
            ModalStatus({
              status: 'error',
              title: 'Terjadi Kesalahan',
              content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
            });
          }
        },
        onError: () => {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
          });
        },
      }
    );

  const { isLoading: isLoadingCustomers } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataCustomers'],
    queryFn: () => getAllCustomers(),
    onSuccess(data) {
      const datas = [] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataCustomers(datas);
    },
    refetchOnWindowFocus: false,
  });

  const { isFetching: isLoadingProducts } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataProducts', { id: dataFilterStore.customerId }],
    queryFn: ({ queryKey }) => getProductByCustomers(queryKey),
    onSuccess(data) {
      const datas = [] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataProducs(datas);
    },
    enabled: !!dataFilterStore.customerId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (loadingProgress === 100) {
      Modal.destroyAll();
      setLoadingProgress(0);
    }
  }, [loadingProgress]);

  useEffect(() => {
    if (isBranch) {
      setDataFilterStore(prevState => ({
        ...prevState,
        branchId: [IdBranch],
      }));
      setDataFilter(prevState => ({
        ...prevState,
        branchId: [IdBranch],
      }));
    }
  }, [isBranch, IdBranch]);

  const handleChangeFilter = (name: string, value: any) => {
    if (name === 'searchValue') {
      setDataFilter((prevState: FilterPerformaKurirProps) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setDataFilterStore((prevState: FilterPerformaKurirProps) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetFilter = () => {
    setDataFilterStore(prevState => ({
      ...defaultFilter,
      branchId: isBranch ? [IdBranch] : [],
    }));
    setDataFilter(prevState => ({
      ...defaultFilter,
      branchId: isBranch ? [IdBranch] : [],
    }));
  };

  const handleSubmitFilter = (value: FilterPerformaKurirProps) => {
    setDataFilter(value);
  };

  const handleDownload = () => {
    mutateDownloadList({
      ...dataFilter,
      reportCourierPerformanceType: downloadForm,
    });
  };

  return (
    <>
      <PerformaKurirComponents
        dataAllBranch={dataAllBranch}
        isLoadingBranch={isLoadingBranch}
        selectedBranchId={IdBranch}
        roleAccount={roleAccount}
        handleChangeFilter={handleChangeFilter}
        handleResetFilter={handleResetFilter}
        handleSubmitFilter={handleSubmitFilter}
        handlePage={value => setPage(value)}
        initialValue={dataFilterStore}
        dataCustomers={dataCustomers}
        dataProducts={dataProducs}
        isLoadingCustomers={isLoadingCustomers}
        isLoadingProducts={isLoadingProducts}
        dataTable={dataTable?.data || null}
        isLoadingTable={isLoadingTable || isLoadingDownload}
        handleDownload={handleDownload}
        downloadForm={downloadForm}
        setDownloadForm={setDownloadForm}
      />
      <ModalDownloadProgress
        loading={isLoadingDownload}
        loadingProgress={loadingProgress}
      />
    </>
  );
}
