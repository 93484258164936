import * as ActionLogin from '../../../module/Login/Store';
import * as toggle from '../../action/toggle/index';

import { put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';

const renderModal = (title: any, content: any) => {
  return Modal.error({
    title: <p className={'titleModalNotifFailed'}>{title}</p>,
    content: (
      <div>
        <p className={'titleModalNotifContent'}>{content}</p>
      </div>
    ),
    onOk() {},
  });
};

export function* S_errorHandling(action: any) {
  const state = yield select();
  const statusModal = state.Toggle.modal;
  const error = action.errorData;
  if (error.response) {
    const responseStatus = error.response.status;
    const responseErrorMessage = error.response.data.Message;
    if (responseStatus === 400) {
      renderModal('Kesalahan Parameter Data !!!', responseErrorMessage);
      yield put(toggle.changeLoadingFalse());
    } else if (responseStatus === 401) {
      if (statusModal === true) {
      }

      yield put(ActionLogin.submitTokenRefresh(action));
    } else if (responseStatus === 403) {
      renderModal('Tidak di izinkan !!!', responseErrorMessage);
      yield put(toggle.changeLoadingFalse());
    } else if (responseStatus === 404) {
      renderModal('Gagal Terhubung Ke Service !!!', responseErrorMessage);
      yield put(toggle.changeLoadingFalse());
    } else if (responseStatus === 408) {
      renderModal('Koneksi Lambat !!!', responseErrorMessage);
      yield put(toggle.changeLoadingFalse());
    } else if (responseStatus === 500) {
      if (responseErrorMessage === undefined) {
        renderModal(
          'Gagal menjalankan proses !!!',
          'Terjadi kesalahan pada server, mohon hubungi admin untuk tindakan lebih lanjut'
        );
      } else {
        renderModal('Gagal menjalankan proses !!!', responseErrorMessage);
      }
      yield put(toggle.changeLoadingFalse());
    } else if (responseStatus === 503) {
      renderModal('Terjadi Masalah Pada Server !!!', responseErrorMessage);
      yield put(toggle.changeLoadingFalse());
    }
  }
}

export function* SM_errorHandling() {
  yield takeLatest('ERROR_HANDLING', S_errorHandling);
}
