import React, { Component } from 'react';
import { Tabs } from 'antd';
import ServerVoipContainer from '../../../containers/Content/Voip/ServerVoip/ServerVoipContainer';
import DataActiveContainer from '../../../containers/Content/Voip/DataActive/DataActiveContainer';

const { TabPane } = Tabs;
interface IProps {
  roleAccount: any;
  lastAccessFiture: any;
  handleChangeTabs: any;
}

export default class Index extends Component<IProps> {
  render() {
    return (
      <div>
        <Tabs
          style={{
            paddingRight: '35px',
            paddingLeft: '35px',
            paddingTop: '35px',
          }}
          defaultActiveKey={
            this.props.lastAccessFiture === 'dataActiveTable'
              ? '1'
              : this.props.lastAccessFiture === 'serverVoipTable'
              ? '2'
              : '1'
          }
          onChange={this.props.handleChangeTabs}
        >
          <TabPane tab={<b>Data Aktif</b>} key="1">
            <DataActiveContainer />
          </TabPane>
          <TabPane tab={<b>Server</b>} key="2">
            <ServerVoipContainer />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
