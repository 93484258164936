import * as ActionAuth from '../../action/Auth';
import * as ActionDetailCustomer from '../../action/Customer/ActionDetailCustomer';
import * as ActionFieldCustomer from '../../action/Customer/ActionFieldCustomer';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionTableCustomer from '../../action/Customer/ActionTableCustomer';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import history from '../../utils/History';

export function* S_EditSubmitCustomer(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const idcustomer = state.Customer.PropertiesCustomer.IdCustomer;
  const customername = state.Customer.DetailCustomer.CustomerName;
  const customertelephone = state.Customer.DetailCustomer.CustomerTelephone;
  const customeremail = state.Customer.DetailCustomer.CustomerEmail;
  const customeraddress = state.Customer.DetailCustomer.CustomerAddress;
  const customeraccess = state.Customer.DetailCustomer.CustomerAccess;
  let customertypeother =
    state.Customer.DetailCustomer.CustomerType === 'Lainnya'
      ? state.Customer.DetailCustomer.CustomerTypeOther
      : state.Customer.DetailCustomer.CustomerType;
  const detaileditcustomer = state.Customer.PropertiesCustomer.EditCustomer;
  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/Customer/update',
      {
        id: idcustomer,
        // code : customercode,
        name: customername,
        customerType: customertypeother,
        phone: customertelephone,
        email: customeremail,
        address: customeraddress,
        access: customeraccess,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    if (detaileditcustomer === true) {
      yield put(ActionFieldCustomer.changeClearFieldCustomer());
      yield put(ActionDetailCustomer.changeDetailCustomer());
      history.push('/customer/detail');
    } else {
      yield put(ActionFieldCustomer.changeClearAllDataCustomer());
    }
    yield put(ActionTableCustomer.ClearTableCustomer());
    yield put(toggle.clearJenisToggle());
    yield put(ActionTableCustomer.GetTableCustomer());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: <p style={{ color: '#31E116' }}>Berhasil di Edit</p>,

      onOk() {},
    });
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_EditSubmitCustomer() {
  yield takeLatest('EDIT_CUSTOMER_SUBMIT', S_EditSubmitCustomer);
}
