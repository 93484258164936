import { Checkbox, Col, Row } from 'antd';
import React, { Component } from 'react';

import InputNumberField from 'components/InputNumberField';
import ButtonMaterial from '../../../materials/ButtonMaterial';
import CardMaterial from '../../../materials/CardMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';

interface IProps {
  handleCheckboxSmsDelivery: any;
  handleCheckboxSmsDelivered: any;
  handleCheckboxTelepon: any;
  handleSubmitFiture: any;
  handleEditFiture: any;
  handleBack: any;
  handleCancel: any;
  putSenderAlias: any;
  putSmsDeliveryBody: any;
  putSmsDeliveredBody: any;
  GetSmsDelivery: any;
  GetSmsDelivered: any;
  GetSenderAlias: any;
  GetSmsDeliveryBody: any;
  GetSmsDeliveredBody: any;
  validateSenderAlias: any;
  validateSmsDeliveryBody: any;
  validateSmsDeliveredBody: any;
  helpSenderAlias: any;
  helpSmsDeliveryBody: any;
  helpSmsDeliveredBody: any;
  messageDeliverySms: any;
  messageDeliveredSms: any;
  senderAliasStatus: any;
  GetCall: any;
  GetCustomerName: any;
  ValidateButton: any;
  typeButton: any;
  isLoading: any;
  IsRadius: boolean;
  IsRequestPhone: boolean;
  IsHistory: boolean;
  RadiusMeters: number;
  handleChangeRadiusMeters: (value: number) => void;
  handleCheckboxIsRadius: (value: any) => void;
  handleCheckboxIsRequestPhone: (value: any) => void;
  handleCheckboxIsHistory: (value: any) => void;
}

export default class CustomerConfigFitureComponent extends Component<IProps> {
  render() {
    return (
      <div>
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <CardMaterial
              extra={
                <Row>
                  <Col span={12}>
                    <div style={{ float: 'right', paddingRight: '10px' }}>
                      <ButtonMaterial
                        disabled={
                          this.props.typeButton === 'Add' ? true : false
                        }
                        handleSubmit={this.props.handleEditFiture}
                        teksButton={<b>Ubah</b>}
                        size={'small'}
                        shape={'round'}
                        className={''}
                        type={'primary'}
                        icon={''}
                        style={
                          this.props.typeButton === 'Add'
                            ? {
                                height: '30px',
                                width: '70px',
                                // borderRadius:'12px',
                              }
                            : {
                                colorFont: '#11BEFF',
                                height: '30px',
                                width: '70px',
                                // borderRadius:'12px',
                                background: '#FCC642',
                                borderColor: '#FCC642',
                              }
                        }
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <div style={{ float: 'right' }}>
                      <ButtonMaterial
                        disabled={''}
                        handleSubmit={this.props.handleBack}
                        teksButton={<b>Kembali</b>}
                        size={''}
                        shape={'round'}
                        className={'btnBackConfig'}
                        type={''}
                        icon={''}
                        style={{}}
                      />
                    </div>
                  </Col>
                </Row>
              }
              title={
                <span style={{ color: '#11BEFF' }}>
                  <b>{this.props.GetCustomerName}</b>
                </span>
              }
              style={{ borderRadius: '10px' }}
              content={
                <div>
                  <Row style={{ paddingTop: '10px' }}>
                    <Col span={18}>
                      <div style={{ marginLeft: 30, marginRight: 30 }}>
                        <div style={{ paddingTop: '10px' }}>
                          <Checkbox
                            disabled={
                              this.props.typeButton === 'Detail' ? true : false
                            }
                            checked={this.props.GetSmsDelivery}
                            onChange={this.props.handleCheckboxSmsDelivery}
                          />
                          <span style={{ paddingLeft: '10px' }}>
                            <b>Pengiriman</b>
                          </span>
                          <p className="checkboxNotesConfigFiture">
                            Ceklis jika dibutuhkan SMS ketika kurir mengirimkan
                            dokumen atau barang
                          </p>
                        </div>
                        <div style={{ paddingTop: '10px' }}>
                          <Checkbox
                            disabled={
                              this.props.typeButton === 'Detail' ? true : false
                            }
                            checked={this.props.GetSmsDelivered}
                            onChange={this.props.handleCheckboxSmsDelivered}
                          />
                          <span style={{ paddingLeft: '10px' }}>
                            <b>SMS Terkirim</b>
                          </span>
                          <p className="checkboxNotesConfigFiture">
                            Ceklis jika dibutuhkan SMS ketika dokumen ataupun
                            barang Diterima atau Dikembalikan
                          </p>
                        </div>
                        <div style={{ paddingTop: '10px' }}>
                          <Checkbox
                            disabled={
                              this.props.typeButton === 'Detail' ? true : false
                            }
                            checked={this.props.GetCall}
                            onChange={this.props.handleCheckboxTelepon}
                          />
                          <span style={{ paddingLeft: '10px' }}>
                            <b>Telepon Personal</b>
                          </span>
                          <p className="checkboxNotesConfigFiture">
                            Ceklis bila dibutuhkan untuk kurir dapat menghubungi
                            Pemilik barang / dokumen
                          </p>
                        </div>
                        <div style={{ paddingTop: '10px' }}>
                          <Checkbox
                            disabled={
                              this.props.typeButton === 'Detail' ? true : false
                            }
                            checked={this.props.IsRadius}
                            onChange={this.props.handleCheckboxIsRadius}
                          />
                          <span style={{ paddingLeft: '10px' }}>
                            <b>Radius Lokasi Pengiriman</b>
                          </span>
                          <p className="checkboxNotesConfigFiture">
                            {this.props.IsRadius
                              ? 'Jangkauan Radius wajib diisi'
                              : 'Ceklis jika dibutuhkan untuk kurir proses pengiriman di radius lokasi tujuan'}
                          </p>
                          {this.props.IsRadius && (
                            <div
                              style={{
                                display: 'flex',
                                gap: 8,
                                alignItems: 'center',
                                paddingLeft: '26px',
                              }}
                            >
                              <p style={{ marginBottom: 0 }}>Radius</p>
                              <InputNumberField
                                maxLength={4}
                                width={80}
                                value={this.props.RadiusMeters}
                                onChange={value =>
                                  this.props.handleChangeRadiusMeters(value)
                                }
                                className="customerRadius"
                                styleInput={{
                                  fontWeight: 'bold',
                                  textAlign: 'end',
                                }}
                                error={
                                  (!this.props.RadiusMeters ||
                                    +this.props.RadiusMeters === 0) &&
                                  'Radius tidak boleh kurang dari 1 meter'
                                }
                                disabled={
                                  !this.props.IsRadius ||
                                  this.props.typeButton === 'Detail'
                                }
                              />
                              <p
                                style={{
                                  marginBottom: 0,
                                  fontWeight: 'bold',
                                }}
                              >
                                Meter
                              </p>
                            </div>
                          )}
                        </div>
                        <div style={{ paddingTop: '10px' }}>
                          <Checkbox
                            disabled={
                              this.props.typeButton === 'Detail' ? true : false
                            }
                            checked={this.props.IsHistory}
                            onChange={this.props.handleCheckboxIsHistory}
                          />
                          <span style={{ paddingLeft: '10px' }}>
                            <b>Tampilkan Riwayat Proses Manual</b>
                          </span>
                          <p className="checkboxNotesConfigFiture">
                            Ceklis jika dibutuhkan untuk melihat riwayat proses
                            manual
                          </p>
                        </div>
                        <div style={{ paddingTop: '10px' }}>
                          <Checkbox
                            disabled={
                              this.props.typeButton === 'Detail' ? true : false
                            }
                            checked={this.props.IsRequestPhone}
                            onChange={this.props.handleCheckboxIsRequestPhone}
                          />
                          <span style={{ paddingLeft: '10px' }}>
                            <b>
                              Permintaan Nomor HP saat konfirmasi Pengembalian
                              di Mobile Apps
                            </b>
                          </span>
                          <p className="checkboxNotesConfigFiture">
                            Ceklis jika dibutuhkan untuk menampilkan Permintaan
                            Nomor HP saat konfirmasi Pengembalian di Mobile Apps
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col span={6}>
                      <div
                        style={{
                          marginLeft: 30,
                          paddingRight: 30,
                          float: 'right',
                        }}
                      >
                        <ButtonMaterial
                          disabled={
                            !this.props.RadiusMeters ||
                            +this.props.RadiusMeters === 0
                          }
                          handleSubmit={this.props.handleSubmitFiture}
                          teksButton={<b>Simpan</b>}
                          size={'middle'}
                          shape={'round'}
                          className={''}
                          type={'primary'}
                          icon={''}
                          style={
                            this.props.typeButton === 'Detail' ||
                            this.props.ValidateButton === true
                              ? {
                                  height: '35px',
                                  float: 'right',
                                }
                              : {
                                  float: 'right',
                                  colorFont: '#11BEFF',
                                  height: '35px',
                                  background: '#11BEFF',
                                  borderColor: '#11BEFF',
                                }
                          }
                        />
                      </div>
                      {this.props.typeButton === 'Add' ||
                      this.props.typeButton === 'Edit' ? (
                        <div style={{ float: 'right' }}>
                          <ButtonMaterial
                            disabled={false}
                            handleSubmit={this.props.handleCancel}
                            teksButton={<b>Batal</b>}
                            size={'middle'}
                            shape={'round'}
                            className={''}
                            type={'primary'}
                            icon={''}
                            style={{
                              float: 'right',
                              colorFont: '#11BEFF',
                              height: '35px',
                              width: '80px',
                              // borderRadius:'12px',
                              background: '#FF5858',
                              borderColor: '#FF5858',
                            }}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
