import { Col, Row, Steps, Tag } from 'antd';
import {
  ComplaintCustomerConfirmIcon,
  ComplaintFinishIcon,
  ComplaintInQueueIcon,
  ComplaintInvestigationIcon,
  ComplaintReviewIcon,
} from '../../../apps/assets/image/svgIcon';
import { Field, reduxForm } from 'redux-form';
import {
  InputFile,
  InputSelect,
  InputText,
} from '../../../apps/assets/components/CInput';

import CButton from '../../../apps/assets/components/CButton';
import CCard from '../../../apps/assets/components/CCard';
import CModal from '../../../apps/assets/components/CModal';
import ModalMaterial from 'materials/ModalMaterial';
import CSpin from '../../../apps/assets/components/CSpin';
import React from 'react';
import moment from 'moment';
import validate from '../Validation/EditComplaintValidation';
import { Form as FormFinal, Field as FieldFinalForm } from 'react-final-form';
import SpinMaterial from 'materials/SpinMaterial';
import Button from 'components/Button';
import ModalConfirmation from 'components/ModalConfirmation';
import { InputText as MaterialInputText } from 'components/InputField';
import { complaintStatusObj } from 'apps/enums';
import { getObjectKey } from 'lib/util';

function DetailComplaintComponent(props) {
  const {
    handleBack,
    handleEdit,
    complaintDetailDoc,
    editComplaintModalIsShow,
    assignCourierComplaintModalIsShow,
    courierInvestigationList,
    branchList,
    handleCloseModal,
    handleCloseModalAssignCourier,
    handleDownload,
    handleLinkImage,
    handleLinkSignature,
    handleConvert,
    handleSubmit,
    handleClearCourier,
    handleChangeCourier,
    isLoading,
    formValue,
    formValueDetailForm,
    imgValueState,
    signatureValueState,
    handleDetailDesc,
    descValueState,
    errorFormValue,
    isLoadingUpdateReview,
    answer,
    handleChangeAnswer,
    showModalConfirm,
    handleShowModalConfirm,
    handleLinkLocation,
  } = props;
  const { Step } = Steps;
  const valueCourier = formValueDetailForm
    ? formValueDetailForm.courierInvestigate
    : '';
  // const branchInvestigateValue = formValueDetailForm
  //   ? formValueDetailForm.branchInvestigate
  //   : '';
  const labelBtnSubmit = () => {
    if (complaintDetailDoc.complaintType === complaintStatusObj.Proses) {
      return 'Proses';
    } else {
      return 'Ubah';
    }
  };
  const labelBtnAssignCourier = () => {
    if (complaintDetailDoc.complaintType === complaintStatusObj.Proses) {
      return 'Ubah';
    } else {
      return 'Kirim';
    }
  };
  const showModal = () => {
    if (valueModalType === 'showImg') {
      if (imgValueState !== null) {
        const showImgValue =
          imgValueState.substring(0, 4) !== 'http'
            ? `data:image/jpeg;base64,${imgValueState}`
            : imgValueState;
        return (
          <React.Fragment>
            <img
              className="image-gallery-slide"
              alt="imagePhoto"
              src={showImgValue}
            />
          </React.Fragment>
        );
      } else {
        return imgValueState;
      }
    } else if (valueModalType === 'showSignature') {
      if (signatureValueState !== null) {
        const showSignatureValue =
          signatureValueState.substring(0, 4) !== 'http'
            ? `data:image/jpeg;base64,${signatureValueState}`
            : signatureValueState;
        return (
          <React.Fragment>
            <img
              className="image-gallery-slide"
              alt="imagePhoto"
              src={showSignatureValue}
            />
          </React.Fragment>
        );
      } else {
        return signatureValueState;
      }
    } else if (valueModalType === 'showDesc') {
      return <>{descValueState}</>;
    }
  };
  const validateBtn = () => {
    const validateResultInvestigate =
      !errorFormValue.resultInvestigation && !errorFormValue.investigationFile
        ? false
        : true;
    const validateAssginCourier = !valueCourier ? true : false;

    if (
      (complaintDetailDoc.complaintType ===
        getObjectKey(complaintStatusObj, complaintStatusObj.Proses) ||
        complaintDetailDoc.complaintType ===
          getObjectKey(complaintStatusObj, complaintStatusObj.DalamAntrian)) &&
      valueModalType === 'edit'
    ) {
      return validateResultInvestigate;
    } else {
      return validateAssginCourier;
    }
  };

  const dataValue = formValue ? formValue : '';
  const valueModalType = dataValue.modalType ? dataValue.modalType : '';
  const valueShowType = dataValue.showType ? dataValue.showType : '';

  const btnBack = [
    valueShowType === 'edit' &&
    (complaintDetailDoc.complaintType === complaintStatusObj.Proses ||
      complaintDetailDoc.complaintType ===
        getObjectKey(complaintStatusObj, complaintStatusObj.DalamAntrian))
      ? {
          type: 'primary',
          className: 'btnChangeCourierComplaint',
          onClick: handleChangeCourier,
          isLoading: isLoading,
          content:
            complaintDetailDoc.complaintType ===
            getObjectKey(complaintStatusObj, complaintStatusObj.DalamAntrian)
              ? 'Proses'
              : 'Ubah kurir',
          id: 'btnChangeCourierComplaint',
        }
      : '',
    valueShowType === 'edit' &&
    complaintDetailDoc.complaintType === complaintStatusObj.Review
      ? {
          type: 'primary',
          onClick: props.handleShowModalReview,
          isLoading: isLoading,
          content: 'Review',
          id: 'btnBackComplaint',
          className: 'btnReviewCourier',
        }
      : '',
    valueShowType !== 'detail' &&
    complaintDetailDoc.complaintType !== complaintStatusObj.Review &&
    complaintDetailDoc.complaintType !==
      getObjectKey(
        complaintStatusObj,
        complaintStatusObj.KonfirmasiPelanggan
      ) &&
    complaintDetailDoc.complaintType !==
      getObjectKey(complaintStatusObj, complaintStatusObj.DalamAntrian)
      ? {
          type: 'primary',
          className: 'btnEditComplaint',
          onClick: handleEdit,
          isLoading: isLoading,
          content: labelBtnSubmit(),
          id: 'btnEditComplaint',
        }
      : '',
    {
      type: 'primary',
      className: 'btnBackDefaultExtra',
      onClick: handleBack,
      isLoading: isLoading,
      content: 'Kembali',
      id: 'btnBackComplaint',
    },
  ];
  const btnDownload = [
    {
      type: 'primary',
      className: 'btnDownloadDefault',
      onClick: handleDownload,
      isLoading: isLoading,
      content: 'Download',
      id: 'btnDownloadComplaint',
    },
  ];
  const btnSubmitComplaint = [
    {
      type: 'primary',
      className: 'btnDefaultSubmit',
      onClick: handleSubmit,
      isLoading: isLoading,
      disabled: validateBtn(),
      content: labelBtnAssignCourier(),
      id: 'btnSubmitComplaint',
    },
  ];
  const address =
    complaintDetailDoc.shipToAddress === null ||
    complaintDetailDoc.shipToAddress === undefined
      ? ''
      : `${Object.values(JSON.parse(complaintDetailDoc.shipToAddress)).join(
          ' '
        )}`;
  const historyLength = complaintDetailDoc.history
    ? complaintDetailDoc.history.length
    : 0;
  const showDetailDesc =
    editComplaintModalIsShow && valueModalType === 'showDesc' ? true : false;
  const showPopupPhoto =
    editComplaintModalIsShow && valueModalType === 'showImg' ? true : false;
  const showPopupSignature =
    editComplaintModalIsShow && valueModalType === 'showSignature'
      ? true
      : false;
  const showPopupEdit =
    editComplaintModalIsShow && valueModalType === 'edit' ? true : false;
  const valueShow = () => {
    if (valueModalType === 'showDesc') {
      return { title: 'Detail', visible: showDetailDesc };
    } else if (valueModalType === 'showImg') {
      return { title: 'FOTO', visible: showPopupPhoto };
    } else if (valueModalType === 'showSignature') {
      return { title: 'Tanda Tangan', visible: showPopupSignature };
    }
    return null;
  };
  const showingModalValue: any = valueShow();
  const titleModal = !showingModalValue ? '' : showingModalValue.title;
  const visibleModal = !showingModalValue ? '' : showingModalValue.visible;

  const showPopupAssignCourier =
    assignCourierComplaintModalIsShow && valueModalType === 'assign'
      ? true
      : false;
  const tagStatus = status => {
    if (status === 'Selesai') {
      return <Tag color={'green'}>{status}</Tag>;
    } else {
      return <Tag color={'gray'}>{status}</Tag>;
    }
  };
  const renderData = (label: any, content: any) => {
    return (
      <>
        <Col span={6}>
          <div className="detailLabelDataComplaint">
            <span>{label}</span>
          </div>
        </Col>
        <Col span={18}>
          <div className="detailValueDataComplaint">
            {label === 'Status' ? (
              tagStatus(content)
            ) : (
              <span>{`: ${content}`}</span>
            )}
          </div>
        </Col>
      </>
    );
  };
  const contentModalEditComplaint = () => {
    return (
      <React.Fragment>
        <div className="defaultPaddingModal">
          <Field
            name="resultInvestigation"
            component={InputText}
            textArea={true}
            placeholder={'Masukan penjelasan'}
            label={'Hasil Investigasi'}
            maxlength={600}
            disabled={false}
          />
          <Field
            additionalHandleChangeFunc={handleConvert}
            className="formUploadInvestigation"
            name="investigationFile"
            component={InputFile}
            label={'Unggah Hasil Investigasi'}
            accept={'.png, .jpg, .jpeg'}
          />
          <Row className="floatRight">
            <CButton buttonData={btnSubmitComplaint} />
          </Row>
        </div>
      </React.Fragment>
    );
  };
  const contentAssignCourier = () => {
    return (
      <>
        <div className="defaultPaddingModal">
          <Field
            name="branchInvestigate"
            component={InputSelect}
            idComponent="inputBranchPickup"
            // dafaultItemName="Pilih Posko"
            additionalHandleChangeFunc={handleClearCourier}
            data={branchList}
            label={'Posko'}
          />
          <Field
            name="courierInvestigate"
            component={InputSelect}
            idComponent="inputComplaintStatus"
            customOption={'labelAndCode'}
            data={courierInvestigationList}
            label={'Kurir'}
            dafaultItemName="Pilih Kurir"
          />
          <Row className="floatRight">
            <CButton buttonData={btnSubmitComplaint} />
          </Row>
        </div>
      </>
    );
  };
  const contentTopCard = () => {
    const queueStatus = status => {
      //status diubah seperti ini agar pengkondisian step lebih mudah
      switch (status) {
        case getObjectKey(complaintStatusObj, complaintStatusObj.Proses):
          return 1 /* proses*/;
        case getObjectKey(complaintStatusObj, complaintStatusObj.Selesai):
          return 4 /* selesai*/;
        case getObjectKey(complaintStatusObj, complaintStatusObj.DalamAntrian):
          return 0 /* dalam antrian*/;
        case getObjectKey(
          complaintStatusObj,
          complaintStatusObj.KonfirmasiPelanggan
        ):
          return 3 /* konfirmasi pelanggan*/;
        case getObjectKey(complaintStatusObj, complaintStatusObj.Review):
          return 2 /* review */;
        case 4:
          return 4; /* kosong*/
      }
      return 4;
    };
    const enableColor = {
      iconColor: '#E5E5E5',
      bgColor: '#11BEFF',
      fontColor: 'lightBlueColor',
    };
    const disableColor = {
      iconColor: '#11BEFF',
      bgColor: '#E5E5E5',
      fontColor: 'greyColor',
    };
    let inQueue =
      queueStatus(complaintDetailDoc.complaintType) >= 0
        ? enableColor
        : disableColor;
    let investigate =
      queueStatus(complaintDetailDoc.complaintType) >= 1
        ? enableColor
        : disableColor;
    let review =
      queueStatus(complaintDetailDoc.complaintType) >= 2
        ? enableColor
        : disableColor;
    let customerConfirm =
      queueStatus(complaintDetailDoc.complaintType) >= 3
        ? enableColor
        : disableColor;
    let finish =
      queueStatus(complaintDetailDoc.complaintType) >= 4
        ? enableColor
        : disableColor;
    return (
      <React.Fragment>
        <Steps
          className="spacingStepComplaint"
          current={queueStatus(complaintDetailDoc.complaintType)}
        >
          <Step
            icon={ComplaintInQueueIcon(inQueue.iconColor, inQueue.bgColor)}
          />
          <Step
            icon={ComplaintInvestigationIcon(
              investigate.iconColor,
              investigate.bgColor
            )}
          />
          <Step icon={ComplaintReviewIcon(review.iconColor, review.bgColor)} />
          <Step
            icon={ComplaintCustomerConfirmIcon(
              customerConfirm.iconColor,
              customerConfirm.bgColor
            )}
          />
          <Step icon={ComplaintFinishIcon(finish.iconColor, finish.bgColor)} />
        </Steps>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '0 10px',
          }}
        >
          <span className={`fontWeight ${inQueue.fontColor}`}>
            Dalam Antrian
          </span>
          <span className={`investigateComplaintStep ${investigate.fontColor}`}>
            Proses Investigasi
          </span>

          <span className={`reviewComplaintStep ${review.fontColor}`}>
            Review
          </span>

          <span className={`confirmComplaintStep ${customerConfirm.fontColor}`}>
            Konfirmasi Pelanggan
          </span>

          <span className={`finishComplaintStep ${finish.fontColor}`}>
            Selesai
          </span>
        </div>
      </React.Fragment>
    );
  };
  const showStatus = status => {
    return complaintStatusObj[status] || '';
  };
  const contentCardDetailWaybill = () => {
    const showReciveOrReturn = () => {
      if (complaintDetailDoc.deliveryStatus === 'Success') {
        return renderData(
          'Nama Penerima',
          `${complaintDetailDoc.receiverName} / ${complaintDetailDoc.receiverTitle}`
        );
      } else if (complaintDetailDoc.deliveryStatus === 'Return') {
        return renderData('Alasan', complaintDetailDoc.returnedReason);
      } else {
        return null;
      }
    };
    const showDeliveryStatus = status => {
      if (status === 'Success') {
        return 'Diterima';
      } else if (status === 'Return') {
        return 'Dikembalikan';
      } else {
        return 'Proses';
      }
    };
    return (
      <React.Fragment>
        <Row>
          {renderData('No Tiket', complaintDetailDoc.tiket)}
          {renderData('Status', showStatus(complaintDetailDoc.complaintType))}
          {renderData('Subjek Keluhan', complaintDetailDoc.question)}
          {renderData(
            'Penjelasan',
            complaintDetailDoc.questionDetail === null
              ? '-'
              : complaintDetailDoc.questionDetail
          )}
          {renderData(
            'No Telp yang bisa dihubungi',
            complaintDetailDoc.shipToPhoneAccount === null ||
              complaintDetailDoc.shipToPhoneAccount === undefined
              ? '-'
              : complaintDetailDoc.shipToPhoneAccount
          )}
        </Row>
        <hr />
        <Row>
          {renderData('Waybill', complaintDetailDoc.waybillCode)}
          {renderData('Tanggal Cycle', complaintDetailDoc.cycle)}
          {renderData('Barcode', complaintDetailDoc.barcode)}
          {renderData('Alamat', address)}
          {renderData('Nama Pemilik', complaintDetailDoc.shipToName)}
          {renderData(
            'No Telp',
            complaintDetailDoc.shipToPhoneCustomers === null ||
              complaintDetailDoc.shipToPhoneCustomers === undefined
              ? '-'
              : complaintDetailDoc.shipToPhoneCustomers
          )}
          {renderData(
            'Status Kirim',
            showDeliveryStatus(complaintDetailDoc.deliveryStatus)
          )}
          {showReciveOrReturn()}
          {complaintDetailDoc.deliveryStatus !== 'OnProcces'
            ? renderData(
                `Tanggal / Jam ${showDeliveryStatus(
                  complaintDetailDoc.deliveryStatus
                )}`,
                moment(complaintDetailDoc.itemFinishedTime).format(
                  'DD/MM/YYYY HH:mm:ss'
                )
              )
            : ''}
        </Row>
      </React.Fragment>
    );
  };
  const contentCardHistory = () => {
    return (
      <React.Fragment>
        <Steps
          current={historyLength}
          progressDot
          direction="vertical"
          className="stepHistoryComplaint"
        >
          {complaintDetailDoc.history
            ? complaintDetailDoc.history.map((data: any) => (
                <Step
                  description={
                    <React.Fragment>
                      <Row>
                        <Col span={12} className="complaintTime">
                          <span>
                            {moment(data.date).format('Do MMMM YYYY / HH:mm')}
                          </span>
                        </Col>
                        <Col span={12} className="complaintActor">
                          <span>{data.userActor}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <CCard
                            cardClassName={'cardHistory'}
                            cardTitle={null}
                            cardExtra={''}
                            cardContent={
                              <React.Fragment>
                                <Row>
                                  {data.description.length > 150
                                    ? `${data.description.substring(0, 150)}...`
                                    : ''}
                                  {data.description.length > 150 ? (
                                    // eslint-disable-next-line
                                    <a
                                      onClick={() =>
                                        handleDetailDesc(data.description)
                                      }
                                    >
                                      Lihat Detail
                                    </a>
                                  ) : (
                                    data.description
                                  )}
                                </Row>
                                {data.image !== null ? (
                                  // <CButton buttonData={btnLinkImage(data.image)} />
                                  // eslint-disable-next-line
                                  <a
                                    onClick={() => handleLinkImage(data.image)}
                                  >
                                    Lihat Foto
                                  </a>
                                ) : (
                                  ''
                                )}
                                {data.signature !== null ? (
                                  // eslint-disable-next-line
                                  <a
                                    style={{ paddingLeft: '50px' }}
                                    onClick={() =>
                                      handleLinkSignature(data.signature)
                                    }
                                  >
                                    Lihat TTD
                                  </a>
                                ) : (
                                  ''
                                )}
                                {data?.latitude && data?.longitude && (
                                  // eslint-disable-next-line
                                  <a
                                    style={{ paddingLeft: '50px' }}
                                    onClick={() =>
                                      handleLinkLocation(
                                        data.latitude,
                                        data.longitude
                                      )
                                    }
                                  >
                                    Lihat Lokasi
                                  </a>
                                )}
                              </React.Fragment>
                            }
                          />
                        </Col>
                      </Row>
                    </React.Fragment>
                  }
                />
              ))
            : ''}
        </Steps>
      </React.Fragment>
    );
  };
  return (
    <CSpin
      spinning={isLoading}
      size={'large'}
      content={
        <div className="defaultSpace">
          <Row className="spacingBottom">
            <CCard
              cardClassName={'defaultCard'}
              cardTitle={<span className="lightBlueColor">DETAIL KELUHAN</span>}
              cardExtra={
                <Row>
                  <Col span={24}>
                    <CButton buttonData={btnBack} />
                  </Col>
                </Row>
              }
              cardContent={<React.Fragment>{contentTopCard()}</React.Fragment>}
            />
          </Row>
          <Row>
            <Col span={12} className="spacingRight">
              <CCard
                cardClassName={'cardDetailComplaint'}
                cardTitle={<span className="lightBlueColor">DETAIL TIKET</span>}
                cardExtra={<CButton buttonData={btnDownload} />}
                cardContent={
                  <React.Fragment>{contentCardDetailWaybill()}</React.Fragment>
                }
              />
            </Col>
            <Col span={12}>
              <CCard
                cardClassName={'defaultCard'}
                cardTitle={
                  <span className="lightBlueColor">RIWAYAT PELACAKAN</span>
                }
                cardContent={
                  <React.Fragment>{contentCardHistory()}</React.Fragment>
                }
              />
            </Col>
          </Row>
          <CModal
            teksTitle={titleModal}
            visible={visibleModal}
            onCancel={handleCloseModal}
            content={<div className="defaultPaddingModal">{showModal()}</div>}
          />
          <CModal
            className={''}
            teksTitle={'UBAH DETAIL KOMPLAIN'}
            visible={showPopupEdit}
            onCancel={handleCloseModal}
            content={
              <React.Fragment>{contentModalEditComplaint()}</React.Fragment>
            }
          />
          <CModal
            teksTitle={'TENTUKAN KURIR'}
            visible={showPopupAssignCourier}
            onCancel={handleCloseModalAssignCourier}
            content={<React.Fragment>{contentAssignCourier()}</React.Fragment>}
          />
          <ModalMaterial
            width={'40%'}
            teksTitle="REVIEW KELUHAN"
            visible={props.showModalReview}
            onCancel={props.handleShowModalReview}
            className="modalProcessPickupAssignCourier"
            content={
              <>
                <SpinMaterial
                  spinning={false}
                  size="large"
                  content={
                    <FormFinal
                      onSubmit={() => {}}
                      initialValues={{
                        id: complaintDetailDoc.id,
                        resultInvestigationCourier:
                          complaintDetailDoc.answer || '',
                        resultInvestigationAdmin: answer,
                      }}
                      validate={values => {
                        const errors = {} as any;
                        if (!values.resultInvestigationAdmin) {
                          errors.resultInvestigationAdmin =
                            'Hasil Investigasi Admin wajib di isi';
                        }
                        return errors;
                      }}
                      render={({ submitting, valid }) => (
                        <div
                          style={{
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                          }}
                        >
                          <div style={{ width: '100%' }}>
                            <FieldFinalForm
                              name="resultInvestigationCourier"
                              render={input => (
                                <MaterialInputText
                                  label="Hasil Investigasi - Kurir"
                                  placeholder="Masukan Penjelasan"
                                  textArea
                                  {...input}
                                  disabled
                                />
                              )}
                            />
                          </div>
                          <div style={{ width: '100%' }}>
                            <FieldFinalForm
                              name="resultInvestigationAdmin"
                              render={input => (
                                <MaterialInputText
                                  label="Hasil Investigasi - Admin"
                                  placeholder="Masukan Penjelasan"
                                  textArea
                                  {...input}
                                  onChange={value => handleChangeAnswer(value)}
                                />
                              )}
                            />
                          </div>
                          <Button
                            text="Simpan"
                            borderRadius="small"
                            onClick={() =>
                              handleShowModalConfirm(!showModalConfirm)
                            }
                            disabled={submitting || !valid}
                            loading={isLoadingUpdateReview}
                          />
                        </div>
                      )}
                    />
                  }
                />
                <ModalConfirmation
                  title="Apakah anda yakin?"
                  description="Pastikan anda telah memeriksa kembali hasil input data."
                  visibleModal={showModalConfirm}
                  onCancel={() => {
                    props.handleSubmitReview(
                      complaintDetailDoc.id,
                      complaintDetailDoc.waybillCode
                    );
                    handleBack();
                  }}
                  isLoadingYa={isLoadingUpdateReview}
                  okeText="Tidak"
                  cancelText="Ya"
                  onConfirm={() => {
                    handleShowModalConfirm(!showModalConfirm);
                  }}
                />
              </>
            }
          />
        </div>
      }
    />
  );
}

const DetailFormComponent = reduxForm({
  form: 'detailFormComplaint',
  shouldError: () => {
    return true;
  },
  validate,
})(DetailComplaintComponent);
export default DetailFormComponent;
