import * as ActionGetDeliveryStatus from 'module/DeliveryStatus/Store/ActionGetDeliveryStatus';
import * as ActionSetDeliveryStatus from 'module/DeliveryStatus/Store/ActionSetDeliveryStatus';

import React, { useEffect, useState } from 'react';
import { bindActionCreators, compose } from 'redux';

import DeliveryStatusComponent from '../Component/DeliveryStatusComponent';
import { connect } from 'react-redux';
import history from '../../../core/utils/History';
import moment from 'moment';

interface IProps {
  actionGetDeliveryStatus: any;
  actionSetDeliveryStatus: any;
  CycleDS: any;
  Waybill: any;
  getProcessTime: any;
  getDeliveryTime: any;
  getFinishTime: any;
  getWaybill: any;
  SearchTypeDS: any;
  isLoading: any;
  parsingWaybill: any;
}
function DeliveryStatusContainer({
  actionGetDeliveryStatus,
  actionSetDeliveryStatus,
  CycleDS,
  Waybill,
  getProcessTime,
  getDeliveryTime,
  getFinishTime,
  getWaybill,
  SearchTypeDS,
  isLoading,
  parsingWaybill,
}: IProps) {
  const [ValidateWaybillForm, SetValidateWaybillForm]: any = useState(null);
  useEffect(() => {
    if (parsingWaybill !== undefined) {
      actionSetDeliveryStatus.setWaybill(parsingWaybill);
      actionGetDeliveryStatus.getDeliveryStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleGetDeliveryStatus() {
    history.replace('/delivery/status');
    actionSetDeliveryStatus.setSearchTypeSubmit(SearchTypeDS);
    if (SearchTypeDS === 'waybill') {
      actionSetDeliveryStatus.clearDeliveryStatusBarcode();
      actionGetDeliveryStatus.getDeliveryStatus();
    } else if (SearchTypeDS === 'barcode') {
      actionSetDeliveryStatus.clearDeliveryStatusEcveptWaybill();
      actionGetDeliveryStatus.getDeliveryStatusBarcode();
    } else {
      actionSetDeliveryStatus.clearDeliveryStatusEcveptWaybill();
      actionGetDeliveryStatus.getDeliveryStatusFilter();
    }
    actionSetDeliveryStatus.clearWaybill();
  }
  function handlePutWaybill(event: any) {
    actionSetDeliveryStatus.setWaybill(event.target.value.toUpperCase());

    if (event.target.value === '' || event.target.value === null) {
      SetValidateWaybillForm('error');
    } else if (event.target.value.length < 3) {
      SetValidateWaybillForm('notMinChar');
    } else {
      SetValidateWaybillForm('');
    }
  }
  function handleRadioButtonDS(event: any) {
    actionSetDeliveryStatus.clearWaybill();
    if (event.target.value === 1) {
      actionSetDeliveryStatus.changeRadioButton('waybill');

      SetValidateWaybillForm(null);
    } else {
      actionSetDeliveryStatus.changeRadioButton('barcode');
      SetValidateWaybillForm(null);
    }
  }
  function handleSearchTypeDS(event: any) {
    actionSetDeliveryStatus.clearWaybill();
    SetValidateWaybillForm(null);
    actionSetDeliveryStatus.changeSearchTypeDS(event);
  }
  function handleCycleDS(event: any) {
    actionSetDeliveryStatus.changeCycleDS(moment(event).format('YYYYMMDD'));
  }

  return (
    <div>
      <DeliveryStatusComponent
        Waybill={Waybill}
        isLoading={isLoading}
        handleGetDeliveryStatus={handleGetDeliveryStatus}
        handlePutWaybill={handlePutWaybill}
        validateButton={
          getWaybill === null ||
          getWaybill === '' ||
          ValidateWaybillForm === 'notMinChar'
            ? true
            : false
        }
        ValidateWaybill={
          ValidateWaybillForm === 'error' ||
          ValidateWaybillForm === 'notMinChar'
            ? 'error'
            : ''
        }
        helpWaybill={
          ValidateWaybillForm === 'error' && SearchTypeDS === 'waybill'
            ? 'Waybill wajib diisi'
            : ValidateWaybillForm === 'error' && SearchTypeDS === 'barcode'
            ? 'Barcode wajib diisi'
            : ValidateWaybillForm === 'error' && SearchTypeDS === 'NomerAccount'
            ? 'Nomer akun wajib diisi'
            : ValidateWaybillForm === 'error' &&
              SearchTypeDS === 'NomerReferensi'
            ? 'Nomer referensi wajib diisi'
            : ValidateWaybillForm === 'error' && SearchTypeDS === 'NomerPolis'
            ? 'Nomer polis wajib diisi'
            : ValidateWaybillForm === 'error' &&
              SearchTypeDS === 'NomerRekening'
            ? 'Nomer rekening wajib diisi'
            : ValidateWaybillForm === 'error' && SearchTypeDS === 'Name'
            ? 'Nama wajib diisi'
            : ValidateWaybillForm === 'notMinChar'
            ? 'Data tidak boleh kurang dari 3'
            : ''
        }
        getProcessTime={getProcessTime}
        getDeliveryTime={getDeliveryTime}
        getFinishTime={getFinishTime}
        parsingWaybill={parsingWaybill}
        handleRadioButtonDS={handleRadioButtonDS}
        handleSearchTypeDS={handleSearchTypeDS}
        SearchTypeDS={SearchTypeDS}
        handleCycleDS={handleCycleDS}
        CycleDS={CycleDS}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  CycleDS: state.DeliveryStatus.CycleDS,
  SearchTypeDS: state.DeliveryStatus.SearchTypeDS,
  Waybill: state.DeliveryStatus.Waybill,
  getWaybill: state.DeliveryStatus.Waybill,
  getProcessTime: state.DeliveryStatus.ProcessTime,
  getDeliveryTime: state.DeliveryStatus.DeliveryTime,
  getFinishTime: state.DeliveryStatus.FinishedTime,
  isLoading: state.Toggle.isLoading,
  parsingWaybill: state.router.location.search.split('?')[1],
});

const mapDispatchToProps = (dispatch: any) => ({
  actionGetDeliveryStatus: bindActionCreators(
    ActionGetDeliveryStatus,
    dispatch
  ),
  actionSetDeliveryStatus: bindActionCreators(
    ActionSetDeliveryStatus,
    dispatch
  ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DeliveryStatusContainer);
