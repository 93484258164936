import {Action} from 'redux';

const initialState = 
{
    PropertiesBranch:{
        idBranch : null,
        idAdminBranch:null,
        idVillage:null,
        EditBranch:null,
        BranchDetail:null,
        LoadingBranch:false,
        TypeLoadingBranch:null
    },
    DetailBranch:{
        GroupBranchId: null,
        GroupName: null,
        GroupNameBranch: null,
        NameBranch : null,
        AreaCode : null,
        Address : null,
        PhoneNumber : null,
        Province : null,
        City : null,
        SubDistrict : null,
        Village : null,
        AdminBranch : null,
        SelectAdmin : false,
        NameAdminBranch: null,
        PostalCode : null,
        LatitudeBranch : null,
        LongitudeBranch : null,
        Partner : false
    },
    ListGroupName: [],
    DeliveryBranch:[],
    BranchGroupList : [] ,
    AllBranch : [] 
}

export interface IAuthAction extends Action<string>
{

}

export interface IChangeIdBranch extends IAuthAction
{
    idBranch : any
}
export interface ISetAllBranch extends IAuthAction
{
    AllBranch : any
}
export interface ISetBranchGroupList extends IAuthAction
{
    BranchGroupList : any
}
export interface IChangeIdAdminBranch extends IAuthAction
{
    idAdminBranch:any
}
export interface IChangeIdVillage extends IAuthAction
{
    idVillage:any
}
export interface IChangeEditBranch extends IAuthAction
{
    editbranch:any
}
export interface ISetDetailBranch extends IAuthAction
{
    detailBranch:any
}
export interface IChangeGroupBranchId extends IAuthAction
{
    GroupBranchId:any
}
export interface IChangeGroupNameBranch extends IAuthAction
{
    GroupNameBranch:any
}
export interface IChangeGroupNameB extends IAuthAction
{
    GroupName:any
}
export interface IChangeNameBranch extends IAuthAction
{
    NameBranch:any
}
export interface IChangeAreaCode extends IAuthAction
{
    AreaCode:any
}
export interface IChangeAddress extends IAuthAction
{
    Address:any
}
export interface IChangePhoneNumber extends IAuthAction
{
    PhoneNumber:any
}
export interface IChangeProvince extends IAuthAction
{
    Province:any
}
export interface IChangeCity extends IAuthAction
{
    City:any
}
export interface IChangeSubDistrict extends IAuthAction
{
    SubDistrict:any
}
export interface IChangeSubVillage extends IAuthAction
{
    Village:any
}
export interface IChangeAdminBranch extends IAuthAction
{
    AdminBranch:any
}
export interface IChangeNameAdminBranch extends IAuthAction
{
    NameAdminBranch:any
}
export interface IChangePostalCode extends IAuthAction
{
    PostalCode:any
}
export interface IChangeLatitudeBranch extends IAuthAction
{
    LatitudeBranch:any
}
export interface IChangeLongitudeBranch extends IAuthAction
{
    LongitudeBranch:any
}
export interface IChangeDeliveryBranch extends IAuthAction
{
    DeliveryBranch:any
}
export interface ISetListGroupName extends IAuthAction
{
    ListGroupName:any
}
export interface ISetTypeLoadingBranch extends IAuthAction
{
    TypeLoadingBranch:any
}

export default function AuthReducerDetailId(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "SET_ID_BRANCH":
            const changeIdBranch = action as IChangeIdBranch
            newState.PropertiesBranch.idBranch = changeIdBranch.idBranch
            
        return {...newState}
        case "CHANGE_EDIT_BRANCH":
            const changeEditBranch = action as IChangeEditBranch
            newState.PropertiesBranch.EditBranch = changeEditBranch.editbranch
         return {...newState}
        case "SET_DETAIL_BRANCH":
            const setDetailBranch = action as ISetDetailBranch
            newState.PropertiesBranch.BranchDetail = setDetailBranch.detailBranch
         return {...newState}
        case "SET_BRANCH":
            const setAllBranch = action as ISetAllBranch
            newState.AllBranch = setAllBranch.AllBranch
            
        return {...newState}
        case "SET_BRANCH_GROUP_LIST":
            const setBranchGroupList = action as ISetBranchGroupList
            newState.BranchGroupList = setBranchGroupList.BranchGroupList
            
        return {...newState}
        case "SET_ID_ADMIN_BRANCH":
            const changeIdAdminBranch = action as IChangeIdAdminBranch
            newState.PropertiesBranch.idAdminBranch = changeIdAdminBranch.idAdminBranch
            
        return {...newState}
        case "SET_ID_VILLAGE":
            const changeIdVilage = action as IChangeIdVillage
            newState.PropertiesBranch.idVillage = changeIdVilage.idVillage
            
        return {...newState}
        case "CHANGE_GROUP_BRANCH_ID":
            const changeGroupBranchId = action as IChangeGroupBranchId
            newState.DetailBranch.GroupBranchId = changeGroupBranchId.GroupBranchId
            
        return {...newState}
        case "CHANGE_GROUP_NAME_B":
            const changeGroupName = action as IChangeGroupNameB
            newState.DetailBranch.GroupName = changeGroupName.GroupName
            
        return {...newState}
        case "CHANGE_GROUP_NAME_BRANCH":
            const changeGroupNameBranch = action as IChangeGroupNameBranch
            newState.DetailBranch.GroupNameBranch = changeGroupNameBranch.GroupNameBranch
            
        return {...newState}
        case "SET_NAME_BRANCH":
            const changeNameBranch = action as IChangeNameBranch
            newState.DetailBranch.NameBranch = changeNameBranch.NameBranch
            
        return {...newState}
        case "SET_AREA_CODE":
            const changeAreaCode = action as IChangeAreaCode
            newState.DetailBranch.AreaCode = changeAreaCode.AreaCode
            
        return {...newState}
        case "SET_ADDRESS":
            const changeAddress = action as IChangeAddress
            newState.DetailBranch.Address = changeAddress.Address
            
        return {...newState}
        case "SET_PHONE_NUMBER":
            const changePhoneNumber = action as IChangePhoneNumber
            newState.DetailBranch.PhoneNumber = changePhoneNumber.PhoneNumber
            
        return {...newState}
        case "SET_BRANCH_PROVINCE":
            const changeProvince = action as IChangeProvince
            newState.DetailBranch.Province = changeProvince.Province
            
        return {...newState}

        case "CLEAR_BRANCH_PROVINCE":
            newState.DetailBranch.Province = null
            
        return {...newState}
        case "CLEAR_BRANCH_CITY":
            newState.DetailBranch.City = null
            
        return {...newState}
        case "CLEAR_BRANCH_SUBDISTRICT":
            newState.DetailBranch.SubDistrict = null
            
        return {...newState}
        case "CLEAR_BRANCH_VILLAGE":
            newState.DetailBranch.Village = null
            
        return {...newState}
        case "CLEAR_ADMIN_BRANCH":
            newState.DetailBranch.AdminBranch = null
            
        return {...newState}
        
        case "CLEAR_CHECKBOX_CHOOSE_ADMIN":
            newState.DetailBranch.SelectAdmin = false
            
        return {...newState}

        case "CLEAR_NAME_ADMIN_BRANCH":
            newState.DetailBranch.NameAdminBranch = null
            
        return {...newState}

        case "SET_BRANCH_CITY":
            const changeCity = action as IChangeCity
            newState.DetailBranch.City = changeCity.City
            
        return {...newState}
        case "SET_BRANCH_SUBDISTRICT":
            const changeSubDistrict = action as IChangeSubDistrict
            newState.DetailBranch.SubDistrict = changeSubDistrict.SubDistrict
            
        return {...newState}
        case "SET_BRANCH_VILLAGE":
            const changeVillage = action as IChangeSubVillage
            newState.DetailBranch.Village = changeVillage.Village
            
        return {...newState}
        case "SET_ADMIN_BRANCH":
            const changeAdminBranch = action as IChangeAdminBranch
            newState.DetailBranch.AdminBranch = changeAdminBranch.AdminBranch
            
        return {...newState}
        case "SET_NAME_ADMIN_BRANCH":
            const changenameadminbranch = action as IChangeNameAdminBranch
            newState.DetailBranch.NameAdminBranch = changenameadminbranch.NameAdminBranch
            
        return {...newState}
        case "SELECT_ADMIN_BRANCH":
            newState.DetailBranch.SelectAdmin = !newState.DetailBranch.SelectAdmin
            
        return {...newState}
        case "SET_POSTAL_CODE":
            const changePostalCode = action as IChangePostalCode
            newState.DetailBranch.PostalCode = changePostalCode.PostalCode
            
        return {...newState}

        case "SET_LATITUDE_BRANCH":
            const changeLatitudeBranch = action as IChangeLatitudeBranch
            newState.DetailBranch.LatitudeBranch = changeLatitudeBranch.LatitudeBranch
            
        return {...newState}

        case "SET_LONGITUDE_BRANCH":
            const changeLongitudeBranch = action as IChangeLongitudeBranch
            newState.DetailBranch.LongitudeBranch = changeLongitudeBranch.LongitudeBranch
            
        return {...newState}

        case "SET_PARTNER":
            newState.DetailBranch.Partner = !newState.DetailBranch.Partner
            
        return {...newState}
        case "SET_DELIVERY_BRANCH":
            const setDeliveryBranch = action as IChangeDeliveryBranch
            newState.DeliveryBranch = setDeliveryBranch.DeliveryBranch
            
        return {...newState}
        case "SET_GROUP_NAME_BRANCH_LIST":
            const setGroupNameBranchList = action as ISetListGroupName
            newState.ListGroupName = setGroupNameBranchList.ListGroupName
            
        return {...newState}
        case "SET_LOADING_BRANCH":
            newState.PropertiesBranch.LoadingBranch = !newState.PropertiesBranch.LoadingBranch
            
        return {...newState}
        case "SET_TYPE_LOADING_BRANCH":
            const setTypeLoadingBranch = action as ISetTypeLoadingBranch
            newState.PropertiesBranch.TypeLoadingBranch = setTypeLoadingBranch.TypeLoadingBranch
            
        return {...newState}
        case "CLEAR_TYPE_LOADING_BRANCH":
            newState.PropertiesBranch.TypeLoadingBranch = null
            
        return {...newState}
        case "CLEAR_POSTAL_CODE":
            newState.DetailBranch.PostalCode = null
            
        return {...newState}
        case "CLEAR_DELIVERY_BRANCH":
            newState.DeliveryBranch = []
            
        return {...newState}
        case "CLEAR_GROUP_NAME_BRANCH_LIST":
            newState.ListGroupName = []
            
        return {...newState}
        case "CLEAR_ALL_DETAIL_BRANCH":
            newState.DetailBranch.GroupNameBranch = null
            newState.DetailBranch.NameBranch = null
            newState.DetailBranch.AreaCode = null
            newState.DetailBranch.Address = null
            newState.DetailBranch.PhoneNumber = null
            newState.DetailBranch.Province = null
            newState.DetailBranch.City = null
            newState.DetailBranch.SubDistrict = null
            newState.DetailBranch.Village = null
            newState.DetailBranch.SelectAdmin = false
            newState.DetailBranch.AdminBranch = null
            newState.DetailBranch.PostalCode = null
            newState.DetailBranch.LatitudeBranch = null
            newState.DetailBranch.LongitudeBranch = null
            newState.DetailBranch.Partner = false
            newState.DetailBranch.NameAdminBranch = null

        return {...newState}
        case "CLEAR_ALL_PROPERTIES_BRANCH":
            newState.PropertiesBranch.idAdminBranch = null
            newState.PropertiesBranch.idBranch = null
            newState.PropertiesBranch.idVillage = null
            newState.PropertiesBranch.EditBranch = null
            newState.PropertiesBranch.BranchDetail = null
            
        return {...newState}
        case "CLEAR_BRANCH_GROUP_NAME":
            newState.DetailBranch.GroupName = null
            
        return {...newState}
        case "CLEAR_BRANCH_GROUP_FORM":
            newState.DetailBranch.GroupBranchId = null
            newState.DetailBranch.GroupName = null
            
        return {...newState}
        case "CLEAR_ALL_BRANCH":
            newState.DetailBranch.GroupNameBranch = null
            newState.DetailBranch.NameBranch = null
            newState.DetailBranch.AreaCode = null
            newState.DetailBranch.Address = null
            newState.DetailBranch.PhoneNumber = null
            newState.DetailBranch.Province = null
            newState.DetailBranch.City = null
            newState.DetailBranch.SubDistrict = null
            newState.DetailBranch.Village = null
            newState.DetailBranch.AdminBranch = null
            newState.DetailBranch.PostalCode = null
            newState.DetailBranch.LatitudeBranch = null
            newState.DetailBranch.LongitudeBranch = null
            newState.DetailBranch.Partner = false
            newState.PropertiesBranch.EditBranch = null
            newState.PropertiesBranch.idAdminBranch = null
            newState.PropertiesBranch.idVillage = null
            newState.PropertiesBranch.idBranch = null
            newState.DetailBranch.NameAdminBranch = null
            newState.PropertiesBranch.BranchDetail = null
            newState.AllBranch=[]
            newState.BranchGroupList=[]
            newState.DeliveryBranch=[]
            newState.PropertiesBranch.LoadingBranch=false
            newState.PropertiesBranch.TypeLoadingBranch=null
            
        return {...newState}

        case "CLEAR_BRANCH":
            newState.AllBranch=[]
            newState.PropertiesBranch.LoadingBranch=false
            newState.PropertiesBranch.TypeLoadingBranch=null
        return {...newState}
    }
    return state
}