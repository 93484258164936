import React, { Component } from 'react'
import {Tag,Switch,Row,Col, Collapse, Select,Form} from 'antd';
import ButtonMaterial from "../../../../materials/ButtonMaterial";
import CardMaterial from '../../../../materials/CardMaterial';

interface IProps
{
    visible:any
    handleBack:any
    listServerVoip:any
    handleActivationToggle:any
    handleGetCustomerProduct:any
    handlePutCustomerProduct:any
    handleBlurSelectProduct:any
    handleSubmit:any
    handlePut:any
    listCustomerProduct:any
    productType:any
    ValueGetProduct:any
    toggleActivation:any
    ArrayValue:any
    ValidateCustomerProduct:any
    helpCustomerProduct:any
    seeMore:any
    handleSeeMore:any
    validateButton:any
}
const { Panel } = Collapse;
const { Option } = Select;
export default class ActivationVoipComponent extends Component<IProps> {
    render() {
        const listCustomerProduct = this.props.listCustomerProduct===null?this.props.listCustomerProduct:this.props.listCustomerProduct.length
        const productType = this.props.productType===null?this.props.productType:this.props.productType.length
        const renderData = (label:any, content:any) => {
            return (
              <>
                <Row style={{paddingBottom: '6px',paddingTop: '1px'}}>
                    <Col span={8}>
                        <div>
                            <span>{label}</span>
                        </div>
                    </Col>
                    <Col span={16} style={{paddingLeft:'10px'}}>
                        <div>
                            <span><b>{content}</b></span>
                        </div>
                    </Col>
                </Row>
              </>
            );
          };
        return (
            <div>
                <CardMaterial
                    extra={
                        <Row>
                            <Col span={12}>
                                <div style={{float:'right'}}>
                                    <ButtonMaterial
                                        disabled={""}
                                        handleSubmit={this.props.handleBack}
                                        teksButton={<b>Kembali</b>}
                                        size={"small"}
                                        shape={"round"}
                                        className={""}
                                        type={"primary"}
                                        icon={""}
                                        style={{
                                            colorFont:'#11BEFF',
                                            height:"30px",
                                            background:"#F5487D",
                                            borderColor:'#F5487D'}}
                                    />
                                </div>
                            </Col>
                        </Row>
                    }
                    style={{borderRadius:'10px'}}
                    title={<span style={{color:'#11BEFF',marginLeft: '30px',}}>
                        <b>Pengaturan Panggilan VoIP (Voice over Internet Protocol)</b>
                        </span>}
                    content={
                        <div>
                            <CardMaterial
                            extra={""}
                            style={{borderRadius:"8px",marginLeft:200, marginRight:200, marginBottom:20}}
                            title={null}
                            content={
                                <React.Fragment>
                                    <div style={{fontWeight:'bold', marginBottom:20}}>
                                    <Switch
                                        checked={this.props.toggleActivation}
                                        onChange={this.props.handleActivationToggle}
                                        style={{marginRight:10}}
                                    />
                                        Aktifkan untuk fitur panggilan
                                    </div>
                                    <Row>
                                        <div>
                                            <span>
                                            <b>Jenis Produk</b>
                                            </span>
                                            <Form.Item
                                            validateStatus={this.props.ValidateCustomerProduct}
                                            help={this.props.helpCustomerProduct}
                                            >
                                            <Select
                                                mode="multiple"
                                                placeholder="Pilih jenis produk"
                                                disabled={this.props.toggleActivation===false?true:false}
                                                onFocus={this.props.handleGetCustomerProduct}
                                                value={this.props.ValueGetProduct}
                                                onChange={this.props.handlePut}
                                                onDeselect={this.props.handleBlurSelectProduct}
                                                onSelect={this.props.handleBlurSelectProduct}
                                                onBlur={this.props.handleBlurSelectProduct}
                                                showSearch
                                                allowClear={true}
                                                style={{ width: '100%' }}
                                            >
                                                {listCustomerProduct===productType?null:
                                                    <Option key={''} value={"SetAllProduct"}>{'Pilih Semua Produk'}</Option>}
                                                    {(this.props.listCustomerProduct.map((data:any, index:any)=>
                                                    <Option key={index} value={data.id}>{data.name}</Option>))}
                                            </Select>
                                            </Form.Item>
                                        </div>
                                    </Row>
                                </React.Fragment>
                            }/>
                            {this.props.listServerVoip.map(
                                (data: any, index: any) => (
                                index<this.props.seeMore?
                                <Collapse
                                    style={{ borderRadius: '30px', marginBottom: '30px',marginLeft:200, marginRight:200 }}
                                >
                                    <Panel disabled={false} header={data.nameServer} key="1">
                                        {renderData('Nama Server', `: ${data.nameServer}`)}
                                        <Row style={{paddingBottom: '6px',paddingTop: '1px'}}>
                                            <Col span={8}>
                                                <span>
                                                    Status Server
                                                </span>
                                            </Col>
                                            <Col span={16} style={{paddingLeft:'10px'}}>
                                            <b>: </b>
                                            <Tag color={data.status===true?"green":"red"}>
                                                <span style={{fontSize:14}}>
                                                    {data.status===true?"AKTIF":"TIDAK AKTIF"}
                                                </span>
                                            </Tag>
                                            </Col>
                                        </Row>
                                        {renderData('IP Server', `: ${data.ipAddress}`)}
                                        {renderData('Kuota Tersisa', `: ${data.kuota}`)}
                                    </Panel>
                                </Collapse>
                                :""
                                ))}
                                <div style={{textAlign:'center'}}>
                                    {this.props.seeMore!==5?"":
                                    <ButtonMaterial
                                        disabled={""}
                                        handleSubmit={this.props.handleSeeMore}
                                        teksButton={'Tampilkan lebih banyak'}
                                        size={"small"}
                                        shape={"round"}
                                        className={""}
                                        type={"link"}
                                        icon={""}
                                        style={{height:"35px",}}
                                    />
                                }
                                </div>
                                <div style={{float:'right'}}>
                                    <ButtonMaterial
                                        disabled={this.props.validateButton}
                                        handleSubmit={this.props.handleSubmit}
                                        teksButton={'Simpan'}
                                        size={"small"}
                                        shape={"round"}
                                        className={""}
                                        type={"primary"}
                                        icon={""}
                                        style={
                                            this.props.validateButton===true?{
                                                float: 'right',
                                                height: '35px',
                                            }:
                                            {
                                            float: 'right',
                                            colorFont: '#11BEFF',
                                            height: '35px',
                                            background: '#11BEFF',
                                            borderColor: '#11BEFF',}
                                        }
                                    />
                                </div>
                        </div>
                    }
                    />
            </div>
        )
    }
}
