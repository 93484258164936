import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { BagTypes, BagStatus } from 'lib/constants';

import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { getScanTableBagging } from 'services/BaggingDocumentServices';

import styles from './ModalDetailBagging.module.css';
import ModalMaterial from 'materials/ModalMaterial';
import SpinMaterial from 'materials/SpinMaterial';
import Button from 'components/Button';
import { SectionInfo } from '../../../../materials/SectionInfo';
import CollapseListWaybil from '../../../../materials/CollapseListWaybil';
import { BaggingDocumentFormType } from '..';

type ModalDetailBaggingProps = {
  isShowModal: boolean;
  handleCloseModal: () => void;
  dataFormBagging: BaggingDocumentFormType;
  isLoading: boolean;
  selectedBagId: number | null;
};

export default function ModalDetailBagging({
  isShowModal,
  handleCloseModal,
  isLoading = false,
  dataFormBagging,
  selectedBagId,
}: ModalDetailBaggingProps) {
  const [page, setPage] = useState<number>(0);

  const { data: dataTableScanBag, status } = useQuery<AxiosResponse>(
    ['TableScanBarcodeBag', page],
    () => getScanTableBagging({ page, idBagging: selectedBagId }),
    {
      enabled: !!selectedBagId,
    }
  );

  return (
    <div>
      <ModalMaterial
        teksTitle="DETAIL BARCODE BAG"
        width="70%"
        visible={isShowModal}
        onCancel={handleCloseModal}
        content={
          <SpinMaterial
            spinning={isLoading}
            size={'large'}
            content={
              <div style={{ marginTop: 24, padding: '0 24px' }}>
                <div className={styles.wrapperFilter}>
                  <Row gutter={24}>
                    <Col md={12} className={styles.filterColumn}>
                      <SectionInfo
                        label="Jenis"
                        value={
                          BagTypes.find(
                            value => value.id === dataFormBagging.typeBag
                          )?.name
                        }
                      />
                      <SectionInfo
                        label="Barcode Bag"
                        value={dataFormBagging.barcodeBag}
                      />
                      <SectionInfo
                        label="Kurir"
                        value={dataFormBagging.couriercode}
                      />
                      <SectionInfo
                        label="Status"
                        value={
                          BagStatus.find(
                            value =>
                              value.id === dataFormBagging.baggingStatusDocument
                          )?.name
                        }
                        valueStyle={{
                          color:
                            dataFormBagging.baggingStatusDocument === 'Process'
                              ? '#1890FF'
                              : '#00AB44',
                        }}
                      />
                    </Col>
                    <Col md={12} className={styles.filterColumn}>
                      <SectionInfo
                        label="Tanggal Kirim"
                        value={dataFormBagging?.dateSend?.format('DD/MM/YYYY')}
                      />
                      <SectionInfo
                        label="Tanggal Terima"
                        value={
                          dataFormBagging?.finishBaggingTime?.format(
                            'DD/MM/YYYY'
                          ) || '-'
                        }
                      />
                      <SectionInfo
                        label="Posko Asal"
                        value={dataFormBagging.branchFromName}
                      />
                      <SectionInfo
                        label="Posko Tujuan"
                        value={dataFormBagging.branchToName}
                      />
                    </Col>
                  </Row>
                  <CollapseListWaybil
                    isDetail={true}
                    selectedBagId={selectedBagId}
                    dataTableScanBag={dataTableScanBag?.data}
                    isLoadingTableScanBag={status === 'loading'}
                    setPage={setPage}
                  />
                  <div className={styles.actionFilter}>
                    <Button
                      text="Kembali"
                      onClick={handleCloseModal}
                      variant="danger"
                    />
                  </div>
                </div>
              </div>
            }
          />
        }
      />
    </div>
  );
}
