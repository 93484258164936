import { Col, DatePicker, Form, Radio, Row, Select } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../../materials/CardMaterial';
import TablePrintWaybillContainer from '../../../../../containers/Content/Delivery/Document/TablePrintWaybillContainer';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

const { Option } = Select;

interface IProps {
  handleValidateUploadDate: any;
  getUploadDate: any;
  valueUploadDate: any;
  IdBranch: any;
  IdCustomer: any;
  IdCustomerProduct: any;
  RadioButton: any;
  handlePutUploadDate: any;
  handlePutBranch: any;
  handlePutCustomer: any;
  handlePutCustomerProduct: any;
  handleGetBranch: any;
  handleGetCustomer: any;
  handleGetCustomerProduct: any;
  ValidateUploadDate: any;
  validateCustomerProduct: any;
  helpValidateUploadDate: any;
  helpValidateCustomerProduct: any;
  handleModalWaybillPrintType: any;
  loadingSelectBranch: any;
  loadingSelectCustomer: any;
  loadingSelectCustomerProduct: any;
  handleBlurBranch: any;
  handleBlurCustomer: any;
  handleBlurCustomerProduct: any;
  handleResetBranch: any;
  handleResetCustomer: any;
  handleChangeRadioButton: any;
  handleModal: any;
  handleReset: any;
  handleSearch: any;
  listBranch: any;
  listCustomer: any;
  listCustomerProduct: any;
}

export default class FormPrintWaybillComponent extends Component<IProps> {
  render() {
    const minDate = (d: any) =>
      !d ||
      d.isBefore('2020-01-01') ||
      d.isAfter(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
    const validateReset =
      this.props.getUploadDate === null &&
      this.props.IdBranch === null &&
      this.props.IdCustomer === null &&
      (this.props.IdCustomerProduct === [] ||
        this.props.IdCustomerProduct.length === 0)
        ? true
        : this.props.getUploadDate !== null ||
          this.props.IdBranch !== null ||
          this.props.IdCustomer !== null ||
          this.props.IdCustomerProduct !== [] ||
          this.props.IdCustomerProduct.length !== 0
        ? false
        : true;
    const validateSearch =
      this.props.IdCustomer !== null
        ? this.props.getUploadDate === null ||
          this.props.IdCustomerProduct === null ||
          this.props.IdCustomerProduct.length === 0 ||
          this.props.IdCustomerProduct === []
          ? true
          : false
        : this.props.getUploadDate === null
        ? true
        : false;
    return (
      <React.Fragment>
        <CardMaterial
          extra={''}
          title={
            <span style={{ color: '#11beff' }}>
              <b>Tanda Terima</b>
            </span>
          }
          style={{ borderRadius: '5px' }}
          content={
            <div>
              <div>
                <Row>
                  <Col span={12} style={{ marginBottom: 25 }}>
                    <Radio.Group
                      onChange={this.props.handleChangeRadioButton}
                      value={this.props.RadioButton}
                    >
                      <Radio value={'Upload'}>Upload</Radio>
                      <Radio value={'Cycle'}>Cycle</Radio>
                    </Radio.Group>
                  </Col>
                  <Col span={12}></Col>
                </Row>
                <Row>
                  <Col span={12} style={{ paddingRight: '20px' }}>
                    <div>
                      <span>
                        <b>
                          {this.props.RadioButton === 'Upload'
                            ? 'Tanggal Upload File'
                            : 'Tanggal Cycle'}
                        </b>
                      </span>
                    </div>
                    <Form.Item
                      validateStatus={this.props.ValidateUploadDate}
                      help={this.props.helpValidateUploadDate}
                    >
                      <DatePicker
                        style={{
                          width: '100%',
                          backgroundColor: '#F5F5F5',
                          fontWeight: 'bold',
                        }}
                        locale={locale}
                        format={'YYYY-MM-DD'}
                        placeholder={'YYYY-MM-DD'}
                        onOpenChange={this.props.handleValidateUploadDate}
                        value={this.props.valueUploadDate}
                        onChange={this.props.handlePutUploadDate}
                        disabledDate={minDate}
                      />
                    </Form.Item>
                    <span>
                      <b>Posko</b>
                    </span>
                    <Form.Item>
                      <Select
                        loading={this.props.loadingSelectBranch}
                        style={{ width: '100%' }}
                        placeholder="Pilih Posko"
                        optionFilterProp="children"
                        disabled={false}
                        showSearch
                        // allowClear={true}
                        onSelect={this.props.handleResetBranch}
                        onChange={this.props.handlePutBranch}
                        onBlur={this.props.handleBlurBranch}
                        value={
                          this.props.IdBranch === null ? (
                            <span style={{ color: '#969696' }}>
                              Pilih Posko
                            </span>
                          ) : (
                            this.props.IdBranch
                          )
                        }
                        onFocus={this.props.handleGetBranch}
                      >
                        {this.props.listBranch.map((data: any, index: any) => (
                          <Option key={index} value={data.id} label={data.name}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} style={{ paddingLeft: '20px' }}>
                    <div>
                      <span>
                        <b>Pelanggan</b>
                      </span>
                      <Form.Item>
                        <Select
                          loading={this.props.loadingSelectCustomer}
                          style={{ width: '100%' }}
                          optionFilterProp="children"
                          disabled={false}
                          showSearch
                          placeholder={'Pilih Pelanggan'}
                          onSelect={this.props.handleResetCustomer}
                          onChange={this.props.handlePutCustomer}
                          onBlur={this.props.handleBlurCustomer}
                          value={
                            this.props.IdCustomer === null ? (
                              <span style={{ color: '#969696' }}>
                                Pilih Pelanggan
                              </span>
                            ) : (
                              this.props.IdCustomer
                            )
                          }
                          onFocus={this.props.handleGetCustomer}
                        >
                          {this.props.listCustomer.map(
                            (data: any, index: any) => (
                              <Option
                                key={index}
                                value={data.id}
                                label={data.name}
                              >
                                {data.name}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                    <div>
                      <span>
                        <b>Produk</b>
                      </span>
                      <Form.Item
                        validateStatus={this.props.validateCustomerProduct}
                        help={this.props.helpValidateCustomerProduct}
                      >
                        <Select
                          loading={this.props.loadingSelectCustomerProduct}
                          mode="multiple"
                          value={this.props.IdCustomerProduct}
                          disabled={
                            this.props.IdCustomer === null ? true : false
                          }
                          placeholder="Pilih Produk Pelanggan"
                          showSearch
                          allowClear={true}
                          onFocus={this.props.handleGetCustomerProduct}
                          onDeselect={this.props.handleBlurCustomerProduct}
                          onSelect={this.props.handleBlurCustomerProduct}
                          onBlur={this.props.handlePutCustomerProduct}
                          onChange={this.props.handlePutCustomerProduct}
                          style={{ width: '100%' }}
                        >
                          {this.props.listCustomerProduct.length ===
                          this.props.IdCustomerProduct.length ? null : (
                            <Option key={''} value={'SetAllProduct'}>
                              {'Pilih Semua Produk'}
                            </Option>
                          )}
                          {this.props.listCustomerProduct.map(
                            (data: any, index: any) => (
                              <Option key={index} value={data.id}>
                                {data.name}
                              </Option>
                            )
                          )}
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}></Col>
                  <Col
                    span={12}
                    style={{ paddingLeft: '10px', marginBottom: 20 }}
                  >
                    <ButtonMaterial
                      disabled={false}
                      handleSubmit={this.props.handleModal}
                      teksButton={<u>Cetak berdasarkan waybill</u>}
                      size={''}
                      shape={''}
                      className={''}
                      type={'link'}
                      icon={''}
                      style={{
                        color: '#11BEFF',
                        fontWeight: 'bold',
                      }}
                    />
                  </Col>
                </Row>

                <Row>
                  <div style={{ marginLeft: 20, float: 'right' }}>
                    <ButtonMaterial
                      disabled={validateSearch}
                      handleSubmit={this.props.handleSearch}
                      teksButton={<b>Cari</b>}
                      size={'middle'}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={
                        validateSearch === true
                          ? {
                              float: 'right',
                              height: '30px',
                              width: '65px',
                            }
                          : {
                              background: '#11BEFF',
                              borderColor: '#11BEFF',
                              float: 'right',
                              height: '30px',
                              width: '65px',
                              color: '#FFFFFF',
                            }
                      }
                    />
                  </div>
                  <div>
                    <ButtonMaterial
                      disabled={validateReset}
                      handleSubmit={this.props.handleReset}
                      teksButton={<b>Reset</b>}
                      size={'middle'}
                      shape={'round'}
                      className={''}
                      type={'primary'}
                      icon={''}
                      style={
                        validateReset === true
                          ? {
                              height: '30px',
                              float: 'right',
                              width: '70px',
                            }
                          : {
                              float: 'right',
                              background: '#FF5858',
                              borderColor: '#FF5858',
                              colorFont: '#FFFFFF',
                              height: '30px',
                              width: '70px',
                            }
                      }
                    />
                  </div>
                </Row>
              </div>
            </div>
          }
        />
        <div style={{ marginTop: 30 }}>
          <TablePrintWaybillContainer />
        </div>
      </React.Fragment>
    );
  }
}
