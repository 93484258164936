import {Action} from 'redux';

const initialState = 
{
    Properties:{
        IdFocusDelivery:null,
        WaybillFocusDelivery:null,
        ExportDeliveryStatus:null,
        SearchTypeSubmit:null,
    },
    DeliveryStatusBarcode:[],
    CycleDS:null,
    SearchTypeDS:'waybill',
    FilterTypeDS:null,
    ValueTypeDS:null,
    DistrictDS:null,
    Dimention:null,
    Attempt:null,
    ReceiptNumber:null,
    TotalGroupDocument:null,
    TotalWeight:null,
    Koli:null,
    DataDocument:null,
    Waybill:null,
    WaybillForm:null,
    ProcessTime:null,
    DeliveryTime:null,
    FinishedTime:null,
    DateEstimation:null,
    Barcode:null,
    DocumentType:null,
    DeliveryType:null,
    DeliveryPosition:null,
    CourierCode:null,
    CourierName:null,
    OwnerName:null,
    NoTelp:null,
    Address:null,
    City:null,
    ZipCode:null,
    DeliveryDuration:null,
    Image:null,
    Signature:null,
    ImageHistory:null,
    ProductImage:null,
    SignatureHistory:null,
    StatusDelivery:null,
    ReceiverName:null,
    ReceiverTitle:null,
    ReturnedReason:null,
    ReceiverLocation:null,
    AdditionalData:null,
    Complaint:null,
    OtherData:[],
    History:[]
}

export interface IAuthAction extends Action<string>
{

}

export interface ISetDeliveryStatusBarcode extends IAuthAction
{
    DeliveryStatusBarcode : any
}
export interface IChangeCycleDS extends IAuthAction
{
    CycleDS : any
}
export interface IChangeSearchTypeDS extends IAuthAction
{
    SearchTypeDS : any
}
export interface IChangeFilterTypeDS extends IAuthAction
{
    FilterTypeDS : any
}
export interface IChangeValueTypeDS extends IAuthAction
{
    ValueTypeDS : any
}
export interface IChangeDistrictDS extends IAuthAction
{
    DistrictDS : any
}
export interface IChangeAttempt extends IAuthAction
{
    Attempt : any
}
export interface IChangeReceiptNumber extends IAuthAction
{
    ReceiptNumber : any
}
export interface IChangeDimention extends IAuthAction
{
    Dimention : any
}
export interface IChangeTotalGroupDocument extends IAuthAction
{
    TotalGroupDocument : any
}
export interface IChangeTotalWeight extends IAuthAction
{
    TotalWeight : any
}
export interface IChangeKoli extends IAuthAction
{
    Koli : any
}
export interface IChangeDataDocument extends IAuthAction
{
    DataDocument : any
}
export interface IChangeWaybill extends IAuthAction
{
    Waybill : any
}
export interface IChangeWaybillForm extends IAuthAction
{
    WaybillForm : any
}
export interface IChangeProcessTime extends IAuthAction
{
    ProcessTime : any
}
export interface IChangeDeliveryTime extends IAuthAction
{
    DeliveryTime : any
}
export interface IChangeFinishedTime extends IAuthAction
{
    FinishedTime : any
}
export interface IChangeDateEstimation extends IAuthAction
{
    DateEstimation : any
}
export interface IChangeBarcode extends IAuthAction
{
    Barcode : any
}
export interface IChangeDocumentType extends IAuthAction
{
    DocumentType : any
}
export interface IChangeDeliveryType extends IAuthAction
{
    DeliveryType : any
}
export interface IChangeDeliveryPosition extends IAuthAction
{
    DeliveryPosition : any
}
export interface IChangeCourierCode extends IAuthAction
{
    CourierCode : any
}
export interface IChangeCourierName extends IAuthAction
{
    CourierName : any
}
export interface IChangeOwnerName extends IAuthAction
{
    OwnerName : any
}
export interface IChangeNoTelp extends IAuthAction
{
    NoTelp : any
}
export interface IChangeAddress extends IAuthAction
{
    Address : any
}
export interface IChangeCity extends IAuthAction
{
    City : any
}
export interface IChangeZipCode extends IAuthAction
{
    ZipCode : any
}
export interface IChangeDeliveryDuration extends IAuthAction
{
    DeliveryDuration : any
}
export interface IChangeImage extends IAuthAction
{
    Image : any
}
export interface IChangeSignature extends IAuthAction
{
    Signature : any
}
export interface IChangeImageHistory extends IAuthAction
{
    ImageHistory : any
}
export interface IChangeSignatureHistory extends IAuthAction
{
    SignatureHistory : any
}
export interface IChangeProductImage extends IAuthAction
{
    ProductImage : any
}
export interface IChangeStatusDelivery extends IAuthAction
{
    StatusDelivery : any
}
export interface IChangeReceiverName extends IAuthAction
{
    ReceiverName : any
}
export interface IChangeReceiverTitle extends IAuthAction
{
    ReceiverTitle : any
}
export interface IChangeReturnedReason extends IAuthAction
{
    ReturnedReason : any
}
export interface IChangeReceiverLocation extends IAuthAction
{
    ReceiverLocation : any
}
export interface IChangeAdditionalData extends IAuthAction
{
    AdditionalData : any
}
export interface IChangeComplaint extends IAuthAction
{
    Complaint : any
}
export interface IChangeOtherData extends IAuthAction
{
    OtherData : any
}
export interface IChangeHistory extends IAuthAction
{
    History : any
}
export interface IChangeIdFocusDelivery extends IAuthAction
{
    IdFocusDelivery : any
}
export interface IChangeWaybillFocusDelivery extends IAuthAction
{
    WaybillFocusDelivery : any
}
export interface IChangeExportDeliveryStatus extends IAuthAction
{
    ExportDeliveryStatus : any
}
export interface IChangeSearchTypeSubmit extends IAuthAction
{
    SearchTypeSubmit : any
}
export default function AuthReducerDetailId(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "SET_DELIVERY_STATUS_BARCODE":
            const setDeliveryStatusBarcode = action as ISetDeliveryStatusBarcode
            newState.DeliveryStatusBarcode = setDeliveryStatusBarcode.DeliveryStatusBarcode
            
        return {...newState}
        case "CHANGE_CYCLE_DS":
            const changeCycleDS = action as IChangeCycleDS
            newState.CycleDS = changeCycleDS.CycleDS
            
        return {...newState}
        case "CHANGE_SEARCH_TYPE_DS":
            const changeSearchTypeDS = action as IChangeSearchTypeDS
            newState.SearchTypeDS = changeSearchTypeDS.SearchTypeDS
            
        return {...newState}
        case "SET_FILTER_TYPE_DS":
            const changeFilterType = action as IChangeFilterTypeDS
            newState.FilterTypeDS = changeFilterType.FilterTypeDS
            
        return {...newState}
        case "SET_VALUE_TYPE_DS":
            const changeValueType = action as IChangeValueTypeDS
            newState.ValueTypeDS = changeValueType.ValueTypeDS
            
        return {...newState}
        case "SET_DISTRICT_DS":
            const changeDistrictDS = action as IChangeDistrictDS
            newState.DistrictDS = changeDistrictDS.DistrictDS
            
        return {...newState}
        case "SET_ATTEMPT":
            const changeAttempt = action as IChangeAttempt
            newState.Attempt = changeAttempt.Attempt
            
        return {...newState}
        case "SET_RECEIPT_NUMBER_DS":
            const changeReceiptNumber = action as IChangeReceiptNumber
            newState.ReceiptNumber = changeReceiptNumber.ReceiptNumber
            
        return {...newState}
        case "SET_DIMENTION":
            const changeDimention = action as IChangeDimention
            newState.Dimention = changeDimention.Dimention
            
        return {...newState}
        case "SET_TOTAL_GROUP_DOCUMENT":
            const changeTotalGroupDocument = action as IChangeTotalGroupDocument
            newState.TotalGroupDocument = changeTotalGroupDocument.TotalGroupDocument
            
        return {...newState}
        case "SET_TOTAL_WEIGHT":
            const changeTotalWeight = action as IChangeTotalWeight
            newState.TotalWeight = changeTotalWeight.TotalWeight
            
        return {...newState}
        case "SET_KOLI":
            const changeKoli = action as IChangeKoli
            newState.Koli = changeKoli.Koli
            
        return {...newState}
        case "SET_DATA_DOCUMENT_DS":
            const changeDataDocument = action as IChangeDataDocument
            newState.DataDocument = changeDataDocument.DataDocument
            
        return {...newState}
        case "SET_WAYBILL":
            const changeWaybill = action as IChangeWaybill
            newState.Waybill = changeWaybill.Waybill
            
        return {...newState}
        case "SET_WAYBILL_FORM":
            const changeWaybillForm = action as IChangeWaybillForm
            newState.WaybillForm = changeWaybillForm.WaybillForm
            
        return {...newState}
        case "SET_PROCESS_TIME":
            const changeProcessTime = action as IChangeProcessTime
            newState.ProcessTime = changeProcessTime.ProcessTime
            
        return {...newState}
        case "SET_DELIVERY_TIME":
            const changeDeliveryTime = action as IChangeDeliveryTime
            newState.DeliveryTime = changeDeliveryTime.DeliveryTime
            
        return {...newState}
        case "SET_FINISHED_TIME":
            const changeFinishedTime = action as IChangeFinishedTime
            newState.FinishedTime = changeFinishedTime.FinishedTime
            
        return {...newState}
        case "SET_DATE_ESTIMATION":
            const changeDateEstimation = action as IChangeDateEstimation
            newState.DateEstimation = changeDateEstimation.DateEstimation
            
        return {...newState}
        case "SET_BARCODE":
            const changeBarcode = action as IChangeBarcode
            newState.Barcode = changeBarcode.Barcode
            
        return {...newState}
        case "SET_DOCUMENT_TYPE":
            const changeDocumentType = action as IChangeDocumentType
            newState.DocumentType = changeDocumentType.DocumentType
            
        return {...newState}
        case "SET_DELIVERY_TYPE":
            const changeDeliveryType = action as IChangeDeliveryType
            newState.DeliveryType = changeDeliveryType.DeliveryType
            
        return {...newState}
        case "SET_DELIVERY_POSITION":
            const changeDeliveryPosition = action as IChangeDeliveryPosition
            newState.DeliveryPosition = changeDeliveryPosition.DeliveryPosition
            
        return {...newState}
        case "SET_COURIER_CODE":
            const changeCourierCode = action as IChangeCourierCode
            newState.CourierCode = changeCourierCode.CourierCode
            
        return {...newState}
        case "SET_COURIER_NAME":
            const changeCourierName = action as IChangeCourierName
            newState.CourierName = changeCourierName.CourierName
            
        return {...newState}
        case "SET_OWNER_NAME":
            const changeOwnerName = action as IChangeOwnerName
            newState.OwnerName = changeOwnerName.OwnerName
            
        return {...newState}
        case "SET_NO_TELP":
            const changeNoTelp = action as IChangeNoTelp
            newState.NoTelp = changeNoTelp.NoTelp
            
        return {...newState}
        case "SET_ADDRESS":
            const changeAddress = action as IChangeAddress
            newState.Address = changeAddress.Address
            
        return {...newState}
        case "SET_CITY":
            const changeCity = action as IChangeCity
            newState.City = changeCity.City
            
        return {...newState}
        case "SET_ZIP_CODE":
            const changeZipCode = action as IChangeZipCode
            newState.ZipCode = changeZipCode.ZipCode
            
        return {...newState}
        case "SET_DELIVERY_DURATION":
            const changeDeliveryDuration = action as IChangeDeliveryDuration
            newState.DeliveryDuration = changeDeliveryDuration.DeliveryDuration
            
        return {...newState}
        case "SET_IMAGE":
            const changeImage = action as IChangeImage
            newState.Image = changeImage.Image
            
        return {...newState}
        case "SET_SIGNATURE":
            const changeSignature = action as IChangeSignature
            newState.Signature = changeSignature.Signature
            
        return {...newState}
        case "SET_IMAGE_HISTORY":
            const changeImageHistory = action as IChangeImageHistory
            newState.ImageHistory = changeImageHistory.ImageHistory
            
        return {...newState}
        case "SET_SIGNATURE_HISTORY":
            const changeSignatureHistory = action as IChangeSignatureHistory
            newState.SignatureHistory = changeSignatureHistory.SignatureHistory
            
        return {...newState}
        case "SET_STATUS_DELIVERY":
            const changeStatusDelivery = action as IChangeStatusDelivery
            newState.StatusDelivery = changeStatusDelivery.StatusDelivery
            
        return {...newState}
        case "SET_RECEIVER_NAME":
            const changeReceiverName = action as IChangeReceiverName
            newState.ReceiverName = changeReceiverName.ReceiverName
            
        return {...newState}
        case "SET_RECEIVER_TITLE":
            const changeReceiverTitle = action as IChangeReceiverTitle
            newState.ReceiverTitle = changeReceiverTitle.ReceiverTitle
            
        return {...newState}
        case "SET_RETURNED_REASON":
            const changeReturnedReason = action as IChangeReturnedReason
            newState.ReturnedReason = changeReturnedReason.ReturnedReason
            
        return {...newState}
        case "SET_RECEIVER_LOCATION":
            const changeReceiverLocation = action as IChangeReceiverLocation
            newState.ReceiverLocation = changeReceiverLocation.ReceiverLocation
            
        return {...newState}

        case "SET_ADDITIONAL_DATA":
            const changeAdditionalData = action as IChangeAdditionalData
            newState.AdditionalData = changeAdditionalData.AdditionalData
            
        return {...newState}
        case "SET_COMPLAINT":
            const changeComplaint = action as IChangeComplaint
            newState.Complaint = changeComplaint.Complaint
            
        return {...newState}
        case "SET_HISTORY":
            const changeHistory = action as IChangeHistory
            newState.History = changeHistory.History
            
        return {...newState}
        case "SET_OTHER_DATA":
            const changeOtherData = action as IChangeOtherData
            newState.OtherData = changeOtherData.OtherData
            
        return {...newState}
        case "SET_PRODUCT_IMAGE":
            const changeProductImage = action as IChangeProductImage
            newState.ProductImage = changeProductImage.ProductImage
            
        return {...newState}
        case "SET_ID_FOCUS_DELIVERY":
            const changeIdFocusDelivery = action as IChangeIdFocusDelivery
            newState.Properties.IdFocusDelivery = changeIdFocusDelivery.IdFocusDelivery
            
        return {...newState}
        case "SET_WAYBILL_FOCUS_DELIVERY":
            const changeWaybillFocusDelivery = action as IChangeWaybillFocusDelivery
            newState.Properties.WaybillFocusDelivery = changeWaybillFocusDelivery.WaybillFocusDelivery
            
        return {...newState}
        case "SET_EXPORT_DELIVERY_STATUS":
            const changeExportDeliveryStatus = action as IChangeExportDeliveryStatus
            newState.Properties.ExportDeliveryStatus = changeExportDeliveryStatus.ExportDeliveryStatus
            
        return {...newState}
        case "SET_SEARCH_TYPE_SUBMIT":
            const changeSearchTypeSubmit = action as IChangeSearchTypeSubmit
            newState.Properties.SearchTypeSubmit = changeSearchTypeSubmit.SearchTypeSubmit
            
        return {...newState}
        case "CLEAR_FOCUS_DELIVERY":
            newState.Properties.IdFocusDelivery=null
            newState.Properties.WaybillFocusDelivery=null
        return {...newState}
        case "CLEAR_IMAGE_HISTORY":
            newState.ImageHistory=null
        return {...newState}
        case "CLEAR_SIGNATURE_HISTORY":
            newState.SignatureHistory=null
        return {...newState}
        case "CLEAR_DELIVERY_STATUS_BARCODE":
            newState.DeliveryStatusBarcode=[]
        return {...newState}
        case "CLEAR_WAYBILL_DS":
            newState.Waybill=null
        return {...newState}
        case "CLEAR_DELIVERY_TIME_ALL":
            newState.ProcessTime=null
            newState.DeliveryTime=null
            newState.FinishedTime=null
        return {...newState}
        case "CLEAR_DELIVERY_STATUS":
            newState.CycleDS=null
            newState.SearchTypeDS='waybill'
            newState.FilterTypeDS=null
            newState.ValueTypeDS=null
            newState.DistrictDS=null
            newState.Dimention=null
            newState.ReceiptNumber=null
            newState.Attempt=null
            newState.TotalGroupDocument=null
            newState.TotalWeight=null
            newState.DataDocument=null
            newState.Waybill=null
            newState.WaybillForm=null
            newState.ProcessTime=null
            newState.DeliveryTime=null
            newState.FinishedTime=null
            newState.DateEstimation=null
            newState.Barcode=null
            newState.DocumentType=null
            newState.DeliveryType=null
            newState.DeliveryPosition=null
            newState.CourierCode=null
            newState.CourierName=null
            newState.OwnerName=null
            newState.NoTelp=null
            newState.Address=null
            newState.City=null
            newState.ZipCode=null
            newState.DeliveryDuration=null
            newState.Image=null
            newState.Signature=null
            newState.ProductImage=null
            newState.StatusDelivery=null
            newState.ReceiverName=null
            newState.ReceiverTitle=null
            newState.ReturnedReason=null
            newState.ReceiverLocation=null
            newState.AdditionalData=null
            newState.Complaint=null
            newState.OtherData=[]
            newState.History=[]
            newState.Properties.IdFocusDelivery=null
            newState.Properties.WaybillFocusDelivery=null
            newState.Properties.ExportDeliveryStatus=null
            newState.Properties.SearchTypeSubmit=null
            
        return {...newState}

        case "CLEAR_DELIVERY_STATUS_EXCEPT_WAYBILL":
            newState.ProcessTime=null
            newState.DeliveryTime=null
            newState.FinishedTime=null
            newState.Dimention=null
            newState.TotalGroupDocument=null
            newState.TotalWeight=null
            newState.DateEstimation=null
            newState.FilterTypeDS=null
            newState.ValueTypeDS=null
            newState.DistrictDS=null
            newState.Barcode=null
            newState.DataDocument=null
            newState.WaybillForm=null
            newState.DocumentType=null
            newState.DeliveryType=null
            newState.DeliveryPosition=null
            newState.CourierCode=null
            newState.CourierName=null
            newState.OwnerName=null
            newState.NoTelp=null
            newState.Address=null
            newState.City=null
            newState.ZipCode=null
            newState.DeliveryDuration=null
            newState.Image=null
            newState.Signature=null
            newState.ProductImage=null
            newState.StatusDelivery=null
            newState.ReceiverName=null
            newState.ReceiverTitle=null
            newState.ReturnedReason=null
            newState.ReceiverLocation=null
            newState.AdditionalData=null
            newState.Complaint=null
            newState.OtherData=[]
            newState.History=[]
            newState.Properties.IdFocusDelivery=null
            newState.Properties.WaybillFocusDelivery=null
            
        return {...newState}
    }
    return {...newState}
}