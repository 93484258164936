import React, { useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { SelectOptionsInterface } from 'interface/general';
import { useQuery, useMutation } from '@tanstack/react-query';
import PerformaPoskoComponents from '../../components/PerformaPosko';
import { getAllCustomers } from 'services/GeneralService';
import { getProductByCustomers } from 'services/GeneralService';
import {
  getDataTablePerformanceBranch,
  downloadPerformanceBranch,
} from 'services/PerformanceReportService';
import ModalStatus from 'materials/ModalStatus';
import { downloadBlob, processProgressRequest, getParams } from 'lib/util';
import ModalDownloadProgress from 'materials/ModalDownloadProgress';
import moment from 'moment-timezone';
import { Modal } from 'antd';

type PerformaPoskoProps = {
  dataAllBranch: Array<any>;
  isLoadingBranch?: boolean;
  IdBranch: number;
  roleAccount: string;
  GetUsername: string;
};

export type FilterPerformaPoskoProps = {
  startDate: any;
  endDate: any;
  customerId?: number | null;
  productId?: number[];
  branchId?: number[];
  searchValue?: string;
};

export default function PerformaPosko({
  dataAllBranch,
  isLoadingBranch,
  IdBranch,
  roleAccount,
  GetUsername,
}: PerformaPoskoProps) {
  const isBranch = roleAccount === 'Branch';
  const defaultFilter = {
    startDate: null,
    endDate: null,
    customerId: null,
    productId: [],
    branchId: [],
    searchValue: '',
  };

  const datePeriod = getParams('datePeriod');
  const dateUntil = getParams('dateUntil');
  const customer = getParams('customer');
  const branch = getParams('branch');
  const typeBranch = getParams('typeBranch');

  const [dataFilter, setDataFilter] =
    useState<FilterPerformaPoskoProps>(defaultFilter);
  const [dataFilterStore, setDataFilterStore] =
    useState<FilterPerformaPoskoProps>(defaultFilter);

  const [dataCustomers, setDataCustomers] = useState<SelectOptionsInterface[]>(
    []
  );
  const [dataProducs, setDataProducs] = useState<SelectOptionsInterface[]>([]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const progressHandler = processProgressRequest(setLoadingProgress);

  const { data: dataTable, isFetching: isLoadingTable } = useQuery(
    ['GetTablePerformancePosko', dataFilter],
    () => getDataTablePerformanceBranch({ ...dataFilter }),
    {
      onSuccess: (res: any) => {
        if (res.status !== 200) {
          ModalStatus({
            status: 'error',
            title: 'Terjadi kesalahan',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      enabled: !!(dataFilter.startDate && dataFilter.endDate),
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: mutateDownloadList, isLoading: isLoadingDownload } =
    useMutation(
      (data: any) => downloadPerformanceBranch(data, progressHandler),
      {
        onSuccess: res => {
          if (res.status === 200) {
            downloadBlob(
              res.data,
              `PerformaPosko_${moment(res.startDate).format(
                'YYYYMMDD'
              )}-${moment(res.endDate).format('YYYYMMDD')}`,
              'xlsx'
            );
          } else {
            ModalStatus({
              status: 'error',
              title: 'Terjadi Kesalahan',
              content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
            });
          }
        },
        onError: () => {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
          });
        },
      }
    );

  const { isLoading: isLoadingCustomers } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataCustomers'],
    queryFn: () => getAllCustomers(),
    onSuccess(data) {
      const datas = [] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataCustomers(datas);
    },
    refetchOnWindowFocus: false,
  });

  const { isFetching: isLoadingProducts } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataProducts', { id: dataFilterStore.customerId }],
    queryFn: ({ queryKey }) => getProductByCustomers(queryKey),
    onSuccess(data) {
      const datas = [] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataProducs(datas);
    },
    enabled: !!dataFilterStore.customerId,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const getValueBranch = () => {
      if (typeBranch && dataAllBranch.length > 0) {
        if (typeBranch === 'vendor') {
          return dataAllBranch
            .filter(value => value.isPartner)
            .map(value => value.id);
        } else {
          return dataAllBranch
            .filter(value => !value.isPartner)
            .map(value => value.id);
        }
      } else if (branch !== 'null' && branch) {
        return [+branch];
      }
      return [];
    };

    const startDate = datePeriod ? moment(datePeriod, 'DD-MM-YYYY') : null;
    const endDate = dateUntil ? moment(dateUntil, 'DD-MM-YYYY') : null;
    const customerId = customer !== 'null' && customer ? +customer : null;
    const branchId = getValueBranch();

    const newDataFilter = {
      startDate,
      endDate,
      customerId,
      branchId,
    };

    setDataFilter(prevState => ({ ...prevState, ...newDataFilter }));
    setDataFilterStore(prevState => ({ ...prevState, ...newDataFilter }));
  }, [datePeriod, dateUntil, customer, branch, typeBranch, dataAllBranch]);

  useEffect(() => {
    if (loadingProgress === 100) {
      Modal.destroyAll();
      setLoadingProgress(0);
    }
  }, [loadingProgress]);

  useEffect(() => {
    if (isBranch) {
      setDataFilterStore(prevState => ({
        ...prevState,
        branchId: [IdBranch],
      }));
      setDataFilter(prevState => ({
        ...prevState,
        branchId: [IdBranch],
      }));
    }
  }, [isBranch, IdBranch]);

  const handleChangeFilter = (name: string, value: any) => {
    if (name === 'searchValue') {
      setDataFilter((prevState: FilterPerformaPoskoProps) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setDataFilterStore((prevState: FilterPerformaPoskoProps) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleResetFilter = () => {
    setDataFilterStore(prevState => ({
      ...defaultFilter,
      branchId: isBranch ? [IdBranch] : [],
    }));
    setDataFilter(prevState => ({
      ...defaultFilter,
      branchId: isBranch ? [IdBranch] : [],
    }));
  };

  const handleSubmitFilter = (value: FilterPerformaPoskoProps) => {
    setDataFilter(value);
  };

  const handleDownload = () => {
    mutateDownloadList(dataFilter);
  };

  return (
    <>
      <PerformaPoskoComponents
        dataAllBranch={dataAllBranch}
        isLoadingBranch={isLoadingBranch}
        selectedBranchId={IdBranch}
        roleAccount={roleAccount}
        handleChangeFilter={handleChangeFilter}
        handleResetFilter={handleResetFilter}
        handleSubmitFilter={handleSubmitFilter}
        initialValue={dataFilterStore}
        dataCustomers={dataCustomers}
        dataProducts={dataProducs}
        isLoadingCustomers={isLoadingCustomers}
        isLoadingProducts={isLoadingProducts}
        dataTable={dataTable?.data || null}
        isLoadingTable={isLoadingTable || isLoadingDownload}
        handleDownload={handleDownload}
      />
      <ModalDownloadProgress
        loading={isLoadingDownload}
        loadingProgress={loadingProgress}
      />
    </>
  );
}
