import React, { Component } from 'react';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import FormAdminComponent from './FormAdminComponent';
import { FormAdminType } from './FormAdminComponent';

interface IProps {
  visible: any;
  jenisToggle: any;

  isLoading: any;
  isView?: boolean;
  handleChange: (name: string, value: any) => void;
  handleClear: () => void;
  initialData: FormAdminType;
  handleSubmit?: () => void;
}

export default class EditAdminBranchComponent extends Component<IProps> {
  render() {
    return (
      <div>
        <ModalMaterial
          className={''}
          width={'80%'}
          style={{}}
          teksTitle={'UBAH DATA ADMIN'}
          visible={this.props.visible}
          onCancel={this.props.handleClear}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={<FormAdminComponent {...this.props} />}
            />
          }
        />
      </div>
    );
  }
}
