// DY: DEV CHANGES 4 JUL 24

import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  SelectOptionsInterface,
  DetailManifestBrankasProps,
} from 'interface/general';
import {
  getManifestBrankas,
  updateManifestBrankas,
} from 'services/ManifestSaveBrankas';
import FormManifestBrankas from '../components/FormManifestBrankas';
import ModalStatus from 'materials/ModalStatus';
import moment from 'moment-timezone';

type ModalEditManifestBrankasProps = {
  isShowModalEdit: number | null;
  setIsShowModalEdit: () => void;
  dataManifestRetur: SelectOptionsInterface[];
  dataCustomers: SelectOptionsInterface[];
  loadingManifestRetur: boolean;
  onClickAddRetur: () => void;
  refetchTable: () => void;
};

export type DataManifestBrankasProps = {
  cycleDate: any | null;
  isMonthly: boolean;
  bundleCode: string;
  type: string;
  maxBundle: number;
  customer: number | null;
  customerProducts: number[];
  manifestSafeId: number | null;
};

const initialValues = {
  cycleDate: null,
  isMonthly: false,
  bundleCode: '',
  type: 'Return',
  maxBundle: 1,
  customer: null,
  customerProducts: [],
  manifestSafeId: null,
};

export default function ModalEditManifestBrankas({
  isShowModalEdit,
  setIsShowModalEdit,
  dataManifestRetur,
  loadingManifestRetur,
  onClickAddRetur,
  dataCustomers,
  refetchTable,
}: ModalEditManifestBrankasProps) {
  const [dataManifestBrankas, setDataManifestBrankas] =
    useState<DataManifestBrankasProps>(initialValues);

  const { mutate: submitManifestBrankas, isLoading: isLoadingSubmitForm } =
    useMutation(updateManifestBrankas, {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          ModalStatus({
            status: 'success',
            title: 'Data berhasil diupdate',
            handleOke: () => {},
          });
          setIsShowModalEdit();
          refetchTable();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Gagal Proses',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const { isFetching: isLoadingDetail, data: dataManifest } = useQuery<
    AxiosResponse<DetailManifestBrankasProps>
  >(
    ['DataDetailManifest', isShowModalEdit],
    () => getManifestBrankas(isShowModalEdit),
    {
      onSuccess(data) {
        const response = data.data;
        setDataManifestBrankas({
          cycleDate: moment(response.cycleDate),
          isMonthly: response.isMonthly,
          bundleCode: response.bundleCode,
          type: response.brankasType,
          maxBundle: response.maxBundle,
          customer: response.customerId,
          customerProducts: response.customerProductIds,
          manifestSafeId: response.manifestSafeCodeId,
        });
      },
      enabled: !!isShowModalEdit,
      refetchOnWindowFocus: false,
    }
  );

  const handleChangeField = (name: string, value: any) => {
    setDataManifestBrankas(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (value: DataManifestBrankasProps) => {
    submitManifestBrankas({
      id: dataManifest?.data.id,
      maxBundle: value.maxBundle,
      manifestSafeId: value.manifestSafeId,
    });
  };

  return (
    <FormManifestBrankas
      isShowModalAdd={!!isShowModalEdit}
      setIsShowModalAdd={setIsShowModalEdit}
      dataManifestRetur={dataManifestRetur}
      loadingManifestRetur={loadingManifestRetur}
      onClickAddRetur={onClickAddRetur}
      dataCustomers={dataCustomers}
      dataManifestBrankas={dataManifestBrankas}
      handleChangeField={handleChangeField}
      isLoadingForm={isLoadingSubmitForm || isLoadingDetail}
      handleSubmit={handleSubmit}
      isEdit
    />
  );
}
