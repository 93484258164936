import * as ActionDeliveryStatus from 'module/DeliveryStatus/Store/ActionSetDeliveryStatus';
import * as ActionToggle from 'core/action/toggle/index';

import { bindActionCreators, compose } from 'redux';

import React from 'react';
import TrackingCardBarcodeComponent from 'module/DeliveryStatus/Component/TrackingCardBarcodeComponent';
import { connect } from 'react-redux';

interface IProps {
  History: any;
  visible: any;
  typeToggle: any;
  getImageHistory: any;
  getSignatureHistory: any;
  actionToggle: any;
  actionDeliveryStatus: any;
  index?: any;
}
function TrackingCardBarcodeContainer({
  History,
  visible,
  typeToggle,
  getImageHistory,
  getSignatureHistory,
  actionToggle,
  actionDeliveryStatus,
  index,
}: IProps) {
  function handleModalImage(event: any) {
    actionDeliveryStatus.setImageHistory(event);
    actionToggle.changeModal();
    actionToggle.changeJenisToggle('imageBarcodeHistory');
  }
  function handleModalSignature(event: any) {
    actionDeliveryStatus.setSignatureHistory(event);
    actionToggle.changeModal();
    actionToggle.changeJenisToggle('signatureBarcodeHistory');
  }
  function handleCloseModal() {
    actionToggle.changeModal();
    actionToggle.clearJenisToggle();
    actionDeliveryStatus.clearImageHistory();
    actionDeliveryStatus.clearSignatureHistory();
  }
  return (
    <div>
      <TrackingCardBarcodeComponent
        History={History}
        visible={visible}
        typeToggle={typeToggle}
        getImageHistory={getImageHistory}
        getSignatureHistory={getSignatureHistory}
        handleModalImage={handleModalImage}
        handleModalSignature={handleModalSignature}
        handleCloseModal={handleCloseModal}
        index={index}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  History: state.DeliveryStatus.DeliveryStatusBarcode,
  visible: state.Toggle.modal,
  typeToggle: state.Toggle.jenisToggle,
  getImageHistory: state.DeliveryStatus.ImageHistory,
  getSignatureHistory: state.DeliveryStatus.SignatureHistory,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionDeliveryStatus: bindActionCreators(ActionDeliveryStatus, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TrackingCardBarcodeContainer);
