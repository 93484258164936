export function addActivationProductVoip() {
    return {
      type: 'ADD_ACTIVATION_PRODUCT_VOIP',
    };
}
export function getListActivationServerVoip() {
    return {
      type: 'GET_LIST_ACTIVATION_SERVER_VOIP',
    };
}
export function getDetailCustomerActivation() {
    return {
      type: 'GET_DETAIL_CUSTOMER_ACTIVATION',
    };
}
export function clearActivationVoip() {
    return {
      type: 'CLEAR_ACTIVATION_VOIP',
    };
}
export function clearProductActivationVoip() {
    return {
      type: 'CLEAR_PRODUCT_ACTIVATION_VOIP',
    };
}
export function changeToggleActivation(toggleActivation: any) {
    return {
      type: 'CHANGE_TOGGLE_ACTIVATION',
      toggleActivation,
    };
}
export function changeCustomerId(customerId: any) {
    return {
      type: 'CHANGE_CUSTOMER_ID_V',
      customerId,
    };
}
export function changeProductTypeV(productType: any) {
    return {
      type: 'CHANGE_PRODUCT_TYPE_V',
      productType,
    };
}