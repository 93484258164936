import { Button, Col, Row, Table } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../materials/ButtonMaterial';
import CardMaterial from '../../../materials/CardMaterial';
import SpinMaterial from '../../../materials/SpinMaterial';

const ButtonGroup = Button.Group;

interface IProps {
  GetCustomerName: any;
  GetReasonDeliveryStatus: any;
  dataSource: any;
  data: any;
  isLoading: any;
  page: any;
  handlePage: any;
}

export default class TableDataStatusDefaultComponent extends Component<IProps> {
  render() {
    const columns = [
      {
        title: <b>Kode</b>,
        dataIndex: 'code',
        render: (code: any) => <span style={{ color: 'red' }}>{code}</span>,
        key: 'code',
        width: 120,
      },
      {
        title: <b>Status</b>,
        dataIndex: 'description',
        key: 'description',
        width: 200,
      },
      {
        title: <b>Posisi</b>,
        dataIndex: 'position',
        render: (position: any) => (
          <span style={{ color: 'red' }}>{position}</span>
        ),
        key: 'position',
        width: 120,
      },
      {
        title: <b>Attempt</b>,
        dataIndex: 'attempt',
        render: (attempt: any) =>
          attempt === false ? (
            <span>False</span>
          ) : (
            <span style={{ color: 'red' }}>True</span>
          ),
        key: 'attempt',
        width: 120,
      },
      {
        title: <b>Aksi</b>,
        key: 'action',
        width: 130,
        render: (record: any) => (
          <ButtonGroup>
            <div style={{ float: 'left', paddingRight: '15px' }}>
              <ButtonMaterial
                disabled={true}
                handleSubmit={''}
                teksButton={''}
                size={''}
                shape={''}
                className={''}
                type={'primary'}
                icon={'edit'}
                style={{
                  borderRadius: '8px',
                }}
              />
            </div>
            <div style={{ float: 'right' }}>
              <ButtonMaterial
                disabled={true}
                handleSubmit={''}
                teksButton={''}
                size={''}
                shape={''}
                className={''}
                type={'primary'}
                icon={'delete'}
                style={{
                  borderRadius: '8px',
                }}
              />
            </div>
          </ButtonGroup>
        ),
      },
    ];
    const columns2 = [
      {
        title: <b>Kode</b>,
        dataIndex: 'code',
        render: (code: any) => <span style={{ color: 'red' }}>{code}</span>,
        key: 'code',
        width: 120,
      },
      {
        title: <b>Status</b>,
        dataIndex: 'description',
        key: 'description',
        width: 200,
      },
      {
        title: <b>Posisi</b>,
        dataIndex: 'position',
        render: (position: any) => (
          <span style={{ color: 'red' }}>{position}</span>
        ),
        key: 'position',
        width: 120,
      },
      {
        title: <b>Aksi</b>,
        key: 'action',
        width: 130,
        render: (record: any) => (
          <ButtonGroup>
            <div style={{ float: 'left', paddingRight: '15px' }}>
              <ButtonMaterial
                disabled={true}
                handleSubmit={''}
                teksButton={''}
                size={''}
                shape={''}
                className={''}
                type={'primary'}
                icon={'edit'}
                style={{
                  borderRadius: '8px',
                }}
              />
            </div>
            <div style={{ float: 'right' }}>
              <ButtonMaterial
                disabled={true}
                handleSubmit={''}
                teksButton={''}
                size={''}
                shape={''}
                className={''}
                type={'primary'}
                icon={'delete'}
                style={{
                  borderRadius: '8px',
                }}
              />
            </div>
          </ButtonGroup>
        ),
      },
    ];
    return (
      <div>
        <SpinMaterial
          spinning={this.props.isLoading}
          style={{}}
          size={'large'}
          content={
            <div>
              <CardMaterial
                extra={''}
                title={''}
                style={{}}
                content={
                  <div>
                    <Row>
                      <Col>
                        <p
                          style={{
                            float: 'left',
                            color: '#11BEFF',
                            fontWeight: 'bold',
                          }}
                        >
                          {this.props.GetCustomerName}
                        </p>
                        <div style={{ float: 'right' }}>
                          <ButtonMaterial
                            disabled={true}
                            handleSubmit={''}
                            teksButton={<b>STATUS DEFAULT</b>}
                            size={''}
                            shape={''}
                            className={''}
                            type={'link'}
                            icon={''}
                            style={{
                              color: '#11BEFF',
                              paddingRight: '15px',
                            }}
                          />

                          <ButtonMaterial
                            disabled={true}
                            handleSubmit={''}
                            teksButton={''}
                            size={''}
                            shape={'circle'}
                            className={''}
                            type={'primary'}
                            icon={'plus'}
                            style={{
                              paddingBottom: '3px',
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
              />
              <Table
                pagination={{
                  onChange: page => {
                    this.props.handlePage(page);
                  },
                  pageSize: 5,
                }}
                bordered={false}
                dataSource={this.props.data}
                columns={
                  this.props.GetReasonDeliveryStatus === 'Return'
                    ? columns
                    : columns2
                }
              />
            </div>
          }
        />
      </div>
    );
  }
}
