import * as ActionLogin from '../../../module/Login/Store';
import * as ActionSetDeliveryStatus from 'module/DeliveryStatus/Store/ActionSetDeliveryStatus';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_getDeliveryStatusBarcode() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
  };
  const barcode = state.DeliveryStatus.Waybill;
  const CycleDS = state.DeliveryStatus.CycleDS;
  try {
    yield put(changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      CycleDS === null || CycleDS === '' || CycleDS === 'Invalid date'
        ? process.env.REACT_APP_URL + '/Delivery/barcode/' + barcode
        : process.env.REACT_APP_URL +
            `/Delivery/barcode/${barcode}?cycle=${CycleDS}`,
      { headers: config }
    );

    yield put(ActionSetDeliveryStatus.setDeliveryStatusBarcode(data));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      yield put(ActionSetDeliveryStatus.clearDeliveryStatusBarcode());
      yield put(changeLoadingFalse());
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>Barcode yang anda cari tidak ada</p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>Silahkan coba lagi</p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* S_getDeliveryStatusFilter() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const filterValue = state.DeliveryStatus.Waybill;
  const filterType = state.DeliveryStatus.SearchTypeDS;
  try {
    yield put(changeLoadingTrue());

    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Delivery/waybill/' +
        filterValue +
        '/' +
        filterType,
      { headers: config }
    );

    yield put(ActionSetDeliveryStatus.setDeliveryStatusBarcode(data));
    yield put(changeLoadingFalse());
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      yield put(ActionSetDeliveryStatus.clearDeliveryStatusBarcode());
      yield put(changeLoadingFalse());

      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>Data yang anda cari tidak ada</p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>Silahkan coba lagi</p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_getDeliveryStatusFilter() {
  yield takeLatest('GET_DELIVERY_STATUS_FILTER', S_getDeliveryStatusFilter);
}
export function* SM_getDeliveryStatusBarcode() {
  yield takeLatest('GET_DELIVERY_STATUS_BARCODE', S_getDeliveryStatusBarcode);
}
