import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import JamPengirimanComponent from '../../components/JamPengiriman';
import {
  getDataTableHour,
  downloadHourDocument,
} from 'services/ReportCourierServices';
import { useQuery, useMutation } from '@tanstack/react-query';
import ModalStatus from 'materials/ModalStatus';
import { downloadBlob, processProgressRequest } from 'lib/util';
import ModalDownloadProgress from 'materials/ModalDownloadProgress';
import { Modal } from 'antd';

type SlaPengirimanProps = {
  dataAllBranch: Array<any>;
  isLoadingBranch?: boolean;
  IdBranch: number;
  roleAccount: string;
  GetUsername: string;
};

export type FilterDataJamProps = {
  dateSend: any;
  branch?: Array<number>;
  dateEnd: any;
  searchValue?: string;
};

export default function SlaPengiriman({
  dataAllBranch,
  isLoadingBranch,
  IdBranch,
  roleAccount,
  GetUsername,
}: SlaPengirimanProps) {
  const isBranch = roleAccount === 'Branch';
  const defaultDateSend = isBranch ? moment().subtract(1, 'weeks') : null;
  const defaultDateEnd = isBranch ? moment() : null;
  const defaultFilter = {
    dateSend: defaultDateSend,
    branch: [],
    dateEnd: defaultDateEnd,
    searchValue: '',
  };

  const [dataFilterJam, setDataFilterJam] =
    useState<FilterDataJamProps>(defaultFilter);
  const [page, setPage] = useState<number>(0);
  const [selectedDownloadJam, setSelectedDownloadJam] = useState<string>('');
  const [loadingProgress, setLoadingProgress] = useState(0);
  const progressHandler = processProgressRequest(setLoadingProgress);

  const { data: dataTable, isFetching: isLoadingTable } = useQuery(
    ['TableSla', page, dataFilterJam],
    () => getDataTableHour({ page, ...dataFilterJam }),
    {
      onSuccess: (res: any) => {
        if (res.data) {
          const response = res.data;
          if (response.data.length > 0) {
            ModalStatus({
              status: 'success',
              title: 'Pencarian Berhasil',
              content: `Data berhasil ditemukan (${response.totalDatas})`,
            });
          } else {
            ModalStatus({
              status: 'error',
              title: 'Tidak Ditemukan',
              content: 'Data yang Anda cari tidak ditemukan Mohon ulangi',
            });
          }
        }
      },
      enabled: isBranch
        ? !!(dataFilterJam.dateSend && dataFilterJam.dateEnd) &&
          !!(
            isBranch &&
            dataFilterJam.branch &&
            dataFilterJam.branch?.length > 0
          )
        : !!(dataFilterJam.dateSend && dataFilterJam.dateEnd),
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: mutateDownloadFile, isLoading: isLoadingDownload } =
    useMutation((data: any) => downloadHourDocument(data, progressHandler), {
      onSuccess: (res: any) => {
        if (res.status === 200) {
          downloadBlob(
            res.data,
            `JamPengiriman_${GetUsername}_${moment().format(
              'YYYYMMDD'
            )}_${moment().format('HHMMSS')}`,
            'xlsx'
          );
          ModalStatus({
            status: 'success',
            title: 'Download berhasil',
          });
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    });

  useEffect(() => {
    if (dataAllBranch.length > 0 && isBranch) {
      setDataFilterJam((prevState: FilterDataJamProps) => ({
        ...prevState,
        branch: [IdBranch],
      }));
    }
  }, [IdBranch, dataAllBranch, isBranch]);

  useEffect(() => {
    if (loadingProgress === 100) {
      Modal.destroyAll();
      setLoadingProgress(0);
    }
  }, [loadingProgress]);

  const handleChangeFilter = (name: string, value: any) => {
    setDataFilterJam((prevState: FilterDataJamProps) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitFilter = (value: FilterDataJamProps) => {
    setDataFilterJam({
      dateSend: value.dateSend,
      dateEnd: value.dateEnd,
      branch: value.branch || [],
      searchValue: dataFilterJam?.searchValue || '',
    });
  };

  const handleDownloadFile = () => {
    mutateDownloadFile({
      startDate: dataFilterJam.dateSend.format('DDMMYYYY'),
      endDate: dataFilterJam.dateEnd.format('DDMMYYYY'),
      valueSearch: dataFilterJam.searchValue,
      branch: dataFilterJam.branch,
    });
  };

  const handleResetFilter = () => {
    setDataFilterJam(prevState => ({
      ...defaultFilter,
      branch: isBranch ? [IdBranch] : [],
    }));
  };

  return (
    <>
      <JamPengirimanComponent
        dataAllBranch={dataAllBranch || []}
        isLoadingBranch={isLoadingBranch}
        IdBranch={IdBranch}
        roleAccount={roleAccount}
        dataFilterJam={dataFilterJam}
        dataTableJam={dataTable?.data || null}
        handleResetFilter={handleResetFilter}
        handleChangeFilter={handleChangeFilter}
        handleSubmitFilter={handleSubmitFilter}
        handlePage={value => setPage(value)}
        isLoadingTable={isLoadingTable || isLoadingDownload}
        handleSearchValue={value => handleChangeFilter('searchValue', value)}
        selectedDownloadJam={selectedDownloadJam}
        setSelectedDownloadJam={setSelectedDownloadJam}
        handleDownloadFile={handleDownloadFile}
      />
      <ModalDownloadProgress
        loading={isLoadingDownload}
        loadingProgress={loadingProgress}
      />
    </>
  );
}
