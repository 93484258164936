import { Icon } from 'antd';
import React from 'react';

interface IProps {
  type: any;
  className?: any;
  onClick?: any;
  theme?: any;
  style?: any;
}

export default function CIcon(props: IProps) {
  const { type, theme, className, onClick, style } = props;
  return (
    <Icon
      type={type}
      className={className}
      theme={theme}
      onClick={onClick}
      style={style}
    />
  );
}
