import { Checkbox, Col, Row } from 'antd';
import React, { Component } from 'react';

import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import moment from 'moment';

interface IProps {
  visible: any;
  handleClearDetailCourier: any;
  jenisToggle: any;

  GetCourierName: any;
  GetCourierLastName: any;
  GetCourierEmail: any;
  GetCourierPhoneNumber: any;
  GetCourierDob: any;
  GetCourierPhoto: any;
  GetCourierCode: any;
  GetCourierAddress: any;
  GetCourierStatus: any;
  GetBranchCourier: any;
  isLoading: any;
}

export default class AddAdminComponent extends Component<IProps> {
  render() {
    return (
      <div>
        <ModalMaterial
          className={''}
          width={'80%'}
          style={{}}
          teksTitle={'DETAIL KURIR'}
          visible={this.props.visible}
          onCancel={this.props.handleClearDetailCourier}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div className="spacingTopDefault">
                  <Row>
                    <Col span={12}>
                      <div className="spacingColForm">
                        <div className="spacingBottom">
                          <span>
                            <b>Nama Depan</b>
                          </span>
                          <br />
                          <FormInputTextMaterial
                            validateType={false}
                            value={this.props.GetCourierName}
                            className={'defaultDisableFrmInput'}
                            onPressEnter={''}
                            disabled={false}
                            addonBefore={''}
                            placeholder={''}
                            prefix={''}
                            maxLength={''}
                            style={{}}
                            size=""
                            type={''}
                            onChange={''}
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                          />
                        </div>

                        <div className={'spacingBottom'}>
                          <span>
                            <b>Kode Kurir</b>
                          </span>
                          <br />
                          <FormInputTextMaterial
                            validateType={false}
                            value={this.props.GetCourierCode}
                            className={'defaultDisableFrmInput'}
                            disabled={false}
                            addonBefore={''}
                            onPressEnter={''}
                            placeholder={''}
                            prefix={''}
                            maxLength={''}
                            style={{}}
                            size=""
                            type={''}
                            onChange={''}
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                          />
                        </div>

                        <div className={'spacingBottom'}>
                          <span>
                            <b>Tanggal Lahir</b>
                          </span>
                          <br />
                          <FormInputTextMaterial
                            validateType={false}
                            onPressEnter={''}
                            value={moment(this.props.GetCourierDob).format(
                              'DD/MM/YYYY'
                            )}
                            className={'datePickerDefault'}
                            disabled={false}
                            addonBefore={''}
                            placeholder={''}
                            prefix={''}
                            maxLength={''}
                            style={{}}
                            size=""
                            type={''}
                            onChange={''}
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                          />
                        </div>

                        <div className={'spacingBottom'}>
                          <span>
                            <b>Alamat Lengkap</b>
                          </span>
                          <br />
                          <FormInputTextMaterial
                            validateType={false}
                            onPressEnter={''}
                            value={this.props.GetCourierAddress}
                            className={'defaultDisableFrmInput'}
                            disabled={false}
                            addonBefore={''}
                            placeholder={''}
                            prefix={''}
                            maxLength={''}
                            style={{}}
                            size=""
                            type={''}
                            onChange={''}
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div className={'spacingColForm'}>
                        <div className={'spacingBottom'}>
                          <span>
                            <b>Nama Belakang</b>
                          </span>
                          <br />
                          <FormInputTextMaterial
                            validateType={false}
                            onPressEnter={''}
                            value={this.props.GetCourierLastName}
                            className={'defaultDisableFrmInput'}
                            disabled={false}
                            addonBefore={''}
                            placeholder={''}
                            prefix={''}
                            maxLength={''}
                            style={{}}
                            size=""
                            type={''}
                            onChange={''}
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                          />
                        </div>

                        <div className={'spacingBottom'}>
                          <span>
                            <b>Wilayah</b>
                          </span>
                          <br />
                          <FormInputTextMaterial
                            validateType={false}
                            onPressEnter={''}
                            value={this.props.GetBranchCourier}
                            className={'defaultDisableFrmInput'}
                            disabled={false}
                            addonBefore={''}
                            placeholder={''}
                            prefix={''}
                            maxLength={''}
                            style={{}}
                            size=""
                            type={''}
                            onChange={''}
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                          />
                        </div>

                        <div className={'spacingBottom'}>
                          <span>
                            <b>No Telp</b>
                          </span>
                          <br />
                          <FormInputTextMaterial
                            validateType={false}
                            value={this.props.GetCourierPhoneNumber}
                            className={'defaultDisableFrmInput'}
                            onPressEnter={''}
                            disabled={false}
                            addonBefore={'+62'}
                            placeholder={''}
                            prefix={''}
                            maxLength={''}
                            style={{}}
                            size=""
                            type={''}
                            onChange={''}
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                          />
                        </div>

                        <div className={'spacingBottom'}>
                          <br />
                          <Checkbox
                            disabled={true}
                            style={{}}
                            className={'spacingCheckboxDetailCourier'}
                            checked={this.props.GetCourierStatus}
                          />
                          <span className={'textCheckboxItalic'}>
                            Ceklis untuk aktifkan status akun
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
