import * as ActionAuth from '../../../../core/action/Auth';
import * as ActionComplaintReport from '../Store/ComplaintReportAction';
import * as ActionComponent from '../../../App/Store/ComponentAction';
import * as ActionDataGeneral from '../../../App/Store/GeneralDataAction';
import * as ActionLogin from '../../../Login/Store';
import * as SelectorsComplaintReport from '../Selector/ComplaintReportSelector';

import { Modal, message } from 'antd';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';
import { getFormValues } from 'redux-form';
import moment from 'moment';

function* S_getCustProductListComplaintReportProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const formValue = yield select(getFormValues('complaintReportForm'));
  try {
    yield put(ActionDataGeneral.loadingGetData('CustomerProduct'));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Customer/product/customer/${formValue.customer}`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComplaintReport.fetchCustomerProductList(data.data));
    yield put(ActionDataGeneral.loadingGetData('CustomerProduct'));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionComponent.processLoading(false));
  }
}

export function* S_downloadComplaintReportProcess(action: any) {
  const state = yield select();
  const formValue = yield select(getFormValues('complaintReportForm'));
  const startDate = moment(formValue.startDate).format('YYYYMMDD');
  const finishDate = moment(formValue.finishDate).format('YYYYMMDD');
  const formatReport = formValue.reportType === 'pdf' ? 'PDF' : 'ExcelXLSX ';
  const extention = formValue.reportType === 'pdf' ? '.zip' : '.xlsx';
  const allCustomer = formValue?.allCustomer || false;
  const customerName = yield select(
    SelectorsComplaintReport.makeCustomerNameSelector()
  );
  const key = 'download';
  function ResponseDownload(data: any) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    const file_name = `${
      customerName || 'SemuaPelanggan'
    }_${startDate} - ${finishDate}${extention}`;

    link.href = url;
    link.setAttribute('download', file_name);
    document.body.appendChild(link);
    link.click();
  }
  const token = state.Auth.Token;
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  try {
    yield put(ActionComponent.processLoading(true));

    const { data } = yield call(
      axios.post,
      `${process.env.REACT_APP_URL}/Complaint/DownloadReportComplaint`,
      {
        filterDate: formValue.dateType,
        startDate: startDate,
        endDate: finishDate,
        customerProductId: formValue.product,
        complaintStatus: formValue.complaintStatus,
        formatFileType: formatReport,
        customerAll: allCustomer,
      },
      {
        responseType: 'arraybuffer',
        headers: config,
        onDownloadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          let percentProgress = Math.floor((loaded * 100) / total);
          if (percentProgress < 100) {
            message.loading({
              content: `Loading ${percentProgress}%`,
              key,
            });
          } else if (percentProgress === 100) {
            Modal.destroyAll();
            message.success({
              content: 'Download Berhasil',
              key,
              duration: 1.5,
            });
          } else if (percentProgress === Infinity) {
            Modal.destroyAll();
            message.error({
              content: 'Download Gagal',
              key,
              duration: 3,
            });
          }
        },
      }
    );
    ResponseDownload(data);
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }
    yield put(ActionComponent.processLoading(false));
  } catch (error) {
    if (error.response.status === 500) {
      message.error({
        content: 'Download Gagal',
        key,
        duration: 3,
      });
    }
    yield put(ActionLogin.errorHandling(action, error));
  }
}

export function* SM_getCustProductListComplaintReportAction() {
  yield takeLatest(
    'COMPLAINT_REPORT_GET_CUSTOMER_PRODUCT_LIST_REQUESTED',
    S_getCustProductListComplaintReportProcess
  );
}
export function* SM_downloadComplaintReportAction() {
  yield takeLatest(
    'DOWNLOAD_COMPLAINT_REPORT_REQUESTED',
    S_downloadComplaintReportProcess
  );
}
