import * as ActionAuth from '../../action/Auth';
import * as ActionLogin from '../../../module/Login/Store';
import * as profile from '../../action/Profile/ActionProfile';
import * as toggle from '../../action/toggle/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_editProfile(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  const Photo = state.Profile.Photo;
  const FirstName = state.Profile.FirstName.toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');
  const LastName =
    state.Profile.LastName === null || state.Profile.LastName === ''
      ? ''
      : state.Profile.LastName.toLowerCase()
          .split(' ')
          .map(
            (word: string) => word.charAt(0).toUpperCase() + word.substring(1)
          )
          .join(' ');
  const UserName = state.Auth.UsernameAuth;
  const NoTelp = state.Profile.NoTelp;
  const Email = state.Profile.Email;
  const Address = state.Profile.Address;
  try {
    yield put(toggle.changeLoadingTrue());

    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/Profile/ProfileAccountUpdate',
      {
        code: UserName,
        firstName: FirstName,
        lastName: LastName,
        phone: NoTelp,
        email: Email,
        address: Address,
        photo: Photo,
      },
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionAuth.changeStatusTokenRefresh(false));
    }

    yield put(toggle.changeModal());
    yield put(toggle.clearJenisToggle());
    yield put(profile.changeClearDataProfile());
    yield put(profile.getDataProfile());
    yield put(toggle.changeLoadingFalse());

    Modal.success({
      title: (
        <p style={{ color: '#31E116' }}>
          <b>Akun Berhasil Di Ubah</b>
        </p>
      ),

      onOk() {},
    });
  } catch (error) {
    yield put(toggle.changeLoadingFalse());
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_editProfile() {
  yield takeLatest('EDIT_DATA_PROFILE', S_editProfile);
}
