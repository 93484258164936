export function changeAdminDetail(idAdmin:any)
{
    return({
        type:"CHANGE_ADMIN_DETAIL",
        idAdmin
    })
}
export function clearAdminDetail()
{
    return({
        type:"CLEAR_ADMIN_DETAIL"
    })
}