import 'styles/CardStyle/index.css';

import CardMaterial from 'materials/CardMaterial';
import IconBarcode_DS from 'image/IconBarcode_DS.png';
import IconBarcode_DS_black from 'image/IconBarcode_DS_black.png';
import IconLocation_DS from 'image/IconLocation_DS.png';
import IconLocation_DS_black from 'image/IconLocation_DS_black.png';
import IconOwnerName_DS from 'image/IconOwnerName_DS.png';
import IconOwnerName_DS_black from 'image/IconOwnerName_DS_black.png';
import { List } from 'antd';
import React from 'react';
import SearchMatrial from 'materials/SearchMatrial';

interface IProps {
  typeToggle: any;
  getOtherData: any;
  handleSearch: any;
  ValueSearch: any;
  handleCloseModal: any;
  handleGetFocus: any;
}
let trimString = function (string: any, length: any) {
  return string.length > length ? string.substring(0, length) + '...' : string;
};
export default function OtherDataComponent({
  getOtherData,
  handleSearch,
  ValueSearch,
  handleGetFocus,
}: IProps) {
  const listData: any = [];
  for (let i = 0; i < 6; i++) {
    listData.push({ barcode: '', waybillCode: '', id: '' });
  }
  return (
    <React.Fragment>
      <CardMaterial
        extra={''}
        title={
          <span style={{ color: '#11BEFF' }}>
            <b>DOKUMEN TERKAIT</b>
            <br />
            <SearchMatrial
              value={ValueSearch}
              style={{ borderRadius: '10px', width: '40%' }}
              placeholder={'Cari berdasarkan nama'}
              onChange={handleSearch}
            />
          </span>
        }
        style={{ borderRadius: '10px' }}
        content={
          <List
            grid={{ gutter: 15, column: 3 }}
            pagination={{
              onChange: page => {},
              pageSize: 6,
            }}
            dataSource={getOtherData.filter((state: any) =>
              state.shipToName.toString().toLowerCase().includes(ValueSearch)
            )}
            renderItem={(data: any) => (
              <List.Item>
                <div
                  className={data.focus === true ? 'cardFocus' : 'cardNotFocus'}
                  onClick={() => handleGetFocus(data.id, data.waybillCode)}
                >
                  <React.Fragment>
                    <div style={{ float: 'left' }}>
                      <img
                        style={{
                          width: '20px',
                          height: '20px',
                          fontSize: '14px',
                        }}
                        alt="Barcode Icon"
                        src={
                          data.focus === true
                            ? IconBarcode_DS
                            : IconBarcode_DS_black
                        }
                      />
                      <span
                        style={{
                          color: data.focus === true ? '#FFFFFF' : '#969696',
                          paddingLeft: '10px',
                        }}
                      >
                        {data.barcode}
                      </span>
                    </div>
                    <br />
                    <div style={{ paddingTop: '15px', fontSize: '14px' }}>
                      <img
                        style={{ width: '20px', height: '20px' }}
                        alt="OwnerName Icon"
                        src={
                          data.focus === true
                            ? IconOwnerName_DS
                            : IconOwnerName_DS_black
                        }
                      />
                      <span
                        style={{
                          color: data.focus === true ? '#FFFFFF' : '#969696',
                          paddingLeft: '10px',
                        }}
                      >
                        {data.shipToName}
                      </span>
                    </div>
                    <div style={{ paddingTop: '15px', fontSize: '14px' }}>
                      <img
                        style={{ width: '20px', height: '20px' }}
                        alt="Location Icon"
                        src={
                          data.focus === true
                            ? IconLocation_DS
                            : IconLocation_DS_black
                        }
                      />
                      <span
                        style={{
                          paddingLeft: '10px',
                          color: data.focus === true ? '#FFFFFF' : '#969696',
                        }}
                      >
                        {trimString(
                          `${Object.values(JSON.parse(data.shipToAddress)).join(
                            ' '
                          )}`,
                          26
                        )}
                      </span>
                    </div>
                  </React.Fragment>
                </div>
              </List.Item>
            )}
          ></List>
        }
      />
    </React.Fragment>
  );
}
