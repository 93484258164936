import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as ActionToggle from '../../../../core/action/toggle/index';
import * as ActionCustomerManifest from '../../../../core/action/Manifest/CustomerManifest/ActionCustomerManifest';
import TableCustomerManifestComponent from '../../../../components/Content/Manifest/CustomerManifest/TableCustomerManifestComponent';

interface IProps {
  isLoading: any;
  TableDeliveryData: any;
  ListCustomerManifest: any;
  Count: any;
  BranchId: any;
  actionToggle: any;
  actionCustomerManifest: any;
}
interface IState {
  page: any;
}
class TableCustomerManifestContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      page: null,
    };
    this.handlePage = this.handlePage.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }
  handlePage(event: any) {
    this.setState({
      page: event,
    });
  }

  handleReset() {
    this.props.actionCustomerManifest.clearCustomerManifestForm();
    this.props.actionCustomerManifest.clearTableCustomerManifestForm();
    this.props.actionCustomerManifest.clearListCustomerrManifestForm();
  }

  handleNext() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('FormDownloadCustomerManifest');
  }

  render() {
    return (
      <div>
        <TableCustomerManifestComponent
          isLoading={this.props.isLoading}
          TableDeliveryData={this.props.TableDeliveryData}
          ListCustomerManifest={this.props.ListCustomerManifest}
          Count={this.props.Count}
          BranchId={this.props.BranchId}
          handleReset={this.handleReset}
          handlePage={this.handlePage}
          handleNext={this.handleNext}
          page={this.state.page}
        />
      </div>
    );
  }
}
const mapStateProps = (state: any) => ({
  TableDeliveryData: state.Manifest.TableCustomerManifest,
  ListCustomerManifest: state.Manifest.ListCustomerManifest,
  Count: state.Manifest.CustomerManifestForm.Count,
  BranchId: state.Manifest.CourierManifestForm.BranchId,
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionCustomerManifest: bindActionCreators(ActionCustomerManifest, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(TableCustomerManifestContainer);
