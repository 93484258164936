import { Checkbox, Col, DatePicker, Form, Radio, Row, Select } from 'antd';

import ButtonMaterial from 'materials/ButtonMaterial';
import CardMaterial from 'materials/CardMaterial';
import React from 'react';
import SpinMaterial from 'materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/id_ID';
import moment from 'moment';

const { Option } = Select;

interface IProps {
  handlePutFirstDate: any;
  GetFirstDate: any;
  handleValidateFirstDate: any;
  ValidateFirstDate: any;
  helpFirstDate: any;

  handlePutLastDate: any;
  GetLastDate: any;
  handleValidateLastDate: any;
  ValidateLastDate: any;
  helpLastDate: any;

  handlePutBranch: any;
  handleGetBranch: any;
  handleBlurBranch: any;
  BranchList: any;
  ValueGetBranch: any;
  ArrayValue: any;
  getBranch: any;
  ValidateBranch: any;
  helpValidateBranch: any;

  ValueGetStatus: any;
  handlePutStatus: any;
  handleBlurStatus: any;
  ValidateStatus: any;
  helpValidateStatus: any;

  handlePutReportType: any;
  ValueReportType: any;

  handleRadioButton: any;
  ValueRadioButton: any;

  handlePutGeoTagging: any;
  handlePutFoto: any;
  handlePutSignature: any;
  getGeoTagging: any;
  getFoto: any;
  getSignature: any;
  handleDownload: any;
  handleReset: any;
  validateDownloadButton: any;
  validateResetButton: any;
  isLoading: any;
  ValueFirstDate: any;
  ValueLastDate: any;

  MultipleSelect: any;
  handlePutMultipleSelect: any;
}

export default function ValidateComponent({
  handlePutFirstDate,
  GetFirstDate,
  handleValidateFirstDate,
  ValidateFirstDate,
  helpFirstDate,
  handlePutLastDate,
  handleValidateLastDate,
  ValidateLastDate,
  helpLastDate,
  handlePutBranch,
  handleGetBranch,
  handleBlurBranch,
  BranchList,
  ValueGetBranch,
  ValidateBranch,
  helpValidateBranch,
  ValueGetStatus,
  handlePutStatus,
  handleBlurStatus,
  ValidateStatus,
  helpValidateStatus,
  handlePutReportType,
  ValueReportType,
  handleRadioButton,
  ValueRadioButton,
  handlePutGeoTagging,
  handlePutFoto,
  handlePutSignature,
  getGeoTagging,
  getFoto,
  getSignature,
  handleDownload,
  handleReset,
  validateDownloadButton,
  validateResetButton,
  isLoading,
  ValueFirstDate,
  ValueLastDate,
  handlePutMultipleSelect,
  MultipleSelect,
}: IProps) {
  const formatDate = 'YYYY-MM-DD';
  const firstDateV = GetFirstDate;
  const minDate = (d: any) =>
    !d ||
    d.isBefore('2020-01-01') ||
    d.isAfter(moment(new Date()).add(1, 'day').format('YYYY-MM-DD'));
  const minDate2 = (d: any) =>
    !d ||
    d.isBefore(moment(GetFirstDate).add(0, 'day').format(formatDate)) ||
    d.isAfter(moment(GetFirstDate).add(30, 'day').format(formatDate));
  return (
    <SpinMaterial
      spinning={isLoading}
      style={{}}
      size={'large'}
      content={
        <CardMaterial
          extra={''}
          title={
            <span className={'titleCard'}>PENGECEKAN DATA PENGIRIMAN</span>
          }
          style={{ borderRadius: '10px' }}
          content={
            <div>
              <Row>
                <Col span={12} className={'spacingRight'}>
                  <div>
                    <Radio.Group
                      onChange={handleRadioButton}
                      value={ValueRadioButton}
                    >
                      <Radio className={'spacingRight'} value={true}>
                        Tanggal Cycle
                      </Radio>
                      <Radio value={false}>Tanggal Kirim</Radio>
                    </Radio.Group>
                  </div>
                  <div className="spacingTopLeftReport">
                    <span>
                      <b>Dari Tanggal</b>
                    </span>
                    <Form.Item
                      validateStatus={ValidateFirstDate}
                      help={helpFirstDate}
                    >
                      <DatePicker
                        className={'datePickerValidateReport'}
                        locale={locale}
                        format={'YYYY-MM-DD'}
                        placeholder={'Pilih Tanggal'}
                        onOpenChange={handleValidateFirstDate}
                        value={ValueFirstDate}
                        onChange={handlePutFirstDate}
                        disabledDate={minDate}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <span>
                      <b>Sampai Tanggal</b>
                    </span>
                    <Form.Item
                      validateStatus={ValidateLastDate}
                      help={helpLastDate}
                    >
                      <DatePicker
                        className={'datePickerValidateReport'}
                        locale={locale}
                        format={'YYYY-MM-DD'}
                        placeholder={'Pilih Tanggal'}
                        defaultPickerValue={moment(firstDateV, formatDate)}
                        onOpenChange={handleValidateLastDate}
                        value={ValueLastDate}
                        onChange={handlePutLastDate}
                        disabledDate={minDate2}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <span>
                      <b>Posko</b>
                    </span>
                    <Form.Item
                      validateStatus={ValidateBranch}
                      help={helpValidateBranch}
                    >
                      <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        showSearch
                        disabled={false}
                        allowClear
                        placeholder="Pilih Posko"
                        onChange={handlePutBranch}
                        onBlur={handleBlurBranch}
                        value={ValueGetBranch}
                        onFocus={handleGetBranch}
                      >
                        {ValueGetBranch.length === 0 ? (
                          <Option key={''} value={'Pilih Semua Posko'}>
                            {'Pilih Semua Posko'}
                          </Option>
                        ) : null}
                        {BranchList.map((data: any, index: any) => (
                          <Option key={index} value={data.id}>
                            {data.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12} className={'spacingColValidateReport'}>
                  <div className="spacingTopRightReport">
                    <span>
                      <b>Status</b>
                    </span>
                    <Form.Item
                      validateStatus={ValidateStatus}
                      help={helpValidateStatus}
                    >
                      <Select
                        style={{ width: '100%' }}
                        mode="multiple"
                        disabled={false}
                        onDeselect={handleBlurStatus}
                        onSelect={handleBlurStatus}
                        onBlur={handlePutStatus}
                        onChange={handlePutStatus}
                        placeholder="Pilih Status"
                        value={ValueGetStatus}
                      >
                        <Option key={''} value={'Success'}>
                          {'Diterima'}
                        </Option>
                        <Option key={''} value={'Return'}>
                          {'Dikembalikan'}
                        </Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className={'spacingTopCheckboxReportV'}>
                    <Checkbox
                      onChange={handlePutGeoTagging}
                      className={'spacingCheckbox'}
                      checked={getGeoTagging}
                    />
                    <span className={'checkboxSpan'}>Geo Tagging</span>
                    <Checkbox
                      onChange={handlePutFoto}
                      className={'spacingCheckbox'}
                      checked={getFoto}
                    />
                    <span className={'checkboxSpan'}>Foto</span>
                    <Checkbox
                      onChange={handlePutSignature}
                      className={'spacingCheckbox'}
                      checked={getSignature}
                    />
                    <span className={'checkboxSpan'}>Ttd</span>
                    <Checkbox
                      onChange={handlePutMultipleSelect}
                      className={'spacingCheckbox'}
                      checked={MultipleSelect}
                    />
                    <span className={'checkboxSpan'}>Multiple Select</span>
                  </div>
                  <div className={'spacingTopReportTypeV'}>
                    <span>
                      <b>Jenis Laporan</b>
                    </span>
                    <Form.Item validateStatus={''} help={''}>
                      <Select
                        style={{ width: '100%' }}
                        optionFilterProp="children"
                        showSearch
                        disabled={false}
                        onChange={handlePutReportType}
                        value={ValueReportType}
                      >
                        <Option value="Summary">Summary Report</Option>
                        <Option value="Detail">Detail Report</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row>
                <div className={'spacingDownloadButton'}>
                  <ButtonMaterial
                    disabled={validateDownloadButton}
                    handleSubmit={handleDownload}
                    teksButton={<b>Download</b>}
                    size={'middle'}
                    shape={'round'}
                    className={
                      validateDownloadButton === false
                        ? 'validateDownloadButtonEnable'
                        : 'validateDownloadButtonDisabled'
                    }
                    type={'primary'}
                    icon={''}
                    style={{}}
                  />
                </div>
                <div>
                  <ButtonMaterial
                    disabled={validateResetButton}
                    handleSubmit={handleReset}
                    teksButton={<b>Reset</b>}
                    size={'middle'}
                    shape={'round'}
                    className={
                      validateResetButton === false
                        ? 'validateResetButtonEnable'
                        : 'validateResetButtonDisabled'
                    }
                    type={'primary'}
                    icon={''}
                    style={{}}
                  />
                </div>
              </Row>
            </div>
          }
        />
      }
    />
  );
}
