import * as ActionCustomerFiture from '../../../core/action/Customer/ActionCustomerFiture';
import * as ActionLogin from '../../../module/Login/Store';
import * as ActionTableStatusCustom from '../../../core/action/Customer/ActionTableStatusDefault';

import { call, put, select, takeLatest } from 'redux-saga/effects';
import { changeLoadingFalse, changeLoadingTrue } from '../../action/toggle';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_editCustomerFiture() {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const SmsDelivery = state.Customer.CustomerFiture.SmsDelivery;
  const SmsDelivered = state.Customer.CustomerFiture.SmsDelivered;
  const Call = state.Customer.CustomerFiture.Call;
  const SenderAlias = state.Customer.CustomerFiture.SenderAlias;
  const SmsDeliveryBody = state.Customer.CustomerFiture.SmsDeliveryBody;
  const SmsDeliveredBody = state.Customer.CustomerFiture.SmsDeliveredBody;
  const IdFiture = state.Customer.CustomerFiture.IdFiture;
  const IsRadius = state.Customer.CustomerFiture.IsRadius;
  const IsRequestPhone = state.Customer.CustomerFiture.IsRequestPhone;
  const IsHistory = state.Customer.CustomerFiture.IsHistory;
  const RadiusMeters = state.Customer.CustomerFiture.RadiusMeters;

  try {
    yield put(changeLoadingTrue());

    yield call(
      axios.put,
      process.env.REACT_APP_URL + '/Setting/CustomFeature/' + IdFiture,
      {
        smSdelivery: SmsDelivery,
        smSdelivered: SmsDelivered,
        call: Call,
        senderAlias: SenderAlias,
        smSdeliveryBody: SmsDeliveryBody,
        smSdeliveredBody: SmsDeliveredBody,
        customerId: IdFiture,
        zonasi: IsRadius,
        isRequestPhone: IsRequestPhone,
        hideManualsHistory: IsHistory,
        radius: +RadiusMeters,
      },
      { headers: config }
    );
    yield put(ActionTableStatusCustom.clearCustomDataStatus());
    yield put(ActionCustomerFiture.changeClearCustomerFiture());
    yield put(changeLoadingFalse());
    yield put(ActionCustomerFiture.changeDetailCustomerFiture());

    Modal.success({
      title: <p style={{ color: '#31E116' }}>Berhasil mengubah</p>,

      onOk() {},
    });
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
      yield put(changeLoadingFalse());
    } else {
      yield put(changeLoadingFalse());
      const propsError = ErrorMassage('Ubah custom fitur');
      const code = propsError.filter((person: any) =>
        person.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}

export function* SM_editCustomerFiture() {
  yield takeLatest('EDIT_CUSTOMER_FITURE', S_editCustomerFiture);
}
