import {Action} from 'redux'

const initialState = 
{
    TableBranch:[],
    TableAdmin:[],
    TableCourier:[],
    TableCustomer:[],
    TableCustomerProduct:[],
    TableHoliday:[],

}

export interface IAuthAction extends Action<string>
{

}

export interface ITableBranch extends IAuthAction
{
    TableBranch : []
}

export interface ITableAdmin extends IAuthAction
{
    TableAdmin : []
}

export interface ITableCourier extends IAuthAction
{
    TableCourier : []
}

export interface ITableCustomer extends IAuthAction
{
    TableCustomer : []
}

export interface ITableCustomerProduct extends IAuthAction
{
    TableCustomerProduct : []
}
export interface ITableHoliday extends IAuthAction
{
    TableHoliday : []
}

export default function AuthReducer(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "SET_TABLE_BRANCH":
            const tablebranch = action as ITableBranch
            newState.TableBranch = tablebranch.TableBranch
            
        return {...newState}
        case "CLEAR_TABLE_BRANCH":
            newState.TableBranch = []
        
        return {...newState}
        case "SET_TABLE_ADMIN":
            const adminTable = action as ITableAdmin
            newState.TableAdmin = adminTable.TableAdmin
                
        return {...newState}
        case "CLEAR_TABLE_ADMIN":
            newState.TableAdmin = []
            
        return {...newState}
        case "SET_TABLE_COURIER":
            const tablecourier = action as ITableCourier
            newState.TableCourier = tablecourier.TableCourier
                
        return {...newState}
        case "CLEAR_TABLE_COURIER":
            newState.TableCourier = []
            
        return {...newState}
        case "SET_TABLE_CUSTOMER":
            const tablecustomer = action as ITableCustomer
            newState.TableCustomer = tablecustomer.TableCustomer
                
        return {...newState}
        case "CLEAR_TABLE_CUSTOMER":
            newState.TableCustomer = []
            
        return {...newState}
        case "SET_TABLE_CUSTOMER_PRODUCT":
            const tablecustomerproduct = action as ITableCustomerProduct
            newState.TableCustomerProduct = tablecustomerproduct.TableCustomerProduct
                
        return {...newState}

        case "SET_TABLE_HOLIDAY":
            const tableHoliday = action as ITableHoliday
            newState.TableHoliday = tableHoliday.TableHoliday
                
        return {...newState}

        case "CLEAR_TABLE_CUSTOMER_PRODUCT":
            newState.TableCustomerProduct = []
            
        return {...newState}
        case "CLEAR_TABLE_HOLIDAY":
            newState.TableHoliday = []
            
        return {...newState}
        case "CLEAR_ALL_TABLE":
            newState.TableBranch = []
            newState.TableAdmin = []
            newState.TableCourier = []
            newState.TableCustomer = []
            newState.TableCustomerProduct = [] 
            newState.TableHoliday = [] 
            
        return {...newState}

    }
    return {...newState}
}