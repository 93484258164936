import '../../../../styles/TableStyle/index.css';

import { Col, Row, Table } from 'antd';
import React, { Component } from 'react';

import ButtonCustomerProductContainer from '../../../../containers/Content/Customer/Product/ButtonCustomerProductContainer';
import ButtonMaterial from '../../../../materials/ButtonMaterial';
import CardMaterial from '../../../../materials/CardMaterial';
import SearchMatrial from '../../../../materials/SearchMatrial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import { DropdownBasic } from 'components/DropdownBasic';

interface IProps {
  data: any;
  isLoading: any;
  page: any;
  typeToggle: any;
  dataSource: any;
  ValueSearch: any;
  handlePage: any;
  handleSearch: any;
  handleModal: any;
  setIsActiveType: (value: string) => void;
  isActiveType: string;
}
export default class CustomerProductComponent extends Component<IProps> {
  render() {
    const looppage = (this.props.page - 1) * 10 + 1;
    const columns = [
      {
        title: (
          <div style={{ textAlign: 'center' }}>
            <b>No</b>
          </div>
        ),
        width: 50,
        render: (text: any, record: any, index: any) => (
          <span>{this.props.page > 1 ? index + looppage : index + 1}</span>
        ),
        key: 'no',
      },
      {
        title: <b>Nama</b>,
        dataIndex: 'name',
        key: 'name',
        width: 200,
      },
      {
        title: <b>Kode</b>,
        dataIndex: 'productCode',
        render: (productCode: any) => (
          <span style={{ color: 'red' }}>{productCode}</span>
        ),
        key: 'kodeproduk',
        width: 200,
      },
      {
        title: <b>Jenis</b>,
        dataIndex: 'productType',
        render: (productType: any) => (
          <span>
            {productType === 'Policy'
              ? 'Polis'
              : productType === 'Letter'
              ? 'Surat'
              : productType === 'Card'
              ? 'Kartu'
              : 'Lainnya'}
          </span>
        ),
        key: 'tipeproduk',
        width: 200,
      },
      {
        title: <b>Status</b>,
        dataIndex: 'isDisabled',
        render: (isDisabled: any) => (
          <p style={{ color: !isDisabled ? '#00AB44' : '#EA1A1A' }}>
            {!isDisabled ? 'Aktif' : 'Nonaktif'}
          </p>
        ),
        key: 'isDisabled',
        width: 200,
      },
      {
        title: <b>Aksi</b>,
        key: 'action',
        width: 100,
        render: (record: any) => (
          <div style={{ marginLeft: '30px' }}>
            <ButtonCustomerProductContainer
              idCustomerProduct={record.id}
              idGroupProduct={''}
              groupingName={''}
            />
          </div>
        ),
      },
    ];
    const optionsFilter = [
      {
        label: 'Aktif',
        key: 'Active',
      },
      {
        label: 'Tidak aktif',
        key: 'Disable',
      },
      {
        label: 'Semua',
        key: 'None',
      },
    ];
    return (
      <div>
        <div>
          <SpinMaterial
            spinning={this.props.isLoading}
            style={{}}
            size={'large'}
            content={
              <div>
                <CardMaterial
                  extra={''}
                  title={''}
                  style={{}}
                  content={
                    <div>
                      <Row>
                        <Col span={16}>
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <span
                              style={{ color: '#11BEFF', paddingRight: '20px' }}
                            >
                              <b>SEMUA PRODUK PELANGGAN</b>
                            </span>
                            <SearchMatrial
                              value={this.props.ValueSearch}
                              style={{ borderRadius: '10px', width: '50%' }}
                              placeholder={'Cari nama atau kode'}
                              onChange={this.props.handleSearch}
                            />
                            <DropdownBasic
                              name="filterValue"
                              idComponent="filterValue"
                              dropdownMenu={optionsFilter}
                              triggerAction={'click'}
                              className="floatLeft"
                              labelTitle={
                                optionsFilter.find(
                                  value => value.key === this.props.isActiveType
                                )?.label
                              }
                              onChange={value =>
                                this.props.setIsActiveType(value.key)
                              }
                            />
                          </div>
                        </Col>
                        <Col span={8}>
                          <div style={{ float: 'right' }}>
                            <ButtonMaterial
                              disabled={''}
                              handleSubmit={this.props.handleModal}
                              teksButton={<b>TAMBAHKAN PRODUK</b>}
                              size={''}
                              shape={''}
                              className={''}
                              type={'link'}
                              icon={''}
                              style={{
                                color: '#11BEFF',
                                paddingRight: '15px',
                              }}
                            />

                            <ButtonMaterial
                              disabled={''}
                              handleSubmit={this.props.handleModal}
                              teksButton={''}
                              size={''}
                              shape={'circle'}
                              className={''}
                              type={'primary'}
                              icon={'plus'}
                              style={{
                                color: '#fafafa',
                                paddingBottom: '3px',
                                background: '#31E116',
                                borderColor: '#31E116',
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  }
                ></CardMaterial>
                <Table
                  rowKey={record => record.id}
                  pagination={{
                    onChange: page => {
                      this.props.handlePage(page);
                    },
                    pageSize: 10,
                  }}
                  bordered={false}
                  dataSource={this.props.dataSource.filter(
                    (person: any) =>
                      person.name
                        .toString()
                        .toLowerCase()
                        .includes(this.props.ValueSearch) ||
                      person.productCode
                        .toString()
                        .toLowerCase()
                        .includes(this.props.ValueSearch)
                  )}
                  columns={columns}
                />
              </div>
            }
          ></SpinMaterial>
        </div>
      </div>
    );
  }
}
