import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import IndexRestoreDataComponent from '../../../components/Content/RestoreData/IndexRestoreDataComponent';
import * as LastAccess from '../../../core/action/LastAccess/index';
import * as ActionRestoreData from '../../../core/action/RestoreData/ActionRestoreData';
import * as ActionPagination from '../../../core/action/Pagination/ActionPagination';

interface IProps {
  roleAccount: any;
  actionLastAccess: any;
  actionRestoreData: any;
  actionPagination: any;
  Fiture: any;
  UploadDate: any;
  UploadDateW: any;
  sideMenus: any;
}

class IndexRestoreDataContainer extends Component<IProps> {
  constructor(props: any) {
    super(props);
    this.handleChangeTabsRestoreData =
      this.handleChangeTabsRestoreData.bind(this);
  }

  handleChangeTabsRestoreData(event: any) {
    this.props.actionPagination.setPagePagination(1);
    if (event === '1') {
      this.props.actionLastAccess.changeLastAccessFiture('restoreDataCsv');
      if (this.props.UploadDate === null) {
      } else {
        this.props.actionRestoreData.clearListRestoreData();
        this.props.actionRestoreData.submitGetDataRestoreData();
      }
    } else if (event === '2') {
      this.props.actionLastAccess.changeLastAccessFiture('restoreDataWaybill');
      if (this.props.UploadDateW === null) {
      } else {
        this.props.actionRestoreData.clearListWaybillRestoreData();
        this.props.actionRestoreData.submitGetDataRestoreData();
      }
    } else if (event === '3') {
      this.props.actionLastAccess.changeLastAccessFiture('restoreCustomer');
    }
  }

  render() {
    return (
      <IndexRestoreDataComponent
        roleAccount={this.props.roleAccount}
        lastAccessFiture={this.props.Fiture}
        sideMenus={this.props.sideMenus}
        handleChangeTabsRestoreData={this.handleChangeTabsRestoreData}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  Fiture: state.LastAccess.Fiture,
  UploadDate: state.RestoreData.FormRestoreData.UploadDate,
  UploadDateW: state.RestoreData.FormRestoreData.UploadDateW,
  sideMenus: state.Auth.SideMenus,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionLastAccess: bindActionCreators(LastAccess, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
  actionRestoreData: bindActionCreators(ActionRestoreData, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IndexRestoreDataContainer);
