export function collapsed() {
  return {
    type: 'ACTION_COLLAPSED',
  };
}
export function changeLoadingFalse() {
  return {
    type: 'CHANGE_LOADING_FALSE',
  };
}
export function changeLoadingTrue() {
  return {
    type: 'CHANGE_LOADING_TRUE',
  };
}
export function changeModal() {
  return {
    type: 'CHANGE_MODAL',
  };
}
export function changeJenisToggle(jenisToggle: any) {
  return {
    type: 'CHANGE_JENIS_TOGGLE',
    jenisToggle,
  };
}
export function changeModal2() {
  return {
    type: 'CHANGE_MODAL2',
  };
}
export function changeJenisToggle2(jenisToggle2: any) {
  return {
    type: 'CHANGE_JENIS_TOGGLE2',
    jenisToggle2,
  };
}
export function changeValueSearch(ValueSearch: any) {
  return {
    type: 'CHANGE_VALUE_SEARCH',
    ValueSearch,
  };
}
export function submitSearch() {
  return {
    type: 'SUBMIT_SEARCH',
  };
}
export function clearJenisToggle() {
  return {
    type: 'CLEAR_JENIS_TOGGLE',
  };
}
export function clearJenisToggle2() {
  return {
    type: 'CLEAR_JENIS_TOGGLE2',
  };
}
export function clearValueSearch() {
  return {
    type: 'CLEAR_VALUE_SEARCH',
  };
}
