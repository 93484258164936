import { Action } from 'redux';

const initialState = {
  UsernameAuth: null,
  PhotoAuth: null,
  InitialsAuth: null,
  Token: null,
  TokenRefresh: null,
  StatusTokenRefresh: false,
  Role: null,
  BranchAuth: null,
  SideMenus: [],
};

export interface IAuthAction extends Action<string> {}

export interface IChangeUsernameAuth extends IAuthAction {
  UsernameAuth: any;
}

export interface IChangePhotoAuth extends IAuthAction {
  PhotoAuth: any;
}

export interface IChangeInitialsAuth extends IAuthAction {
  InitialsAuth: any;
}

export interface IChangeToken extends IAuthAction {
  Token: any;
}

export interface IChangeSideMenus extends IAuthAction {
  SideMenus: any;
}

export interface IChangeTokenRefresh extends IAuthAction {
  TokenRefresh: any;
}
export interface IChangeStatusTokenRefresh extends IAuthAction {
  StatusTokenRefresh: any;
}

export interface IChangeRole extends IAuthAction {
  Role: any;
}

export interface IChangeBranchAuth extends IAuthAction {
  BranchAuth: any;
}
export default function AuthReducerLoginForm(
  state = initialState,
  action: IAuthAction
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'CHANGE_USERNAME_AUTH':
      const changeUsernameAuth = action as IChangeUsernameAuth;
      newState.UsernameAuth = changeUsernameAuth.UsernameAuth;

      return { ...newState };

    case 'CHANGE_PHOTO_AUTH':
      const changePhotoAuth = action as IChangePhotoAuth;
      newState.PhotoAuth = changePhotoAuth.PhotoAuth;

      return { ...newState };

    case 'CHANGE_INITIALS_AUTH':
      const changeInitialsAuth = action as IChangeInitialsAuth;
      newState.InitialsAuth = changeInitialsAuth.InitialsAuth;

      return { ...newState };

    case 'CHANGE_TOKEN':
      const changeToken = action as IChangeToken;
      newState.Token = changeToken.Token;

      return { ...newState };

    case 'CHANGE_SIDEMENUS':
      const changeSideMenus = action as IChangeSideMenus;
      newState.SideMenus = changeSideMenus.SideMenus;

      return { ...newState };

    case 'CHANGE_TOKEN_REFRESH':
      const changeTokenRefresh = action as IChangeTokenRefresh;
      newState.TokenRefresh = changeTokenRefresh.TokenRefresh;

      return { ...newState };

    case 'CHANGE_STATUS_TOKEN_REFRESH':
      const changeStatusTokenRefresh = action as IChangeStatusTokenRefresh;
      newState.StatusTokenRefresh = changeStatusTokenRefresh.StatusTokenRefresh;

      return { ...newState };

    case 'CHANGE_ROLE':
      const ChangeRole = action as IChangeRole;
      newState.Role = ChangeRole.Role;

      return { ...newState };

    case 'CHANGE_BRANCH_AUTH':
      const ChangeBranchAuth = action as IChangeBranchAuth;
      newState.BranchAuth = ChangeBranchAuth.BranchAuth;

      return { ...newState };

    case 'CHANGE_CLEAR_TOKEN':
      newState.Token = null;

      return { ...newState };

    case 'CHANGE_CLEAR_SIDEMENUS':
      newState.SideMenus = [];

      return { ...newState };

    case 'CHANGE_CLEAR_TOKEN_REFRESH':
      newState.TokenRefresh = null;

      return { ...newState };

    case 'CLEAR_STATUS_TOKEN_REFRESH':
      newState.StatusTokenRefresh = false;

      return { ...newState };

    case 'CHANGE_CLEAR_USERNAME_AUTH':
      newState.UsernameAuth = null;

      return { ...newState };

    case 'CHANGE_CLEAR_DATA_PROFILE_AUTH':
      newState.PhotoAuth = null;
      newState.InitialsAuth = null;
      newState.Role = null;

      return { ...newState };
  }
  return state;
}
