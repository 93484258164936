import {
  IChangeLoadingAction,
  IFetchGeneralListAction,
} from './GeneralDataAction';

import { Action } from 'redux';

const initialState: any = {
  loadingGetBranch: false,
  loadingGetCustomer: false,
  loadingGetFormatCsv: false,
  loadingGetCourier: false,
  loadingGetCustomerProduct: false,
  logActivityList: [],
  userList: [],
  branchList: [],
  internalBranchList: [],
  customerList: [],
};

export default function PickUpProcessReducer(
  state = initialState,
  action: Action
) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'FETCH_GENERAL_CUSTOMER_LIST': {
      const fetchCustomerListAction = action as IFetchGeneralListAction;
      newState.customerList = fetchCustomerListAction.data;
      return { ...newState };
    }
    case 'FETCH_GENERAL_BRANCH_LIST': {
      const fetchBranchListAction = action as IFetchGeneralListAction;
      newState.branchList = fetchBranchListAction.data;
      return { ...newState };
    }
    case 'FETCH_INTERNAL_BRANCH_LIST': {
      const fetchInternalBranchListAction = action as IFetchGeneralListAction;
      newState.internalBranchList = fetchInternalBranchListAction.data;
      return { ...newState };
    }
    case 'FETCH_GENERAL_LOG_ACTIVITY_LIST': {
      const fetchLogActivityListAction = action as IFetchGeneralListAction;
      newState.logActivityList = fetchLogActivityListAction.data;
      return { ...newState };
    }
    case 'FETCH_GENERAL_USER_LIST': {
      const fetchUserListAction = action as IFetchGeneralListAction;
      newState.userList = fetchUserListAction.data;
      return { ...newState };
    }
    case 'LOADING_GET_DATA': {
      const changeLoadingAction = action as IChangeLoadingAction;
      const component = changeLoadingAction.component;
      newState[`loadingGet${component}`] = !state[`loadingGet${component}`];
      return { ...newState };
    }
    //   case 'CLEAR_LIST_DOCUMENT_COMPLAINT': {
    //     newState.list = [];
    //     return { ...newState };
    //   }
  }
  return state;
}
