const CreateComplaintFormValidation = (values, props) => {
  const errors: any = {};
  if (!values.complaintSubject) {
    errors.complaintSubject = 'Subjek Keluhan wajib diisi';
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'No telp wajib diisi';
  } else if (values.phoneNumber) {
    if (!/^\d+$/i.test(values.phoneNumber)) {
      errors.phoneNumber = 'Hanya diperbolehkan angka';
    }
  }
  if (!values.detailComplaint) {
    errors.detailComplaint = 'Penjelasan wajib diisi';
  }
  if (!values.resultInvestigation) {
    errors.resultInvestigation = 'Hasil investigasi wajib diisi';
  }
  if (!values.investigationFile) {
    errors.investigationFile = 'Bukti investigasi wajib diisi';
  }
  return errors;
};
export default CreateComplaintFormValidation;
