export function setBranchLat(BranchLat:any)
{
    return({
        type:"SET_BRANCH_LAT",
        BranchLat
    })
}
export function setBranchLng(BranchLng:any)
{
    return({
        type:"SET_BRANCH_LNG",
        BranchLng
    })
}
export function clearAllBranchTracking()
{
    return({
        type:"CLEAR_ALL_BRANCH_TRACKING"
    })
}
