import 'styles/LoginStyle/index.css';

import React from 'react';

export default function SidebarLoginComponent() {
  return (
    <div className="SideBar-Component">
      <div style={{ fontSize: '20px' }}>Selamat Datang di</div>
      <div style={{ fontSize: '32px' }}>
        <b>Courier </b>Command <b>Center</b>
      </div>
    </div>
  );
}
