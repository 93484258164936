import { Checkbox, Col, Row } from 'antd';
import React, { Component } from 'react';

import ButtonMaterial from '../../../../materials/ButtonMaterial';
import DatePickerMaterial from '../../../../materials/DatePickerMaterial';
import FormInputTextMaterial from '../../../../materials/FormInputTextMaterial';
import ModalMaterial from '../../../../materials/ModalMaterial';
import SpinMaterial from '../../../../materials/SpinMaterial';
import locale from 'antd/es/date-picker/locale/en_US';
import moment from 'moment';

interface IProps {
  visible: any;
  handleClearEditCourier: any;
  jenisToggle: any;
  BranchList: any;
  roleAccount: any;
  BranchAuth: any;

  ValueGetWilayah: any;

  GetCourierName: any;
  GetCourierLastName: any;
  GetCourierEmail: any;
  GetCourierPhoneNumber: any;
  GetCourierDob: any;
  GetCourierPhoto: any;
  GetCourierAddress: any;
  GetCourierStatus: any;
  PutCourierCode: any;
  GetCourierCode: any;
  GetCourierAreaCode: any;
  GetBranchCourier: any;

  ValidateCourierName: any;
  ValidateCourierLastName: any;
  ValidateCourierEmail: any;
  ValidateCourierPhoneNumber: any;
  ValidateCourierDob: any;
  ValidateCourierAddress: any;
  ValidateCourierBranchArea: any;
  ValidateButton: any;

  helpCourierName: any;
  helpCourierLastName: any;
  helpCourierEmail: any;
  helpCourierPhoneNumber: any;
  helpCourierDob: any;
  helpCourierAddress: any;
  StateDobCourier: any;

  PutCourierName: any;
  PutCourierLastName: any;
  PutCourierEmail: any;
  PutCourierPhoneNumber: any;
  PutCourierDob: any;
  PutCourierPhoto: any;
  PutCourierAddress: any;
  isLoading: any;
  handleSubmitEditDataCourier: any;
  handleValidateDob: any;
  handleGetBranch: any;
  handleCheckBox: any;
  handlePutAreaCode: any;
  handleGenerateCourierCode: any;
  handleBlurAreaCode: any;
}

export default class EditCourierComponent extends Component<IProps> {
  render() {
    const minDate = (d: any) =>
      !d || d.isAfter(moment().add(-17, 'year').format('YYYY-MM-DD'));
    const dateFormat = 'DD/MM/YYYY';
    const valueDob = this.props.GetCourierDob
      ? moment(this.props.GetCourierDob)
      : this.props.GetCourierDob;
    return (
      <div>
        <ModalMaterial
          className={''}
          width={'80%'}
          style={{}}
          teksTitle={'UBAH DATA KURIR'}
          visible={this.props.visible}
          onCancel={this.props.handleClearEditCourier}
          content={
            <SpinMaterial
              spinning={this.props.isLoading}
              style={{}}
              size={'large'}
              content={
                <div className={'spacingTopDefault'}>
                  <Row>
                    <Col span={12}>
                      <div className={'spacingColForm'}>
                        <div>
                          <span>
                            <b>Nama Depan</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetCourierName}
                            className={'borderRadiusDefault'}
                            onPressEnter={''}
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Masukan nama depan'}
                            prefix={''}
                            maxLength={'15'}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.PutCourierName}
                            onBlur={this.props.PutCourierName}
                            validatestatus={this.props.ValidateCourierName}
                            help={this.props.helpCourierName}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Kode Kurir</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetCourierCode}
                            className={'borderRadiusDefault'}
                            onPressEnter={''}
                            disabled={true}
                            addonBefore={''}
                            placeholder={'Kode Kurir'}
                            prefix={''}
                            maxLength={''}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.PutCourierCode}
                            onBlur={this.props.PutCourierCode}
                            validatestatus={''}
                            help={''}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Tanggal Lahir</b>
                          </span>
                          <DatePickerMaterial
                            style={{ width: '100%' }}
                            locale={locale}
                            format={'DD/MM/YYYY'}
                            defaultPickerValue={moment(
                              '01/01/2002',
                              dateFormat
                            )}
                            placeholder={'01/01/2002'}
                            defaultValue={''}
                            onOpenChange={this.props.handleValidateDob}
                            value={
                              this.props.StateDobCourier === null
                                ? valueDob
                                : this.props.StateDobCourier
                            }
                            onChange={this.props.PutCourierDob}
                            validatestatus={this.props.ValidateCourierDob}
                            help={this.props.helpCourierDob}
                            disabledDate={minDate}
                            className={'datePickerDefault'}
                          />
                        </div>

                        <div>
                          <span>
                            <b>Alamat Lengkap</b>
                          </span>
                          <br />
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetCourierAddress}
                            className={'borderRadiusDefault'}
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Masukan alamat lengkap'}
                            prefix={''}
                            maxLength={225}
                            style={{}}
                            size=""
                            type={'textArea'}
                            onChange={this.props.PutCourierAddress}
                            onBlur={this.props.PutCourierAddress}
                            validatestatus={this.props.ValidateCourierAddress}
                            help={this.props.helpCourierAddress}
                            onPressEnter={''}
                          />
                        </div>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div className={'spacingColForm'}>
                        <div>
                          <span>
                            <b>Nama Belakang</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetCourierLastName}
                            className={'borderRadiusDefault'}
                            onPressEnter={''}
                            disabled={false}
                            addonBefore={''}
                            placeholder={'Masukan nama belakang'}
                            prefix={''}
                            maxLength={'15'}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.PutCourierLastName}
                            onBlur={this.props.PutCourierLastName}
                            validatestatus={''}
                            help={''}
                          />
                        </div>

                        <span>
                          <b>Wilayah</b>
                        </span>
                        <div>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetBranchCourier}
                            className={'dropdownDefault'}
                            disabled={true}
                            addonBefore={''}
                            onPressEnter={''}
                            placeholder={''}
                            prefix={''}
                            maxLength={''}
                            style={{}}
                            size=""
                            type={''}
                            onChange={''}
                            onBlur={''}
                            validatestatus={''}
                            help={''}
                          />
                        </div>

                        <div className={'formTelpDefault'}>
                          <span>
                            <b>No Telp</b>
                          </span>
                          <FormInputTextMaterial
                            validateType={true}
                            value={this.props.GetCourierPhoneNumber}
                            className={'borderRadiusDefault'}
                            disabled={false}
                            addonBefore={'+62'}
                            placeholder={'No Telp'}
                            prefix={''}
                            maxLength={'15'}
                            onPressEnter={''}
                            style={{}}
                            size=""
                            type={''}
                            onChange={this.props.PutCourierPhoneNumber}
                            onBlur={this.props.PutCourierPhoneNumber}
                            validatestatus={
                              this.props.ValidateCourierPhoneNumber
                            }
                            help={this.props.helpCourierPhoneNumber}
                          />
                        </div>

                        <div className={'spacingBottom'}>
                          <br />
                          <Checkbox
                            disabled={
                              this.props.roleAccount === 'Branch' ? true : false
                            }
                            onChange={this.props.handleCheckBox}
                            style={{}}
                            checked={this.props.GetCourierStatus}
                            className={'spacingCheckboxDetailCourier'}
                          />
                          <span className={'textCheckboxItalic'}>
                            Ceklis untuk aktifkan status akun
                          </span>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className={'btnSubmitModalForm'}>
                    <ButtonMaterial
                      disabled={this.props.ValidateButton}
                      handleSubmit={this.props.handleSubmitEditDataCourier}
                      teksButton={'Simpan'}
                      size={''}
                      shape={'default'}
                      className={'borderRadiusDefault'}
                      type={'primary'}
                      style={{}}
                      icon={''}
                    />
                  </div>
                </div>
              }
            />
          }
        />
      </div>
    );
  }
}
