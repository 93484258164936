import instance from 'config/interceptor';

export const getLevelGroup = async params => {
  try {
    const { page = 0, isActive = 'Active', size = 10, levelName } = params[1];
    let link = `/LevelPermission/${isActive}/${page}/${size}`;
    if (levelName) link = link.concat(`?levelName=${levelName}`);

    return await instance.get(process.env.REACT_APP_URL + link);
  } catch (error) {
    return error;
  }
};

export const getAllMenus = async () => {
  try {
    return await instance.get(process.env.REACT_APP_URL + `/Menus/All`);
  } catch (error) {
    return error;
  }
};

export const postLevelGroup = async data => {
  try {
    return await instance.post(
      process.env.REACT_APP_URL + `/LevelPermission`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const updateLevelGroup = async data => {
  try {
    return await instance.put(
      process.env.REACT_APP_URL + `/LevelPermission`,
      data
    );
  } catch (error) {
    return error;
  }
};

export const getLevelGroupSingle = async params => {
  const { id } = params[1];
  try {
    return await instance.get(
      process.env.REACT_APP_URL + `/LevelPermission/${id}`
    );
  } catch (error) {
    return error;
  }
};

export const getAllLevelPermission = async (params: {
  idAccountType: string;
}) => {
  try {
    const { idAccountType } = params;

    let link = process.env.REACT_APP_URL + `/LevelPermission`;
    if (idAccountType) link = link.concat(`?accountType=${idAccountType}`);

    return await instance.get(link);
  } catch (error) {
    return error;
  }
};

export const getMenusLevelPermission = async params => {
  const { id } = params[1];
  try {
    return await instance.get(
      process.env.REACT_APP_URL + `/LevelPermission/Menus/${id}`
    );
  } catch (error) {
    return error;
  }
};
