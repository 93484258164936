import * as ActionDetailBranch from '../../../../core/action/Branch/ActionDetailBranch';
import * as ActionFormCourier from '../../../../core/action/ManageAccount/Courier/ActionFormDetailCourier';
import * as ActionGenerateCourierCode from '../../../../core/action/ManageAccount/Courier/ActionGenerateCourierCode';
import * as ActionPagination from '../../../../core/action/Pagination/ActionPagination';
import * as courierTable from '../../../../core/action/ManageAccount/Courier/ActionTableCourier';
import * as toggle from '../../../../core/action/toggle';

import React, { Component } from 'react';
import { bindActionCreators, compose } from 'redux';

import CourierTableComponent from '../../../../components/Content/ManageAccount/Courier/CourierComponent';
import { connect } from 'react-redux';

interface IProps {
  ValueSearch: any;
  data: any;
  PagePagination: any;
  TotalPagePagination: any;
  actionTable: any;
  actionToggle: any;
  isLoading: any;
  EditBranch: any;
  roleAccount: any;
  branchAuth: any;
  actionPagination: any;
  actionDetailBranch: any;
  actionFormCourier: any;
  actionGenerateCourierCode: any;
}

interface IState {
  sRT: any;
  dataSource: any;
  nameSearch: any;
}

class CourierBranchContainer extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      sRT: null,
      dataSource: this.props.data,
      nameSearch: '',
    };
    this.handlePage = this.handlePage.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);
    this.handleModal = this.handleModal.bind(this);
  }

  handlePage(event: any) {
    this.props.actionPagination.setPagePagination(event)
    if (this.props.EditBranch === null) {
      if (this.props.roleAccount === 'Branch') {
        this.props.actionTable.getCourierTableBranch();
      } else {
        this.props.actionTable.getCourierTable();
      }
    } else {
      this.props.actionTable.getCourierTableBranch();
    }
  }

  handleSearch(event: any) {
    if (event.target.value.length === 0) {
      this.props.actionToggle.changeValueSearch(null);
      if (this.props.EditBranch === null) {
        if (this.props.roleAccount === 'Branch') {
          this.props.actionTable.getCourierTableBranch();
        } else {
          this.props.actionTable.getCourierTable();
        }
      } else {
        this.props.actionTable.getCourierTableBranch();
      }
    } else {
      this.props.actionToggle.changeValueSearch(event.target.value.replace(/^\s/, ''));
    }
  }

  handleSubmitSearch() {
    if (this.props.ValueSearch === null || this.props.ValueSearch === '') {
    } else {
      this.props.actionPagination.setPagePagination(0);
      this.props.actionTable.SubmitSearchCourier();
    }
  }

  async handleModal() {
    this.props.actionToggle.changeModal();
    this.props.actionToggle.changeJenisToggle('AddCourier');
    this.props.actionDetailBranch.setBranchDetail(true);
    if (this.props.roleAccount === 'Branch') {
      await this.props.actionFormCourier.changeAreaCodeId(
        this.props.branchAuth
      );
      await this.props.actionGenerateCourierCode.generateCourierCode();
    }
  }

  handleResetSearch(clearFilters: any) {
    clearFilters();
    this.setState({ sRT: null });
  }

  componentWillMount() {
    if (this.props.data.length === 0) {
      if (this.props.EditBranch === null) {
        if (this.props.roleAccount === 'Branch') {
          this.props.actionTable.getCourierTableBranch();
        } else {
          this.props.actionTable.getCourierTable();
        }
      } else {
        this.props.actionTable.getCourierTableBranch();
      }
    }
    this.props.actionToggle.clearValueSearch();
  }

  render() {
    return (
      <CourierTableComponent
        ValueSearch={this.props.ValueSearch}
        dataSource={this.state.dataSource}
        data={this.props.data}
        handlePage={this.handlePage}
        handleSearch={this.handleSearch}
        handleSubmitSearch={this.handleSubmitSearch}
        handleModal={this.handleModal}
        nameSearch={this.state.nameSearch}
        isLoading={this.props.isLoading}
        EditBranch={this.props.EditBranch}
        PagePagination={this.props.PagePagination}
        TotalPagePagination={this.props.TotalPagePagination}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  roleAccount: state.Auth.Role,
  branchAuth: state.Auth.BranchAuth,
  data: state.Table.TableCourier,
  isLoading: state.Toggle.isLoading,
  EditBranch: state.Branch.PropertiesBranch.EditBranch,
  PagePagination:state.Pagination.PagePagination,
  TotalPagePagination:state.Pagination.TotalPagePagination,
  ValueSearch: state.Toggle.ValueSearch,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionTable: bindActionCreators(courierTable, dispatch),
  actionToggle: bindActionCreators(toggle, dispatch),
  actionDetailBranch: bindActionCreators(ActionDetailBranch, dispatch),
  actionFormCourier: bindActionCreators(ActionFormCourier, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
  actionGenerateCourierCode: bindActionCreators(
    ActionGenerateCourierCode,
    dispatch
  ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CourierBranchContainer);
