import React from 'react';
import FormLevelGroup from 'components/FormLevelGroup';

function EditLevelFormComponent(props) {
  return (
    <FormLevelGroup
      handleBtnBack={props.handleBtnBack}
      handleSubmit={props.handleSubmit}
      dataAllMenus={props.dataAllMenus}
      dataLevel={props.dataLevel}
      isLoadingMenus={props.isLoadingMenus}
      isLoadingSubmit={props.isLoadingSubmit}
      setDataMenus={props.setDataAllMenus}
      isEdit
    />
  );
}

export default EditLevelFormComponent;
