// DY: DEV CHANGES 4 JUL 24

import React from 'react';
import { Table, Row, Col } from 'antd';
import CSpin from 'apps/assets/components/CSpin';
import CCard from 'apps/assets/components/CCard';
import CButton from 'apps/assets/components/CButton';
import { TextInputSearch } from 'components/InputField';
import { DropdownBasic } from 'components/DropdownBasic';
import ExpandRowItemCard, {
  customExpandIcon,
} from 'components/ExpandRowItemCard';

export default function RoleComponent(props: any) {
  const { columns, handlePageAddNewLevel, isLoading } = props;
  const buttonData = [
    {
      type: 'link',
      className: 'btnLinkAdd',
      onClick: handlePageAddNewLevel,
      isLoading: false,
      content: <b>Permintaan Hapus Data</b>,
      id: 'btnPopupAddComplaint',
    },
    {
      type: 'circle',
      className: 'btnTableAdd',
      onClick: handlePageAddNewLevel,
      isLoading: false,
      icon: 'plus',
      id: 'btnPopupAddComplaint',
    },
  ];
  const dataN = [
    {
      label: 'Dalam Antrian',
      key: 'New',
    },
    {
      label: 'Proses',
      key: 'Process',
    },
    {
      label: 'Selesai',
      key: 'Success',
    },
    {
      label: 'Gagal',
      key: 'Error',
    },
    {
      label: 'Semua',
      key: 'All',
    },
  ];

  return (
    <CSpin
      spinning={isLoading}
      size={'large'}
      content={
        <div>
          <CCard
            cardContent={
              <React.Fragment>
                <Row>
                  <Col span={12}>
                    <TextInputSearch
                      name="searchValue"
                      idComponent="inputSearch"
                      label="Hapus data pelanggan"
                      className="searchForm"
                      placeholder={'Cari berdasarkan nama'}
                      regex={'noSpaceInFirstChar'}
                      value={props.searchGroup}
                      onChange={props.handleSearchGroup}
                      additionalHandleEnterFunc={() =>
                        props.handleSearchLevel(props.searchGroup)
                      }
                    />
                  </Col>
                  <Col span={4}>
                    <DropdownBasic
                      name="filterValue"
                      idComponent="filterValue"
                      dropdownMenu={dataN}
                      triggerAction={'click'}
                      labelTitle={'Semua'}
                      className="floatLeft"
                      value={props.statusGroup}
                      onChange={props.handleStatusGroup}
                    />
                  </Col>
                  <Col span={8}>
                    <div className="floatRight">
                      <CButton buttonData={buttonData} />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            }
          />
          <Table
            pagination={{
              onChange: page => {
                props.handlePage(page);
              },
              pageSize: 10,
              total: props.totalPagination,
              current: props.pagePagination === 0 ? 1 : props.pagePagination,
            }}
            bordered={false}
            dataSource={props.roles}
            columns={columns}
            expandIcon={props => customExpandIcon(props)}
            expandedRowRender={(record: { productName: string }) => (
              <ExpandRowItemCard
                label="Produk"
                datas={record.productName.split(';')}
              />
            )}
          />
        </div>
      }
    />
  );
}
