export function setIdBranch(idBranch:any)
{
    return({
        type:"SET_ID_BRANCH",
        idBranch
    })
}
export function changeEditBranch(editbranch:any)
{
    return({
        type:"CHANGE_EDIT_BRANCH",
        editbranch

    })
}
export function setIdVillage(idVillage:any)
{
    return({
        type:"SET_ID_VILLAGE",
        idVillage
    })
}
export function setAdminBranchName(adminBranch:any)
{
    return({
        type:"SET_ADMIN_BRANCH_NAME",
        adminBranch
    })
}
export function setIdAdminBranch(idAdminBranch:any)
{
    return({
        type:"SET_ID_ADMIN_BRANCH",
        idAdminBranch
    })
}
export function setBranchDetail(detailBranch:any)
{
    return({
        type:"SET_DETAIL_BRANCH",
        detailBranch
    })
}
export function changeDetailBranch()
{
    return({
        type:"CHANGE_DETAIL_BRANCH"
    })
}
export function changeAdminBranchName()
{
    return({
        type:"CHANGE_ADMIN_BRANCH_NAME"
    })
}
export function changeDetailVillage()
{
    return({
        type:"CHANGE_DETAIL_VILLAGE"
    })
}
export function clearAllDetailBranch()
{
    return({
        type:"CLEAR_ALL_DETAIL_BRANCH"
    })
}
export function clearAllPropertiesBranch()
{
    return({
        type:"CLEAR_ALL_PROPERTIES_BRANCH"
    })
}
export function clearAllBranch()
{
    return({
        type:"CLEAR_ALL_BRANCH"
    })
}
