import * as ActionComponent from '../../App/Store/ComponentAction';
import * as ActionDataGeneral from '../../App/Store/GeneralDataAction';
import * as ActionLogin from '../../Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import axios from 'axios';

function* S_getAllCustomerProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(ActionDataGeneral.loadingGetData('Customer'));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/customers`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionComponent.processLoading(false));
    }
    yield put(ActionDataGeneral.fetchCustomerList(data.data));
    yield put(ActionDataGeneral.loadingGetData('Customer'));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionDataGeneral.loadingGetData('Customer'));
  }
}
function* S_getAllBranchProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    yield put(ActionDataGeneral.loadingGetData('Branch'));
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/branchs`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionComponent.processLoading(false));
    }
    yield put(ActionDataGeneral.fetchBranchList(data));
    yield put(ActionDataGeneral.loadingGetData('Branch'));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
    yield put(ActionDataGeneral.loadingGetData('Branch'));
  }
}
function* S_getLogActivityProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/LogActivity/Activitys`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionComponent.processLoading(false));
    }
    yield put(ActionDataGeneral.fetchLogActivityList(data));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
function* S_getUserProcess(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Profile/AccountActivity`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionComponent.processLoading(false));
    }
    yield put(ActionDataGeneral.fetchUserList(data));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
function* S_getInternalBranch(action: any) {
  const state = yield select();
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const statusTokenRefresh = state.Auth.StatusTokenRefresh;
  try {
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/branchs/NotIsPartner`,
      { headers: config }
    );
    if (statusTokenRefresh === true) {
      yield put(ActionComponent.processLoading(false));
    }
    yield put(ActionDataGeneral.fetchInternalBranch(data));
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* SM_getAllCustomerAction() {
  yield takeLatest(
    'GET_GENERAL_CUSTOMER_LIST_REQUESTED',
    S_getAllCustomerProcess
  );
}
export function* SM_getAllBranchAction() {
  yield takeLatest('GET_GENERAL_BRANCH_LIST_REQUESTED', S_getAllBranchProcess);
}
export function* SM_getLogActivityAction() {
  yield takeLatest(
    'GET_GENERAL_LOG_ACTIVITY_LIST_REQUESTED',
    S_getLogActivityProcess
  );
}
export function* SM_getUserAction() {
  yield takeLatest('GET_USER_LIST_REQUESTED', S_getUserProcess);
}
export function* SM_getInternalBranch() {
  yield takeLatest('GET_INTERNAL_BRANCH_LIST_REQUESTED', S_getInternalBranch);
}
