import {Action} from 'redux';

const initialState = 
{
    PropertiesHoliday:{
        SwitchHoliday:false,
        IdHoliday:null,
        SyncronizeWeekly:false
    },
    FormManualHoliday:{
        DateHoliday:null,
        EventHoliday:null,
        ActiveHoliday:false
    },
    FormUploadHoliday:{
        CsvHoliday:null,
        FileNameCsv:null
    },
    FormSynchronizedWeekly:{
        YearSynchronized:null,
        HolidaySynchroniz:[],
    }
}

export interface IAuthAction extends Action<string>
{

}

export interface ISetDateHoliday extends IAuthAction
{
    DateHoliday : any
}
export interface ISetEventHoliday extends IAuthAction
{
    EventHoliday : any
}
export interface ISetIdHoliday extends IAuthAction
{
    IdHoliday : any
}
export interface ISetCsvHoliday extends IAuthAction
{
    CsvHoliday : any
}
export interface ISetFileNameCsv extends IAuthAction
{
    FileNameCsv : any
}
export interface ISetYearSynchronized extends IAuthAction
{
    YearSynchronized : any
}
export interface ISetHolidaySynchroniz extends IAuthAction
{
    HolidaySynchroniz : any
}
export default function AuthReducerDetailId(state=initialState, action:IAuthAction){
    const newState = Object.assign({}, state)
    switch(action.type)
    {
        case "SET_SWITCH_FORM_HOLIDAY":
            newState.PropertiesHoliday.SwitchHoliday = !newState.PropertiesHoliday.SwitchHoliday
            
        return {...newState}

        case "SET_DATE_HOLIDAY":
            const setDateHoliday = action as ISetDateHoliday
            newState.FormManualHoliday.DateHoliday = setDateHoliday.DateHoliday
            
        return {...newState}

        case "SET_EVENT_HOLIDAY":
            const setEventHoliday = action as ISetEventHoliday
            newState.FormManualHoliday.EventHoliday = setEventHoliday.EventHoliday
            
        return {...newState}

        case "SET_ID_HOLIDAY":
            const setIdHoliday = action as ISetIdHoliday
            newState.PropertiesHoliday.IdHoliday = setIdHoliday.IdHoliday
            
        return {...newState}

        case "SET_CSV_HOLIDAY":
            const setCsvHoliday = action as ISetCsvHoliday
            newState.FormUploadHoliday.CsvHoliday = setCsvHoliday.CsvHoliday
            
        return {...newState}

        case "SET_FILE_NAME_CSV":
            const setFileNameCsv = action as ISetFileNameCsv
            newState.FormUploadHoliday.FileNameCsv = setFileNameCsv.FileNameCsv
            
        return {...newState}
        
        case "SET_YEAR_SYNCHRONIZED":
            const setYearSynchronized = action as ISetYearSynchronized
            newState.FormSynchronizedWeekly.YearSynchronized = setYearSynchronized.YearSynchronized
            
        return {...newState}

        case "SET_HOLIDAY_SYNCHRONIZ":
            const setHolidaySynchroniz = action as ISetHolidaySynchroniz
            newState.FormSynchronizedWeekly.HolidaySynchroniz = setHolidaySynchroniz.HolidaySynchroniz
            
        return {...newState}

        case "CLEAR_HOLIDAY_SYNCHRONIZ":
            newState.FormSynchronizedWeekly.HolidaySynchroniz = []
            
        return {...newState}

        case "CLEAR_CSV_HOLIDAY":
            newState.FormUploadHoliday.CsvHoliday = null
            newState.FormUploadHoliday.FileNameCsv = null
            
        return {...newState}

        case "SET_ACTIVE_HOLIDAY":
            newState.FormManualHoliday.ActiveHoliday = !newState.FormManualHoliday.ActiveHoliday
            
        return {...newState}
        
        case "SET_SYNCHRONIZE_WEEKLY":
            newState.PropertiesHoliday.SyncronizeWeekly = !newState.PropertiesHoliday.SyncronizeWeekly
            
        return {...newState}

        case "CLEAR_DATE_HOLIDAY":
            newState.FormManualHoliday.DateHoliday = null
            
        return {...newState}

        case "CLEAR_EVENT_HOLIDAY":
            newState.FormManualHoliday.EventHoliday = null
            
        return {...newState}
        
        case "CLEAR_ALL_FORM_MANUAL_HOLIDAY":
            newState.PropertiesHoliday.SwitchHoliday = false
            newState.PropertiesHoliday.IdHoliday = null
            newState.PropertiesHoliday.SyncronizeWeekly = false
            newState.FormManualHoliday.DateHoliday = null
            newState.FormManualHoliday.EventHoliday = null
            newState.FormManualHoliday.ActiveHoliday = false
            newState.FormSynchronizedWeekly.YearSynchronized = null
            newState.FormSynchronizedWeekly.HolidaySynchroniz = []
            
        return {...newState}
    }
    return state
}