import * as ActionLastAccess from '../../../../../core/action/LastAccess';
import * as ActionListCustomCsvCustomer from '../../../../../core/action/Customer/ActionListCustomCsvCustomer';
import * as ActionListCustomer from '../../../../../core/action/Customer/ActionListCustomer';
import * as ActionListCustomerProduct from '../../../../../core/action/CustomerProduct/ActionListCustomerProduct';
import * as ActionPagination from '../../../../../core/action/Pagination/ActionPagination';
import * as ActionToggle from '../../../../../core/action/toggle/index';
import * as ActionUnggahDocument from '../../../../../core/action/Delivery/Document/ActionFormUnggahDocument';

import React, { useState, useEffect } from 'react';
import { bindActionCreators, compose } from 'redux';
import { useQuery, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  getAllCustomers,
  getProductByCustomers,
  getAllBranch,
} from 'services/GeneralService';
import {
  uploadDelivery,
  uploadDeliveryContinueProcess,
} from 'services/DeliveryDocumentUploadService';
import { getCustomerDetailCsvList } from 'services/CustomerService';
import ModalStatus from 'materials/ModalStatus';
import FormUnggahDocumentComponent from '../../../../../components/Content/Delivery/Document/FormUnggahDataComponent';
import { SelectOptionsInterface } from 'interface/general';

interface IProps {
  actionUnggahDocument: any;
  actionPagination: any;
  actionListCustomer: any;
  actionListCustomerProduct: any;
  actionListCustomCsvCustomer: any;
  actionToggle: any;
  actionLastAccess: any;
  listCustomer: any;
  listCustomerProduct: any;
  listCustomCsvCustomer: any;
  customer: any;
  customerProduct: any;
  customCsvCustomer: any;
  ProductType: any;
  customDeliveryDate: any;
  customUploadDate: any;
  countSuccess: any;
  countFailed: any;
  resultUploadData: any;
  listResultUploadData: any;
  visible: any;
  jenisToggle: any;
  Csv: any;
  PagePagination: any;
  TotalPagePagination: any;
  detailStatusUploadDocument: any;
  listStatusUploadDocument: any;
  isLoading: any;
  typeLoading: any;
  LoadingSelectCustomer: any;
  LoadingTypeSelectCustomer: any;
  LoadingSelectCustomerProduct: any;
  LoadingTypeSelectCustomerProduct: any;
}

export type DataUploadDocumentType = {
  customer: number | null;
  product: number | null;
  typeDocument: number | null;
  branch: number | null;
  typeTemplate: number | null;
  uploadFile: any;
  uploadFileName: string | null;
  uploadFileBase64: string | null;
  dateSend: any;
  dateUpload: any;
};

const defaultDataUploadDocument: DataUploadDocumentType = {
  customer: null,
  product: null,
  typeDocument: null,
  branch: null,
  typeTemplate: null,
  uploadFile: null,
  uploadFileName: null,
  uploadFileBase64: null,
  dateSend: null,
  dateUpload: moment(),
};

const FormUnggahDocumentContainer = (props: IProps) => {
  const [ListCustomCsvState] = useState<any>([]);
  const [file, setFile] = useState<any>(null);
  const [fileName, setFileName] = useState<any>(null);
  const [formatFile, setFormatFile] = useState<any>('.csv');
  const [validateCustomerForm, setValidateCustomerForm] = useState<any>(null);
  const [validateProductTypeFrom, setValidateProductTypeFrom] =
    useState<any>(null);
  const [validateCustomerProductForm, setValidateCustomerProductForm] =
    useState<any>(null);
  const [validateCustomCsvCustomer, setValidateCustomCsvCustomer] =
    useState<any>(null);
  const [defaultCsv, setDefaultCsv] = useState<any>(false);
  const [valueUploadDateForm, setValueUploadDateForm] = useState<any>(moment());
  const [valueDeliveryDateForm, setValueDeliveryDateForm] =
    useState<any>(false);

  const [dataUploadDocument, setDataUploadDocument] = useState(
    defaultDataUploadDocument
  );
  const [dataCustomers, setDataCustomers] = useState<SelectOptionsInterface[]>(
    []
  );
  const [dataProducts, setDataProducs] = useState<any[]>([]);
  const [dataCsvList, setDataCsvList] = useState<any[]>([]);
  const [dataBranchs, setDataBranchs] = useState<any[]>([]);

  const { mutate: mutateUpload, isLoading: isLoadingUpload } = useMutation(
    (data: any) => uploadDelivery(data),
    {
      onSuccess: res => {
        if (res.status === 200) {
          handleModalDetailStatus(res.data.id);
          handleRefresh();
          handleResetForm();
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan',
            content:
              res.response?.data?.Message ||
              'Terjadi kesalahan pada server, mohon hubungi admin untuk tindakan lebih lanjut',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
        });
      },
    }
  );

  const {
    mutate: mutateDeliveryContinue,
    isLoading: isLoadingDeliveryContinue,
  } = useMutation((data: any) => uploadDeliveryContinueProcess(data), {
    onSuccess: res => {
      if (res.status === 200) {
        handleRefresh();
        handleCloseModalDetailStatus();
      } else {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan',
          content:
            res.response?.data?.Message ||
            'Terjadi kesalahan pada server, mohon hubungi admin untuk tindakan lebih lanjut',
        });
      }
    },
    onError: () => {
      ModalStatus({
        status: 'error',
        title: 'Terjadi Kesalahan',
      });
    },
  });

  const { isLoading: isLoadingCustomers } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataCustomers'],
    queryFn: () => getAllCustomers(),
    onSuccess(data) {
      const datas = [] as any;
      data?.data?.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataCustomers(datas);
    },
    refetchOnWindowFocus: false,
  });

  const { isFetching: isLoadingProducts } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataProducts', { id: dataUploadDocument.customer }],
    queryFn: ({ queryKey }) => getProductByCustomers(queryKey),
    onSuccess(data) {
      const datas = [] as any;
      data?.data?.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
          productType: item.productType,
        });
      });
      setDataProducs(datas);
    },
    enabled: !!dataUploadDocument.customer,
    refetchOnWindowFocus: false,
  });

  const { isFetching: isLoadingCsvList } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataCustomerCsvList', dataUploadDocument.customer],
    queryFn: () =>
      getCustomerDetailCsvList({
        IdCustomer: dataUploadDocument.customer,
        isActiveType: 'Active',
      }),
    enabled: !!dataUploadDocument.customer,
    refetchOnWindowFocus: false,
    onSuccess: res => {
      if (res?.data.length > 0) {
        setDataCsvList(res.data);
      } else {
        setDataCsvList([]);
      }
    },
  });
  const { isFetching: isLoadingBranch } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataListBranch'],
    queryFn: () => getAllBranch(),
    onSuccess(res) {
      if (res?.data?.length > 0) {
        setDataBranchs(res.data || []);
      } else {
        setDataBranchs([]);
      }
    },
  });

  const handleChangeForm = async (name: string, value: any) => {
    if (name === 'uploadFileName') {
      const base64: any = await getBase64(value.file.originFileObj);
      setDataUploadDocument(prevState => ({
        ...prevState,
        uploadFile: value.file,
        uploadFileName: value.file.name,
        uploadFileBase64: base64.split(',')[1],
      }));
      return;
    }
    setDataUploadDocument(prevState => ({ ...prevState, [name]: value }));
  };

  const handleResetForm = () => {
    setDataUploadDocument(defaultDataUploadDocument);
  };

  const handleSubmitUpload = () => {
    const deliveryTime = dataUploadDocument?.dateSend
      ? dataUploadDocument?.dateSend.format('YYYY-MM-DDTHH:mm:ss.SSSz')
      : null;

    const data = {
      fileCsv: dataUploadDocument.uploadFileBase64,
      deliveryTime,
      customerProductId: dataUploadDocument.product,
      customerCsvId: dataUploadDocument.typeTemplate,
      fileName: dataUploadDocument.uploadFileName,
      processTime: dataUploadDocument?.dateUpload.format('YYYY-MM-DDTHH:mm:ss'),
      branchId: dataUploadDocument.branch,
    };

    mutateUpload(data);
  };

  const handleGetCustomer = () => {
    props.actionListCustomer.getListCustomer();
  };
  const handleGetCustomCsvCustomer = () => {
    props.actionListCustomCsvCustomer.getListCustomCsvCustomer();
  };
  const handleBlurCustomer = () => {
    if (props.customer === null) {
      setValidateCustomerForm('error');
    } else {
      setValidateCustomerForm(null);
    }
  };
  const handleBlurCustomCsvCustomer = () => {
    if (props.customCsvCustomer === null) {
      setValidateCustomCsvCustomer('error');
    } else {
      setValidateCustomCsvCustomer(null);
    }
  };

  const handleResetCustomer = () => {
    props.actionUnggahDocument.clearProductType();
    props.actionUnggahDocument.clearCustomerProduct();
    props.actionListCustomerProduct.clearListCustomerProduct();

    setValidateCustomerForm(null);
    setValidateProductTypeFrom(null);
    setValidateCustomerProductForm(null);
    setValidateCustomCsvCustomer(null);
    setDefaultCsv(true);
  };

  const handleResetCustomCsv = () => {
    setDefaultCsv(false);
  };

  const handleBlurProductType = () => {
    if (props.ProductType === null) {
      setValidateProductTypeFrom('error');
    } else {
      setValidateProductTypeFrom(null);
    }
  };

  const handleResetProductType = () => {
    props.actionUnggahDocument.clearCustomerProduct();
    props.actionListCustomerProduct.clearListCustomerProduct();
    setValidateProductTypeFrom(null);
    setValidateCustomerProductForm(null);
  };

  const handleResetUploadCsv = () => {
    props.actionUnggahDocument.clearAllFormUnggahCsv();
    props.actionListCustomer.clearListCustomer();
    props.actionListCustomerProduct.clearListCustomerProduct();

    setValidateCustomerForm(null);
    setValidateProductTypeFrom(null);
    setValidateCustomerProductForm(null);
    setValidateCustomCsvCustomer(null);
    setFile(null);
    setFileName('');
    setValueDeliveryDateForm(null);
    setFormatFile('.csv');
  };
  const handleGetCustomerProduct = () => {
    props.actionListCustomerProduct.getListCustomerProduct();
  };
  const handleBlurCustomerProduct = () => {
    if (props.customerProduct === null) {
      setValidateCustomerProductForm('error');
    } else {
      setValidateCustomerProductForm(null);
    }
  };
  const handleResetCustomerProduct = () => {
    setValidateCustomerProductForm(null);
  };
  const handlePutCustomer = (event: any) => {
    props.actionUnggahDocument.changeCustomerId(event);
    props.actionUnggahDocument.clearProductType();
    props.actionListCustomCsvCustomer.getListCustomCsvCustomer();
  };
  const handlePutProductType = (event: any) => {
    props.actionUnggahDocument.changeProductType(event);
  };
  const handlePutCustomerProduct = (event: any) => {
    props.actionUnggahDocument.changeCustomerProductId(event);
  };
  const handlePutDeliveryDate = (event: any) => {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      props.actionUnggahDocument.changeCustomerDeliveryDate(dateValue);
    } else {
      props.actionUnggahDocument.changeCustomerDeliveryDate(null);
    }
    setValueDeliveryDateForm(event);
  };
  const handlePutUploadDate = (event: any) => {
    if (event) {
      const dateValue = event._d
        ? moment(new Date(event._d)).format('YYYY-MM-DDTHH:mm:ss')
        : event;
      props.actionUnggahDocument.changeCustomerUploadDate(dateValue);
    } else {
      props.actionUnggahDocument.changeCustomerUploadDate(null);
    }
    setValueUploadDateForm(event);
  };
  const handlePutCustomCsvCustomer = (event: any, label: any) => {
    const lengthFormatFile = label.props.children.length;
    let result = label.props.children.substring(
      lengthFormatFile - 2,
      lengthFormatFile
    );
    if (result === 'x)') {
      setFormatFile('.xlsx');
    } else if (result === 't)') {
      setFormatFile('.txt');
    } else if (result === 's)') {
      setFormatFile('.xls');
    } else {
      setFormatFile('.csv');
    }

    props.actionUnggahDocument.changeCustomCsvCustomer(event);
  };
  const getBase64 = (file: any) => {
    return new Promise(resolve => {
      let baseURL;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleConvert = (e: any) => {
    setFileName(e.target.value);
    let fileU = file;
    fileU = e.target.files[0];
    getBase64(fileU)
      .then((result: any) => {
        fileU['base64'] = result.toString().split(',')[1];
        props.actionUnggahDocument.changeFileCsv(result.split(',')[1]);
        setFile(fileU);
      })
      .catch(err => {
        console.log(err);
      });

    setFile(e.target.files[0]);
    props.actionUnggahDocument.changeCustomerFileName(fileU.name.split('.')[0]);
  };

  const handleUploadCsv = () => {
    setFileName('');
    props.actionUnggahDocument.uploadCsv();
    props.actionToggle.changeJenisToggle('uploadCSV');
  };

  const handleCloseModalDetailStatus = () => {
    props.actionToggle.changeModal2();
    props.actionToggle.changeJenisToggle2(null);
  };

  const handleModalDetailStatus = (idDocument: any) => {
    props.actionUnggahDocument.fetchDocumentId(idDocument);
    props.actionUnggahDocument.getDetailDocumentStatusUpload();
    props.actionToggle.changeModal2();
    props.actionToggle.changeJenisToggle2('DetailStatusUpload');
  };
  const handleModal = () => {
    props.actionUnggahDocument.clearAllFormUnggahCsv();
    props.actionListCustomer.clearListCustomer();
    props.actionListCustomerProduct.clearListCustomerProduct();
    props.actionToggle.changeJenisToggle(null);
  };
  // DY: THIS UNGGAH DOCUMENT
  const handleRefresh = () => {
    props.actionPagination.setPagePagination(0);
    props.actionPagination.setTotalPagePagination(1);
    props.actionUnggahDocument.getListDataStatusUpload();
  };

  const handleNext = () => {
    props.actionUnggahDocument.changeNextUploadCsv();
  };

  const handleDownload = () => {
    props.actionUnggahDocument.downloadResultUploadCsv();
  };
  const handlePage = (event: any) => {
    props.actionPagination.setPagePagination(event);
    props.actionUnggahDocument.getListDataStatusUpload();
  };

  const handleSubmitContinue = (isContinue: boolean) => {
    mutateDeliveryContinue({
      id: props.detailStatusUploadDocument?.id,
      isContinue,
    });
  };

  useEffect(() => {
    let DateNow = moment().format('YYYY-MM-DDTHH:mm:ss');
    props.actionUnggahDocument.changeCustomerUploadDate(DateNow);
    props.actionUnggahDocument.getListDataStatusUpload();
  }, [props.actionUnggahDocument]);

  return (
    <FormUnggahDocumentComponent
      file={file}
      defaultCsv={defaultCsv}
      fileName={fileName}
      Csv={props.Csv}
      customUploadDate={props.customUploadDate}
      countSuccess={
        props.countSuccess === null || props.countSuccess === undefined
          ? '-'
          : props.countSuccess
      }
      countFailed={
        props.countFailed === null || props.countFailed === undefined
          ? '-'
          : props.countFailed
      }
      resultUploadData={props.resultUploadData}
      PagePagination={props.PagePagination}
      TotalPagePagination={props.TotalPagePagination}
      isLoading={props.isLoading}
      listCustomer={props.listCustomer}
      customCsvCustomer={props.customCsvCustomer}
      LoadingSelectCustomer={props.LoadingSelectCustomer}
      LoadingTypeSelectCustomer={props.LoadingTypeSelectCustomer}
      LoadingSelectCustomerProduct={props.LoadingSelectCustomerProduct}
      LoadingTypeSelectCustomerProduct={props.LoadingTypeSelectCustomerProduct}
      validateCustomerForm={validateCustomerForm}
      validateCustomCsvCustomer={validateCustomCsvCustomer}
      validateProductTypeForm={validateProductTypeFrom}
      valueGetCustomer={
        props.customer === null ? (
          <span style={{ color: '#969696' }}>Pilih pelanggan</span>
        ) : (
          props.customer
        )
      }
      valueGetCustomCsvCustomer={props.customCsvCustomer}
      validateCustomerProductForm={validateCustomerProductForm}
      valueGetCustomerProduct={
        props.customerProduct === null ? (
          <span style={{ color: '#969696' }}>Pilih produk pelanggan</span>
        ) : (
          props.customerProduct
        )
      }
      handleRefresh={handleRefresh}
      visible2={props.visible}
      jenisToggle2={props.jenisToggle}
      handlePage={handlePage}
      handleResetCustomer={handleResetCustomer}
      handleResetProductType={handleResetProductType}
      handleResetUploadCsv={handleResetUploadCsv}
      detailStatusUploadDocument={props.detailStatusUploadDocument}
      listStatusUploadDocument={props.listStatusUploadDocument}
      listCustomerProduct={props.listCustomerProduct}
      listCustomCsvCustomer={props.listCustomCsvCustomer}
      ListCustomCsvState={ListCustomCsvState}
      Customer={props.customer}
      ProductType={props.ProductType}
      typeLoading={props.typeLoading}
      CustomerProduct={props.customerProduct}
      handleResetCustomerProduct={handleResetCustomerProduct}
      handleGetCustomer={handleGetCustomer}
      handleGetCustomCsvCustomer={handleGetCustomCsvCustomer}
      handleGetCustomerProduct={handleGetCustomerProduct}
      handlePutCustomer={handlePutCustomer}
      handlePutCustomCsvCustomer={handlePutCustomCsvCustomer}
      handlePutProductType={handlePutProductType}
      handlePutCustomerProduct={handlePutCustomerProduct}
      handleConvert={handleConvert}
      handleUploadCsv={handleUploadCsv}
      handleBlurCustomer={handleBlurCustomer}
      handleBlurCustomCsvCustomer={handleBlurCustomCsvCustomer}
      handleBlurProductType={handleBlurProductType}
      handleBlurCustomerProduct={handleBlurCustomerProduct}
      handleResetCustomCsv={handleResetCustomCsv}
      handlePutDeliveryDate={handlePutDeliveryDate}
      handlePutUploadDate={handlePutUploadDate}
      handleModal={handleModal}
      handleModalDetailStatus={handleModalDetailStatus}
      handleNext={handleNext}
      handleDownload={handleDownload}
      handleCloseModalDetailStatus={handleCloseModalDetailStatus}
      valueUploadDateForm={valueUploadDateForm}
      valueDeliveryDateForm={valueDeliveryDateForm}
      customDeliveryDate={props.customDeliveryDate}
      validateResetButton={
        props.customer !== null ||
        props.ProductType !== null ||
        props.customerProduct !== null ||
        props.customCsvCustomer !== null ||
        props.customDeliveryDate !== null ||
        props.Csv !== null
          ? false
          : true
      }
      formatFile={formatFile}
      dataUploadDocument={dataUploadDocument}
      handleChangeForm={handleChangeForm}
      handleResetForm={handleResetForm}
      dataCustomers={dataCustomers}
      isLoadingCustomers={isLoadingCustomers}
      dataProducts={dataProducts}
      isLoadingProducts={isLoadingProducts}
      dataCsvList={dataCsvList}
      isLoadingCsvList={isLoadingCsvList}
      isLoadingBranch={isLoadingBranch}
      dataBranchs={dataBranchs}
      isLoadingUpload={isLoadingUpload}
      handleSubmitUpload={handleSubmitUpload}
      isLoadingDeliveryContinue={isLoadingDeliveryContinue}
      handleSubmitContinue={handleSubmitContinue}
    />
  );
};

const mapStateProps = (state: any) => ({
  listCustomer: state.Customer.ListCustomer,
  listCustomerProduct: state.CustomerProduct.ListCustomerProduct,
  listCustomCsvCustomer: state.Customer.ListCustomCsvCustomer,
  customer: state.Delivery.UnggahDocument.CustomerId,
  customerProduct: state.Delivery.UnggahDocument.CustomerProductId,
  customCsvCustomer: state.Delivery.UnggahDocument.CustomCsvCustomer,
  customDeliveryDate: state.Delivery.UnggahDocument.DeliveryDate,
  customUploadDate: state.Delivery.UnggahDocument.UploadDate,
  ProductType: state.Delivery.UnggahDocument.ProductType,
  countSuccess: state.Delivery.UnggahDocument.CountSuccess,
  countFailed: state.Delivery.UnggahDocument.CountFailed,
  resultUploadData: state.Delivery.UnggahDocument.ResultUploadData,
  listResultUploadData: state.Delivery.UnggahDocument.ListResultUploadData,
  detailStatusUploadDocument:
    state.Delivery.StatusUploadDocument.detailStatusUploadDocument,
  listStatusUploadDocument:
    state.Delivery.StatusUploadDocument.listStatusUploadDocument,
  Csv: state.Delivery.UnggahDocument.csv,
  isLoading: state.Toggle.isLoading,
  typeLoading: state.Toggle.jenisToggle,
  PagePagination: state.Pagination.PagePagination,
  TotalPagePagination: state.Pagination.TotalPagePagination,
  visible: state.Toggle.modal2,
  jenisToggle: state.Toggle.jenisToggle2,
  LoadingSelectCustomer: state.Customer.PropertiesCustomer.LoadingCustomer,
  LoadingTypeSelectCustomer:
    state.Customer.PropertiesCustomer.TypeLoadingCustomer,
  LoadingSelectCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.LoadingCustomerProduct,
  LoadingTypeSelectCustomerProduct:
    state.CustomerProduct.PropertiesCustomerProduct.TypeLoadingCustomerProduct,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionUnggahDocument: bindActionCreators(ActionUnggahDocument, dispatch),
  actionListCustomer: bindActionCreators(ActionListCustomer, dispatch),
  actionListCustomCsvCustomer: bindActionCreators(
    ActionListCustomCsvCustomer,
    dispatch
  ),
  actionListCustomerProduct: bindActionCreators(
    ActionListCustomerProduct,
    dispatch
  ),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
  actionLastAccess: bindActionCreators(ActionLastAccess, dispatch),
  actionPagination: bindActionCreators(ActionPagination, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(FormUnggahDocumentContainer);
