const EnumDeliveryType = (i: any) => {
  if (i === 'Urgent') {
    return 'Urgent ';
  } else if (i === 'SameDayService') {
    return 'SameDay';
  } else if (i === 'Reguler') {
    return 'Reguler';
  }
  return null
};
const EnumLabelSearchType = (i: any) => {
  if (i === 'NomerPolis') {
    return 'No Polis';
  } else if (i === 'NomerRekening') {
    return 'No Rekening';
  } else if (i === 'NomerMail') {
    return 'No Mail';
  } else if (i === 'Name') {
    return 'Nama';
  } else if (i === 'NomerReferensi') {
    return 'No Referensi';
  }
  return null
};
const EnumLabelHistoryType = (i: any) => {
  if (i === 'Uploaded') {
    return 'Uploaded';
  } else if (i === 'ScanBarcode') {
    return 'ScanBarcode';
  } else if (i === 'WaybillPrinted') {
    return 'WaybillPrinted';
  } else if (i === 'ScanBranch') {
    return 'ScanBranch';
  } else if (i === 'ScanCourier') {
    return 'ScanCourier';
  } else if (i === 'DownloadCourier') {
    return 'DownloadCourier';
  } else if (i === 'WrongRoute') {
    return 'WrongRoute';
  } else if (i === 'Received') {
    return 'Received';
  } else if (i === 'Resend') {
    return 'Resend';
  } else if (i === 'UpdatedManual') {
    return 'UpdatedManual';
  } else if (i === 'PrintManifestCustomer') {
    return 'PrintManifestCustomer';
  } else if (i === 'UpdatedManualVendor') {
    return 'UpdatedManualVendor';
  }
  return null
};
export { EnumDeliveryType, EnumLabelSearchType, EnumLabelHistoryType };
