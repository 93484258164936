export function GetProvince()
{
    return({
        type:"GET_PROVINCE"
    })
}
export function SetProvince(Province:any)
{
    return({
        type:"SET_PROVINCE",
        Province
    })
}
export function ClearProvince()
{
    return({
        type:"CLEAR_PROVINCE"
    })
}
export function GetCity()
{
    return({
        type:"GET_CITY"
    })
}
export function SetCity(City:any)
{
    return({
        type:"SET_CITY",
        City
    })
}
export function ClearCity()
{
    return({
        type:"CLEAR_CITY"
    })
}
export function GetSubDistrict()
{
    return({
        type:"GET_SUBDISTRICT"
    })
}
export function SetSubDistrict(SubDistrict:any)
{
    return({
        type:"SET_SUBDISTRICT",
        SubDistrict
    })
}
export function ClearSubDistrict()
{
    return({
        type:"CLEAR_SUBDISTRICT"
    })
}
export function GetVillage()
{
    return({
        type:"GET_VILLAGE"
    })
}
export function GetDetailVillage()
{
    return({
        type:"DETAIL_VILLAGE"
    })
}
export function SetVillage(Village:any)
{
    return({
        type:"SET_VILLAGE",
        Village
    })
}
export function ClearVillage()
{
    return({
        type:"CLEAR_VILLAGE"
    })
}
export function SetPostalCode(PostalCode:any)
{
    return({
        type:"SET_POSTAL_CODE",
        PostalCode
    })
}
export function ClearPostalCode()
{
    return({
        type:"CLEAR_POSTAL_CODE"
    })
}
export function ClearAllAddress()
{
    return({
        type:"CLEAR_ALL_ADDRESS"
    })
}
export function setLoadingAddress()
{
    return({
        type:"SET_LOADING_ADDRESS"
    })
}
export function setLoadingTypeAddress(LoadingTypeAddress:any)
{
    return({
        type:"SET_LOADING_TYPE_ADDRESS",
        LoadingTypeAddress
    })
}
export function clearLoadingTypeAddress()
{
    return({
        type:"CLEAR_LOADING_TYPE_ADDRESS"
    })
}