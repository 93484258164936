import * as ActionFilter from '../../action/DataDelivery/ActionFormDataDelivery';
import * as ActionLogin from '../../../module/Login/Store';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';
import moment from 'moment';

export function* S_GetDataDeliveryFilterCount() {
  const state = yield select();
  // const CycleDateFrom = state.Pagination.PagePagination-1
  // const CycleDateTo = state.Pagination.SizeDataPagination
  const token = state.Auth.Token;
  const config = {
    Authorization: `bearer ${token}`,
    'Content-type': 'application/json',
  };
  const IdBranchFormAuth = state.Auth.BranchAuth;
  const RoleFormAuth = state.Auth.Role;
  const RadioButton = state.DataDelivery.Filter.RadioButton;
  const DeliveStat = state.DataDelivery.Filter.DeliveryStatus;
  const DateFrom = moment(state.DataDelivery.Filter.CycleStart).format(
    'YYYYMMDD'
  );
  const DateTo = moment(state.DataDelivery.Filter.CycleEnd).format('YYYYMMDD');
  const Customer =
    state.DataDelivery.Filter.Customer === null
      ? 0
      : state.DataDelivery.Filter.Customer;
  //const paramDate= DeliveryDocument===false?"CycleDateFrom="+CycleDateFrom+"&"+"CycleDateTo="+CycleDateTo:"ItemDeliveryTimeFrom="+CycleDateFrom+"&"+"ItemDeliveryTimeTo="+CycleDateTo
  const CustomerProduct =
    state.DataDelivery.Filter.CustomerProduct === null
      ? 0
      : state.DataDelivery.Filter.CustomerProduct;
  const Branch =
    state.DataDelivery.Filter.Branch === null
      ? 0
      : state.DataDelivery.Filter.Branch;
  const Courier =
    state.DataDelivery.Filter.Courier === null
      ? 0
      : state.DataDelivery.Filter.Courier;
  const PhysicalDocument = state.DataDelivery.Filter.PhysicalDocument;
  const paramDeliveStat = `${Object.values(DeliveStat).join('%2C')}`;
  const ConditionBranch = RoleFormAuth === 'Branch' ? IdBranchFormAuth : Branch;

  try {
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL +
        '/Delivery/GetDeliveryCount/' +
        paramDeliveStat +
        '?TimeStart=' +
        DateFrom +
        '&TimeEnd=' +
        DateTo +
        '&Customer=' +
        Customer +
        '&Product=' +
        CustomerProduct +
        '&Branch=' +
        ConditionBranch +
        '&Courier=' +
        Courier +
        '&DeliveryTime=' +
        RadioButton +
        '&PhysicalDocument=' +
        PhysicalDocument,
      { headers: config }
    );

    yield put(ActionFilter.setTotalOnProcess(data.inProcess));
    yield put(ActionFilter.setTotalSuccess(data.success));
    yield put(ActionFilter.setTotalReturn(data.return));
    yield put(ActionFilter.setTotalDelivery(data.total));
    // yield put(ActionPagination.setTotalPagePagination(data.totalPages*10))
    if (data.total === 0) {
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            <b>Pencarian Tidak Ditemukan</b>
          </p>
        ),
        content: (
          <div>
            <p style={{ color: 'black' }}>Data yang dicari ({data.total})</p>
          </div>
        ),
        onOk() {},
      });
    } else {
      Modal.success({
        title: (
          <p style={{ color: '#31E116' }}>
            <b>Pencarian Berhasil</b>
          </p>
        ),
        content: (
          <div>
            <p style={{ color: 'black' }}>Data yang dicari ({data.total})</p>
          </div>
        ),
        onOk() {},
      });
    }
  } catch (error) {
    if (error.response.status === 401) {
      yield put(ActionLogin.submitTokenRefrash());
    } else {
      const propsError = ErrorMassage('table total posko');
      const code = propsError.filter((data: any) =>
        data.Code.toString().toLowerCase().includes(error.response.status)
      );
      Modal.error({
        title: (
          <p style={{ color: '#FF0000' }}>
            {code.map((data: any) => data.TitleMassage)}
          </p>
        ),
        content: (
          <div>
            <p style={{ color: '#C0C0C0' }}>
              {code.map((data: any) => data.Massage)}
            </p>
          </div>
        ),
        onOk() {},
      });
    }
  }
}
export function* SM_GetDataDeliveryFilterCount() {
  yield takeLatest('SEARCH_DELIVERY_COUNT', S_GetDataDeliveryFilterCount);
}
