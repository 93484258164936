import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import FailedLogComponent from 'module/SmsBlast/components/FailedLog';
import { getSmsBlast } from 'services/LogBlastService';

export type SmsBlastFailedLogType = {
  id: number;
  cycle: number;
  customerId: number;
  customerProductId: number;
  customerName: string;
  customerProductName: string;
  status: string;
  proccessDateSms: string;
  description: string;
  payload: string;
};

export default function FailedLog() {
  const [dataSmsBlast, setDataSmsBlast] = useState<SmsBlastFailedLogType[]>([]);
  const [page, setPage] = useState<number>(1);

  const { isLoading } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataLevelPermission'],
    queryFn: () => getSmsBlast(),
    onSuccess: res => {
      setDataSmsBlast(res.data);
    },
  });

  return (
    <FailedLogComponent
      dataSmsBlast={dataSmsBlast}
      page={page}
      setPage={setPage}
      isLoading={isLoading}
    />
  );
}
