//@flow

import 'leaflet/dist/leaflet.css';

import { Map, Marker, TileLayer, ZoomControl } from 'react-leaflet';
import React, { Component } from 'react';

import { Button } from 'antd';
import Control from 'react-leaflet-control';
import IconMarkerCCC from '../../../image/iconCCC.png';
import L from 'leaflet';
import ModalMaterial from '../../../materials/ModalMaterial';

export const pointerIcon = new L.Icon({
  iconUrl: IconMarkerCCC,
  iconSize: [34, 42],
});
interface IProps {
  getBranchLat: any;
  getBranchLng: any;
  getZoomMap: any;
  modalLocationBranch: any;
  getLocation: any;
  handleModalLocationBranch: any;
  handleMarkerUpdatePosition: any;
  handleSaveLocationBranch: any;
}
export default class SetMapsBranchComponent extends Component<IProps> {
  render() {
    return (
      <ModalMaterial
        className={''}
        width={'50%'}
        style={{}}
        teksTitle={'LOKASI POSKO'}
        visible={this.props.modalLocationBranch}
        onCancel={this.props.handleModalLocationBranch}
        content={
          <div>
            <Map
              center={
                this.props.getBranchLat === null ||
                this.props.getBranchLng === null
                  ? { lat: -6.229186089831833, lng: 106.90948382279811 }
                  : {
                      lat: this.props.getBranchLat,
                      lng: this.props.getBranchLng,
                    }
              }
              zoom={this.props.getZoomMap}
              zoomControl={false}
              style={{ width: '100%', height: '500px' }}
            >
              <ZoomControl position="bottomright" />
              <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker
                icon={pointerIcon}
                draggable={true}
                ondragend={this.props.handleMarkerUpdatePosition}
                position={
                  this.props.getBranchLat === null ||
                  this.props.getBranchLng === null
                    ? { lat: -6.229186089831833, lng: 106.90948382279811 }
                    : {
                        lat: this.props.getBranchLat,
                        lng: this.props.getBranchLng,
                      }
                }
              ></Marker>
              <Control position="bottomright">
                <Button
                  className={'mapButton'}
                  shape={'circle'}
                  icon={'environment'}
                  size={'large'}
                  onClick={this.props.getLocation}
                >
                </Button>
              </Control>
              <Control position="bottomleft">
                <Button
                  onClick={this.props.handleSaveLocationBranch}
                  type={'primary'}
                  className={'mapSubmitBtn'}
                >
                  Simpan
                </Button>
              </Control>
            </Map>
          </div>
        }
      />
    );
  }
}
