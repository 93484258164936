import * as GenerateAdminCode from '../../../../core/action/ManageAccount/AdminBranch/ActionGenerateAdminBranch';
import * as addAdmin from '../../../../core/action/ManageAccount/AdminBranch/ActionAddAdminBranch';
import * as formAdmin from '../../../../core/action/ManageAccount/AdminBranch/ActionFormDetailAdminBranch';
import * as toggle from '../../../../core/action/toggle';
import ModalStatus from 'materials/ModalStatus';
import { postAdminBranch } from 'services/AccountServices';

import React, { useState } from 'react';
import { bindActionCreators, compose } from 'redux';
import { useMutation } from '@tanstack/react-query';

import AddAdminComponent from '../../../../components/Content/ManageAccount/AdminBranch/AddAdminComponent';
import { connect } from 'react-redux';
import { FormAdminType } from 'components/Content/ManageAccount/AdminBranch/FormAdminComponent';

interface IProps {
  actionToggle: any;
  visible: any;
  jenisToggle: any;
  actionFormAdmin: any;

  GetAdminName: any;
  AccountType: any;
  GetAdminLastName: any;
  GetAdminEmail: any;
  GetAdminPhoneNumber: any;
  GetAdminDob: any;
  GetAdminAddress: any;
  GetAdminPhoto: any;

  addAdmin: any;
  generateAdminCode: any;

  isLoading: any;
  refetch: any;
  Code: any;
}

const AddAdminContainer = (props: IProps) => {
  const defaultValue = {
    firstName: '',
    lastName: '',
    email: '',
    typeAccount: '',
    rolePermission: null,
    phone: null,
    dob: null,
    address: '',
    branchId: 0,
    status: true,
  };
  const [dataFormAdmin, setDataFormAdmin] =
    useState<FormAdminType>(defaultValue);

  const { mutate: submitAdminBranch, isLoading } = useMutation(
    postAdminBranch,
    {
      onSuccess: res => {
        if (res.status === 200) {
          props.refetch();
          handleClear();
          ModalStatus({
            status: 'success',
            title: 'Data berhasil disimpan',
          });
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    }
  );

  const handleChange = (name: string, value: any) => {
    setDataFormAdmin(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClear = () => {
    props.actionToggle.changeModal();
    props.actionToggle.clearJenisToggle();
    props.actionFormAdmin.changeClearDataAdminForm();
    setDataFormAdmin(defaultValue);
  };

  const handleSaveAdminBranch = () => {
    const data = {
      ...dataFormAdmin,
      photo: null,
      code: null,
      username: null,
      password: null,
      pinCourier: 0,
      productBlock: [0],
      accountType: dataFormAdmin.typeAccount,
      isActive: dataFormAdmin.status,
    };
    submitAdminBranch(data);
  };
  return (
    <AddAdminComponent
      visible={
        props.jenisToggle === 'AddAdmin' && props.visible === true
          ? true
          : false
      }
      jenisToggle={props.jenisToggle}
      handleChange={handleChange}
      isLoading={isLoading}
      handleClear={handleClear}
      handleSubmit={handleSaveAdminBranch}
      initialData={dataFormAdmin}
    />
  );
};

const mapStateToProps = (state: any) => ({
  visible: state.Toggle.modal,
  jenisToggle: state.Toggle.jenisToggle,
  GetAdminName: state.AdminBranch.FormAdmin.AdminName,
  AccountType: state.AdminBranch.FormAdmin.AccountType,
  GetAdminLastName: state.AdminBranch.FormAdmin.AdminLastName,
  GetAdminEmail: state.AdminBranch.FormAdmin.AdminEmail,
  GetAdminPhoneNumber: state.AdminBranch.FormAdmin.AdminPhoneNumber,
  GetAdminDob: state.AdminBranch.FormAdmin.AdminDob,
  GetAdminAddress: state.AdminBranch.FormAdmin.AdminAddress,
  GetAdminPhoto: state.AdminBranch.FormAdmin.AdminPhoto,
  Code: state.AdminBranch.FormAdmin.Code,
  isLoading: state.Toggle.isLoading,
});

const mapDispatchToProps = (dispatch: any) => ({
  actionToggle: bindActionCreators(toggle, dispatch),
  actionFormAdmin: bindActionCreators(formAdmin, dispatch),
  addAdmin: bindActionCreators(addAdmin, dispatch),
  generateAdminCode: bindActionCreators(GenerateAdminCode, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AddAdminContainer);
