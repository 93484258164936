export function setCsvHoliday(CsvHoliday:any)
{
    return({
        type:"SET_CSV_HOLIDAY",
        CsvHoliday
    })
}
export function setFileNameCsv(FileNameCsv:any)
{
    return({
        type:"SET_FILE_NAME_CSV",
        FileNameCsv
    })
}
export function addCsvHoliday()
{
    return({
        type:"ADD_CSV_HOLIDAY"
    })
}
export function getTemplateCsvHoliday()
{
    return({
        type:"GET_TEMPLATE_CSV_HOLIDAY"
    })
}
export function clearCsvHoliday()
{
    return({
        type:"CLEAR_CSV_HOLIDAY" 
    })
}