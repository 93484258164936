import React, { useState } from 'react';
import styles from './CustomerReportComponents.module.css';
import { Col, Row, Icon } from 'antd';
import SearchMaterial from '../../../../materials/SearchMatrial';
import CardMaterial from 'materials/CardMaterial';
import { DropdownBasic } from 'components/DropdownBasic';
import ButtonMaterial from 'materials/ButtonMaterial';
import { CustomerReportType } from '../container';
import SpinMaterial from 'materials/SpinMaterial';

type CustomerReportComponentsProps = {
  isLoading: boolean;
  dataFormatReport: CustomerReportType[];
  CustomerName: string;
  handleBack: () => void;
  setShowModalAdd: () => void;
  setShowModalEdit: (value: number) => void;
  handleDetailConfig: (id: number) => void;
  setIsActiveType: (value: string) => void;
  isActiveType: string;
};

const ItemFormatReport = ({
  isActive = false,
  name,
  onClick,
  onClickEdit,
}: {
  isActive?: boolean;
  name: string;
  onClick: () => void;
  onClickEdit: () => void;
}) => {
  return (
    <div
      className={
        isActive ? styles.itemFormatReportActive : styles.itemFormatReport
      }
      onClick={onClick}
    >
      <div className={styles.wrapperItemFormat}>
        <p className={styles.itemFormatName}>{name}</p>
        {isActive ? (
          <p className={styles.itemFormatStatusActive}>Aktif</p>
        ) : (
          <p className={styles.itemFormatStatus}>Nonaktif</p>
        )}
      </div>

      <ButtonMaterial
        icon="edit"
        className="btnEdit"
        handleSubmit={e => {
          e.stopPropagation();
          onClickEdit();
        }}
      />
    </div>
  );
};

export default function CustomerReportComponents({
  isLoading,
  dataFormatReport,
  CustomerName,
  handleBack,
  setShowModalAdd,
  setShowModalEdit,
  handleDetailConfig,
  setIsActiveType,
  isActiveType,
}: CustomerReportComponentsProps) {
  const [searchName, setSearchName] = useState<string>('');
  const optionsFilter = [
    {
      label: 'Aktif',
      key: 'Active',
    },
    {
      label: 'Tidak aktif',
      key: 'Disable',
    },
    {
      label: 'Semua',
      key: 'None',
    },
  ];

  return (
    <SpinMaterial size="large" spinning={isLoading}>
      <CardMaterial style={{ borderRadius: '12px 12px 0 0' }}>
        <Row>
          <Col span={20}>
            <div className={styles.wrapperTitleSection}>
              <span className={styles.titleSection}>{CustomerName}</span>
              <SearchMaterial
                style={{ width: '40%' }}
                placeholder="Cari berdasarkan Nama Format Laporan"
                onChange={e => setSearchName(e.target.value)}
                value={searchName}
                onPressEnter={() => {}}
              />
              <DropdownBasic
                name="filterValue"
                idComponent="filterValue"
                dropdownMenu={optionsFilter}
                triggerAction={'click'}
                className="floatLeft"
                labelTitle={
                  optionsFilter.find(value => value.key === isActiveType)?.label
                }
                onChange={value => setIsActiveType(value.key)}
              />
            </div>
          </Col>
          <Col span={4}>
            <div className={'floatRight'}>
              <ButtonMaterial
                teksButton="Kembali"
                type="danger"
                style={{ borderRadius: 10, fontWeight: 500 }}
                handleSubmit={handleBack}
              />
            </div>
          </Col>
        </Row>
      </CardMaterial>
      <CardMaterial className={styles.wrapperCardMaterialContent}>
        <div className={styles.wrapperContent}>
          <div className={styles.buttonAddCustom} onClick={setShowModalAdd}>
            <Icon type="plus" style={{ color: '#45E42D' }} />
            <p className={styles.textButton}>Tambah Format Laporan</p>
          </div>

          <div className={styles.wrapperItem}>
            {dataFormatReport
              .filter(item =>
                searchName
                  ? item.name.toLowerCase().includes(searchName.toLowerCase())
                  : true
              )
              .map((item, index) => (
                <ItemFormatReport
                  key={index}
                  name={item.name}
                  onClick={() => handleDetailConfig(item.id)}
                  onClickEdit={() => setShowModalEdit(item.id)}
                  isActive={item.isActive}
                />
              ))}
          </div>
        </div>
      </CardMaterial>
    </SpinMaterial>
  );
}
