import React, { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import {
  getAllCustomers,
  getProductByCustomers,
  getAllBranch,
  getCourierWithBranch,
} from 'services/GeneralService';
import DistributionComponent from '../components/DistributionScan';
import AssignDocument from '../components/AssignDocument';
import AdditionalData from '../components/AdditionalData';
import { SelectOptionsInterface } from 'interface/general';
import {
  getDeliveryStatusScan,
  getDataDeliveryScan,
  putAssignDocument,
} from 'services/DeliveryDocumentServices';
import ModalStatus from 'materials/ModalStatus';
import ModalDocumentScan from '../components/ModalDocumentScan';
import ModalSelectBarcodeMultiple from 'components/ModalSelectBarcodeMultiple';

export type DataDeliveryDistributionType = {
  cycleDate: any;
  typeDocument: string;
  customer: number | null;
  product: number | null;
};

export type DataScanDeliveryType = {
  scan: number;
  unScan: number;
};

export type DataAssignDocumentType = {
  deliveryType: string;
  branch: number | null;
  courier: number | null;
  paperType: string;
  district: string;
  scan: string;
  typeScan: string;
  isReverseBarcode: boolean;
  noResi?: string;
  koli?: any[];
  totalWeight?: number;
  trueOrFalseKoli?: boolean;
};

const defaultDataDeliveryDistribution = {
  cycleDate: null,
  typeDocument: '',
  customer: null,
  product: null,
};

const defaultDataAssignDocument = {
  deliveryType: '',
  branch: null,
  courier: null,
  paperType: '',
  district: '',
  scan: '',
  typeScan: 'waybill',
  isReverseBarcode: false,
  noResi: '',
  koli: [],
  totalWeight: 0,
  trueOrFalseKoli: false,
};

export default function Distribution() {
  const [dataDeliveryDistribution, setDataDeliveryDistribution] =
    useState<DataDeliveryDistributionType>(defaultDataDeliveryDistribution);
  const [dataCustomers, setDataCustomers] = useState<SelectOptionsInterface[]>(
    []
  );
  const [dataProducts, setDataProducs] = useState<any[]>([]);
  const [dataScanDelivery, setDataScanDelivery] =
    useState<DataScanDeliveryType>({
      scan: 0,
      unScan: 0,
    });
  const [isShowModalMultipleWaybill, setIsShowModalMultipleWaybill] =
    useState<boolean>(false);
  const [showModalScan, setModalScan] = useState<string | null>(null);
  const [dataWaybillMultiple, setDataWaybillMultiple] = useState<any[]>([]);
  const [documentScan, setDocumentScan] = useState<any[]>([]);
  const [dataBranchs, setDataBranchs] = useState<any[]>([]);
  const [dataCourier, setDataCourier] = useState<any[]>([]);

  const [dataAssignDocument, setDataAssignDocument] =
    useState<DataAssignDocumentType>(defaultDataAssignDocument);
  const [successScan, setSuccessScan] = useState<{
    waybill: string;
    courierName: string;
  }>({
    waybill: '',
    courierName: '',
  });

  const { isFetching: isLoadingCustomers } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataCustomers'],
    queryFn: () => getAllCustomers(),
    onSuccess(data) {
      const datas = [] as any;
      data.data.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
        });
      });
      setDataCustomers(datas);
    },
    refetchOnWindowFocus: false,
  });

  const { isFetching: isLoadingProducts } = useQuery<AxiosResponse, Error>({
    queryKey: [
      'dataProducts',
      {
        id: dataDeliveryDistribution.customer,
        active: true,
        typeProduct: dataDeliveryDistribution.typeDocument,
      },
    ],
    queryFn: ({ queryKey }) => getProductByCustomers(queryKey),
    onSuccess(res) {
      const datas = [] as any;
      res.data.forEach(item => {
        datas.push({
          id: item.id,
          name: item.name,
          productType: item.productType,
        });
      });
      setDataProducs(datas);
    },
    enabled: !!(
      dataDeliveryDistribution.customer && dataDeliveryDistribution.typeDocument
    ),
    refetchOnWindowFocus: false,
  });

  const { isFetching: isLoadingDeliveryScan } = useQuery<any, Error>({
    queryKey: ['dataDeliveryScan'],
    queryFn: () =>
      getDataDeliveryScan({
        cycleDate: dataDeliveryDistribution.cycleDate.format('YYYYMMDD'),
        productId: dataDeliveryDistribution.product,
        isScan: showModalScan === 'scan',
      }),
    onSuccess(res) {
      if (res.status === 200) {
        if (res.data.length > 0) {
          setDocumentScan(res.data);
        } else {
          setDocumentScan([]);
        }
      } else {
        ModalStatus({
          status: 'error',
          title: 'Terjadi Kesalahan!!!',
          content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
        });
      }
    },
    enabled: !!showModalScan,
    refetchOnWindowFocus: false,
  });

  const { isFetching: isLoadingBranch } = useQuery<AxiosResponse, Error>({
    queryKey: ['dataListBranch'],
    queryFn: () => getAllBranch(),
    onSuccess(res) {
      if (res?.data?.length > 0) {
        setDataBranchs(res.data || []);
      } else {
        setDataBranchs([]);
      }
    },
  });

  const { isFetching: isLoadingCourier } = useQuery<AxiosResponse, Error>({
    queryKey: [
      'dataCourierByBranch',
      { branchItems: [dataAssignDocument.branch], isAll: false },
    ],
    queryFn: ({ queryKey }) => getCourierWithBranch(queryKey),
    enabled: !!dataAssignDocument.branch,
    onSuccess(res) {
      if (res?.data.length > 0) {
        setDataCourier(res.data);
      } else {
        setDataCourier([]);
      }
    },
  });

  const { mutate: submitScanDelivery, isLoading: isLoadingScan } = useMutation(
    getDeliveryStatusScan,
    {
      onSuccess: res => {
        if (res.status === 200) {
          setDataScanDelivery({
            scan: res?.data?.scan || 0,
            unScan: res?.data?.unScan || 0,
          });
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    }
  );

  const { mutate: mutateAssignDocument, isLoading: isLoadingAssign } =
    useMutation(putAssignDocument, {
      onSuccess: res => {
        if (res.status === 200) {
          if (!!res?.data?.isMultiple) {
            setIsShowModalMultipleWaybill(!isShowModalMultipleWaybill);
            setDataWaybillMultiple(res?.data?.data || []);
          } else {
            setIsShowModalMultipleWaybill(false);
            setDataWaybillMultiple([]);
            handleChangeAssignDocument('scan', '');
            const selectedCourier =
              dataCourier?.find(
                item => item.id === dataAssignDocument.courier
              ) || null;
            if (dataDeliveryDistribution.cycleDate) {
              submitScanDelivery({
                cycleDate:
                  dataDeliveryDistribution.cycleDate.format('YYYYMMDD'),
                productId: dataDeliveryDistribution.product,
              });
            }
            setSuccessScan({
              waybill: dataAssignDocument.scan,
              courierName: selectedCourier?.firstName || null,
            });
          }
        } else {
          ModalStatus({
            status: 'error',
            title: 'Terjadi Kesalahan!!!',
            content: res.response?.data?.Message || 'Silahkan Hubungi Admin',
          });
        }
      },
      onError: () => {
        ModalStatus({
          status: 'error',
          title: 'Internal Server Error',
          content: 'Silahkan Hubungi Admin',
        });
      },
    });

  const handleChangeDeliveryDistribution = (name: string, value: any) => {
    setDataDeliveryDistribution(prevState => ({
      ...prevState,
      [name]: value,
    }));

    if (name !== 'product') {
      if (name !== 'typeDocument') {
        handleChangeDeliveryDistribution('typeDocument', '');
      }
      handleChangeDeliveryDistribution('product', null);
      setDataScanDelivery({
        scan: 0,
        unScan: 0,
      });
    }

    if (name === 'product' && !!value) {
      submitScanDelivery({
        cycleDate: dataDeliveryDistribution.cycleDate.format('YYYYMMDD'),
        productId: value,
      });
    }
  };

  const handleChangeAssignDocument = (name: string, value: any) => {
    setDataAssignDocument(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCloseModalScan = () => {
    setModalScan(null);
    setDocumentScan([]);
  };

  const handleAssignDocument = () => {
    const isWaybill = dataAssignDocument.typeScan === 'waybill';
    const barcode =
      (!isWaybill &&
        (dataAssignDocument.isReverseBarcode
          ? dataAssignDocument.scan.split('').reverse().join('')
          : dataAssignDocument.scan)) ||
      '';
    const data = {
      customerProductId: dataDeliveryDistribution?.product || 0,
      noResi: dataAssignDocument.noResi,
      productType: dataDeliveryDistribution?.typeDocument || 0,
      trueOrFalseKoli: dataAssignDocument.trueOrFalseKoli,
      barcode,
      waybill: isWaybill && [dataAssignDocument.scan],
      dimention: dataAssignDocument.paperType,
      cycle: dataDeliveryDistribution.cycleDate
        ? dataDeliveryDistribution.cycleDate.format('YYYYMMDD')
        : '',
      deliveryType: dataAssignDocument.deliveryType,
      deliveryDistrictType: dataAssignDocument.district,
      branchId: dataAssignDocument.branch,
      courierId: dataAssignDocument.courier,
      koli: dataAssignDocument.koli?.filter(item => !!item)?.join(';'),
      totalWeight: dataAssignDocument.totalWeight,
      typeScan: dataAssignDocument.typeScan,
    };

    mutateAssignDocument(data);
  };

  const handleAssignMultipleDocument = (waybill: any[]) => {
    const isWaybill = true;
    const barcode =
      (!isWaybill &&
        (dataAssignDocument.isReverseBarcode
          ? dataAssignDocument.scan.split('').reverse().join('')
          : dataAssignDocument.scan)) ||
      '';
    const data = {
      customerProductId: dataDeliveryDistribution?.product || 0,
      noResi: dataAssignDocument.noResi,
      productType: dataDeliveryDistribution?.typeDocument || 0,
      trueOrFalseKoli: dataAssignDocument.trueOrFalseKoli,
      barcode,
      waybill: isWaybill && waybill,
      dimention: dataAssignDocument.paperType,
      cycle: dataDeliveryDistribution.cycleDate
        ? dataDeliveryDistribution.cycleDate.format('YYYYMMDD')
        : '',
      deliveryType: dataAssignDocument.deliveryType,
      deliveryDistrictType: dataAssignDocument.district,
      branchId: dataAssignDocument.branch,
      courierId: dataAssignDocument.courier,
      koli: dataAssignDocument.koli?.filter(item => !!item)?.join(';'),
      totalWeight: dataAssignDocument.totalWeight,
      typeScan: 'waybill',
    };

    mutateAssignDocument(data);
  };

  const handleShowModalMultipleWaybill = () => {
    setDataWaybillMultiple([]);
    setIsShowModalMultipleWaybill(!isShowModalMultipleWaybill);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      <DistributionComponent
        dataDeliveryDistribution={dataDeliveryDistribution}
        dataCustomers={dataCustomers}
        dataProducts={dataProducts}
        dataScanDelivery={dataScanDelivery}
        isLoadingCustomers={isLoadingCustomers}
        isLoadingProducts={isLoadingProducts}
        isLoadingScan={isLoadingScan}
        handleChangeDeliveryDistribution={handleChangeDeliveryDistribution}
        setModalScan={setModalScan}
      />
      <AssignDocument
        handleChange={handleChangeAssignDocument}
        initialValues={dataAssignDocument}
        isLoadingBranch={isLoadingBranch}
        isLoadingCourier={isLoadingCourier}
        dataBranchs={dataBranchs}
        dataCourier={dataCourier}
        isLoadingAssign={isLoadingAssign}
        handleAssignDocument={handleAssignDocument}
        successScan={successScan}
      />
      <AdditionalData
        isLoading={isLoadingAssign}
        handleChange={handleChangeAssignDocument}
        handleAssignDocument={handleAssignDocument}
        initialValues={dataAssignDocument}
      />
      <ModalDocumentScan
        show={!!showModalScan}
        isScan={showModalScan === 'scan'}
        isLoading={isLoadingDeliveryScan}
        onClose={handleCloseModalScan}
        dataScan={documentScan}
      />
      <ModalSelectBarcodeMultiple
        isShowModal={isShowModalMultipleWaybill}
        handleCloseModal={handleShowModalMultipleWaybill}
        dataScanBarcode={dataWaybillMultiple}
        handleSubmit={value => handleAssignMultipleDocument(value)}
        isLoadingScan={isLoadingAssign}
        okText="Assign"
        isCourier
      />
    </div>
  );
}
