export function getListCustomerReportFormat() {
  return {
    type: 'GET_LIST_CUSTOMER_REPORT_FORMAT',
  };
}
export function setLoadingCustomerReportFormat() {
  return {
    type: 'SET_LOADING_CUSTOMER_REPORT_FORMAT',
  };
}
