import { Col, Row, Table } from 'antd';
import React, { Component } from 'react';

import ButtonManageCustomerAccountContainer from '../../../../containers/Content/ManageAccount/Customer/ButtonManageCustomerAccountContainer';
import CardMaterial from '../../../../materials/CardMaterial';
import SearchMaterial from '../../../../materials/SearchMatrial';
import SpinMaterial from '../../../../materials/SpinMaterial';

interface IProps {
  handlePage: any;
  handleSearch: any;
  handleResetSearch: any;
  handleSubmitSearch: any;
  data: any;
  PagePagination: any;
  TotalPagePagination: any;
  nameSearch: any;
  dataSource: any;
  isLoading: any;
  ValueSearch: any;
}

export default class TableManageCustomer extends Component<IProps> {
  render() {
    let loopPage = (this.props.PagePagination - 1) * 10 + 1;
    const columns = [
      {
        title: (
          <div style={{ textAlign: 'center' }}>
            <b>No</b>
          </div>
        ),
        width: 50,
        render: (text: any, record: any, index: any) => (
          <span>{this.props.PagePagination > 1 ? index + loopPage : index + 1}</span>
        ),
      },
      {
        title: <b>Nama</b>,
        dataIndex: 'name',
        key: 'name',
        width: 120,
      },
      {
        title: <b>Kode</b>,
        dataIndex: 'code',
        key: 'code',
        render: (code: any) => <span style={{ color: 'red' }}>{code}</span>,
        width: 80,
      },
      {
        title: <b>Jenis</b>,
        dataIndex: 'customerType',
        key: 'customerType',
        width: 100,
      },
      {
        title: <b>Email</b>,
        dataIndex: 'email',
        key: 'email',
        render: (email: any) => <span style={{ color: 'red' }}>{email}</span>,
        width: 100,
      },
      {
        title: <b>Aksi</b>,
        key: 'action',
        width: 90,
        render: (record: any) => (
          <div>
            <Row>
              <Col>
                <div style={{ paddingRight: '85px' }}>
                  <ButtonManageCustomerAccountContainer
                    idCustomer={record.id}
                    idCustomerAccount={''}
                    typeButton={'detailAccount'}
                  />
                </div>
              </Col>
            </Row>
          </div>
        ),
      },
    ];
    return (
      <div style={{ paddingBottom: '35px' }}>
        <div>
          <SpinMaterial
            spinning={this.props.isLoading}
            style={{}}
            size={'large'}
            content={
              <div>
                <CardMaterial
                  extra={''}
                  title={''}
                  style={{}}
                  content={
                    <div>
                      <Row>
                        <Col span={12}>
                          <div>
                            <span
                              style={{ color: '#11BEFF', paddingRight: '20px' }}
                            >
                              <b>PELANGGAN</b>
                            </span>
                            <SearchMaterial
                              value={this.props.ValueSearch}
                              style={{ borderRadius: '10px', width: '50%' }}
                              placeholder={'Cari nama atau kode'}
                              onChange={this.props.handleSearch}
                              onPressEnter={this.props.handleSubmitSearch}
                            />
                          </div>
                        </Col>
                        <Col span={12}></Col>
                      </Row>
                    </div>
                  }
                ></CardMaterial>
                <Table
                  rowKey={record => record.id}
                  pagination={{
                    onChange: page => {
                      this.props.handlePage(page);
                    },
                    pageSize: 10,
                    total: this.props.TotalPagePagination,
                current:
                    this.props.PagePagination === 0
                      ? 1
                      : this.props.PagePagination,
                  }}
                  bordered={false}
                  dataSource={this.props.data}
                  columns={columns}
                />
              </div>
            }
          ></SpinMaterial>
        </div>
      </div>
    );
  }
}
