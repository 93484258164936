import React from 'react';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import * as ActionPropertiesHoliday from '../../../core/action/Holiday/ActionPropertiesHoliday';
import * as ActionDetailHoliday from '../../../core/action/Holiday/ActionDetailHoliday';
import * as ActionToggle from '../../../core/action/toggle';
import ButtonEditHolidayComponent from '../Component/ButtonEditHolidayComponent';

interface IProps {
  idHoliday: any;
  actionDetailHoliday: any;
  actionToggle: any;
  actionPropertiesHoliday: any;
}

const ButtonEditHolidayContainer = (props: IProps) => {
  const handleEditHoliday = () => {
    props.actionPropertiesHoliday.setIdHoliday(props.idHoliday);
    props.actionToggle.changeJenisToggle('EditHoliday');
    props.actionToggle.changeModal();
    props.actionDetailHoliday.getDetailHoliday();
  };

  return (
    <div>
      <ButtonEditHolidayComponent handleEditHoliday={handleEditHoliday} />
    </div>
  );
};

const mapStateProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  actionPropertiesHoliday: bindActionCreators(
    ActionPropertiesHoliday,
    dispatch
  ),
  actionDetailHoliday: bindActionCreators(ActionDetailHoliday, dispatch),
  actionToggle: bindActionCreators(ActionToggle, dispatch),
});

const withConnect = connect(mapStateProps, mapDispatchToProps);

export default compose(withConnect)(ButtonEditHolidayContainer);
