import { Col, List, Row, Skeleton } from 'antd';
import { Field, reduxForm } from 'redux-form';

import CButton from '../../../apps/assets/components/CButton';
import CCard from '../../../apps/assets/components/CCard';
import { InputText } from '../../../apps/assets/components/CInput';
import React from 'react';

function MainPickUpProcessComponent(props: any) {
  const {
    isLoading,
    pickUpProcessList,
    handleSyncron,
    formValue,
    handleDetailPickUp,
  } = props;
  const formValueSearch = formValue ? formValue.searchInput : null;
  const buttonSyncron = [
    {
      type: 'primary',
      className: 'btnSyncronPickUpProcess',
      onClick: handleSyncron,
      isLoading: isLoading,
      icon: 'sync',
      content: 'Silahkan Sinkronisasikan Lebih Dulu',
      id: 'btnSyncronPickUpProcess',
    },
  ];

  const listData: any = [];
  for (let i = 0; i < 3; i++) {
    listData.push({
      custName: '',
      totalDataBeforeAssign: '',
      totalDataAfterAssign: '',
    });
  }

  const dataSourceValue = isLoading ? listData : pickUpProcessList;
  const dataFilter = dataSourceValue
    .sort((a: any, b: any) => b.total - a.total)
    .filter((state: any) =>
      state.custName.toString().toLowerCase().includes(formValueSearch)
    );

  const contentSearchCard = () => {
    return (
      <React.Fragment>
        <Row>
          <Col span={8} className={'textSearchCard'}>
            <span className={'titleSearchCard'}>
              <b>CARI CUSTOMER</b>
            </span>
          </Col>
          <Col span={16}>
            <Field
              name="searchInput"
              component={InputText}
              placeholder={'Cari nama customer'}
              label={''}
              icon={'search'}
              disabled={false}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  const contentListCard = (
    custName: any,
    totalBefore: any,
    totalAfter: any
  ) => {
    return (
      <React.Fragment>
        <div className="headerContentCard">{custName}</div>
        <div className={'contentCardPickUpProcess'}>
          <span>{totalBefore}/</span>
          <span>
            <b>{totalAfter}</b>
          </span>
        </div>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className={'berandaPickUpProcess'}>
        <Row>
          <Col span={12} className={'spacingCardPickUpProcess'}>
            <CCard
              cardClassName={'searchCardPickUpProcess'}
              cardContent={contentSearchCard()}
            />
          </Col>
          <Col span={12}>
            <CButton buttonData={buttonSyncron} />
          </Col>
        </Row>
      </div>
      <div className={'spacingContentCardPickUpProcess'}>
        <List
          grid={{ gutter: 15, column: 3 }}
          dataSource={formValueSearch !== null ? dataFilter : dataSourceValue}
          pagination={{
            pageSize: 6,
          }}
          renderItem={(item: any) => (
            <List.Item>
              <CCard
                cardClassName={'cardPickUpProcess'}
                cardContent={
                  <React.Fragment>
                    {isLoading ? (
                      <Skeleton loading={isLoading} active />
                    ) : (
                      contentListCard(
                        item.custName,
                        item.totalDataBeforeAssign,
                        item.totalDataAfterAssign
                      )
                    )}
                  </React.Fragment>
                }
              />
              {isLoading ? null : handleDetailPickUp(item)}
            </List.Item>
          )}
        />
      </div>
    </React.Fragment>
  );
}
const PickUpProcessComponent = reduxForm({
  form: 'pickUpProcess',
  shouldError: () => {
    return true;
  },
})(MainPickUpProcessComponent);
export default PickUpProcessComponent;
