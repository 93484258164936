import { Form, Tree, Checkbox, Row, Col } from 'antd';
import { flattenDeep } from 'lodash';
import BadgeTree from 'components/BadgeTree';
import styles from './TreeField.module.css';

import React, { useState, useEffect } from 'react';

interface IPropsTree {
  input?;
  treeData;
  isExpandedAll?: boolean;
  expandedKeys?: Array<string>;
  setDataMenuState?: (value: Array<any>) => void;
  isMultiple?: boolean;
  isView?: boolean;
}

const getAllKeys = data => {
  const nestedKeys = data.map(node => {
    let childKeys = [] as any;
    if (node.subMenus) {
      childKeys = getAllKeys(node.subMenus);
    }
    return [childKeys, node.key];
  });
  return flattenDeep(nestedKeys) as Array<string>;
};

export const TreeField = ({
  expandedKeys,
  treeData,
  isExpandedAll,
  setDataMenuState = () => undefined,
  isMultiple = false,
  isView = false,
}: IPropsTree) => {
  const [expandedKeysMenu, setExpandedKeys] = useState<Array<string>>([]);
  const { TreeNode } = Tree;

  useEffect(() => {
    if (expandedKeys && expandedKeys.length > 0) {
      setExpandedKeys(expandedKeys);
    }
  }, [expandedKeys]);

  const handleCheckPermission = (
    key,
    permission: 'view' | 'delete' | 'edit'
  ) => {
    const datas = [...treeData] as any[];
    datas.forEach(menu => {
      if (menu.key === key) {
        menu.permission[permission] = !menu.permission[permission];
        if (menu?.subMenus && menu?.subMenus.length > 0) {
          menu.subMenus.forEach(submenu => {
            submenu.permission[permission] = menu.permission[permission];
            if (submenu?.tabMenus && submenu?.tabMenus.length > 0) {
              submenu.tabMenus.forEach(tabmenu => {
                tabmenu.permission[permission] = menu.permission[permission];
              });
            }
          });
        }
        if (menu?.tabMenus && menu?.tabMenus.length > 0) {
          menu.tabMenus.forEach(tabmenu => {
            tabmenu.permission[permission] = menu.permission[permission];
          });
        }
      } else {
        const submenus = menu?.subMenus || [];
        const tabmenus = menu?.tabMenus || [];
        if (menu.subMenu && submenus.length > 0) {
          const [submenu] = submenus.filter(submenu => submenu.key === key);
          if (submenu) {
            menu.permission[permission] = true;
            submenu.permission[permission] = !submenu.permission[permission];

            const filtered = submenus.filter(
              submenu => submenu.permission[permission]
            );
            const filteredFalse = submenus.filter(
              submenu => !submenu.permission[permission]
            );
            menu.permission[permission] =
              filtered.length === submenus.length
                ? menu.permission[permission]
                : filteredFalse.length === submenus.length
                ? false
                : true;

            if (submenu?.tabMenus && submenu?.tabMenus.length > 0) {
              submenu?.tabMenus.forEach(tabmenu => {
                tabmenu.permission[permission] = submenu.permission[permission];
              });
            }
          }
          menu.subMenus.forEach(submenu => {
            const tabmenus = submenu?.tabMenus || [];
            if (tabmenus?.length > 0) {
              const [tabmenu] = tabmenus.filter(tabmenu => tabmenu.key === key);
              if (tabmenu) {
                menu.permission[permission] = true;
                submenu.permission[permission] = true;
                tabmenu.permission[permission] =
                  !tabmenu.permission[permission];

                const filtered = tabmenus.filter(
                  tabmenu => tabmenu.permission[permission]
                );
                const filteredFalse = tabmenus.filter(
                  tabmenu => !tabmenu.permission[permission]
                );

                menu.permission[permission] = submenu.permission[permission] =
                  filtered.length === tabmenus.length
                    ? submenu.permission[permission]
                    : filteredFalse.length === tabmenus.length
                    ? false
                    : true;
              }
            }
          });
        }
        if (menu.tabMenu && tabmenus.length > 0) {
          const [tabmenu] = tabmenus.filter(tabmenu => tabmenu.key === key);
          if (tabmenu) {
            menu.permission[permission] = true;
            tabmenu.permission[permission] = !tabmenu.permission[permission];
            const filtered = tabmenus.filter(
              tabmenu => tabmenu.permission[permission]
            );
            const filteredFalse = tabmenus.filter(
              tabmenu => !tabmenu.permission[permission]
            );

            menu.permission[permission] =
              filtered.length === tabmenus.length
                ? menu.permission[permission]
                : filteredFalse.length === tabmenus.length
                ? false
                : true;
          }
        }
      }
    });

    setDataMenuState(datas);
  };

  const getActivePermission = (
    permission: 'view' | 'delete' | 'edit'
  ): boolean => {
    const datas = [...treeData] as any[];

    if (datas.length === 0) {
      return false;
    }

    const hasFalseViewPermission = datas.some(menu => {
      return (
        !menu.permission[permission] ||
        (menu.subMenus &&
          menu.subMenus.some(submenu => !submenu.permission[permission])) ||
        (menu.tabMenus &&
          menu.tabMenus.some(tabMenu => !tabMenu.permission[permission]))
      );
    });

    return !hasFalseViewPermission;
  };

  const handleCheckAllPermission = (
    permission: 'view' | 'delete' | 'edit',
    checked: boolean
  ) => {
    const datas = [...treeData] as any[];
    datas.forEach(menu => {
      const submenus = menu?.subMenus || [];
      const tabmenus = menu?.tabMenus || [];

      menu.permission[permission] = checked;
      if (menu.subMenu && submenus.length > 0) {
        submenus.forEach(submenu => {
          submenu.permission[permission] = checked;
          const subtabmenus = submenu?.tabMenus || [];
          if (subtabmenus?.length > 0) {
            subtabmenus.forEach(subtabmenu => {
              subtabmenu.permission[permission] = checked;
            });
          }
        });
      }

      if (menu.tabMenu && tabmenus.length > 0) {
        tabmenus.forEach(tabmenu => {
          tabmenu.permission[permission] = checked;
        });
      }
    });

    setDataMenuState(datas);
  };

  const onExpand = expandedKeys => {
    setExpandedKeys(expandedKeys);
  };

  const renderTreeNode = item => {
    const renderSubMenus = (subMenus, menu, isTabMenus = false) => {
      if (!subMenus) return null;

      return subMenus.map(subItem => {
        const renderTabMenus = tabMenus => {
          if (!tabMenus) return null;

          return tabMenus.map(tabItem => (
            <TreeNode
              title={renderNodeContent(tabItem, 'Tab')}
              key={tabItem.key}
              dataRef={subItem}
              disableCheckbox={tabItem.disableCheckbox}
              expanded={true}
            />
          ));
        };

        return (
          <TreeNode
            title={renderNodeContent(subItem, menu, isTabMenus)}
            key={subItem.key}
            dataRef={item}
            disableCheckbox={subItem.disableCheckbox}
            expanded={true}
          >
            {renderTabMenus(subItem.tabMenus)}
          </TreeNode>
        );
      });
    };

    const renderNodeContent = (node, label, isTabMenusParent = false) => {
      let isSelectTabMenus = false;
      const isSubmenus =
        node?.subMenus?.some(value => value.permission?.view) &&
        node.subMenus.filter(value => value.permission?.view).length <
          node.subMenus.length;

      const isTabmenus =
        node?.tabMenus?.some(value => value.permission?.view) &&
        node.tabMenus.filter(value => value.permission?.view).length <
          node.tabMenus.length;

      if (label === 'Modul') {
        const subMenusWithTabMenus = node?.subMenus?.filter(
          subMenu => subMenu.tabMenus
        );
        const tabMenus = subMenusWithTabMenus?.flatMap(
          subMenu => subMenu.tabMenus
        );

        const isViewTabMenus =
          tabMenus?.filter(tabMenu => tabMenu.permission?.view).length || 0;
        const isTabMenus = tabMenus?.length || 0;
        isSelectTabMenus = !!isViewTabMenus && isViewTabMenus < isTabMenus;
      }

      if (isMultiple) {
        return (
          <Row gutter={16}>
            <Col span={12} className={styles['wrapperContentParent']}>
              {label !== 'Modul' && (
                <>
                  <BadgeTree
                    label={'Modul'}
                    styles={{ visibility: 'hidden', padding: 0 }}
                  />
                  {label === 'Tab' && (
                    <BadgeTree
                      label={'Submodul'}
                      styles={{ visibility: 'hidden', padding: 0 }}
                    />
                  )}
                </>
              )}
              <div
                className={styles['wrapperContent']}
                style={
                  label === 'Tab' && isTabMenusParent ? { marginLeft: 19 } : {}
                }
              >
                <BadgeTree label={label} />
                <p style={{ margin: 0 }}>{node.menuName}</p>
              </div>
            </Col>
            <Col span={12}>
              <Checkbox
                disabled={node.disableCheckbox}
                checked={node?.permission?.view || false}
                onChange={() => handleCheckPermission(node.key, 'view')}
                indeterminate={isSubmenus || isTabmenus || isSelectTabMenus}
                style={
                  label !== 'Modul'
                    ? {
                        marginLeft:
                          label === 'Tab' && !isTabMenusParent
                            ? '-20px'
                            : '-10px',
                      }
                    : {}
                }
              />
            </Col>
          </Row>
        );
      }

      return (
        <div className={styles['wrapperContentParent']}>
          {label !== 'Modul' && (
            <>
              <BadgeTree
                label={'Modul'}
                styles={{ visibility: 'hidden', padding: 0 }}
              />
              {label === 'Tab' && (
                <BadgeTree
                  label={'Submodul'}
                  styles={{ visibility: 'hidden', padding: 0 }}
                />
              )}
            </>
          )}
          <div className={styles['wrapperContent']}>
            <BadgeTree label={label} />
            <Checkbox
              disabled={node.disableCheckbox}
              checked={node?.permission?.view || false}
              onChange={() => handleCheckPermission(node.key, 'view')}
              indeterminate={isSubmenus || isTabmenus || isSelectTabMenus}
            />
            <p style={{ margin: 0 }}>{node.menuName}</p>
          </div>
        </div>
      );
    };

    return (
      <TreeNode
        title={renderNodeContent(item, 'Modul')}
        key={item.key}
        dataRef={item}
        disableCheckbox={item.disableCheckbox}
        expanded={true}
      >
        {renderSubMenus(item.subMenus, 'Submodul')}
        {renderSubMenus(item.tabMenus, 'Tab', true)}
      </TreeNode>
    );
  };

  const renderTreeNodes = data => data.map(renderTreeNode);

  return (
    <Form.Item>
      <div className={styles['wrapperTree']}>
        {isMultiple && (
          <Row className={styles['wrapperHeader']}>
            <Col span={12}>
              <h1 className={styles['labelHeader']}>Nama Modul</h1>
            </Col>
            <Col span={12}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  paddingLeft: 4,
                }}
              >
                <Checkbox
                  onChange={e =>
                    handleCheckAllPermission('view', e.target.checked)
                  }
                  checked={getActivePermission('view')}
                  disabled={isView}
                />
                <h1 className={styles['labelHeader']}>View</h1>
              </div>
            </Col>
          </Row>
        )}
        <Tree
          expandedKeys={isExpandedAll ? getAllKeys(treeData) : expandedKeysMenu}
          onExpand={onExpand}
          selectable={false}
        >
          {renderTreeNodes(treeData)}
        </Tree>
      </div>
    </Form.Item>
  );
};
