export function setLoadingCustomerProduct()
{
    return({
        type:"SET_LOADING_CUSTOMER_PRODUCT"
    })
}
export function setTypeLoadingCustomerProduct(TypeLoadingCustomerProduct:any)
{
    return({
        type:"SET_TYPE_LOADING_CUSTOMER_PRODUCT",
        TypeLoadingCustomerProduct
    })
}
export function clearTypeLoadingCustomerProduct()
{
    return({
        type:"CLEAR_TYPE_LOADING_CUSTOMER_PRODUCT"
    })
}
export function clearLoadingCustomerProduct()
{
    return({
        type:"CLEAR_LOADING_CUSTOMER_PRODUCT"
    })
}