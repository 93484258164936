import React from 'react';
import { Button, Dropdown, Form, Menu } from 'antd';

import CIcon from 'apps/assets/components/CIcon';

interface IPropsDropdown {
  input?;
  icon?;
  dropdownMenu;
  labelTitle?;
  triggerAction;
  className?;
  onChange?;
  value?;
}

export const DropdownBasic = (props: IPropsDropdown) => {
  const {
    dropdownMenu,
    triggerAction,
    labelTitle,
    className,
    onChange = () => undefined,
    value,
  } = props;
  const handleOnChange = e => onChange(e);
  const menuList = (
    <Menu onClick={handleOnChange} selectedKeys={['active']}>
      {dropdownMenu.map((data: any, index: any) => (
        <Menu.Item key={data.key}>{data.label}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Form.Item style={{ marginBottom: '0px' }}>
      <Dropdown
        overlay={menuList}
        trigger={[triggerAction]}
        className={className}
      >
        <Button type="link" className="spacingLeft" href="#">
          <span className="blackColor">
            {value ? value.label : labelTitle}&nbsp;&nbsp;
          </span>
          <CIcon type="down" />
        </Button>
      </Dropdown>
    </Form.Item>
  );
};
