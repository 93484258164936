import * as ActionLogin from '../../../module/Login/Store';
import * as Address from '../../action/Address/index';

import { call, put, select, takeLatest } from 'redux-saga/effects';

import { ErrorMassage } from '../../ErrorMassage/index';
import { Modal } from 'antd';
import React from 'react';
import axios from 'axios';

export function* S_GetVillage(action: any) {
  try {
    yield put(Address.setLoadingAddress());
    yield put(Address.setLoadingTypeAddress('selectVillage'));
    const state = yield select();
    const idSubDistrict = state.Branch.DetailBranch.SubDistrict;
    const token = state.Auth.Token;
    const config = {
      Authorization: `bearer ${token}`,
      'Content-type': 'application/json',
    };
    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_URL}/Address/village/0/1000?subDistrictId=
        ${idSubDistrict}`,
      { headers: config }
    );
    yield put(Address.SetVillage(data.data));
    yield put(Address.setLoadingAddress());
    yield put(Address.clearLoadingTypeAddress());
  } catch (error) {
    yield put(ActionLogin.errorHandling(action, error));
  }
}
export function* S_DetailVillage() {
  try {
    const state = yield select();
    const idVillage = state.Branch.DetailBranch.Village;
    const token = state.Auth.Token;
    const config = {
      Authorization: `bearer ${token}`,
    };
    const { data } = yield call(
      axios.get,
      process.env.REACT_APP_URL + '/Address/village/' + idVillage,
      { headers: config }
    );
    yield put(Address.SetPostalCode(data.subDistrictPostCode));
  } catch (error) {
    const propsError = ErrorMassage('get detail desa');
    const code = propsError.filter((data: any) =>
      data.Code.toString().toLowerCase().includes(error.response.status)
    );
    Modal.error({
      title: (
        <p style={{ color: '#FF0000' }}>
          {code.map((data: any) => data.TitleMassage)}
        </p>
      ),
      content: (
        <div>
          <p style={{ color: '#C0C0C0' }}>
            {code.map((data: any) => data.Massage)}
          </p>
        </div>
      ),
      onOk() {},
    });
  }
}
export function* SM_GetVillage() {
  yield takeLatest('GET_VILLAGE', S_GetVillage);
}
export function* SM_DetailVillage() {
  yield takeLatest('DETAIL_VILLAGE', S_DetailVillage);
}
